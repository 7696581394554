import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import {
	NotificationsService,
	ToastService,
	UrlSaverService
} from 'app/core/services';

import { NotificationStatus } from 'ui-lib';

import { StatusType } from '@enSend/_shared/models';
import { ScenariosDataService } from './scenarios-data.service';
import { OnChangeStatus, Scenario } from './scenario.model';
import { ScenariosStore } from './scenarios.store';

@Injectable({ providedIn: 'root' })
export class ScenariosService {
	constructor(
		private dataService: ScenariosDataService,
		private toastService: ToastService,
		private store: ScenariosStore,
		private notificationService: NotificationsService,
		private confirmationService: ConfirmationService,
		private router: Router,
		private urlService: UrlSaverService,
		private translate: TranslateService
	) {}

	getList({ page, perPage, sort, search, filters }: any): any {
		return this.dataService
			.list({
				limit: perPage,
				offset: (page - 1) * perPage,
				sort,
				search,
				startDate: filters?.dateRange && filters?.dateRange[0],
				endDate: filters?.dateRange && filters?.dateRange[1],
				status: filters?.status?.toString()
			})
			.pipe(
				tap(() => {
					this.urlService.setParamsToUrl(
						{
							sortKey: sort?.field,
							sortOrder: sort?.order,
							perPage,
							search
						},
						{
							status: filters?.status || null,
							startDate:
								(filters?.dateRange && filters?.dateRange[0]) ||
								null,
							endDate:
								(filters?.dateRange && filters?.dateRange[1]) ||
								null
						}
					);
				}),
				switchMap(resp => {
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: Math.ceil(resp.total / perPage) || 1,
						data: resp.result ? [...resp.result] : []
					});
				}),
				catchError(() => {
					this.toastService.errorRequestToast();
					return of({
						perPage: 10,
						lastPage: 0,
						currentPage: 1,
						total: 0,
						data: []
					});
				})
			);
	}

	createScenario(data: Scenario) {
		return this.dataService.create(data).pipe(
			catchError(error => {
				this.notificationService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			})
		);
	}

	confirmEditDialog(id: ID) {
		this.confirmationService.confirm({
			key: 'confirmLeave',
			header: this.translate.instant('scenario_dialogs.edit_header'),
			message: this.translate.instant('scenario_dialogs.edit_message'),
			acceptLabel: this.translate.instant('common.edit'),
			rejectLabel: this.translate.instant('common.cancel'),
			accept: () => {
				this.router.navigate([`ensend/scenario/edit/${id}`]);
			}
		});
	}

	confirmDeleteDialog(id: ID, status: StatusType, onActionConfirm: Function) {
		const messageText: Partial<{
			[key in StatusType | 'default']: string;
		}> = {
			draft: this.translate.instant('scenario_dialogs.delete_draft'),
			default: this.translate.instant('scenario_dialogs.delete_default')
		};

		this.confirmationService.confirm({
			key: 'confirmDelete',
			header: this.translate.instant('scenario_dialogs.delete_header'),
			message: messageText[status] || messageText.default,
			accept: () => {
				this.delete(id)
					.pipe(tap(() => onActionConfirm()))
					.subscribe();
			}
		});
	}

	delete(id: ID): Observable<void> {
		this.store.setLoading(true);
		return this.dataService.delete(id).pipe(
			tap(() => {
				this.store.remove(id);
				this.notificationService
					.show(
						this.translate.instant('scenario_toasts.delete_text', {
							value: id
						}),
						{
							label: 'toast.deleted',
							status: NotificationStatus.SUCCESS,
							params: {
								id
							}
						}
					)
					.subscribe();
			}),
			catchError(e => {
				this.notificationService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	confirmStatusDialog(id: ID, status: string, onActionConfirm: Function) {
		if (status === 'stop') {
			this.confirmationService.confirm({
				key: 'info',
				header: this.translate.instant('scenario_dialogs.stop_header'),
				acceptLabel: this.translate.instant(
					'scenario_dialogs.stop_accept'
				),
				rejectVisible: true,
				rejectLabel: this.translate.instant('common.cancel'),
				message: this.translate.instant(
					'scenario_dialogs.stop_message'
				),
				accept: () => {
					this.changeStatus({ id, status })
						.pipe(
							tap(() => onActionConfirm()),
							switchMap(() =>
								this.notificationService.show('', {
									label: this.translate.instant(
										'scenario_toasts.deactivation_title'
									),
									status: NotificationStatus.SUCCESS
								})
							)
						)
						.subscribe();
				}
			});
			return;
		}

		if (status === 'start') {
			this.changeStatus({ id, status })
				.pipe(
					tap(() => onActionConfirm()),
					catchError(e => {
						if (e.error.message === 'scenario has removed items') {
							this.confirmationService.confirm({
								key: 'confirmLeave',
								header: this.translate.instant(
									'scenario_dialogs.invalid_header'
								),
								acceptLabel:
									this.translate.instant('common.edit'),
								rejectVisible: true,
								rejectLabel:
									this.translate.instant('common.cancel'),
								message: this.translate.instant(
									'scenario_dialogs.invalid_message'
								),
								accept: () => {
									this.router.navigate([
										`ensend/scenario/edit/${id}`
									]);
								}
							});
						}
						if (e.error.message === 'scenario contains cycles') {
							this.notificationService
								.show('', {
									label: this.translate.instant(
										'scenario_wizard.contains_cycles_toast_label'
									),
									status: NotificationStatus.ERROR,
									style: {
										label: 'maxWidth: 40rem'
									}
								})
								.subscribe();
						}

						return throwError(e);
					}),
					switchMap(() =>
						this.notificationService.show('', {
							label: this.translate.instant(
								'scenario_toasts.activation_title'
							),
							status: NotificationStatus.SUCCESS
						})
					)
				)
				.subscribe();
		}
	}

	changeStatus({ id, status }: OnChangeStatus) {
		this.store.setLoading(true);
		return this.dataService.changeStatus({ id, status }).pipe(
			tap(() => {
				// console.log(this.store.getValue(id));
				// this.store.update(id, { status });
			}),
			finalize(() => this.store.setLoading(false))
		);
	}
}
