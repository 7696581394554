import { ID } from '@datorama/akita';
import { CommonTypeOptions } from '@enkod-core/types';

export interface SqlTemplateDefault {
	name: string;
	description: string;
	type: SqlType;
	sql: string;
	params: ISqlParams[];
}
export interface SqlTemplate extends SqlTemplateDefault {
	id: ID;
}

export interface SqlTemplateCopied extends SqlTemplateDefault {
	id: ID | null;
}

export type SqlType = 'segment' | 'dc';
export interface ISqlParams {
	name: string;
	type: CommonTypeOptions;
	testValue: number | string;
}
