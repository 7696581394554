import { NgModule } from '@angular/core';
import { InjectorMessageWizardDirective } from './injector-message-wizard.directive';

/**
 * @deprecated
 * Удалить когда полностью уберем содание формы wizard-а из диалога выбора типа сообщения в списке сообщений */
@NgModule({
	declarations: [InjectorMessageWizardDirective],
	exports: [InjectorMessageWizardDirective]
})
export class InjectorMessageWizardModule {}
