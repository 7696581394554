import { Provider } from '@angular/core';
import { MessagesTab, MESSAGES_TAB_TOKEN } from '@enSend/message/kit';
import {
	MESSAGE_TABLE_COLUMNS,
	SCENARIO_TABLE_COLUMNS,
	WHATSAPP_TABLE_COLUMNS
} from '@enSend/_shared/components/message-table/columns';

export const WHATSAPP_MESSAGES_TAB: MessagesTab = {
	location: 'whatsapp',
	columns: WHATSAPP_TABLE_COLUMNS,
	hideFilters: ['channel', 'sendingType', 'sendingTime', 'scenarios']
};

export const SCENARIO_MESSAGES_TAB: MessagesTab = {
	location: 'scenario',
	columns: SCENARIO_TABLE_COLUMNS,
	hideFilters: ['sendingType'],
	filter: { sendingType: ['scenario'] }
};

export const COMMON_MESSAGES_TAB: MessagesTab = {
	location: 'message',
	columns: MESSAGE_TABLE_COLUMNS,
	hideFilters: ['scenarios']
};

export const MESSAGE_LIST_TABS_PROVIDER: Provider[] = [
	{
		provide: MESSAGES_TAB_TOKEN,
		useValue: COMMON_MESSAGES_TAB,
		multi: true
	},
	{
		provide: MESSAGES_TAB_TOKEN,
		useValue: SCENARIO_MESSAGES_TAB,
		multi: true
	},
	{
		provide: MESSAGES_TAB_TOKEN,
		useValue: WHATSAPP_MESSAGES_TAB,
		multi: true
	}
];
