import { Inject, Injectable } from '@angular/core';
import { dia } from '@clientio/rappid';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InspectorItem, InspectorItemPlugin } from './inspector-item-plugin';
import { INSPECTOR_ITEM_PLUGIN } from './inspector-item-plugin.token';

@Injectable()
export class InspectorManager {
	constructor(
		@Inject(INSPECTOR_ITEM_PLUGIN)
		private readonly inspectorItemPlugins: InspectorItemPlugin[]
	) {}

	getInspector(cell: dia.Cell): Observable<InspectorItem> {
		return combineLatest(
			this.inspectorItemPlugins.map(plugins => plugins.getItems())
		).pipe(
			map(items => items.flat()),
			map(items => items.find(item => item.id === cell.get('subType'))),
			filter((items): items is InspectorItem => items !== undefined),
			map(items =>
				Object.assign(items, { context: { ...items.context, cell } })
			)
		);
	}
}
