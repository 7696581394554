import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { EnButtonModule } from 'ui-lib';
import { FullscreenPreviewComponent } from './fullscreen-preview.component';
import { PopupPreviewIframeModule } from '../../../popup-preview-iframe/popup-preview-iframe.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		PopupPreviewIframeModule,
		EnButtonModule
	],
	declarations: [FullscreenPreviewComponent],
	exports: [FullscreenPreviewComponent]
})
export class FullscreenPreviewModule {}
