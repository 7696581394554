import { Scenario } from '@enSend/scenario/_state';
import { ShapeTypesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';

export class ScenarioFormOTD {
	scenarioModel: Scenario;

	constructor(scenarioModel: Scenario) {
		Object.assign(this, { scenarioModel });
	}

	getValue() {
		const { id, name, status, creationDate, blocks, links } =
			this.scenarioModel;

		return {
			id,
			name,
			status,
			creationDate,
			cells: {
				cells: [
					...blocks,
					...links.map(link => ({
						...link,
						type: ShapeTypesEnum.LINK
					}))
				]
			}
		};
	}
}
