import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EnsendSharedService } from '@state-enSend/_shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tag } from '@state-enKod/segments';

@UntilDestroy()
@Component({
	selector: 'en-message-add-tag',
	templateUrl: './message-add-tag.component.html',
	providers: [EnsendSharedService],
	styleUrls: ['../../../abstract-condition.component.scss']
})
export class MessageAddTagComponent implements OnInit {
	isHover = false;

	optionsMessageProperty: SelectItem[] = [];

	tags$: Observable<SelectItem[]>;

	tooltipTextTags: string;

	tags: SelectItem[];

	control = this.fb.control<string[] | null>(null);

	@Input() tagsControl: FormControl<Tag[]>;

	@Input() isPreview: boolean;

	@Input() isActionBounce: boolean;

	@Input() markAsInvalid = false;

	@Output() remove = new EventEmitter();

	constructor(
		private sharedService: EnsendSharedService,
		private fb: FormBuilder
	) {}

	clearControl(event: Event, control: AbstractControl) {
		event.stopPropagation();
		control.patchValue(null);
	}

	ngOnInit() {
		this.initTagListStream();
		if (this.tagsControl.value) {
			const tagsArray: string[] = this.tagsControl.value.map(
				(tag: { name: string }) => tag.name
			);
			this.control.patchValue(tagsArray);
		}
		this.control.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(names => {
					const selectedTags: Tag[] = [];
					names?.forEach((name: string) => {
						const finded: SelectItem = this.tags.filter(
							tag => tag.value === name
						)[0];
						selectedTags.push({
							name: finded.value,
							id: Number(finded.label)
						});
					});
					this.tagsControl.patchValue(selectedTags);
				})
			)
			.subscribe();
	}

	private initTagListStream(): void {
		this.tags$ = this.sharedService.getTagObjects().pipe(
			map(tags => {
				const tagItems: SelectItem[] = [];
				if (tags) {
					tags.forEach(tag => {
						tagItems.push({
							label: tag.id.toString(),
							value: tag.name
						});
					});
				}
				return tagItems;
			}),
			tap(tags => {
				this.tags = tags;
			})
		);
	}
}
