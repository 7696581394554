import {
	Component,
	EventEmitter,
	Input,
	Output,
	ChangeDetectionStrategy
} from '@angular/core';

@Component({
	selector: 'en-chosen-item-info',
	templateUrl: './chosen-item-info.component.html',
	styleUrls: ['./chosen-item-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChosenItemInfoComponent {
	@Input() id = '';
	@Input() content = '';
	@Input() contentClickable = false;
	@Input() isCreationMode = true;
	@Input() type = '';
	@Output() remove = new EventEmitter();
	@Output() contentAction = new EventEmitter();
}
