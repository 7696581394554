<div class="fullscreen__header">
    <div>
        <span class="fullscreen__ellipse"></span>
        <span class="fullscreen__ellipse"></span>
        <span class="fullscreen__ellipse"></span>
    </div>
    <button class="en-button-secondary h32"
            enButton
            [label]="'common.back' | translate"
            (click)="onExit.emit()"></button>
</div>
<div class="container">
    <div class="iframe">
        <img class="iframe__background"
             [src]="desktopBackground">
        <en-popup-preview-iframe class="iframe__template"
                                 [template]="template"></en-popup-preview-iframe>
    </div>
</div>
