import { AbstractControl } from '@angular/forms';
import { TuiValidationError } from 'ui-lib';

function formatLinkValue(value: string | { link: string }) {
	return typeof value === 'string' ? value : value.link;
}

export function checkUrl(value: string) {
	return (
		value &&
		(!value.startsWith('http') ||
			!value.includes('://') ||
			!value.includes('.'))
	);
}

export class InputDropdownValidators {
	static urlValidator(control: AbstractControl) {
		const { value } = control;
		const formatValue = formatLinkValue(value);

		return !checkUrl(formatValue)
			? null
			: {
					error: new TuiValidationError(
						'message_wizard_validate_field.mob_push_action_link_not_found'
					)
			  };
	}

	static required(control: AbstractControl) {
		const { value } = control;
		const formatValue = formatLinkValue(value);

		return formatValue
			? null
			: {
					error: new TuiValidationError(
						'message_wizard_validate_field.mob_push_action_link_required'
					)
			  };
	}
}
