/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/lines-between-class-members */
import { ID } from '@datorama/akita';
import { MessageType } from '@enSend/message/kit';
import { WhatsappParamsOTD } from '@enSend/_shared/classes';
import { WaTemplateParamsDTO } from '@enSend/message/whatsapp-template-wizard/models';
import {
	Step_1,
	Step_2,
	Step_3,
	Step_4,
	SendingType,
	MailEntity,
	MailFormEntity,
	PushFormEntity,
	MessageModel,
	PushEntity,
	MobPushEntity,
	MobPushFormEntity,
	SmsFormEntity
} from './models';
import { SmsEntity } from './models/sms-entity.model';

// из объекта в форму
export class WizardOTD {
	id: ID;

	title: string;

	type: MessageType;

	status: string;

	step_1: Step_1;

	step_2: Step_2 = {
		tags: [],
		whatsapp: {}
	};

	step_3: Step_3 = {};
	step_4: Step_4 = {
		segments: [],
		mailingGroups: [],
		exceptSegments: [],
		contactsFile: {}
	};

	constructor(message: MessageModel) {
		this.id = message.id;

		this.title = message.name ?? '';

		this.type = message.type;

		this.status = message.status;

		this.step_1 = {
			sendingType: message.sendingType,
			...this.getSendingCondition(
				message.sendingType,
				message?.sendingSettings
			)
		};

		this.step_2.tags = [...(message.tags || [])];

		switch (this.type) {
			case 'mail':
				if (message.mails)
					this.step_2.mails = [
						...getMails(message.mails, message.status)
					];
				break;

			case 'push':
				if (message.pushes)
					this.step_2.pushes = [...getPush(message.pushes)];
				break;

			case 'mobPush':
				if (message.mobPushes)
					this.step_2.mobPushes = [...getMobPush(message.mobPushes)];
				break;

			case 'sms':
				if (message.sms) this.step_2.sms = [...getSms(message.sms)];
				break;

			case 'whatsapp':
				this.step_2.whatsapp.id = message.id;
				this.step_2.whatsapp.name = message.name;
				if (message.whatsappTemplateId)
					this.step_2.whatsapp.whatsappTemplateId =
						message.whatsappTemplateId;
				if (message.whatsappParams)
					this.step_2.whatsapp.whatsappParams = new WhatsappParamsOTD(
						message.whatsappParams as WaTemplateParamsDTO
					).getValue();

				break;
			default:
				break;
		}

		this.step_3 = {
			...(message.splitTestSettings ? message.splitTestSettings : {})
		};

		this.step_4.segments = [...(message.segments || [])];
		this.step_4.mailingGroups = [...(message.groups || [])];
		this.step_4.exceptSegments = [...(message.exceptSegments || [])];
		if (Object.keys(message.importInfo).length) {
			this.step_4.contactsFile = message.importInfo;
		}
	}

	private getSendingCondition(
		sendingType: SendingType,
		sendingSettings: any
	): {
		sendnow: boolean;
		sendlate: any;
		regular: any;
		api: any;
		event: any;
	} {
		const settings = {
			sendnow: false,
			sendlate: {},
			regular: {},
			api: {},
			event: {},
			doi: {}
		};

		if (sendingType === 'sendnow') settings.sendnow = true;
		if (
			sendingType !== 'sendnow' &&
			sendingType !== '' &&
			sendingType !== 'scenario'
		)
			settings[sendingType] = { ...sendingSettings };

		return settings;
	}
}

function getPush(messages: PushEntity[]) {
	return messages.map(push => {
		const pushFormEntity: PushFormEntity = {
			id: 0,
			messageId: 0,

			subject: '',
			body: '',
			icon: '',
			clickAction: '',
			timeToLive: 259200,

			urlParams: {},
			utm: {}
		};

		(Object.keys(push) as (keyof PushEntity)[]).forEach(key => {
			switch (key) {
				case 'urlParams':
					pushFormEntity.urlParams = push.urlParams.params || {};
					pushFormEntity.utm = push.urlParams.utm || {};
					break;

				case 'id':
				case 'messageId':
					pushFormEntity[key] = push[key];
					break;

				case 'timeToLive':
					pushFormEntity[key] = push[key];
					break;

				default:
					pushFormEntity[key] = push[key];
					break;
			}
		});

		return pushFormEntity;
	}) as PushFormEntity[];
}

function getMobPush(messages: MobPushEntity[]) {
	return messages.map(mobPush => {
		const mobPushFormEntity: MobPushFormEntity = {
			id: 0,
			messageId: 0,

			subject: '',
			body: '',
			smallImage: '',
			image: '',
			appName: '',
			linkRef: { link: '', action: 'url' },
			buttons: [],

			urlParams: {},
			utm: {}
		};

		(Object.keys(mobPush) as (keyof MobPushEntity)[]).forEach(key => {
			switch (key) {
				case 'urlParams':
					mobPushFormEntity.urlParams =
						mobPush.urlParams.params || {};
					mobPushFormEntity.utm = mobPush.urlParams.utm || {};
					break;

				case 'id':
				case 'messageId':
					mobPushFormEntity[key] = mobPush[key];
					break;

				case 'link':
					mobPushFormEntity.linkRef[key] = mobPush[key];
					break;

				case 'action':
					mobPushFormEntity.linkRef[key] = mobPush[key] || 'url';
					break;

				case 'buttons':
					mobPushFormEntity[key] = mobPush[key].map(item => {
						return {
							label: item.label,
							linkRef: {
								action: item.action,
								...(item.link ? { link: item.link } : {})
							}
						};
					});
					break;

				default:
					mobPushFormEntity[key] = mobPush[key];
					break;
			}
		});

		return mobPushFormEntity;
	}) as MobPushFormEntity[];
}

function getMails(messages: MailEntity[], status: string) {
	if (messages.length > 1) {
		// eslint-disable-next-line no-param-reassign
		messages = messages.filter(
			mail => mail.splitTestLabel?.toLowerCase() !== 'winner'
		);

		messages.sort((a: any, b: any) =>
			a.splitTestLabel.localeCompare(b.splitTestLabel)
		);
	}

	return messages.map(mail => {
		const mailWithStatus = { ...mail };
		mailWithStatus.status = '';
		const mailFormEntity: MailFormEntity = {
			id: 0,
			messageId: 0,
			status: '',

			sendSettings: {
				fromEmail: '',
				fromName: '',
				subject: '',
				preheader: '',
				withAttachments: false
			},

			plainText: '',
			html: '',
			amp: '',
			stripoHtml: '',
			stripoCss: '',
			trackClicks: false,
			manually: false,

			chamaileonLetter: {
				jsonBody: {}
			},

			urlParams: {},
			utm: {},
			attachments: [],
			fileAttachments: false
		};

		(Object.keys(mailWithStatus) as (keyof MailEntity)[]).forEach(key => {
			switch (key) {
				case 'urlParams':
					mailFormEntity.urlParams = mail.urlParams.params || {};
					mailFormEntity.utm = mail.urlParams.utm || {};
					break;
				case 'attachments':
					mailFormEntity.attachments = mail.attachments;
					break;

				case 'fileAttachments':
					mailFormEntity.fileAttachments =
						mail.attachments?.length > 0;
					break;

				case 'id':
				case 'messageId':
					mailFormEntity[key] = mail[key];
					break;
				case 'status':
					mailFormEntity.status = status;
					break;
				case 'splitTestLabel':
					break;

				case 'fromEmail':
				case 'fromName':
				case 'subject':
				case 'preheader':
					mailFormEntity.sendSettings[key] = mail[key];
					break;

				case 'withAttachments':
					mailFormEntity.sendSettings.withAttachments =
						mail.withAttachments;
					break;

				case 'replyToName':
				case 'replyToEmail':
					mailFormEntity.sendSettings[key] = mail[key];
					break;

				case 'trackClicks':
					mailFormEntity.trackClicks = mail.trackClicks;
					break;

				case 'manually':
					mailFormEntity.manually = mail.manually;
					break;

				default:
					mailFormEntity[key] = mail[key];
					break;
			}
		});
		return mailFormEntity;
	}) as MailFormEntity[];
}

function getSms(message: SmsEntity[]): SmsFormEntity[] {
	return message.map(sms => ({
		id: sms.id,
		messageId: sms.messageId,
		body: sms.body,
		toShortLink: sms.toShortLink,
		urlParams: sms.urlParams.params,
		utm: sms.urlParams.utm
	}));
}
