import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PopupPreview } from '../misc/abstract-preview';

@Component({
	selector: 'en-popup-preview',
	templateUrl: './popup-preview.component.html',
	styleUrls: ['./popup-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupPreviewComponent extends PopupPreview {}
