import {
	Component,
	ViewChild,
	ChangeDetectionStrategy,
	ElementRef,
	Inject,
	Input,
	Output,
	EventEmitter,
	AfterViewInit,
	OnDestroy
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FORCE_RERENDER_TOKEN } from '@enkod-core/tokens';

import { RappidInterface } from '@enSend/_shared/models';
import { RAPPID_SERVICE_TOKEN } from '@enSend/_shared/tokens/rappid-service-token';

import { BottomBarService } from './services/bottom-bar.service';
import * as actions from '../scenario-canva/rappid/actions';

@UntilDestroy()
@Component({
	selector: 'en-bottom-bar',
	templateUrl: './bottom-bar.component.html',
	styleUrls: ['./bottom-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomBarComponent implements AfterViewInit, OnDestroy {
	scale$ = this.bottomBarService.scaleChanges;

	fileJSON$ = new BehaviorSubject<Object>({});

	@ViewChild('navigator') navigator: ElementRef;

	@Input() template = '';
	@Input() dialog = false;
	@Input() isToggleCursorView = true;
	@Input() isScaleButtonsView = true;
	@Input() isToggleNavigatorView = true;

	@Output() fullScreen = new EventEmitter();

	constructor(
		@Inject(RAPPID_SERVICE_TOKEN)
		private rappid: RappidInterface,
		private bottomBarService: BottomBarService,
		@Inject(FORCE_RERENDER_TOKEN) readonly forceRerender$: Subject<void>
	) {}

	get canva() {
		return this.rappid.canva;
	}

	get isSelectionMod() {
		return this.bottomBarService.isSelectionMod;
	}

	get isNavigator() {
		return this.bottomBarService.navigatorInited$.value;
	}

	ngAfterViewInit() {
		this.bottomBarService.navigatorInited$
			.pipe(
				untilDestroyed(this),
				tap(inited => {
					inited
						? this.rappid.initNavigator!(
								this.navigator.nativeElement
						  )
						: (this.navigator.nativeElement.innerHTML = '');
				})
			)
			.subscribe();
	}

	toggleCursorMod() {
		this.bottomBarService.toggleCursorMod();
	}

	toggleNavigator() {
		this.bottomBarService.toggleNavigator();
	}

	toggleFullScreen() {
		if (this.dialog) {
			this.fullScreen.emit();
		} else {
			actions.toggleFullScreen(this.rappid);
		}
	}

	undo() {
		actions.undoAction(this.rappid);
	}

	redo() {
		actions.redoAction(this.rappid);
	}

	zoomIn() {
		this.rappid.scroller.zoom(0.2, { max: 2 });
	}

	zoomOut() {
		this.rappid.scroller.zoom(-0.2, { max: 2 });
	}

	ngOnDestroy() {
		this.bottomBarService.setDefaultCursor();
	}
}
