import { Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AbstractCondition } from '../../../abstract-condition.component';

@Component({
	selector: 'en-utm-condition',
	templateUrl: './utm-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss']
})
export class UtmConditionComponent extends AbstractCondition {
	readonly optionsOperator: SelectItem[] = [
		{
			label: 'segment_form.options_operator_equal',
			value: 'equal'
		},
		{
			label: 'segment_options.not_equal_options',
			value: 'not_equal'
		},
		{
			label: 'segment_form.cond_utm_contain',
			value: 'contain'
		},
		{
			label: 'segment_options.not_contain_options',
			value: 'not_contain'
		}
	];

	readonly optionsName: SelectItem[] = [
		{ label: 'source', value: 'source' },
		{ label: 'medium', value: 'medium' },
		{ label: 'campaign', value: 'campaign' },
		{ label: 'content', value: 'content' },
		{ label: 'term', value: 'term' }
	];
}
