import {
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { fadeIn, heightCollapse } from '@enkod-core/animations';
import { tuiPure } from '@taiga-ui/cdk';
import { checkUrl } from '../../../../message-wizard/message-wizard-common/step-content/2-step-editor/mobile-push-editor/editor-settings/input-dropdown/validation';
import { MobPushPreview } from '../../../../message-wizard/message-wizard-common/step-content/2-step-editor/mobile-push-editor/mobile-push-editor.model';
import { SelectedViewMobilePush } from '../message-preview.component';

type Preview = {
	subject: string;
	body: string;
	smallImage: string;
	image: string;
	appName: string;
	buttons: string[];
};
@Component({
	selector: 'en-mobile-push-preview',
	templateUrl: './mobile-push-preview.component.html',
	styleUrls: ['./mobile-push-preview.component.scss'],
	animations: [fadeIn, heightCollapse]
})
export class MobilePushPreviewComponent implements OnChanges {
	iosHeightImage = 0;
	preview: Preview;
	view: SelectedViewMobilePush;

	@HostBinding('class._ios')
	isIos: boolean;

	@HostBinding('class._android')
	isAndroid: boolean;

	@HostBinding('class._expanded')
	expanded = false;

	@HostBinding('class._has-buttons')
	get countButtons() {
		return this.preview.buttons.length;
	}

	@ViewChild('imageIos') imageIosRef: ElementRef;

	@Input() placeholder = false;

	@Input('view') set viewSetter(value: SelectedViewMobilePush) {
		this.view = value;
		this.isAndroid = value === 'android';
		this.isIos = value === 'ios';
	}

	@Input('preview') set previewSetter(value: MobPushPreview) {
		this.preview = {
			...value,
			buttons: value.buttons.map(b => b.label)
		};
	}

	get iosSmallImage() {
		// Порядок условий важен, if smallImage должно быть на первом месте
		if (this.preview.smallImage) {
			return this.preview.smallImage;
		}
		if (this.preview.image) {
			return this.preview.image;
		}
		return null;
	}

	get iosImage() {
		// Порядок условий важен, if image должно быть на первом месте
		if (this.preview.image) {
			return this.preview.image;
		}
		if (this.preview.smallImage) {
			return this.preview.smallImage;
		}
		return null;
	}

	get androidSmallImage() {
		return this.preview.smallImage ? this.preview.smallImage : null;
	}

	get androidImage() {
		return this.preview.image ? this.preview.image : null;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.viewSetter) {
			if (this.isIos) this.changeIosImageHight();
			// this.cd.markForCheck();
		}

		if (changes.previewSetter) {
			const {
				currentValue: currentValuePreview,
				previousValue: previousValuePreview,
				firstChange: firstChangePreview
			} = changes.previewSetter;

			// произошло удаление большой картинки
			if (!currentValuePreview?.image && previousValuePreview?.image) {
				this.iosHeightImage = 0;
			}

			// произошло измение количества кнопок
			if (
				currentValuePreview?.buttons.length !==
					previousValuePreview?.buttons.length &&
				!firstChangePreview
			) {
				this.togglePreview(true);
			}
		}
	}

	changeIosImageHight() {
		if (!this.preview.image && !this.preview.smallImage) {
			this.iosHeightImage = 0;
			return;
		}

		if (!this.imageIosRef.nativeElement.complete) return;

		if (this.expanded) {
			const el = this.imageIosRef.nativeElement;
			const realHeight = el.naturalHeight;

			if (realHeight < 360 && realHeight > 200) {
				this.iosHeightImage = 200;
			}
			if (realHeight >= 360) {
				this.iosHeightImage = 360;
			}
			if (realHeight <= 200) {
				this.iosHeightImage = 200;
			}
		} else {
			this.iosHeightImage = 0;
		}
	}

	onErrorLoadIosImage() {
		if (this.expanded && this.iosImage) this.iosHeightImage = 200;
	}

	togglePreview(flag?: boolean) {
		if (flag !== undefined) {
			this.expanded = flag;
		} else this.expanded = !this.expanded;

		if (this.isIos) this.changeIosImageHight();
	}

	@tuiPure
	getValidImgUrl(url: string | null) {
		if (url && !checkUrl(url)) return url;
		return null;
	}
}
