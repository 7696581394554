import { Directive } from '@angular/core';
import {
	MESSAGE_WIZARD_DTO,
	MESSAGE_WIZARD_SERVICE
} from '@enSend/message/message-wizard/kit';
import { MessageWizardService } from '../../message-wizard.service';
import { WizardFormValue } from '../../models';
import { WizardDTO } from '../../wizard-dto';

function wizardDTO(formValue: WizardFormValue): WizardDTO {
	return new WizardDTO(formValue);
}

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[injectorMessageWizardCommon]',
	providers: [
		{ provide: MESSAGE_WIZARD_SERVICE, useExisting: MessageWizardService },
		{ provide: MESSAGE_WIZARD_DTO, useValue: wizardDTO }
	]
})
export class InjectorMessageWizardDirective {
	// constructor() {}
}
