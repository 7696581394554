<ng-container *ngIf="checkValidate$ | async"></ng-container>

<div class="topbar">
    <div class="panel">
        <en-error class="error"
                  [formControl]="nameControl"></en-error>
        <div [class]="isTopBarOpened ? 'topbar__panel topbar__short' : 'topbar__panel_closed'">
            <editable class="panel__group input">
                <ng-template viewMode>
                    <div class="input__wrapper"
                         [class.input_empty]="nameControl.invalid"
                         [class.input_entered]="nameControl.value">
                        <span class="text-overflow">
                            {{nameControl.value || ('chatbot_wizard.name_input' | translate)}}
                        </span>
                    </div>

                </ng-template>

                <ng-template editMode>
                    <input enInput
                           editableOnEnter
                           editableOnEscape
                           editableFocusable
                           class="h32 input_focused"
                           [formControl]="nameControl"
                           [class.input_empty]="nameControl.invalid">
                </ng-template>
            </editable>
            <div class="topbar__group topbar__buttons">
                <button type="button"
                        class="button download"
                        [tippy]="'scenario_wizard_canva.download_image' | translate"
                        [appendTo]="canva"
                        placement="bottom">
                    <span class="en en-download"
                          (click)="download()"></span>
                </button>
                <button type="button"
                        class="button"
                        [tippy]="'scenario_wizard_canva.hot_keys_title' | translate"
                        [appendTo]="canva"
                        placement="bottom"
                        (click)="toggleHotkeys()">
                    <span class="en en-list-o"></span>
                </button>
            </div>
        </div>

        <div class="topbar__group toggle-button"
             [ngClass]="{'toggle-button_closed' : !isTopBarOpened}">
            <button type="button"
                    class="button"
                    (click)="collapseToggleBar()">
                <span [class]="isTopBarOpened
                    ? 'en en-chevron-double-left'
                    : 'en en-chevron-double-right'"></span>
            </button>
        </div>
    </div>
</div>