/* eslint-disable import/no-cycle */
import { Injectable, Injector } from '@angular/core';
import { dia, ui } from '@clientio/rappid';
import {
	EventBusService,
	InspectorService
} from '@enSend/_shared/components/scenario-canva/services';
import { BottomBarService } from '@enSend/_shared/components/bottom-bar/services';
import { CanvaMode } from '@enSend/_shared/models';
import { Events } from 'backbone';

import { Subscription } from 'rxjs';
import { RappidController } from '../rappid/controllers/rappid.controller';
import { createPlugins } from '../rappid/plugins';
import { DialogService } from './dialog.service';
import { DimService } from './dim.service';

@Injectable()
export class PreviewRappidService {
	public controllers: { rappid: RappidController };
	public paper: dia.Paper;
	public canva: Element;
	public scroller: ui.PaperScroller;
	public canvaMode: CanvaMode;

	private subscriptions = new Subscription();

	constructor(
		public readonly eventBusService: EventBusService,
		public readonly inspectorService: InspectorService,
		public readonly bottomBarService: BottomBarService,
		public dialogService: DialogService,
		public dimService: DimService,
		public readonly injector: Injector
	) {
		this.subscriptions.add(
			// Translate RxJx notifications to Backbone Events
			this.eventBusService
				.events()
				.subscribe(({ name, value }) =>
					Events.trigger.call(this.eventBusService, name, value)
				)
		);
	}

	init(paperElement: Element) {
		Object.assign(this, createPlugins(paperElement));

		this.controllers = {
			rappid: new RappidController(this)
		};
	}

	public destroy(): void {
		const { paper, scroller, subscriptions, controllers } = this;
		paper.remove();
		scroller.remove();
		(Object.keys(controllers) as Array<keyof typeof controllers>).forEach(
			name => controllers[name]?.stopListening()
		);
		subscriptions.unsubscribe();
	}
}
