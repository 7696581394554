import {
	Component,
	OnInit,
	AfterViewInit,
	AfterViewChecked,
	OnDestroy,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, finalize, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import {
	ConditionGroupModel,
	ConditionModel,
	Segment,
	SegmentsQuery,
	SegmentsService
} from '@state-enKod/segments';
import { ConditionJsonForm } from '@enKod/segments/segments-form.model';
import { SegmentsFormService } from '@enKod/segments/segments-form.service';

@UntilDestroy()
@Component({
	selector: 'en-segment-selection-modal',
	templateUrl: './segment-selection-modal.component.html',
	styleUrls: ['./segment-selection-modal.component.scss'],
	providers: [SegmentsFormService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentSelectionModalComponent
	implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
	private hasUpdatedTabIndexes = false;

	private treeLoading: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private listLoading: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private searched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	submitted = false;
	isSegmentSelected = false;
	selectedId?: number;
	searchControl = new UntypedFormControl('');
	searchedOptions: SelectItem[] = [];
	segmentsOptionList: SelectItem[] = [];
	conditionJSON?: ConditionGroupModel;
	conditions: ConditionModel[] = [];
	segmentEntity?: Segment;

	treeLoading$: Observable<boolean> = this.treeLoading.asObservable();
	listLoading$: Observable<boolean> = this.listLoading.asObservable();
	searched$: Observable<boolean> = this.searched.asObservable();

	@Input() visible: boolean = false;
	@Output() close = new EventEmitter<void>();
	@Output() select = new EventEmitter<Segment>();

	@ViewChild('enSegmentsTree', { read: ElementRef })
	enSegmentsTree!: ElementRef;

	constructor(
		private formService: SegmentsFormService,
		private segmentsQuery: SegmentsQuery,
		private segmentService: SegmentsService,
		public cd: ChangeDetectorRef
	) {}

	get form() {
		return this.formService.form;
	}

	get nameControl(): FormControl<string> {
		return this.form.controls.name as FormControl<string>;
	}

	get formConditions(): FormGroup<ConditionJsonForm> {
		return this.formService.form.controls
			.conditionJSON as FormGroup<ConditionJsonForm>;
	}

	ngOnInit() {
		this.searchListener();
	}

	ngAfterViewInit() {
		this.getSegments();
	}

	ngAfterViewChecked() {
		if (this.isSegmentSelected && !this.hasUpdatedTabIndexes) {
			this.updateTabIndexes();
			this.hasUpdatedTabIndexes = true;
		}
	}

	closeSelectionModal() {
		this.close.emit();
	}

	confirmSelectionModal() {
		this.select.emit(this.segmentEntity);
	}

	async selectSegment(segment: SelectItem) {
		this.treeLoading.next(true);
		this.selectedId = segment.value.id;
		await this.getSegmentEntity();
		this.isSegmentSelected = true;
		this.initSegmentTree();
		this.hasUpdatedTabIndexes = false;
		this.cd.markForCheck();
	}

	private searchListener() {
		this.searchControl.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(200),
				tap(val => {
					this.searched.next(!!val);
					const lowerVal = val.toLowerCase();
					this.searchedOptions = this.segmentsOptionList.filter(
						segment =>
							segment.label?.toLowerCase().includes(lowerVal) ||
							segment.value?.id?.toString().includes(val)
					);
				})
			)
			.subscribe();
	}

	private getSegments() {
		this.listLoading.next(true);
		this.segmentService
			.requestAllList({ type: 'enSend', withoutTags: true })
			.pipe(
				untilDestroyed(this),
				take(1),
				tap(segments => {
					this.segmentsOptionList =
						segments?.map(segment => ({
							label: segment.name,
							value: { id: segment.id }
						})) ?? [];
				}),
				finalize(() => {
					this.listLoading.next(false);
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private async getSegmentEntity() {
		if (this.selectedId) {
			await this.segmentService.get(this.selectedId);
			this.segmentEntity = this.segmentsQuery.getEntity(this.selectedId);
			this.treeLoading.next(false);
		}
	}

	private initSegmentTree() {
		if (this.selectedId) {
			this.segmentEntity
				? this.formService.fromEntity(this.segmentEntity)
				: this.formService.init();
		}

		if (this.conditionJSON) {
			this.formService.createConditionsFromEntity(this.conditionJSON);
		}
		this.form.markAsUntouched();
		this.cd.markForCheck();
	}

	private updateTabIndexes() {
		if (this.enSegmentsTree) {
			const focusableEls =
				this.enSegmentsTree.nativeElement.querySelectorAll(
					'a[href], area[href], input:not([disabled]), select:not([disabled]), ' +
						'textarea:not([disabled]), button:not([disabled]), [tabindex]:not([tabindex="-1"])'
				);

			focusableEls.forEach((el: HTMLElement) => {
				el.setAttribute('tabindex', '-1');
			});
		}
	}

	ngOnDestroy() {
		this.segmentService.closeCountConnetion();
		this.segmentService.clearSubscribersQuantityCache();
		this.formService.resetSubmitting();
		this.form.reset();
	}
}
