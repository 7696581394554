import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'en-additional-settings',
	templateUrl: './additional-settings.component.html',
	styleUrls: ['./additional-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalSettingsComponent {
	@Input() form: UntypedFormGroup;
	@Input() disabled: boolean;

	get amoAuthEnabledControl() {
		return this.form.controls.amoAuthEnabled;
	}
}
