import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	ActionTimeForm,
	ProductConditionForm
} from '@enKod/segments/segments-form.model';
import { SelectItem } from 'primeng/api';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-product-condition',
	templateUrl: './product-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductConditionComponent extends AbstractCondition {
	readonly optionsParameter: SelectItem[] = [
		{
			label: 'segment_form.cond_product_quantity',
			value: 'quantity'
		},
		{
			label: 'segment_options.with_id',
			value: 'id'
		},
		{
			label: 'segment_form.cond_purchase_stock',
			value: 'atLeastOne'
		}
	];

	readonly optionsPeriod: SelectItem[] = [
		{
			label: 'segment_options.session_options',
			value: 'session'
		},
		{
			label: 'segment_form.options_period_all',
			value: 'all'
		},
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		},
		{
			label: 'segment_form.options_operator_more',
			value: 'more'
		}
	];

	get typedForm(): FormGroup<ProductConditionForm> {
		return this.form as FormGroup<ProductConditionForm>;
	}

	get actionTime(): FormGroup<ActionTimeForm> {
		return this.typedForm.controls.actionTime as FormGroup<ActionTimeForm>;
	}

	get parameterValue(): string {
		return this.typedForm.controls.parameter.value;
	}

	get valueControl() {
		return this.typedForm.controls.value;
	}

	get operatorControl() {
		return this.form.get('operator');
	}

	setValidation() {
		this.getSubscription('parameter')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				switch (value) {
					case 'quantity':
						this.valueControl?.value
							? this.setQuantity('more', this.valueControl?.value)
							: this.setQuantity('more', 3);
						break;
					case 'atLeastOne':
						this.setQuantity('moreOrEqual', 1);
						this.valueControl?.setValue(1);
						break;
					case 'id':
						this.valueControl?.updateValueAndValidity();
						this.typedForm.controls.operator.disable();
						break;
					default:
						break;
				}

				this.markForCheck();
			});
	}

	private setQuantity(operator: string, value: number): void {
		this.typedForm.controls.operator.enable();
		this.typedForm.controls.value.enable();
		this.typedForm.patchValue({ operator, value });
	}
}
