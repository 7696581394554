import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Inject,
	isDevMode
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ID, PaginatorPlugin } from '@datorama/akita';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SelectItemDesctiption } from '@enkod-core/interfaces';
import {
	PAGINATOR_PLUGIN_TOKEN,
	SELECT_OPTIONS_TOKEN
} from '@enkod-core/tokens';
import { MainFormService } from '@enSend/scenario/scenario-wizard/services';
import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';
import { Segment, SegmentsQuery } from '@state-enKod/segments';

import { PaginationService, SearchService } from 'ui-lib';

import { InspectorItemContext } from '../../inspector-item-plugin';
import { DUPLICATE_HANDLING_OPTIONS } from '../mailing-group/constants';
import { AbstractInspectorStart } from '../../abstract/abstract-inspector-start';

export interface SelectedSegment {
	id: ID;
	label: string;
}

@UntilDestroy()
@Component({
	selector: 'en-api-start',
	templateUrl: './api-start.component.html',
	styleUrls: ['./api-start.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: DUPLICATE_HANDLING_OPTIONS
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiStartComponent
	extends AbstractInspectorStart
	implements OnInit
{
	visibleDialogMessage: boolean;
	selectedSegment$ = new BehaviorSubject<SelectedSegment[]>([]);
	generatedUrl$ = new BehaviorSubject<any[]>([]);
	resetValue$ = new Subject();
	scenarioId: number;
	constructor(
		fb: UntypedFormBuilder,
		translate: TranslateService,
		private segmentsQuery: SegmentsQuery,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: SelectItemDesctiption[],
		public paginationService: PaginationService,
		@Inject(PAGINATOR_PLUGIN_TOKEN)
		public readonly paginatorRef: PaginatorPlugin<Segment>,
		private mainFormService: MainFormService,
		private searchService: SearchService
	) {
		super(fb, translate);
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get startApiBlocks() {
		return this.cell.collection.filter(
			cell => cell.get('subType') === 'apiStart'
		);
	}

	ngOnInit() {
		this.segmentsWithoutSiteBehavior();
		this.scenarioId = this.mainFormService.form.value.id;
		this.context$
			.pipe(
				untilDestroyed(this),
				// eslint-disable-next-line no-undef
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;
						const options = this.cell.get('options');
						const subType = this.cell.get('subType');
						const { params } = options[subType];
						this.generatedUrl$.next(
							params.map((option: any) => ({
								blockId: option.blockId,
								label: option.label,
								url: option.url
							})) || []
						);
						this.selectedSegment$.next(options.segment || []);
						this.initDuplicateHandling();
						if (this.isCreationMode) this.updateCell();
					}
				})
			)
			.subscribe();
	}

	/** Передаем параметр withoutSiteBehavior в запрос,
	 *  чтобы бэк отфильтровал и убрал сегменты по поведению пользователя */
	private segmentsWithoutSiteBehavior() {
		this.paginationService.setFilterValue({ type: 'enSend' });
	}

	selectSegment(segment: Segment) {
		const selectedSegment = this.segmentsQuery.getEntity(
			segment.id
		) as Segment;
		const nextSegment: SelectedSegment = {
			id: selectedSegment?.id,
			label: selectedSegment.name
		};
		this.selectedSegment$.next([nextSegment]);
		this.updateCell();
		this.visibleDialogMessage = false;
	}

	private updateCell() {
		const selectedSegment = this.selectedSegment$.getValue();
		this.changeCellProp('options', {
			apiStart: {
				params: this.generatedUrl$.getValue()
			},
			segment: selectedSegment,
			duplicateHandling: this.duplicateHandling.value
		});
	}

	removeSegment() {
		this.selectedSegment$.next([]);
		this.changeCellProp('options', {
			apiStart: {
				params: this.generatedUrl$.getValue()
			},
			duplicateHandling: this.duplicateHandling.value
		});
	}

	generateUrl() {
		const id = this.getBlockId();
		const devUrl = isDevMode() ? 'dev.' : '';
		const generatedUrl = [
			{
				blockId: id,
				label: `...event/start/${this.scenarioId}/${id}/`,
				url: `https://${devUrl}api.enkod.ru/v1/event/start/${this.scenarioId}/${id}/`
			}
		];
		this.changeCellProp(['options', 'apiStart'], { params: generatedUrl });
		this.generatedUrl$.next(generatedUrl);
	}

	getBlockId(id: number = 0): number {
		let increment = id;
		const blocks = this.startApiBlocks.filter(
			block =>
				block.get('options').apiStart?.params[0]?.blockId === increment
		);

		if (blocks.length) {
			return this.getBlockId(++increment);
		}
		return increment;
	}

	onDialogHide() {
		this.visibleDialogMessage = false;

		this.searchService.setSearchValue('');
		this.resetValue$.next();
		this.paginationService.setPerPageValue(10);
		this.paginatorRef.setFirstPage();
	}
}
