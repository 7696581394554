import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	ChangeDetectorRef
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ID } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { debounceTime } from 'rxjs/operators';

import {
	FileAttachment,
	MessagesService
} from '@enSend/message/_states/_state-message';
import { NotificationsService } from '@enkod-core/services';
import { NotificationStatus } from 'ui-lib';
import { StepConditionService, StepSplitTestService } from '../../services';
import { SendTestService } from './send-test.service';

import { Params, TestMail, Utm } from '../../models';
import { TestSnippetsFormService } from './test-snippets/test-snippets-form.service';

interface FormMessage {
	id: ID;
	messageId: ID;

	sendSettings: SendSettings;
	replyToName?: string;
	replyToEmail?: string;
	plainText: string;
	html: string;
	amp: string;
	trackClicks: boolean;

	urlParams: Params;
	utm: Utm;
	attachments: FileAttachment[];
}

interface SendSettings {
	fromEmail: string;
	fromName: string;
	subject: string;
	preheader: string;
	withAttachments: boolean;
	replyToEmail: string;
	replyToName: string;
}
@UntilDestroy()
@Component({
	selector: 'en-send-test',
	templateUrl: './send-test.component.html',
	styleUrls: ['./send-test.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendTestComponent implements OnInit, OnDestroy {
	readonly isLoading$ = this.messageService.loading$;
	constructor(
		private conditionService: StepConditionService,
		private sendTestService: SendTestService,
		private messageService: MessagesService,
		private snippetFormService: TestSnippetsFormService,
		private notificationsService: NotificationsService,
		private cd: ChangeDetectorRef,
		private stepSplitTestService: StepSplitTestService
	) {}

	@Input() readonly showTest: boolean;

	@Output() closeTest = new EventEmitter();

	index = 0;

	snippetsSet: Set<string>;

	get tabsShowCondition(): boolean {
		return this.conditionService.sendingType === 'api';
	}

	get htmlControl(): UntypedFormControl {
		return this.stepSplitTestService.formMessage?.get(
			'html'
		) as UntypedFormControl;
	}

	get plainTextControl(): UntypedFormControl {
		return this.stepSplitTestService.formMessage?.get(
			'plainText'
		) as UntypedFormControl;
	}

	get formMessageValue(): FormMessage {
		return this.stepSplitTestService.formMessage?.value;
	}

	get isApi(): boolean {
		return (
			this.conditionService.stepCondition?.get('sendingType')?.value ===
			'api'
		);
	}

	get warnAlert(): boolean {
		return this.snippetFormService.warnAlert;
	}

	get emptyEmailsInput(): boolean {
		return (
			!this.sendTestService.emailsInput.value ||
			this.sendTestService.emailsInput.value.length === 0
		);
	}

	get fromEmailValue(): string {
		return this.formMessageValue.sendSettings.fromEmail;
	}

	ngOnInit(): void {
		this.snippetFormService.findSnippets();
		this.setupFormListeners();
	}

	tabChange(tabIndex: number) {
		this.index = tabIndex;
	}

	private setupFormListeners() {
		this.htmlControl?.valueChanges
			.pipe(untilDestroyed(this), debounceTime(500))
			.subscribe(() => {
				this.snippetFormService.findSnippets();
				this.cd.markForCheck();
			});

		this.plainTextControl?.valueChanges
			.pipe(untilDestroyed(this), debounceTime(500))
			.subscribe(() => {
				this.snippetFormService.findSnippets();
				this.cd.markForCheck();
			});
	}

	sendTest() {
		if (this.sendTestService.minLength()) return;

		if (this.isApi && this.showAlert()) return;

		const testData: TestMail = {
			messageId: this.formMessageValue?.messageId,
			name: this.formMessageValue?.sendSettings.subject,
			fromName: this.formMessageValue?.sendSettings.fromName,
			fromEmail: this.formMessageValue?.sendSettings.fromEmail,
			subject: this.formMessageValue?.sendSettings.subject,
			preheader: this.formMessageValue.sendSettings.preheader,
			withAttachments: this.formMessageValue.sendSettings.withAttachments,
			plainText: this.formMessageValue?.plainText,
			html: this.formMessageValue?.html,
			amp: this.formMessageValue?.amp,
			trackClicks: this.formMessageValue?.trackClicks,
			urlParams: {
				params: this.formMessageValue?.urlParams,
				utm: this.formMessageValue?.utm
			},
			toEmails: [...this.sendTestService?.emailsInput.value],
			replyToName: this.formMessageValue?.sendSettings?.replyToName || '',
			replyToEmail:
				this.formMessageValue?.sendSettings?.replyToEmail || '',
			...(this.isApi && {
				snippets: this.removeEmptySnippets()
			}),
			fileAttachments: this.formMessageValue?.attachments?.length > 0,
			testingMailId: this.formMessageValue?.id
		};

		this.messageService
			.sendTestMail(testData)
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.closeTest.emit();
				this.notificationsService
					.show('message_wizard_edit_step.test_email_sent', {
						label: 'toast.success',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			});
	}

	private removeEmptySnippets(): { [key: string]: string } {
		const filtredSnippets = {};
		const { snippetsValue } = this.snippetFormService;
		Object.entries(snippetsValue).forEach(([key, value]) => {
			if (value) {
				Object.assign(filtredSnippets, { [key]: value });
			}
		});
		return filtredSnippets;
	}

	testClose(): void {
		this.sendTestService.errorMessage$.next('');
		this.index = 0;
		this.cd.markForCheck();
		this.closeTest.emit();
	}

	showAlert(): boolean {
		let isShown = false;
		if (this.snippetFormService.snippets.length) {
			if (!this.warnAlert && this.snippetFormService.isEmptySnippets()) {
				this.snippetFormService.warnAlert = true;
				this.index = 1;
				isShown = true;
			}
		}
		return isShown;
	}

	ngOnDestroy() {
		this.sendTestService.emailsInput.setValue([]);
		this.sendTestService.mailsList.clear();
		this.snippetFormService.snippets = [];
		if (this.isApi) {
			this.snippetFormService.snippetForm.get('snippets')?.setValue({});
		}
	}
}
