import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { markAllAsDirty } from '@enkod-core/utils';
import { ConditionGroupModel, ConditionModel } from '@state-enKod/segments';
import { NotificationStatus } from 'ui-lib';
import { NotificationsService } from '@enkod-core/services';
import { checkGroupEmpty, checkValidateConditions } from './utils';
import { ConditionJsonForm, SegmentForm } from './segments-form.model';

@Injectable({
	providedIn: 'root'
})
export class SegmentsValidateService {
	clearValidationMsgs$: Subject<void> = new Subject();
	resetInvalidMark$: Subject<void> = new Subject();

	private forceValidate$: Subject<void> = new Subject();

	constructor(private notificationsService: NotificationsService) {}

	checkValidate() {
		this.forceValidate$.next();
	}

	get checkedValidate() {
		return this.forceValidate$.asObservable();
	}

	checkAndSetValidationCount(form: FormGroup<SegmentForm>) {
		const conditionJSON = form.controls
			.conditionJSON as FormGroup<ConditionJsonForm>;

		switch (true) {
			case checkGroupEmpty(conditionJSON.value as ConditionGroupModel):
				markAllAsDirty(conditionJSON);
				this.notificationsService
					.show('contacts_count_list.toast_error_empty_segment', {
						label: 'contacts_count_list.toast_error_title',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return true;

			case this.isEnPopSegment(
				conditionJSON.value as ConditionGroupModel
			):
				this.notificationsService
					.show('contacts_count_list.toast_error_enpop_condition', {
						label: 'contacts_count_list.toast_error_title',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return true;

			case checkValidateConditions(conditionJSON):
				markAllAsDirty(conditionJSON as FormGroup<ConditionJsonForm>);
				form.markAllAsTouched();
				this.notificationsService
					.show('contacts_count_list.toast_error_invalid_condition', {
						label: 'contacts_count_list.toast_error_title',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return true;

			default:
				return false;
		}
	}

	public isEnPopSegment(conditionJSON: ConditionGroupModel): boolean {
		const errorTypes: string[] = [];

		const enPopTypes: string[] = [
			'html',
			'utm',
			'url',
			'device',
			'time',
			'scroll',
			'referrers',
			'close'
		];

		const conditions = (conditionArr: ConditionModel[]) => {
			conditionArr.some((item: ConditionModel) => {
				if (item.type && enPopTypes.includes(item.type)) {
					errorTypes.push(item.type);
					return true;
				}
				return false;
			});
		};

		const inners = (innerArr: ConditionGroupModel[]) => {
			innerArr.forEach((item: ConditionGroupModel) => {
				conditions(item.conditions);
				if (item.inners.length) inners(item.inners);
			});
		};

		conditions(conditionJSON.conditions);
		inners(conditionJSON.inners);

		return !!errorTypes.length;
	}
}
