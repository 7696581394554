import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	EnButtonModule,
	EnDropdownCalendarNewModule,
	EnDropdownCalendarRangeModule,
	EnDropdownModule,
	EnInputModule,
	EnMultilevelDropdownModule,
	EnSliderModule,
	RadioButtonModule
} from 'ui-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { TextMaskModule } from 'angular2-text-mask';
import { TuiLetModule } from '@taiga-ui/cdk';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SharedModule } from '@shared';
import { NumberRangeDirectiveModule } from '@enkod-core/directives';
import { ChosenItemInfoModule } from '../../components/chosen-item-info/chosen-item-info.module';
import { DistributionComponent } from './distribution.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { DistributionService } from './distribution.service';
import {
	ChooseSegmentsDialogModule,
	SegmentsTreeDialogModule
} from './dialogs';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		RadioButtonModule,
		EnButtonModule,
		ChooseSegmentsDialogModule,
		SegmentsTreeDialogModule,
		TuiLetModule,
		ChosenItemInfoModule,
		EnInputModule,
		NumberRangeDirectiveModule,
		KeyFilterModule,
		EnDropdownModule,
		EnDropdownCalendarNewModule,
		EnDropdownCalendarRangeModule,
		EnMultilevelDropdownModule,
		IMaskModule,
		TextMaskModule,
		EnSliderModule
	],
	declarations: [DistributionComponent],
	exports: [DistributionComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: DistributionService,
			multi: true
		}
	]
})
export class DistributionModule {}
