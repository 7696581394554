import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { NotificationsService, UrlSaverService } from '@enkod-core/services';
import { NotificationStatus } from 'ui-lib';
import { Observable, of, throwError } from 'rxjs';
import {
	catchError,
	switchMap,
	tap,
	finalize,
	map,
	shareReplay
} from 'rxjs/operators';
import { SqlTemplate } from './sql-template.model';
import { SqlTemplatesDataService } from './sql-templates-data.service';
import { SqlTemplatesStore } from './sql-templates.store';

@Injectable({ providedIn: 'root' })
export class SqlTemplatesService {
	constructor(
		private store: SqlTemplatesStore,
		private dataService: SqlTemplatesDataService,
		private notificationsService: NotificationsService,
		private urlSevice: UrlSaverService
	) {}

	private listResult: Observable<SqlTemplate[]> | null = null;

	getList({ page, sort, perPage, search, filter }: any) {
		return this.dataService
			.list({
				limit: perPage,
				offset: (page - 1) * perPage,
				sort,
				search,
				...filter
			})
			.pipe(
				tap(() => {
					this.urlSevice.setParamsToUrl(
						{
							sortKey: sort?.field,
							sortOrder: sort?.order,
							page,
							perPage,
							search
						},
						{
							...filter
						}
					);
				}),
				switchMap(resp => {
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: Math.ceil(resp.total / perPage) || 1,
						data: resp.result ? [...resp.result] : []
					});
				}),
				catchError(() => {
					this.notificationsService
						.show('sql_list.toast_error_get_list', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: 1,
						data: []
					});
				})
			);
	}

	getListResult(params: any = {}): Observable<SqlTemplate[]> {
		if (!this.listResult)
			this.listResult = this.dataService.list(params).pipe(
				map(resp => resp.result),
				shareReplay(1)
			);
		return this.listResult;
	}

	get(id: ID) {
		this.store.setLoading(true);
		return this.dataService.detail(id).pipe(
			tap(entity => {
				this.store.upsert(entity.id, entity);
			}),
			catchError(error => {
				this.notificationsService
					.show('sql_list.toast_error_get_entity', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	create(entity: SqlTemplate): Observable<SqlTemplate> {
		this.store.setLoading(true);
		return this.dataService.create(entity).pipe(
			tap(resp => {
				this.store.add(resp);
				this.notificationsService
					.show('sql_create.toast_succes_create_entity', {
						label: 'toast.created',
						status: NotificationStatus.SUCCESS,
						params: { id: resp.id, name: resp.name }
					})
					.subscribe();
			}),
			catchError(error => {
				this.notificationsService
					.show('sql_create.toast_error_create_entity', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	update(entity: SqlTemplate) {
		this.store.setLoading(true);
		return this.dataService.update(entity).pipe(
			tap(resp => {
				this.store.update(resp.id, resp);
				this.notificationsService
					.show('sql_create.toast_succes_edit_entity', {
						label: 'toast.edited',
						status: NotificationStatus.SUCCESS,
						params: { id: resp.id, name: resp.name }
					})
					.subscribe();
			}),
			catchError(error => {
				this.notificationsService
					.show('sql_create.toast_error_edit_entity', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();

				return throwError(error);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	delete(entity: SqlTemplate) {
		return this.dataService.remove(entity.id).pipe(
			tap(() => {
				this.store.remove(entity.id);
				this.notificationsService
					.show('sql_create.toast_succes_delete_entity', {
						label: 'toast.deleted',
						status: NotificationStatus.SUCCESS,
						params: {
							id: entity.id,
							name: entity.name
						}
					})
					.subscribe();
			}),
			catchError(error => {
				this.notificationsService
					.show('sql_create.toast_error_delete_entity', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	clearSqlCache(): void {
		this.listResult = null;
	}
}
