<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">

    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsCategoryMessage"
                 [placeholder]="'segment_form.cond_message_empty' | translate"
                 [formControl]="form.get('message.action')"
                 (onChange)="actionChange($event.value)"></en-dropdown>
    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="parameterOptions"
                 formControlName="parameter"
                 (onChange)="parameterChange()"></en-dropdown>

    <!-- Любое сообщение -->
    <ng-container *ngIf="!isSpecificMessageParameter">
        <en-dropdown class="input__small"
                     styleClass="en-dropdown-solid"
                     [class.active-hide]="showHide"
                     [options]="optionsChannel$ | async"
                     [formControl]="form.get('message.channel')"
                     (onChange)="chooseChannel($event.value)"></en-dropdown>
    </ng-container>

    <!-- Выбор конкретного сообщения  -->
    <ng-container *ngIf="isSpecificMessageParameter">
        <ng-container *ngIf="!isMessageSelected">
            <button type="button"
                    enButton
                    class="h32 en-button-secondary"
                    [class.highlight]="true"
                    [class.active-hide]="showHide"
                    [label]="'segment_common.pick_message' | translate"
                    (click)="openDialogMessage()">
            </button>
        </ng-container>
        <ng-container *ngIf="
                    isMessageSelected">
            <en-selected-message-item [id]="specificIdControl?.value"
                                      [class.active-hide]="showHide"
                                      [name]="specificNameControl?.value"
                                      (remove)="removeSelectedMessage()">
            </en-selected-message-item>
        </ng-container>
    </ng-container>
    <!-- допусловие по условию отправки -->
    <div [class.hideMargin]="!messageValue.sendingType"
         [class.active-hide]="showHide">
        <en-message-add-type *ngIf="messageValue.sendingType"
                             [channelType]="messageForm?.get('channel').value"
                             [formControl]="messageForm?.get('sendingType')"
                             (remove)="deleteSendingType()"></en-message-add-type>
    </div>
    <!-- допусловие по тегам -->
    <div [class.hideMargin]="!messageTagsControl?.enabled"
         [class.active-hide]="showHide">
        <en-message-add-tag *ngIf="messageTagsControl?.enabled"
                            [tagsControl]="messageTagsControl"
                            [isPreview]="isPreview"
                            [isActionBounce]="isActionBounce"
                            [markAsInvalid]="markTagAsInvalid"
                            (remove)="deleteTags()"></en-message-add-tag>
    </div>
    <!-- условие причины баунса -->
    <div [class.hideMargin]="!messageValue.bounceType"
         [class.active-hide]="showHide">
        <en-message-add-bounce *ngIf="messageValue.bounceType"
                               [formControl]="messageForm.get('bounceType')"
                               [channelType]="messageForm?.get('channel').value"
                               (remove)="removeBounceType()"></en-message-add-bounce>
    </div>
    <!-- допусловие по количеству -->
    <div [class.hideMargin]="!messageValue.actionCount"
         [class.active-hide]="showHide">
        <en-message-add-count *ngIf="messageValue.actionCount"
                              [form]="form.get('message.actionCount')"
                              [isShowRemoveButton]="true"
                              (remove)="deleteCount()"></en-message-add-count>
    </div>
    <!-- допусловие по времени -->
    <div [class.hideMargin]="!messageValue.actionTime"
         [class.active-hide]="showHide">
        <en-action-time *ngIf="messageValue.actionTime"
                        [timeForm]="messageForm.get('actionTime')"
                        [isShowRemoveButton]="true"
                        [isHide]="isHideParent || isHide"
                        [period]="'past'"
                        (remove)="deleteTime()"></en-action-time>
    </div>
    <!-- допусловие по ссылке -->

    <div [class.hideMargin]="!messageValue.actionLink"
         [class.active-hide]="showHide">
        <en-message-add-link *ngIf="messageValue.actionLink"
                             [form]="form.get('message')"
                             [isShowRemoveButton]="true"
                             [isHide]="isHideParent || isHide"
                             (remove)="deleteActionLink()"></en-message-add-link>
    </div>


    <!-- допусловие по uniq messages -->
    <div [class.hideMargin]="!messageValue.onlyUniqMessage">
        <en-add-only-uniq-message *ngIf="messageValue.onlyUniqMessage"
                                  (remove)="deleteUniqMessages()">
        </en-add-only-uniq-message>
    </div>

    <!-- добавление допусловия -->
    <en-add-condition-button [class.active-hide]="showHide"
                             [parameterControl]="form.get('parameter')"
                             [messageGroup]="form.get('message')"
                             [itemHasBeenDeleted$]="itemHasBeenDeleted$"
                             [showLinksOption]="showLinksOption"
                             (onItemClick)="addAdditionalCondition($event)"></en-add-condition-button>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>

    <!-- Диалог: Список сообщений -->
    <en-dialog [header]="'message_list.nav_title' | translate"
               [maximizable]="true"
               [style]="{ minWidth: '50rem' }"
               styleClass="p-dialog--lg"
               [modal]="true"
               [(visible)]="visibleDialogMessage"
               appendTo="body"
               (onHide)="closeDialogMessage()">
        <form class="dialog-form" [formGroup]="searchForm">
            <div class="search">
                <en-search [placeholder]="'message_list.search_placeholder' | translate"
                           styleClass="h32"
                           formControlName="searchControl"
                           [refresher]="false"></en-search>

                <section class="tab">
                    <p-tabView [(activeIndex)]="activeIndex">
                        <p-tabPanel [header]="'message_list.tab_header_link_message' | translate">
                        </p-tabPanel>
                        <p-tabPanel [header]="'message_list.tab_header_link_campaing' | translate">
                        </p-tabPanel>
                    </p-tabView>
                </section>

            </div>
            <tui-scrollbar>
                <div class="table-wrapper">
                    <en-message-table *ngIf="visibleDialogMessage"
                                      [tab]="tabs[activeIndex]"
                                      [hideFilters]="['channel']"
                                      [customChannelOptions]="channelOptions"
                                      [notSaveInUrl]="true"
                                      [toolsPanel]="false"
                                      [previewLink]="false"
                                      class="table__message"
                                      [enReorder]="false"
                                      [selection]="true"
                                      (selected)="selectMessage($event)">
                        <ng-template enTemplate="tools">
                        </ng-template>
                    </en-message-table>
                </div>
            </tui-scrollbar>
        </form>
    </en-dialog>
</form>
