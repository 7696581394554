import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';

export const SEND_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_message_options.bounce_option',
		value: 'bounceType'
	},
	{
		label: 'segment_message_options.time_option',
		value: 'actionTime'
	},
	{
		label: 'segment_message_options.count_option',
		value: 'actionCount'
	},
	{
		label: 'segment_message_options.condition_option',
		value: 'sendingType'
	},
	{
		label: 'segment_message_options.tag_option',
		value: 'tag_items'
	}
];

export const SEND_SMS_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_message_options.time_option',
		value: 'actionTime'
	},
	{
		label: 'segment_message_options.count_option',
		value: 'actionCount'
	},
	{
		label: 'segment_message_options.condition_option',
		value: 'sendingType'
	},
	{
		label: 'segment_message_options.tag_option',
		value: 'tag_items'
	}
];

export const NOT_OPEN_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_message_options.bounce_option',
		value: 'bounceType'
	},
	{
		label: 'segment_message_options.open_time_option',
		value: 'actionTime'
	},
	{
		label: 'segment_message_options.open_count_option',
		value: 'actionCount'
	},
	{
		label: 'segment_message_options.condition_option',
		value: 'sendingType'
	},
	{
		label: 'segment_message_options.tag_option',
		value: 'tag_items'
	}
];

export const OPEN_OPTIONS: MultiMenuItem[] = [
	...NOT_OPEN_OPTIONS,

	{
		label: 'segment_message_options.uniq_messages_option',
		value: 'onlyUniqMessage'
	}
];

export const CLICK_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_message_options.bounce_option',
		value: 'bounceType'
	},
	{
		label: 'segment_message_options.click_time_option',
		value: 'actionTime'
	},
	{
		label: 'segment_message_options.click_count_option',
		value: 'actionCount'
	},
	{
		label: 'segment_message_options.link_click_option',
		value: 'actionLink'
	},
	{
		label: 'segment_message_options.condition_option',
		value: 'sendingType'
	},
	{
		label: 'segment_message_options.tag_option',
		value: 'tag_items'
	}
];

export const READ_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_message_options.read_time_option',
		value: 'actionTime'
	},
	{
		label: 'segment_message_options.tag_option',
		value: 'tag_items'
	}
];
