import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { TuiMapper } from '@taiga-ui/cdk';
import { SubscriptionForm } from '@enKod/segments/segments-form.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	MailingGroupsService,
	MailingGroupsStore
} from '@state-enKod/mailing-groups';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { ID } from '@datorama/akita';
import { SelectItemValue, SelectItemWithDeleted } from '@state-enKod/segments';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-subscription-condition',
	templateUrl: './subscription-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss']
})
export class SubscriptionConditionComponent
	extends AbstractCondition
	implements OnInit, OnDestroy
{
	readonly subsctiptionSelectItem$: Observable<SelectItem[]>;

	selectItems: SelectItem[];

	markAsInvalid = false;

	constructor(
		private mailingGroupsService: MailingGroupsService,
		private mailingGroupsStore: MailingGroupsStore,
		public validateServices: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(validateServices, cd);
	}

	get typeForm(): FormGroup<SubscriptionForm> {
		return this.form as FormGroup<SubscriptionForm>;
	}

	get valueControl(): FormControl<
		SelectItemWithDeleted[] | SelectItemValue[]
	> {
		return this.typeForm.controls.value as FormControl<
			SelectItemWithDeleted[] | SelectItemValue[]
		>;
	}

	get value() {
		return this.valueControl.value;
	}

	ngOnInit(): void {
		this.setListeners();
	}

	initSort(): void {
		(this.value as SelectItemValue[]).forEach((item: SelectItemValue) => {
			this.selectItems = this.selectItems.sort((a, b) => {
				if (a.value.id === item.id) return -1;
				return b.value.id === item.id ? 1 : 0;
			});
		});
	}

	sortedGroups(group: SelectItem[]) {
		return group.sort((a, b) => {
			if (a.value.id > b.value.id) return -1;
			if (a.value.id < b.value.id) return 1;
			return 0;
		});
	}

	deselectItem(index: number): void {
		const arrCopy = [...this.value];
		arrCopy.splice(index, 1);
		this.valueControl.patchValue(arrCopy as SelectItemValue[]);
	}

	mapMultiSelect: TuiMapper<SelectItemValue<number>, any> = (
		item,
		groups: SelectItemWithDeleted<number>[]
	) => groups?.find(group => group.value.id === item.id);

	private setListeners() {
		this.mailingGroupsService
			.getAllList()
			.pipe(
				untilDestroyed(this),
				map(list =>
					list.map(item => ({
						label: item.name,
						value: {
							id: item.id,
							name: item.name,
							channel: item.channel,
							isDeleted: item.isDeleted
						}
					}))
				),
				map(list => {
					const deletedItems: SelectItemWithDeleted<ID>[] = [];
					(this.value as SelectItemValue[])?.forEach(
						(item: SelectItemValue) => {
							if (item.isDeleted) {
								deletedItems.push({
									label: item.name,
									value: {
										id: item.id,
										name: item.name,
										channel: item.channel,
										isDeleted: item.isDeleted
									},
									disabledItem: item.isDeleted
								});
							}
						}
					);

					return [...deletedItems, ...list];
				})
			)
			.subscribe(list => {
				this.selectItems = this.sortedGroups(list);
				this.cd.markForCheck();
			});

		this.validateServices.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.form.markAsPristine();
						this.markAsInvalid = false;
					} else {
						this.markAsInvalid = !this.value.length;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.validateServices.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.markAsInvalid = false;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.valueControl?.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (value.length) this.markAsInvalid = false;
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.mailingGroupsStore.reset();
	}
}
