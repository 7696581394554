import {
	Component,
	ChangeDetectorRef,
	OnInit,
	ChangeDetectionStrategy
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map, startWith, tap, debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnkodTagsDataService } from '@enKod/tags/services/enkod-tags-data.service';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { SelectItemValue } from '@state-enKod/segments';
import { TagsValue } from '@enkod-core/enums';
import { TagConditionForm } from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-tag-condition',
	templateUrl: './tag-condition.component.html',
	providers: [EnkodTagsDataService],
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagConditionComponent extends AbstractCondition implements OnInit {
	selectItems = this.tagService.getAll().pipe(
		untilDestroyed(this),
		map(list =>
			list
				? list
						.filter(tag => tag.name !== TagsValue.SPLIT_TEST)
						.sort(tag => (tag.name === TagsValue.BOT ? -1 : 0))
						.map(item => ({
							label: item.name,
							value: {
								id: item.id,
								name: item.name
								// isDeleted: item.isDeleted
							}
						}))
				: []
		)
	);

	markAsInvalid = false;

	constructor(
		private tagService: EnkodTagsDataService,
		public validateServices: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(validateServices, cd);
	}

	get valueControl(): FormControl<SelectItemValue[]> {
		return (this.form as FormGroup<TagConditionForm>).controls
			.value as FormControl<SelectItemValue[]>;
	}

	get value() {
		return this.valueControl.value;
	}

	ngOnInit(): void {
		this.setListeners();
	}

	deselectItem(index: number): void {
		const arrCopy = [...this.value];
		arrCopy.splice(index, 1);
		this.valueControl.patchValue(arrCopy);
	}

	private setListeners() {
		this.validateServices.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.markAsInvalid = false;
					} else {
						this.markAsInvalid = !this.value.length;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.valueControl?.valueChanges
			.pipe(
				untilDestroyed(this),
				startWith(),
				tap(value => {
					if (value.length) this.markAsInvalid = false;
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.segmentsValidateService.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.markAsInvalid = false;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}
}
