import {
	AfterViewInit,
	Directive,
	ElementRef,
	HostListener,
	Inject,
	Input,
	Renderer2
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from 'utils';

@Directive({
	selector: '[enDynamicWidth]'
})
export class EnDynamicWidthDirective implements AfterViewInit {
	private readonly DEFAULT_UNIT = 'rem';
	private readonly DEFAULT_MIN_WIDTH = 3.75;
	private readonly DEFAULT_MAX_WIDTH = 25;
	private readonly SPINNER_WIDTH_PX = 16;
	private readonly PADDING_RIGHT = 0.25;

	private canvas!: HTMLCanvasElement;
	private context!: CanvasRenderingContext2D;

	private minWidth: string = `${this.DEFAULT_MIN_WIDTH}${this.DEFAULT_UNIT}`;
	private maxWidth: string = `${this.DEFAULT_MAX_WIDTH}${this.DEFAULT_UNIT}`;

	private computedFont!: string;
	private padding!: number;
	private borderWidth!: number;
	private unit!: string;
	private conversionFactor!: number;
	private minWidthValuePx!: number;
	private maxWidthValuePx!: number;

	/**
	 * Ширина инпута задается строкой в формате "minWidth maxWidth".
	 * Допустимые единицы измерения: px и rem.
	 * Есть дефолтные значения в 3.75rem и 25rem.
	 * Примеры: 	enDynamicWidth
	 * 						enDynamicWidth="15rem"
	 * 						enDynamicWidth="5rem 30rem"
	 * 						enDynamicWidth="100px 500px"
	 */
	@Input('enDynamicWidth')
	widthParams: string = `${this.minWidth} ${this.maxWidth}`;

	@HostListener('input')
	onInput(): void {
		this.updateWidth();
	}

	constructor(
		private el: ElementRef<HTMLInputElement>,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(WINDOW) private readonly window: Window
	) {}

	ngAfterViewInit(): void {
		this.initCanvas();
		if (this.el.nativeElement.type === 'number') this.setSpinnerPadding();
		this.setWidthParams();
		this.cacheComputedStyles();
		this.updateWidth();
	}

	private initCanvas(): void {
		this.canvas = this.renderer.createElement('canvas');
		const context = this.canvas.getContext('2d');
		if (context) this.context = context;
	}

	private setSpinnerPadding(): void {
		const unit = this.getUnitFromParams();

		const paddingRight =
			unit === 'px'
				? `${this.PADDING_RIGHT * this.getConversionFactor('rem')}px`
				: `${this.PADDING_RIGHT}rem`;

		this.renderer.setStyle(
			this.el.nativeElement,
			'paddingRight',
			paddingRight
		);
	}

	private getUnitFromParams(): string {
		const [minValue] = this.widthParams.split(' ').map(p => p.trim());
		return this.getUnit(minValue);
	}

	private setWidthParams(): void {
		const params = this.widthParams
			.split(' ')
			.slice(0, 2)
			.map(p => p.trim());

		this.minWidth = this.getWidth(
			params[0],
			`${this.DEFAULT_MIN_WIDTH}${this.DEFAULT_UNIT}`
		);
		this.maxWidth = this.getWidth(
			params[1],
			`${this.DEFAULT_MAX_WIDTH}${this.DEFAULT_UNIT}`
		);

		const minWidthValue = parseFloat(this.minWidth);
		const maxWidthValue = parseFloat(this.maxWidth);

		this.unit = this.getUnit(this.minWidth);
		this.conversionFactor = this.getConversionFactor(this.unit);
		this.minWidthValuePx = minWidthValue * this.conversionFactor;
		this.maxWidthValuePx = maxWidthValue * this.conversionFactor;
	}

	private getParsedValue(
		value: string | undefined
	): { numericValue: string; unit: string } | null {
		const match = value?.match(/^(\d*\.?\d+)(px|rem)?$/);
		if (match) {
			const [, numericValue, unit] = match;
			return { numericValue, unit: unit || this.DEFAULT_UNIT };
		}
		return null;
	}

	private getWidth(value: string | undefined, defaultValue: string): string {
		const parsed = this.getParsedValue(value);
		return parsed ? `${parsed.numericValue}${parsed.unit}` : defaultValue;
	}

	private getUnit(value: string): string {
		const parsed = this.getParsedValue(value);
		return parsed ? parsed.unit : this.DEFAULT_UNIT;
	}

	private cacheComputedStyles(): void {
		const computedStyle = this.window.getComputedStyle(
			this.el.nativeElement
		);
		this.computedFont = computedStyle.font;
		this.padding = this.calculatePadding(computedStyle);
		this.borderWidth = this.calculateBorderWidth(computedStyle);
	}

	private updateWidth(): void {
		const inputElement = this.el.nativeElement;

		this.renderer.setStyle(inputElement, 'minWidth', this.minWidth);

		const { value } = inputElement;
		const spinnerWidth =
			inputElement.type === 'number' ? this.SPINNER_WIDTH_PX : 0;

		const additionalWidth = this.padding + this.borderWidth + spinnerWidth;
		const inputTextWidth =
			this.getTextWidth(value, this.computedFont) + additionalWidth;

		const newWidthPx = Math.max(
			this.minWidthValuePx,
			Math.min(inputTextWidth, this.maxWidthValuePx)
		);

		const newWidth = newWidthPx / this.conversionFactor;

		this.renderer.setStyle(
			inputElement,
			'width',
			`${newWidth}${this.unit}`
		);
	}

	private calculatePadding(computedStyle: CSSStyleDeclaration): number {
		const paddingLeft = parseFloat(computedStyle.paddingLeft);
		const paddingRight = parseFloat(computedStyle.paddingRight);
		return paddingLeft + paddingRight;
	}

	private calculateBorderWidth(computedStyle: CSSStyleDeclaration): number {
		const borderLeft = parseFloat(computedStyle.borderLeftWidth);
		const borderRight = parseFloat(computedStyle.borderRightWidth);
		return borderLeft + borderRight;
	}

	private getTextWidth(text: string, font: string): number {
		if (!this.context) return 0;
		this.context.font = font;
		return this.context.measureText(text).width;
	}

	private getConversionFactor(unit: string): number {
		const rootFontSize = parseFloat(
			this.window.getComputedStyle(this.document.documentElement).fontSize
		);

		switch (unit) {
			case 'rem':
				return rootFontSize;
			case 'px':
			default:
				return 1;
		}
	}
}
