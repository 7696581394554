import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	ViewChild
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ID } from '@datorama/akita';
import { UrlSaverService } from '@enkod-core/services';
import {
	MESSAGES_TAB_TOKEN,
	MessageType,
	MessagesTab
} from '@enSend/message/kit';
import {
	ActionLinkForm,
	MessageConditionForm,
	MessageFullBodyForm,
	TimeRangeForm
} from '@enKod/segments/segments-form.model';
import { MESSAGE_LIST_TABS_PROVIDER } from '@enSend/message/message-list/providers';
import { SearchService } from 'ui-lib';
import { Message } from '@enSend/message/_states/_state-message';
import { SegmentsFormService } from '@enKod/segments/segments-form.service';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { Tag } from '@state-enKod/segments';
import { AbstractCondition } from '../../../abstract-condition.component';
import { OPTIONS_CATEGORY, OPTIONS_PARAMETER } from './message-options';
import { AddConditionButtonComponent } from './add-condition-button/add-condition-button.component';

@UntilDestroy()
@Component({
	selector: 'en-message',
	templateUrl: './message.component.html',
	styleUrls: [
		'./message.component.scss',
		'../../../abstract-condition.component.scss'
	],
	providers: [MESSAGE_LIST_TABS_PROVIDER, SearchService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent extends AbstractCondition {
	currentLocation: string | undefined;

	markTagAsInvalid = false;

	visibleDialogMessage = false;

	addition = new FormControl('none');

	bounceEmailTypeControl = new FormControl('any');

	optionsChannel$ = new BehaviorSubject<SelectItem[]>([]);

	itemHasBeenDeleted$ = new Subject();

	parameterOptions: SelectItem[] = OPTIONS_PARAMETER;

	optionsCategoryMessage: SelectItem[] = OPTIONS_CATEGORY;

	showLinksOption: boolean = true;

	activeIndex = 0;

	searchForm: FormGroup<{ searchControl: FormControl<string | null> }> =
		this.fb.group({
			searchControl: ''
		});

	@ViewChild(AddConditionButtonComponent)
	addConditionButton: AddConditionButtonComponent;

	/** Нужен для компонентов с доп условиями */
	@Input()
	submitted: boolean;

	constructor(
		private service: SegmentsFormService,
		public segmentsValidateService: SegmentsValidateService,
		public cd: ChangeDetectorRef,
		private fb: FormBuilder,
		@Inject(MESSAGES_TAB_TOKEN)
		public tabs: MessagesTab[],
		private searchService: SearchService,
		private urlService: UrlSaverService
	) {
		super(segmentsValidateService, cd);
	}

	get typedForm(): FormGroup<MessageConditionForm> {
		return this.form as FormGroup<MessageConditionForm>;
	}

	get messageForm(): FormGroup<MessageFullBodyForm> {
		return this.typedForm.controls.message;
	}

	get messageChannelControl(): FormControl<string> {
		return this.messageForm.controls.channel as FormControl<string>;
	}

	get specificIdControl(): FormControl<ID | null> {
		return this.messageForm.controls.specificId as FormControl<ID>;
	}

	get specificNameControl(): FormControl<string | null> {
		return this.messageForm.controls.specificName as FormControl<string>;
	}

	get messageTagsControl(): FormControl<Tag[]> {
		return this.messageForm.controls.tag_items as FormControl<Tag[]>;
	}

	get actionLinkControl(): FormGroup<ActionLinkForm> {
		return this.messageForm.controls
			.actionLink as FormGroup<ActionLinkForm>;
	}

	get messageChannel(): string {
		return this.messageChannelControl.value;
	}

	get messageValue() {
		return this.messageForm.value;
	}

	get isSpecificMessageParameter(): boolean {
		return this.typedForm.controls.parameter.value === 'specific';
	}

	get isActionBounce(): boolean {
		return (
			this.messageValue.action === 'bounce' ||
			this.messageValue.action === 'not_bounce'
		);
	}

	get isActionOpen(): boolean {
		return (
			this.messageValue.action === 'open' ||
			this.messageValue.action === 'not_open'
		);
	}

	get isActionRead(): boolean {
		return (
			this.messageValue.action === 'read' ||
			this.messageValue.action === 'not_read'
		);
	}

	get isActionClick(): boolean {
		return (
			this.messageValue.action === 'click' ||
			this.messageValue.action === 'not_click'
		);
	}

	get isMessageSelected(): boolean {
		return Boolean(this.specificIdControl?.value);
	}

	get isInvalidTags(): boolean {
		return (
			this.messageTagsControl?.enabled && !this.messageTagsControl?.value
		);
	}

	get channelOptions(): MessageType[] {
		return this.optionsChannel$.getValue().map(item => {
			if (item.value === 'email') return 'mail';
			return item.value;
		});
	}

	setValidation() {
		this.searchForm
			.get('searchControl')
			?.valueChanges.pipe(
				untilDestroyed(this),
				tap(value => this.searchService.setSearchValue(value || ''))
			)
			.subscribe();

		this.getSubscription('message.action')?.subscribe(() => {
			this.nextOptionsChannel();
			this.correctChannelControl();
			this.form.markAsUntouched();
		});

		this.messageChannelControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				this.removeBounceType();
				this.removeSendingType(value);
			});

		this.bounceEmailTypeControl.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value === 'exact') this.createTags();
				if (value === 'any') this.deleteTags();
			});

		this.segmentsValidateService.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.isHide || this.isHideParent
						? (this.markTagAsInvalid = false)
						: (this.markTagAsInvalid = this.isInvalidTags);
				})
			)
			.subscribe();

		this.segmentsValidateService.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent)
						this.markTagAsInvalid = false;
					this.cd.markForCheck();
				})
			)
			.subscribe();
		this.parameterChange();
	}

	nextOptionsChannel(): void {
		if (this.isActionBounce) {
			this.optionsChannel$.next([
				{
					label: 'channels_name.email',
					value: 'email'
				},
				{
					label: 'channels_name.sms',
					value: 'sms'
				},
				{
					label: 'channels_name.whatsapp',
					value: 'whatsapp'
				}
			]);
			return;
		}

		if (this.isActionRead) {
			this.optionsChannel$.next([
				{
					label: 'channels_name.whatsapp',
					value: 'whatsapp'
				}
			]);
			return;
		}

		this.optionsChannel$.next([
			{
				label: 'channels_name.email',
				value: 'email'
			},
			{
				label: 'channels_name.push',
				value: 'push'
			},
			...(!this.isActionOpen
				? [
						{
							label: 'channels_name.mobPush',
							value: 'mobPush'
						}
				  ]
				: []),
			...(this.isSmsChannelAvailable()
				? [
						{
							label: 'channels_name.sms',
							value: 'sms'
						}
				  ]
				: []),
			...(this.isWhatsappChannelAvailable()
				? [
						{
							label: 'channels_name.whatsapp',
							value: 'whatsapp'
						}
				  ]
				: [])
		]);
	}

	addAdditionalCondition(value: string): void {
		switch (value) {
			case 'actionTime':
				this.createTime();
				break;
			case 'actionCount':
				this.createCount();
				break;
			case 'actionLink':
				this.createLink();
				break;
			case 'sendingType':
				this.createSendingType();
				break;
			case 'tag_items':
				this.createTags();
				break;
			case 'bounceType':
				this.createBounceType();
				break;
			case 'onlyUniqMessage':
				this.uniqMessage();
				break;
			default:
				break;
		}
	}

	actionChange(value: string) {
		this.addConditionButton.refreshMenuItems();

		if (value !== 'bounce' && this.messageValue.bounceType) {
			this.bounceEmailTypeControl.patchValue('any');
			this.removeBounceType();
		}

		if (value === 'bounce') {
			if (this.messageValue.actionCount) this.deleteCount();
		}

		if (value === 'read' || value === 'not_read') this.deleteCount();

		if (value !== 'open' && this.messageValue.onlyUniqMessage) {
			this.deleteUniqMessages();
		}
	}

	chooseChannel(value: string) {
		if (value !== 'email' && this.messageValue.onlyUniqMessage) {
			this.deleteUniqMessages();
		}
	}

	parameterChange(): void {
		if (this.isSpecificMessageParameter) {
			this.specificIdControl.enable();
			this.specificNameControl.enable();
			this.deleteSendingType();
			this.deleteTags();

			this.deleteUniqMessages();

			return;
		}
		this.removeSelectedMessage();
		this.specificIdControl.disable();
		this.specificNameControl.disable();
		this.deleteActionLink();
	}

	openDialogMessage(): void {
		this.currentLocation = this.urlService.getQueryParams().location;
		this.visibleDialogMessage = true;
	}

	closeDialogMessage(): void {
		this.urlService.setParamsToUrl(
			{ location: this.currentLocation },
			{ channel: null }
		);
		this.visibleDialogMessage = false;
	}

	selectMessage(message: Message): void {
		const { id, name, type, status, isSplitTest } = message;
		this.showLinksOption =
			status !== 'draft' && !isSplitTest && type === 'mail';

		this.specificIdControl?.patchValue(id);
		this.specificNameControl?.patchValue(name || '');

		this.messageChannelControl.patchValue(type);
		this.visibleDialogMessage = false;
	}

	private createTags() {
		this.messageForm.setControl(
			'tag_items',
			new FormControl(null, [Validators.required])
		);
		this.form.markAsTouched();
	}

	deleteTags() {
		this.messageForm.controls.tag_items?.disable();
		this.form.markAsTouched();
		this.itemHasBeenDeleted$.next();
	}

	private uniqMessage() {
		this.messageForm.setControl('onlyUniqMessage', new FormControl(true));
		this.form.markAsTouched();
	}

	deleteUniqMessages() {
		this.messageForm.controls.onlyUniqMessage?.disable();
		this.form.markAsTouched();
		this.itemHasBeenDeleted$.next();
	}

	private createCount() {
		this.messageForm.setControl(
			'actionCount',
			this.service.createMessageAddCount()
		);
		this.form.markAsTouched();
	}

	deleteCount() {
		this.messageForm.controls.actionCount?.disable();
		this.form.markAsTouched();
		this.itemHasBeenDeleted$.next();
	}

	private createTime() {
		this.messageForm.setControl(
			'actionTime',
			this.fb.group({
				periodOperator: 'between',
				start: [null, Validators.required],
				end: [null, Validators.required],
				periodValue: [1, Validators.required],
				units: 'hour'
			}) as FormGroup<TimeRangeForm>
		);
		this.form.markAsTouched();
	}

	deleteTime() {
		this.messageForm.controls.actionTime?.disable();
		this.form.markAsTouched();
		this.itemHasBeenDeleted$.next();
	}

	private createLink() {
		this.messageForm.setControl(
			'actionLink',
			this.fb.group({
				url_id: null,
				url: ['', Validators.required],
				seekBy: 'fullMatch'
			}) as FormGroup<ActionLinkForm>
		);
		this.form.markAsTouched();
	}

	deleteActionLink() {
		this.messageForm.controls.actionLink?.disable({ emitEvent: false });
		this.form.markAsTouched();
		this.itemHasBeenDeleted$.next();
	}

	private createSendingType() {
		this.messageForm.setControl('sendingType', new FormControl('sendnow'));
		this.form.markAsTouched();
	}

	deleteSendingType(): void {
		this.messageForm.controls.sendingType?.disable();
		this.form.markAsTouched();
		this.itemHasBeenDeleted$.next();
	}

	private createBounceType(): void {
		this.messageForm.setControl('bounceType', new FormControl('block'));
		this.form.markAsTouched();
	}

	removeBounceType(): void {
		this.messageForm.controls.bounceType?.disable();
		this.itemHasBeenDeleted$.next();
	}

	removeSendingType(value: string): void {
		if (value === 'whatsapp') {
			this.deleteSendingType();
		}
	}

	private removeSelectedMessage(): void {
		this.specificIdControl.patchValue(null);
		this.specificNameControl.patchValue(null);

		this.showLinksOption = false;

		this.deleteActionLink();

		this.itemHasBeenDeleted$.next();
	}

	private isSmsChannelAvailable() {
		const { action } = this.messageValue;
		return (
			action === 'send' ||
			action === 'not_send' ||
			action === 'click' ||
			action === 'not_click'
		);
	}

	private isWhatsappChannelAvailable() {
		const { action } = this.messageValue;
		return (
			action === 'send' ||
			action === 'not_send' ||
			action === 'read' ||
			action === 'not_read'
		);
	}

	private correctChannelControl() {
		const { channel } = this.messageValue;

		switch (true) {
			case this.isActionBounce && channel === 'push':
			case this.isActionOpen &&
				(channel === 'sms' || channel === 'whatsapp'):
			case this.isActionClick && channel === 'whatsapp':
				this.messageChannelControl?.patchValue('email');
				break;

			case this.isActionRead:
				this.messageChannelControl?.patchValue('whatsapp');
				break;
			default:
				break;
		}
	}
}
