import { MailingGroup } from '@state-enKod/mailing-groups';
import { SelectItemWithDeleted } from '@state-enKod/segments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type Channel = 'email' | 'sms';

export function mailingGroupSelectOptionFactory<
	T extends { getAllList: Function }
>(
	service: T
): Observable<SelectItemWithDeleted & { value: { channel: Channel } }[]> {
	return service.getAllList().pipe(
		map((resp: MailingGroup[]) => {
			return resp.map(item => ({
				label: item.name,
				value: {
					id: item.id,
					name: item.name,
					channel: item.channel,
					isDeleted: item.isDeleted
				}
			}));
		})
	);
}
