import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { FieldsModel } from '../model/fields';

export interface BlockScenarioFieldsState extends EntityState<FieldsModel> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'block-fields', idKey: 'guid' })
export class BlockScenarioFieldsStore extends EntityStore<BlockScenarioFieldsState> {
	constructor() {
		super({});
	}
}
