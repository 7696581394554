import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { SelectItem } from 'primeng/api/selectitem';
import { RegularEveryWeek } from '@enSend/message/message-wizard/message-wizard-common/models';

@Component({
	selector: 'en-start-weekday',
	template: `
		<div class="en-field">
			<label>{{ weeksLabel | translate }}</label>
			<en-multiSelect
				[options]="countWeekOptions$ | async"
				[showToggleAll]="false"
				[formControl]="everyWeek"
				(onChange)="onChange($event)"
			>
				<ng-template let-value pTemplate="selectedItems">
					<div
						*ngIf="value && value.length < 4"
						style="display: flex;"
					>
						<span
							class="chosen text-color-badge text-overflow"
							*ngFor="let option of value"
						>
							{{ regularEveryWeek[option] | translate }}
						</span>
					</div>
					<span *ngIf="!value || value.length === 0" translate></span>
					<span *ngIf="value && value.length >= 4">
						{{ value.length }}
						{{ 'message_wizard.month_count' | translate }}
					</span>
				</ng-template>
			</en-multiSelect>
			<en-error [formControl]="everyWeek"></en-error>
		</div>
		<div class="en-field">
			<label>{{ weekDaysLabel | translate }}</label>
			<en-weekday-selector
				[formControl]="weekdays"
				[disabled]="disabled"
			></en-weekday-selector>
			<en-error [formControl]="weekdays"></en-error>
		</div>
	`,
	styleUrls: ['./month.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class StartWeekdayComponent implements OnInit {
	@Input() everyWeek: UntypedFormControl;

	@Input() weekdays: UntypedFormControl;

	@Input() location: string;

	@Input() disabled = null;

	weeksLabel: string;
	weekDaysLabel: string;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	regularEveryWeek: { [key in RegularEveryWeek]: string } = {
		every: 'message_wizard.regular_week_every',
		first: 'message_wizard.regular_week_first',
		second: 'message_wizard.regular_week_second',
		third: 'message_wizard.regular_week_third',
		fourth: 'message_wizard.regular_week_fourth',
		fifth: 'message_wizard.regular_week_fifth',
		last: 'message_wizard.regular_week_last'
	};

	countWeekOptions: SelectItem[] = [
		{ label: this.regularEveryWeek.every, value: 'every' },
		{ label: this.regularEveryWeek.first, value: 'first' },
		{ label: this.regularEveryWeek.second, value: 'second' },
		{ label: this.regularEveryWeek.third, value: 'third' },
		{ label: this.regularEveryWeek.fourth, value: 'fourth' },
		{ label: this.regularEveryWeek.fifth, value: 'fifth' },
		{ label: this.regularEveryWeek.last, value: 'last' }
	];

	countWeekOptions$: BehaviorSubject<SelectItem[]>;

	ngOnInit() {
		this.countWeekOptions$ = new BehaviorSubject(this.countWeekOptions);
		this.setLabels();
	}

	onChange(event: any) {
		// отмечен пункт "каждая неделя"
		if (event.itemValue !== 'every') return;

		if (event.value.includes('every'))
			this.changeOptions({ disabled: true });
		else this.changeOptions();
	}

	changeOptions({ disabled }: { disabled?: boolean } = {}) {
		const options = this.countWeekOptions.map((option: SelectItem) => {
			if (option.value === 'every') return option;

			return {
				...option,
				disabled
			};
		});

		// убираем отмеченные пункты, кроме каждой недели
		if (disabled) this.everyWeek.patchValue(['every']);

		this.countWeekOptions$.next(options);
	}

	private setLabels() {
		this.weeksLabel =
			this.location === 'scenario'
				? 'scenario_block_schedule_start.regular_start_weeks'
				: 'message_wizard.regular_choose_week';

		this.weekDaysLabel =
			this.location === 'scenario'
				? 'scenario_block_schedule_start.regular_start_days'
				: 'message_wizard.regular_weekday';
	}
}
