import IMask from 'imask';

export const MM = {
	mask: IMask.MaskedRange,
	from: 1,
	to: 12,
	autofix: true
};

export const DD = {
	mask: IMask.MaskedRange,
	from: 1,
	to: 31,
	autofix: true
};

export const HH = {
	mask: IMask.MaskedRange,
	from: 0,
	to: 23,
	autofix: true
};

export const mm = {
	mask: IMask.MaskedRange,
	from: 0,
	to: 59,
	autofix: true
};

export function createYearRange(
	min: number,
	max: number
): IMask.AnyMaskedOptions {
	return {
		mask: IMask.MaskedRange,
		from: min,
		to: max,
		autofix: true
	};
}
