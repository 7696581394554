import {
	Component,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import * as codemirror from 'codemirror';
import 'codemirror/mode/sql/sql';
import { SqlTemplate } from '@state-enKod/sql';

@Component({
	selector: 'en-sql-preview',
	templateUrl: './sql-preview.component.html',
	styleUrls: ['./sql-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SqlPreviewComponent {
	selectedTemplate: SqlTemplate;
	readonly options: codemirror.EditorConfiguration = {
		lineNumbers: true,
		mode: 'text/x-sql',
		theme: 'material',
		readOnly: true,
		lineWrapping: true
	};

	@Input() set id(val: number) {
		const selectedTemplate = this.allTemplates.find(
			template => template.id === val
		);
		if (selectedTemplate) this.selectedTemplate = selectedTemplate;
	}

	@Input() sqlPreviewVisible: boolean;
	@Input() private readonly allTemplates: SqlTemplate[];

	@Output() onHide = new EventEmitter();
}
