import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';
import { EVENT_OPTIONS } from './event-button-options';

@UntilDestroy()
@Component({
	selector: 'en-event-button',
	template: `
		<ng-container *ngIf="currentConditions?.length < 3">
			<en-multilevel-dropdown
				[options]="conditionOptions$ | async"
				[isButtonMode]="true"
				[icon]="'en-plus-circle-o'"
				[useSegmentsButton]="true"
				[useMainMenuStyle]="true"
				(itemClick)="onItemClick.emit($event)"
				(onButtonClick)="checkEnableConditions()"
			></en-multilevel-dropdown>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventButtonComponent {
	allConditionOptions: MultiMenuItem[] = EVENT_OPTIONS;

	conditionOptions: MultiMenuItem[];

	conditionOptions$ = new BehaviorSubject<MultiMenuItem[]>(
		this.allConditionOptions
	);

	@Input() currentConditions: string[];
	@Output() onItemClick = new EventEmitter<string>();

	checkEnableConditions() {
		this.conditionOptions$.next(
			this.allConditionOptions.filter(option => {
				return !this.currentConditions.find(
					type => type === option.value
				);
			})
		);
	}
}
