import { dia, setTheme, ui } from '@clientio/rappid';
import { enKodLink as Link } from '@enSend/_shared/components/scenario-canva/rappid/shapes/app.shapes.ts/link.shape';
import {
	GRID_SIZE,
	PADDING_L,
	PADDING_S
} from '@enSend/_shared/components/scenario-canva/theme';
import { shapes } from './shapes/index';

export function createPlugins(paperElement: Element) {
	setTheme('enkod-scenario');

	// Graph
	// https://resources.jointjs.com/docs/jointjs/v3.1/joint.html#dia.Graph
	const graph = new dia.Graph({}, { cellNamespace: shapes });

	// Paper
	// https://resources.jointjs.com/docs/jointjs/v3.1/joint.html#dia.Paper
	const paper = new dia.Paper({
		model: graph,
		async: true,
		sorting: dia.Paper.sorting.APPROX,
		gridSize: GRID_SIZE,
		linkPinning: false,
		multiLinks: false,
		snapLinks: true,
		moveThreshold: 5,
		magnetThreshold: 'onleave',
		drawGrid: false,
		cellViewNamespace: shapes,
		interactive: false,
		defaultLink: () => new Link(),
		defaultRouter: {
			name: 'manhattan',
			args: {
				padding: {
					bottom: PADDING_L,
					vertical: PADDING_S,
					horizontal: PADDING_S
				},
				step: GRID_SIZE
			}
		},
		defaultConnector: {
			name: 'rounded'
		}
	});

	// PaperScroller Plugin (Scroll & Zoom)
	// https://resources.jointjs.com/docs/rappid/v3.1/ui.html#ui.PaperScroller
	const scroller = new ui.PaperScroller({
		paper,
		autoResizePaper: true,
		contentOptions: {
			padding: 100,
			allowNewOrigin: 'any'
		},
		scrollWhileDragging: true,
		cursor: 'grab',
		baseWidth: 700,
		baseHeight: 700
	});
	paperElement.appendChild(scroller.el);
	scroller.render().center();

	return {
		graph,
		paper,
		scroller
	};
}
