<form [formGroup]="form">
    <div class="en-text-large">
        {{ 'scenario_block_data_change_main.inspector_header' | translate }}
    </div>

    <en-dropdown [options]="dataTypeOptions"
                 [placeholder]="'scenario_block_data_change_main.data_type_options_placeholder' | translate"
                 styleClass="h36"
                 class="data-type"
                 [disabled]="!isCreationMode"
                 formControlName="dataType">
    </en-dropdown>

    <ng-container *ngIf="showFields"
                  formGroupName="extraFields">
        <div *ngFor="let field of extraFieldsArrayControls; index as i"
             class="en-field"
             [formGroupName]="i">

            <div class="field__header">
                <div class="field__header-name">{{ 'scenario_block_data_change_main.field_header' | translate }}</div>
                <button *ngIf="extraFieldsArrayLength > 1 && isCreationMode"
                        enButton
                        class="en-button-lowercase h28"
                        type="button"
                        icon="en en-minus-circle-o"
                        iconColor="red-button-icon"
                        (click)="removeField(i)"></button>
            </div>

            <div class="field-controls">
                <en-dropdown styleClass="h36"
                             class="field__extrafields"
                             [options]="extraFieldsOptions"
                             [placeholder]="'scenario_block_data_change_main.field_placeholder' | translate"
                             [filter]="true"
                             [disabled]="!isCreationMode"
                             filterBy="label,id"
                             formControlName="extraField">

                    <ng-template let-selectedOption
                                 pTemplate="selectedItem">
                        <ng-container *ngIf="selectedOption">
                            <div class="selected-extrafield">
                                <span class="en-mr-2"
                                      [ngClass]="selectedOption.value.type | extraFieldsIconPipe"></span>
                                <span class="selected-extrafield__name">{{ selectedOption.value.label }}</span>
                            </div>
                        </ng-container>
                    </ng-template>

                    <ng-template let-field
                                 pTemplate="item">
                        <div class="extrafield">
                            <div class="extrafield-content">
                                <span class="id-badge en-mr-2">
                                    id {{ field.value.id }}
                                </span>
                                {{ field.value.label }}
                            </div>
                            <span [ngClass]="field.value.type | extraFieldsIconPipe"></span>
                        </div>
                    </ng-template>

                </en-dropdown>

                <ng-container *ngIf="field.value.extraField.id">
                    <ng-container [ngSwitch]="field.value.extraField.type">

                        <ng-template ngSwitchCase="number">
                            <input enInput
                                   type="number"
                                   pKeyFilter="int"
                                   [attr.disabled]="disableInput"
                                   formControlName="newValue">
                        </ng-template>

                        <ng-template ngSwitchCase="float">
                            <input enInput
                                   enInputToFloat
                                   [imask]="maskFloat"
                                   [attr.disabled]="disableInput"
                                   formControlName="newValue">
                        </ng-template>

                        <ng-template ngSwitchCase="bool">
                            <en-dropdown [options]="boolOptions"
                                         [placeholder]="'scenario_block_data_change_main.data_type_options_placeholder' | translate"
                                         styleClass="en-dropdown-solid h36"
                                         [disabled]="!isCreationMode"
                                         formControlName="newValue">
                            </en-dropdown>
                        </ng-template>

                        <ng-template ngSwitchCase="date">
                            <en-dropdown-calendar-new [type]="'date'"
                                                      [disabled]="!isCreationMode"
                                                      formControlName="newValue"></en-dropdown-calendar-new>
                        </ng-template>

                        <ng-template ngSwitchCase="dateTime">
                            <en-dropdown-calendar-new [type]="'dateTime'"
                                                      [disabled]="!isCreationMode"
                                                      formControlName="newValue"></en-dropdown-calendar-new>
                        </ng-template>

                        <ng-template ngSwitchDefault>
                            <input enInput
                                   type="text"
                                   [attr.disabled]="disableInput"
                                   formControlName="newValue">
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
        </div>


        <ng-container *ngIf="isCreationMode">
            <button enButton
                    type="button"
                    class="en-button-lowercase add-field"
                    icon="en en-plus-circle-o"
                    iconColor="blue-button-icon"
                    iconPos="left"
                    [disabled]="maxFieldsLength"
                    [label]="'scenario_block_data_change_main.add_field_placeholder' | translate"
                    (click)="addField()"></button>
            <span class="max-fields-desc">{{ 'scenario_block_data_change_main.add_field_max' | translate }}</span>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="showScenarioFields"
                  formArrayName="scenarioFields">
        <div *ngFor="let field of scnFieldControls; index as i"
             class="en-field"
             [formGroup]="field">
            <div class="field__header">
                <div class="field__header-name">{{ 'scenario_block_destribution.scenario_field' | translate }}</div>
                <button *ngIf="isCreationMode && scnFieldControls.length > 1"
                        enButton
                        class="en-button-lowercase h28"
                        icon="en en-minus-circle-o"
                        iconColor="red-button-icon"
                        [disabled]="!isCreationMode"
                        (click)="removeScnField(i)"></button>
            </div>
            <div class="field-controls">
                <en-dropdown class="field-dropdown"
                             styleClass="h36"
                             [placeholder]="'scenario_block_destribution.select_field' | translate"
                             [options]="scenarioFieldOptions"
                             [filter]="true"
                             [disabled]="!isCreationMode"
                             formControlName="name"
                             (onChange)="resetValue(i)">
                    <ng-template let-selectedOption
                                 pTemplate="selectedItem">
                        <ng-container *ngIf="selectedOption">
                            <div class="selected-extrafield">
                                <span class="en-mr-2"
                                      [ngClass]="selectedOption.type | extraFieldsIconPipe"></span>
                                <span class="selected-extrafield__name">{{ selectedOption.label }}</span>
                            </div>
                        </ng-container>
                    </ng-template>
                    <ng-template let-field
                                 pTemplate="item">
                        <div class="field-dropdown_item">
                            <div class="field-dropdown_label">
                                {{field.label}}
                            </div>
                            <span class="field-dropdown_type"
                                  [ngClass]="field.type | extraFieldsIconPipe"></span>
                        </div>
                    </ng-template>
                </en-dropdown>

                <ng-container *ngIf="field.value.name">
                    <ng-container [ngSwitch]="getParamType(field.value.name)">
                        <input *ngSwitchCase="'text'"
                               enInput
                               type="text"
                               [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                               [attr.disabled]="disableInput"
                               formControlName="newValue">
                        <input *ngSwitchCase="'number'"
                               enInput
                               [imask]="maskNumber"
                               [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                               [attr.disabled]="disableInput"
                               formControlName="newValue">
                        <input *ngSwitchCase="'float'"
                               enInput
                               [imask]="maskFloat"
                               [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                               [attr.disabled]="disableInput"
                               formControlName="newValue">
                        <en-dropdown *ngSwitchCase="'bool'"
                                     styleClass="en-dropdown-solid"
                                     [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                     [options]="boolOptions"
                                     [disabled]="!isCreationMode"
                                     formControlName="newValue"></en-dropdown>
                        <en-dropdown-calendar-new *ngSwitchDefault
                                                  [type]="getParamType(field.value.name)"
                                                  [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                                  [disabled]="!isCreationMode"
                                                  formControlName="newValue"></en-dropdown-calendar-new>
                    </ng-container>
                </ng-container>

            </div>
        </div>


        <ng-container *ngIf="isCreationMode">
            <button *ngIf="scnFieldControls.length < 10"
                enButton
                type="button"
                class="add-field en-button-lowercase"
                icon="en en-plus-circle-o"
                iconColor="blue-button-icon"
                iconPos="left"
                [label]="'scenario_block_destribution.add_field' | translate"
                (click)="addScnField()"></button>
            <span class="max-fields-desc">{{ 'scenario_block_destribution.max_fields' | translate }}</span>
        </ng-container>
    </ng-container>

</form>
