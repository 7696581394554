/* eslint-disable import/no-cycle */
import { InjectFlags } from '@angular/core';
import { dia } from '@clientio/rappid';
import { ScenarioShapesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';
import { blockView } from '@enSend/_shared/components/scenario-canva/rappid/shapes';
import { SendMessageStateToken } from '@enSend/_shared/tokens';
import { mainBlock } from '../main.shapes';

export const sendMessageBlock = mainBlock.define(
	ScenarioShapesEnum.SEND_MESSAGE_BLOCK,
	{},
	{
		remove() {
			const state = this.injector.get(
				SendMessageStateToken,
				InjectFlags.Optional
			);
			state?.delete(this.get('id'));
			mainBlock.prototype.remove.call(this);
		}
	}
);

export const sendMessageBlockView: dia.ElementView = Object.assign(blockView);
