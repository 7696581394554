import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Injector,
	OnDestroy,
	OnInit
} from '@angular/core';
import { dia, shapes } from '@clientio/rappid';
import { TuiMapper } from '@taiga-ui/cdk';
import { tuiSlideInRight } from '@taiga-ui/core';
import { BehaviorSubject, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { GET_ROUTE_ID } from '@enkod-core/tokens';
import { CanvaMode, Option, Subtype } from '@enSend/_shared/models';
import { PreviewRappidService } from '@enSend/scenario/scenario-details/scenario-details-preview/preview-canva/services/preview-rappid.service';
import {
	CanvaModeType,
	CANVA_MODE_TOKEN
} from '../../../tokens/canva-mode-token';
import { SharedEvents } from '../rappid/controller';
import { EventBusService, InspectorService } from '../services';
import { RappidService } from '../services/rappid.service';
import { InspectorManager } from './inspector-manager.service';
import { removeCell } from '../rappid/actions';
import { shapeConfig } from '../rappid/config/shape.config';
import { ChatbotShapesEnum } from '../rappid/config/enum.shapes';

@Component({
	selector: 'en-canva-inspector',
	templateUrl: './inspector.component.html',
	styleUrls: ['./inspector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [InspectorManager],
	animations: [tuiSlideInRight]
})
export class InspectorComponent implements OnInit, OnDestroy {
	private rappid = this.getRappidService();
	cell: dia.Cell | null;

	readonly toggleSideBar$ = this.inspectorService.toggleInspectorChanges;
	readonly selectedCell$ = new BehaviorSubject<dia.Cell | null>(null);
	readonly inspector$ = this.selectedCell$.pipe(
		filter((cell): cell is dia.Cell => cell !== null),
		switchMap(cell => this.inspectorManager.getInspector(cell))
	);

	readonly context$ = this.inspector$.pipe(
		switchMap(inspector =>
			of({
				scenarioId: this.routeId,
				injector: this.injector,
				...inspector.context
			})
		)
	);

	protected readonly getInspectorText: TuiMapper<string, string> = (
		name: string,
		namespace: CanvaMode
	) => {
		const subtypes = shapeConfig[namespace];
		return (subtypes[name as keyof typeof subtypes] as Subtype).text;
	};

	constructor(
		private injector: Injector,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(GET_ROUTE_ID) private routeId: number,
		private readonly eventBusService: EventBusService,
		private readonly inspectorManager: InspectorManager,
		private readonly inspectorService: InspectorService
	) {}

	get isCreationMode(): boolean {
		return this.canvaMode === 'create';
	}

	get namespace(): CanvaMode {
		return this.rappid.canvaMode;
	}

	ngOnInit(): void {
		this.eventBusService.on(
			SharedEvents.SELECTION_CHANGED,
			(selection: dia.Cell[]) => this.setCell(selection)
		);
	}

	private setCell(selection: dia.Cell[]): void {
		const [cell = null] = selection;
		this.cell = cell;
		if (this.cell?.get('type') !== 'enKodLink') {
			this.inspectorService.toggleInspector(true);
		}
		this.selectedCell$.next(cell);
	}

	copyElement() {
		if (!this.isCreationMode) return;
		if (this.cell) {
			const newElem = (this.rappid as RappidService).graph
				.getCell(this.cell.cid)
				// .findView(this.rappid.paper)
				.clone() as InstanceType<typeof shapes.Block>;
			const subType = newElem.get('subType');
			const options = newElem.get('options');

			if (subType === 'sendMessage' || subType === 'apiStart') {
				const { params } = options[subType];
				newElem.blockLayout.options.length = 0;
				params.length = 0;
				newElem.size(200, 60);
			}

			if (subType === 'distribution') {
				const unsuitablePath = [
					'options',
					'distribution',
					'unsuitable'
				];
				// @ts-ignore:next-line
				newElem.prop(unsuitablePath, newElem.generatePortId());
			}

			newElem.position(
				this.cell.attributes.position.x,
				this.cell.attributes.position.y + 100
			);

			(this.rappid as RappidService).graph.addCell(newElem);
			this.generateNewPorts(newElem);
		}
		this.inspectorService.toggleInspector(false);
	}

	removeElement() {
		if (!this.isCreationMode) return;
		if (this.cell) removeCell(this.cell);
	}

	private getRappidService() {
		return this.isCreationMode
			? this.injector.get(RappidService)
			: this.injector.get(PreviewRappidService);
	}

	// todo тот же метод в halo.service вынести куда-то ещё
	private generateNewPorts(model: InstanceType<typeof shapes.Block>) {
		const ports = model.getPorts();
		const newPorts = ports.map(port => ({
			...port,
			id: model.generatePortId()
		}));
		model.prop('ports/items', newPorts, { noHistory: true });

		const newOutPorts = newPorts.filter(port => port.group === 'out');

		const namespace = model.get('namespace') ?? 'scenario';
		const options = model.get('options');
		const subType = model.get('subType');

		if (namespace === 'scenario') {
			const { params } = options[subType];
			const newParams = params.map((option: Option, i: number) => ({
				...option,
				port: newOutPorts[i].id
			}));
			model.prop(`options/${subType}/params`, newParams, {
				noHistory: true
			});
		}

		if (namespace === 'chatbot') {
			if (subType === ChatbotShapesEnum.MESSAGE) {
				const { inlineReplyButtons } = options;
				const newParams = inlineReplyButtons
					.filter((option: Option) => option.type !== 'restart')
					.map((option: Option, i: number) => ({
						...option,
						port: newOutPorts[i].id,
						guid: model.generatePortId()
					}));

				model.prop(`options/inlineReplyButtons`, newParams, {
					noHistory: true
				});
			}
		}
	}

	ngOnDestroy(): void {
		this.inspectorService.toggleInspector(false);
	}
}
