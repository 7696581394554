<en-fileupload #uploader
               [hidden]="true"
               [auto]="true"
               [accept]="accept"
               mode="basic"
               [maxFileSize]="maxFileSize"
               [customUpload]="true"
               [multiple]="false"
               (uploadHandler)="customUploader($event)"
               (onInvalidFileSize)="onInvalidFileSize()"
               (onInvalidFileType)="onInvalidFileType()">
</en-fileupload>

<en-messages [value]="messages | async"
             [isBackground]="false"></en-messages>
