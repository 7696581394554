<en-dialog [header]="'select_segment_dialog.segment_selection' | translate"
           [modal]="true"
           [style]="{width: '75rem', height: '42.5rem'}"
           [(visible)]="visible"
           [draggable]="false"
           (onHide)="closeSelectionModal()">
    <div class="segment-select">
        <div class="segment-select__tree">
            <ng-container *ngIf="(treeLoading$ | async); else segmentsTree">
                <div class="loader">
                    <i class="loader__icon"></i>
                </div>
            </ng-container>

            <ng-template #segmentsTree>
                <tui-scrollbar class="segment-select__tree-scrollbar">
                    <div class="segment-select__tree-content">
                        <en-segments-tree #enSegmentsTree
                                          *ngIf="isSegmentSelected; else emptySegment"
                                          class="segment__tree"
                                          [form]="formConditions"
                                          [isUseSegmentModal]="true">
                        </en-segments-tree>
                    </div>
                </tui-scrollbar>
            </ng-template>

            <ng-template #emptySegment>
                <div class="segment-select__empty-segment">
                    <span>{{'select_segment_dialog.select_segment_to_view' | translate}}</span>
                </div>
            </ng-template>
        </div>

        <div class="segment-select__search">
            <en-search class="segment-select__search-input"
                       styleClass="h32"
                       [placeholder]="'select_segment_dialog.search_placeholder' | translate"
                       [refresher]="false"
                       [formControl]="searchControl">
            </en-search>

            <ng-container *ngIf="(listLoading$ | async); else segmentsList">
                <div class="loader loader__segments-list">
                    <i class="loader__icon"></i>
                </div>
            </ng-container>
            <ng-template #segmentsList>
                <tui-scrollbar>
                    <div *tuiLet="searched$ | async as searched"
                         class="segment-select__search-content">
                        <ng-container *ngIf="!searched; else searchedOpt">
                            <ng-container *ngFor="let segment of segmentsOptionList">
                                <button enButton
                                        type="button"
                                        class="en-button-lowercase segment-select__segment-option"
                                        [label]="' '"
                                        (click)="selectSegment(segment)">
                                    <span class="id-badge en-mr-2">id {{ segment.value.id }}</span>
                                    <span class="text-overflow">{{ segment.label }}</span>
                                </button>
                            </ng-container>
                        </ng-container>

                        <ng-template #searchedOpt>
                            <div *ngIf="searchedOptions.length"
                                 class="option__searched">
                                <ng-container *ngFor="let option of searchedOptions">
                                    <button enButton
                                            type="button"
                                            class="en-button-lowercase segment-select__segment-option"
                                            [label]="' '"
                                            (click)="selectSegment(option)">
                                        <span class="id-badge en-mr-2">id {{ option.value.id }}</span>
                                        <span class="text-overflow">{{ option.label }}</span>
                                    </button>
                                </ng-container>
                            </div>

                            <div *ngIf="!searchedOptions.length"
                                 class="empty-search">{{'select_segment_dialog.empty_segment' | translate}}
                            </div>
                        </ng-template>

                    </div>
                </tui-scrollbar>
            </ng-template>
        </div>
    </div>
    <p-footer>
        <div class="buttons">
            <button enButton
                    type="button"
                    class="en-button-secondary button reject h36 en-mr-4"
                    [label]="'common.cancel' | translate"
                    (click)="closeSelectionModal()">
            </button>
            <button enButton
                    type="button"
                    class="button h36"
                    [label]="'common.to_select' | translate"
                    [disabled]="!isSegmentSelected"
                    (click)="confirmSelectionModal()"></button>
        </div>
    </p-footer>
</en-dialog>
