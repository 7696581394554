import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	isDevMode,
	OnInit,
	Output
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@enkod-core/authentication/_state';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import {
	ConditionModel,
	Segment,
	SelectItemWithDeleted
} from '@state-enKod/segments';
import { ConfirmationService } from 'primeng/api';
import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import {
	ConditionFormTypes,
	ConditionJsonForm,
	UserEventCommonForm
} from '@enKod/segments/segments-form.model';
import { SegmentsFormService } from '../../segments-form.service';
import { UnitsDeclinationService } from './conditions/common/services/units-declination.service';
import { ENPOP_MENU_OPTIONS } from './_constants/enpop-menu-options';
import { ENSEND_MENU_OPTIONS } from './_constants/ensend-menu-options';

// Для работы скрытия групп и условий(сегменты для сообщений), необходимо:
//
// 1. В форму каждого условия добавлять isHide контрол
// 2. Добавлять класс active-hide в атрибуты каждого элемента в условии
// Для того, чтобы обесцвечивать и блокировать элементы условия
// 3. В инпуты каждого условия надо прокидывать isHideParent
// Это надо для определения того, что группа скрыта в условии
// 4. Если в условии валидация применяется через переменную в компоненте
// Можно подписаться на resetInvalidMark$, чтобы понимать, когда группа или условие скрывается
@UntilDestroy()
@Component({
	selector: 'en-segments-tree',
	templateUrl: './segments-tree.component.html',
	styleUrls: ['./segments-tree.component.scss'],
	providers: [UnitsDeclinationService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentsTreeComponent implements OnInit, AfterViewInit {
	/** Для тестеров */
	showJson = isDevMode();

	optionsJoin: Array<object>;

	mailingGroup: SelectItemWithDeleted;

	tag: SelectItemWithDeleted;

	isHover = false;

	currentRFM: SelectItemWithDeleted;

	segment: SelectItemWithDeleted;

	isModalVisible = false;

	selectedSegment: Segment;

	@Input() mainMenuOptions: MultiMenuItem[] = [];

	@Input() form: FormGroup<ConditionJsonForm>;

	@Input() innerIndex: number | null = null;

	@Input() forceValidate: boolean;

	@Input() isPreview: boolean;

	@Input() isUseSegmentModal: boolean;

	@Input() isWizard: boolean;

	@Input() isAccessEnpop: boolean;

	@Input() isAccessEnsend: boolean;

	@Input() isAccessTables: boolean;

	@Input() submitted: boolean;

	@Input() isInner = false;

	@Input() isParentHide = false;

	@Input() forceUpdate$ = new Subject<void>();

	@Input() set moduleType(value: string) {
		this.updateMenuOptions(value);
	}

	@Output() emitRemoveGroup = new EventEmitter<number>();
	@Output() emitCopyGroup = new EventEmitter<number>();
	@Output() emitHideGroup = new EventEmitter<number>();

	@Output() calculateCountContacts = new EventEmitter<void>();

	constructor(
		private segmentsService: SegmentsFormService,
		private segmentsValidateService: SegmentsValidateService,
		private confirmationService: ConfirmationService,
		private element: ElementRef,
		private translate: TranslateService,
		private cd: ChangeDetectorRef,
		private formService: SegmentsFormService,
		private authQuery: AuthQuery,
		private router: Router,
		private route: ActivatedRoute
	) {
		if (
			this.router.getCurrentNavigation()?.extras.state
				?.groupSubscription ||
			this.router.getCurrentNavigation()?.extras.state?.tag ||
			this.router.getCurrentNavigation()?.extras.state?.currentRFM
		) {
			this.mailingGroup = this.router.getCurrentNavigation()?.extras.state
				?.groupSubscription as SelectItemWithDeleted;
			this.tag = this.router.getCurrentNavigation()?.extras.state
				?.tag as SelectItemWithDeleted;
			this.currentRFM = this.router.getCurrentNavigation()?.extras.state
				?.currentRFM as SelectItemWithDeleted;
		}
	}

	get isRoot() {
		return this.innerIndex === null;
	}

	get conditions() {
		return this.conditionArray.value;
	}

	get conditionArray() {
		return this.form.controls.conditions as FormArray<
			FormGroup<ConditionFormTypes>
		>;
	}

	get isHide(): boolean {
		return this.form.controls.isHide?.value;
	}

	get inners() {
		return this.form.controls.inners as FormArray<
			FormGroup<ConditionJsonForm>
		>;
	}

	get isEnSendSegment() {
		return this.route.snapshot.queryParams.location === 'enSend';
	}

	ngOnInit() {
		this.optionsJoin = [
			{
				label: this.translate.instant('segment_form.group_join_and'),
				value: 'and'
			},
			{
				label: this.translate.instant('segment_form.group_join_or'),
				value: 'or'
			}
		];
		this.addConditionFromGroupSubscription();
		this.addConditionFromTag();
		this.addConditionFromRfm();

		this.forceUpdate$
			.pipe(
				untilDestroyed(this),
				tap(() => this.cd.markForCheck())
			)
			.subscribe();
	}

	ngAfterViewInit() {
		if (this.isPreview) this.setFormPreview();
		if (this.isUseSegmentModal) this.showJson = false;
		this.cd.detectChanges();
	}

	updateMenuOptions(module: string) {
		const isTableAccess = this.authQuery.isAccessTables;
		const isTrackingAccess = this.authQuery.isAccessTracking;
		this.mainMenuOptions =
			module === 'enPop' ? ENPOP_MENU_OPTIONS : ENSEND_MENU_OPTIONS;

		if (module === 'enPop' && !isTrackingAccess) {
			this.mainMenuOptions = this.mainMenuOptions.map(item => {
				if (item.value === 'behaviorOptions') {
					return {
						...item,
						submenuItems: item.submenuItems?.filter(item => {
							if (item.value === 'cartChange') return false;
							return true;
						})
					};
				}
				return item;
			});
		}

		this.mainMenuOptions = this.mainMenuOptions.filter(item => {
			if (!isTableAccess && item.value === 'dataTable') return false;
			if (!isTrackingAccess && item.value === 'tracking') return false;
			return true;
		});
		this.cd.markForCheck();
	}

	removeGroup(index: number) {
		this.confirmationService.confirm({
			key: this.isWizard ? 'confirmSegmentWizard' : 'confirmDelete',
			message: this.translate.instant(
				'segment_form.confirm_delete_group'
			),
			accept: () => {
				this.inners.removeAt(index);
				this.cd.markForCheck();
			}
		});
		this.form.markAsTouched();
	}

	hideGroup(index: number): void {
		const group = this.inners.controls[index];
		this.formService.hideGroup(group, !group.controls.isHide.value);
		if (group.controls.isHide.value) {
			group.markAsPristine();
			this.segmentsValidateService.resetInvalidMark$.next();
		}
	}

	copyGroup(index: number): void {
		const target = this.inners.controls[index];
		const copyGroup = this.formService.createGroupCopy(target);
		this.inners.insert(index, copyGroup);
	}

	copyCondition(index: number) {
		const condition = this.conditionArray.at(
			index
		) as FormGroup<ConditionFormTypes>;
		const newCondition = this.formService.createConditionFromEntity(
			condition.value as ConditionModel
		);
		if (newCondition) {
			newCondition.patchValue(
				this.formService.conditionFilterGroups(
					condition.value as ConditionModel
				)
			);
			this.conditionArray.insert(index + 1, newCondition);
		}
	}

	removeCondition(index: number) {
		this.conditionArray.removeAt(index);
		this.form.markAsTouched();
	}

	removeAll() {
		this.confirmationService.confirm({
			key: this.isWizard ? 'confirmSegmentWizard' : 'confirmDelete',
			message: this.translate.instant(
				'segment_form.confirm_delete_all_group'
			),
			accept: () => {
				this.conditionArray.controls = [];
				this.conditionArray.setValue([]);
				this.inners.controls = [];
				this.inners.setValue([]);
				this.cd.markForCheck();
			}
		});
		this.form.markAsTouched();
	}

	addGroup() {
		const group = this.segmentsService.createGroup(false);
		this.inners.push(group);
		this.form.markAsTouched();
	}

	addCondition(type: string) {
		if (type === 'segment') {
			this.openSegmentSelectionModal();
		} else {
			const condition = this.segmentsService.createCondition(
				type
			) as FormGroup<ConditionFormTypes>;
			this.conditionArray.push(condition);
			this.segmentsValidateService.clearValidationMsgs$.next();
			this.form.markAsTouched();
			this.cd.markForCheck();
		}
	}

	addConditionFromGroupSubscription() {
		if (this.mailingGroup) {
			const condition = this.segmentsService.createSubscriptionValue([
				this.mailingGroup.value
			]) as FormGroup<ConditionFormTypes>;
			this.conditionArray.push(condition);
			this.form.markAsTouched();
			this.calculateCountContacts.emit();
		}
	}

	addConditionFromTag() {
		if (this.tag) {
			const condition = this.segmentsService.createTag([
				this.tag.value
			]) as FormGroup<ConditionFormTypes>;
			this.conditionArray.push(condition);
			this.form.markAsTouched();
			this.calculateCountContacts.emit();
		}
	}

	addConditionFromRfm() {
		if (this.currentRFM) {
			const condition = this.segmentsService.createCurrentRFM(
				this.currentRFM.value
			) as FormGroup<ConditionFormTypes>;
			this.conditionArray.push(condition);
			this.form.markAsTouched();
			this.calculateCountContacts.emit();
		}
	}

	changeCondition(event: { index: number; type: string }) {
		const condition = this.segmentsService.createCondition(
			event.type
		) as FormGroup<ConditionFormTypes>;
		this.conditionArray.removeAt(event.index);
		this.conditionArray.insert(event.index, condition);
		this.form.markAsTouched();
	}

	checkIsCustomEvent(optionForm: FormGroup<ConditionFormTypes>): boolean {
		const type = (optionForm as FormGroup<UserEventCommonForm>).controls
			.customEvent?.value?.type;
		return type === 'event' || type === 'not_event';
	}

	setFormPreview() {
		const elements = this.element.nativeElement.querySelectorAll(
			'input, button, textarea'
		);
		elements.forEach((element: any) => {
			element.classList.add('ui-state-error');
			element.setAttribute('readonly', '');
		});
	}

	trackByIndex(index: number) {
		return index;
	}

	closeSelectionModal() {
		this.isModalVisible = false;
		this.cd.markForCheck();
	}

	confirmSelectionModal(event: Segment) {
		this.selectedSegment = event;
		const segmentConditionJSON = this.segmentsService.buildConditionGroup(
			event.conditionJSON,
			false
		);

		const segmentConditions = segmentConditionJSON.get(
			'conditions'
		) as FormArray<FormGroup<ConditionFormTypes>>;
		this.mergeControls(segmentConditions, this.conditionArray);

		const segmentInners = segmentConditionJSON.get('inners') as FormArray<
			FormGroup<ConditionJsonForm>
		>;
		this.mergeControls(segmentInners, this.inners);

		const joinValue = segmentConditionJSON.get('join')?.value;
		if (joinValue) this.form.get('join')?.setValue(joinValue);

		this.segmentsValidateService.clearValidationMsgs$.next();
		this.form.markAsTouched();
		this.isModalVisible = false;
		this.cd.markForCheck();
	}

	private openSegmentSelectionModal() {
		this.isModalVisible = true;
		this.cd.markForCheck();
	}

	private mergeControls(fromArray: FormArray, toArray: FormArray) {
		if (fromArray && fromArray.controls.length > 0) {
			fromArray.controls.forEach(control => {
				toArray.push(control);
			});
		}
	}
}
