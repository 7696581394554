import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupPreviewIframeComponent } from './popup-preview-iframe.component';

@NgModule({
	imports: [CommonModule],
	declarations: [PopupPreviewIframeComponent],
	exports: [PopupPreviewIframeComponent]
})
export class PopupPreviewIframeModule {}
