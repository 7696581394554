export const DEFAULT_DOI_PLAIN_TEXT = `Добрый день!

Спасибо за подписку на рассылку {{group_names}}.

Для активации вашей подписки и подтверждения желания получать письма с данного ресурса перейдите по ссылке, расположенной ниже.

Перейдите по данной ссылке чтобы начать получать письма ( {{link_confirm}} )

Если Вы не можете перейти по ссылке, скопируйте ее в адресную строку браузера.

Если Вы не хотите получать письма {{group_names}}. и не запрашивали регистрацию на данном ресурсе, просто проигнорируйте данное письмо.

С наилучшими пожеланиями.

О данном сообщении
Сообщение отправлено для подтверждения данного емейл-адреса.
Если это сообщение попало к Вам по ошибке, мы приносим Вам свои извинения.`;
