import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiScrollbarModule } from '@enkod-core/components';
import {
	EnButtonModule,
	EnCalendarModule,
	EnDialogModule,
	EnDropdownCalendarRangeModule,
	EnDropdownModule,
	EnMessagesModule,
	ErrorModule,
	SearchModule
} from 'ui-lib';
import { WeekdaySelectorModule } from '@enSend/message/message-wizard/message-wizard-common/components';
import { MonthModule } from '@enSend/_shared/components/month/month.module';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SegmentsTableModule } from '@enKod/segments/components';
import { StepConditionService } from '@enSend/message/message-wizard/message-wizard-common';
import { ScheduleStartComponent } from './schedule-start.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { ScheduleStartService } from './schedule-start.service';
import { ChosenItemInfoModule } from '../../components/chosen-item-info/chosen-item-info.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		FormsModule,
		EnDropdownCalendarRangeModule,
		EnDropdownModule,
		EnButtonModule,
		ChosenItemInfoModule,
		ErrorModule,
		EnCalendarModule,
		WeekdaySelectorModule,
		MonthModule,
		TuiLetModule,
		SegmentsTableModule,
		EnDialogModule,
		SearchModule,
		EnMessagesModule,
		TuiScrollbarModule
	],
	declarations: [ScheduleStartComponent],
	exports: [ScheduleStartComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: ScheduleStartService,
			multi: true
		},
		StepConditionService
	]
})
export class ScheduleStartModule {}
