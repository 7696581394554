import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { EnButtonModule, EnDialogModule } from 'ui-lib';

import { ListEditConfirmDialogComponent } from './list-edit-confirm-dialog.component';

@NgModule({
	imports: [CommonModule, TranslateModule, EnDialogModule, EnButtonModule],
	declarations: [ListEditConfirmDialogComponent],
	exports: [ListEditConfirmDialogComponent]
})
export class ListEditConfirmDialogModule {}
