<nav class="navigations">
    <div class="navigation">
        <en-selectbutton styleClass="h32"
                         class="navigation__selectButtons"
                         [formControl]="devicePreview"
                         [options]="selectOptions[0]"
                         (onOptionClick)="handleViewMode($event.option.value)"></en-selectbutton>
        <en-selectbutton *ngIf="showPageToggler"
                         styleClass="h32"
                         class="navigation__selectButtons"
                         [formControl]="selectedPagePreview"
                         [options]="previewOptions"></en-selectbutton>
    </div>
</nav>
