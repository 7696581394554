import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TopBarService {
	hotkeysOpened$ = new BehaviorSubject<boolean>(false);

	get toggleHotkeysChanges() {
		return this.hotkeysOpened$.asObservable();
	}

	toggleHotkeys(value: boolean) {
		this.hotkeysOpened$.next(value);
	}
}
