import { UrlParams } from './entity-params.model';

export interface SmsEntity {
	id: number;
	subject: string;
	messageId: number;
	body: string;
	toShortLink: boolean;
	urlParams: UrlParams;
}
