import {
	Component,
	OnInit,
	forwardRef,
	Input,
	ChangeDetectorRef,
	AfterViewInit
} from '@angular/core';
import {
	NG_VALUE_ACCESSOR,
	ControlValueAccessor,
	UntypedFormArray,
	UntypedFormBuilder
} from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Dialog } from 'primeng/dialog';
import { debounceTime } from 'rxjs/operators';
import { TestSnippetsFormService } from '../test-snippets-form.service';

export interface SnippetsModel {
	[key: string]: string;
}

export const SNIPPETS_CONTROLS_ACCESS = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SnippetInputsComponent),
	multi: true
};
@UntilDestroy()
@Component({
	selector: 'en-snippet-inputs',
	templateUrl: './snippet-inputs.component.html',
	styleUrls: ['./snippet-inputs.component.scss'],
	providers: [SNIPPETS_CONTROLS_ACCESS]
})
export class SnippetInputsComponent
	implements OnInit, AfterViewInit, ControlValueAccessor
{
	constructor(
		private fb: UntypedFormBuilder,
		private testSnippetsForm: TestSnippetsFormService,
		private cd: ChangeDetectorRef
	) {}

	@Input() dialog: Dialog;

	snippetsFormArray: UntypedFormArray = this.fb.array([]);

	snippetsSet: Set<string>;

	ngOnInit(): void {
		this.setupFormListeners();
	}

	ngAfterViewInit() {
		this.setupVisibleChangeListeners();
	}

	onChange: Function = () => {};

	onTouch: Function = () => {};

	writeValue(value: SnippetsModel): void {
		if (!value) return;
		const keyValue: SnippetsModel[] = this.toKeyValue(value);
		this.writeValueToControls(keyValue);
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	private setupFormListeners() {
		this.snippetsFormArray.valueChanges
			.pipe(untilDestroyed(this), debounceTime(500))
			.subscribe(change => {
				const snippetObject = this.transformToFinalObject(change);
				this.onChange(snippetObject);
			});
	}

	private setupVisibleChangeListeners() {
		this.dialog.onShow.pipe(untilDestroyed(this)).subscribe(() => {
			this.snippetsSet = new Set(this.testSnippetsForm.snippets);
			this.fillSnippetsForm();
			this.cd.markForCheck();
		});

		this.dialog.onHide.pipe(untilDestroyed(this)).subscribe(() => {
			this.snippetsFormArray.clear();
		});
	}

	fillSnippetsForm(): void {
		this.snippetsSet.forEach((snippet: string) => {
			this.snippetsFormArray.push(
				this.fb.group({
					key: snippet,
					value: ''
				})
			);
		});
	}

	transformToFinalObject(snippetsArray: SnippetsModel[]): SnippetsModel {
		const snippetsValue: SnippetsModel = {};

		snippetsArray.forEach(({ key, value }) => {
			Object.assign(snippetsValue, Object.fromEntries([[key, value]]));
		});

		return snippetsValue;
	}

	toKeyValue(value: SnippetsModel): SnippetsModel[] {
		const keyValueArray: SnippetsModel[] = [];
		Object.entries(value).forEach(([key, value]) => {
			keyValueArray.push({
				key,
				value
			});
		});

		return keyValueArray;
	}

	writeValueToControls(keyValue: SnippetsModel[]): void {
		keyValue.forEach((element: SnippetsModel) => {
			this.snippetsFormArray.controls.forEach(control => {
				if (element.key === control.get('key')?.value)
					control.patchValue(element);
			});
		});
	}
}
