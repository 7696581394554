/* eslint-disable import/no-cycle */
import { dia, shapes, ui } from '@clientio/rappid';
import { RappidService } from '../../services/rappid.service';
import { Controller, SharedEvents } from '../controller';
import * as actions from '../actions';

const MOUSEWHEEL_DELTA_THROTTLE = 0.2;

export class RappidController extends Controller<RappidService> {
	constructor(public readonly service: RappidService) {
		super(service);
	}

	startListening() {
		const {
			graph,
			paper,
			selectionUI: selection,
			snaplines
		} = this.service;

		this.listenTo(graph, {
			// add: onCellAdd,
			'change:options': onChangeOption,
			'change add remove': onGraphChange,
			'change:source change:target': onChangeLink
		});

		this.listenTo(paper, {
			scale: onPaperScale,
			'blank:pointerdown': onPaperBlankPointerdown,
			'blank:pointerup': onPaperBlankPointerup,
			'blank:mousewheel': onPaperBlankMousewheel,
			'cell:mousewheel': onCellMousewheel,
			'blank:pointerdblclick': onPaperBlankDBclick,
			'cell:pointerdown': onPaperCellPointerdown,
			'cell:pointerup': onPaperCellPointerup,
			'cell:pointerdblclick': onPaperCellDBclick,
			'link:mouseenter': onLinkMouseEnter,
			'link:mouseleave': onLinkMouseLeave
		});

		this.listenTo(selection.collection, {
			'reset add': onSelectionChange
		});

		snaplines.startListening();
	}
}

// Graph

function onChangeOption(
	_service: RappidService,
	block: InstanceType<typeof shapes.Block>
): void {
	block.onChangeOptions();
}

function onGraphChange(service: RappidService, _: any, option: any): void {
	if (option.noHistory) return;
	const { graph, eventBusService } = service;
	eventBusService.emit(SharedEvents.GRAPH_CHANGED, graph.toJSON());
}

function onChangeLink(service: RappidService, link: dia.Link) {
	const sourceId = link.get('source').id;
	const targetId = link.get('target').id;

	const { paper } = service;
	ui.Halo.clear(paper);

	if (sourceId && targetId) {
		const sourceBlock = link.getSourceElement() as InstanceType<
			typeof shapes.Block
		>;
		const targetBlock = link.getTargetElement() as InstanceType<
			typeof shapes.Block
		>;

		if (sourceBlock.get('invalid')) sourceBlock.checkLinksValidity(service);
		if (targetBlock.get('invalid')) targetBlock.checkLinksValidity(service);
	}
}

function onPaperBlankMousewheel(
	service: RappidService,
	evt: dia.Event,
	x: number,
	y: number,
	delta: number
): void {
	evt.preventDefault();
	zoomAtPoint(service, delta * MOUSEWHEEL_DELTA_THROTTLE, x, y);
}

function onCellMousewheel(
	service: RappidService,
	_cellView: dia.CellView,
	evt: dia.Event,
	x: number,
	y: number,
	delta: number
) {
	evt.preventDefault();
	zoomAtPoint(service, delta * MOUSEWHEEL_DELTA_THROTTLE, x, y);
}

function zoomAtPoint(
	service: RappidService,
	deltaZoom: number,
	x: number,
	y: number
): void {
	const { scroller } = service;
	scroller.zoom(deltaZoom, {
		min: 0.4,
		max: 3,
		grid: 0.2,
		ox: x,
		oy: y
	});
}

// Paper

function onPaperScale(service: RappidService, value: number) {
	actions.setScale(service, Math.round(value * 100));
}

function onPaperBlankPointerdown(service: RappidService, evt: dia.Event): void {
	const {
		isSelectionMod,
		scroller,
		inspectorService,
		selectionUI: selection,
		topBarService
	} = service;

	// любая кнопка мыши кроме колесика
	if (evt.which !== 2 && isSelectionMod) {
		selection.startSelecting(evt);
		actions.setSelection(service, []);
	} else scroller.startPanning(evt);

	// колесико мыши
	if (evt.which === 2) {
		scroller.setCursor('grab');
	}

	if (topBarService.hotkeysOpened$.getValue()) {
		topBarService.toggleHotkeys(false);
		return;
	}

	inspectorService.toggleInspector(false);
}

function onPaperBlankPointerup(service: RappidService, evt: dia.Event) {
	const { isSelectionMod, scroller } = service;
	if (evt.which === 2 && isSelectionMod) {
		scroller.setCursor('default');
	}
}

function onPaperBlankDBclick(service: RappidService) {
	const { isSelectionMod, bottomBarService } = service;

	if (!isSelectionMod) {
		bottomBarService.toggleCursorMod();
	}
}

function onPaperCellPointerdown(
	_service: RappidService,
	elementView: dia.CellView,
	evt: dia.Event
) {
	evt.stopPropagation();
	actions.setSelection(_service, [elementView.model]);
	elementView.model.toFront({ noHistory: true });

	if (elementView.model.isLink()) {
		elementView.vel.addClass('selected');
		actions.setLinkColor(elementView.model as dia.Link, '#234ec4');
	}
}

function onPaperCellPointerup(
	service: RappidService,
	cellView: dia.CellView
): void {
	actions.showHalo(service, [cellView.model]);
	actions.setSelection(service, [cellView.model]);
}

function onPaperCellDBclick(service: RappidService, cellView: dia.CellView) {
	const { paper, topBarService } = service;

	if (topBarService.hotkeysOpened$.getValue()) {
		topBarService.toggleHotkeys(false);
	}

	if (cellView.model.isLink()) return;

	ui.Halo.clear(paper);
	actions.setActiveSelection(service);
}

function onLinkMouseEnter(_service: RappidService, linkView: dia.LinkView) {
	if (linkView.vel.hasClass('selected')) return;
	actions.setLinkColor(linkView.model, '#234ec4');
}

function onLinkMouseLeave(_service: RappidService, linkView: dia.LinkView) {
	if (linkView.vel.hasClass('selected')) return;
	actions.setLinkColor(linkView.model, '#535766');
}

// Selection

function onSelectionChange(service: RappidService) {
	const { selectionUI: selection } = service;
	const { collection } = selection;

	if (collection.length === 1) {
		const cell: dia.Cell = collection.first();

		selection.destroySelectionBox(cell);
		actions.showHalo(service, [cell]);
	}

	actions.setSelection(service, collection.models as dia.Cell[]);
}
