import { ID } from '@datorama/akita';

export type DistributionParam = {
	id?: ID;
	label?: string;
	field?: string;
	port?: ID;
	scenarioFieldsCondition?: ParamValue;
	type?: string;
	forUse?: boolean;
};

export type Group = {
	id: number;
	name: string;
};

export type Options = {
	distributionType: string;
	groupsAmount: number;
	propGroupsAmount: number;
	propGroupsValues: number[];
	disabledSliders: number[];
	fields?: FieldValue[];
	params: DistributionParam[];
};

export type Field = {
	guid: string;
	name: string;
	type: string;
};

export type FieldValue = {
	fieldName: string;
	operator: string;
	value: string | number | DateFieldValue;
};

type DateFieldValue = {
	startDate: number;
	endDate: number;
};

export type ParamValue = {
	field: string;
	operator: string;
	value: string;
	startDate: string;
	endDate: string;
};

export type FieldOption = {
	id: string;
	label: string;
	value: string;
	type: string;
};

export type FormValue = {
	distributionType: string;
	groupsAmount: number;
	fields: FieldValue[];
};
