import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ID } from '@datorama/akita';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, finalize, map, switchMap } from 'rxjs/operators';

import { download } from '@enkod-core/utils';
import { NotificationsService, TToastDetail } from '@enkod-core/services';
import { Message } from '@enSend/message/_states/_state-message';
import { NotificationStatus } from 'ui-lib';
import { DateRange } from 'app/ui-lib/calendar-range/calendar-range.model';
import { MessageDetailDataService } from './message-detail-data.service';
import { MessageDetailStore } from './message-detail.store';
import {
	ChangeStatusDetails,
	DeleteMessageDetails,
	IMessageDataDetail,
	IMessageDetailModal,
	LinksQueryParams,
	LinkUpdate,
	MessageStatisticsType
} from './message-detail.model';

interface QueryParams {
	startDate?: number;
	endDate?: number;
	group?: 'day';
	contentType?: MessageStatisticsType;
}

@Injectable({ providedIn: 'root' })
export class MessageDetailService {
	linksEditorAvailable: boolean = true;
	constructor(
		private router: Router,
		private dataService: MessageDetailDataService,
		private store: MessageDetailStore,
		private notificationsService: NotificationsService
	) {}

	get activeId() {
		return this.store.getValue().active;
	}

	setActive(id: number) {
		this.store.setActive(id);
	}

	getDetailMessageById(id: ID) {
		this.store.setLoading(true);
		return this.dataService.getDetailById(id).pipe(
			tap(entity => this.store.upsert(entity.id, { preview: entity })),
			catchError(error => {
				this.notificationsService
					.show('toast.message_detail_cant_receive_message', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			})
		);
	}

	getDetailById(id: ID) {
		this.store.setLoading(true);
		return this.dataService.getDetailById(id).pipe(
			map((entity: IMessageDetailModal) => {
				return this.sortSplitTest(entity);
			}),
			tap(entity => {
				this.store.upsert(entity.id, { preview: entity });
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	updateTags(id: ID, data: object = {}) {
		return this.dataService.updateTags(id, data);
	}

	private sortSplitTest(entity: IMessageDetailModal) {
		if (entity.isSplitTest) {
			const sortedEntity = this.sortSplit(entity);
			return sortedEntity;
		}
		return entity;
	}

	private sortSplit(entity: IMessageDetailModal) {
		const mails = entity.mails?.sort((first, second) => {
			const firstLabel = first.splitTestLabel?.toLocaleLowerCase();
			const secondLabel = second.splitTestLabel?.toLocaleLowerCase();

			if (!firstLabel || !secondLabel) return 0;
			if (firstLabel < secondLabel) return -1;
			if (firstLabel > secondLabel) return 1;
			return 0;
		});
		return { ...entity, mails };
	}

	getObserverDetail(id: ID) {
		return this.dataService.getDetailById(id);
	}

	getMessageStatistics(
		id: ID,
		contentType: MessageStatisticsType
	): Observable<IMessageDataDetail[]> {
		const params: QueryParams = this.getParams();

		return this.dataService.getMessageStatistics(id, contentType, params);
	}

	getFilteredStats(id: ID) {
		this.store.setLoading(true);
		const params: QueryParams = {
			contentType: 'all',
			...this.getParams()
		};

		return this.dataService.detail(id, params).pipe(
			tap((resp: any) => {
				this.store.upsert(id, { filtred: resp });
				this.store.setLoading(false);
			})
		);
	}

	getGroupedAndGeneralStats(id: ID) {
		this.store.setLoading(true);
		const params: QueryParams = { ...this.getParams(), group: 'day' };
		return this.dataService.grouped(id, params).pipe(
			tap((resp: any) => {
				this.store.upsert(id, {
					grouped: resp.result || []
				});
			}),
			catchError(err => {
				this.showErrorToast();
				this.router.navigateByUrl('/ensend/list');
				return throwError(err);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	setDateFilters(value: DateRange): void {
		this.store.update({ filter: value });
		this.getFilteredStats(this.activeId);
		this.getGroupedAndGeneralStats(this.activeId).subscribe();
	}

	resetDateFilters() {
		this.store.update({
			filter: {
				startDate: null,
				endDate: null
			}
		});
	}

	chooseSplitWinner(messageId: number, winnerId: number) {
		this.store.setLoading(true);
		return this.dataService.chooseSplitWinner({ messageId, winnerId }).pipe(
			switchMap(() => this.getDetailById(this.activeId)),
			catchError(err => {
				this.showErrorToast();
				return throwError(err);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	getStatisticDetail(id: number) {
		return this.dataService.getStatisticDetail(id);
	}

	private getParams(): QueryParams {
		let params: QueryParams = {};
		const { startDate, endDate } = this.store.getValue().filter;
		if (startDate) params = { ...params, startDate };
		if (endDate) params = { ...params, endDate };
		return params;
	}

	getSplitStatistics(id: number): Observable<IMessageDataDetail[]> {
		const params: QueryParams = this.getParams();
		this.store.setLoading(true);
		return this.dataService
			.getSplitStatistics(id, { ...params, group: 'version' })
			.pipe(
				tap(resp => {
					this.store.upsert(id, { splitStatistics: resp });
				}),
				catchError(err => {
					this.showErrorToast();
					return throwError(err);
				}),
				finalize(() => {
					this.store.setLoading(false);
				})
			);
	}

	getAmpSplitStatistics(id: number): Observable<IMessageDataDetail[]> {
		const params: QueryParams = this.getParams();
		this.store.setLoading(true);
		return this.dataService
			.getAmpSplitStatistics(id, { ...params, group: 'version' })
			.pipe(
				tap(resp => {
					this.store.upsert(id, { splitStatistics: resp });
				}),
				catchError(err => {
					this.showErrorToast();
					return throwError(err);
				}),
				finalize(() => {
					this.store.setLoading(false);
				})
			);
	}

	setActiveSplitTest(activeSplitVersion: number) {
		this.store.update({ activeSplitVersion });
	}

	resetSplitTestVersion() {
		this.store.update({ activeSplitVersion: 0 });
	}

	getClicksStatistic(mailId: ID) {
		this.store.setLoading(true);
		return this.dataService
			.getClicksStatistic(this.activeId, { mailId })
			.pipe(
				tap(resp => {
					this.store.update({ clicksMapData: resp });
				}),
				catchError(err => {
					this.showErrorToast();
					return throwError(err);
				}),
				finalize(() => {
					this.store.setLoading(false);
				})
			);
	}

	getLinksData(queryParams: LinksQueryParams) {
		this.store.setLoading(true);
		return this.dataService.getLinksData(queryParams).pipe(
			tap(resp => {
				this.store.update({ linksEditorData: resp });
				this.linksEditorAvailable = true;
			}),
			catchError(err => {
				if (
					err.error.message?.includes(
						'links with such dynamic content'
					)
				) {
					this.linksEditorAvailable = false;
					this.store.update({ linksEditorData: [] });
				} else {
					this.showErrorToast();
				}
				return throwError(err);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	changeLink(data: LinkUpdate) {
		return this.dataService.changeLink(data).pipe(
			tap(() => {
				this.notificationsService
					.show('', {
						label: 'clicks_map.update_toast',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			})
		);
	}

	cancelSentMessage(id: number) {
		return this.dataService.cancelSend(id).pipe(
			tap(() => {
				this.notificationsService
					.show('toast.detail_message_cancel', {
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			}),
			catchError(e => {
				this.showErrorToast();
				return throwError(e);
			})
		);
	}

	changeStatus(data: ChangeStatusDetails) {
		return this.dataService.changeStatus(data).pipe(
			tap(resp => {
				this.showToast(resp);
			}),
			catchError(e => {
				this.showErrorToast();
				return throwError(e);
			})
		);
	}

	deleteMessage({ id, name }: DeleteMessageDetails): Observable<void> {
		return this.dataService.deleteMessage(id).pipe(
			tap(() => {
				this.notificationsService
					.show('toast.detail_message_delete', {
						label: 'toast.deleted',
						status: NotificationStatus.SUCCESS,
						params: {
							id,
							name
						}
					})
					.subscribe();
			}),
			catchError(e => {
				this.showErrorToast();
				return throwError(e);
			})
		);
	}

	exportStat(id: number) {
		const queryParams = this.getParams();

		return this.dataService.exportStat(id, queryParams).pipe(
			tap(resp => {
				const fileName = resp.headers.get('X-File-Name');
				download(resp.body, fileName);
			}),
			catchError(error => {
				this.showErrorToast();
				return throwError(error);
			})
		);
	}

	private showToast(entity: Message): void {
		let summary: string;
		let detail: TToastDetail;

		switch (entity.status) {
			case 'active':
				summary = 'toast.summary_message_active';
				detail = {
					message: 'toast.detail_message_active',
					params: {
						id: entity.id
					}
				};
				break;

			case 'inactive':
				if (entity.sendingType === 'scenario') {
					summary = 'toast.created';
					detail = {
						message: 'toast.detail_message_create',
						params: {
							id: entity.id,
							name: entity.name
						}
					};
					break;
				}
				summary = 'toast.summary_message_inactive';
				detail = {
					message: 'toast.detail_message_inactive',
					params: {
						id: entity.id
					}
				};

				break;

			default:
				summary = 'toast.created';
				detail = {
					message: 'toast.detail_message_create',
					params: {
						id: entity.id,
						name: entity.name
					}
				};
				break;
		}
		this.notificationsService
			.show(detail.message, {
				label: summary,
				status: NotificationStatus.SUCCESS,
				params: detail?.params
			})
			.subscribe();
	}

	private showErrorToast(): void {
		this.notificationsService
			.show('toast.detail_request_error', {
				label: 'toast.summary_try_later',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}
}
