import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	EnButtonModule,
	EnDropdownModule,
	EnMessagesModule,
	ErrorModule
} from 'ui-lib';
import { TuiLetModule } from '@taiga-ui/cdk';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared';
import { MailingGroupComponent } from './mailing-group.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { MailingGroupService } from './mailing-group.service';
import { ChosenItemInfoModule } from '../../components/chosen-item-info/chosen-item-info.module';
import { ChooseMailingGroupDialogModule } from './dialogs/choose-mailing-group-dialog/choose-mailing-group-dialog.module';
import { ChooseSegmentDialogModule } from './dialogs/choose-segment-dialog/choose-segment-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		EnButtonModule,
		EnDropdownModule,
		TuiLetModule,
		ChosenItemInfoModule,
		EnMessagesModule,
		TranslateModule,
		ErrorModule,
		ChooseMailingGroupDialogModule,
		ChooseSegmentDialogModule
	],
	declarations: [MailingGroupComponent],
	exports: [MailingGroupComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: MailingGroupService,
			multi: true
		}
	]
})
export class MailingGroupModule {}
