/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { block, blockView } from '../../base.shapes';
import { ChatbotShapesEnum } from '../../../../config/enum.shapes';

export const start = block.define(
	ChatbotShapesEnum.START,
	{
		options: {
			triggerCondition: '/start',
			isValid: true
		},
		size: { width: 260, height: 52 },
		ports: {
			items: [
				{
					group: 'out'
				}
			]
		},
		attrs: {
			headerRect: {
				fill: '#1EBD53',
				stroke: '#16A847'
			}
		}
	},
	{
		initialize(...args: any) {
			block.prototype.initialize.apply(this, args);
			this.onChangeOptions();
		},

		onChangeOptions(args: any) {
			block.prototype.onChangeOptions.call(this, {
				updatePorts: true,
				...args
			});
		}
	}
);

export const startView: dia.ElementView = Object.assign(blockView);
