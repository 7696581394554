<form [formGroup]="actionLinkForm">
    <div class="addition"
         [class.highlight]="isHover">
        <span class="center-span-text prefix">{{ 'segment_message_options.specific_link_click' | translate }}</span>
        <input class="h32 text-overflow"
               [placeholder]="'segment_message_options.link_input_placeholder' | translate"
               enInput
               formControlName="url"
               enDynamicWidth="15rem 37.5rem" />
        <i class="center-button en en-link"
           (click)="openDialog()"></i>
        <i *ngIf="isShowRemoveButton"
           class="button-remove-condition en en-cancel-fill"
           (mouseenter)="isHover = true"
           (mouseleave)="isHover = false"
           (click)="remove.emit()"></i>
    </div>
</form>

<en-link-picker-dialog *ngIf="visibleDialog"
                       [form]="form"
                       [visibleDialog]="visibleDialog"
                       (applyLink)="applyLink($event)"
                       (closeDialog)="visibleDialog = false"></en-link-picker-dialog>