import {
	Component,
	ChangeDetectionStrategy,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { SegmentsDataService } from '@enKod/segments/_state/segments-data.service';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import { EventParamOption } from '@state-enKod/segments';
import { debounceTime, tap } from 'rxjs/operators';
import { EventParameterForm } from '@enKod/segments/segments-form.model';
import {
	OPERATOR_BOOLEAN_OPTIONS,
	OPERATOR_NUMBER_OPTIONS,
	OPERATOR_STRING_OPTIONS
} from './param-operators';

@UntilDestroy()
@Component({
	selector: 'en-param-condition',
	templateUrl: './event-param-condition.component.html',
	styleUrls: [
		'../user-event.component.scss',
		'../../../abstract-condition.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventParamСonditionComponent implements OnInit {
	isHover = false;
	stringOptions = OPERATOR_STRING_OPTIONS;
	numberOptions = OPERATOR_NUMBER_OPTIONS;
	booleanOptions = OPERATOR_BOOLEAN_OPTIONS;

	paramOptions$ = new BehaviorSubject<SelectItem[]>([]);
	operatorOptions$ = new BehaviorSubject<SelectItem[]>([]);

	@Input() form: FormGroup<EventParameterForm>;

	@Input() isHide: boolean;

	@Output() remove = new EventEmitter();

	constructor(
		private dataService: SegmentsDataService,
		private validationService: SegmentsValidateService,
		private cd: ChangeDetectorRef
	) {}

	get paramControl(): FormControl<number | null> {
		return this.form.controls.id as FormControl<number | null>;
	}

	get typeControl(): FormControl<string> {
		return this.form.controls.type as FormControl<string>;
	}

	get operatorControl(): FormControl<string> {
		return this.form.controls.operator as FormControl<string>;
	}

	get valueControl(): FormControl<string> {
		return this.form.controls.value as FormControl<string>;
	}

	get operatorValue(): string {
		return this.form.controls.operator.value;
	}

	get paramValue(): number | null {
		return this.paramControl.value;
	}

	get typeValue(): string {
		return this.typeControl.value;
	}

	get showValueInput(): boolean {
		return (
			this.typeValue !== 'bool' &&
			this.operatorValue !== 'empty' &&
			this.operatorValue !== 'not_empty'
		);
	}

	get isDateType(): boolean {
		return this.typeValue === 'date' || this.typeValue === 'dateTime';
	}

	ngOnInit(): void {
		this.addOperatorOptions(this.typeControl.value, true);
		this.checkValueControl(this.operatorValue);
		this.setListeners();
	}

	onChangeParam(event: { option: EventParamOption }): void {
		this.form.patchValue({
			id: event.option.value,
			name: event.option.label,
			type: event.option.type
		});
	}

	private setListeners() {
		this.dataService
			.getUserParamList()
			.pipe(
				untilDestroyed(this),
				tap(params => {
					this.paramOptions$.next(
						params.map(param => ({
							id: param.id,
							label: param.systemName,
							value: param.id,
							type: param.type
						}))
					);
				})
			)
			.subscribe();

		this.paramControl.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					this.addOperatorOptions(this.typeControl.value);
				})
			)
			.subscribe();

		this.operatorControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(operator => {
					this.checkValueControl(operator);
				})
			)
			.subscribe();

		this.validationService.checkedValidate
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide) {
						this.form.markAsPristine();
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private checkValueControl(operator: string): void {
		if (
			operator === 'empty' ||
			operator === 'not_empty' ||
			this.typeValue === 'bool'
		) {
			this.valueControl?.disable();
		} else {
			this.valueControl?.enable();
		}
	}

	private addOperatorOptions(type: string, isFirstLoad?: boolean) {
		this.disableDateForm();
		switch (type) {
			case 'string':
				this.operatorOptions$.next(this.stringOptions);
				if (!isFirstLoad) this.setStringForm();
				break;
			case 'bool':
				this.operatorOptions$.next(this.booleanOptions);
				if (!isFirstLoad) this.setBooleanForm();
				break;
			case 'float':
			case 'number':
				this.operatorOptions$.next(this.numberOptions);
				if (!isFirstLoad) this.setNumberForm();
				break;
			case 'date':
			case 'dateTime':
				this.enableDateForm();
				if (!isFirstLoad) this.operatorControl.patchValue('');
				break;
			default:
				break;
		}
	}

	private setStringForm() {
		this.disableDateForm();
		this.operatorControl.patchValue('equal');
		this.valueControl.patchValue('');
	}

	private setBooleanForm() {
		this.disableDateForm();
		this.operatorControl.patchValue('true');
	}

	private setNumberForm() {
		this.disableDateForm();
		this.operatorControl.patchValue('equal');
		this.valueControl.patchValue('');
	}

	private disableDateForm() {
		this.form.controls.start.disable();
		this.form.controls.end.disable();
		this.form.controls.units.disable();
	}

	private enableDateForm() {
		this.form.controls.start.enable();
		this.form.controls.end.enable();
		this.form.controls.units.enable();
	}
}
