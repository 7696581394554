<div>
    <ng-container [ngSwitch]="type">
        <en-dropdown *ngSwitchCase="'bool'"
                     class="inspector-setting"
                     styleClass="h36 en-dropdown-solid"
                     [options]="booleanExtraFieldOptions"
                     [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                     [attr.disabled]="!isCreationMode"
                     [formControl]="control">
        </en-dropdown>
        <input *ngSwitchCase="'float'"
               enInput
               class="inspector-setting"
               [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
               [imask]="maskFloat"
               [unmask]="'typed'"
               [disableControl]="!isCreationMode"
               [formControl]="control">
        <input *ngSwitchCase="'number'"
               enInput
               class="inspector-setting"
               type="number"
               [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
               [disableControl]="!isCreationMode"
               [formControl]="control">
        <input *ngSwitchCase="'text'"
               enInput
               class="inspector-setting"
               type="text"
               [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
               [disableControl]="!isCreationMode"
               [formControl]="control">
    </ng-container>
    <div *ngIf="showCalendarSettings"
         class="en-field-medium inspector-setting">
        <div class="en-field">
            <en-dropdown-calendar-new [type]="type"
                                      [disabled]="!isCreationMode"
                                      [formControl]="control"></en-dropdown-calendar-new>
        </div>
    </div>
</div>