<div class="data-change-start">
    <span class="description en-text-large">
        {{'scenario_block_data_change.description' | translate}}
    </span>

    <en-dropdown class="inspector-setting"
                 styleClass="h36"
                 [options]="selectOptions.dataTypeOptions"
                 [placeholder]="'scenario_block_data_change.condition_placeholder' | translate"
                 [attr.disabled]="!isCreationMode"
                 [formControl]="dataTypeControl">
    </en-dropdown>

    <ng-container *ngIf="showExtraFieldSettings">
        <div class="extra-field">
            <span class="control-header en-text-medium">
                {{'scenario_block_data_change.settings_text' | translate}}
            </span>

            <en-dropdown class="inspector-setting"
                         appendTo="body"
                         styleClass="h36"
                         [options]="selectOptions.extraFieldOptions"
                         [placeholder]="'scenario_block_data_change.pick_field_placeholder' | translate"
                         filter="true"
                         filterBy="label, id"
                         [attr.disabled]="!isCreationMode"
                         (onChange)="onExtraFieldChange()"
                         [formControl]="extraFieldControl">
                <ng-template let-field
                             pTemplate="item">
                    <div class="extraField-item__wrapper">
                        <div class="extraField-item__text">
                            <span *ngIf="field.value.id"
                                  class="id-badge extraField-item__id">id {{field.value.id}}</span>
                            {{field.value.label}}
                        </div>
                        <span [ngClass]="field.value.dataType | extraFieldsIconPipe"></span>
                    </div>
                </ng-template>
            </en-dropdown>
        </div>

        <div *ngIf="extraFieldControl.value">
            <span class="control-header en-text-medium extraField-setting__header">
                {{'scenario_block_data_change.settings_text_end' | translate}}</span>

            <en-email-extrafield *ngIf="isEmailExtrafield"
                                 [options]="selectOptions.emailExtraFieldOptions"
                                 [control]="changeTypeControl"
                                 [isCreationMode]="isCreationMode"
                                 (onChangeTypeChange)="onChangeTypeChange($event.value)"></en-email-extrafield>

            <ng-container *ngIf="!isEmailExtrafield">
                <en-dropdown class="inspector-setting"
                             styleClass="h36 en-dropdown-solid"
                             [options]="selectOptions.changeTypeOptions"
                             [attr.disabled]="!isCreationMode"
                             (onChange)="onChangeTypeChange($event.value)"
                             [formControl]="changeTypeControl">
                </en-dropdown>

                <en-extrafield-settings *ngIf="showSpecificSettings"
                                        [control]="specificValueControl"
                                        [isCreationMode]="isCreationMode"
                                        [type]="extraFieldType"></en-extrafield-settings>
            </ng-container>
        </div>
    </ng-container>
</div>

<en-duplicate-handling [message]="message"
                       [control]="duplicateHandling"
                       (changeDuplicateHandling)="changeDuplicateHandling($event)"></en-duplicate-handling>