import { UnsubscriptionReason } from '@enKod/segments/segments-form.model';
import { SelectItem } from 'primeng/api';

export const REASON_OPTIONS: SelectItem<UnsubscriptionReason>[] = [
	{
		label: 'segment_form.reason_from_message',
		value: 'mail'
	},
	{
		label: 'segment_form.reason_three_soft_bounces',
		value: 'soft_bounce_limit'
	},
	{
		label: 'segment_form.reason_contact_card',
		value: 'manual'
	},
	{
		label: 'segment_form.reason_segment_action',
		value: 'segment'
	},
	{
		label: 'segment_form.reason_import',
		value: 'import'
	},
	{
		label: 'segment_form.reason_global_blacklist',
		value: 'blacklist'
	},
	{
		label: 'segment_form.reason_API',
		value: 'api'
	},
	{
		label: 'segment_form.reason_scenario_finish',
		value: 'scenario'
	},
	// {
	// 	label: 'segment_form.reason_removed_mailing_group',
	// 	value: 'remove_mailing_group'
	// },
	{
		label: 'segment_form.reason_removed_contact',
		value: 'remove_person'
	}
];
