import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

@Component({
	selector: 'en-segments-tree-dialog',
	templateUrl: './segments-tree-dialog.component.html',
	styleUrls: ['./segments-tree-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentsTreeDialogComponent {
	@Input() dialogVisible: boolean;
	@Input() segmentName = '';

	@Output() onHide = new EventEmitter();
}
