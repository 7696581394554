<form [formGroup]="timeForm"
      class="addition"
      [class.highlight]="isHover"
      [ngSwitch]="true">
    <en-dropdown [options]="optionsPeriod"
                 styleClass="en-dropdown-solid"
                 (onChange)="operatorChange($event.value)"
                 formControlName="periodOperator">
    </en-dropdown>

    <!--между датами -->
    <ng-container *ngSwitchCase="periodOperatorValue === 'between'">
        <en-dropdown-calendar-range [formControl]="dateRangeControl"
                                    [showReset]="false"
                                    [isInvalid]="markRangeAsInvalid || isHover"
                                    [type]="'dateTime'"
                                    [period]="period"
                                    [readonly]="readonly">
        </en-dropdown-calendar-range>
    </ng-container>

    <!-- За последнее время -->
    <ng-container *ngSwitchCase="showLastTimeCondition">
        <input class="h32 add-time text-overflow"
               type="number"
               min="1"
               max="1000"
               step="1"
               enInput
               enDynamicWidth
               pKeyFilter="pint"
               formControlName="periodValue"
               (input)="updateValue()">

        <!-- Юниты мин, час, день, месяц -->
        <en-dropdown [options]="optionsUnits"
                     styleClass="en-dropdown-solid"
                     formControlName="units">
        </en-dropdown>
        <span class="center-span-text">{{'segment_form.cond_field_date_ago' | translate}}</span>
    </ng-container>
    <i *ngIf="isShowRemoveButton"
       class="button-remove-condition cancel-icon en en-cancel-fill"
       (mouseenter)="isHover = true"
       (mouseleave)="isHover = false"
       (click)="remove.emit()"></i>
</form>