import { InjectionToken } from '@angular/core';

export const DEFAULT_ALERT_AUTOCLOSE_TIMEOUT = 3000;

export enum NotificationStatus {
	INFO = 'info',
	WARNING = 'warning',
	SUCCESS = 'success',
	ERROR = 'error'
}

export enum PositionNotification {
	LEFT = 'left',
	RIGHT = 'right',
	CENTER = 'center'
}

export type NotificationAutoClose =
	| boolean
	| number
	| ((status: NotificationStatus) => number | boolean);

export const STATUS_ICON = {
	info: 'en en-info-circle-o',
	success: 'en en-check-circle-o',
	error: 'en en-exclamation-circle-o',
	warning: 'en en-exclamation-triangle-o'
} as const;

export interface NotificationAction {
	label: string;
	func: Function;
}

export interface NotificationTokenOptions {
	readonly autoClose: NotificationAutoClose;
	readonly label: string;
	readonly status: NotificationStatus;
	readonly hasIcon: boolean;
	readonly hasCloseButton: boolean;
	readonly position: PositionNotification;
	readonly botText: boolean;
	readonly style: Partial<Style>;
	readonly params: Object;
	readonly action: Partial<NotificationAction>;
}

export interface NotificationOptions {
	readonly autoClose?: NotificationAutoClose;
	readonly label?: string;
	readonly status?: NotificationStatus;
	readonly hasIcon?: boolean;
	readonly hasCloseButton?: boolean;
	readonly position?: PositionNotification;
	readonly botText?: boolean;
	readonly style?: Partial<Style>;
	readonly params?: Object;
	readonly action?: Partial<NotificationAction>;
}

export const NOTIFICATION_DEFAULT_OPTIONS: NotificationTokenOptions = {
	autoClose: true,
	label: '',
	status: NotificationStatus.INFO,
	hasIcon: true,
	hasCloseButton: true,
	position: PositionNotification.CENTER,
	botText: true,
	style: {},
	params: {},
	action: {}
};

export const NOTIFICATION_OPTIONS =
	new InjectionToken<NotificationTokenOptions>(
		'Default parameters for notification alert component',
		{
			factory: () => NOTIFICATION_DEFAULT_OPTIONS
		}
	);

export interface NotificationOptionsWithData<I> extends NotificationOptions {
	readonly data: I;
}

export interface ContextWithImplicit<T> {
	$implicit: T;
}

export interface Style {
	label: string;
	content: string;
}
