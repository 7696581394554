import {
	AbstractCategoryOptions,
	AbstractOptions,
	OptionsCategoryData,
	OptionsPage
} from '../conditions/common/models';

export const ABSTRACT_OPTIONS: AbstractOptions = {
	page: [
		{
			label: 'segment_options.product_options',
			value: 'product'
		},
		{
			label: 'segment_options.not_product_options',
			value: 'not_product'
		},
		{
			label: 'segment_options.cart_options',
			value: 'cart'
		},
		{
			label: 'segment_options.not_cart_options',
			value: 'not_cart'
		},
		{
			label: 'segment_options.purchase_options',
			value: 'purchase'
		},
		{
			label: 'segment_options.not_purchase_options',
			value: 'not_purchase'
		},
		{
			label: 'segment_options.favourite_options',
			value: 'favourite'
		},
		{
			label: 'segment_options.not_favourite_options',
			value: 'not_favourite'
		},
		{
			label: 'segment_options.categories_options',
			value: 'category_open'
		},
		{
			label: 'segment_options.not_categories_options',
			value: 'not_category_open'
		},
		{
			label: 'segment_options.comparison_options',
			value: 'comparison' as OptionsPage
		},
		{
			label: 'segment_options.not_comparison_options',
			value: 'not_comparison' as OptionsPage
		},
		{
			label: 'segment_options.visits_options',
			value: 'visits' as OptionsPage
		},
		{
			label: 'segment_options.not_visits_options',
			value: 'not_visits' as OptionsPage
		}
	],
	behavior: [
		{
			label: 'segment_options.time_options',
			value: 'time'
		},
		{
			label: 'segment_options.scroll_options',
			value: 'scroll'
		},
		{
			label: 'segment_options.referrers_options',
			value: 'referrers'
		},
		{
			label: 'segment_options.close_options',
			value: 'close'
		},
		{
			label: 'segment_options.cart_options',
			value: 'cartChange'
		}
	],
	link: [
		{
			label: 'segment_form.options_link_url',
			value: 'url'
		},
		{
			label: 'segment_form.options_link_utm',
			value: 'utm'
		}
	],
	event: [
		{
			label: 'segment_form.options_event_true',
			value: 'event'
		},
		{
			label: 'segment_form.options_event_false',
			value: 'not_event'
		}
	],
	operatorPeriod: [
		{
			label: 'segment_options.session_options',
			value: 'session'
		},
		{
			label: 'segment_form.options_period_all',
			value: 'all'
		},
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		},
		{
			label: 'segment_form.options_operator_more',
			value: 'more'
		}
	],
	operatorShort: [
		{
			label: 'segment_form.options_operator_more',
			value: 'more'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		}
	],
	operatorFull: [
		{
			label: 'segment_form.options_operator_more',
			value: 'more'
		},
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		}
	],
	operatorEquality: [
		{
			label: 'segment_form.options_operator_equal',
			value: 'equal'
		},
		{
			label: 'segment_options.not_equal_options',
			value: 'not_equal'
		},
		{
			label: 'segment_options.one_of_options',
			value: 'one_of'
		},
		{
			label: 'segment_options.not_one_of_options',
			value: 'not_one_of'
		}
	],
	operatorTagsEquality: [
		{
			label: 'recomm_algorithms.operator_equal',
			value: 'equal'
		},
		{
			label: 'recomm_algorithms.operator_not_equal',
			value: 'not_equal'
		},
		{
			label: 'segment_options.one_of_tags_options',
			value: 'one_of'
		},
		{
			label: 'segment_options.not_one_of_tags_options',
			value: 'not_one_of'
		}
	],
	operatorRfmEquality: [
		{
			label: 'recomm_algorithms.operator_equal',
			value: 'equal'
		},
		{
			label: 'recomm_algorithms.operator_not_equal',
			value: 'not_equal'
		}
	],
	operatorLogic: [
		{ label: 'segment_options.any_groups_options', value: 'any' },
		{ label: 'segment_options.each_groups_options', value: 'each' }
	],
	operatorUnsubscriptionLogic: [
		{ label: 'segment_options.from_all_groups_options', value: 'all' },
		{ label: 'segment_options.from_any_group_options', value: 'any' },
		{ label: 'segment_options.from_each_group_options', value: 'each' }
	],
	unitsDefault: [
		{
			label: 'segment_form.options_units_sec',
			value: 'sec'
		},
		{
			label: 'segment_form.options_units_min',
			value: 'min'
		},
		{
			label: 'segment_form.options_units_hour',
			value: 'hour'
		}
	]
};

export const ABSTRACT_OPTION_CATEGORIES: AbstractCategoryOptions = {
	data: [
		{
			label: 'segment_form.options_data_field',
			value: 'field'
		},
		{
			label: 'segment_form.options_data_subscription',
			value: 'subscription'
		},
		{
			label: 'segment_form.options_data_subscription_date',
			value: 'subscriptionDate'
		},
		{
			label: 'segment_form.options_data_birthday_date',
			value: 'birthdayDate'
		},
		{
			label: 'segment_form.options_data_unsubscribed',
			value: 'unsubscription' as OptionsCategoryData
		},
		{
			label: 'segment_form.options_data_tag',
			value: 'tag'
		},
		{
			label: 'contact_detail.rfm_current_tag',
			value: 'currentRFM'
		},
		{
			label: 'contact_detail.rfm_prev_tag',
			value: 'previousRFM'
		}
	],
	behavior: [
		{
			label: 'segment_form.options_behavior_page',
			value: 'page'
		},
		{
			label: 'segment_form.options_behavior_link',
			value: 'link'
		},
		{
			label: 'segment_form.options_behavior_device',
			value: 'device'
		},
		{
			label: 'segment_form.options_behavior_html',
			value: 'html'
		},
		{
			label: 'segment_form.options_behavior_history',
			value: 'visitsHistory'
		},
		{
			label: 'Local storage',
			value: 'localStorage'
		}
	],
	buying: [
		{
			label: 'segment_form.options_buying_product',
			value: 'product'
		},
		{
			label: 'segment_form.options_buying_cart',
			value: 'cart'
		},
		{
			label: 'segment_form.options_buying_purchase',
			value: 'purchase'
		},
		{
			label: 'segment_form.options_buying_favorites',
			value: 'favourite'
		}
	],
	dataTables: [
		{
			label: 'segment_form.options_data_tables_sql',
			value: 'sql_template'
		}
	]
};
