import { Injectable } from '@angular/core';
import { dia, setTheme, V, Vectorizer } from '@clientio/rappid';
// eslint-disable-next-line import/no-cycle
import { PreviewRappidService } from './preview-rappid.service';

@Injectable()
export class DimService {
	private dimmer: Vectorizer | null = null;

	dim(service: PreviewRappidService, elementView: dia.CellView) {
		if (this.dimmer) return;

		setTheme('enkod-scenario dim');
		service.paper.drawBackground({
			color: 'white'
		});
		this.dimmer = V('rect')
			.attr({
				fill: 'black',
				opacity: 0.3
			})
			.attr(service.paper.getArea().toJSON());

		service.paper.on('render:done', () => {
			this.dimmer?.attr(service.paper.getArea().toJSON());
		});

		elementView.model.toFront({ async: false });
		elementView.vel.before(this.dimmer);
	}

	resetDim(service: PreviewRappidService) {
		if (!this.dimmer) return;

		setTheme('enkod-scenario');

		this.dimmer?.node.remove();
		this.dimmer = null;

		service.paper.drawBackground({
			color: 'transparent'
		});
	}
}
