import {
	Component,
	ChangeDetectionStrategy,
	EventEmitter,
	Output,
	Input
} from '@angular/core';
import { IPopupContent } from 'app/modules/enPop/popups-detail/models';

@Component({
	selector: 'en-split-handler',
	templateUrl: './split-handler.component.html',
	styleUrls: ['./split-handler.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitHandlerComponent {
	@Input()
	readonly readonly = false;

	@Input()
	readonly content: IPopupContent[];

	@Input()
	readonly typeTest: string;

	@Input()
	readonly index: number;

	@Input()
	readonly winnerId: string;

	@Input() deletable = false;

	@Output()
	readonly createSplit = new EventEmitter();

	@Output()
	readonly switchTo = new EventEmitter<number>();

	@Output()
	readonly removeSplit = new EventEmitter<number>();

	get splitsLabels() {
		return this.typeTest.split('/');
	}

	remove(event: MouseEvent, index: number) {
		event.stopPropagation();
		this.removeSplit.emit(index);
	}
}
