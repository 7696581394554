import { Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AbstractCondition } from '../../../abstract-condition.component';

@Component({
	selector: 'en-url-condition',
	templateUrl: './url-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss']
})
export class UrlConditionComponent extends AbstractCondition {
	readonly optionsOperator: SelectItem[] = [
		{
			label: 'segment_form.options_operator_equal',
			value: 'equal'
		},
		{
			label: 'segment_options.not_equal_options',
			value: 'not_equal'
		},
		{
			label: 'segment_form.cond_url_starts',
			value: 'startWith'
		},
		{
			label: 'segment_options.not_prefix_options',
			value: 'not_startWith'
		},
		{
			label: 'segment_form.cond_url_contain',
			value: 'contain'
		},
		{
			label: 'segment_options.not_contain_options',
			value: 'not_contain'
		},
		{
			label: 'segment_form.cond_url_wild',
			value: 'wildCard'
		}
	];
}
