import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { KeyFilterModule } from 'primeng/keyfilter';

import { SharedModule } from '@shared';

import {
	EnDropdownCalendarNewModule,
	EnDropdownModule,
	EnInputModule,
	ErrorModule
} from 'ui-lib';

import { NumberRangeDirectiveModule } from '@enkod-core/directives';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';

import { PauseService } from './pause.service';
import { PauseComponent } from './pause.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnDropdownModule,
		EnInputModule,
		EnDropdownCalendarNewModule,
		ErrorModule,
		KeyFilterModule,
		NumberRangeDirectiveModule
	],
	declarations: [PauseComponent],
	exports: [PauseComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: PauseService,
			multi: true
		},
		DatePipe
	]
})
export class PauseModule {}
