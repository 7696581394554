/* eslint-disable import/no-cycle */
import { ScenarioShapesEnum, ChatbotShapesEnum } from './enum.shapes';
import { shapeConfig, ScenarioSubtype } from './shape.config';

export const StencilConfig = {
	scenario: {
		start: [
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.START_BLOCK,
				subType: ScenarioSubtype.MAILING_GROUP,
				attrs: {
					rect: { fill: '#1EBD53', stroke: '#16A847' },
					label: {
						text: shapeConfig.scenario[
							ScenarioSubtype.MAILING_GROUP
						].text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.MAILING_GROUP]
								.iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.MESSAGE_START_BLOCK,
				subType: ScenarioSubtype.MESSAGE_START,
				attrs: {
					rect: { fill: '#1EBD53', stroke: '#16A847' },
					label: {
						text: shapeConfig.scenario[
							ScenarioSubtype.MESSAGE_START
						].text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.MESSAGE_START]
								.iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.START_BLOCK,
				subType: ScenarioSubtype.API_START,
				attrs: {
					rect: { fill: '#1EBD53', stroke: '#16A847' },
					label: {
						text: shapeConfig.scenario[ScenarioSubtype.API_START]
							.text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.API_START]
								.iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.EXTRA_FIELD_START_BLOCK,
				subType: ScenarioSubtype.EXTRA_FIELD_START,
				attrs: {
					rect: { fill: '#1EBD53', stroke: '#16A847' },
					label: {
						text: shapeConfig.scenario[
							ScenarioSubtype.EXTRA_FIELD_START
						].text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[
								ScenarioSubtype.EXTRA_FIELD_START
							].iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.START_BLOCK,
				subType: ScenarioSubtype.SCHEDULE_START,
				attrs: {
					rect: { fill: '#1EBD53', stroke: '#16A847' },
					label: {
						text: shapeConfig.scenario[
							ScenarioSubtype.SCHEDULE_START
						].text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.SCHEDULE_START]
								.iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.START_BLOCK,
				subType: ScenarioSubtype.EVENT_START,
				attrs: {
					rect: { fill: '#1EBD53', stroke: '#16A847' },
					label: {
						text: shapeConfig.scenario[ScenarioSubtype.EVENT_START]
							.text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.EVENT_START]
								.iconLinkHref
					}
				}
			}
		],
		main: [
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.MAIN_BLOCK,
				subType: ScenarioSubtype.PAUSE,
				attrs: {
					rect: { fill: '#1B77E0', stroke: '#1061C3' },
					label: {
						text: shapeConfig.scenario[ScenarioSubtype.PAUSE].text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.PAUSE]
								.iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.SEND_MESSAGE_BLOCK,
				subType: ScenarioSubtype.SEND_MESSAGE,
				attrs: {
					rect: { fill: '#1B77E0', stroke: '#1061C3' },
					label: {
						text: shapeConfig.scenario[ScenarioSubtype.SEND_MESSAGE]
							.text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.SEND_MESSAGE]
								.iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.DISTRIBUTION_BLOCK,
				subType: ScenarioSubtype.DISTRIBUTION,
				attrs: {
					rect: { fill: '#1B77E0', stroke: '#1061C3' },
					label: {
						text: shapeConfig.scenario[ScenarioSubtype.DISTRIBUTION]
							.text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.DISTRIBUTION]
								.iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.DATA_CHANGE_MAIN_BLOCK,
				subType: ScenarioSubtype.DATA_CHANGE_MAIN,
				attrs: {
					rect: { fill: '#1B77E0', stroke: '#1061C3' },
					label: {
						text: shapeConfig.scenario[
							ScenarioSubtype.DATA_CHANGE_MAIN
						].text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[
								ScenarioSubtype.DATA_CHANGE_MAIN
							].iconLinkHref
					}
				}
			},
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.MAIN_BLOCK,
				subType: ScenarioSubtype.API_QUERY,
				attrs: {
					rect: { fill: '#1B77E0', stroke: '#1061C3' },
					label: {
						text: shapeConfig.scenario[ScenarioSubtype.API_QUERY]
							.text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.API_QUERY]
								.iconLinkHref
					}
				}
			}
		],
		end: [
			{
				name: 'Block',
				appShapeName: ScenarioShapesEnum.END_BLOCK,
				subType: ScenarioSubtype.FINISH,
				attrs: {
					rect: { fill: '#8619E5', stroke: '#7210C7' },
					label: {
						text: shapeConfig.scenario[ScenarioSubtype.FINISH].text
					},
					icon: {
						xlinkHref:
							shapeConfig.scenario[ScenarioSubtype.FINISH]
								.iconLinkHref
					}
				}
			}
		]
	},
	chatbot: {
		start: [
			{
				name: 'Block',
				appShapeName: ChatbotShapesEnum.START,
				namespace: 'chatbot',
				subType: ChatbotShapesEnum.START,
				attrs: {
					rect: { fill: '#1EBD53', stroke: '#16A847' },
					label: {
						text: shapeConfig.chatbot[ChatbotShapesEnum.START].text
					},
					icon: {
						xlinkHref:
							shapeConfig.chatbot[ChatbotShapesEnum.START]
								.iconLinkHref
					}
				}
			}
		],
		main: [
			{
				name: 'Block',
				appShapeName: ChatbotShapesEnum.MESSAGE,
				namespace: 'chatbot',
				subType: ChatbotShapesEnum.MESSAGE,
				attrs: {
					rect: { fill: '#234EC4', stroke: '#153EB5' },
					label: {
						text: shapeConfig.chatbot[ChatbotShapesEnum.MESSAGE]
							.text
					},
					icon: {
						xlinkHref:
							shapeConfig.chatbot[ChatbotShapesEnum.MESSAGE]
								.iconLinkHref
					}
				}
			}
		],
		end: []
	}
};
