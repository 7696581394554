import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'en-back-button',
	templateUrl: './back-button.component.html',
	styleUrls: ['./back-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackButtonComponent {
	@Input() group: FormGroup;

	@Output() deleteButton = new EventEmitter();

	get textControl(): FormControl {
		return this.group.controls.label as FormControl;
	}
}
