import { SelectItem } from 'primeng/api';

export const OptionsText: SelectItem[] = [
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_form.cond_html_contain',
		value: 'contain'
	},
	{
		label: 'segment_options.not_contain_options',
		value: 'not_contain'
	},
	{
		label: 'segment_form.options_operator_start_with',
		value: 'prefix'
	},
	{
		label: 'segment_options.not_prefix_options',
		value: 'not_prefix'
	}
];

export const OptionsEmail: SelectItem[] = [
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_form.options_operator_start_with',
		value: 'prefix'
	},
	{
		label: 'segment_options.postfix_options',
		value: 'postfix'
	},
	{
		label: 'segment_form.options_operator_domain',
		value: 'contain'
	},
	{
		label: 'segment_options.not_contain_domain',
		value: 'not_contain'
	}
];

export const OptionsPhone: SelectItem[] = [
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_form.options_operator_start_with',
		value: 'prefix'
	},
	{
		label: 'segment_options.not_prefix_options',
		value: 'not_prefix'
	}
];

export const OptionsNumber: SelectItem[] = [
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_form.options_operator_less',
		value: 'less'
	},
	{
		label: 'segment_form.options_operator_lessOrEqual',
		value: 'lessOrEqual'
	},
	{
		label: 'segment_form.options_operator_more',
		value: 'more'
	},
	{
		label: 'segment_form.options_operator_moreOrEqual',
		value: 'moreOrEqual'
	}
];

export const OptionsBoolean: SelectItem[] = [
	{
		label: 'segment_form.options_field_boolean_true',
		value: 'true'
	},
	{
		label: 'segment_form.options_field_boolean_false',
		value: 'false'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	}
];

export const OptionsList: SelectItem[] = [
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_options.one_of_tags_options',
		value: 'one_of'
	},
	{
		label: 'segment_options.not_one_of_tags_options',
		value: 'not_one_of'
	}
];
