import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnButtonModule, EnDialogModule, EnLoaderOverlayModule } from 'ui-lib';
import { SharedModule } from '@shared';
import { PopupCardModule } from 'app/modules/enPop/_shared';

import { DeleteSegmentWithRelationsComponent } from './delete-segment-with-relations.component';

@NgModule({
	imports: [
		CommonModule,
		EnDialogModule,
		EnButtonModule,
		SharedModule,
		PopupCardModule,
		EnLoaderOverlayModule
	],
	declarations: [DeleteSegmentWithRelationsComponent],
	exports: [DeleteSegmentWithRelationsComponent]
})
export class DeleteSegmentWithRelationsModule {}
