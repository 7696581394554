import { FormArray } from '@angular/forms';
import { ScriptSettingsModel } from 'app/modules/enPop/popup-wizard/models';
import { IframeTemplate } from './types';

export function changeSelectedPagePreview(
	scriptSettings: ScriptSettingsModel,
	htmlStr: string,
	page: 'popup' | 'thanksPage'
): string {
	if (!scriptSettings.containerSelector || !scriptSettings.thanksPageClass)
		return htmlStr;

	const html = parseHtml(htmlStr);
	const elm = html.querySelector(scriptSettings.containerSelector);

	if (!elm) return htmlStr;

	let classArray = elm.className.split(' ');

	if (page === 'thanksPage') {
		classArray.push(scriptSettings.thanksPageClass);
	} else
		classArray = classArray.filter(
			item => item !== scriptSettings.thanksPageClass
		);

	elm.className = classArray.join(' ');

	return html.body.innerHTML.toString();
}

export function resetPagePreview(form: FormArray) {
	form.controls.forEach(group => {
		const { scriptSettings, html } = group.value;
		group.patchValue({
			html: changeSelectedPagePreview(scriptSettings, html, 'popup')
		});
	});
}

export function findPosition(template: IframeTemplate): string {
	if (!template) return '';
	const html = parseHtml(template.html);
	const elm = html.querySelector('#enpop');

	const className = elm!.className
		.split(' ')
		.find(item => item.includes('position'));

	return className ?? elm!.className.split(' ').at(1)! ?? elm!.className;
}

function parseHtml(html: string): Document {
	const parser = new DOMParser();
	return parser.parseFromString(html, 'text/html');
}
