import { SelectItem } from 'primeng/api';

export const ACTION_OPTIONS: SelectItem[] = [
	{
		label: 'scenario_block_finish.nothing_option',
		value: 'nothing'
	},
	{
		label: 'scenario_block_finish.resub_option',
		value: 'resub'
	},
	{
		label: 'scenario_block_finish.delete_option',
		value: 'remove'
	}
];

export const SUBSCRIBE_OPTIONS: SelectItem[] = [
	{
		label: 'scenario_block_finish.all_option',
		value: 'all'
	},
	{
		label: 'scenario_block_finish.all_groups_option',
		value: 'current_unsub'
	},
	{
		label: 'scenario_block_finish.selected_option',
		value: 'select_unsub'
	}
];
