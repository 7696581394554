import { Injectable } from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
	MessagesService,
	NewAttachment,
	SplitMail
} from '@enSend/message/_states/_state-message';
import { MessageTypeService } from '../../kit';
import { SPLIT_TEST_LABEL } from '../constants';
import {
	DEFAULT_DOI_TEMPLATE,
	DEFAULT_DOI_PLAIN_TEXT,
	DEFAULT_HTML,
	DEFAULT_STRIPO_HTML
} from '../step-content/2-step-editor/email-editor/constants';
import { StepEditorService } from './2-step-editor.service';

@Injectable()
export class StepSplitTestService {
	private form: UntypedFormGroup;

	private _splitIndex = 0;

	public readonly versions = SPLIT_TEST_LABEL;

	splitIndex$ = new BehaviorSubject<number>(this._splitIndex);

	constructor(
		private fb: UntypedFormBuilder,
		private editorService: StepEditorService,
		private messageTypeService: MessageTypeService,
		private messageService: MessagesService
	) {}

	get splitForm(): UntypedFormGroup {
		return this.form;
	}

	get splitIndex(): number {
		return this._splitIndex;
	}

	/** В будущем сделать для динамичного типа сообщения */
	get splits(): UntypedFormArray {
		return this.editorService.mails;
	}

	get splitLength(): number {
		return this.splits?.length;
	}

	get hasSplit(): boolean {
		return this.splitLength > 1;
	}

	get splitIndexChanges() {
		return this.splitIndex$.asObservable();
	}

	get messageId(): number {
		return this.splits.value[0].messageId;
	}

	/** Временное, не самое лучшее решенее. Сделано так, ибо если вернуть formMessage в 2-step-editor, то мы не сможешь закинуть туда splitIndex из этого сервиса, тк будет circular dependency detected, при первой возможности переделать путем создания нового общего сервиса для всех step-services, чтобы не инжектить их друг в друга  */
	get formMessage(): UntypedFormGroup {
		switch (this.messageTypeService.messageType) {
			case 'push':
				return this.editorService.pushes?.at(0) as UntypedFormGroup;
			default:
				return this.editorService.mails?.at(
					this.splitIndex
				) as UntypedFormGroup;
		}
	}

	init(): void {
		this.form = this.initSplitForm();
	}

	private initSplitForm(): UntypedFormGroup {
		return this.fb.group({
			choiceWinner: 'clicks',
			duration: 1,
			unit: 'hour',
			testGroupSize: 0.5
		});
	}

	setDefaultDoiTemplate(): void {
		this.formMessage.patchValue({
			html: DEFAULT_DOI_TEMPLATE,
			plainText: DEFAULT_DOI_PLAIN_TEXT,
			stripoHtml: DEFAULT_DOI_TEMPLATE
		});
	}

	setDefaultTemplate(): void {
		this.formMessage.patchValue({
			html: DEFAULT_HTML,
			plainText: '',
			stripoHtml: DEFAULT_STRIPO_HTML
		});
	}

	resetAmp(): void {
		this.formMessage?.patchValue({
			amp: ''
		});
	}

	createSplit(): void {
		if (this.splitLength < 5) {
			const previosFormValue = this.splits.at(
				this.splits.length - 1
			).value;
			const formMail = this.editorService.initMail();

			const originalIsNewValues = previosFormValue.attachments?.map(
				(att: Partial<NewAttachment>) => att.isNew
			);

			const attachmentsWithoutIsNew = previosFormValue.attachments?.map(
				({ isNew, ...rest }: Partial<NewAttachment>) => rest
			);

			const splitData: SplitMail = {
				messageId: this.messageId,
				mailId: this.splits.value[this.splits.length - 1].id,
				splitTestLabel: this.versions[this.splitLength],
				attachments: attachmentsWithoutIsNew
			};

			this.messageService.getMailId(splitData).subscribe(res => {
				const mergedAttachments = res.attachments?.map(
					(att, index) => ({
						...att,
						isNew: originalIsNewValues[index]
					})
				);

				formMail.patchValue({
					...previosFormValue,
					id: res.mailId,
					attachments: mergedAttachments
				});
			});

			this.editorService.mails.push(formMail);
		} else {
			throw new Error(
				'Превышен лимит допустимых значений сплит тестов, проверь логику в компоненте split handler'
			);
		}
	}

	removeSplit(index: number): void {
		this.editorService.mails.removeAt(index);
	}

	removeAllSplit() {
		let index = this._splitIndex;
		while (this.splitLength > 1) {
			this.editorService.mails.removeAt(index--);
		}
		this.setSplitIndex(0);
	}

	setSplitIndex(index: number): void {
		this._splitIndex = index;
		this.splitIndex$.next(index);
	}

	decreseSplitIndex(): void {
		this.setSplitIndex(this._splitIndex - 1);
	}

	resetSplitIndex() {
		this.setSplitIndex(0);
	}
}
