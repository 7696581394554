import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	Output
} from '@angular/core';

import { SelectItem } from 'primeng/api';

import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';

import { ContactExtraFields } from '@enKod/contacts-detail/_state/contacts-detail';
import { DATA_TYPE_OPTIONS } from '@enKod/extra-fields/components/extra-field-maker/data-type-options';
import { EditExtrafieldNameService } from '@enKod/contacts-detail/contacts-client-card/edit-extrafield-name/edit-extrafield-name.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListOption } from '@state-enKod/extra-fields';
import { markAllAsDirty } from 'utils';
import { validateListDuplicates } from '@enKod/extra-fields/components/extra-field-maker/list-duplicate.validator';
import { EditExtafieldNameServiceForm } from './edit-extafield-name-form.service';

@Component({
	selector: 'en-edit-extrafield-name',
	templateUrl: './contacts-dialog-edit-field-name.component.html',
	styleUrls: ['./contacts-dialog-edit-field-name.component.scss'],
	providers: [
		EditExtafieldNameServiceForm,
		EditExtrafieldNameService,
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: DATA_TYPE_OPTIONS
		},
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'extra_field_form.error_required',
				isTakenValue: 'extra_field_form.error_taken_name'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsDialogEditFieldNameComponent {
	readonly loading$ = this.extrafieldDataService.loading$;

	initialListOptions: ContactExtraFields;
	listEditConfirmVisible = false;

	@Input() visible = false;
	@Input() set extraField(value: ContactExtraFields) {
		const convertedField = { ...value, dataType: value.type };
		this.initialListOptions = convertedField;
		this.form.patchValue(convertedField);

		const listOptionsFA = this.form.get('listOptions') as FormArray;
		listOptionsFA.clear();

		if (
			convertedField.dataType === 'list' &&
			Array.isArray(convertedField.listOptions)
		) {
			this.fillListOptions(listOptionsFA, convertedField.listOptions);
		}
	}

	@Output() onHide = new EventEmitter<void>();
	@Output() updateData = new EventEmitter<void>();

	constructor(
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly dataTypeOptions: SelectItem[],
		private extraFieldFormService: EditExtafieldNameServiceForm,
		private extrafieldDataService: EditExtrafieldNameService,
		private fb: FormBuilder,
		private cd: ChangeDetectorRef
	) {}

	get form() {
		return this.extraFieldFormService.form;
	}

	get nameHasError() {
		return this.form.get('name')?.hasError('required');
	}

	get formValue() {
		return this.form.value;
	}

	get formValueType() {
		return this.formValue.dataType;
	}

	get listOptionsControlArray(): FormArray {
		return this.form.get('listOptions') as FormArray;
	}

	get listChanged(): boolean {
		return (
			JSON.stringify(this.listOptionsControlArray?.value) !==
			JSON.stringify(this.initialListOptions?.listOptions)
		);
	}

	addListItem(option?: ListOption) {
		this.listOptionsControlArray.push(this.createListOptionGroup(option));
		this.cd.detectChanges();
	}

	removeListItem(i: number) {
		this.listOptionsControlArray.removeAt(i);
	}

	submit(): void {
		markAllAsDirty(this.form);

		if (
			this.formValueType === 'list' &&
			!validateListDuplicates(this.listOptionsControlArray)
		)
			return;
		if (!this.form.valid) return;

		this.formValueType === 'list' && this.listChanged
			? (this.listEditConfirmVisible = true)
			: this.onEditAccept();
	}

	onEditAccept() {
		this.updateData.emit(this.formValue);
		this.listEditConfirmVisible = false;
		this.cd.detectChanges();
	}

	private fillListOptions(listOptionsFA: FormArray, options: ListOption[]) {
		[...options]
			.sort((a, b) => {
				const aId = Number(a.id);
				const bId = Number(b.id);
				if (aId === 0 && bId !== 0) return 1;
				if (bId === 0 && aId !== 0) return -1;
				return aId - bId;
			})
			.forEach(opt =>
				listOptionsFA.push(this.createListOptionGroup(opt))
			);
	}

	private createListOptionGroup(option?: ListOption): FormGroup {
		return this.fb.group({
			id: [option?.id ?? 0],
			name: [option?.name ?? '', Validators.required]
		});
	}
}
