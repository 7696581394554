import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import {
	ActionTimeForm,
	FavouriteConditionForm
} from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';

type FavouriteParameterOptions = 'quantity' | 'atLeastOne';

@UntilDestroy()
@Component({
	selector: 'en-favourite-condition',
	templateUrl: './favourite-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavouriteConditionComponent extends AbstractCondition {
	readonly optionsPeriod: SelectItem[] = [
		{
			label: 'segment_form.cond_favourite_all_time',
			value: 'all'
		},
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		},
		{
			label: 'segment_form.options_operator_more',
			value: 'more'
		}
	];

	readonly optionsParameter: SelectItem<FavouriteParameterOptions>[] = [
		{
			label: 'segment_form.cond_favourite_total',
			value: 'quantity'
		},
		{
			label: 'segment_form.cond_favourite_at_least',
			value: 'atLeastOne'
		}
	];

	optionsUnits: SelectItem[] = [];

	periodValueSubscription: Subscription | undefined = undefined;

	get typedForm(): FormGroup<FavouriteConditionForm> {
		return this.form as FormGroup<FavouriteConditionForm>;
	}

	get actionTime(): FormGroup<ActionTimeForm> {
		return this.typedForm.controls.actionTime as FormGroup<ActionTimeForm>;
	}

	get parameterValue(): string {
		return this.typedForm.controls.parameter.value as string;
	}

	get parameterControl(): FormControl<string> {
		return this.typedForm.controls.parameter as FormControl<string>;
	}

	get valueControl(): FormControl<number> {
		return this.typedForm.controls.value as FormControl<number>;
	}

	get operatorControl(): FormControl<string> {
		return this.typedForm.controls.operator as FormControl<string>;
	}

	setValidation() {
		this.parameterControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(option => {
				if (option === 'quantity') {
					this.valueControl?.setValidators([Validators.required]);
					this.setQuantity('more', 2);
					this.valueControl?.updateValueAndValidity();
				}
				if (option === 'atLeastOne') {
					this.setQuantity('moreOrEqual', 1);
					this.valueControl?.updateValueAndValidity();
				}
			});
	}

	private setQuantity(operator: string, value: number): void {
		this.typedForm.controls.operator.enable();
		this.typedForm.controls.value.enable();
		this.typedForm.patchValue({ operator, value });
	}
}
