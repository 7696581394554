<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown class="input__medium"
                 styleClass="en-dropdown-solid"
                 [options]="optionsLink"
                 [formControl]="type"
                 (onChange)="onChangeType($event)"></en-dropdown>
    <en-dropdown class="input__medium"
                 styleClass="en-dropdown-solid"
                 [options]="optionsName"
                 formControlName="name"></en-dropdown>
    <en-dropdown class="input__medium"
                 styleClass="en-dropdown-solid"
                 [options]="optionsOperator"
                 formControlName="operator"></en-dropdown>
    <input class="h32 text-overflow"
           type="text"
           enInput
           enDynamicWidth="15rem"
           showClear="true"
           formControlName="value" />
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"></en-condition-tools>
    </div>
</form>