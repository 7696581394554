<span #container
      [ngClass]="{
				'en-autocomplete en-component': true,
				'en-autocomplete-dd': dropdown,
                'en-autocomplete-multiple': multiple
			}"
      [ngStyle]="style"
      [class]="styleClass">
    <input *ngIf="!multiple"
           #in
           [attr.type]="type"
           [attr.id]="inputId"
           [ngStyle]="inputStyle"
           [class]="inputStyleClass"
           [autocomplete]="autocomplete"
           [attr.required]="required"
           [attr.name]="name"
           class="en-autocomplete-input  en-inputtext en-component"
           [ngClass]="{
					'en-autocomplete-dd-input': dropdown,
					'en-disabled': disabled
				}"
           [value]="inputFieldValue"
           autocomplete="off"
           aria-autocomplete="list"
           [attr.aria-controls]="listId"
           role="searchbox"
           [attr.aria-expanded]="overlayVisible"
           aria-haspopup="true"
           [attr.aria-activedescendant]="'en-highlighted-option'"
           (click)="onInputClick($event)"
           (input)="onInput($event)"
           (keydown)="onKeydown($event)"
           (keyup)="onKeyup($event)"
           [attr.autofocus]="autofocus"
           (focus)="onInputFocus($event)"
           (blur)="onInputBlur($event)"
           (change)="onInputChange($event)"
           (paste)="onInputPaste($event)"
           [attr.placeholder]="placeholder"
           [attr.size]="size"
           [attr.maxlength]="maxlength"
           [attr.tabindex]="tabindex"
           [readonly]="readonly"
           [disabled]="disabled"
           [attr.aria-label]="ariaLabel"
           [attr.aria-labelledby]="ariaLabelledBy"
           [attr.aria-required]="required" />
    <ul *ngIf="multiple"
        #multiContainer
        class="en-autocomplete-multiple-container en-component en-inputtext"
        [ngClass]="{ 'en-disabled': disabled, 'en-focus': focus}"
        (click)="multiIn.focus()">
        <li #token
            *ngFor="let val of value"
            class="en-autocomplete-token text-color-badge">
            <ng-container *ngTemplateOutlet="
							selectedItemTemplate;
							context: { $implicit: val }
						"></ng-container>
            <span *ngIf="!selectedItemTemplate"
                  class="en-autocomplete-token-label">
                {{ resolveFieldData(val) }}
            </span>
            <span *ngIf="!disabled && val !== '__sys_tag_bot___'"
                  class="en-autocomplete-token-icon en en-cancel-fill"
                  (click)="removeItem(token)"></span>
        </li>
        <li class="en-autocomplete-input-token">
            <input #multiIn
                   autocomplete="off"
                   [attr.type]="type"
                   [attr.id]="inputId"
                   [disabled]="disabled"
                   [attr.placeholder]="
							value && value.length ? null : placeholder
						"
                   [attr.tabindex]="tabindex"
                   [attr.maxlength]="maxlength"
                   (input)="onInput($event)"
                   (click)="onInputClick($event)"
                   (keydown)="onKeydown($event)"
                   [readonly]="readonly"
                   (keyup)="onKeyup($event)"
                   [attr.autofocus]="autofocus"
                   (focus)="onInputFocus($event)"
                   (blur)="onInputBlur($event)"
                   (change)="onInputChange($event)"
                   (paste)="onInputPaste($event)"
                   [autocomplete]="autocomplete"
                   [ngStyle]="inputStyle"
                   [class]="inputStyleClass"
                   [imask]="mask"
                   [attr.aria-label]="ariaLabel"
                   [attr.aria-labelledby]="ariaLabelledBy"
                   [attr.aria-required]="required"
                   aria-autocomplete="list"
                   [attr.aria-controls]="listId"
                   role="searchbox"
                   [attr.aria-expanded]="overlayVisible"
                   aria-haspopup="true"
                   [attr.aria-activedescendant]="'en-highlighted-option'" />
        </li>
        <i *ngIf="showClear"
           class="en en-cancel-fill button-clear"
           [attr.tabindex]="tabindex"
           (click)="clickOnClear.emit()"></i>
        <button #ddBtn
                type="button"
                enButton
                [icon]="dropdownIcon"
                class="en-autocomplete-dropdown en-autocomplete en-button-lowercase"
                [disabled]="disabled"
                (click)="handleDropdownClick($event)"
                *ngIf="dropdown"
                [attr.tabindex]="tabindex"
                [ngClass]="{'en-focus': focus}"></button>
    </ul>
    <i *ngIf="loading"
       class="en-autocomplete-loader pi pi-spinner pi-spin"></i>
    <div #panel
         *ngIf="overlayVisible"
         [ngClass]="['en-autocomplete-panel en-component']"
         [ngStyle]="panelStyle"
         [class]="panelStyleClass"
         [@overlayAnimation]="{
                 value: 'visible',
                 params: {
                     showTransitionParams: showTransitionOptions,
                     hideTransitionParams: hideTransitionOptions
					}
				}"
         (@overlayAnimation.start)="onOverlayAnimationStart($event)">
        <tui-scrollbar>
            <div [style.max-height]="scrollHeight">
                <ul role="listbox"
                    [attr.id]="listId"
                    class="en-autocomplete-items">
                    <li role="option"
                        *ngFor="let option of suggestions; let idx = index"
                        class="en-autocomplete-item"
                        pRipple
                        [ngClass]="{
                                'en-highlight': option === highlightOption
                            }"
                        [id]="
                                highlightOption == option
                                    ? 'en-highlighted-option'
                                    : ''
                            "
                        (click)="selectItem(option)">
                        <span *ngIf="!itemTemplate && !mask">
                            {{ resolveFieldData(option) }}
                        </span>
                        <span *ngIf="!itemTemplate && mask">
                            {{ resolveFieldData(option) | imask:mask }}
                        </span>
                        <ng-container *ngTemplateOutlet="
                                    itemTemplate;
                                    context: { $implicit: option, index: idx }
                                "></ng-container>
                    </li>
                    <li *ngIf="noResults && emptyMessage"
                        class="en-autocomplete-emptymessage en-autocomplete-item">
                        {{ emptyMessage }}
                    </li>
                </ul>
            </div>
        </tui-scrollbar>
    </div>
</span>