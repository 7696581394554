import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UnsubscriptionReason } from '@enKod/segments/segments-form.model';
import { SelectItem } from 'primeng/api';

@Component({
	selector: 'en-unsubscribe-reason',
	templateUrl: './unsubscribe-reason.component.html',
	styleUrls: ['../../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubscribeReasonComponent {
	isHover = false;
	@Input() control: FormControl<UnsubscriptionReason>;
	@Input() options: SelectItem[];
	@Input() isHide: boolean = false;

	removeReason(): void {
		this.control.patchValue('');
	}
}
