import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit,
	Output,
	EventEmitter,
	ContentChildren,
	QueryList,
	TemplateRef,
	AfterContentInit,
	Input,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';

import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { PAGINATOR_PLUGIN_TOKEN } from '@enkod-core/tokens/paginator-plugin-token';
import { AuthQuery } from '@enkod-core/authentication/_state';
import { UrlSaverService, UrlParams } from '@enkod-core/services';
import { EnTempalateDirective } from '@shared';
import {
	SegmentsState,
	Segment,
	FiltersSegment,
	RelationsModel,
	DeleteData,
	SegmentsService
} from '@state-enKod/segments';

import { PaginationService, PAGINATION_DATA } from 'ui-lib';

import { TableToolsService } from 'app/ui-lib/table/table-tools.service';

@UntilDestroy()
@Component({
	selector: 'en-segments-table',
	templateUrl: './segments-table.component.html',
	styleUrls: ['./segments-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentsTableComponent
	implements OnInit, AfterContentInit, OnDestroy
{
	constructor(
		private cd: ChangeDetectorRef,
		public tableToolsService: TableToolsService,
		public paginationService: PaginationService,
		@Inject(PAGINATOR_PLUGIN_TOKEN)
		public readonly paginatorRef: PaginatorPlugin<Segment>,
		@Inject(PAGINATION_DATA)
		private readonly data$: Observable<PaginationResponse<Segment>>,
		public urlService: UrlSaverService,
		private authQuery: AuthQuery,
		private router: Router,
		private segmentsService: SegmentsService
	) {}

	isFirstLoad = true;
	toolsTemplate: TemplateRef<any>;
	segments: SegmentsState[] = [];
	total = 0;
	urlParams: UrlParams;
	filterModel$ = new BehaviorSubject({});
	filterItems: Array<keyof FiltersSegment>;
	hideFilters: Array<keyof FiltersSegment>;
	filterItemsPull: Array<keyof FiltersSegment> = ['type', 'tags'];

	cols: any[];

	isModalShowSegment = false;
	relatedSegments: DeleteData[];

	modal = false;
	relations$: Observable<RelationsModel>;
	segmentName: string;

	@Input() readonly selectable = false;

	@Input() readonly selection = false;

	@Input() readonly toolsPanel = true;

	@Input() selectedEntity: Segment[];

	@Input() maxSelectedItems: number;

	@Input() notSaveInUrl = false;

	@Input() isEnSendSegments = false;

	@Input() isEnPopSegments = false;

	@Input() segmentsInNewWindow = false;

	@Input() isHeaderCheckboxDisabled = false;

	@Input('filters')
	set filtersSetter(value: Array<keyof FiltersSegment>) {
		this.updateFilterModel(value);
	}

	@Input('hideFilters')
	set hideFiltersSetter(value: Array<keyof FiltersSegment>) {
		this.hideFilters = value;
	}

	@Output() editEntity = new EventEmitter<Segment>();

	@Output() copyEntity = new EventEmitter<Segment>();

	@Output() deleteEntity = new EventEmitter<DeleteData>();

	@Output() selected = new EventEmitter<Segment>();

	@Output() selectedEntityChange = new EventEmitter<Segment>();

	@ContentChildren(EnTempalateDirective) templates: QueryList<any>;

	get isEnSend() {
		return this.authQuery.isAccessEnsend;
	}

	get isEnPop() {
		return this.authQuery.isAccessEnpop;
	}

	ngOnInit(): void {
		this.urlParams = this.urlService.getQueryParams();

		this.setTableCols();

		this.data$.pipe(untilDestroyed(this)).subscribe(({ data, total }) => {
			this.segments = data;
			this.total = total || 0;
			this.cd.markForCheck();
		});

		this.initFilterItems();
	}

	initFilterItems(): void {
		if (
			this.isEnSend &&
			this.isEnPop &&
			!this.isEnSendSegments &&
			!this.isEnPopSegments
		) {
			this.filterItems = ['type', 'tags'];
		} else {
			this.filterItems = ['tags'];
		}
		this.updateFilterItems();
	}

	private updateFilterModel(filterModel: any = {}) {
		this.filterModel$.next(filterModel);
	}

	private updateFilterItems(items: string[] = []) {
		const arr = new Set([...(this.hideFilters || []), ...items]);
		this.filterItems = this.filterItemsPull.filter(item => !arr.has(item));
	}

	ngAfterContentInit(): void {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'tools':
					this.toolsTemplate = item.template;
					break;

				default:
					break;
			}
		});
	}

	checkboxDisabled(data: Segment) {
		if (!this.maxSelectedItems) return false;
		return (
			this.selectedEntity.length >= this.maxSelectedItems &&
			!this.selectedEntity.find(entity => entity?.id === data?.id)
		);
	}

	private setTableCols(): void {
		const scenarioLocation = this.router.url.includes('/scenario/');
		this.cols = [
			...(this.selectable
				? [{ field: 'selectCheckbox', width: '48px' }]
				: []),
			{
				field: 'id',
				header: 'segment_table.header_id',
				reorderableColumn: false,
				width: '4.5rem'
			},
			{
				field: 'name',
				reorderableColumn: true,
				header: 'segment_table.header_name',
				'min-width': '280px'
			},
			{
				field: 'module',
				header: 'segment_table.header_module',
				reorderableColumn: true,
				width: '17.5rem'
			},
			{
				field: 'tags',
				header: 'segment_table.header_tags',
				reorderableColumn: true,
				'min-width': '150px'
			},
			...(scenarioLocation
				? [
						{
							field: 'createDate',
							reorderableColumn: true,
							header: 'segment_table.create_date',
							'min-width': '280px'
						}
				  ]
				: [])
		];
	}

	onModelChange(model: FiltersSegment) {
		if (this.isEnSendSegments) {
			this.paginationService.setFilterValue({ ...model, type: 'enSend' });
		} else if (this.isEnPopSegments) {
			this.paginationService.setFilterValue({ ...model, type: 'enPop' });
		} else this.paginationService.setFilterValue(model);

		if (!this.isFirstLoad) {
			this.paginatorRef.setFirstPage();
		}
		this.isFirstLoad = false;
	}

	checkDeleted(entity: Segment): void {
		if (entity.hasRelations) {
			this.modal = true;
			this.relations$ = this.segmentsService.getRelationsInfo(entity.id);
			this.segmentName = entity.name;
		} else {
			const newId = +entity.id;
			this.checkDeletedRelated(newId, entity.name);
		}
	}

	checkDeletedRelated(id: number, name: string) {
		this.paginatorRef.setLoading(true);
		this.segmentsService
			.checkDeleted(id)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.paginatorRef.setLoading(false);
				})
			)
			.subscribe(resp => {
				if (resp.length) {
					this.urlService.saveCurrentUrl();
					this.relatedSegments = resp;
					this.isModalShowSegment = true;
				} else {
					this.deleteEntity.emit({ id, name });
				}
			});
	}

	ngOnDestroy(): void {
		this.paginatorRef.setFirstPage();
	}
}
