import { SelectItemDesctiption } from '@enkod-core/interfaces';
import { SelectItem } from 'primeng/api';

export const DATA_TYPE_OPTIONS: SelectItem[] = [
	{
		label: 'scenario_block_data_change.data_type_option',
		value: 'extrafield'
	}
];

export const EXTRAFIELD_OPTIONS: SelectItem[] = [
	{
		label: 'email',
		value: {
			label: 'email',
			dataType: 'email',
			id: 0
		}
	}
];

export const CHANGE_TYPE_OPTIONS: SelectItem[] = [
	{
		label: 'scenario_block_data_change.any_value_option',
		value: 'any'
	},
	{
		label: 'scenario_block_data_change.add_value_option',
		value: 'specific'
	}
];

export const BOOLEAN_EXTRAFIELD_OPTIONS: SelectItem[] = [
	{
		label: 'scenario_block_data_change.bool_true_option',
		value: 'true'
	},
	{
		label: 'scenario_block_data_change.bool_false_option',
		value: 'false'
	}
];

export const EMAIL_EXTRAFIELD_OPTIONS: SelectItemDesctiption[] = [
	{
		label: 'scenario_block_data_change.mail_options_any_label',
		value: 'any',
		description: 'scenario_block_data_change.mail_options_any_description'
	},
	{
		label: 'scenario_block_data_change.mail_options_filled_label',
		value: 'filled',
		description:
			'scenario_block_data_change.mail_options_filled_description'
	},
	{
		label: 'scenario_block_data_change.mail_options_empty_label',
		value: 'empty',
		description: 'scenario_block_data_change.mail_options_empty_description'
	}
];
