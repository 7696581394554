import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SendingType } from '@enSend/message/_states/_state-message';
import { MessageType } from '@enSend/message/kit';
import { TagsValue } from '@enkod-core/enums';
import { UrlParams } from '../../../message-wizard/message-wizard-common/models';

import { IEmailPreview } from '../../../message-wizard/message-wizard-common/step-content/2-step-editor/email-editor/email-editor.component';
import { IPushPreview } from '../../../message-wizard/message-wizard-common/step-content/2-step-editor/push-editor/push-editor.model';
import { MobPushPreview } from '../../../message-wizard/message-wizard-common/step-content/2-step-editor/mobile-push-editor/mobile-push-editor.model';

import {
	DataOfDetailsMessages,
	IMessageDataDetail,
	IMessageDetail,
	IMessageDetailModal,
	IOverallData,
	MessageDetailEntity
} from './message-detail.model';
import { MessageDetailStore, MessageDetailState } from './message-detail.store';

@Injectable({ providedIn: 'root' })
export class MessageDetailQuery extends QueryEntity<MessageDetailState> {
	constructor(protected store: MessageDetailStore) {
		super(store);
	}

	currentStream$ = this.selectActive() as Observable<IMessageDetail>;

	get activeSplitVersion() {
		return (this.getValue() as IMessageDetail).activeSplitVersion;
	}

	actvieSplitVersion$ = this.select('activeSplitVersion');

	previewEntity$ = this.currentStream$.pipe(
		map(value => value?.preview)
	) as Observable<IMessageDetailModal>;

	get previewEntity() {
		return (this.getActive() as IMessageDetail)
			?.preview as IMessageDetailModal;
	}

	filtredEntity$ = this.currentStream$.pipe(
		map(value => value?.filtred)
	) as Observable<IMessageDataDetail>;

	filteredAmpEntity$ = this.currentStream$.pipe(
		map(value => value.filteredAmp)
	) as Observable<IMessageDataDetail>;

	groupedEntity$ = this.currentStream$.pipe(
		map(value => value?.grouped)
	) as Observable<IMessageDataDetail[]>;

	preview$ = this.actvieSplitVersion$.pipe(
		switchMap(
			splitVersion =>
				this.previewEntity$.pipe(
					filter<any>(Boolean),
					map((value: Required<IMessageDetailModal>) => {
						if (value.type === 'mail') {
							return {
								html: value.mails[splitVersion].html,
								plainText: value.mails[splitVersion].plainText,
								messageId: value.mails[splitVersion].messageId
							};
						}
						if (value.type === 'push') {
							return {
								subject: value?.pushes[0].subject,
								body: value?.pushes[0].body,
								messageId: value?.pushes[0].messageId,
								icon: value?.pushes[0].icon,
								clickAction: value.pushes[0].clickAction
							};
						}
						if (value.type === 'mobPush') {
							return {
								subject: value?.mobPushes[0].subject,
								messageId: value?.mobPushes[0].messageId,
								body: value?.mobPushes[0].body,
								smallImage: value?.mobPushes[0].smallImage,
								image: value?.mobPushes[0].image,
								appName: value?.mobPushes[0].appName,
								buttons: value?.mobPushes[0].buttons
							};
						}
						if (value.type === 'sms') {
							return {
								body: value?.sms[0].body,
								id: value?.sms[0].id,
								messageId: value?.sms[0].messageId,
								toShortLink: value?.sms[0].toShortLink,
								urlParams: value?.sms[0].urlParams
							};
						}
						return value;
					})
				) as Observable<IEmailPreview | IPushPreview | MobPushPreview>
		)
	);

	previewType$ = this.previewEntity$.pipe(
		map(value => value?.type)
	) as Observable<MessageType>;

	sendingType$ = this.previewEntity$.pipe(
		map(value => value?.sendingType)
	) as Observable<SendingType>;

	overallData$ = this.previewEntity$.pipe(
		map(value => {
			return {
				id: value?.id,
				name: value?.name,
				status: value?.status,
				sendingType: value?.sendingType
			};
		})
	) as Observable<IOverallData>;

	get messageType() {
		const active = this.getActive() as IMessageDetail | undefined;
		return active?.preview?.type as MessageType;
	}

	get messageId() {
		const active = this.getActive() as IMessageDetail | undefined;
		return active?.preview?.id ? +active?.preview?.id : undefined;
	}

	dataOfDetailsMessages$ = this.actvieSplitVersion$.pipe(
		switchMap(
			splitVersion =>
				this.previewEntity$.pipe(
					filter<any>(Boolean),
					map(value => {
						const entity: MessageDetailEntity[] =
							value.mails ||
							value.pushes ||
							value.mobPushes ||
							value.sms ||
							[];
						const sortTags = value.tags
							?.slice()
							.sort((tag: string) =>
								tag === TagsValue.SPLIT_TEST ? -1 : 0
							);
						const params: UrlParams = messageParams(
							entity[splitVersion]?.urlParams
						);

						return <DataOfDetailsMessages>{
							subject: entity
								? entity[splitVersion]?.subject
								: '',
							preheader: value.mails
								? value.mails[splitVersion]?.detailsPreheader
								: '',
							fromEmail: value.mails
								? value.mails[splitVersion]?.fromEmail
								: '',
							segments: value.segments,
							exceptSegments: value.exceptSegments,
							groups: value.groups,
							tags: sortTags,
							sendingTime: value.sendingTime,
							importInfo: value.importInfo,
							endTime:
								value.sendingSettings?.endDate ||
								value.splitTestSettings?.endDate,
							subscribers:
								value.sendingSettings?.subscribersCount,
							messageType: value.type,
							sendingType: value.sendingType,
							scenarioId: value.scenarioId,
							scenarioName: value.scenarioName,
							contactsFileName: value.contactsFileName,
							contactsCount: value.contactsCount,
							choiceWinner: value.splitTestSettings?.choiceWinner,
							durationTest: value.splitTestSettings?.duration,
							durationUnit: value.splitTestSettings?.unit,
							whatsappTemplateId: value.whatsappTemplateId,
							whatsappParams: value.whatsappParams,
							withAttachments: value.mails
								? value.mails[splitVersion].withAttachments
								: false,
							isTextVersionExist: value.mails
								? !!value.mails[splitVersion].plainText
								: false,
							isAmpExist: value.mails
								? !!value.mails[splitVersion].amp
								: false,
							testGroupSize:
								value.splitTestSettings?.testGroupSize,
							utm: Object.entries(params.utm),
							url: Object.entries(params.params),
							attachments: value.mails
								? value.mails[splitVersion]?.attachments
								: [],
							fileAttachments:
								value.mails ??
								value.mails?.[splitVersion]?.attachments
									?.length > 0
						};
					})
				) as Observable<DataOfDetailsMessages>
		)
	);

	get isSplitTest(): boolean {
		return !!(this.getActive() as IMessageDetail)?.preview?.isSplitTest;
	}

	get isAmp(): boolean {
		return !!(this.getActive() as IMessageDetail)?.preview?.mails?.[0].amp
			.length;
	}

	get mailId() {
		if (this.previewEntity.mails)
			return this.previewEntity.mails[this.activeSplitVersion].id;
		return null;
	}

	clicksMapData$ = this.select('clicksMapData');
	linksEditorData$ = this.select('linksEditorData');
}

const messageParams = (entity: UrlParams): UrlParams => {
	if (entity?.utm && entity?.params) return entity;
	if (entity?.utm && !entity?.params) return { ...entity, params: {} };
	if (!entity?.utm && entity?.params) return { ...entity, utm: {} };
	return { utm: {}, params: {} };
};
