<div *ngIf="opened$ | async"
     class="wrapper"
     [@tuiSlideInRight]>
    <div class="bar">
        <div class="bar__header">
            <div class="bar__title">
                {{'scenario_wizard_canva.hot_keys_title' | translate}}
            </div>
            <div class="bar__toolbar">
                <button type="button"
                        enButton
                        class="en-button-lowercase h32"
                        icon="en en-cancel"
                        (click)="toggleHotkeys()"></button>
            </div>
        </div>
        <div class="bar__content">
            <div class="action">
                <span>{{'scenario_wizard_canva.hot_keys_undo' | translate}}</span>
                <div>
                    <span class="id-badge">Ctrl</span> + <span class="id-badge">Z</span>
                </div>
            </div>
            <div class="action">
                <span>{{'scenario_wizard_canva.hot_keys_redo' | translate}}</span>
                <div>
                    <span class="id-badge">Ctrl</span> + <span class="id-badge">Shift</span> + <span
                          class="id-badge">Z</span>
                </div>
            </div>
            <div class="action">
                <span>{{'scenario_wizard_canva.hot_keys_zoom' | translate}}</span>
                <div>
                    <span class="id-badge">Ctrl</span> + <span>Scroll</span>
                </div>
            </div>
            <div class="action">
                <span>{{'scenario_wizard_canva.hot_keys_fullscreen' | translate}}</span>
                <div>
                    <span class="id-badge">Ctrl</span> + <span class="id-badge">O</span>
                </div>
            </div>
            <div class="action">
                <span>{{'scenario_wizard_canva.hot_keys_preview' | translate}}</span>
                <div>
                    <span class="id-badge">Ctrl</span> + <span class="id-badge">M</span>
                </div>
            </div>
            <div class="action">
                <span>{{'scenario_wizard_canva.hot_keys_select' | translate}}</span>
                <div>
                    <span class="id-badge">V</span>
                </div>
            </div>
        </div>
    </div>
</div>