import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RestService } from 'app/core/utils/rest-service';
import { ExtraField, IDeleteData } from './extra-field.model';

@Injectable({ providedIn: 'root' })
export class ExtraFieldsDataService extends RestService<ExtraField> {
	constructor(protected http: HttpClient) {
		super(http, 'enkod/extrafield');
	}

	checkSystemName(
		serviceName: string
	): Observable<{ uniqueSystemName: boolean }> {
		return this.http.post('enkod/extrafield/check', {
			serviceName
		}) as Observable<{ uniqueSystemName: boolean }>;
	}

	checkDeletedData(id: number) {
		return this.http.get(
			`enkod/extrafield/deleteCheck/${id}`
		) as Observable<IDeleteData[]>;
	}
}
