import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SqlTemplatesStore, SqlTemplatesState } from './sql-templates.store';

@Injectable({ providedIn: 'root' })
export class SqlTemplatesQuery extends QueryEntity<SqlTemplatesState> {
	constructor(protected store: SqlTemplatesStore) {
		super(store);
	}
}
