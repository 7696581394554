import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api/selectitem';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import {
	IMonthControls,
	IMonths,
	RegularFilter
} from '@enSend/message/message-wizard/message-wizard-common/models';
import { MonthService } from './month.service';

@UntilDestroy()
@Component({
	selector: 'en-month',
	templateUrl: './month.component.html',
	styleUrls: ['./month.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class MonthComponent implements OnInit {
	constructor(
		private monthService: MonthService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService
	) {}

	@Input() form: UntypedFormGroup;

	@Input() location: string;

	@Input() disabled = null;

	monthControls: Partial<IMonthControls>;

	// Временная версия
	monthsNumber: SelectItem[] = [];

	months: IMonths = {};

	monthOptions: SelectItem[] = [];

	monthLabel: IMonths = {};

	filterOptions: SelectItem[] = [
		{
			label: this.translate.instant('message_wizard.regular_filter_week'),
			value: 'weekday'
		},
		{
			label: this.translate.instant('message_wizard.regular_filter_day'),
			value: 'day'
		}
	];

	ngOnInit(): void {
		for (let i = 1; i < 32; i++) {
			this.monthsNumber.push({ label: i.toString(), value: i });
		}
		this.setMonthsValue();
		this.getMonthControls();
		this.setupFormListeners();
		this.cd.markForCheck();
	}

	private setupFormListeners() {
		this.monthControls.filter?.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value: RegularFilter) => {
				this.monthService.pickFilterControl(value);
				this.cd.markForCheck();
			});
	}

	private setMonthsValue() {
		this.months = this.monthService.months;
		this.monthOptions = this.monthService.monthsOptions;
		this.monthLabel = this.monthService.monthLabel;
	}

	private getMonthControls() {
		this.monthControls = {
			weekdays: this.monthService.weekdaysControl as UntypedFormControl,
			filter: this.monthService.filterControl as UntypedFormControl,
			everyWeek: this.monthService.everyWeekControl as UntypedFormControl
		};
	}
}
