import { Component, ChangeDetectionStrategy } from '@angular/core';
import { tuiSlideInRight } from '@taiga-ui/core';
import { TopBarService } from '../../services/top-bar.service';

@Component({
	selector: 'en-sidebar-hotkeys',
	templateUrl: './sidebar-hotkeys.component.html',
	styleUrls: ['./sidebar-hotkeys.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [tuiSlideInRight]
})
export class SidebarHotkeysComponent {
	constructor(private topbarService: TopBarService) {}

	opened$ = this.topbarService.toggleHotkeysChanges;

	toggleHotkeys() {
		this.topbarService.toggleHotkeys(false);
	}
}
