import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { debounceTime, tap } from 'rxjs/operators';
import { TimeRangeForm } from '@enKod/segments/segments-form.model';
import { UnitsDeclinationService } from '../../services/units-declination.service';

@UntilDestroy()
@Component({
	selector: 'en-action-time',
	templateUrl: './action-time.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionTimeComponent implements OnInit {
	isHover = false;

	optionsUnits: SelectItem[] = [];

	dateRangeControl = new FormControl<{
		startDate: number;
		endDate: number;
	} | null>(null);

	markRangeAsInvalid = false;

	@Input() period: string;

	@Input() timeForm: FormGroup<TimeRangeForm>;

	@Input() isCreationMode = true;

	@Input() isShowRemoveButton = false;

	@Input() readonly: boolean;

	@Input() isHide = false;

	@Input() optionsPeriod: SelectItem[] = [
		{
			label: 'segment_options.between_date',
			value: 'between'
		},
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		},
		{
			label: 'segment_form.options_operator_more',
			value: 'more'
		}
	];

	@Output() remove = new EventEmitter<void>();

	constructor(
		private declinationService: UnitsDeclinationService,
		private validationService: SegmentsValidateService,
		private cd: ChangeDetectorRef
	) {}

	get periodValueControl(): FormControl<number | null> {
		return this.timeForm.controls.periodValue as FormControl<number | null>;
	}

	get periodOperatorControl(): FormControl<string> {
		return this.timeForm.controls.periodOperator as FormControl<string>;
	}

	get startControl(): FormControl<number | null> {
		return this.timeForm.controls.start as FormControl<number | null>;
	}

	get endControl(): FormControl<number | null> {
		return this.timeForm.controls.end as FormControl<number | null>;
	}

	get unitsControl(): FormControl<string> {
		return this.timeForm.controls.units as FormControl<string>;
	}

	get periodOperatorValue(): string {
		return this.periodOperatorControl?.value;
	}

	get isLessOrMorePeriod(): boolean {
		return (
			this.periodOperatorValue === 'less' ||
			this.periodOperatorValue === 'more'
		);
	}

	get showLastTimeCondition(): boolean {
		return (
			this.periodOperatorValue === 'less' ||
			this.periodOperatorValue === 'more' ||
			this.periodOperatorValue === 'equal'
		);
	}

	get isInvalidRange(): boolean {
		return !this.startControl?.value || !this.endControl?.value;
	}

	ngOnInit(): void {
		this.operatorChange(this.periodOperatorControl.value);
		if (this.startControl?.value && this.endControl?.value)
			this.dateRangeControl.patchValue({
				startDate: this.startControl.value,
				endDate: this.endControl.value
			});
		this.dateRangeControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (value) {
						this.startControl.patchValue(value.startDate);
						this.endControl.patchValue(value.endDate);
						this.markRangeAsInvalid = this.isInvalidRange;
						this.isInvalidRange
							? this.timeForm.setErrors({ invalidRange: true })
							: this.timeForm.updateValueAndValidity();
						this.cd.markForCheck();
					}
				})
			)
			.subscribe();
		this.validationService.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.markRangeAsInvalid =
						this.isInvalidRange && !this.isHide;
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.validationService.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(200),
				tap(() => {
					if (this.isHide) {
						this.markRangeAsInvalid = false;
						this.cd.markForCheck();
					}
				})
			)
			.subscribe();
	}

	operatorChange(operator: string) {
		switch (operator) {
			case 'between':
				this.startControl.enable();
				this.endControl.enable();
				this.periodValueControl.disable();
				this.unitsControl.disable();
				break;
			case 'equal':
			case 'less':
			case 'more':
				this.periodValueControl.enable();
				this.unitsControl.enable();
				this.startControl?.disable();
				this.endControl?.disable();
				this.dateRangeControl.patchValue(null);
				this.startControl?.patchValue(null);
				this.endControl?.patchValue(null);
				break;
			default:
				this.periodValueControl.disable();
				this.unitsControl.disable();
				this.startControl?.disable();
				this.endControl?.disable();
				this.dateRangeControl.patchValue(null);
				this.startControl?.patchValue(null);
				this.endControl?.patchValue(null);
				break;
		}
		this.setCaseUnits();
	}

	updateValue() {
		this.setCaseUnits();
	}

	private setCaseUnits() {
		if (this.periodValueControl)
			this.optionsUnits = this.declinationService.getCaseUnits(
				this.periodValueControl,
				this.isLessOrMorePeriod
			);
	}
}
