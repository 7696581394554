<form [formGroup]="form">
    <!-- Месяцы старта -->
    <div class="en-field">
        <label>{{'message_wizard.regular_month' | translate}}</label>
        <en-multiSelect [options]="monthOptions"
                        [emptyFilterMessage]="'message_wizard.regular_month_empty_message' | translate"
                        formControlName="month">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div *ngIf="value && value.length < 4"
                     style="display: flex;">
                    <span class="chosen text-color-badge text-overflow"
                          *ngFor="let option of value">
                        {{ monthLabel[option] | translate }}
                    </span>
                </div>
                <span *ngIf="!value || value.length === 0"
                      translate>
                </span>
                <span *ngIf="value && value.length >= 4">
                    {{ value.length}} {{'message_wizard.month_count' | translate }}
                </span>
            </ng-template>
        </en-multiSelect>
        <en-error formControlName="month"></en-error>
    </div>

    <!-- Фильтр дней отправки -->
    <div class="en-field">
        <label>{{'message_wizard.regular_filter' | translate}}</label>
        <en-selectbutton [options]="filterOptions"
                         formControlName="filter"></en-selectbutton>
        <en-error formControlName="filter"></en-error>
    </div>

    <ng-container [ngSwitch]="monthControls.filter?.value">
        <ng-template ngSwitchCase="weekday">
            <en-start-weekday [weekdays]="monthControls.weekdays"
                              [everyWeek]="monthControls.everyWeek"
                              [location]="location"
                              [disabled]="disabled"></en-start-weekday>
        </ng-template>

        <ng-template ngSwitchCase="day">
            <div class="en-field">
                <label>{{'message_wizard.regular_choose_day' | translate}}</label>
                <en-multiSelect [options]="monthsNumber"
                                [emptyFilterMessage]="'message_wizard.regular_month_empty_message' | translate"
                                [selectedItemsLabel]="'message_wizard.regular_month_select_days' | translate"
                                formControlName="day">

                </en-multiSelect>
                <en-error formControlName="day"></en-error>
            </div>
        </ng-template>
    </ng-container>
</form>