<div class="preview"
     [ngSwitch]="view">
    <ng-template ngSwitchCase="desktop">
        <div class="preview__container"
             [ngClass]="{'border-none': bordersNone}">
            <div class="preview__header">
                <span class="preview__ellipse"></span>
                <span class="preview__ellipse"></span>
                <span class="preview__ellipse"></span>
            </div>
            <ng-container *ngIf="createPreview; else desktopPreview">
                <ng-container *ngTemplateOutlet="createButtonTemplate"></ng-container>
            </ng-container>

        </div>
    </ng-template>
    <ng-template ngSwitchCase="codeHtml">
        <div class="preview__container"
             [ngClass]="{'border-none': bordersNone}">
            <div class="preview__header">
                <span class="preview__ellipse"></span>
                <span class="preview__ellipse"></span>
                <span class="preview__ellipse"></span>
            </div>

            <ng-container *ngIf="createPreview; else htmlPreview">
                <ng-container *ngTemplateOutlet="createButtonTemplate"></ng-container>
            </ng-container>

            <ng-template #htmlPreview>
                <div class="preview__canva">
                    <ngx-codemirror class="codeHtml"
                                    [options]="options"
                                    [formControl]="tempHtml"></ngx-codemirror>
                </div>
            </ng-template>
        </div>
    </ng-template>
    <ng-template ngSwitchCase="mobile">

        <ng-container *ngIf="createPreview; else htmlPreview">
            <div class="preview__container"
                 [ngClass]="{'border-none': bordersNone}">
                <div class="preview__header">
                    <span class="preview__ellipse"></span>
                    <span class="preview__ellipse"></span>
                    <span class="preview__ellipse"></span>
                </div>
                <ng-container *ngTemplateOutlet="createButtonTemplate"></ng-container>
            </div>
        </ng-container>

        <ng-template #htmlPreview>
            <div class="preview__container-mobile"
                 [ngClass]="{'border-none': bordersNone}">

                <en-mobile-mock [isDetail]="true"
                                phoneModel="iphone">
                    <ng-container [ngTemplateOutlet]="mobilePreview"></ng-container>
                </en-mobile-mock>
            </div>
        </ng-template>
    </ng-template>

    <!-- <ng-template ngSwitchCase="plainText">
        <div class="preview__container plain"
             [ngClass]="{'border-none':bordersNone}">
            {{plainText}}
        </div>
    </ng-template>

    <ng-template ngSwitchCase="amp">
        AMP
    </ng-template> -->
</div>
<p-sidebar *ngIf="fullscreenVisible"
           [(visible)]="fullscreenVisible"
           [fullScreen]="true"
           [showCloseIcon]="false"
           [closeOnEscape]="true"
           (onHide)="fullscreenVisible = false">
    <div class="preview__container"
         [ngClass]="{'border-none': bordersNone}">
        <div class="preview__header fullscreen">
            <div>
                <span class="preview__ellipse"></span>
                <span class="preview__ellipse"></span>
                <span class="preview__ellipse"></span>
            </div>

            <en-view-buttons [options]="optionsView"
                             [currentValue]="view"
                             (onChange)="changeView.emit($event)"></en-view-buttons>

            <button enButton
                    [label]="'common.back' | translate"
                    class="en-button-secondary h32"
                    (click)="closeFullscreen()"></button>
        </div>
        <ng-container [ngSwitch]="view">
            <ng-template ngSwitchCase="desktop">
                <ng-container [ngTemplateOutlet]="desktopPreview"></ng-container>
            </ng-template>
            <ng-template ngSwitchCase="mobile">
                <div class="container__mobile-full">
                    <en-mobile-mock [isDetail]="true"
                                    phoneModel="iphone">
                        <ng-container [ngTemplateOutlet]="mobilePreview"></ng-container>
                    </en-mobile-mock>
                </div>
            </ng-template>
            <ng-template ngSwitchCase="codeHtml">
                <div class="preview__canva">
                    <ngx-codemirror class="codeHtml"
                                    [options]="options"
                                    [formControl]="tempHtml"></ngx-codemirror>
                </div>
            </ng-template>
        </ng-container>
    </div>
</p-sidebar>


<ng-template #mobilePreview>
    <iframe enHtmlPreview
            [showScrollbar]="false"
            [html]="html || ''"></iframe>
</ng-template>

<ng-template #desktopPreview>
    <ng-container *ngIf="clicksMapAvailable; else withoutClicksMap">
        <iframe enHtmlPreview
                enClicksMap
                [clicksMapData]="clicksMapData$ | async"
                [linksEditorData]="linksEditorData$ | async"
                [html]="html || ''">
        </iframe>
    </ng-container>

    <ng-template #withoutClicksMap>
        <iframe #descktopIframe
                class="height-auto"
                enHtmlPreview
                scrolling="yes"
                [html]="html || ''">
        </iframe> 
    </ng-template>
</ng-template>