<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsPage"
                 [formControl]="type"
                 (onChange)="onChangeType($event)"></en-dropdown>
    <en-dropdown class="input__medium"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsParameter"
                 formControlName="parameter"></en-dropdown>
    <div *ngIf="isSpecificLoaded && isShowSpecificParam"
         class="addition"
         [class.active-hide]="showHide">
        <en-dropdown class="input__small"
                     styleClass="en-dropdown-solid"
                     [options]="optionsSpecific"
                     formControlName="specificOperator"></en-dropdown>
        <input class="h32 text-overflow"
               type="text"
               enInput
               enDynamicWidth="15rem"
               showClear="true"
               formControlName="specificValue" />
    </div>

    <div class="addition"
         [class.active-hide]="showHide">
        <en-dropdown class="input__medium"
                     styleClass="en-dropdown-solid"
                     [options]="optionsCount"
                     formControlName="operator"></en-dropdown>
        <input class="h32 text-overflow"
               type="number"
               min="1"
               max="1000"
               step="1"
               enInput
               enDynamicWidth
               pKeyFilter="pint"
               showClear="true"
               formControlName="value" />
        <span translate
              class="center-span-text">segment_form.cond_visits_count</span>
    </div>
    <div>
        <en-action-time [class.active-hide]="showHide"
                        [isHide]="isHideParent || isHide"
                        [timeForm]="actionTime"
                        [period]="'past'"></en-action-time>
    </div>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>
</form>
