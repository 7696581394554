import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	OnDestroy,
	ViewEncapsulation,
	ChangeDetectorRef,
	Inject,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { dia } from '@clientio/rappid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import tippy from 'tippy.js';

import { FORM_TOKEN, VALIDATION_TOKEN } from '@enkod-core/tokens';
import { declinationWord } from '@enkod-core/utils';
import { DateAccountTimezoneService } from '@enkod-core/services';

import {
	CanvaMode,
	FormService,
	DistributionParam,
	FieldValue
} from '@enSend/_shared/models';

import {
	RAPPID_SERVICE_TOKEN,
	SendMessageStateToken
} from '@enSend/_shared/tokens';
import { operatorTranslates } from '@enSend/_shared/constants';
import {
	importGraphFromJSON,
	loadStencilShapes,
	zoomToFit
} from '../rappid/actions';
import { EventBusService, RappidService } from '../services';
import { SharedEvents } from '../rappid/controller';
import { InspectorItemContext } from '../inspector/inspector-item-plugin';

@UntilDestroy()
@Component({
	selector: 'en-canva',
	templateUrl: './canva.component.html',
	styleUrls: ['./canva.component.scss'],
	providers: [
		{
			provide: RAPPID_SERVICE_TOKEN,
			useExisting: RappidService
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	// TODO: решить проблему с инкапсуляцией стилей
	encapsulation: ViewEncapsulation.None
})
export class CanvaComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	private readonly tippyAppendDefault = tippy.defaultProps.appendTo;

	@Input() canvaMode: CanvaMode;
	@Output() onGraphChange = new EventEmitter();

	@ViewChild('paper') paper: ElementRef;
	@ViewChild('stencil') stencil: ElementRef;

	constructor(
		private rappid: RappidService,
		private eventBusService: EventBusService,
		@Inject(FORM_TOKEN)
		private mainFormService: FormService,
		private elementRef: ElementRef,
		@Inject(VALIDATION_TOKEN)
		protected readonly checkValidateCells$: Subject<boolean>,
		private cd: ChangeDetectorRef,
		private translate: TranslateService,
		private timezoneService: DateAccountTimezoneService
	) {}

	get form(): UntypedFormGroup {
		return this.mainFormService.form as unknown as UntypedFormGroup;
	}

	get cellsControl(): UntypedFormControl {
		return this.form.get('cells') as UntypedFormControl;
	}

	get nameControl(): UntypedFormControl {
		return this.form.get('name') as UntypedFormControl;
	}

	ngOnInit() {
		this.rappid.canva = this.elementRef.nativeElement;
		const { subscriptions, eventBusService } = this;
		subscriptions.add(
			eventBusService.on(SharedEvents.GRAPH_CHANGED, (json: Object) => {
				this.onRappidGraphChange(json);
				this.onGraphChange.emit();
			})
		);
		this.mainFormService.firstBootChanges
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.initCanva();
			});
	}

	initCanva() {
		const { rappid, paper, stencil } = this;
		const graphJSON = this.cellsControl.value;

		this.rappid.init(
			paper.nativeElement,
			stencil.nativeElement,
			this.canvaMode
		);
		this.openFile(graphJSON);

		this.translateBlocksText();
		loadStencilShapes(rappid, this.canvaMode, this.translate);

		this.cd.detectChanges();
	}

	addTemplateToCanva(template?: any) {
		this.openFile(template);
		this.cellsControl.patchValue(template);
		this.translateBlocksText();
		this.cd.markForCheck();
	}

	private openFile(json: dia.Cell[]): void {
		const { rappid } = this;
		importGraphFromJSON(rappid, json);
		zoomToFit(rappid);
	}

	private onRappidGraphChange(json: Object): void {
		this.cellsControl.patchValue(json);
		this.translateBlocksText();
	}

	private translateBlocksText(): void {
		this.rappid.graph.attributes.cells?.models.map(model => {
			if (model.attributes.type !== 'enKodLink') {
				const translatedModel = model;
				const { attrs } = translatedModel.attributes;
				const options = model.get('options');
				const subType = model.get('subType');
				const namespace = model.get('namespace') ?? 'scenario';

				const { params } =
					namespace === 'scenario' ? options[subType] : [];

				if (attrs) {
					// Переводим названия добавленных блоков
					if (attrs.name && attrs.name.text)
						attrs.name.text = this.translate.instant(
							attrs.name.text
						);
					// Переводим опции блоков
					if (attrs['.subheader-option-name']?.text) {
						attrs['.subheader-option-name'].text =
							this.translate.instant(
								attrs['.subheader-option-name'].text
							);
					}

					if (attrs['.footer-option-name']?.text) {
						attrs['.footer-option-name'].text =
							this.translate.instant(
								attrs['.footer-option-name'].text
							);
					}

					if (namespace === 'scenario') {
						if (
							subType === 'scheduleStart' &&
							attrs[`.desc__block_${params[0]?.port} .desc__text`]
								?.text
						) {
							const string = attrs[
								`.desc__block_${params[0].port} .desc__text`
							]?.text as string;
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							attrs[
								`.desc__block_${params[0].port} .desc__text`
							]!.text = this.translate.instant(string);
						}

						if (
							options[subType].distributionType ===
							'scenarioFields'
						) {
							this.translateOperators(model);
						}

						if (
							subType === 'pause' &&
							options.pauseType !== 'extraFields' &&
							options.pauseType !== 'scenarioFields' &&
							attrs[`.desc__block_${params[0]?.port} .desc__text`]
								?.text
						) {
							this.pauseLabelFix(model);
						}
					}
				}
			}
			return model;
		});
	}

	translateOperators(model: any) {
		const translatedModel = model;
		const { attrs } = translatedModel.attributes;
		const options = model.get('options');
		const subType = model.get('subType');
		const { params } = options[subType];

		params.forEach((param: DistributionParam, i: number) => {
			if (!param.scenarioFieldsCondition?.operator) return;

			const fieldType = options[subType].fields.find(
				(field: FieldValue) =>
					field.fieldName === param.scenarioFieldsCondition?.field
			).type;

			const operator = options[subType].fields[i].operator as string;

			let { value } = options[subType].fields[i];

			if (fieldType === 'date' && value)
				value =
					operator === 'between'
						? `${this.timezoneService.convertDate(
								Number(value.startDate),
								'DD.MM.yyyy'
						  )} - ${this.timezoneService.convertDate(
								Number(value.endDate),
								'DD.MM.yyyy'
						  )}`
						: this.timezoneService.convertDate(
								Number(value),
								'DD.MM.yyyy'
						  );

			if (fieldType === 'dateTime' && value)
				value =
					operator === 'between'
						? `${this.timezoneService.convertDate(
								Number(value.startDate),
								'DD.MM.yyyy HH:mm'
						  )} - ${this.timezoneService.convertDate(
								Number(value.endDate),
								'DD.MM.yyyy HH:mm'
						  )}`
						: this.timezoneService.convertDate(
								Number(value),
								'DD.MM.yyyy HH:mm'
						  );

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			attrs[
				`.desc__block_${param.port} .desc__value`
			]!.text = `${this.translate.instant(
				operatorTranslates[operator] || operator
			)} ${value || ''}`;
		});
	}

	pauseLabelFix(model: any) {
		const translatedModel = model;
		const { attrs } = translatedModel.attributes;
		const options = model.get('options');
		const subType = model.get('subType');
		const { params } = options[subType];

		if (params.length && params[0].unit !== 'until') {
			const lowerCaseOption = this.translate.instant(
				declinationWord(params[0].unit, params[0].value)
			);

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			attrs[
				`.desc__block_${params[0].port} .desc__text`
			]!.text = `${params[0].value} ${lowerCaseOption}`;
		}

		if (params.length && params[0].unit === 'until') {
			const dateLabelAccountTz = this.timezoneService.convertDate(
				params[0].value,
				'DD.MM.yyyy HH:mm'
			);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			attrs[`.desc__block_${params[0].port} .desc__text`]!.text =
				dateLabelAccountTz;
		}
	}

	ngOnDestroy() {
		// Очищаем инжектор блока Отправка сообщения
		// Для того чтобы при перезапуске редактора стэйт был пуст
		this.rappid.graph.attributes.cells?.models.forEach(item => {
			if (item.get('type') === 'sendMessageBlock') {
				const id = item.get('id');
				const context = item as unknown as InspectorItemContext;
				const state = context.injector.get(SendMessageStateToken);
				state?.delete(id);
			}
		});

		this.rappid.destroy();

		tippy.setDefaultProps({
			appendTo: this.tippyAppendDefault
		});
	}
}
