<div class="duplicate-handling">
    <span class="sub-title en-text-medium">{{'scenario_block_api.message_summary' | translate}}</span>
    <en-dropdown styleClass="h36"
                 [options]="selectOptions"
                 [attr.disabled]="!isCreationMode"
                 (onChange)="changeDuplicateHandling.emit($event.value)"
                 [formControl]="control">
        <ng-template let-selected
                     pTemplate="selectedItem">
            <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
        </ng-template>
        <ng-template let-type
                     pTemplate="item">
            <div class="duplicate-handling__drop">
                <span class="drop-label">{{type.label | translate}}</span>
                <div class="drop-description">{{type.description | translate}}</div>
            </div>
        </ng-template>
    </en-dropdown>
</div>

<en-messages class="en-message"
             [visible]="message.length"
             [(value)]="message"></en-messages>