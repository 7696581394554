import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LayoutArchiveUploaderService {
	constructor(private http: HttpClient) {}

	uploadFile(
		file: File,
		parameters: any
	): Observable<{ html: string; mailId: number; plainText: string }> {
		const formData = new FormData();

		formData.append('layout_data', file, file.name);
		const params = new HttpParams({ fromObject: parameters });
		return this.http.post<{
			html: string;
			mailId: number;
			plainText: string;
		}>('ensend/message/uploadLayout/', formData, {
			params
		});
	}
}
