import { FormGroup } from '@angular/forms';
import { ConditionJsonForm, ConditionFormTypes } from '../segments-form.model';

export function checkValidateConditions(
	conditions: FormGroup<ConditionJsonForm>
): boolean {
	let validateStatus = false;
	if (conditions.valid) return false;
	conditions.controls.conditions.controls.forEach(condition => {
		if (isInvalidCondition(condition)) validateStatus = true;
	});
	conditions.controls.inners.controls.forEach(inner => {
		if (isInvalidInner(inner)) validateStatus = true;
	});
	return validateStatus;
}

export function isInvalidCondition(
	condition: FormGroup<ConditionFormTypes>
): boolean {
	return condition.invalid && !condition.controls.isHide?.value;
}

export function isInvalidInner(inner: FormGroup<ConditionJsonForm>): boolean {
	const isInnerHide = inner.controls.isHide.value;
	let result = false;
	inner.controls.conditions.controls?.forEach(condition => {
		if (isInvalidCondition(condition) && !isInnerHide) result = true;
	});
	inner.controls.inners.controls?.forEach(item => {
		if (isInvalidInner(item)) result = true;
	});
	return result;
}
