/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { ScenarioShapesEnum } from '../../../../config/enum.shapes';
import { mainBlock, mainBlockView } from './main.shapes';

export const dataChangeMainBlock = mainBlock.define(
	ScenarioShapesEnum.DATA_CHANGE_MAIN_BLOCK,
	{},
	{
		onChangeOptions() {
			mainBlock.prototype.onChangeOptions.call(this);
		}
	}
);

export const dataChangeMainBlockView: dia.ElementView =
	Object.assign(mainBlockView);
