import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';
import { MessageBodyForm } from '@enKod/segments/segments-form.model';
import {
	CLICK_OPTIONS,
	OPEN_OPTIONS,
	NOT_OPEN_OPTIONS,
	READ_OPTIONS,
	SEND_OPTIONS,
	SEND_SMS_OPTIONS
} from './addition-condition-options';

@UntilDestroy()
@Component({
	selector: 'en-add-condition-button',
	template: `
		<ng-container *ngIf="isAviableOptions">
			<en-multilevel-dropdown
				[options]="conditionOptions"
				[isButtonMode]="true"
				[icon]="'en-plus-circle-o'"
				[useSegmentsButton]="true"
				[useMainMenuStyle]="true"
				(click)="refreshMenuItems()"
				(itemClick)="selectItem($event)"
			></en-multilevel-dropdown>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddConditionButtonComponent implements OnInit {
	allConditionOptions: MultiMenuItem[] = [];

	conditionOptions$ = new BehaviorSubject<MultiMenuItem[]>(
		this.allConditionOptions
	);

	conditionOptions: MultiMenuItem[];

	@Input() parameterControl: FormControl<string>;

	@Input() itemHasBeenDeleted$: Subject<void>;

	@Input() messageGroup: FormGroup<MessageBodyForm>;

	@Input() showLinksOption: boolean;

	@Output() onItemClick = new EventEmitter<string>();

	constructor(private cd: ChangeDetectorRef) {}

	get actionControl(): FormControl<string> {
		return this.messageGroup.controls.action as FormControl<string>;
	}

	get actionControlValue(): string {
		return this.actionControl?.value;
	}

	get channelControl(): FormControl<string> {
		return this.messageGroup.controls.channel as FormControl<string>;
	}

	get channelControlValue(): string {
		return this.channelControl?.value;
	}

	get isAviableOptions(): boolean {
		return this.conditionOptions.length !== 0;
	}

	get isBounce(): boolean {
		return (
			this.actionControlValue === 'bounce' ||
			this.actionControlValue === 'not_bounce'
		);
	}

	get isSmsChannel(): boolean {
		return this.channelControlValue === 'sms';
	}

	ngOnInit(): void {
		this.conditionOptions$.pipe(untilDestroyed(this)).subscribe(options => {
			this.conditionOptions = options;
			this.cd.markForCheck();
		});

		this.itemHasBeenDeleted$
			.pipe(untilDestroyed(this))
			.subscribe(() => this.refreshMenuItems());

		this.parameterControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(() => this.refreshMenuItems());

		this.refreshMenuItems();
	}

	// Получение списка меню с фильтрацией итемов
	getMenuItems(parameter: string): MultiMenuItem[] {
		this.setOptionsByAction();
		let conditionOptions: MultiMenuItem[] = [];

		switch (parameter) {
			case 'any':
				conditionOptions = this.allConditionOptions.filter(
					item => item.value !== 'actionLink'
				);
				break;
			case 'specific':
				conditionOptions = this.allConditionOptions.filter(
					item =>
						item.value === 'actionTime' ||
						item.value === 'actionCount' ||
						item.value === 'bounceType' ||
						(this.showLinksOption && item.value === 'actionLink')
				);
				break;
			default:
				conditionOptions = this.allConditionOptions;
				break;
		}

		this.isBounce
			? (conditionOptions = conditionOptions.filter(
					item => item.value !== 'actionCount'
			  ))
			: (conditionOptions = conditionOptions.filter(
					item => item.value !== 'bounceType'
			  ));

		if (this.channelControl.value === 'push') {
			conditionOptions = conditionOptions.filter(
				item => item.value !== 'onlyUniqMessage'
			);
		}

		Object.keys(this.messageGroup.value).forEach(control => {
			conditionOptions = conditionOptions.filter(
				item => item.value !== control
			);
		});
		return conditionOptions;
	}

	selectItem(value: string): void {
		this.onItemClick.emit(value);
		this.refreshMenuItems();
	}

	refreshMenuItems() {
		this.conditionOptions$.next(
			this.getMenuItems(this.parameterControl.value)
		);
		this.cd.markForCheck();
	}

	setOptionsByAction() {
		switch (this.actionControlValue) {
			case 'send':
			case 'not_send':
				this.allConditionOptions = SEND_OPTIONS;
				break;
			case 'open':
				this.allConditionOptions = OPEN_OPTIONS;
				break;
			case 'not_open':
				this.allConditionOptions = NOT_OPEN_OPTIONS;
				break;
			case 'click':
			case 'not_click':
				this.allConditionOptions = CLICK_OPTIONS;
				break;
			case 'bounce':
				this.allConditionOptions = this.isSmsChannel
					? SEND_SMS_OPTIONS
					: SEND_OPTIONS;
				break;
			case 'read':
			case 'not_read':
				this.allConditionOptions = READ_OPTIONS;
				break;
			default:
				this.allConditionOptions = SEND_OPTIONS;
				break;
		}

		this.updateWhatsappOptions();
	}

	updateWhatsappOptions() {
		if (this.channelControl.value === 'whatsapp') {
			this.allConditionOptions = this.allConditionOptions.filter(item => {
				if (item.value === 'sendingType' || item.value === 'bounceType')
					return false;
				return true;
			});
		}
	}
}
