import { AbstractControl } from '@angular/forms';

export function segmentsValidator({ value }: AbstractControl) {
	return value?.length ? null : { emptySegments: true };
}

export function subscriptionsValidator({ value }: AbstractControl) {
	return value?.length ? null : { emptySubscriptions: true };
}

export function fileValidator({ value }: AbstractControl) {
	return Object.keys(value).length ? null : { emptyFile: true };
}
