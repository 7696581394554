<ng-container *ngIf="forceRerender$ && forceRerender$ | async"></ng-container>
<div class="toolbar">

    <div *ngIf="isToggleCursorView"
         class="panel">
        <div class="panel__group">
            <button type="button"
                    class="button"
                    [class.button_active]="!isSelectionMod"
                    [tippy]="tippyCursor"
                    [appendTo]="canva"
                    (click)="toggleCursorMod()">
                <span class="en en-hand"></span>
            </button>
        </div>
    </div>

    <div class="panel panel_navigator">
        <div *ngIf="isScaleButtonsView"
             class="panel__group">
            <button type="button"
                    class="button"
                    [tippy]="tippyUndo"
                    [appendTo]="canva"
                    (click)="undo()">
                <span class="en en-undo"></span>
            </button>
            <button type="button"
                    class="button"
                    [tippy]="tippyRedo"
                    [appendTo]="canva"
                    (click)="redo()">
                <span class="en en-redo"></span>
            </button>
        </div>

        <div class="panel__group">
            <button type="button"
                    class="button"
                    [tippy]="tippyZoomIn"
                    [appendTo]="canva"
                    (click)="zoomIn()">
                <span class="en en-zoom-in"></span>
            </button>

            <span class="panel__text">{{scale$ | async}}%</span>

            <button type="button"
                    class="button"
                    [tippy]="tippyZoomOut"
                    [appendTo]="canva"
                    (click)="zoomOut()">
                <span class="en en-zoom-out"></span>
            </button>
        </div>

        <div class="panel__group">
            <button type="button"
                    class="button"
                    [tippy]="tippyFullscreen"
                    [appendTo]="canva"
                    (click)="toggleFullScreen()">
                <span class="en en-expand"></span>
            </button>
            <button *ngIf="isToggleNavigatorView"
                    type="button"
                    class="button"
                    [class.button_active]="isNavigator"
                    [tippy]="tippyPreview"
                    [appendTo]="canva"
                    (click)="toggleNavigator()">
                <span class="en en-earth-o"></span>
            </button>
        </div>
    </div>

</div>

<div #navigator
     class="navigator">
</div>

<!-- TIPPY TEMPLATES -->
<ng-template #tippyCursor>
    <ng-container *ngIf="isSelectionMod; else selectObject">
        {{'scenario_wizard_canva.move_mouse_tooltip' | translate}}
        <span class="tippy__secondary-text"> V</span>
    </ng-container>
</ng-template>

<!-- тултипы не работают в fullscreen -->
<ng-template #tippyFullscreen>
    <ng-container *ngIf="isFullScreenMod; else fullScreen">
        {{'scenario_wizard_canva.hide_tooltip' | translate}}
        <span class="tippy__secondary-text"> Ctrl + O</span>
    </ng-container>
</ng-template>

<ng-template #tippyPreview>
    <ng-container *ngIf="isNavigator; else showPreview">
        {{'scenario_wizard_canva.hide_preview_tooltip' | translate}}
        <span class="tippy__secondary-text"> Ctrl + M</span>
    </ng-container>
</ng-template>

<ng-template #selectObject>{{'scenario_wizard_canva.select_object_tooltip' | translate}}
    <span class="tippy__secondary-text"> V</span>
</ng-template>
<ng-template #fullScreen>{{'scenario_wizard_canva.fullscreen_mode_tooltip' | translate}}
    <span class="tippy__secondary-text"> Ctrl + O</span>
</ng-template>
<ng-template #showPreview>{{'scenario_wizard_canva.show_preview_tooltip' | translate}}
    <span class="tippy__secondary-text"> Ctrl + M</span>
</ng-template>
<ng-template #tippyUndo>{{'scenario_wizard_canva.undo_tooltip' | translate}}
    <span class="tippy__secondary-text"> Ctrl + Z</span>
</ng-template>
<ng-template #tippyRedo>{{'scenario_wizard_canva.redo_tooltip' | translate}}
    <span class="tippy__secondary-text"> Ctrl + Shift + Z</span>
</ng-template>
<ng-template #tippyZoomIn>{{'scenario_wizard_canva.zoom_in_tooltip' | translate}}
    <span class="tippy__secondary-text">Ctrl + scroll</span>
</ng-template>
<ng-template #tippyZoomOut>{{'scenario_wizard_canva.zoom_out_tooltip' | translate}}
    <span class="tippy__secondary-text">Ctrl - scroll</span>
</ng-template>