import { Injector } from '@angular/core';
import { dia } from '@clientio/rappid';
import { InspectorService } from '@enSend/_shared/components/scenario-canva/services';
import { Subtype } from '@enSend/_shared/models';
import { shapeConfig } from '../../../config/shape.config';
import { ShapeTypesEnum } from '../../../config/enum.shapes';

enum BlockData {
	'id',
	'type',
	'subType',
	'namespace',
	'ports',
	'options',
	'position',
	'z',
	'angle',
	'count',
	'scenarioId'
}

export const base = dia.Element.define(
	ShapeTypesEnum.BASE,
	{
		// no default attributes
	},
	{
		injector: null,
		// getBoundaryPadding: function() {},
		toJSON() {
			// Simplify the element resulting JSON
			const json = dia.Element.prototype.toJSON.call(this);

			Object.keys(json).forEach(key => {
				if (key in BlockData) return;
				delete json[key];
			});

			// Remove port groups and angle for better readability
			delete json.ports.groups;
			return json;
		},

		initialize(_cell: dia.Cell, options?: { [key: string]: any }) {
			const injector = options?.injector;
			const namespace = this.get('namespace') ?? 'scenario';
			if (injector) this.injector = injector;
			dia.Element.prototype.initialize.call(this, _cell, options);

			const subType = this.get('subType');
			const subtypes = shapeConfig[namespace as keyof typeof shapeConfig];

			this.attr({
				name: {
					text: (
						subtypes[subType as keyof typeof subtypes] as Subtype
					).text
				},
				headerIcon: {
					xlinkHref: (
						subtypes[subType as keyof typeof subtypes] as Subtype
					).iconLinkHref
				}
			});
		},
		clone(...args: any) {
			const cloned = dia.Element.prototype.clone.apply(this, args);
			// @ts-ignore
			cloned.injector = this.injector;
			return cloned;
		},
		remove(...args: any) {
			const inspectorService = this.injector?.get(InspectorService);
			inspectorService.toggleInspector(false);
			dia.Element.prototype.remove.apply(this, args);
		}
	},
	{
		fromStencilShape(element: dia.Element, injector: Injector) {
			const attrs = {
				name: {
					text: element.attr(['label', 'text'])
				},
				headerIcon: {
					xlinkHref: element.attr(['icon', 'xlinkHref'])
				}
			};
			const subType = element.get('subType');
			const namespace = element.get('namespace');
			const options = {
				[subType]: {
					params: []
				}
			};
			const inspectorService = injector?.get(InspectorService);
			inspectorService.toggleInspector(false);

			return new this(
				{
					attrs,
					subType,
					...(!namespace ? { options } : {}),
					...(namespace ? { namespace } : {})
				},
				{ injector }
			);
		}
	}
);
