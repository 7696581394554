import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl
} from '@angular/forms';

import { Observable, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';

import { CANVA_MODE_TOKEN, CanvaModeType } from '@enSend/_shared/tokens';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { SelectItemDesctiption } from '@enkod-core/interfaces';

import { InspectorItemContext } from '../../inspector-item-plugin';
import { DUPLICATE_HANDLING_OPTIONS } from '../mailing-group/constants';

import { EventStartDataService } from './_state/event-start-date.service';
import {
	Condition,
	EventOption,
	EventStartParamData,
	FormValue,
	ParamOption
} from './_state/event-start.model';
import {
	booleanOperatorOptions,
	dateOperatorOptions,
	numberOperatorOptions,
	stringOperatorOptions
} from './constants/event-start.options';
import { AbstractInspectorStart } from '../../abstract/abstract-inspector-start';

@UntilDestroy()
@Component({
	selector: 'en-event-start',
	templateUrl: './event-start.component.html',
	styleUrls: ['./event-start.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: DUPLICATE_HANDLING_OPTIONS
		},
		EventStartDataService
	]
})
export class EventStartComponent
	extends AbstractInspectorStart
	implements OnInit
{
	eventOptions: EventOption[];
	paramOptions: ParamOption[];

	maskNumber = {
		mask: Number,
		thousandsSeparator: '',
		scale: 0,
		max: '999999999999999',
		min: '-999999999999999'
	};

	maskFloat = {
		mask: Number,
		thousandsSeparator: '',
		scale: '9',
		radix: '.',
		mapToRadix: [','],
		max: '999999999',
		min: '-999999999'
	};

	readonly operatorsWithoutValue = [
		'true',
		'false',
		'empty',
		'notEmpty',
		'today',
		'thisDay',
		'thisMonth',
		'thisYear'
	];

	form = this.fb.group({
		systemName: '',
		conditions: this.fb.array([])
	});

	constructor(
		fb: UntypedFormBuilder,
		translate: TranslateService,
		private eventService: EventStartDataService,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: SelectItemDesctiption[]
	) {
		super(fb, translate);
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get disableInput() {
		return this.isCreationMode ? null : true;
	}

	get subTypeOptions() {
		const options = this.cell.get('options');
		const subType = this.cell.get('subType');
		return options[subType];
	}

	get conditionControlArray() {
		return this.form.controls.conditions as UntypedFormArray;
	}

	get conditionControls() {
		return this.conditionControlArray.controls;
	}

	get eventControl() {
		return this.form.controls.systemName as UntypedFormControl;
	}

	ngOnInit() {
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;

						this.initLists();

						this.initDuplicateHandling();
					}
				})
			)
			.subscribe();

		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					const formattedValue = this.otd(value);
					this.changeCellProp('options', {
						isValid: this.isFormValid(),
						duplicateHandling: this.duplicateHandling.value,
						customEventStart: {
							params: [formattedValue]
						}
					});
					this.disableUsedParams(value.conditions);
				})
			)
			.subscribe();
	}

	private initLists() {
		combineLatest([
			this.eventService.getEvents(),
			this.eventService.getParams()
		])
			.pipe(
				untilDestroyed(this),
				tap(([events, parameters]) => {
					if (!this.eventOptions)
						this.eventOptions = events.map(event => ({
							label: event.name,
							value: { id: event.id, sysName: event.systemName }
						}));
					if (!this.paramOptions)
						this.paramOptions = parameters.map(param => ({
							label: param.name,
							value: {
								id: param.id,
								sysName: param.systemName,
								type: param.type
							}
						}));
					if (events && parameters) {
						const { params } = this.subTypeOptions;

						this.patchOnInit(params);
					}
				})
			)
			.subscribe();
	}

	private patchOnInit(params: EventStartParamData[]) {
		if (!params.length) return;

		const formParams = {
			systemName: params[0].systemName,
			conditions: params[0].conditions
		};
		this.conditionControls.length = 0;
		formParams.conditions.forEach(() => {
			this.conditionControlArray.push(
				this.fb.group({
					paramName: '',
					operator: '',
					value: ''
				})
			);
		});

		this.form.patchValue(this.dto(formParams));

		this.disableUsedParams(this.conditionControlArray.value);
	}

	private isFormValid() {
		const formValue = this.form.value;
		if (!formValue.systemName) return false;
		const boolValidControls = formValue.conditions.map(
			(cond: Condition) => {
				if (!cond.paramName || !cond.paramName.sysName) return false;
				if (!cond.operator) return false;
				if (
					!this.operatorsWithoutValue.includes(cond.operator) &&
					!cond.value
				)
					return false;
				if (
					cond.operator === 'between' &&
					typeof cond.value !== 'string' &&
					typeof cond.value !== 'number' &&
					cond.value?.endDate === 0
				) {
					return false;
				}
				return true;
			}
		);
		return !boolValidControls.includes(false);
	}

	/// преобразуем значения в строки для бека и убираем пустые value
	private otd(formValue: FormValue) {
		const event = this.eventOptions.find(
			option => option.value === this.eventControl.value
		);
		const label = event?.label;
		const systemName = event?.value.sysName;

		const formattedConditions = formValue.conditions.map(
			(cond: Condition) => {
				const condition = {
					paramName: cond.paramName?.sysName,
					operator: cond.operator
				};
				if (String(cond.value) === '0') {
					return {
						...condition,
						value: '0'
					};
				}
				if (
					!cond.value ||
					!String(cond.value).length ||
					this.operatorsWithoutValue.includes(cond.operator)
				) {
					return condition;
				}
				if (typeof cond.value === 'number') {
					return {
						...condition,
						value: cond.value.toString()
					};
				}
				if (
					typeof cond.value !== 'string' &&
					cond.operator === 'between'
				) {
					return {
						...condition,
						value: `${cond.value.startDate},${
							cond.value.endDate || ''
						}`
					};
				}
				return { ...condition, value: cond.value };
			}
		);
		return { label, systemName, conditions: formattedConditions };
	}

	/// преобразуем значения с бека из строк в типы, соответствующие своим инпутам
	private dto(formValue: EventStartParamData) {
		const formattedEvent = this.eventOptions.find(
			option => option.value.sysName === formValue.systemName
		)?.value;
		const paramTypes = ['number', 'float', 'date', 'dateTime'];
		const paramsToNumber = this.paramOptions
			.filter(param => paramTypes.includes(param.value.type))
			.map(option => option.value.sysName);
		const formattedConditions = formValue.conditions.map(cond => {
			const condition = {
				paramName: this.paramOptions.find(
					option => option.value.sysName === cond.paramName
				)?.value,
				operator: cond.operator
			};
			if (typeof cond.value === 'string' && cond.operator === 'between') {
				const betweenValues = cond.value?.split(',');
				return {
					...condition,
					value: {
						startDate: betweenValues[0],
						endDate: betweenValues[1]
					}
				};
			}
			if (cond.value && paramsToNumber.includes(cond.paramName)) {
				return {
					...condition,
					value: +cond.value
				};
			}
			return { ...condition, value: cond.value };
		});
		return {
			systemName: formattedEvent,
			conditions: formattedConditions
		};
	}

	private disableUsedParams(conditions: Condition[]) {
		const usedParams = conditions.map(
			(condition: Condition) => condition.paramName?.sysName
		);
		this.paramOptions = this.paramOptions.map(option => {
			const isOptionUsed = usedParams.includes(option.value.sysName);

			return {
				...option,
				disabled: isOptionUsed
			};
		});
	}

	/// сбрасываем нижестоящие контролы (3 метода)
	resetParams() {
		this.conditionControls.length = 0;
		this.changeCellProp('options', {
			isValid: this.isFormValid(),
			duplicateHandling: this.duplicateHandling.value,
			customEventStart: {
				params: [
					{
						conditions: [],
						label: this.eventOptions.find(
							option => option.value === this.eventControl.value
						)?.label,
						systemName: this.eventControl.value.sysName
					}
				]
			}
		});
	}

	resetFromParam(i: number) {
		this.conditionControls[i].get('operator')?.reset();
		this.conditionControls[i].get('value')?.reset();
	}

	resetValue(i: number) {
		this.conditionControls[i].get('value')?.reset();
	}

	getOperatorOptions(paramValue: string) {
		const paramType = this.paramOptions.find(
			option => option.value.sysName === paramValue
		)?.value.type;
		switch (paramType) {
			case 'string':
				return stringOperatorOptions;
			case 'number':
				return numberOperatorOptions;
			case 'float':
				return numberOperatorOptions;
			case 'bool':
				return booleanOperatorOptions;
			case 'date':
				return dateOperatorOptions;
			case 'dateTime':
				return dateOperatorOptions;
			default:
				return [];
		}
	}

	getParamType(paramValue: string) {
		return this.paramOptions.find(
			option => option.value.sysName === paramValue
		)?.value.type;
	}

	addParam() {
		this.conditionControlArray.push(
			this.fb.group({
				paramName: '',
				operator: '',
				value: ''
			})
		);
	}

	deleteParam(i: number) {
		this.conditionControls.splice(i, 1);
		this.conditionControlArray.value.splice(i, 1);
		const formValue = {
			...this.form.value,
			conditions: this.conditionControlArray.value
		};
		const formattedValue = this.otd(formValue);
		this.changeCellProp('options', {
			isValid: this.isFormValid(),
			duplicateHandling: this.duplicateHandling.value,
			customEventStart: {
				params: [formattedValue]
			}
		});
		this.disableUsedParams(this.conditionControlArray.value);
	}
}
