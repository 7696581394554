import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UploadEvent } from '@enkod-core/types';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FileUploaderComponent } from 'ui-lib/file-uploader/fileupload';
import { LayoutArchiveUploaderService } from './layout-archive-uploader.service';

@UntilDestroy()
@Component({
	selector: 'en-archive-uploader',
	templateUrl: './archive-uploader.component.html',
	styleUrls: ['./archive-uploader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveUploaderComponent {
	@Input() loading = false;
	@Output() loadingChange = new EventEmitter();
	@Input() requestParams = {};

	@Output() onArchiveLoaded = new EventEmitter<object>();

	loading$ = new BehaviorSubject<boolean>(false);
	messages: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);

	maxFileSize = 5 * 1024 * 1024;
	accept = '.zip';

	@ViewChild('uploader') private uploader: FileUploaderComponent;

	constructor(
		private translate: TranslateService,
		private uploadService: LayoutArchiveUploaderService
	) {}

	openExplorer() {
		this.uploader.onBasicUploaderClick();
		this.messages.next([]);
	}

	customUploader({ files: [file] }: UploadEvent) {
		this.loading = true;
		this.loadingChange.emit(this.loading);
		this.uploadService
			.uploadFile(file, this.requestParams)
			.pipe(
				untilDestroyed(this),
				tap(response => this.onArchiveLoaded.emit(response)),
				catchError(err => {
					this.uploader.clear();
					this.errorHandling(err.message);
					return throwError(err);
				}),
				finalize(() => {
					this.loading = false;
					this.loadingChange.emit(this.loading);
				})
			)
			.subscribe();
	}

	onInvalidFileSize() {
		this.messages.next([
			{
				severity: 'error',
				summary: this.translate.instant(
					'layout_archive_uploader.not_valid_file_size'
				)
			}
		]);
	}

	onInvalidFileType() {
		this.messages.next([
			{
				severity: 'error',
				summary: this.translate.instant(
					'layout_archive_uploader.not_valid_file_type'
				)
			}
		]);
	}

	private errorHandling(message: string) {
		switch (message) {
			case 'invalid structure':
			default: {
				this.messages.next([
					{
						severity: 'error',
						summary: this.translate.instant(
							'layout_archive_uploader.incorrect_file_structer_summary'
						),
						detail: this.translate.instant(
							'layout_archive_uploader.incorrect_file_structer_details'
						)
					}
				]);
			}
		}
	}
}
