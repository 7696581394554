import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';

import {
	RestService,
	convertPaginationAndSortParams,
	deleteEmptyKeys
} from 'utils';

import { Message } from '@enSend/message/_states/_state-message';
import { Observable } from 'rxjs';
import {
	ChangeStatusDetails,
	ChooseWinnerRequestBody,
	IMessageDataDetail,
	IMessageDetail,
	LinkData,
	LinksQueryParams,
	LinkUpdate,
	MessageInfo,
	MessageStatisticsType
} from './message-detail.model';

@Injectable({
	providedIn: 'root'
})
export class MessageDetailDataService extends RestService<IMessageDetail> {
	constructor(protected http: HttpClient) {
		super(http, 'ensend/statistic/mail');
	}

	grouped(id: ID, queryParams: any = {}) {
		const params = new HttpParams({
			fromObject: queryParams
		});
		return this.http.get(`ensend/statistic/mail/${id}/grouped/`, {
			params
		});
	}

	getDetailById(id: ID) {
		return this.http.get<Message>(`ensend/message/${id}`);
	}

	updateTags(id: ID, data: object = {}) {
		return this.http.put(`enkod/tag/message/${id}/`, data);
	}

	getStatisticDetail(id: ID) {
		return this.http.get(
			`ensend/statistic/messageinfo/${id}`
		) as Observable<MessageInfo>;
	}

	getMessageStatistics(
		id: ID,
		contentType: MessageStatisticsType = 'all',
		queryParams: any
	): Observable<IMessageDataDetail[]> {
		const params = new HttpParams({
			fromObject: {
				contentType,
				...queryParams
			}
		});

		return this.http.get(`ensend/statistic/mail/${id}`, {
			params
		}) as Observable<IMessageDataDetail[]>;
	}

	chooseSplitWinner(body: ChooseWinnerRequestBody) {
		return this.http.post('ensend/message/sendWinner', body);
	}

	getSplitStatistics(id: number, queryParams: any) {
		const params = new HttpParams({
			fromObject: queryParams
		});
		return this.http.get(`ensend/statistic/splitTest/${id}`, {
			params
		}) as Observable<IMessageDataDetail[]>;
	}

	getAmpSplitStatistics(id: number, queryParams: any) {
		const params = new HttpParams({
			fromObject: queryParams
		});
		return this.http.get(`ensend/statistic/splitTest/amp/${id}`, {
			params
		}) as Observable<IMessageDataDetail[]>;
	}

	getClicksStatistic(id: number, queryParams: any) {
		const params = new HttpParams({
			fromObject: queryParams
		});
		return this.http.get(`ensend/statistic/clickmap/${id}`, { params });
	}

	getLinksData(queryParams: LinksQueryParams): Observable<LinkData[]> {
		const params = new HttpParams({
			fromObject: queryParams
		});
		return this.http.get(`ensend/message/links/`, {
			params
		}) as Observable<LinkData[]>;
	}

	changeLink(data: LinkUpdate) {
		return this.http.put(`ensend/message/links/`, data);
	}

	cancelSend(id: number) {
		return this.http.post('ensend/message/stop/', { id });
	}

	changeStatus({ id, status }: ChangeStatusDetails): Observable<Message> {
		return this.http.put(`ensend/message/${id}`, {
			isActive: status
		}) as Observable<Message>;
	}

	deleteMessage(id: number): Observable<void> {
		return this.http.delete<void>(`ensend/message/${id}`);
	}

	exportStat(
		id: number,
		queryParams: { startDate?: number; endDate?: number }
	) {
		const params = new HttpParams({
			fromObject: convertPaginationAndSortParams(
				deleteEmptyKeys(queryParams) as {
					[param: string]: string;
				}
			)
		});
		return this.http.get(`ensend/message/export/${id}`, {
			params,
			responseType: 'blob',
			observe: 'response'
		});
	}
}
