<div class="tag"
     tooltipPosition="bottom"
     tooltipStyleClass="tag__tooltip"
     [enTooltip]="tooltipText">
    <i *ngIf="leftIcon"
       [class]="'tag__icon en ' + leftIcon"></i>
    <div *ngIf="loader"
         class="tag__loader"></div>
    <span class="tag__text">{{ displayText }}</span>
    <i *ngIf="isDeletable"
       class="en en-cancel-fill tag__delete"
       (click)="removeItem.emit()"></i>
</div>
