import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { CHANNEL_OPTIONS } from '@enkod-core/constants';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { MailingGroupsService } from '@state-enKod/mailing-groups';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { TuiMapper } from '@taiga-ui/cdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ID } from '@datorama/akita';
import { SelectItemValue, SelectItemWithDeleted } from '@state-enKod/segments';
import {
	SubscriptionConditionForm,
	SubscriptionDateForm,
	TimeRangeForm
} from '@enKod/segments/segments-form.model';
import { OPTIONS_TIME_ABSTRACT } from '../../../../_constants';
import { AbstractCondition } from '../../../abstract-condition.component';
import { mailingGroupSelectOptionFactory } from './functions';
import { UnitsDeclinationService } from '../../../common/services/units-declination.service';

type Channel = 'any' | 'mob_push' | 'web_push' | 'email' | 'sms';

type MailingGroup = SelectItemWithDeleted & {
	value: { channel: 'email' | 'sms' };
} & {
	singleItem?: boolean;
};

@UntilDestroy()
@Component({
	selector: 'en-subscription-date-condition',
	templateUrl: './subscription-date-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	providers: [
		UnitsDeclinationService,
		/** Селект каналов [0] */
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: CHANNEL_OPTIONS,
			multi: true
		},
		/** Период между датами / за последнее время [1] */
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: OPTIONS_TIME_ABSTRACT,
			multi: true
		},
		/** Список групп рассылок [2] (async) */
		{
			provide: SELECT_OPTIONS_TOKEN,
			useFactory: mailingGroupSelectOptionFactory,
			deps: [MailingGroupsService],
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionDateConditionComponent
	extends AbstractCondition
	implements OnInit
{
	private correctMailingGroups$ = new Subject();

	mailingGroups: SelectItem[];

	markAsInvalid = false;

	isFirstLoad = true;

	constructor(
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: Array<SelectItem<string>[]>,
		public segmentsValidateService: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(segmentsValidateService, cd);
	}

	get typedForm(): FormGroup<SubscriptionConditionForm> {
		return this.form as FormGroup<SubscriptionConditionForm>;
	}

	get subscriptionDateControl(): FormGroup<SubscriptionDateForm> {
		return this.typedForm.controls
			.subscriptionDate as FormGroup<SubscriptionDateForm>;
	}

	get actionTime(): FormGroup<TimeRangeForm> {
		return this.subscriptionDateControl.controls
			.actionTime as FormGroup<TimeRangeForm>;
	}

	get channelControl(): FormControl<Channel> {
		return this.subscriptionDateControl.controls
			.channel as FormControl<Channel>;
	}

	get operatorControl(): FormControl<string> {
		return this.subscriptionDateControl.controls
			.operator as FormControl<string>;
	}

	get groupsControl(): FormControl<
		SelectItemWithDeleted[] | SelectItemValue[]
	> {
		return this.subscriptionDateControl.controls.groups as FormControl<
			SelectItemWithDeleted[]
		>;
	}

	get groupsControlValue() {
		return this.groupsControl.value;
	}

	get mailingGroupVisible(): boolean {
		return (
			this.channelControl.value === 'email' ||
			this.channelControl.value === 'sms'
		);
	}

	get isInvalidSelectedGroup(): boolean {
		return this.groupsControl.enabled && !this.groupsControl.value?.length;
	}

	ngOnInit(): void {
		this.setCategory(this.typedForm.value.type || '');
		this.channelChange(this.channelControl.value);
		this.setupValidationListener();

		this.correctMailingGroups$
			.pipe(
				untilDestroyed(this),
				startWith(null),
				switchMap(
					() =>
						this.selectOptions[2] as unknown as Observable<
							MailingGroup[]
						>
				),
				map((list: MailingGroup[]) =>
					list.filter(message => {
						const { value } = this.channelControl;
						const channel = value === 'sms' ? 'phone' : value;
						return message.value.channel === channel;
					})
				),
				map(list => {
					const deletedItems: SelectItemWithDeleted<ID>[] = [];
					this.groupsControlValue?.forEach(item => {
						const typed = item as SelectItemValue;
						if (typed.isDeleted) {
							deletedItems.push({
								label: typed.name,
								value: {
									id: typed.id,
									name: typed.name,
									channel: typed.channel,
									isDeleted: typed.isDeleted
								},
								disabledItem: typed.isDeleted
							});
						}
					});

					return [...deletedItems, ...list];
				})
			)
			.subscribe(list => {
				this.mailingGroups = this.sortedGroups(list);
				this.cd.markForCheck();
			});

		this.segmentsValidateService.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.form.markAsPristine();
						this.markAsInvalid = false;
					} else {
						this.markAsInvalid = this.isInvalidSelectedGroup;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.groupsControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.form.markAsPristine();
						this.markAsInvalid = false;
					} else {
						this.markAsInvalid = this.isInvalidSelectedGroup;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.segmentsValidateService.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.markAsInvalid = false;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	public channelChange(channel: Channel): void {
		switch (channel) {
			case 'any':
			case 'mob_push':
			case 'web_push': {
				this.subscriptionDateControl
					?.get('groups')
					?.disable({ emitEvent: false });
				this.operatorControl.disable();
				break;
			}
			case 'sms':
			case 'email':
			default: {
				this.subscriptionDateControl
					?.get('groups')
					?.enable({ emitEvent: false });
				this.operatorControl.enable();
				this.correctMailingGroups$.next();
				if (!this.isFirstLoad) {
					this.groupsControl.patchValue([], { emitEvent: false });
				}
				break;
			}
		}
		this.isFirstLoad = false;
	}

	deselectItem(index: number): void {
		const arrCopy = [...this.groupsControl.value];
		arrCopy.splice(index, 1);
		this.groupsControl.patchValue(arrCopy as SelectItemWithDeleted[]);
	}

	initSort(): void {
		this.subscriptionDateControl.controls.groups?.value?.forEach(
			(id: SelectItemWithDeleted) => {
				this.mailingGroups = this.mailingGroups.sort((a, b) => {
					if (a.value.id === id) return -1;
					return b.value.id === id ? 1 : 0;
				});
			}
		);
	}

	mapMultiSelect: TuiMapper<SelectItemValue<number>, any> = (
		item,
		groups: SelectItemWithDeleted<number>[]
	) => groups?.find(group => group.value.id === item.id);

	sortedGroups(group: SelectItem[]) {
		return group.sort((a, b) => {
			if (a.value.id > b.value.id) return -1;
			if (a.value.id < b.value.id) return 1;
			return 0;
		});
	}
}
