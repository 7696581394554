export function getMailingGroupSubheader(text: string) {
	switch (text) {
		case 'specificGroup':
			return 'scenario_block_mailing_groups.specific_mailing_group';
		case 'specificGroupWithSegment':
			return 'scenario_block_mailing_groups.specific_mailing_group_with_segment';
		case 'anyGroupWithSegment':
			return 'scenario_block_mailing_groups.any_mailing_group_with_segment';
		case 'mobPush':
			return 'scenario_block_mailing_groups.mobPush';
		default:
			return '';
	}
}

export function getMessageStartSubheader(text: string) {
	switch (text) {
		case 'open':
			return 'scenario_block_message_start.action_open';
		case 'click':
			return 'scenario_block_message_start.action_click';
		default:
			return '';
	}
}

export function getExtraFieldSubheader(text: string) {
	switch (text) {
		case 'extraField':
			return 'scenario_block_data_change.data_field_option';
		case 'scenarioField':
			return 'scenario_block_data_change_main.scenario_field_option';
		default:
			return '';
	}
}

export function getPauseSubheader(text: string) {
	switch (text) {
		case 'specificDate':
			return 'scenario_block_pause.exact_date';
		case 'extraFields':
			return 'scenario_block_pause.data_field_label';
		case 'scenarioFields':
			return 'scenario_block_destribution.scenario_field';
		default:
			return 'scenario_block_pause.duration';
	}
}

export function getDistributionSubheader(text: string) {
	switch (text) {
		case 'equally':
			return 'scenario_block_destribution.equally';
		case 'proportionally':
			return 'scenario_block_destribution.proportionally';
		case 'scenarioFields':
			return 'scenario_block_destribution.by_fields';
		default:
			return 'scenario_block_destribution.by_segments';
	}
}

export function getChannelSubheader(text: string) {
	switch (text) {
		case 'mail':
			return 'Email';
		case 'sms':
			return 'SMS';
		case 'mobPush':
			return 'Mobile Push';
		case 'webPush':
			return 'Web Push';
		case 'whatsapp':
			return 'WhatsApp';
		default:
			return '';
	}
}

export const chatbotSubheader = {
	start(text: string) {
		switch (text) {
			case 'link':
				return 'chatbot_start_block.launch_by_link';
			case 'keywords':
				return 'chatbot_start_block.launch_by_keywords';
			default:
				return 'chatbot_start_block.launch_by_start';
		}
	},

	message(text: string) {
		if (text) return text;
		return 'chatbot_message_block.message_text';
	}
};
