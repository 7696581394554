import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
	EnButtonModule,
	EnDropdownModule,
	EnInputModule,
	EnMessagesModule,
	EnSwitchModule,
	ErrorModule
} from 'ui-lib';
import { TuiLetModule } from '@taiga-ui/cdk';

import { SharedModule } from '@shared';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TranslateModule } from '@ngx-translate/core';

import { TippyModule } from '@ngneat/helipopper';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { DynamicContentModule } from '@enSend/_shared/components/dynamic-content/dynamic-content.module';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { ApiQueryService } from './api-query.service';
import { ApiQueryComponent } from './api-query.component';
import { JsonPluginDialogModule } from './json-plugin-dialog/json-plugin-dialog.module';
import { AdditionalSettingsComponent } from './additional-settings/additional-settings.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnButtonModule,
		TuiLetModule,
		EnDropdownModule,
		EnMessagesModule,
		EnSwitchModule,
		EnInputModule,
		ClipboardModule,
		TranslateModule,
		TippyModule,
		JsonPluginDialogModule,
		ErrorModule,
		InputTextareaModule,
		CodemirrorModule,
		DynamicContentModule
	],
	declarations: [ApiQueryComponent, AdditionalSettingsComponent],
	exports: [ApiQueryComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: ApiQueryService,
			multi: true
		}
	]
})
export class ApiQueryModule {}
