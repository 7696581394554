<div class="addition">
    <span class="center-span-text prefix"
          [class.active-hide]="isHide">
        {{ 'segment_form.with_unsubscribe_reason' | translate }}
    </span>
    <en-dropdown class="event-dropdown"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="isHide"
                 [options]="options"
                 [formControl]="control">
    </en-dropdown>

    <i class="button-remove-condition cancel-icon en en-cancel-fill"
       [class.active-hide]="isHide"
       (mouseenter)="isHover = true"
       (mouseleave)="isHover = false"
       (click)="removeReason()"></i>
</div>