import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';

export const EVENT_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_options.event_time_options',
		value: 'actionTime'
	},
	{
		label: 'segment_options.event_count_options',
		value: 'actionCount'
	},
	{
		label: 'segment_options.event_param_options',
		value: 'param'
	}
];
