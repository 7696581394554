import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ChangeDetectorRef
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
	distinctUntilChanged,
	takeUntil,
	debounceTime,
	filter,
	startWith
} from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { ConditionFormTypes } from '@enKod/segments/segments-form.model';
import { SegmentsValidateService } from '../../../segments-validate.service';
import { ABSTRACT_OPTIONS, ABSTRACT_OPTION_CATEGORIES } from '../_constants';

import {
	OperatorEquality,
	OperatorEqualityShort,
	OperatorFull,
	OperatorLogic,
	OperatorUnitsDefault,
	OptionsBehavior,
	OptionsCategoryBehavior,
	OptionsCategoryData,
	OptionsLink,
	OptionsOperatorShort,
	OptionsPage,
	OptionsPeriod,
	OptionsUserEvents
} from './common/models';

@Component({
	template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class AbstractCondition implements OnDestroy, OnInit {
	destroy$ = new Subject();

	type = new FormControl('');

	category = new FormControl('');

	equality = new FormControl('');

	optionsCategoryData: SelectItem<OptionsCategoryData>[] = [];

	optionsCategoryBehavior: SelectItem<OptionsCategoryBehavior>[] = [];

	optionsCategoryBuying: SelectItem[] = [];

	optionsCategoryMessage: SelectItem[] = [];

	optionsCategoryDataTables: SelectItem[] = [];

	optionsPage: SelectItem<OptionsPage>[] = [];

	optionsBehavior: SelectItem<OptionsBehavior>[] = [];

	optionsLink: SelectItem<OptionsLink>[] = [];

	optionsUserEvents: SelectItem<OptionsUserEvents>[] = [];

	optionsOperatorShort: SelectItem<OptionsOperatorShort>[] = [];

	optionsOperatorFull: SelectItem<OperatorFull>[] = [];

	optionsUnitsDefault: SelectItem<OperatorUnitsDefault>[] = [];

	optionsOperatorEquality: SelectItem<OperatorEquality>[] = [];

	optionsOperatorTagsEquality: SelectItem<OperatorEquality>[] = [];

	optionsOperatorRfmEquality: SelectItem<OperatorEqualityShort>[] = [];

	optionsOperatorLogic: SelectItem<OperatorLogic>[] = [];

	optionsOperatorUnsubscriptionLogic: SelectItem<OperatorLogic>[] = [];

	optionsOperatorPeriod: SelectItem<OptionsPeriod>[] = [];

	isInit = true;

	isHover = false;

	@Input() form: FormGroup<ConditionFormTypes>;

	@Input() conditionsArray: FormArray<FormGroup<ConditionFormTypes>>;

	@Input() index: number;

	@Input() isPreview: boolean;

	@Input() isHideParent = false;

	@Output() changeType = new EventEmitter<any>();

	@Output() removeCondition = new EventEmitter<number>();
	@Output() copyCondition = new EventEmitter<number>();

	constructor(
		public segmentsValidateService: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		this.translateOptions();
	}

	get showHide(): boolean {
		return (
			!this.isHideParent && (this.form.controls.isHide?.value as boolean)
		);
	}

	get isHide(): boolean {
		return this.form.controls.isHide?.value as boolean;
	}

	ngOnInit() {
		const currentType = this.form.controls.type.value;
		this.type.setValue(currentType);
		this.setCategory(currentType);
		this.setValidation();
		this.translateInnerOptions();
		this.setupValidationListener();
	}

	public setupValidationListener(): void {
		this.segmentsValidateService.checkedValidate
			.pipe(takeUntil(this.destroy$), debounceTime(100))
			.subscribe(() => {
				if (this.isHide || this.isHideParent)
					this.form.markAsPristine();
				this.markForCheck();
			});
	}

	setCategory(type: string) {
		switch (type) {
			case 'time':
			case 'scroll':
			case 'referrers':
			case 'visits':
			case 'close':
				return this.category.setValue('page');
			case 'url':
			case 'utm':
				return this.category.setValue('link');
			case 'device':
				return this.category.setValue('device');
			case 'html':
				return this.category.setValue('html');
			case 'visitsHistory':
				return this.category.setValue('visitsHistory');
			case 'category_open':
			case 'cart':
			case 'product':
			case 'purchase':
			case 'favourite':
				return this.category.setValue('product');
			case 'field':
				return this.category.setValue('field');
			case 'subscription':
				return this.category.setValue('subscription');
			case 'subscriptionDate':
				return this.category.setValue('subscriptionDate');
			case 'unsubscription':
				return this.category.setValue('unsubscription');
			case 'tag':
				return this.category.setValue('tag');
			case 'currentRFM':
				return this.category.setValue('currentRFM');
			default:
				return this.category.setValue('');
		}
	}

	setValidation() {}

	translateInnerOptions() {}

	onChangeCategory(option: any) {
		let type: string;
		switch (option.value) {
			case 'page':
				type = 'unselected-behavior-page';
				break;
			case 'link':
				type = 'unselected-behavior-link';
				break;
			default:
				type = option.value;
				break;
		}
		this.changeType.emit({ index: this.index, type });
	}

	onChangeType(option: any) {
		this.changeType.emit({ index: this.index, type: option.value });
	}

	getSubscription<T = any>(param: string) {
		return this.form.get(param)?.valueChanges.pipe(
			startWith(this.form.get(param)?.value),
			takeUntil(this.destroy$),
			distinctUntilChanged(),
			debounceTime(200),
			filter(value => value !== null)
		) as Observable<T>;
	}

	getSubscriptionNoDebounce(param: string) {
		return this.form.get(param)?.valueChanges.pipe(
			startWith(this.form.get(param)?.value),
			takeUntil(this.destroy$),
			distinctUntilChanged(),
			filter(value => value !== null)
		);
	}

	setValue(control: string, value: any): void {
		if (this.isInit && (!value || control === 'value')) this.isInit = false;
		else this.form.get(control)?.setValue(value);
	}

	translateOptions() {
		this.optionsCategoryData = ABSTRACT_OPTION_CATEGORIES.data;
		this.optionsCategoryBehavior = ABSTRACT_OPTION_CATEGORIES.behavior;
		this.optionsCategoryBuying = ABSTRACT_OPTION_CATEGORIES.buying;
		this.optionsCategoryDataTables = ABSTRACT_OPTION_CATEGORIES.dataTables;

		this.optionsPage = ABSTRACT_OPTIONS.page;
		this.optionsLink = ABSTRACT_OPTIONS.link;
		this.optionsBehavior = ABSTRACT_OPTIONS.behavior;
		this.optionsUserEvents = ABSTRACT_OPTIONS.event;
		this.optionsOperatorShort = ABSTRACT_OPTIONS.operatorShort;
		this.optionsOperatorFull = ABSTRACT_OPTIONS.operatorFull;
		this.optionsUnitsDefault = ABSTRACT_OPTIONS.unitsDefault;
		this.optionsOperatorEquality = ABSTRACT_OPTIONS.operatorEquality;
		this.optionsOperatorTagsEquality =
			ABSTRACT_OPTIONS.operatorTagsEquality;
		this.optionsOperatorUnsubscriptionLogic =
			ABSTRACT_OPTIONS.operatorUnsubscriptionLogic;
		this.optionsOperatorRfmEquality = ABSTRACT_OPTIONS.operatorRfmEquality;
		this.optionsOperatorLogic = ABSTRACT_OPTIONS.operatorLogic;
		this.optionsOperatorPeriod = ABSTRACT_OPTIONS.operatorPeriod;
	}

	hideCondition(isHide: boolean) {
		this.form.controls.isHide?.patchValue(!isHide);
		if (this.isHide || this.isHideParent) {
			this.form.markAsPristine();
			this.segmentsValidateService.resetInvalidMark$.next();
		}
	}

	protected markForCheck(): void {
		this.cd.markForCheck();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
