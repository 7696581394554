import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EnDropdownModule } from 'ui-lib/dropdown/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnMultiSelectModule, ErrorModule } from 'ui-lib';
import { TuiLetModule, TuiMapperPipeModule } from '@taiga-ui/cdk';
import { SharedModule } from '@shared';
import { TippyModule } from '@ngneat/helipopper';
import { FinishComponent } from './finish.component';
import { FinishService } from './finish.service';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		EnDropdownModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		EnMultiSelectModule,
		TuiLetModule,
		TuiMapperPipeModule,
		TippyModule,
		ErrorModule
	],
	declarations: [FinishComponent],
	exports: [FinishComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: FinishService,
			multi: true
		}
	]
})
export class FinishModule {}
