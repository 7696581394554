import { SelectItem } from 'primeng/api';

export const DATA_TYPE_OPTIONS: SelectItem[] = [
	{
		label: 'scenario_block_data_change_main.field_header',
		value: 'extraField'
	},
	{
		label: 'scenario_block_data_change_main.scenario_field_option',
		value: 'scenarioField'
	},
	{
		label: 'scenario_block_data_change_main.mailing_group_option',
		value: 'mailingGroup',
		disabled: true
	}
];

export const BOOL_TYPE_OPTIONS: SelectItem[] = [
	{
		label: 'boolean.true',
		value: 'true'
	},
	{
		label: 'boolean.false',
		value: 'false'
	}
];
