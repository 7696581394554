import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { IMonths } from '@enSend/message/message-wizard/message-wizard-common/models';

@Injectable()
export class MonthService {
	months: IMonths = {
		january: 'calendar.months_january',
		february: 'calendar.months_february',
		march: 'calendar.months_march',
		april: 'calendar.months_april',
		may: 'calendar.months_may',
		june: 'calendar.months_june',
		july: 'calendar.months_july',
		august: 'calendar.months_august',
		september: 'calendar.months_september',
		october: 'calendar.months_october',
		november: 'calendar.months_november',
		december: 'calendar.months_december'
	};

	monthsOptions: SelectItem[] = [
		{ label: this.months.january, value: 1 },
		{ label: this.months.february, value: 2 },
		{ label: this.months.march, value: 3 },
		{ label: this.months.april, value: 4 },
		{ label: this.months.may, value: 5 },
		{ label: this.months.june, value: 6 },
		{ label: this.months.july, value: 7 },
		{ label: this.months.august, value: 8 },
		{ label: this.months.september, value: 9 },
		{ label: this.months.october, value: 10 },
		{ label: this.months.november, value: 11 },
		{ label: this.months.december, value: 12 }
	];

	monthLabel: IMonths = {
		1: this.months.january,
		2: this.months.february,
		3: this.months.march,
		4: this.months.april,
		5: this.months.may,
		6: this.months.june,
		7: this.months.july,
		8: this.months.august,
		9: this.months.september,
		10: this.months.october,
		11: this.months.november,
		12: this.months.december
	};

	controls: UntypedFormGroup;

	get paramsControl(): UntypedFormGroup {
		return this.controls;
	}

	set paramsControl(form: UntypedFormGroup) {
		this.controls = form;
	}

	get weekdaysControl(): UntypedFormControl {
		return this.paramsControl?.get('weekdays') as UntypedFormControl;
	}

	get monthsControl(): UntypedFormControl {
		return this.paramsControl?.get('months') as UntypedFormControl;
	}

	get filterControl(): UntypedFormControl {
		return this.paramsControl?.get('filter') as UntypedFormControl;
	}

	get everyWeekControl(): UntypedFormControl {
		return this.paramsControl?.get('everyWeek') as UntypedFormControl;
	}

	get dayControl(): UntypedFormControl {
		return this.paramsControl?.get('day') as UntypedFormControl;
	}

	pickFilterControl(value: 'day' | 'weekday' | '') {
		switch (value) {
			case 'day':
				this.enableDay();
				break;
			case 'weekday':
				this.enableWeekday();
				break;
			default:
				this.disableAll();
				break;
		}
	}

	enableDay() {
		this.disableWeekControls();
		this.enableDayControl();
	}

	enableWeekday() {
		this.disableDayControl();
		this.enableWeekControls();
	}

	/** Дизейблим контролы по неделям */
	private disableWeekControls() {
		this.everyWeekControl?.disable();
		this.weekdaysControl?.disable();
		this.weekdaysControl?.disable();
	}

	/** Энейблим контролы по неделям */
	private enableWeekControls() {
		this.everyWeekControl?.enable();
		this.weekdaysControl?.enable();
		this.weekdaysControl?.enable();
	}

	/** Дизейблим контролы по числам */
	private disableDayControl() {
		this.dayControl?.disable({ onlySelf: true });
	}

	/** Энейблим контролы по числам */
	private enableDayControl() {
		this.dayControl?.enable({ onlySelf: true });
	}

	private disableAll(): void {
		this.disableWeekControls();
		this.disableDayControl();
	}
}
