/* eslint-disable no-param-reassign */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl
} from '@angular/forms';

import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';
import { ExtraFieldsService } from '@state-enKod/extra-fields';
import { FieldsService } from '@enSend/scenario/scenario-wizard/components/fields/services/fields.service';

import { MainFormService } from '@enSend/scenario/scenario-wizard/services';
import { BlockScenarioFieldsQuery } from '@enSend/scenario/scenario-wizard/components/fields/_state/scn-fields-blocks.query';

import { AbstractInspector } from '../../abstract';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { BOOL_TYPE_OPTIONS, DATA_TYPE_OPTIONS } from './constants';
import {
	ExtraFieldsOptions,
	SelectItems,
	ExtraFieldForm,
	ExtraFieldItem,
	FieldOption,
	Field,
	ScnFieldForm,
	FormValue,
	DataChangeParam
} from './models';

@UntilDestroy()
@Component({
	selector: 'en-data-change-main',
	templateUrl: './data-change-main.component.html',
	styleUrls: ['./data-change-main.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: {
				dataType: DATA_TYPE_OPTIONS,
				boolOptions: BOOL_TYPE_OPTIONS
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataChangeMainComponent
	extends AbstractInspector
	implements OnInit
{
	readonly form = this.fb.group({
		dataType: '',
		extraFields: this.fb.array([
			this.fb.group({
				extraField: {},
				newValue: ''
			})
		]),
		scenarioFields: this.fb.array([
			this.fb.group({
				name: '',
				newValue: ''
			})
		])
	});

	maskNumber = {
		mask: Number,
		thousandsSeparator: '',
		scale: 0,
		max: '999999999999999',
		min: '-999999999999999',
		radix: '.',
		mapToRadix: [',']
	};

	maskFloat = {
		mask: Number,
		scale: 7,
		signed: true,
		thousandsSeparator: '',
		radix: '.',
		mapToRadix: [','],
		max: 999999999,
		normalizeZeros: false
	};

	extraFieldsOptions: ExtraFieldsOptions[] = [];

	scenarioFieldOptions: FieldOption[] = [];

	constructor(
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(SELECT_OPTIONS_TOKEN)
		private readonly selectOptions: SelectItems,
		private fb: UntypedFormBuilder,
		private extraFieldsService: ExtraFieldsService,
		private cd: ChangeDetectorRef,
		private fieldsService: FieldsService,
		private blockQuery: BlockScenarioFieldsQuery,
		private mainFormService: MainFormService
	) {
		super();
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get disableInput() {
		return this.isCreationMode ? null : true;
	}

	get dataTypeControl(): UntypedFormControl {
		return this.form.get('dataType') as UntypedFormControl;
	}

	get extraFieldsArray(): UntypedFormArray {
		return this.form.get('extraFields') as UntypedFormArray;
	}

	get showFields() {
		return this.dataTypeControl.value === 'extraField';
	}

	get extraFieldsArrayControls() {
		return this.extraFieldsArray.controls;
	}

	get extraFieldsArrayLength() {
		return this.extraFieldsArrayControls.length;
	}

	get maxFieldsLength() {
		return this.extraFieldsArrayLength >= 5;
	}

	get showScenarioFields() {
		return this.dataTypeControl.value === 'scenarioField';
	}

	get scnFieldControlArray() {
		return this.form.controls.scenarioFields as UntypedFormArray;
	}

	get scnFieldControls() {
		return this.scnFieldControlArray.controls;
	}

	get dataTypeOptions() {
		return this.selectOptions.dataType;
	}

	get boolOptions() {
		return this.selectOptions.boolOptions;
	}

	get scenarioId() {
		return this.mainFormService.form.get('id')?.value ?? 0;
	}

	ngOnInit() {
		this.initListeners();

		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;

						const scenarioId = this.isCreationMode
							? this.scenarioId
							: context.scenarioId;

						this.initScenarioFields(scenarioId);
					}
				})
			)
			.subscribe();
	}

	private patchFormOnInit(options: any, subType: string) {
		const { dataType } = options;
		const { params } = options[subType];

		this.dataTypeControl.patchValue(dataType);

		if (!params.length) return;

		this.otd(params, dataType);
	}

	/** Конвертируем из данных в блоке в данные для fieldsArray, патчим каждый айтем в fieldsArray */
	private otd(params: DataChangeParam[], dataType: string) {
		if (dataType === 'extraField') {
			this.extraFieldsArray.clear();
			params[0].extraFields.forEach((item: ExtraFieldItem) => {
				const fieldGroup = this.fb.group({
					extraField: {
						id: item.id,
						label: item.label,
						type: item.type,
						serviceName: item.serviceName
					},
					newValue: item.newValue
				});
				this.extraFieldsArray.push(fieldGroup);
			});
		}

		if (dataType === 'scenarioField') {
			this.scnFieldControlArray.clear();
			params[0].scenarioFields.forEach((item: ScnFieldForm) => {
				const fieldGroup = this.fb.group({
					name: item.name,
					newValue: item.newValue
				});
				this.scnFieldControlArray.push(fieldGroup);
			});
		}
	}

	private initListeners() {
		this.getFieldsList();
		this.formListener();
	}

	private initScenarioFields(scenarioId: number) {
		const options = this.cell.get('options');
		const subType = this.cell.get('subType');

		this.blockQuery.fields$
			.pipe(
				untilDestroyed(this),
				map(fields =>
					fields
						.filter(field => field.scenarioId === scenarioId)
						.map((field: Field) => ({
							id: field.guid,
							label: field.name,
							value: field.name,
							type: field.type,
							disabled: false
						}))
				),
				tap((fieldOptions: FieldOption[]) => {
					this.scenarioFieldOptions = fieldOptions;
				})
			)
			.subscribe();

		this.fieldsService
			.getFields(scenarioId)
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.patchFormOnInit(options, subType);
				})
			)
			.subscribe();
	}

	private formListener() {
		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					this.filterFields(value);

					this.changeCellProp('options', {
						dataType: value.dataType,
						dataChangeMain: {
							params: this.dto(value)
						},
						isValid: this.checkValidity(value)
					});
				})
			)
			.subscribe();
	}

	private getFieldsList() {
		this.extraFieldsService
			.getAllList()
			.pipe(
				untilDestroyed(this),
				map(resp => {
					return resp.map(extrafield => ({
						label: extrafield.name,
						value: {
							id: extrafield.id,
							label: extrafield.name,
							type: extrafield.dataType,
							serviceName: extrafield.serviceName
						},
						disabled: false
					}));
				}),
				tap(resp => {
					this.extraFieldsOptions = resp;
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	/** Фильтруем допполя, чтобы уже выбранные дизейблились в выпадашке */
	private filterFields(value: FormValue) {
		if (value.dataType === 'extraField') {
			const selectedIds: ID[] = [];
			value.extraFields.forEach(extrafield => {
				selectedIds.push(extrafield.extraField.id);
			});

			this.extraFieldsOptions.forEach(extrafield => {
				extrafield.disabled = selectedIds.includes(extrafield.value.id);
			});
		}

		if (value.dataType === 'scenarioField') {
			const selectedScnFields = value.scenarioFields.map(
				field => field.name
			);
			this.scenarioFieldOptions.forEach(field => {
				field.disabled = selectedScnFields.includes(field.value);
			});
		}
	}

	/** Конвертируем value формы для обновления cell и красивой структуры */
	private dto(value: FormValue) {
		const type = value.dataType;

		/** Склеиваем все лейблы в один большой, для отображения на канве в блоке */
		const createLabel = () => {
			const labels: string[] = [];

			if (type === 'extraField') {
				value.extraFields.forEach((item: ExtraFieldForm) => {
					if (item.extraField.label)
						labels.push(item.extraField.label);
				});
			}

			if (type === 'scenarioField') {
				value.scenarioFields.forEach((item: ScnFieldForm) => {
					if (item.name.length) labels.push(item.name);
				});
			}

			const label = labels.join(', ');

			return label;
		};

		if (!this.isCreationMode) {
			const options = this.cell.get('options');
			const subType = this.cell.get('subType');
			const { port } = options[subType].params[0];

			if (type === 'extraField') {
				return [
					{
						extraFields: value.extraFields.map(
							(item: ExtraFieldForm) => {
								return {
									...item.extraField,
									newValue: item.newValue?.toString() || ''
								};
							}
						),
						label: createLabel(),
						port
					}
				];
			}

			if (type === 'scenarioField') {
				return [
					{
						scenarioFields: value.scenarioFields.map(
							(item: ScnFieldForm) => {
								return {
									name: item.name,
									newValue: item.newValue?.toString() || ''
								};
							}
						),
						label: createLabel(),
						port
					}
				];
			}
		}

		// если creationMode
		if (type === 'extraField') {
			return [
				{
					extraFields: value.extraFields.map(
						(item: ExtraFieldForm) => {
							return {
								...item.extraField,
								newValue: item.newValue?.toString() || ''
							};
						}
					),
					label: createLabel()
				}
			];
		}

		if (type === 'scenarioField') {
			return [
				{
					scenarioFields: value.scenarioFields.map(
						(item: ScnFieldForm) => {
							return {
								name: item.name,
								newValue: item.newValue?.toString() || ''
							};
						}
					),
					label: createLabel()
				}
			];
		}

		return [];
	}

	private checkValidity(value: FormValue) {
		if (value.dataType === 'extraField') {
			const emptyField = value.extraFields.find(
				(field: ExtraFieldForm) => !field.extraField.id
			);

			return !emptyField;
		}
		if (value.dataType === 'scenarioField') {
			const invalidField = value.scenarioFields.find(
				(field: ScnFieldForm) => !field.name
			);
			return !invalidField;
		}
		return false;
	}

	resetValue(i: number) {
		this.scnFieldControls[i].get('newValue')?.reset();
	}

	getParamType(fieldValue: string) {
		return this.scenarioFieldOptions.find(
			option => option.value === fieldValue
		)?.type;
	}

	addField() {
		const group = this.fb.group({
			extraField: {},
			newValue: ''
		});
		this.extraFieldsArray.push(group);
	}

	addScnField() {
		this.scnFieldControlArray.push(
			this.fb.group({
				name: '',
				newValue: ''
			})
		);
	}

	removeField(idx: number) {
		this.extraFieldsArray.removeAt(idx);
	}

	removeScnField(idx: number) {
		this.scnFieldControlArray.removeAt(idx);
	}
}
