import { Provider } from '@angular/core';
import { MessagesTab, MESSAGES_TAB_TOKEN } from '@enSend/message/kit';
import { TABLE_COLUMNS_MESSAGE, TABLE_COLUMNS_SCENARIO } from '../constants';

export const SCENARIO_MESSAGES_TAB: MessagesTab = {
	location: 'scenario',
	columns: TABLE_COLUMNS_SCENARIO,
	hideFilters: ['sendingTime', 'sendingType'],
	filter: {
		noDraft: [true],
		sendingType: 'scenario'
	}
};

export const MESSAGES_TAB: MessagesTab = {
	location: 'message',
	columns: TABLE_COLUMNS_MESSAGE,
	hideFilters: ['sendingTime'],
	filter: {
		noDraft: [true]
	}
};

export const MESSAGE_LIST_TABS_PROVIDER_SCENARIO_WIZARD: Provider[] = [
	{
		provide: MESSAGES_TAB_TOKEN,
		useValue: MESSAGES_TAB,
		multi: true
	},
	{
		provide: MESSAGES_TAB_TOKEN,
		useValue: SCENARIO_MESSAGES_TAB,
		multi: true
	}
];
