import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EnButtonModule, EnMessagesModule, FileUploadModule } from 'ui-lib';
import { ConvertSizePipeModule } from '@enkod-core/pipes';
import { AttachmentsControlComponent } from './attachments-control.component';

@NgModule({
	declarations: [AttachmentsControlComponent],
	imports: [
		CommonModule,
		TranslateModule,
		EnButtonModule,
		FileUploadModule,
		ConvertSizePipeModule,
		EnMessagesModule
	],
	exports: [AttachmentsControlComponent]
})
export class AttachmentsControlModule {}
