<en-dialog [(visible)]="limitVisible"
           [header]="'segments_dialogs.limits_header' | translate"
           styleClass="dialog-medium-width"
           [modal]="true"
           (onHide)="closeLimit.emit()">

    <div translate>segments_dialogs.limits_description</div>

    <span class="en-input-reset">
        <input class="input-limit"
               enInput
               type="number"
               min="0"
               pKeyFilter="pint"
               [(ngModel)]="limitValue">
        <button [class.en-input-reset-icon--show]="limitValue || limitValue === null"
                icon="en en-cancel-fill"
                (click)="clearLimit()"
                class="en-input-reset-icon en en-cancel-fill"></button>
    </span>

    <p-footer>
        <button type="button"
                enButton
                class="h36 en-button-secondary"
                [style]="{'margin-right': '1rem'}"
                [label]="'segments_dialogs.cancel' | translate"
                (click)="closeLimit.emit()">
        </button>
        <button type="button"
                enButton
                class="h36"
                [label]="'segments_dialogs.accept' | translate"
                (click)="onSubmit.emit(limitValue)"></button>
    </p-footer>
</en-dialog>
