/* eslint-disable max-classes-per-file */
import {
	OnChanges,
	Directive,
	ElementRef,
	Input,
	NgModule
} from '@angular/core';

import { FULL_URL_REGEX } from 'custom-validators';

@Directive({
	selector: '[enWrapLink]'
})
export class WrapLinkDirective implements OnChanges {
	@Input('enWrapLink') mainValue: string;
	@Input() regExp = FULL_URL_REGEX;
	@Input() styleClass: string;

	constructor(private el: ElementRef) {}

	ngOnChanges() {
		if (!this.mainValue) return;

		// заменяем перенос строки на пробел с тэгом
		const valueWithBr = this.mainValue.replace(/\n/g, ' <br/>');
		const linksIndexes: number[] = [];
		const splited = valueWithBr.split(' ');

		// ищем и записываем индексы ссылок
		splited.forEach((element: string, index: number) => {
			if (element.search(this.regExp) !== -1) {
				linksIndexes.push(index);
			}
		});

		const result: string[] = [];
		let lastIndex = -1;

		// отделяем ссылки от строк, оборачиваем их и записываем всё в result
		linksIndexes.forEach(index => {
			result.push(splited.slice(lastIndex + 1, index).join(' '));
			result.push(
				`<span class="${this.styleClass}">
                    ${valueWithBr.split(' ')[index]}</span>`
			);
			lastIndex = index;
		});
		result.push(splited.slice(lastIndex + 1).join(' '));

		this.el.nativeElement.innerHTML = `${result.join(' ')}`;
	}
}

@NgModule({
	declarations: [WrapLinkDirective],
	exports: [WrapLinkDirective]
})
export class WrapLinkModule {}
