import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { NotificationStatus } from 'ui-lib';
import { NotificationsService, UrlSaverService } from '@enkod-core/services';
import { ExtraField, IDeleteData } from './extra-field.model';

import { ExtraFieldsDataService } from './extra-fields-data.service';
import { ExtraFieldsStore } from './extra-fields.store';
import { ExtraFieldsQuery } from './extra-fields.query';

@Injectable({ providedIn: 'root' })
export class ExtraFieldsService {
	constructor(
		protected store: ExtraFieldsStore,
		private dataService: ExtraFieldsDataService,
		private query: ExtraFieldsQuery,
		private notificationsService: NotificationsService,
		private urlService: UrlSaverService
	) {}

	getAllList(showListFields?: boolean): Observable<ExtraField[]> {
		// todo: epic EK-4838 удалить параметр метода, когда эпик будет доделан

		if (this.query.hasAllEntities()) return this.query.allFields$;
		return this.requestAllList(showListFields);
	}

	private requestAllList(showListFields?: boolean) {
		// todo: epic EK-4838 удалить параметр метода, когда эпик будет доделан

		return this.dataService.list({ sortKey: 'id', sortOrder: 'desc' }).pipe(
			map(resp => {
				if (showListFields) return resp;

				return {
					...resp,
					result: resp.result?.filter(
						field => field.dataType !== 'list'
					)
				};
			}),
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			switchMap(resp => {
				this.store.update(() => ({
					[ALL_LIST_STORE_NAME]: resp.result
				}));
				return this.query.allFields$;
			})
		);
	}

	getList({ page, sort, perPage, search }: any) {
		return this.dataService
			.list({
				limit: perPage,
				offset: (page - 1) * perPage,
				sort,
				search
			})
			.pipe(
				tap(() => {
					this.urlService.setParamsToUrl({
						sortKey: sort?.field,
						sortOrder: sort?.order,
						perPage,
						search
					});
				}),
				switchMap(resp => {
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: Math.ceil(resp.total / perPage) || 1,
						data: resp.result ? [...resp.result] : []
					});
				}),
				catchError(() => {
					this.notificationsService
						.show('toast.extra_field_toast_request_error', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return of({
						perPage: 10,
						lastPage: 0,
						currentPage: 1,
						total: 0,
						data: []
					});
				})
			);
	}

	create(data: ExtraField): Observable<ExtraField | null> {
		this.store.setLoading(true);
		return this.dataService.create(data).pipe(
			tap(resp => {
				this.store.add(resp);
				this.clearAllListCache();
			}),
			catchError(() => {
				this.notificationsService
					.show('toast.extra_field_toast_create_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				this.store.setLoading(false);
				return of(null);
			})
		);
	}

	update(data: ExtraField): Observable<ExtraField | null> {
		this.store.setLoading(true);

		return this.dataService.update(data).pipe(
			tap(resp => {
				this.store.update(resp.id, resp);
				this.clearAllListCache();
			}),
			catchError(() => {
				this.notificationsService
					.show('toast.extra_field_toast_edit_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				this.store.setLoading(false);
				return of(null);
			})
		);
	}

	delete(id: number): Observable<void> {
		this.store.setLoading(true);
		return this.dataService.remove(id).pipe(
			tap(() => {
				this.store.remove(id);
				this.clearAllListCache();
			}),
			catchError(e => {
				this.notificationsService
					.show('toast.extra_field_toast_delete_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				this.store.setLoading(false);
				return throwError(e);
			})
		);
	}

	checkSystemName(systemName: string): Observable<boolean> {
		return this.dataService
			.checkSystemName(systemName)
			.pipe(map(value => value.uniqueSystemName));
	}

	checkDeleted(id: number): Observable<IDeleteData[]> {
		return this.dataService.checkDeletedData(id).pipe(
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			})
		);
	}

	clearAllListCache(): void {
		this.store.update(() => ({
			[ALL_LIST_STORE_NAME]: null
		}));
	}
}
