import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input
} from '@angular/core';

import { UserStatus } from '@state-admin/users';

@Component({
	selector: 'en-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent {
	@Input()
	@HostBinding('attr.data-en-host-status')
	status: UserStatus = 'active';
}
