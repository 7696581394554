import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Observable } from 'rxjs';

import {
	BlockScenarioFieldsState,
	BlockScenarioFieldsStore
} from './scn-fields-blocks.store';
import { FieldsModel } from '../model/fields';

@Injectable({ providedIn: 'root' })
export class BlockScenarioFieldsQuery extends QueryEntity<BlockScenarioFieldsState> {
	constructor(protected store: BlockScenarioFieldsStore) {
		super(store);
	}

	readonly fields$ = this.selectAll() as Observable<FieldsModel[]>;
}
