import { Block, PortItem } from '@enSend/_shared/models';
import { Scenario } from '@enSend/scenario/_state';

const fixWrongLinks = (scenario: Scenario, ports: PortItem[]): string => {
	return ports.filter(({ id, group }) => {
		return (
			group === 'out' &&
			!scenario.links.some(link => link.source.port === id)
		);
	})[0].id as string;
};

export function isValidScenario(scenario: Scenario): Scenario {
	const dictionary = new Map();

	scenario.blocks.forEach((block: Block) => {
		dictionary.set(block.id, block.ports.items);
	});

	scenario.links.forEach(link => {
		const sourceId = link.source.port;

		const sourceBlock = link.source.id;
		const ports: PortItem[] = dictionary.get(sourceBlock);

		if (!ports.some(({ id }) => id === sourceId)) {
			// eslint-disable-next-line no-param-reassign
			link.source.port = fixWrongLinks(scenario, ports);
		}
	});

	return scenario;
}
