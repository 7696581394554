import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { KeyupEventKeys, TagsValue } from '@enkod-core/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnsendSharedQuery, EnsendSharedService } from '@state-enSend/_shared';

@UntilDestroy()
@Component({
	selector: 'en-tags-control',
	templateUrl: './tags-control.component.html',
	styleUrls: ['./tags-control.component.scss'],
	providers: [EnsendSharedService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsControlComponent implements OnInit {
	onInitTags: string[];

	filteredTagsMultiple: string[];

	constructor(
		private ensendSharedService: EnsendSharedService,
		private ensendSharedQuery: EnsendSharedQuery
	) {}

	@Input() readonly formTags: UntypedFormControl;

	@Input() readonly showClear: boolean;

	@Input() readonly = false;

	@Input() placeholder: string;

	@Input() set loadTags(value: boolean) {
		if (value) this.requestTags();
	}

	ngOnInit() {
		const selectedTags =
			this.formTags.value?.filter(
				(value: string) =>
					value !== TagsValue.SPLIT_TEST && value !== TagsValue.BOT
			) || [];
		this.formTags.patchValue([...selectedTags]);
		this.ensendSharedQuery.tagList$
			.pipe(untilDestroyed(this))
			.subscribe((tags: string[] | null) => {
				if (tags) this.onInitTags = tags;
			});
	}

	// autocomplete component
	filterTagMultiple(event: any) {
		const { query } = event;
		this.filteredTagsMultiple = this.filterTag(query, this.onInitTags);
	}

	addTag(event: KeyboardEvent): void {
		if (event?.key === KeyupEventKeys.enter) {
			const { value } = event?.target as HTMLInputElement;
			const selectedTags = [...this.formTags.value] || [];
			const valueArr: string[] = value.split(',');

			valueArr.forEach((item: string) => {
				const tag: string = item
					.toString()
					.replace(/[- "'/\\]/g, '')
					.replace(/_sys_/g, '');
				if (tag && selectedTags.indexOf(tag) === -1) {
					selectedTags.push(tag);
				}
			});
			this.formTags.patchValue(selectedTags);
			// eslint-disable-next-line no-param-reassign
			(<HTMLInputElement>event.target).value = '';
			event.preventDefault();
		}
	}

	private requestTags(): void {
		this.ensendSharedService.getTags();
	}

	private filterTag(query: any, tags: string[] | null): string[] {
		const filtered: string[] = [];
		if (tags) {
			for (let i = 0; i < tags.length; i++) {
				const country = tags[i];
				if (country.toLowerCase().indexOf(query.toLowerCase()) === 0) {
					if (
						tags[i] !== TagsValue.SPLIT_TEST &&
						tags[i] !== TagsValue.BOT
					)
						filtered.push(country);
				}
			}
		}
		return filtered;
	}

	clearTags() {
		this.formTags.reset([]);
	}
}
