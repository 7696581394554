import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import {
	ActionTimeForm,
	CartConditionForm
} from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-cart-condition',
	templateUrl: './cart-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartConditionComponent extends AbstractCondition {
	readonly optionsParameter: SelectItem[] = [
		{
			label: 'segment_form.cond_cart_quantity',
			value: 'quantity'
		},
		{
			label: 'segment_options.with_id',
			value: 'id'
		},
		{
			label: 'segment_form.cond_cart_stock',
			value: 'atLeastOne'
		}
	];

	periodValueSubscription: Subscription | undefined = undefined;

	get typedForm(): FormGroup<CartConditionForm> {
		return this.form as FormGroup<CartConditionForm>;
	}

	get actionTime(): FormGroup<ActionTimeForm> {
		return this.typedForm.controls.actionTime as FormGroup<ActionTimeForm>;
	}

	get parameterValue(): string | null {
		return this.typedForm.controls.parameter.value;
	}

	get valueControl(): FormControl<number | null> {
		return this.typedForm.controls.value as FormControl<number | null>;
	}

	get operatorControl(): FormControl<string> {
		return this.typedForm.controls.operator as FormControl<string>;
	}

	setValidation() {
		this.setValidatorsRequired('value');
		this.getSubscription('parameter')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				switch (value) {
					case 'quantity':
						this.createOperatorControl();
						this.createValueControl();
						this.setValue('value', this.typedForm.value.value || 3);
						break;
					case 'id':
						this.createValueControl();
						this.typedForm.controls.value.updateValueAndValidity();
						break;
					case 'atLeastOne':
						this.valueControl?.setValue(1);
						this.operatorControl?.setValue('moreOrEqual');
						break;
					default:
						break;
				}
				this.markForCheck();
			});
	}

	private setValidatorsRequired(сontrol: string) {
		this.form.get(сontrol)?.setValidators([Validators.required]);
	}

	private createValueControl(): void {
		if (this.valueControl) return;
		this.typedForm.setControl('value', new FormControl(null));
	}

	private createOperatorControl(): void {
		if (this.operatorControl?.value) return;
		this.typedForm.setControl('operator', new FormControl('more'));
	}
}
