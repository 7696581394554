import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import {
	MessageTypeService,
	WizardService
} from '@enSend/message/message-wizard/kit';

import {
	StepConditionService,
	StepEditorService,
	StepReceiversService,
	StepSplitTestService
} from './services';
import { MessageModel } from './models';

@Injectable()
export class MessageWizardService implements WizardService<MessageModel> {
	readonly loading$ = new BehaviorSubject<boolean>(false);
	private _isNew: boolean;

	get isNew() {
		return this._isNew;
	}

	set isNew(isNew: boolean) {
		this._isNew = isNew;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private messageTypeService: MessageTypeService,
		private stepConditionService: StepConditionService,
		private stepEditorService: StepEditorService,
		private splitTestService: StepSplitTestService,
		private stepReceiversService: StepReceiversService
	) {
		this.stepConditionService.loading$ = this.loading$;
	}

	form: UntypedFormGroup;

	inited = false;

	initForm() {
		if (!this.inited) {
			this.inited = true;

			this.messageTypeService.init();
			this.stepConditionService.init();
			this.stepEditorService.init();
			this.splitTestService.init();
			this.stepReceiversService.init();

			this.form = this.fb.group({
				id: 0,
				title: '',
				type: this.messageTypeService.messageTypeControl,
				status: '',
				step_1: this.stepConditionService.stepCondition,
				step_2: this.stepEditorService.stepEditor,
				step_3: this.splitTestService.splitForm,
				step_4: this.stepReceiversService.stepReceivers
			});
		}
	}

	initMessage(message: Partial<MessageModel>) {
		this.stepEditorService.initMessage(message);
	}

	resetMessageFormGroup() {
		this.inited = false;
		this.splitTestService.resetSplitIndex();
	}
}
