import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { MatMenuModule } from '@angular/material/menu';
import { EnButtonModule } from 'ui-lib';

import { SplitHandlerComponent } from './split-handler.component';

@NgModule({
	imports: [CommonModule, TranslateModule, EnButtonModule, MatMenuModule],
	declarations: [SplitHandlerComponent],
	exports: [SplitHandlerComponent]
})
export class SplitHandlerModule {}
