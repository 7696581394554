import { AbstractControl, Validators } from '@angular/forms';
import { ParamsMapModel } from '../../message-conditions';

export function sqlParamsValidate(control: AbstractControl): null | Validators {
	const { value } = control;
	if (value !== null && Object.keys(value).length && checkEmptyValue(value))
		return {
			someEmptyParam: true
		};

	return null;
}

function checkEmptyValue(value: ParamsMapModel): boolean {
	return Object.keys(value).some(key => {
		if (
			value[key] &&
			value[key].type === 'bool' &&
			value[key].value === false
		)
			return false;
		return !value[key].value;
	});
}
