import { dia, shapes } from '@clientio/rappid';
import { ObjectHash } from 'backbone';
import { CanvaMode } from '@enSend/_shared/models';

export enum ShapeTypesEnum {
	BLOCK = 'stencil.Block'
}

const SHAPE_SIZE = 32;

export function initStencilShapes(canvaMode: CanvaMode) {
	const BorderRadius = canvaMode === 'scenario' ? 5 : 2;

	const Block = dia.Element.define(
		ShapeTypesEnum.BLOCK,
		{
			name: 'Block',
			size: { width: SHAPE_SIZE, height: SHAPE_SIZE },
			attrs: {
				rect: {
					width: SHAPE_SIZE,
					height: SHAPE_SIZE,
					rx: BorderRadius,
					x: -10,
					y: 8,
					strokeWidth: 1
				},
				icon: {
					ref: 'rect',
					refX: '50%',
					refY: '50%',
					x: -20 / 2,
					y: -20 / 2
				},
				label: {
					text: 'Blockname',
					refDx: 12,
					refY: '50%',
					y: 8,
					x: -10,
					textAnchor: 'start',
					textVerticalAnchor: 'middle',
					fill: '#242424',
					fontFamily: '"Inter", sans-serif',
					fontSize: 14
				}
			}
		} as ObjectHash,
		{
			markup: [
				{
					tagName: 'rect',
					selector: 'rect'
				},
				{
					tagName: 'use',
					selector: 'icon'
				},
				{
					tagName: 'text',
					selector: 'label'
				}
			]
		}
	);

	Object.assign(shapes, {
		stencil: {
			Block
		}
	});
}
