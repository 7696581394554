import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	Inject,
	Input
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'en-symbol-counter',
	templateUrl: './symbol-counter.component.html',
	styleUrls: ['./symbol-counter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SymbolCounterComponent implements OnInit {
	textLengthString$ = new BehaviorSubject('');

	@Input() postfix = 'symbol_counter.postfix';
	@Input() limit: number;

	constructor(
		@Inject(NgControl)
		private readonly ngControl: NgControl | null,
		private translate: TranslateService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		this.setString(this.ngControl?.value.length ?? 0);
		this.ngControl?.valueChanges
			?.pipe(
				untilDestroyed(this),
				tap(value => this.setString(value.length))
			)
			.subscribe();
	}

	registerOnChange(): void {}

	registerOnTouched(): void {}

	writeValue(): void {}

	private setString(current: number): void {
		this.textLengthString$.next(
			`${current}/${this.limit} ${this.translate.instant(this.postfix)}`
		);
	}
}
