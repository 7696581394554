<div class="addition uniq-messages" [class.highlight]="isHover">
	<span class="center-span-text first-block">
    {{ 'segment_form.only_uniq_messages' | translate }}
  </span>

	<i
		class="button-remove-condition cancel-icon en en-cancel-fill"
		(mouseenter)="isHover = true"
		(mouseleave)="isHover = false"
		(click)="remove.emit()"
	></i>
</div>
