import {
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Input,
	OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { debounceTime, tap } from 'rxjs/operators';
import { CommonTypeOptions } from '@enkod-core/types';
import { ISqlParams, SqlTemplate, SqlTemplatesService } from '@state-enKod/sql';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { SqlConditionForm } from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';

export interface ParamsMapModel {
	[key: string]: {
		value: any;
		type: CommonTypeOptions;
	};
}
@UntilDestroy()
@Component({
	selector: 'en-sql-request',
	templateUrl: './sql-request.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SqlRequestComponent extends AbstractCondition implements OnInit {
	readonly actionOptions: SelectItem[] = [
		{ label: 'segment_options.match_sql', value: 'match' },
		{ label: 'segment_options.not_match_sql', value: 'not_match' }
	];

	sqlTemplatesOptions: SelectItem<number>[];
	allTemplates: SqlTemplate[];
	sqlPreviewVisible = false;
	selectedTemplate: SqlTemplate;

	@Input()
	readonly submitted: boolean;

	get typedForm(): FormGroup<SqlConditionForm> {
		return this.form as FormGroup<SqlConditionForm>;
	}

	get formParams(): FormControl<object> {
		return this.typedForm.controls.params as FormControl<object>;
	}

	get hasSqlTemplate() {
		return this.typedForm.value.id;
	}

	constructor(
		public segmentsValidateService: SegmentsValidateService,
		public cd: ChangeDetectorRef,
		private sqlService: SqlTemplatesService
	) {
		super(segmentsValidateService, cd);
		this.fetchData();
	}

	ngOnInit(): void {
		this.segmentsValidateService.checkedValidate
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => this.updateValidation())
			)
			.subscribe();

		this.segmentsValidateService.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => this.updateValidation())
			)
			.subscribe();
	}

	private fetchData(): void {
		this.sqlService
			.getListResult({
				type: 'segment'
			})
			.pipe(
				untilDestroyed(this),
				tap(resp => {
					this.allTemplates = resp;
					this.sqlTemplatesOptions = resp.map((item: any) => ({
						label: item.name,
						value: item.id
					}));
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	onTemplateChange(id: number): void {
		const params: ISqlParams[] =
			this.allTemplates.find(item => item.id === id)?.params || [];
		this.formParams.patchValue(this.toMap(params));
		this.cd.markForCheck();
	}

	private toMap(params: ISqlParams[]): ParamsMapModel {
		const map: ParamsMapModel = {};
		params.forEach(item => {
			if (item.type === 'bool') {
				map[item.name] = {
					value: true,
					type: item.type
				};
				return;
			}

			map[item.name] = {
				value: null,
				type: item.type
			};
		});
		return map;
	}

	private updateValidation(): void {
		if (this.isHide || this.isHideParent) this.form.markAsPristine();
		this.cd.markForCheck();
	}
}
