import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectItemDesctiption } from '@enkod-core/interfaces';

@Component({
	selector: 'en-email-extrafield',
	templateUrl: './email-extrafield.component.html',
	styleUrls: ['./email-extrafield.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailExtrafieldComponent {
	@Input() options: SelectItemDesctiption[];
	@Input() control: UntypedFormControl;
	@Input() isCreationMode: boolean;

	@Output() onChangeTypeChange = new EventEmitter();
}
