import { SelectItem } from 'primeng/api';

export const BirthdayDateOptions: SelectItem[] = [
	{
		label: 'segment_options.options_today',
		value: 'today'
	},
	{
		label: 'segment_options.options_happen_equal',
		value: 'happen_equal'
	},
	{
		label: 'segment_options.options_happened_equal',
		value: 'happened_equal'
	},
	{
		label: 'segment_options.options_in_month',
		value: 'in_month'
	},
	{
		label: 'segment_options.options_between_days',
		value: 'between_days'
	}
];

export const MonthOperatorOptions: SelectItem[] = [
	{
		label: 'segment_options.options_this_month',
		value: 'this_month'
	},
	{
		label: 'segment_options.options_in_one_of_month',
		value: 'in_one_of_month'
	}
];

export const UnitOptions: SelectItem[] = [
	{
		label: 'common.day',
		value: 'day'
	},
	{
		label: 'common.month',
		value: 'month'
	}
];

export const MonthOptions: SelectItem[] = [
	{ label: 'calendar.months_january', value: 1 },
	{ label: 'calendar.months_february', value: 2 },
	{ label: 'calendar.months_march', value: 3 },
	{ label: 'calendar.months_april', value: 4 },
	{ label: 'calendar.months_may', value: 5 },
	{ label: 'calendar.months_june', value: 6 },
	{ label: 'calendar.months_july', value: 7 },
	{ label: 'calendar.months_august', value: 8 },
	{ label: 'calendar.months_september', value: 9 },
	{ label: 'calendar.months_october', value: 10 },
	{ label: 'calendar.months_november', value: 11 },
	{ label: 'calendar.months_december', value: 12 }
];

export const MonthLabel = {
	1: 'calendar.months_january',
	2: 'calendar.months_february',
	3: 'calendar.months_march',
	4: 'calendar.months_april',
	5: 'calendar.months_may',
	6: 'calendar.months_june',
	7: 'calendar.months_july',
	8: 'calendar.months_august',
	9: 'calendar.months_september',
	10: 'calendar.months_october',
	11: 'calendar.months_november',
	12: 'calendar.months_december'
};
