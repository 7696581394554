<ng-container [ngSwitch]="view">
    <div class="wrapper">
        <ng-template ngSwitchCase="desktop">
            <div class="web">
                <div class="web__browser">
                    <div class="web__browser-top"></div>
                    <div class="web__browser-bottom">
                        <div class="web__browser-url"></div>
                    </div>
                    <img [attr.alt]="'message_wizard_edit_step.push_wrong_link' | translate"
                         src="assets/images/message-editor/browser-tools.svg"
                         class="web__browser-img" />
                </div>

                <div class="preview-web">
                    <div style="background-image: url({{preview.icon}});"
                         class="preview-web__image"></div>
                    <div>
                        <div class="preview__body">
                            <span class="preview__subject preview__subject_slice">
                                {{ preview.subject || ('message_wizard_edit_step.push_preview_subject' | translate) }}
                            </span>

                            <span class="preview__text preview__text_slice">
                                {{ preview.body || ('message_wizard_edit_step.push_preview_body' | translate) }}
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </ng-template>

        <ng-template ngSwitchCase="browser">browser</ng-template>

        <ng-template ngSwitchCase="android">
            android
        </ng-template>

        <ng-template ngSwitchCase="ios">
            <div class="phone-container"
                 [ngClass]="{'border-none':bordersNone}">
                <img src="assets/images/message-editor/phone_SE.svg"
                     alt="" />
                <div class="preview">
                    <div class="preview__head">
                        <div style="background-image: url({{preview.icon}});"
                             class="preview__image"></div>
                        <span class="preview__sender">{{'message_wizard_edit_step.push_preview_subject' |
                            translate}}</span>
                    </div>
                    <div class="preview__body">
                        <span class="preview__subject preview__subject_slice">
                            {{ preview.subject || ('message_wizard_edit_step.push_preview_subject' | translate) }}
                        </span>

                        <span class="preview__text preview__text_slice">
                            {{ preview.body || ('message_wizard_edit_step.push_preview_body' | translate) }}
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>
