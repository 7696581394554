import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '@shared';
import {
	EnTextAreaModule,
	EnSymbolCounterModule,
	ErrorModule,
	EnButtonModule,
	EnInputModule
} from 'ui-lib';
import { MessageComponent } from './message.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { MessageService } from './message.service';
import { ButtonsContainerComponent } from './buttons-container/buttons-container.component';
import { TextButtonComponent } from './buttons-container/text-button/text-button.component';
import { BackButtonComponent } from './buttons-container/back-button/back-button.component';
import { LinkButtonComponent } from './buttons-container/link-button/link-button.component';

@NgModule({
	declarations: [
		MessageComponent,
		ButtonsContainerComponent,
		TextButtonComponent,
		BackButtonComponent,
		LinkButtonComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnTextAreaModule,
		EnSymbolCounterModule,
		ErrorModule,
		EnButtonModule,
		EnInputModule,
		MatMenuModule
	],
	exports: [MessageComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: MessageService,
			multi: true
		}
	]
})
export class MessageModule {}
