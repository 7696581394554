import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { EnDialogModule } from 'ui-lib';

import { SegmentsTreeModule } from 'app/modules/enKod/segments/components';
import { SegmentsTreeDialogComponent } from './segments-tree-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SegmentsTreeModule,
		EnDialogModule
	],
	declarations: [SegmentsTreeDialogComponent],
	exports: [SegmentsTreeDialogComponent]
})
export class SegmentsTreeDialogModule {}
