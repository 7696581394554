import { Injectable } from '@angular/core';
import { Store, StoreConfig, ID } from '@datorama/akita';
import { AccountModel } from '@state-admin/accounts/accounts-list';
import { Access, UserModel } from '../models';

export interface AuthState {
	accessToken: string;
	refreshToken: string;
	expire: number | null;
	currentAccountId: ID | null;
	user: UserModel | null;
	access: Access[];
	accounts: AccountModel[];
	loading?: boolean;
	limitSettings: {
		baseLimit: number;
	};
	timeZone: string;
	enableCustomId: boolean;
}

export function createInitialState(): AuthState {
	return {
		accessToken: '',
		refreshToken: '',
		expire: null,
		currentAccountId: null,
		user: null,
		access: [],
		accounts: [],
		loading: false,
		limitSettings: {
			baseLimit: 0
		},
		timeZone: '+0300',
		enableCustomId: false
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
	constructor() {
		super(createInitialState());
	}
}
