import { AbstractControl, Validators } from '@angular/forms';
import { DateAccountTimezoneService } from '@enkod-core/services';

import { TuiValidationError } from 'app/ui-lib/error/error.component';
import { CustomValidators, FULL_URL_REGEX } from 'custom-validators';

import { SHORT_LINK_FIX_LENGTH } from '../step-content/2-step-editor/sms-editor/constants';

export function sendlateValidator(field: AbstractControl): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.sendlate'
				)
		  };
}

export function regularRangeValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_range'
				)
		  };
}

export function regularEveryValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_every'
				)
		  };
}

export function regularStartTimeValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_start_time'
				)
		  };
}

// export const regularTimeTodaySendValidator =
// 	(service: DateAccountTimezoneService) => (control: AbstractControl) => {
// 		const currentDate = new Date(
// 			service.fromUtcWithAccountTz(control.parent?.value.startDate)
// 		).toString();
// 		const currentTime = new Date(
// 			service.fromUtcWithAccountTz(control.value)
// 		).toString();

// 		const nowDate = new Date(service.dateWithAccountTimezone()).toString();

// 		if (
// 			getDateWithoutTime(currentDate) === getDateWithoutTime(nowDate) &&
// 			getOnlyTime(currentTime) < getOnlyTime(nowDate)
// 		) {
// 			return {
// 				error: new TuiValidationError(
// 					'message_wizard_validate_field.regular_time_send_input'
// 				)
// 			};
// 		}
// 		return null;
// 	};

export const regularTimeSendValidator =
	(service: DateAccountTimezoneService) => (control: AbstractControl) => {
		const controlTime = control.parent?.get('startTime');
		controlTime?.updateValueAndValidity();
		const currentDate = service.fromUtcWithAccountTz(control.value);
		const nowDate = service.dateWithAccountTimezone();

		const currentDateStr = getDate(getFullData(currentDate / 1000));
		const nowDateStr = getDate(getFullData(nowDate / 1000));

		if (currentDateStr < nowDateStr) {
			return {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_time_send'
				)
			};
		}
		controlTime?.updateValueAndValidity();
		return null;
	};

function getDate(date: string) {
	const newDate = `${new Date(date).getFullYear()}.${(
		new Date(date).getMonth() + 1
	)
		.toString()
		.padStart(2, '0')}.${new Date(date)
		.getDate()
		.toString()
		.padStart(2, '0')}`;
	return newDate;
}

function getFullData(dateInNumber: number) {
	return new Date(dateInNumber * 1000).toString(); // умножаем на 1000, так как значение должно быть в миллисекундах
}
function getDateWithoutTime(date: string) {
	return date.substring(0, 15);
}

function getOnlyTime(date: string) {
	return date.substring(16, 21);
}

export function sendlateTimeValidator(
	field: AbstractControl
): Validators | null {
	const currentDate = getFullData(field.value);
	const currentDateStr = getDate(currentDate);
	const fullNowDate = new Date().toString();
	const nowDateStr = getDate(fullNowDate);
	if (currentDateStr < nowDateStr) {
		return {
			error: new TuiValidationError(
				'message_wizard_validate_field.regular_time_send'
			)
		};
	}
	if (
		getDateWithoutTime(currentDate) === getDateWithoutTime(fullNowDate) &&
		getOnlyTime(currentDate) < getOnlyTime(fullNowDate)
	) {
		return {
			error: new TuiValidationError(
				'message_wizard_validate_field.regular_time_send'
			)
		};
	}
	return null;
}

export function regularWeekdaysValidator(
	field: AbstractControl
): Validators | null {
	return field.value.length !== 0
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_weekdays'
				)
		  };
}

export function regularMonthValidator(
	field: AbstractControl
): Validators | null {
	return field.value.length !== 0
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_month'
				)
		  };
}

export function regularFilterValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_filter'
				)
		  };
}

export function regularEveryWeekValidator(
	field: AbstractControl
): Validators | null {
	return field.value.length !== 0
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_every_week'
				)
		  };
}

export function regularDayValidator(field: AbstractControl): Validators | null {
	return field.value.length !== 0
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.regular_day'
				)
		  };
}

export function apiAgreementValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.api_agreement'
				)
		  };
}

export function eventTypeValidator(field: AbstractControl): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.event_type'
				)
		  };
}

export function eventExtraFieldValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.event_extra_field'
				)
		  };
}

export function eventExtraFieldValueValidator(
	field: AbstractControl
): Validators | null {
	const { value } = field;
	const validField =
		(typeof value === 'number' && (value === 0 || value !== 0)) ||
		(typeof value === 'boolean' && (value === false || value === true)) ||
		(typeof value === 'string' && value !== '');

	return validField
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.event_extra_field_value'
				)
		  };
}

export function eventMessageValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.event_message'
				)
		  };
}

export function eventMailingGroupsValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.event_mailing_groups'
				)
		  };
}

export function eventMailingGroupsArrayValidator(
	field: AbstractControl
): Validators | null {
	return field.value.length !== 0
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.event_mailing_groups'
				)
		  };
}

export function editorMailFromEmailValidator(
	field: AbstractControl
): Validators | null {
	if (!field.value)
		return {
			required: new TuiValidationError(
				'message_wizard_validate_field.mail_from_email_required'
			)
		};

	if (field.value.split('@')[0].length > 64) {
		return {
			maxlenght: new TuiValidationError(
				'message_wizard_validate_field.mail_from_email_valid'
			)
		};
	}

	return !CustomValidators.email(field)
		? null
		: {
				invalidEmail: new TuiValidationError(
					'message_wizard_validate_field.mail_from_email_valid'
				)
		  };
}

export function editorMailFromNameValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.mail_from_name'
				)
		  };
}

export function editorMessageSubjectValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.message_subject'
				)
		  };
}

export function editorPushIconValidator(
	field: AbstractControl
): Validators | null {
	if (!field.value)
		return {
			required: new TuiValidationError(
				'message_wizard_validate_field.push_icon_required'
			)
		};

	return !CustomValidators.url(field)
		? null
		: {
				invalidEmail: new TuiValidationError(
					'message_wizard_validate_field.push_icon_valid'
				)
		  };
}

export function editorPushClickActionValidator(
	field: AbstractControl
): Validators | null {
	switch (true) {
		case !field.value:
			return {
				error: new TuiValidationError(
					'message_wizard_edit_step.push_empty_link'
				)
			};
		case !!CustomValidators.url(field):
			return {
				error: new TuiValidationError(
					'message_wizard_edit_step.push_wrong_link'
				)
			};
		default:
			return null;
	}
}

export function editorPushTimeToLiveValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.push_time_to_live'
				)
		  };
}

export function editorMobPushButtonLabelValidator(
	field: AbstractControl
): Validators | null {
	return field.value
		? null
		: {
				error: new TuiValidationError(
					'message_wizard_validate_field.button_title'
				)
		  };
}

export function validationSmsLength(
	control: AbstractControl
): Validators | null {
	const useShortLinks: boolean = control.parent?.value?.toShortLink;
	let body: string = control.value;
	const error = {
		maxSmsLength: true
	};

	if (useShortLinks) {
		const urls: string[] | null = body.match(FULL_URL_REGEX);
		let shortedLength = 0;

		urls?.forEach(url => {
			body = body.replace(url, '');
			shortedLength += SHORT_LINK_FIX_LENGTH;
		});

		return body.length + shortedLength > 1000 ? error : null;
	}

	return body.length > 1000 ? error : null;
}
