import { ID } from '@datorama/akita';

export interface RfSegmentModel {
	id: ID;
	sysName: string;
	name: string;
	description: string;
	contactsCount: number;
	contactsPercent: number;
	ordersSum: number;
	ordersCount: number;
	averageCheck: number;
	color?: string;
}

export interface DashboardModel {
	averageCheck: number;
	buyersCount: number;
	contactsCount: number;
	lastRecount: number;
	nextRecount: number;
	ordersSum: number;
}

export type Recency = 'r1' | 'r2' | 'r3' | 'r4' | 'r5';

export type Frequency = 'f1' | 'f2' | 'f3' | 'f4' | 'f5';

export type Monetary = 'm1' | 'm2' | 'm3' | 'm4' | 'm5';

export type RecountFrequency = 1 | 7 | 14 | 30;

export interface SegmentsSettingsModel {
	bordersSettings: {
		recency: { [key in Recency]: string };
		frequency: { [key in Frequency]: string };
		monetary: { [key in Monetary]: string };
	};
	recountFrequency: RecountFrequency;
}

export interface SegmentsSettingsFormModel {
	recency: number[];
	frequency: number[];
	monetary: number[];
	period: RecountFrequency;
}

export interface CalculationProgressModel {
	percent: number;
	status: ProgressStatus;
}

export interface ChangeSegmentInfoModel {
	systemName: string;
	name: string;
	description: string;
}

export interface BorderCategory {
	field: BorderField;
	one: string;
	two: string;
	three: string;
	four: string;
	five: string;
}

export type BorderField = 'recency' | 'frequency' | 'monetary';

export type ProgressStatus = 'active' | 'inactive';
