import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesktopPreviewComponent } from './desktop-preview.component';
import { PopupPreviewIframeModule } from '../../../popup-preview-iframe/popup-preview-iframe.module';

@NgModule({
	imports: [CommonModule, PopupPreviewIframeModule],
	declarations: [DesktopPreviewComponent],
	exports: [DesktopPreviewComponent]
})
export class DesktopPreviewModule {}
