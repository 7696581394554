import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { Observable, Subject, timer } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import {
	PersonsCount,
	PersonsCountRequesData
} from '../models/persons-count.model';
import {
	fileValidator,
	segmentsValidator,
	subscriptionsValidator
} from './validators/validators';

@Injectable()
export class StepReceiversService {
	private form: UntypedFormGroup;
	readonly stopCountStream$ = new Subject();
	public lastGuid = '';

	constructor(private fb: UntypedFormBuilder, private http: HttpClient) {}

	get stepReceivers(): UntypedFormGroup {
		return this.form as UntypedFormGroup;
	}

	init() {
		this.form = this.initReceiversForm();
	}

	initReceiversForm(): UntypedFormGroup {
		return this.fb.group({
			segments: this.fb.control([], segmentsValidator),
			mailingGroups: this.fb.control([], subscriptionsValidator),
			exceptSegments: this.fb.control([]),
			contactsFile: this.fb.control({}, fileValidator)
		});
	}

	getPersonCount(data: PersonsCountRequesData): Observable<PersonsCount> {
		const POLLING_PERIOD = 5000;
		return this.http
			.post<string>('enkod/segments/countSegments/', data)
			.pipe(
				switchMap(guid =>
					timer(0, POLLING_PERIOD).pipe(
						mergeMap(() => {
							this.lastGuid = guid;
							return this.http.get<PersonsCount>(
								`enkod/segments/count/${guid}`
							);
						}),
						filter(response => response.status === 'ready')
					)
				),
				takeUntil(this.stopCountStream$)
			);
	}

	closeCountConnection(guid: string) {
		return this.http.delete(`enkod/segments/count/${guid}`);
	}
}
