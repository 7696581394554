<div style="display: flex; align-items: center">
    <form class="addition addition_field-date"
          [class.error]="form.invalid"
          [formGroup]="form">
        <en-multilevel-dropdown [valueControl]="form.get('operator')"
                                [options]="optionsMenu"
                                [hideRightBorder]="hideRightBorder"></en-multilevel-dropdown>

        <ng-container *ngIf="showSingleCalendar">
            <en-dropdown-calendar-new [formControl]="form.get('value')"
                                      [showReset]="false"
                                      [forceInvalid]="markValueAsInvalid"
                                      [type]="dateType === 'dateTime' ? 'dateTime' : 'date'"
                                      [reset$]="reset$"
                                      [size]="'s'"></en-dropdown-calendar-new>
        </ng-container>

        <ng-container *ngIf="showUnitSelector">
            <input class="h32 text-overflow"
                   type="number"
                   min="1"
                   max="1000"
                   step="1"
                   enInput
                   enDynamicWidth
                   pKeyFilter="pint"
                   formControlName="value" />
            <en-dropdown class="input__small"
                         styleClass="en-dropdown-solid"
                         [options]="optionsUnits"
                         formControlName="units"
                         dropdownIcon="en en-chevron-down"></en-dropdown>
            <span *ngIf="isPastTimeOperator"
                  class="center-span-text"
                  translate>segment_form.cond_field_date_ago</span>
        </ng-container>

        <ng-container *ngIf="showRangeCalendar">
            <en-dropdown-calendar-range [formControl]="dateRangeControl"
                                        [showReset]="false"
                                        [type]="dateType === 'dateTime' ? 'dateTime' : 'date'"
                                        [isInvalid]="markRangeAsInvalid">
            </en-dropdown-calendar-range>
        </ng-container>
    </form>
</div>