<div *ngIf="warnAlert"
     class="snippet-alert">
    <span class="en en-help-circle-o"></span>
    {{'message_wizard_edit_step.sendtest_warn_empty_snippets' | translate}}
</div>

<ng-container [formGroup]="snippetForm">
    <en-snippet-inputs [dialog]="dialog"
                       formControlName="snippets"></en-snippet-inputs>
</ng-container>
