import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class TranslationLoaderGuard implements CanActivate {
	constructor(private translate: TranslateService) {}

	canActivate(): Observable<boolean> {
		return new Observable(observer => {
			this.translate.get('common.apply').subscribe({
				next: () => {
					observer.next(true);
				}
			});
		});
	}
}
