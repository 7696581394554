<en-dialog [header]="'scenario_block_api.select_segments' | translate"
           [resizable]="false"
           [modal]="true"
           styleClass="p-dialog--lg"
           appendTo="body"
           [(visible)]="dialogVisible"
           (onHide)="onHideDialog()">
    <tui-scrollbar class="scrollbar">
        <div class="segments-table-content">
            <en-segments-table *ngIf="dialogVisible"
                               [toolsPanel]="false"
                               [selection]="true"
                               [notSaveInUrl]="true"
                               [isEnSendSegments]="true"
                               [segmentsInNewWindow]="true"
                               (selected)="onConfirmDialog($event)">
                <ng-template enTemplate="tools">
                    <section class="search">
                        <en-search [placeholder]="'segment_list.search_placeholder' | translate"
                                   styleClass="h32"
                                   [formControl]="searchControl"
                                   (onSearch)="paginationService.setSearchValue($event)"
                                   [refresher]="false"></en-search>
                    </section>
                </ng-template>
            </en-segments-table>
        </div>
    </tui-scrollbar>
</en-dialog>