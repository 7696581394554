import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { Segment, SubscribersQuantityModel } from './segment.model';

export interface SegmentsState extends EntityState<Segment> {
	subscribersQuantity?: SubscribersQuantityModel;
	countLoading?: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'segments'
})
export class SegmentsStore extends EntityStore<SegmentsState> {
	constructor() {
		super({
			[ALL_LIST_STORE_NAME]: null,
			uuidCount: null
		});
	}
}
