import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input,
	OnInit,
	EventEmitter,
	Output,
	OnDestroy,
	Inject
} from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormControl,
	NG_VALUE_ACCESSOR
} from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { PaginatorPlugin } from '@datorama/akita';
import { SelectItem } from 'primeng/api';
import { filter, skip, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { UrlSaverService } from '@enkod-core/services';
import { WINDOW } from '@enkod-core/utils';
import { PaginationService } from './pagination-service.class';

const notNull = <T>(value: T | null): value is T => value !== null;

export const PAGINATOR_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => PaginatorComponent),
	multi: true
};

@UntilDestroy()
@Component({
	selector: 'en-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss'],
	providers: [PAGINATOR_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent
	implements OnInit, ControlValueAccessor, OnDestroy
{
	paginatorRef: PaginatorPlugin<any>;

	perPageControl: UntypedFormControl;

	currentPage: UntypedFormControl;

	paginatorRef$ = new BehaviorSubject<PaginatorPlugin<any> | null>(null);

	perPage: number;

	isFirstLoad = true;

	@Input() onLoadFromUrl = false;

	@Input() total: number;

	@Input() onPageCount: boolean;

	@Input() independent: boolean = false;

	@Input()
	set resetPage(value: string) {
		if (value) this.paginatorRef.setFirstPage();
	}

	@Input('paginatorRef')
	set paginatorRefSetter(value: PaginatorPlugin<any>) {
		this.paginatorRef$.next(value);
		this.paginatorRef = value;
	}

	@Input() perPageItems: SelectItem[] = [
		{
			label: '10',
			value: 10
		},
		{
			label: '50',
			value: 50
		},
		{
			label: '100',
			value: 100
		}
	];

	@Output() onChangePerPage = new EventEmitter();

	// private onTouched: Function = () => {};

	private onChange: Function = () => {};

	get perPageValue() {
		if (this.independent) return this.perPage || 10;
		return this.window.localStorage.getItem('perPage') || 10;
	}

	constructor(
		@Inject(WINDOW) readonly window: Window,
		private urlService: UrlSaverService,
		private paginationService: PaginationService
	) {}

	registerOnChange(onChange: Function) {
		this.onChange = onChange;
	}

	registerOnTouched() {
		// this.onTouched = onTouched;
	}

	writeValue(perPage: number) {
		this.perPage = perPage;
	}

	ngOnInit() {
		this.init();
		this.setupFormListeners();

		if (this.onLoadFromUrl) {
			this.paginationService.setSavedParamsFromUrl(
				this.urlService.getQueryParams()
			);
			this.paginatorRef.setPage(
				Number(this.urlService.getQueryParams().page) || 1
			);
			this.urlService
				.patchCurrentPageControl(this.currentPage)
				.pipe(untilDestroyed(this))
				.subscribe();
			this.urlService
				.setPageToUrl(this.paginatorRef)
				.pipe(untilDestroyed(this))
				.subscribe();
		}
		this.paginationService.selectSearchValue
			.pipe(untilDestroyed(this), skip(1))
			.subscribe(() => {
				if (!this.isFirstLoad) this.paginatorRef.setFirstPage();
				this.isFirstLoad = false;
			});
	}

	private init() {
		this.perPageControl = new UntypedFormControl(Number(this.perPageValue));
		this.currentPage = new UntypedFormControl(
			this.paginatorRef.currentPage
		);
	}

	private setupFormListeners() {
		this.paginatorRef$
			.pipe(
				untilDestroyed(this),
				filter(notNull),
				switchMap(paginator => paginator.pageChanges)
			)
			.subscribe((page: number) => {
				this.currentPage.patchValue(page, { emitEvent: false });
			});

		this.perPageControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(val => {
				if (this.independent) {
					this.perPage = val;
				} else {
					this.window.localStorage.setItem('perPage', val);
					this.urlService.setFirstPageToUrl(this.paginatorRef);
				}
				this.onChange(val);
				this.onChangePerPage.emit(val);
			});

		this.currentPage.valueChanges
			.pipe(
				untilDestroyed(this),
				filter(page => page)
			)
			.subscribe(page => {
				if (page > this.total)
					this.currentPage.patchValue(this.total, {
						emitEvent: false
					});
				else if (page < 0)
					this.currentPage.patchValue(1, {
						emitEvent: false
					});
				else this.paginatorRef.setPage(page);
			});
	}

	blurCurrentPage() {
		if (this.currentPage.value === null) this.currentPage.patchValue(1);
	}

	ngOnDestroy(): void {
		//  Сброс на первую страницу для таблиц без сохранения в url
		if (!this.onLoadFromUrl && this.paginatorRef)
			this.paginatorRef.setFirstPage();
	}
}
