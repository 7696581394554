import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit,
	EventEmitter,
	Output
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScenarioMessage } from '@enSend/message/_states/_state-message';
import {
	DataWizardService,
	WhatsappVariableService
} from '@enSend/message/message-wizard/kit';
import { WhatsappParamsOTD } from '@enSend/_shared/classes';

import { WaTemplateDTO } from '@enSend/message/whatsapp-template-wizard/models';
import { TemplateDataService } from '@enSend/message/whatsapp-template-wizard/services';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
	WhatsappPreviewParams,
	WhatsappPreviewTemplate,
	WhatsappResponseParams
} from '../models/whatsapp-preview.model';

@UntilDestroy()
@Component({
	selector: 'en-whatsapp-info',
	templateUrl: './whatsapp-info.component.html',
	styleUrls: ['./whatsapp-info.component.scss'],
	providers: [TemplateDataService, WhatsappVariableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappInfoComponent implements OnInit {
	message: ScenarioMessage;
	template: WaTemplateDTO;
	previewTemplate: WhatsappPreviewTemplate;
	isPreviewVisible = false;
	textContent$ = new BehaviorSubject<string>('');

	@Input() messageId: number;
	@Output() onDeletedTemplate = new EventEmitter();

	constructor(
		private messageDataService: DataWizardService<ScenarioMessage>,
		private templateDataService: TemplateDataService,
		private variableService: WhatsappVariableService
	) {}

	ngOnInit(): void {
		this.messageDataService
			.getMessage(this.messageId)
			.pipe(
				untilDestroyed(this),
				switchMap(message => {
					this.message = message;
					if (message.whatsappTemplateId)
						return this.templateDataService.getById(
							message.whatsappTemplateId
						);
					return of(null);
				})
			)
			.subscribe(template => {
				if (template) {
					if (template.isDeleted) {
						this.onDeletedTemplate.emit();
					} else {
						this.combineWhatsappText(template);
					}
				}
			});
	}

	private combineWhatsappText(template: WaTemplateDTO) {
		this.template = template;
		const text = template.components.find(
			item => item.type === 'BODY'
		)?.text;
		const footer = template.components.find(
			item => item.type === 'FOOTER'
		)?.text;
		const title = template.components.find(item => item.type === 'HEADER');

		if (text) {
			this.previewTemplate = {
				...template,
				text,
				footer,
				title,
				titleType: title?.format,
				params: this.getParams()
			};

			this.textContent$.next(
				this.message.whatsappParams?.body_text
					? this.variableService.highlightVariablesInText(
							text,
							(this.message.whatsappParams
								?.body_text[0] as unknown as string[]) || []
					  )
					: text
			);
		}
	}

	private getParams(): WhatsappPreviewParams {
		return new WhatsappParamsOTD(
			this.message.whatsappParams as WhatsappResponseParams
		).getValue();
	}
}
