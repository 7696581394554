import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ConvertDateModule } from '@enkod-core/pipes';
import { SharedModule } from '@shared';

import {
	PaginatorModule,
	EnButtonModule,
	EnTagModule,
	EnMultiSelectModule,
	HorizontalScrollWrapperModule,
	EnTableModule,
	RelatedDataDialogModule
} from 'ui-lib';
import { DisableControlDirectiveModule } from '@enkod-core/directives';
import { SegmentsTableComponent } from './segments-table.component';
import { ModuleBadgeComponent } from '../module-badge/module-badge.component';
import { SegmentsFilterComponent } from './segments-filter/segments-filter.component';
import { DeleteSegmentWithRelationsModule } from '../dialogs';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		ReactiveFormsModule,
		EnTableModule,
		PaginatorModule,
		MatMenuModule,
		EnButtonModule,
		SharedModule,
		TranslateModule,
		ConvertDateModule,
		EnTagModule,
		EnMultiSelectModule,
		DisableControlDirectiveModule,
		HorizontalScrollWrapperModule,
		DeleteSegmentWithRelationsModule,
		RelatedDataDialogModule,
		RouterModule
	],
	declarations: [
		SegmentsTableComponent,
		ModuleBadgeComponent,
		SegmentsFilterComponent
	],
	exports: [SegmentsTableComponent]
})
export class SegmentsTableModule {}
