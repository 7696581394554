<en-dropdown class="inspector-setting"
             styleClass="h36 en-dropdown-solid"
             [options]="options"
             [filter]="true"
             [attr.disabled]="!isCreationMode"
             (onChange)="onChangeTypeChange.emit($event.value)"
             [formControl]="control">
    <ng-template let-selected
                 pTemplate="selectedItem">
        <div>{{selected.label | translate}}</div>
    </ng-template>
    <ng-template let-type
                 pTemplate="item">
        <div class="drop">
            <span class="drop__label">{{type.label | translate}}</span>
            <div class="drop__description">{{type.description | translate}}</div>
        </div>
    </ng-template>
</en-dropdown>