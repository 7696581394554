import {
	trigger,
	style,
	state,
	keyframes,
	transition,
	animate
} from '@angular/animations';

export const shake = trigger('shakeIt', [
	state('true', style({})),
	state('false', style({})),
	transition(
		'0 => 1',
		animate(
			'1000ms ease-in',
			keyframes([
				style({
					transform: 'translate3d(-1px, 0, 0)',
					offset: 0.1
				}),
				style({
					transform: 'translate3d(2px, 0, 0)',
					offset: 0.2
				}),
				style({
					transform: 'translate3d(-3px, 0, 0)',
					offset: 0.3
				}),
				style({
					transform: 'translate3d(3px, 0, 0)',
					offset: 0.4
				}),
				style({
					transform: 'translate3d(-3px, 0, 0)',
					offset: 0.5
				}),
				style({
					transform: 'translate3d(3px, 0, 0)',
					offset: 0.6
				}),
				style({
					transform: 'translate3d(-3px, 0, 0)',
					offset: 0.7
				}),
				style({
					transform: 'translate3d(2px, 0, 0)',
					offset: 0.8
				}),
				style({
					transform: 'translate3d(-1px, 0, 0)',
					offset: 0.9
				})
			])
		)
	)
]);
