import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { ActionCountForm } from '@enKod/segments/segments-form.model';

@UntilDestroy()
@Component({
	selector: 'en-message-add-count',
	templateUrl: './message-add-count.component.html',
	styleUrls: ['../../../abstract-condition.component.scss']
})
export class MessageAddCountComponent {
	readonly optionsMessageCount: SelectItem[] = [
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.cond_message_count_min',
			value: 'min'
		},
		{
			label: 'segment_form.cond_message_count_max',
			value: 'max'
		}
	];

	isHover = false;

	@Input() form: FormGroup<ActionCountForm>;

	@Input() isShowRemoveButton = false;

	@Output() remove = new EventEmitter();

	constructor(public translate: TranslateService) {}

	get countValue() {
		return this.form.controls.value.value;
	}
}
