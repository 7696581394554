import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'testSendingStatusText'
})
export class TestSendingStatusTextPipe implements PipeTransform {
	transform(value: any) {
		switch (value) {
			case 'sending':
				return 'message_list.status_sending';
			case 'delivered':
				return 'message_list.status_delivered';
			case 'error':
				return 'message_list.status_not_sent';
			default:
				return '';
		}
	}
}
