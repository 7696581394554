import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ModalVisibleService {
	public templatesModal$ = new Subject<boolean>();
	public fieldsModal$ = new Subject<boolean>();

	openTemplatesModal(value: boolean) {
		this.templatesModal$.next(value);
	}

	openFieldsModal(value: boolean) {
		this.fieldsModal$.next(value);
	}
}
