import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Param } from './event-start.model';

export interface EventStartParamsState extends EntityState<Param> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'event-start-params' })
export class EventStartParamsStore extends EntityStore<EventStartParamsState> {
	constructor() {
		super({});
	}
}
