import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ExpandedQuery } from '@enkod-core/abstract';

import { Param } from './event-start.model';
import {
	EventStartParamsState,
	EventStartParamsStore
} from './event-start-params.store';

@Injectable({ providedIn: 'root' })
export class EventStartParamsQuery extends ExpandedQuery<EventStartParamsState> {
	constructor(protected store: EventStartParamsStore) {
		super(store);
	}

	readonly params$ = this.selectAll() as Observable<Param[]>;
}
