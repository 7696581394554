<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsCategoryData"
                 formControlName="type"
                 (onChange)="onChangeType($event)"></en-dropdown>

    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsOperatorTagsEquality"
                 [formControl]="form.get('equality')"></en-dropdown>

    <ng-container>
        <en-multiSelect class="condition__tag"
                        styleClass="en-dropdown-solid"
                        [class.active-hide]="showHide"
                        [options]="selectItems | async"
                        [filter]="true"
                        [emptyFilterMessage]="'segment_form.cond_subscription_empty_message' | translate"
                        [showToggleAll]="false"
                        [showCloseButton]="false"
                        [useSelectAllItem]="true"
                        [isInvalid]="markAsInvalid"
                        [placeholder]="'segment_common.tag_placeholder' | translate"
                        formControlName="value">
            <ng-template pTemplate="selectedItems"
                         let-selectedItems>
                <div *ngIf="selectedItems.length === 0">{{'segment_common.tag_placeholder' |
                    translate}}</div>
                <ng-container *ngIf="selectedItems.length !== 0 && selectedItems.length < 4">
                    <ng-container *ngFor="let tag of selectedItems; index as idx">
                        <en-tag status="message"
                                [value]="tag.name | systemTag"
                                [defaultValue]="tag.name"
                                [tippy]="tag.isDeleted ? ('segment_common.tag_removed' | translate) : false"
                                [forceDeletable]="true"
                                [disabled]="tag.isDeleted"
                                (removeItem)="deselectItem(idx)"></en-tag>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedItems.length > 3">
                    <span>{{'segment_form.selected' | translate:{value: selectedItems.length} }}</span>
                </ng-container>
            </ng-template>
            <ng-template let-condition
                         pTemplate="item">
                <div class="options-tags"
                     [tippy]="condition.value.isDeleted && 'segment_common.tag_removed' | translate"
                     [class.disabled-receiver]="condition.value.isDeleted">
                    <en-tag status="message"
                            [value]="condition.label | systemTag"
                            [defaultValue]="condition.label"
                            [disabled]="condition.value.isDeleted"></en-tag>
                </div>
            </ng-template>
        </en-multiSelect>
    </ng-container>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>
</form>
