import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ID } from '@datorama/akita';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MessageBodyForm } from '@enKod/segments/segments-form.model';
import {
	LinkData,
	MessageDetailQuery,
	MessageDetailService
} from '@enSend/message/message-details/_states/_state-message';
import {
	OPEN_CLICK_DETAILS_PROVIDER,
	OPEN_LINK_EDITOR_PROVIDER
} from 'ui-lib/clicks-map/tokens';
import { ClicksMapState } from 'ui-lib';
import { ActionLink } from '@state-enKod/segments';

@UntilDestroy()
@Component({
	selector: 'en-link-picker-dialog',
	templateUrl: './link-picker-dialog.component.html',
	styleUrls: ['./link-picker-dialog.component.scss'],
	providers: [
		OPEN_CLICK_DETAILS_PROVIDER,
		OPEN_LINK_EDITOR_PROVIDER,
		ClicksMapState
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPickerDialogComponent implements OnInit {
	activeIndex = 0;

	@Input() visibleDialog = false;
	@Input() form: FormGroup<MessageBodyForm>;

	clicksMapData$ = this.messageQuery.clicksMapData$;
	linksEditorData$ = this.messageQuery.linksEditorData$;

	clicksMapData = {
		allClicks: 1,
		allUniqClicks: 1,
		details: [
			{
				clicks: 1,
				uniqClicks: 1,
				urlId: 0
			}
		]
	};

	html: string;

	links: LinkData[];
	filteredLinks: LinkData[];
	linksMapInited = false;

	@Output() closeDialog = new EventEmitter();
	@Output() applyLink = new EventEmitter();

	constructor(
		private cd: ChangeDetectorRef,
		private messageQuery: MessageDetailQuery,
		private messageService: MessageDetailService,
		public state: ClicksMapState
	) {}

	ngOnInit() {
		this.getLinksData();
	}

	getLinksData() {
		let objectId: ID;
		const messageId = this.form.controls.specificId.value as number;

		this.messageService.setActive(messageId);

		this.messageService
			.getDetailMessageById(messageId)
			.pipe(
				untilDestroyed(this),
				switchMap(value => {
					if (value.mails?.length) {
						objectId = value.mails[0].id;
						this.html = value.mails[0].html;

						return this.messageService.getLinksData({
							messageId,
							objectId,
							getAll: true
						});
					}
					return EMPTY;
				}),
				tap(links => {
					this.links = links.map((link, index) => ({
						...link,
						url_id: index,
						seekBy: 'urlId'
					}));
					this.filteredLinks = this.links;
				}),
				switchMap(() => {
					return this.messageService
						.getClicksStatistic(objectId)
						.pipe(untilDestroyed(this));
				}),
				tap(() => {
					this.cd.detectChanges();
				})
			)
			.subscribe();
	}

	onSearch(search: string) {
		if (!search) {
			this.filteredLinks = this.links;
			return;
		}

		this.filteredLinks = this.links.filter(link =>
			link.url?.includes(search)
		);
	}

	onTabChange() {
		if (this.linksMapInited) return;
		this.linksMapInited = true;
		if (this.activeIndex === 1) {
			this.state.modifier.next('chooseLink');
			this.state.visible = true;
			this.state.action.next('changeVisible');
		}
	}

	selectLinkFromMap(event: ActionLink & LinkData) {
		this.applyLink.emit(event);
		this.state.action.next('removeClicksMap');
	}
}
