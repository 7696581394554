export enum Path {
	condition = 'condition',
	editor = 'editor',
	split = 'split',
	receivers = 'receivers',
	confirm = 'confirm'
}

export interface Steps {
	[key: number]: keyof typeof Path;
}
