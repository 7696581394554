/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { startBlock } from './start.shapes';
import { ScenarioShapesEnum } from '../../../../config/enum.shapes';
import { block, blockView } from '../../base.shapes';

export const messageStartBlock = startBlock.define(
	ScenarioShapesEnum.MESSAGE_START_BLOCK,
	{},
	{
		initialize(...args: any) {
			block.prototype.initialize.apply(this, args);
			this.onChangeOptions();
		},

		onChangeOptions(args: any) {
			block.prototype.onChangeOptions.call(this, {
				updatePorts: true,
				...args
			});
		}
	}
);

export const messageStartBlockView: dia.ElementView = Object.assign(blockView);
