import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TippyModule } from '@ngneat/helipopper';
import { BottomBarComponent } from './bottom-bar.component';
import { BottomBarService } from './services';

@NgModule({
	declarations: [BottomBarComponent],
	imports: [CommonModule, TippyModule],
	exports: [BottomBarComponent],
	providers: [BottomBarService]
})
export class BottomBarModule {}
