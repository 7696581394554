<en-dialog [(visible)]="visible"
           [modal]="true"
           headerBackground="warn"
           headerIcon="en en-exclamation-triangle-o"
           header="Данные были изменены"
           appendTo="body"
           (onHide)="hide.emit()">

    <div class="content">
        <span class="content__desc">{{'extra_field_list.field_list_edit_header' | translate}}</span>
        <span class="content__confirm">{{'extra_field_list.field_list_edit_confirm' | translate}}</span>
    </div>

    <p-footer>
        <button enButton
                type="button"
                style="margin-right: 1rem;"
                class="h36 en-button-secondary"
                [label]="'extra_field_list.dialog_button_cancel' | translate"
                (click)="hide.emit()">
        </button>
        <button enButton
                type="button"
                class="h36"
                [label]="'extra_field_list.dialog_button_save' | translate"
                (click)="accept.emit()"></button>
    </p-footer>
</en-dialog>