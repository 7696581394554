import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Scenario } from './scenario.model';

export interface ScenariosState extends EntityState<Scenario> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'scenarios'
})
export class ScenariosStore extends EntityStore<ScenariosState> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {
		super();
	}
}
