import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import {
	ReferrersConditionForm,
	TimeRangeForm
} from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-referrers-condition',
	templateUrl: './referrers-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferrersConditionComponent extends AbstractCondition {
	optionsPeriod: SelectItem[] = [
		{ label: 'segment_options.session_options', value: 'session' }
	];

	get actionTime(): FormGroup<TimeRangeForm> {
		return (this.form as FormGroup<ReferrersConditionForm>).controls
			.actionTime as FormGroup<TimeRangeForm>;
	}

	setValidation() {
		this.getSubscription('value')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (value < 1) this.setValue('value', 1);
				if (value > 1000) this.setValue('value', 1000);
			});
	}
}
