import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { MailingGroupComponent } from './mailing-group.component';

@Injectable()
export class MailingGroupService implements InspectorItemPlugin {
	private readonly mailingGroupContent = new PolymorpheusComponent(
		MailingGroupComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'mailingGroup',
				name: 'mailingGroup',
				content: this.mailingGroupContent
			}
		]);
	}
}
