import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TestMobpushService } from './test-mobpush.service';

@UntilDestroy()
@Component({
	selector: 'en-test-mobpush',
	templateUrl: './test-mobpush.component.html',
	styleUrls: ['./test-mobpush.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestMobpushComponent implements OnInit {
	options: string[] = [];

	systems: SelectItem[] = [
		{
			label: 'IOS',
			value: 'ios'
		},
		{
			label: 'Android',
			value: 'android'
		}
	];

	@Input() selectedSystem: FormControl;
	@Input() tokenValue: FormControl;

	@Output() onChange = new EventEmitter();

	constructor(private testMobpushService: TestMobpushService) {}

	get savedTokens() {
		return this.testMobpushService.tokens$.getValue();
	}

	ngOnInit(): void {
		this.testMobpushService.updateTokens();

		this.tokenValue.valueChanges
			.pipe(
				tap(() => {
					this.onChange.emit(this.tokenValue.value);
				}),
				untilDestroyed(this)
			)
			.subscribe();
	}

	getOptiion(event: any) {
		const { query } = event;
		const result = new Set<string>();

		this.savedTokens.forEach((token: string) => {
			if (token.startsWith(query.toLowerCase())) {
				result.add(token);
			}
		});

		this.options = Array.from(result).reverse().slice(0, 6);
	}
}
