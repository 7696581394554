import { SegmentType } from '@state-enKod/segments';
import { SelectItem } from 'primeng/api';

export const SEGMENT_TYPE_OPTIONS: SelectItem<SegmentType>[] = [
	{
		label: 'для сообщений',
		value: 'enSend'
	},
	{
		label: 'для всплывающих окон',
		value: 'enPop'
	}
];
