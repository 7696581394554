import { Injectable } from '@angular/core';

import { PeopleCountListService } from '@enSend/scenario/components/dialogs/people-count-dialog/state';
import { TranslateService } from '@ngx-translate/core';
import {
	shapeConfig,
	ScenarioSubtype
} from '@enSend/_shared/components/scenario-canva/rappid/config/shape.config';
import { BehaviorSubject } from 'rxjs';

type PeopleCountDialogOptions = {
	subtype: ScenarioSubtype;
	blockGUID: number;
	scenarioId: number;
};
@Injectable()
export class DialogService {
	peopleCountDialog$ = new BehaviorSubject<boolean>(false);

	dialogHeader: string;

	constructor(
		private peopleCountListService: PeopleCountListService,
		private translate: TranslateService
	) {}

	openPeopleCountDialog({
		subtype,
		blockGUID,
		scenarioId
	}: PeopleCountDialogOptions) {
		this.dialogHeader = this.translate.instant(
			shapeConfig.scenario[subtype].text
		);
		this.peopleCountListService.setListProperties(scenarioId, blockGUID);
		this.peopleCountDialog$.next(true);
	}
}
