import { SelectItem } from 'primeng/api';
import { TimeOperators } from '../models/types';

export const OPTIONS_ABSTRACT_TIME: SelectItem<TimeOperators>[] = [
	{
		label: 'segment_form.option_time_between',
		value: 'between'
	},
	{
		label: 'segment_form.option_time_lastime',
		value: 'last'
	}
];

export const OPTIONS_TIME: SelectItem<TimeOperators>[] = [
	{
		label: 'segment_form.option_time_last',
		value: 'last'
	},
	{
		label: 'segment_form.option_time_equal',
		value: 'equal'
	},
	{
		label: 'segment_form.option_time_more',
		value: 'more'
	}
];

export const OPTIONS_UNSUBSCRIPTION_TIME: SelectItem<TimeOperators>[] = [
	{
		label: 'segment_form.options_period_all',
		value: 'all'
	},
	{
		label: 'segment_options.between_date',
		value: 'between'
	}
];
