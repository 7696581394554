import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AbstractCondition } from '../../../abstract-condition.component';

@Component({
	selector: 'en-close-condition',
	templateUrl: './close-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseConditionComponent extends AbstractCondition {}
