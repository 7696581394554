import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';

export const ENPOP_MENU_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_menu_items.conditions_item',
		isTitle: true
	},
	{
		label: 'segment_menu_items.behavior_item',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'segment_menu_items.watch_time_item',
				value: 'time'
			},
			{
				label: 'segment_menu_items.scroll_item',
				value: 'scroll'
			},
			{
				label: 'segment_menu_items.referrers_item',
				value: 'referrers'
			},
			{
				label: 'segment_menu_items.close_item',
				value: 'close'
			},
			{
				label: 'segment_form.menu_condition_buying_cart',
				value: 'cartChange'
			}
		],
		value: 'behaviorOptions'
	},
	{
		label: 'segment_menu_items.link_page_item',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'URL',
				value: 'url'
			},
			{
				label: 'segment_menu_items.utm_item',
				value: 'utm'
			}
		]
	},
	{ label: 'segment_menu_items.device_item', value: 'device' },
	{ label: 'segment_menu_items.html_item', value: 'html' },
	{ label: 'Local storage', value: 'localStorage' }
];
