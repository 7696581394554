import { ElementRef, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ScrollService {
	private scrollElement: ElementRef<HTMLElement>;

	public setScrollElement(element: ElementRef) {
		this.scrollElement = element;
	}

	public scrollToTop() {
		if (this.scrollElement) {
			this.scrollElement.nativeElement.scrollTo(0, 0);
		}
	}
}
