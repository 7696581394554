import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EnMultiSelectModule, EnSelectButtonModule, ErrorModule } from 'ui-lib';

import { SharedModule } from '@shared';

import { WeekdaySelectorModule } from '@enSend/message/message-wizard/message-wizard-common/components';
import { MonthComponent } from './month.component';
import { StartWeekdayComponent } from './start-weekday.component';
import { MonthService } from './month.service';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		WeekdaySelectorModule,
		EnSelectButtonModule,
		EnMultiSelectModule,
		ErrorModule,
		SharedModule
	],
	declarations: [MonthComponent, StartWeekdayComponent],
	exports: [MonthComponent],
	providers: [MonthService]
})
export class MonthModule {}
