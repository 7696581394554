import { Pipe, PipeTransform } from '@angular/core';

import { UserRole } from '@state-admin/users';

@Pipe({
	name: 'userRole'
})
export class UserRolePipe implements PipeTransform {
	transform(value: Exclude<UserRole, 'admin'>): string {
		switch (value) {
			case 'owner':
				return 'admin_users_list.role_owner';
			case 'marketer':
				return 'admin_users_list.role_marketer';
			default:
				return this.exhaustiveCheck(value);
		}
	}

	private exhaustiveCheck(value: never) {
		return value;
	}
}
