<div class="settings__wrapper">
    <span class="en-text-large">
        {{ 'scenario_block_api_query.additional_settings' | translate }}
    </span>

    <div class="switch__wrapper">
        <div>
            <label class="switch__label"
                   for="switch">{{'scenario_block_api_query.amo_autorization_label' | translate}}</label>
            <span class="switch__description"
                  [innerHTML]="'scenario_block_api_query.amo_autorization_description' | translate">
            </span>
        </div>

        <en-inputswitch inputId="switch"
                        [disabled]="disabled"
                        [formControl]="amoAuthEnabledControl"></en-inputswitch>
    </div>

    <en-messages [visible]="amoAuthEnabledControl.value"
                 viewMode="template"
                 severity="info"
                 [summary]="'scenario_block_api_query.limit_info_title' | translate">
        <ng-template enTemplate="content">
            <div [innerHtml]="'scenario_block_api_query.limit_info_description' | translate"></div>
        </ng-template>
    </en-messages>    
</div>