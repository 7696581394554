<p-calendar [class]="styleClass"
            [formControl]="dateControl"
            [styleClass]="styleClass"
            [showTime]="showTime"
            [timeOnly]="timeOnly"
            [showIcon]="showIcon"
            [defaultDate]="defaultDate"
            icon="en en-date"
            [disabled]="disabled"
            [showButtonBar]="showButtonBar"
            [readonlyInput]="readonlyInput"
            [placeholder]="placeholder"
            dateFormat="dd.mm.yy"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [appendTo]="appendTo"
            [selectionMode]="selectionMode"
            [inline]="inline"
            [yearNavigator]="yearNavigator"
            [monthNavigator]="monthNavigator"
            [yearRange]="yearRange"
            [inputId]="inputId"
            (onShow)="onShow.emit($event)"
            (onClearClick)="onClearClick.emit($event)"
            (onClose)="onClose.emit($event)"
            (onSelect)="onSelect.emit($event)"
            (onTodayClick)="onTodayClick()">
</p-calendar>