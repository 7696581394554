/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { ScenarioShapesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';
import { mainBlock, mainBlockView } from './main.shapes';

export const apiQueryBlock = mainBlock.define(
	ScenarioShapesEnum.API_QUERY_BLOCK,
	{},
	{
		initialize(...args: any) {
			mainBlock.prototype.initialize.apply(this, args);
		},

		onChangeOptions() {
			mainBlock.prototype.onChangeOptions.call(this);
		}
	}
);

export const apiQueryBlockView: dia.ElementView = Object.assign(mainBlockView);
