import { Injectable } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ID } from '@datorama/akita';
import { sqlParamsValidate } from './components/segments-tree/conditions/common/validators/sql-params-validators';
import {
	ConditionGroupModel,
	ConditionModel,
	CustomEvent,
	Segment,
	SelectItemValue,
	SelectItemWithDeleted
} from './_state';
import {
	FieldConditionForm,
	SubscriptionForm,
	SubscriptionConditionForm,
	TagConditionForm,
	RfmConditionForm,
	ReferrersConditionForm,
	ScrollConditionForm,
	ComparisonConditionForm,
	CartChangeConditionForm,
	UrlConditionForm,
	TimeConditionForm,
	VisitsConditionForm,
	UtmConditionForm,
	CommonConditionForm,
	DeviceConditionForm,
	HtmlConditionForm,
	CountConditionForm,
	ProductConditionForm,
	CartConditionForm,
	PurchaseConditionForm,
	FavouriteConditionForm,
	UserEventCommonForm,
	TimeRangeForm,
	ActionCountForm,
	EventParameterForm,
	UserEventConditionForm,
	MessageCommonForm,
	MessageConditionForm,
	SqlConditionForm,
	ConditionJsonForm,
	ConditionFormTypes,
	SegmentForm,
	UnsubscriptionConditionForm,
	LocalStorageConditionForm,
	BirthdayDateForm,
	CategoriesConditionForm,
	ActionLinkForm
} from './segments-form.model';

@Injectable()
export class SegmentsFormService {
	constructor(private fb: FormBuilder, private translate: TranslateService) {
		this.init();
	}

	form: FormGroup<SegmentForm>;

	submitting = false;

	init() {
		this.form = this.createSegmentForm();
	}

	createSegmentForm(): FormGroup<SegmentForm> {
		return this.fb.group({
			id: 0 as ID,
			name: ['', Validators.required],
			limit: 0,
			conditionJSON: this.createGroup(true),
			module: '',
			tags: this.fb.control<string[]>([])
		}) as FormGroup<SegmentForm>;
	}

	fromEntity(entity: Segment, isCopy?: boolean) {
		const copyText = this.translate.instant('common.copy');
		this.form.patchValue({
			id: entity.id,
			name: isCopy ? `${copyText} ${entity.name}` : entity.name,
			limit: entity.limit,
			module: entity.module
		});

		this.form.setControl(
			'conditionJSON',
			this.buildConditionGroup(entity.conditionJSON, true, isCopy)
		);
	}

	buildConditionGroup(
		group: ConditionGroupModel,
		isRoot: boolean,
		isCopy: boolean = false
	): FormGroup<ConditionJsonForm> {
		const formGroup = this.createGroup(isRoot);
		formGroup.controls.join.setValue(group.join);
		if (!isRoot) {
			formGroup.controls.isHide.setValue(group.isHide);
		}
		const { inners, conditions } = formGroup.controls;
		group.conditions.forEach((condition: any) => {
			const conditionForm = this.createConditionFromEntity(condition);
			if (conditionForm) {
				conditionForm.patchValue(
					isCopy ? this.conditionFilterGroups(condition) : condition,
					{
						emitEvent: false
					}
				);
				conditions.push(conditionForm);
			}
		});
		group.inners?.forEach((inner: any) => {
			const innerForm = this.buildConditionGroup(inner, false, isCopy);
			inners.push(innerForm);
		});
		return formGroup;
	}

	createGroup(isRoot: boolean): FormGroup<ConditionJsonForm> {
		return this.fb.group({
			join: this.fb.nonNullable.control('and'),
			conditions: this.fb.array<FormGroup<ConditionFormTypes>>([]),
			inners: this.fb.array<FormGroup<ConditionJsonForm>>([]),
			...(!isRoot ? { isHide: this.fb.control(false) } : {})
		}) as FormGroup<ConditionJsonForm>;
	}

	createGroupCopy(formGroup: FormGroup<ConditionJsonForm>) {
		const group = formGroup.value;
		const copyGroup = this.createGroup(false);
		copyGroup.controls.isHide.patchValue(group.isHide || false);
		copyGroup.controls.join.patchValue(group.join || 'and');

		group.conditions?.forEach(condition => {
			const copyCondition = this.createConditionFromEntity(
				condition as ConditionModel
			);
			if (copyCondition) {
				copyCondition.patchValue(
					this.conditionFilterGroups(condition as ConditionModel)
				);
				copyGroup.controls.conditions.push(copyCondition);
			}
		});
		formGroup.controls.inners?.controls?.forEach(inner => {
			copyGroup.controls.inners.push(this.createGroupCopy(inner));
		});
		return copyGroup;
	}

	createConditionFromEntity(
		condition: ConditionModel
	): FormGroup<ConditionFormTypes> | null {
		if (condition.type) {
			if (condition.type === 'message') {
				return this.createMessageWithAdd(
					condition
				) as FormGroup<ConditionFormTypes>;
			}
			if (condition.type === 'customEvent') {
				return this.createEventWithAdd(
					condition as unknown as CustomEvent
				) as FormGroup<ConditionFormTypes>;
			}
			return this.createCondition(condition.type);
		}
		return null;
	}

	conditionFilterGroups(condition: ConditionModel): ConditionModel {
		const newCondition = { ...condition };
		if (newCondition.type === 'subscription') {
			newCondition.value = condition.value?.filter(
				(item: SelectItemValue) => !item.isDeleted
			);
		}
		if (newCondition.type === 'subscriptionDate') {
			const newConditionData = { ...newCondition.subscriptionDate };
			newConditionData.groups = condition.subscriptionDate.groups?.filter(
				(item: SelectItemValue) => !item.isDeleted
			);
			newCondition.subscriptionDate = { ...newConditionData };
		}
		if (newCondition.type === 'unsubscription') {
			const newConditionData = { ...newCondition.unsubscription };
			newConditionData.groups = condition.unsubscription?.groups?.filter(
				(item: SelectItemValue) => !item.isDeleted
			);
			newCondition.unsubscription = { ...newConditionData };
		}
		return newCondition;
	}

	createConditionsFromEntity(group: ConditionGroupModel): void {
		const patchGroup = (group: ConditionGroupModel) => {
			const formGroup = this.createGroup(false);
			const { conditions } = formGroup.controls;
			group.conditions.forEach((condition: ConditionModel) => {
				const conditionForm = this.createConditionFromEntity(condition);
				if (conditionForm) {
					conditionForm.patchValue(condition);
					conditions.push(conditionForm);
				}
			});
			return formGroup as FormGroup<ConditionJsonForm>;
		};

		this.form.setControl('conditionJSON', patchGroup(group));
	}

	createCondition(type: string): FormGroup<ConditionFormTypes> | null {
		this.form.markAllAsTouched();
		switch (type) {
			// 1 - По данным пользователя
			case 'field':
				return this.createField() as FormGroup<ConditionFormTypes>;
			case 'subscription':
				return this.createSubscription() as FormGroup<ConditionFormTypes>;
			case 'subscriptionDate':
				return this.createSubscriptionDate() as FormGroup<ConditionFormTypes>;
			case 'birthdayDate':
				return this.createBirthday() as FormGroup<ConditionFormTypes>;
			case 'unsubscription':
				return this.createUnsubscription() as FormGroup<ConditionFormTypes>;
			case 'tag':
				return this.createTag() as FormGroup<ConditionFormTypes>;
			case 'currentRFM':
				return this.createCurrentRFM() as FormGroup<ConditionFormTypes>;
			case 'previousRFM':
				return this.createPreviousRFM() as FormGroup<ConditionFormTypes>;
			// 2 - По сообщениям
			case 'message-send':
				return this.createMessage(
					'send'
				) as FormGroup<ConditionFormTypes>;
			case 'message-open':
				return this.createMessage(
					'open'
				) as FormGroup<ConditionFormTypes>;
			case 'message-click':
				return this.createMessage(
					'click'
				) as FormGroup<ConditionFormTypes>;
			case 'message-bounce':
				return this.createMessage(
					'bounce'
				) as FormGroup<ConditionFormTypes>;
			case 'message-read':
				return this.createMessage(
					'read'
				) as FormGroup<ConditionFormTypes>;
			// 3 - SQL-шаблоны
			case 'sql_template':
				return this.createSqlRequest() as FormGroup<ConditionFormTypes>;
			// 4 - Поведение на сайте
			case 'visits':
				return this.createVisits(
					false
				) as FormGroup<ConditionFormTypes>;
			case 'not_visits':
				return this.createVisits(true) as FormGroup<ConditionFormTypes>;
			case 'product':
				return this.createProduct(
					false
				) as FormGroup<ConditionFormTypes>;
			case 'not_product':
				return this.createProduct(
					true
				) as FormGroup<ConditionFormTypes>;
			case 'cart':
				return this.createCart(false) as FormGroup<ConditionFormTypes>;
			case 'not_cart':
				return this.createCart(true) as FormGroup<ConditionFormTypes>;
			case 'purchase':
				return this.createPurchase(
					false
				) as FormGroup<ConditionFormTypes>;
			case 'not_purchase':
				return this.createPurchase(
					true
				) as FormGroup<ConditionFormTypes>;
			case 'favourite':
				return this.createFavourite(
					false
				) as FormGroup<ConditionFormTypes>;
			case 'not_favourite':
				return this.createFavourite(
					true
				) as FormGroup<ConditionFormTypes>;
			case 'category_open':
				return this.createCategories(
					false
				) as FormGroup<ConditionFormTypes>;
			case 'not_category_open':
				return this.createCategories(
					true
				) as FormGroup<ConditionFormTypes>;
			case 'comparison':
				return this.createComparison(
					false
				) as FormGroup<ConditionFormTypes>;
			case 'not_comparison':
				return this.createComparison(
					true
				) as FormGroup<ConditionFormTypes>;
			// 5 - Пользовательские события
			case 'event':
				return this.createUserEvent(
					false
				) as FormGroup<ConditionFormTypes>;
			case 'not_event':
				return this.createUserEvent(
					true
				) as FormGroup<ConditionFormTypes>;
			// 6 - Использование сегмента
			case 'segment':
				return null;

			// Попапы
			case 'scroll':
				return this.createScroll() as FormGroup<ConditionFormTypes>;
			case 'device':
				return this.createDevice() as FormGroup<ConditionFormTypes>;
			case 'time':
				return this.createTime() as FormGroup<ConditionFormTypes>;
			case 'visitsCount':
				return this.createCount() as FormGroup<ConditionFormTypes>;
			case 'url':
				return this.createUrl() as FormGroup<ConditionFormTypes>;
			case 'html':
				return this.createHtml() as FormGroup<ConditionFormTypes>;
			case 'localStorage':
				return this.createLocalStorage() as FormGroup<ConditionFormTypes>;
			case 'close':
				return this.createClose() as FormGroup<ConditionFormTypes>;
			case 'referrers':
				return this.createReferrers() as FormGroup<ConditionFormTypes>;
			case 'utm':
				return this.createUtm() as FormGroup<ConditionFormTypes>;
			case 'cartChange':
				return this.createCartChange() as FormGroup<ConditionFormTypes>;
			default: {
				return null;
			}
		}
	}

	createClose(): FormGroup<CommonConditionForm> {
		return this.fb.nonNullable.group({
			type: 'close'
		}) as FormGroup<CommonConditionForm>;
	}

	createDevice(): FormGroup<DeviceConditionForm> {
		return this.fb.nonNullable.group({
			type: 'device',
			value: 'desktop',
			operator: 'equal'
		}) as FormGroup<DeviceConditionForm>;
	}

	createHtml(): FormGroup<HtmlConditionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('html'),
			operator: this.fb.nonNullable.control('contain'),
			value: ['', Validators.required]
		}) as FormGroup<HtmlConditionForm>;
	}

	createLocalStorage(): FormGroup<LocalStorageConditionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('localStorage'),
			field: ['', Validators.required],
			operator: this.fb.nonNullable.control('equal'),
			value: ['', Validators.required]
		});
	}

	createCount(): FormGroup<CountConditionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('visitsCount'),
			operator: this.fb.nonNullable.control('less'),
			value: [3, Validators.required],
			actionTime: this.fb.group({
				periodOperator: this.fb.nonNullable.control('session'),
				start: [0, Validators.required],
				end: [0, Validators.required],
				periodValue: [1, Validators.required],
				units: this.fb.nonNullable.control('hour')
			})
		});
	}

	createField(): FormGroup<FieldConditionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('field'),
			field: this.fb.nonNullable.control('email'),
			field_id: null,
			operator: this.fb.nonNullable.control('empty', Validators.required),
			value: [null, Validators.required],
			isExtraField: this.fb.nonNullable.control(false),
			extraFieldType: this.fb.nonNullable.control('text'),
			start: [null, Validators.required],
			end: [null, Validators.required],
			units: this.fb.nonNullable.control('hour'),
			isHide: this.fb.nonNullable.control(false)
		}) as FormGroup<FieldConditionForm>;
	}

	createSubscription(
		groups: SelectItemWithDeleted[] = []
	): FormGroup<SubscriptionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('subscription'),
			equality: this.fb.nonNullable.control('equal'),
			value: [groups, Validators.required],
			isHide: this.fb.nonNullable.control(false)
		}) as FormGroup<SubscriptionForm>;
	}

	createSubscriptionValue(
		groups: SelectItemValue[] = []
	): FormGroup<SubscriptionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('subscription'),
			equality: this.fb.nonNullable.control('equal'),
			value: [groups, Validators.required],
			isHide: this.fb.nonNullable.control(false)
		}) as FormGroup<SubscriptionForm>;
	}

	createSubscriptionDate(
		groups: SelectItemWithDeleted[] = []
	): FormGroup<SubscriptionConditionForm> {
		return this.fb.group({
			type: 'subscriptionDate',
			isHide: this.fb.nonNullable.control(false),
			subscriptionDate: this.fb.group({
				channel: ['email', Validators.required],
				operator: ['each', Validators.required],
				groups: [groups, Validators.required],
				actionTime: this.fb.group({
					periodOperator: 'between',
					start: [null, Validators.required],
					end: [null, Validators.required],
					periodValue: [1, Validators.required],
					units: 'hour'
				})
			})
		}) as FormGroup<SubscriptionConditionForm>;
	}

	createBirthday(): FormGroup<BirthdayDateForm> {
		return this.fb.group({
			type: 'birthdayDate',
			isHide: this.fb.nonNullable.control(false),
			field_id: [null, Validators.required],
			extraFieldType: 'date',
			operator: 'today',
			month_operator: 'this_month',
			units: 'day',
			value: [1, Validators.required],
			start: [1, Validators.required],
			end: [31, Validators.required]
		}) as FormGroup<BirthdayDateForm>;
	}

	createUnsubscription(
		groups: SelectItemWithDeleted[] = []
	): FormGroup<UnsubscriptionConditionForm> {
		return this.fb.group({
			type: 'unsubscription',
			isHide: this.fb.nonNullable.control(false),
			unsubscription: this.fb.group({
				channel: ['email', Validators.required],
				operator: ['all', Validators.required],
				groups,
				actionTime: this.fb.group({
					periodOperator: 'all',
					start: [null, Validators.required],
					end: [null, Validators.required],
					periodValue: [1, Validators.required],
					units: 'hour'
				}),
				reason: ''
			})
		}) as FormGroup<UnsubscriptionConditionForm>;
	}

	createTag(tags: SelectItemValue[] = []): FormGroup<TagConditionForm> {
		return this.fb.nonNullable.group({
			type: 'tag',
			equality: 'equal',
			value: [tags, Validators.required],
			isHide: this.fb.nonNullable.control(false)
		}) as FormGroup<TagConditionForm>;
	}

	createCurrentRFM(
		rfm: Partial<SelectItemValue> | null = null
	): FormGroup<RfmConditionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('currentRFM'),
			equality: this.fb.nonNullable.control('equal'),
			value: [rfm, Validators.required],
			isHide: this.fb.nonNullable.control(false)
		}) as FormGroup<RfmConditionForm>;
	}

	createPreviousRFM(): FormGroup<RfmConditionForm> {
		return this.fb.group({
			type: this.fb.nonNullable.control('previousRFM'),
			equality: this.fb.nonNullable.control('equal'),
			value: [null, Validators.required],
			isHide: this.fb.nonNullable.control(false)
		}) as FormGroup<RfmConditionForm>;
	}

	createReferrers(): FormGroup<ReferrersConditionForm> {
		return this.fb.group({
			type: 'referrers',
			operator: 'more',
			value: [2, [Validators.required, Validators.min(0)]],
			actionTime: this.fb.group({
				periodOperator: 'session',
				start: [null, Validators.required],
				end: [null, Validators.required],
				periodValue: [1, Validators.required],
				units: 'hour'
			})
		}) as FormGroup<ReferrersConditionForm>;
	}

	createScroll(): FormGroup<ScrollConditionForm> {
		return this.fb.group({
			type: 'scroll',
			operator: 'more',
			value: [
				20,
				[Validators.required, Validators.max(100), Validators.min(0)]
			]
		}) as FormGroup<ScrollConditionForm>;
	}

	createCartChange(): FormGroup<CartChangeConditionForm> {
		return this.fb.group({
			type: 'cartChange',
			operator: 'more',
			value: [
				1,
				[Validators.required, Validators.max(9999), Validators.min(1)]
			]
		}) as FormGroup<CartChangeConditionForm>;
	}

	createUrl(): FormGroup<UrlConditionForm> {
		return this.fb.group({
			type: 'url',
			operator: 'equal',
			value: [null, Validators.required]
		}) as FormGroup<UrlConditionForm>;
	}

	createTime(): FormGroup<TimeConditionForm> {
		return this.fb.group({
			type: 'time',
			operator: 'more',
			value: [50, [Validators.required, Validators.min(0)]],
			units: 'second'
		}) as FormGroup<TimeConditionForm>;
	}

	createVisits(inverse: boolean): FormGroup<VisitsConditionForm> {
		return this.fb.group({
			type: inverse ? 'not_visits' : 'visits',
			operator: 'equal',
			parameter: 'any',
			specificOperator: 'equal',
			specificValue: '',
			value: [5, [Validators.min(0), Validators.required]],
			isHide: this.fb.nonNullable.control(false),
			actionTime: this.fb.group({
				periodOperator: this.fb.nonNullable.control('between'),
				start: [null, Validators.required],
				end: [null, Validators.required],
				periodValue: 1,
				units: 'hour'
			})
		}) as FormGroup<VisitsConditionForm>;
	}

	createUtm(): FormGroup<UtmConditionForm> {
		return this.fb.group({
			type: 'utm',
			name: 'source',
			operator: 'equal',
			value: ['', Validators.required]
		}) as FormGroup<UtmConditionForm>;
	}

	createProduct(inverse: boolean): FormGroup<ProductConditionForm> {
		return this.fb.group({
			type: inverse ? 'not_product' : 'product',
			parameter: 'quantity',
			operator: 'more',
			value: [3, Validators.required],
			isHide: this.fb.nonNullable.control(false),
			actionTime: this.fb.group({
				periodOperator: 'session',
				periodValue: [1, Validators.required],
				units: 'hour'
			})
		}) as FormGroup<ProductConditionForm>;
	}

	createCart(inverse: boolean): FormGroup<CartConditionForm> {
		return this.fb.group({
			type: inverse ? 'not_cart' : 'cart',
			parameter: 'quantity',
			value: [3, Validators.required],
			operator: 'more',
			isHide: this.fb.nonNullable.control(false),
			actionTime: this.fb.group({
				periodOperator: 'session',
				periodValue: [1, Validators.required],
				units: 'hour'
			})
		}) as FormGroup<CartConditionForm>;
	}

	createPurchase(inverse: boolean): FormGroup<PurchaseConditionForm> {
		return this.fb.group({
			type: inverse ? 'not_purchase' : 'purchase',
			parameter: 'quantity',
			aggregation: 'average',
			operator: 'more',
			value: [2, Validators.required],
			isHide: this.fb.nonNullable.control(false),
			actionTime: this.fb.group({
				periodOperator: 'all',
				periodValue: [1, Validators.required],
				units: 'hour',
				start: [null, Validators.required],
				end: [null, Validators.required]
			})
		}) as FormGroup<PurchaseConditionForm>;
	}

	createFavourite(inverse: boolean): FormGroup<FavouriteConditionForm> {
		return this.fb.group({
			type: inverse ? 'not_favourite' : 'favourite',
			parameter: 'quantity',
			operator: 'more',
			value: [2, Validators.required],
			isHide: this.fb.nonNullable.control(false),
			actionTime: this.fb.group({
				periodOperator: 'all',
				periodValue: [1, Validators.required],
				units: 'hour'
			})
		}) as FormGroup<FavouriteConditionForm>;
	}

	createCategories(inverse: boolean): FormGroup<CategoriesConditionForm> {
		return this.fb.group({
			type: inverse ? 'not_category_open' : 'category_open',
			parameter: 'id',
			value: [2, Validators.required],
			isHide: this.fb.nonNullable.control(false),
			actionTime: this.fb.group({
				periodOperator: this.fb.nonNullable.control('between'),
				periodValue: [1, Validators.required],
				units: 'hour',
				start: [null, Validators.required],
				end: [null, Validators.required]
			})
		}) as FormGroup<CategoriesConditionForm>;
	}

	createComparison(inverse: boolean): FormGroup<ComparisonConditionForm> {
		return this.fb.nonNullable.group({
			type: inverse ? 'not_comparison' : 'comparison',
			parameter: 'quantity',
			operator: 'more',
			value: [1, Validators.required],
			isHide: this.fb.nonNullable.control(false),
			actionTime: this.fb.group({
				periodOperator: this.fb.nonNullable.control('all'),
				periodValue: [1, Validators.required],
				units: 'hour'
			})
		}) as FormGroup<ComparisonConditionForm>;
	}

	createUserEvent(inverse: boolean): FormGroup<UserEventCommonForm> {
		return this.fb.nonNullable.group({
			type: 'customEvent',
			isHide: this.fb.nonNullable.control(false),
			customEvent: this.fb.group({
				type: this.fb.nonNullable.control(
					inverse ? 'not_event' : 'event'
				),
				customEvent: this.fb.group({
					id: [null, Validators.required],
					name: this.fb.nonNullable.control('', Validators.required)
				})
			})
		}) as FormGroup<UserEventCommonForm>;
	}

	createEventActionTime(): FormGroup<TimeRangeForm> {
		return this.fb.group({
			periodOperator: 'between',
			start: [null, Validators.required],
			end: [null, Validators.required],
			periodValue: [1, Validators.required],
			units: 'hour'
		}) as FormGroup<TimeRangeForm>;
	}

	createEventActionCount(): FormGroup<ActionCountForm> {
		return this.fb.nonNullable.group({
			operator: 'more',
			value: [1, Validators.required]
		});
	}

	createEventParam(): FormGroup<EventParameterForm> {
		return this.fb.group({
			id: [null, Validators.required],
			name: ['', Validators.required],
			type: ['', Validators.required],
			operator: 'equal',
			value: ['', Validators.required],
			start: [null, Validators.required],
			end: [null, Validators.required],
			units: 'hour'
		}) as FormGroup<EventParameterForm>;
	}

	createEventWithAdd(
		condition: CustomEvent
	): FormGroup<UserEventConditionForm> {
		const value = condition.customEvent;
		const form = this.createUserEvent(value?.type === 'not_event');
		const customEventForm = form.controls
			.customEvent as unknown as FormGroup<UserEventConditionForm>;
		if (value?.actionCount)
			customEventForm.setControl(
				'actionCount',
				this.createEventActionCount()
			);
		if (value?.actionTime)
			customEventForm.setControl(
				'actionTime',
				this.createEventActionTime()
			);
		if (value?.param)
			customEventForm.setControl('param', this.createEventParam());

		return form as unknown as FormGroup<UserEventConditionForm>;
	}

	createMessage(category: string = 'send'): FormGroup<MessageCommonForm> {
		return this.fb.group({
			type: 'message',
			parameter: 'any',
			isHide: this.fb.nonNullable.control(false),
			message: this.fb.group({
				channel: 'email',
				action: category,
				specificId: [null, Validators.required],
				specificName: [null, Validators.required]
			})
		}) as FormGroup<MessageCommonForm>;
	}

	// дополнительные условия для сообщений
	createMessageWithAdd(
		cond: ConditionModel
	): FormGroup<MessageConditionForm> {
		const value = cond.message;
		const form = this.createMessage(
			value?.action
		) as unknown as FormGroup<MessageConditionForm>;

		if (value?.onlyUniqMessage) {
			form.controls.message.setControl(
				'onlyUniqMessage',
				this.fb.control(value.onlyUniqMessage)
			);
		}

		if (value?.tag_items)
			form.controls.message.setControl(
				'tag_items',
				this.fb.control(value.tag_items)
			);
		if (value?.actionCount)
			form.controls.message.setControl(
				'actionCount',
				this.createMessageAddCount()
			);
		if (value?.actionTime)
			form.controls.message.setControl(
				'actionTime',
				this.createMessageActionTime()
			);

		if (value?.bounceType)
			form.controls.message.setControl(
				'bounceType',
				this.fb.control(value.bounceType)
			);

		if (value?.sendingType)
			form.controls.message.setControl(
				'sendingType',
				this.fb.control(value.sendingType)
			);

		if (value?.actionLink)
			form.controls.message.setControl('actionLink', this.createLink());

		return form;
	}

	createMessageActionTime(): FormGroup<TimeRangeForm> {
		return this.fb.group({
			periodOperator: 'between',
			start: [null, Validators.required],
			end: [null, Validators.required],
			periodValue: [1, Validators.required],
			units: 'hour'
		}) as FormGroup<TimeRangeForm>;
	}

	createMessageAddCount(): FormGroup<ActionCountForm> {
		return this.fb.nonNullable.group({
			operator: 'equal',
			value: [5, Validators.required]
		});
	}

	private createLink() {
		return this.fb.group({
			url_id: null,
			url: ['', Validators.required],
			seekBy: 'fullMatch'
		}) as FormGroup<ActionLinkForm>;
	}

	createSqlRequest(): FormGroup<SqlConditionForm> {
		return this.fb.group({
			type: 'sql_template',
			action: 'match',
			id: [null, Validators.required],
			params: [null, sqlParamsValidate],
			isHide: this.fb.nonNullable.control(false)
		}) as FormGroup<SqlConditionForm>;
	}

	hideGroup(group: FormGroup<ConditionJsonForm>, isHide: boolean): void {
		group.controls.isHide.patchValue(isHide);
		if (group.controls.inners.length)
			group.controls.inners.controls.forEach(innerGroup =>
				this.hideGroup(innerGroup, isHide)
			);
	}

	markAllAsDirty(form: FormGroup) {
		Object.values(form.controls).forEach(control => {
			control.markAsDirty();
			if ((control as FormGroup).controls) {
				this.markAllAsDirty(control as FormGroup);
			}
		});
	}

	markSubmitting() {
		this.submitting = true;
	}

	resetSubmitting() {
		this.submitting = false;
	}
}
