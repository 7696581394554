import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Injector } from '@angular/core';
import { dia, ui } from '@clientio/rappid';
import { Observable } from 'rxjs';
import { BottomBarService } from '../components/bottom-bar/services';

export interface FormService {
	form: UntypedFormGroup | FormGroup;
	firstBootChanges: Observable<void>;
}

export interface RappidInterface {
	canva: Element;
	scroller: ui.PaperScroller;
	initNavigator?: Function;
	zoom?: Function;
	bottomBarService: BottomBarService;
	injector: Injector;
	commandManager?: dia.CommandManager;
}
