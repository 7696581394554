import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { RfmDataService } from '@state-enKod/rfm';
import { AuthQuery } from '@state-auth';
import { RfmTagNamePipe } from 'app/shared/pipes/rfm-tag-name.pipe';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-rfm-condition',
	templateUrl: './rfm-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	providers: [RfmTagNamePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfmConditionComponent extends AbstractCondition implements OnInit {
	selectItems: SelectItem[] = [];

	constructor(
		private authQuery: AuthQuery,
		private rfmDataService: RfmDataService,
		public validateServices: SegmentsValidateService,
		private rfmName: RfmTagNamePipe,
		public cd: ChangeDetectorRef
	) {
		super(validateServices, cd);
	}

	get isTracking(): boolean {
		return this.authQuery.isAccessTracking;
	}

	ngOnInit(): void {
		this.isTracking ? this.setSelectItems() : this.setDefaultItems();
		this.setListener();
	}

	setDefaultItems() {
		this.selectItems = [
			{
				label: this.rfmName.transform(
					'recomm_statistics.test_not_found'
				),
				value: { sysName: '' },
				disabled: true
			}
		];
	}

	private setSelectItems() {
		this.rfmDataService
			.getSegmentNames()
			.pipe(untilDestroyed(this))
			.subscribe(rfmNames => {
				this.selectItems = this.getRfmNames(rfmNames);
				this.cd.markForCheck();
			});
	}

	private getRfmNames(names: Record<string, string>): SelectItem[] {
		return Object.keys(names).map(key => ({
			label: names[key] === '' ? this.rfmName.transform(key) : names[key],
			value: { sysName: key }
		}));
	}

	private setListener() {
		this.validateServices.checkedValidate
			.pipe(untilDestroyed(this))
			.subscribe(() => this.cd.markForCheck());
	}
}
