import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AuthQuery } from '@state-auth';

@Injectable()
export class OwnerGuard implements CanActivate, CanLoad {
	constructor(private authQuery: AuthQuery, private router: Router) {}

	canActivate(): boolean {
		const { isAdmin, isOwner } = this.authQuery;
		if (!isAdmin && !isOwner) this.router.navigateByUrl('');
		return isAdmin || isOwner;
	}

	canLoad() {
		const { isAdmin, isOwner } = this.authQuery;
		if (!isAdmin && !isOwner) this.router.navigateByUrl('');
		return isAdmin || isOwner;
	}
}
