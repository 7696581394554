<div class="message-start">
    <span class="description en-text-large">
        {{'scenario_block_message_start.description' | translate}}
    </span>

    <en-dropdown [options]="actionOptions"
                 [placeholder]="'scenario_block_message_start.choose_condition'| translate"
                 styleClass="h36"
                 class="action-options"
                 [disabled]="!isCreationMode"
                 [formControl]="actionHandling"
                 (onChange)="changeActionHandling($event.value)">
    </en-dropdown>

    <ng-container *tuiLet="selectedMessage$ | async as selectedMessage">
        <ng-container *ngIf="actionHandling.value">
            <div class="block-option">
                <span class="sub-title en-text-medium">{{'scenario_block_message_start.message' | translate}}</span>
                <div class="choose-btn"
                     *ngIf="!selectedMessage.length">
                    <ng-container *ngIf="isCreationMode">
                        <button *ngIf="actionHandling.value"
                                enButton
                                [label]="'scenario_block_message_start.choose_message'| translate"
                                class="en-button-secondary h36"
                                (click)="onDialogOpen()"></button>
                    </ng-container>
                </div>
                <ng-container *ngFor="let info of selectedMessage">
                    <en-chosen-item-info class="choose-btn"
                                         [id]="info.id"
                                         [content]="info.label"
                                         (remove)="removeMessage()"
                                         [isCreationMode]="isCreationMode"></en-chosen-item-info>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="showLinksDropdown">
        <div class="block-option">
            <span class="sub-title en-text-medium">{{'scenario_block_message_start.link' | translate}}</span>
            <en-dropdown *ngIf="showLinksDropdown"
                         [options]="linksOptions"
                         [placeholder]="'scenario_block_message_start.choose_link' | translate"
                         styleClass="h36 en-dropdown-solid"
                         class="condition__field input__small"
                         filter="true"
                         [disabled]="!isCreationMode"
                         [formControl]="linksHandling"
                         filterBy="label">
                <ng-template let-field
                             pTemplate="item">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                            {{field.label}}
                        </div>
                    </div>
                </ng-template>
            </en-dropdown>
        </div>

    </ng-container>
</div>

<div class="duplicate-handling">
    <span class="sub-title en-text-medium">{{'scenario_block_api.duplicate_processing' | translate}}</span>
    <en-dropdown [options]="selectOptions"
                 styleClass="h36"
                 [disabled]="!isCreationMode"
                 [formControl]="duplicateHandling"
                 (onChange)="changeDuplicateHandling($event.value)">
        <ng-template let-selected
                     pTemplate="selectedItem">
            <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
        </ng-template>
        <ng-template let-type
                     pTemplate="item">
            <div class="duplicate-handling__drop">
                <span class="drop-label">{{type.label | translate}}</span>
                <div class="drop-description">{{type.description | translate}}</div>
            </div>
        </ng-template>
    </en-dropdown>
</div>

<en-messages [visible]="message.length"
             class="en-message"
             [(value)]="message"></en-messages>

<en-dialog [header]="'message_list.nav_title' | translate"
           [maximizable]="true"
           styleClass="p-dialog--lg"
           [modal]="true"
           [(visible)]="visibleDialogMessage"
           appendTo="body"
           (onHide)="onDialogHide()">
    <form [formGroup]="searchForm">
        <div class="search">
            <en-search [placeholder]="'message_list.search_placeholder' | translate"
                       styleClass="h32"
                       formControlName="searchControl"
                       [refresher]="false"
                       [resetValue$]="resetValue$"></en-search>

            <section class="tab">
                <p-tabView [(activeIndex)]="activeIndex">
                    <p-tabPanel [header]="'message_list.tab_header_link_message' | translate">
                    </p-tabPanel>
                    <p-tabPanel [header]="'message_list.tab_header_link_campaing' | translate">
                    </p-tabPanel>
                </p-tabView>
            </section>

        </div>
        <tui-scrollbar class="scrollbar">
            <div class="table-wrapper">
                <en-message-table [tab]="tabs[activeIndex]"
                                  [toolsPanel]="false"
                                  [previewLink]="false"
                                  class="table__message"
                                  [enReorder]="false"
                                  [selection]="true"
                                  [notSaveInUrl]="true"
                                  [hideWhatsapp]="true"
                                  (selected)="select($event)">
                    <ng-template enTemplate="tools">
                    </ng-template>
                </en-message-table>
            </div>
        </tui-scrollbar>
    </form>
</en-dialog>