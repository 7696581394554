import { dia, V } from '@clientio/rappid';

export const blockView: dia.ElementView = dia.ElementView.extend({
	presentationAttributes: dia.ElementView.addPresentationAttributes({
		options: ['OPTIONS'],
		invalid: ['INVALID']
	}),

	renderMarkup(...args: any) {
		// @ts-ignore
		dia.ElementView.prototype.renderMarkup.apply(this, args);

		// A holder for all the options.
		this.$options = this.$('.desc-options');
		this.$invalid = this.$('.invalid');
		this.$subheader = this.$('.subheader-option');
		this.$footer = this.$('.footer-option');

		// Create an SVG element representing one option. This element will
		// be cloned in order to create more options.
		this.elOption = V(this.model.optionMarkup);
		this.elSubheader = V(this.model.subheaderMarkup);
		this.elInvalid = V(this.model.invalidMarkup);
		this.elFooter = V(this.model.footerMarkup);
		this.renderOptions();
	},

	confirmUpdate(flags: any, ...args: any): void {
		dia.ElementView.prototype.confirmUpdate.call(this, flags, args);
		if (this.hasFlag(flags, 'OPTIONS')) this.renderOptions();
		if (this.hasFlag(flags, 'INVALID')) {
			const invalid = this.model.get('invalid');
			if (invalid) this.renderInvalid();
			else this.removeInvalid();
		}
	},

	renderOptions() {
		this.$options.empty();
		this.$subheader.empty();
		this.$footer.empty();
		const { blockLayout } = this.model;

		this.pauseBlockFix();

		blockLayout.options.forEach((option: any) => {
			const className = `desc__block_${option.port}`;
			const elOptionClone = this.elOption.clone().addClass(className);
			if (!option.label) return;
			elOptionClone.attr('option-port', option.port);
			this.$options.append(elOptionClone.node);
		});

		if (blockLayout.subheader) {
			const elSubheaderClone = this.elSubheader.clone();
			this.$subheader.append(elSubheaderClone.node);
		}

		if (blockLayout.footer) {
			if (this.$footer[0].children.length) return;
			const elFooterClone = this.elFooter.clone();
			this.$footer.append(elFooterClone.node);
		}

		if (!blockLayout.subheader) {
			this.$subheader.children('.subheader-option-block').remove();
		}

		if (!blockLayout.footer) {
			this.$footer.children('.footer-option-block').remove();
		}

		// V('g').appendTo(this.paper)
		// Apply `attrs` to the newly created SVG elements.
		this.update();
	},

	renderInvalid() {
		this.$invalid.empty();
		const elInvalidClone = this.elInvalid.clone();
		this.$invalid.append(elInvalidClone.node);

		this.update();

		this.vel.addClass('invalid');
	},

	removeInvalid() {
		this.vel.removeClass('invalid');
		this.vel.removeClass('empty-block');
		this.vel.removeClass('invalid-links');
		this.$invalid.children('.invalid__tippy').remove();
		this.pauseBlockFix();
	},

	pauseBlockFix() {
		if (
			this.model.attributes.subType === 'pause' &&
			this.model.attributes.options.pause.params[0]?.unit === 'until'
		) {
			if (
				this.model.attributes.options.pause.params[0]?.value <
				Math.trunc(Date.now() / 1000)
			) {
				this.vel.addClass('invalid');
			} else {
				this.vel.removeClass('invalid');
			}
		}
	}
});
