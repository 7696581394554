import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { ActionCountForm } from '@enKod/segments/segments-form.model';

@UntilDestroy()
@Component({
	selector: 'en-action-count',
	templateUrl: './action-count.component.html',
	styleUrls: ['../../../abstract-condition.component.scss']
})
export class ActionCountComponent {
	readonly optionsActionCount: SelectItem[] = [
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		},
		{
			label: 'segment_form.options_operator_more',
			value: 'more'
		}
	];

	isHover = false;

	@Input() form: FormGroup<ActionCountForm>;

	@Input() isShowRemoveButton = false;

	@Output() remove = new EventEmitter();

	constructor(public translate: TranslateService) {}

	get countValue(): number {
		return this.form.controls.value.value;
	}

	get isEqualOperator(): boolean {
		return this.form.controls.operator.value === 'equal';
	}
}
