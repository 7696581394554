import { Injectable } from '@angular/core';
import {
	ButtonParam,
	WaTemplateDTO
} from '@enSend/message/whatsapp-template-wizard/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TemplateDataService } from '@enSend/message/whatsapp-template-wizard/services';
import { tap } from 'rxjs/operators';
import { Message } from '@enSend/message/_states/_state-message';
import { WhatsappVariableService } from '../../../services';

@Injectable()
export class PreviewContentService {
	titleTextContent$: BehaviorSubject<string> = new BehaviorSubject('');

	titleImageUrl$: BehaviorSubject<string> = new BehaviorSubject('');

	titleDocumentName$: BehaviorSubject<string> = new BehaviorSubject('');

	titleVideoUrl$: BehaviorSubject<string> = new BehaviorSubject('');

	textContent$: BehaviorSubject<string> = new BehaviorSubject('');

	footerContent$: BehaviorSubject<string> = new BehaviorSubject('');

	buttonArray$: BehaviorSubject<ButtonParam[]> = new BehaviorSubject<
		ButtonParam[]
	>([]);

	constructor(
		private variableService: WhatsappVariableService,
		private templateService: TemplateDataService
	) {}

	showStaticTemplateTitle(
		template: WaTemplateDTO,
		fromMessage = false
	): void {
		let link = '';
		if (template.params?.header_handle?.length) {
			link = template.params?.header_handle[0];
		}
		template.components.forEach(component => {
			switch (component.format) {
				case 'IMAGE':
					this.titleImageUrl$.next(link);
					break;
				case 'VIDEO':
					this.titleVideoUrl$.next(link);
					break;
				case 'DOCUMENT':
					this.titleDocumentName$.next(this.getDocumentName(link));
					break;
				case 'TEXT': {
					const titleText =
						fromMessage && template.sendingType !== 'api'
							? this.variableService.replaceVariablesInText(
									component.text || '',
									template.params.header_text || []
							  )
							: component.text || '';
					this.titleTextContent$.next(titleText);
					break;
				}
				default:
					break;
			}
		});
	}

	showStaticBodyFromMessage(
		message: Message
	): Observable<WaTemplateDTO | null> {
		const { whatsappTemplateId, whatsappParams } = message;
		if (whatsappTemplateId)
			return this.templateService.getById(whatsappTemplateId).pipe(
				tap(template => {
					const paramsWithMessageVars = template.params;
					if (whatsappParams) {
						if (whatsappParams.body_text) {
							paramsWithMessageVars.body_text =
								whatsappParams.body_text as unknown as string[][];
						}
						if (whatsappParams.header_text) {
							paramsWithMessageVars.header_text =
								whatsappParams.header_text;
						}
						if (whatsappParams.url_button_params) {
							paramsWithMessageVars.url_button_params =
								whatsappParams.url_button_params;
						}
						if (whatsappParams.header_image) {
							paramsWithMessageVars.header_handle =
								whatsappParams.header_image;
						}
						if (whatsappParams.header_handle) {
							paramsWithMessageVars.header_handle =
								whatsappParams.header_handle;
						}
						if (whatsappParams.header_document) {
							paramsWithMessageVars.header_handle =
								whatsappParams.header_document;
						}
					}
					this.showStaticBodyFromTemplate(
						{
							...template,
							...paramsWithMessageVars,
							sendingType: message.sendingType
						},
						true
					);
					this.showStaticTemplateTitle(
						{
							...template,
							...paramsWithMessageVars,
							sendingType: message.sendingType
						},
						true
					);
				})
			);
		return of(null);
	}

	showStaticBodyFromTemplate(
		template: WaTemplateDTO,
		fromMessage = false
	): void {
		const { params, components, buttons, sendingType } = template;

		const bodyText = components.filter(item => item.type === 'BODY')[0]
			?.text;
		const footerText = components.find(
			item => item.type === 'FOOTER'
		)?.text;

		if (bodyText) this.textContent$.next(bodyText);

		if (footerText) this.footerContent$.next(footerText);

		if (
			bodyText &&
			params?.body_text &&
			params?.body_text[0] &&
			fromMessage &&
			sendingType !== 'api'
		)
			this.textContent$.next(
				this.variableService.replaceVariablesInText(
					bodyText,
					params.body_text[0]
				)
			);

		if (buttons?.length) {
			this.buttonArray$.next(buttons);
		}
	}

	getDocumentName(link?: string): string {
		if (link) {
			const urlArray = link.split('/');
			return urlArray[urlArray.length - 1];
		}
		return '';
	}
}
