import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
	TuiDropdownControllerModule,
	TuiHostedDropdownModule,
	TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { IMaskModule } from 'angular-imask';
import { EnCalendarNewModule } from '../calendarNew/calendar.module';
import { EnInputModule } from '../input/input';
import { DropdownCalendarRangeComponent } from './dropdown-calendar-range.component';
import { EnCalendarRangeNewModule } from '../calendar-range-new/calendar-range.module';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnCalendarNewModule,
		TranslateModule,
		EnInputModule,
		IMaskModule,
		TuiHostedDropdownModule,
		TuiActiveZoneModule,
		TuiTextfieldControllerModule,
		TuiDropdownControllerModule,
		EnCalendarRangeNewModule,
		TranslateModule
	],
	declarations: [DropdownCalendarRangeComponent],
	exports: [DropdownCalendarRangeComponent]
})
export class EnDropdownCalendarRangeModule {}
