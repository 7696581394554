import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { DataChangeMainComponent } from './data-change-main.component';

@Injectable()
export class DataChangeMainService implements InspectorItemPlugin {
	private readonly dataChangeMainContent = new PolymorpheusComponent(
		DataChangeMainComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'dataChangeMain',
				name: 'dataChangeMain',
				content: this.dataChangeMainContent
			}
		]);
	}
}
