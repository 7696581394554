import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef
} from '@angular/core';
import {
	ControlContainer,
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';
import {
	DeletingAttachment,
	FileAttachment,
	MessagesService,
	NewAttachment,
	UploadAttachmentResponse
} from '@enSend/message/_states/_state-message';

export const ATTACHMENTS_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => AttachmentsControlComponent),
	multi: true
};

@UntilDestroy()
@Component({
	selector: 'en-attachments-control',
	templateUrl: './attachments-control.component.html',
	styleUrls: ['./attachments-control.component.scss'],
	providers: [ATTACHMENTS_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsControlComponent implements ControlValueAccessor {
	private readonly FILE_EXTENSIONS = [
		'doc',
		'docx',
		'xls',
		'xlsx',
		'csv',
		'pdf',
		'txt',
		'ppt',
		'pptx',
		'jpeg',
		'jpg',
		'png',
		'gif'
	];

	private readonly MAX_FILE_SIZE = 10 * 1024 * 1024;

	private readonly ERROR_KEYS = {
		exist: 'message_wizard_edit_step.email_attachment_error-exist',
		limit: 'message_wizard_edit_step.email_attachment_error-limit',
		format: 'message_wizard_edit_step.email_attachment_error-format',
		summary: 'message_wizard_edit_step.email_attachment_error-summary'
	};

	attachments: NewAttachment[] = [];
	message: Message[] = [
		{
			severity: 'error',
			summary: this.ERROR_KEYS.summary,
			detail: ''
		}
	];

	get attachmentDescription(): string {
		return 'message_wizard_edit_step.email_attachment_description';
	}

	constructor(
		private messageService: MessagesService,
		private translate: TranslateService,
		private controlContainer: ControlContainer,
		private cd: ChangeDetectorRef
	) {}

	onChange: Function = () => {};

	onTouch: Function = () => {};

	writeValue(value: FileAttachment[]): void {
		this.attachments = value
			? value.map(att => ({
					...att,
					isLoading: false,
					isNew: false
			  }))
			: [];
		this.clearErrorDetail();
		this.cd.markForCheck();
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}

	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		const file = input.files?.[0];

		if (!file) {
			this.clearErrorDetail();
			return;
		}

		this.clearErrorDetail();

		const validationErrorKey = this.getValidationErrorKey(file);

		if (validationErrorKey) {
			this.setErrorDetail(validationErrorKey);
			input.value = '';
			return;
		}

		const { mailId, messageId } = this.getFormIdentifiers();

		const newAttachment: NewAttachment = {
			size: 0,
			name: file.name,
			isLoading: true,
			isNew: true,
			uploadSub: undefined
		};

		this.attachments = [...this.attachments, newAttachment];
		this.emitAttachmentsChange();

		newAttachment.uploadSub = this.messageService
			.uploadAttachment({ attachment: file }, mailId, messageId)
			.pipe(untilDestroyed(this))
			.subscribe({
				next: (response: UploadAttachmentResponse) => {
					Object.assign(newAttachment, {
						name: response.name,
						size: response.size,
						link: response.link,
						isLoading: false,
						uploadSub: undefined
					});

					this.emitAttachmentsChange();
				},
				error: (err: HttpErrorResponse) => {
					this.attachments = this.attachments.filter(
						att => att !== newAttachment
					);
					this.handleUploadError(err);
					this.emitAttachmentsChange();
				}
			});
		input.value = '';
	}

	deleteAttachment(index: number): void {
		if (index < 0 || index >= this.attachments.length) return;

		const { mailId, messageId } = this.getFormIdentifiers();
		const attachmentToDelete = this.attachments[index];

		if (attachmentToDelete.isLoading && attachmentToDelete.uploadSub) {
			attachmentToDelete.uploadSub.unsubscribe();
			attachmentToDelete.uploadSub = undefined;
		}

		this.attachments.splice(index, 1);
		this.attachments = [...this.attachments];
		this.emitAttachmentsChange();

		if (!attachmentToDelete.isLoading) {
			const data: DeletingAttachment = {
				name: attachmentToDelete.name,
				mailId,
				messageId
			};
			this.messageService
				.deleteAttachment(data)
				.pipe(untilDestroyed(this))
				.subscribe();
		}
	}

	private emitAttachmentsChange(): void {
		this.onChange(
			this.attachments.map(
				({ isLoading, uploadSub, ...fileAttachment }) => fileAttachment
			)
		);

		this.cd.markForCheck();
	}

	private clearErrorDetail(): void {
		this.message = [
			{
				severity: 'error',
				summary: this.ERROR_KEYS.summary,
				detail: ''
			}
		];

		this.cd.markForCheck();
	}

	private setErrorDetail(translationKey: string): void {
		this.message = [
			{
				severity: 'error',
				summary: this.ERROR_KEYS.summary,
				detail: this.translate.instant(translationKey)
			}
		];

		this.cd.markForCheck();
	}

	private getValidationErrorKey(file: File): string | null {
		if (this.isDuplicateFile(file.name)) {
			return this.ERROR_KEYS.exist;
		}
		if (this.exceedsMaxSize(file.size)) {
			return this.ERROR_KEYS.limit;
		}
		if (!this.hasValidExtension(file.name)) {
			return this.ERROR_KEYS.format;
		}
		return null;
	}

	private handleUploadError(err: HttpErrorResponse): void {
		const errorMapping: { [key: string]: string } = {
			'attachment already exists': this.ERROR_KEYS.exist,
			'attachment size is too large': this.ERROR_KEYS.limit,
			'attachments size is too large': this.ERROR_KEYS.limit,
			'invalid attachment extension': this.ERROR_KEYS.format
		};

		const errorMessage = err?.error?.message;

		if (errorMapping[errorMessage]) {
			this.setErrorDetail(errorMapping[errorMessage]);
		}
	}

	private getFormIdentifiers(): { mailId: string; messageId: string } {
		const formGroup = this.controlContainer.control as FormGroup;

		return {
			mailId: formGroup.get('id')?.value,
			messageId: formGroup.get('messageId')?.value
		};
	}

	private isDuplicateFile(fileName: string): boolean {
		return this.attachments.some(
			att => att.name.toLowerCase() === fileName.toLowerCase()
		);
	}

	private exceedsMaxSize(fileSize: number): boolean {
		const totalSize =
			this.attachments.reduce((sum, att) => sum + att.size, 0) + fileSize;

		return totalSize > this.MAX_FILE_SIZE;
	}

	private hasValidExtension(fileName: string): boolean {
		const extension = fileName.toLowerCase().split('.').pop() || '';

		return this.FILE_EXTENSIONS.includes(extension);
	}
}
