<en-dialog [header]="'scenario_block_data_change.segments_list' | translate"
           appendTo="body"
           [(visible)]="dialogVisible"
           styleClass="p-dialog--lg"
           [modal]="true"
           (onHide)="onHideDialog()">

    <tui-scrollbar class="scrollbar">
        <div class="content">
            <en-segments-table [selectable]="true"
                               [hideFilters]="['type']"
                               [filters]="{ type: ['enSend'] }"
                               [toolsPanel]="false"
                               [maxSelectedItems]="10"
                               [selectedEntity]="selectedSegments"
                               [notSaveInUrl]="true"
                               [segmentsInNewWindow]="true"
                               [isHeaderCheckboxDisabled]="isHeaderCheckboxDisabled"
                               (selectedEntityChange)="selectedSegmentChange($event)">
                <ng-template enTemplate="tools">
                    <section class="search">
                        <en-search [placeholder]="'message_wizard_receivers_step.segment_search_placeholder' | translate"
                                   styleClass="h32"
                                   (refresh)="paginatorRef.refreshCurrentPage()"
                                   (onSearch)="paginationService.setSearchValue($event)"
                                   [refresher]="false"
                                   [resetValue$]="resetValue$"></en-search>
                    </section>
                </ng-template>
            </en-segments-table>
        </div>
    </tui-scrollbar>

    <p-footer class="footer">
        <div class="hint">
            <span class="info-icon en en-info-circle-o en-mr-2"></span>
            <span class="font-weight-500 en-mr-2">{{'scenario_block_data_change.hint_title' | translate}}</span>
            <span class="en-text-secondary">{{'scenario_block_data_change.hint_text' | translate}}</span>
        </div>
        <div>
            <button enButton
                    class="h36 en-button-secondary en-mr-4"
                    [label]="'common.cancel' | translate"
                    (click)="onHideDialog()"></button>
            <button enButton
                    class="h36"
                    [label]="'common.apply' | translate"
                    (click)="onConfirmDialog(selectedSegments)"></button>
        </div>

    </p-footer>

</en-dialog>