import { Inject, Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { snapshotManager, ID, resetStores } from '@datorama/akita';
import { BehaviorSubject, EMPTY, fromEvent, of, throwError } from 'rxjs';
import {
	filter,
	map,
	tap,
	catchError,
	switchMap,
	delay,
	finalize
} from 'rxjs/operators';

import { UserSettingsState, UserSettingsService } from '@state-user-settings';
import { jwtHelper, WINDOW } from 'utils';
import { AuthStore, AuthState } from './auth.store';
import { AuthDataService } from './auth-data.service';
import {
	AuthenticateModel,
	LoginResponse,
	JWT,
	UserModel,
	Access,
	Service
} from '../models';

@Injectable({ providedIn: 'root' })
export class AuthService {
	// Используется для определения статуса обновления токена
	// в сокет-соединениях
	updateIsActive$ = new BehaviorSubject(false);

	constructor(
		private store: AuthStore,
		private authData: AuthDataService,
		private userSettingsService: UserSettingsService,
		private router: Router,
		@Inject(WINDOW) readonly window: Window
	) {
		this.syncState();
	}

	login(model: AuthenticateModel) {
		return this.authData.login(model).pipe(
			tap(resp => {
				const { auth, settings } = this.substitudeResponse(resp);

				this.store.update(auth);
				if (settings.language)
					this.userSettingsService.updateLanguage(settings.language);
			}),
			catchError(error => {
				return throwError(error);
			})
		);
	}

	logout() {
		const { refreshToken } = this.store.getValue();

		setTimeout(() => {
			this.router.navigateByUrl('/login');
		}, 0);

		return (isDevMode() ? this.authData.logout(refreshToken) : EMPTY).pipe(
			finalize(() => {
				resetStores({
					exclude: ['user-settings', 'table-columns-settings']
				});
			})
		);
	}

	changeAccount(accountId: ID) {
		return this.authData.changeAccount(accountId).pipe(
			tap(resp => {
				const jwt: JWT = jwtHelper.decodeToken(resp.accessToken) as JWT;

				this.store.update({
					accessToken: resp.accessToken,
					currentAccountId: resp.accountId,
					expire: resp.expire,
					access: jwt.access
				});

				resetStores({
					exclude: [
						'user-settings',
						'auth',
						'layout-settings',
						'table-columns-settings'
					]
				});
			}),
			switchMap(() => this.refreshToken())
		);
	}

	// Метод обновление токена в сокете
	// В зависимости от состояния имеет 3 варианта выполнения:
	// - через REST API, при первом запуске на всех вкладках
	// - через стэйт, при втором запуске в одной вкладке(когда обновляются сразу 2 сокета)
	// - через localStorage, когда токен уже обновлен через REST в другой вкладке
	refreshTokenForSocket() {
		const TOKEN_KEY = 'enkod-token';
		const STATUS_KEY = 'enkod-loading';
		const storageRef = this.window.localStorage;
		// Условие, чтобы избежать повтоного вызова обновления токена
		// на одной вкладке, для каждого сокета
		if (!this.updateIsActive$.getValue()) {
			this.updateIsActive$.next(true);
		} else {
			// Если обновление уже запущено на другом сокете
			// После задержки токен будет взят из стейта
			return of(null).pipe(
				delay(2000),
				map(() => {
					return this.store.getValue();
				})
			);
		}

		// Проверяем что обновление токена через REST еще не запущено
		if (!storageRef.getItem(STATUS_KEY)) {
			storageRef.setItem(STATUS_KEY, 'true');

			const { refreshToken } = this.store.getValue();
			return this.authData.refreshToken(refreshToken).pipe(
				map(resp => this.substitudeResponse(resp).auth),
				tap(auth => {
					storageRef.setItem(TOKEN_KEY, auth.accessToken);
					this.store.update(auth);
					this.updateIsActive$.next(false);
				}),
				delay(5000),
				tap(() => {
					storageRef.removeItem(STATUS_KEY);
					storageRef.removeItem(TOKEN_KEY);
				}),
				catchError(error => {
					this.updateIsActive$.next(false);
					this.logout();
					return throwError(error);
				})
			);
		}

		// Если обновление токена через рест уже запущено,
		// То берем токен из localStorage
		return of(null).pipe(
			delay(2000),
			map(() => {
				const token = storageRef.getItem(TOKEN_KEY) || '';
				this.store.update({
					...this.store.getValue(),
					accessToken: token
				});
				this.updateIsActive$.next(false);
				return this.store.getValue();
			})
		);
	}

	refreshToken() {
		const { refreshToken } = this.store.getValue();
		return this.authData.refreshToken(refreshToken).pipe(
			map(resp => this.substitudeResponse(resp).auth),
			tap(auth => this.store.update(auth)),
			catchError(error => {
				this.logout();
				return throwError(error);
			})
		);
	}

	private substitudeResponse(resp: LoginResponse) {
		const { accounts } = resp.metadata;
		const jwt: JWT = jwtHelper.decodeToken(resp.accessToken) as JWT;
		const user: UserModel = {
			id: jwt.accountId,
			userId: jwt.userId,
			login: jwt.login,
			firstName: resp.metadata.firstName,
			lastName: resp.metadata.lastName,
			role: resp.metadata.role,
			status: resp.metadata.status,
			accounts: accounts.map(account => account.id)
		};

		const auth: AuthState = {
			currentAccountId: jwt.accountId,
			user,
			accessToken: resp.accessToken,
			refreshToken: resp.refreshToken,
			expire: resp.exp,
			access: jwt.access,
			accounts: accounts.map(account => ({
				...account,
				access: account.access || [],
				services: this.getServices(account.access)
			})),
			limitSettings: { baseLimit: resp.limitSettings.baseLimit },
			timeZone: resp.timeZone,
			enableCustomId: resp.enableCustomId
		};

		const settings: Partial<UserSettingsState> = {
			language: jwt.settings.language
		};
		return { accounts, auth, settings };
	}

	private syncState() {
		fromEvent<StorageEvent>(this.window, 'storage')
			.pipe(filter(event => event.key === 'enKod'))
			.subscribe(event => {
				if (event.newValue && event.oldValue) {
					snapshotManager.setStoresSnapshot(
						this.combineStores(event),
						{
							skipStorageUpdate: true
						}
					);

					const newAccountId = JSON.parse(event.newValue).auth
						.currentAccountId;
					const oldAccountId = JSON.parse(event.oldValue).auth
						.currentAccountId;

					if (newAccountId !== oldAccountId) {
						this.window.location.reload();
					}
				}

				// Обновление страницы, если на другой вкладке был изменён выбранный аккаунт
				if (this.window.location.pathname.startsWith('/login')) {
					this.router.navigateByUrl('/');
				}
			});
	}

	private getServices(access: Access[]): Service[] {
		if (!access) return [];

		const services = [] as Service[];

		if (access.includes(Access.POPUP)) services.push('enPop');
		if (
			access.includes(Access.MAIL) ||
			access.includes(Access.SMS) ||
			access.includes(Access.WEBPUSH) ||
			access.includes(Access.WHATSAPP)
		)
			services.push('enSend');
		if (access.includes(Access.ENRECOM)) services.push('enRecom');

		return services;
	}

	private combineStores(event: StorageEvent): string {
		let storage = '';

		if (event.newValue && event.oldValue) {
			const layoutStorage = JSON.parse(event.oldValue)['layout-settings'];
			const combinedStorage = {
				...JSON.parse(event.newValue),
				'layout-settings': layoutStorage
			};

			storage = JSON.stringify(combinedStorage);
		}

		return storage;
	}
}
