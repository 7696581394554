import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { UserEvent } from './event-start.model';

export interface EventStartState extends EntityState<UserEvent> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'event-start-events' })
export class EventStartStore extends EntityStore<EventStartState> {
	constructor() {
		super({});
	}
}
