import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BOOLEAN_EXTRAFIELD_OPTIONS } from '../../misc/options';

@Component({
	selector: 'en-extrafield-settings',
	templateUrl: './extrafield-settings.component.html',
	styleUrls: ['./extrafield-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtrafieldSettingsComponent {
	readonly booleanExtraFieldOptions = BOOLEAN_EXTRAFIELD_OPTIONS;

	@Input() control: UntypedFormControl;
	@Input() isCreationMode: boolean;
	@Input() type: string;

	get maskFloat() {
		return {
			mask: Number,
			thousandsSeparator: ' ',
			scale: '9',
			max: '999999999',
			min: '-999999999',
			radix: '.',
			mapToRadix: [',']
		};
	}

	get showCalendarSettings() {
		return this.type === 'dateTime' || this.type === 'date';
	}
}
