import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { WeekdaySelectorComponent } from './weekday-selector.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, TranslateModule],
	declarations: [WeekdaySelectorComponent],
	exports: [WeekdaySelectorComponent]
})
export class WeekdaySelectorModule {}
