import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LogoutGuard, TranslationLoaderGuard } from 'guards';

const routes: Routes = [
	{
		path: 'login',
		canActivate: [LogoutGuard, TranslationLoaderGuard],
		loadChildren: () =>
			import('./layouts/auth-layout/auth-layout.module').then(
				m => m.AuthLayoutModule
			)
	},
	{
		path: '',
		canActivate: [AuthGuard, TranslationLoaderGuard],
		loadChildren: () =>
			import('./layouts/app-layout/app-layout.module').then(
				m => m.AppLayoutModule
			)
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [TranslationLoaderGuard]
})
export class AppRoutingModule {}
