import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { MessageStartComponent } from './message-start.component';

@Injectable()
export class MessageStartService implements InspectorItemPlugin {
	private readonly messageStartContent = new PolymorpheusComponent(
		MessageStartComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'messageStart',
				name: 'messageStart',
				content: this.messageStartContent
			}
		]);
	}
}
