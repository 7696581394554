import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { ApiQueryComponent } from './api-query.component';

@Injectable()
export class ApiQueryService implements InspectorItemPlugin {
	private readonly apiQueryContent = new PolymorpheusComponent(
		ApiQueryComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'apiQuery',
				name: 'apiQuery',
				content: this.apiQueryContent
			}
		]);
	}
}
