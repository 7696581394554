import {
	Component,
	OnInit,
	forwardRef,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	Inject,
	Optional,
	Input
} from '@angular/core';
import {
	NG_VALUE_ACCESSOR,
	ControlValueAccessor,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormArray,
	AbstractControl,
	ValidatorFn
} from '@angular/forms';

import { switchMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SUBJECT_TOKEN } from '@enkod-core/tokens';
import { shake } from './animations';

export const WEEKDAY_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => WeekdaySelectorComponent),
	multi: true
};

@UntilDestroy()
@Component({
	selector: 'en-weekday-selector',
	template: `
		<div class="check-group">
			<ul class="check-group__list" [formGroup]="weekdaysFormArray">
				<li
					*ngFor="let order of weekdaysFormArray.controls; index as i"
				>
					<label class="check">
						<input
							[attr.disabled]="disabled"
							class="visually-hidden"
							[ngClass]="
								disabled
									? 'check__input-disabled'
									: 'check__input'
							"
							type="checkbox"
							[formControlName]="i"
						/>
						<div
							class="check__box"
							[@shakeIt]="shake"
							(@shakeIt.done)="shakeDone = true; shake = false"
						>
							{{ weekdayItem[i].label | translate }}
						</div>
					</label>
				</li>
			</ul>
		</div>
	`,
	styleUrls: ['./weekday-selector.component.scss'],
	providers: [WEEKDAY_VALUE_ACCESSOR],
	animations: [shake],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeekdaySelectorComponent implements OnInit, ControlValueAccessor {
	constructor(
		private fb: UntypedFormBuilder,
		public cd: ChangeDetectorRef,
		@Optional()
		@Inject(SUBJECT_TOKEN)
		public forceRerender$: Subject<void>
	) {}

	value: number[];

	weekdaysFormArray: UntypedFormArray;

	shakeDone = true;

	shake = false;

	@Input() disabled = null;

	weekdayItem: Array<{ label: string; value: number }> = [
		{ label: 'calendar.days_min_monday', value: 1 },
		{
			label: 'calendar.days_min_tuesday',
			value: 2
		},
		{
			label: 'calendar.days_min_wednesday',
			value: 3
		},
		{
			label: 'calendar.days_min_thursday',
			value: 4
		},
		{ label: 'calendar.days_min_friday', value: 5 },
		{
			label: 'calendar.days_min_saturday',
			value: 6
		},
		{ label: 'calendar.days_min_sunday', value: 7 }
	];

	@Output() shakeStops = new EventEmitter();

	onModelChange: Function = () => {};

	onModelTouched: Function = () => {};

	ngOnInit() {
		this.init();
		this.setupFormListeners();
	}

	writeValue(value: number[]): void {
		if (!value) return;

		this.value = value;

		this.value.forEach(number => {
			this.weekdaysFormArray
				.at(number - 1)
				.patchValue(true, { emitEvent: false });
		});

		this.cd.markForCheck();
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	private init() {
		this.weekdaysFormArray = this.fb.array(
			[...this.controlArrayInit()],
			validate as ValidatorFn
		);
	}

	private controlArrayInit(): AbstractControl[] {
		const controls: AbstractControl[] = [];
		this.weekdayItem.forEach(() => controls.push(new UntypedFormControl(false)));
		return controls;
	}

	private setupFormListeners() {
		this.forceRerender$
			?.pipe(
				untilDestroyed(this),
				switchMap(() =>
					of(this.shakeDone && !this.weekdaysFormArray.valid)
				)
			)
			.subscribe(value => {
				this.shake = value;
				this.cd.markForCheck();
			});
		this.weekdaysFormArray.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				const arr: any[] = [];
				value.forEach((number: any, index: number) => {
					if (number) arr.push(index + 1);
				});
				this.onModelChange(arr);
			});
	}
}

function validate(control: UntypedFormControl) {
	// eslint-disable-next-line no-restricted-syntax
	for (const element of control.value) {
		if (element) return null;
	}

	return {
		required: true
	};
}
