<div *tuiLet="selectedViewMail$ | async as selectedViewMail"
     class="container">
    <div class="container__header">
        <en-view-buttons *ngIf="messageTypeMail"
                         [options]="optionsViewMail"
                         [currentValue]="selectedViewMail"
                         (onChange)="selectedViewMail$.next($event)"></en-view-buttons>

        <en-selectbutton *ngIf="messageTypePush"
                         styleClass="h32 icon20"
                         [(ngModel)]="selectedViewPush"
                         [options]="optionsViewPush"></en-selectbutton>

        <en-selectbutton *ngIf="messageTypeMobPush"
                         styleClass="h32 icon20"
                         [(ngModel)]="selectedViewMobilePush"
                         [options]="optionsViewMobilePush"></en-selectbutton>

        <en-selectbutton *ngIf="messageTypeSms"
                         styleClass="h32 icon20"
                         [(ngModel)]="selectedViewMobileSms"
                         [options]="optionsViewMobileSms"></en-selectbutton>

        <ng-container *ngTemplateOutlet="viewTemplate"></ng-container>

        <ng-container *ngTemplateOutlet="toolsTemplate"></ng-container>
    </div>
    <div [ngSwitch]="messageType"
         class='container__content'
         #content
         @opacity>
        <div *ngIf="isOnHover && visibleHoverButtons"
             class='container__buttons'>
            <button *ngIf="fullScreenButtonAvailable"
                    enButton
                    class="en-button-solid tool"
                    [class.en-button-border-radius]="editButtonAvailable"
                    icon="en en-expand"
                    (click)="fullscreenVisible = true"></button>
            <button *ngIf="editButtonAvailable"
                    enButton
                    class="en-button-solid tool"
                    [class.en-button-border-radius]="editButtonAvailable"
                    icon="en en-pencil-o"
                    (click)="redirectEditMessage()"></button>
            <button *ngIf="htmlEditorAvailable"
                    enButton
                    class="en-button-solid tool margin-top"
                    icon="en en-editor-html"
                    [enTooltip]="'message_wizard_edit_step.tooltip_preview_html_button' | translate"
                    tooltipPosition="right"
                    (click)="redirectEditMessage('HTML')"></button>
            <button *ngIf="textVersionAvailable"
                    enButton
                    class="en-button-solid tool margin-top"
                    icon="en en-text-type"
                    [enTooltip]="'message_wizard_edit_step.tooltip_preview_text_button' | translate"
                    tooltipPosition="right"
                    (click)="openTextVersionDialog.emit()"></button>
            <button *ngIf="ampVersionAvailable"
                    enButton
                    class="en-button-solid tool margin-top"
                    icon="en en-amp-lighting"
                    [enTooltip]="'message_wizard_edit_step.tooltip_preview_amp_button' | translate"
                    tooltipPosition="right"
                    (click)="openAmpDialog.emit()"></button>  
            <!-- <button enButton
                    *ngIf="chamaileonEditorAvailable"
                    class="en-button-solid tool margin-top"
                    icon="en en-editor-block"
                    [enTooltip]="'message_wizard_edit_step.tooltip_preview_chamaileon_button' | translate"
                    tooltipPosition="right"
                    (click)="redirectEditMessage('chamaileon')"></button> -->
            <button *ngIf="stripoEditorAvailable"
                    enButton
                    class="en-button-solid tool margin-top"
                    [useTuiSvg]="true"
                    [enTooltip]="'message_wizard_edit_step.tooltip_preview_stripo_button' | translate"
                    tooltipPosition="right"
                    (click)="redirectEditMessage('Stripo')">
                <tui-svg src="enIconStripo"></tui-svg>
            </button>
            <button *ngIf="saveAsTemplateButtonAvailable"
                    enButton
                    class="en-button-solid tool margin-top"
                    icon="en en-save-o"
                    [enTooltip]="'message_wizard_edit_step.tooltip_preview_save_as_template' | translate"
                    tooltipPosition="right"
                    (click)="openTemplateDialog.emit()">
            </button>
            <button *ngIf="trashButtonAvailable"
                    enButton
                    iconColor="red-50-button-icon"
                    class="en-button-solid tool margin-top"
                    icon="en en-trash"
                    (click)="this.resetCurrentHtml.emit()"></button>

        </div>


        <ng-template ngSwitchCase="mail">
            <en-mail-preview class="container__preview"
                             [createButtonTemplate]="createButtonTemplate"
                             [previewType]="previewType"
                             [ngClass]="{'border-none': bordersNone}"
                             [bordersNone]="bordersNone"
                             [clicksMapAvailable]="clicksMapAvailable"
                             [view]="selectedViewMail"
                             [optionsView]="optionsViewMail"
                             [dataGroup]="preview"
                             [(fullscreenVisible)]="fullscreenVisible"
                             (openEditor)="openEditor.emit()"
                             (resetCurrentHtml)="resetCurrentHtml.emit()"
                             (changeView)="selectedViewMail$.next($event)"></en-mail-preview>
        </ng-template>
        <ng-template ngSwitchCase="push">
            <en-push-preview class="container__preview"
                             [ngClass]="{'border-none': bordersNone}"
                             [bordersNone]="bordersNone"
                             [view]="selectedViewPush"
                             [preview]="preview"></en-push-preview>
        </ng-template>
        <ng-template ngSwitchCase="mobPush">
            <tui-scrollbar [hidden]="invisibleScroll" 
                           [ngClass]="{'scrollbar': scrollable}">
                <en-mobile-push-preview [placeholder]="placeholder"
                                        [preview]="preview"
                                        [view]="selectedViewMobilePush"></en-mobile-push-preview>
            </tui-scrollbar>
        </ng-template>
        <ng-template ngSwitchCase="sms">
            <tui-scrollbar [hidden]="invisibleScroll"
                           [ngClass]="{'scrollbar': scrollable}">
                <en-sms-preview [placeholder]="placeholder"
                                [preview]="preview.body | previewUrlParams: preview.urlParams | shortLink: preview.toShortLink"
                                [view]="selectedViewMobileSms"></en-sms-preview>
            </tui-scrollbar>
        </ng-template>
        <ng-template ngSwitchCase="whatsapp">
            <tui-scrollbar [hidden]="invisibleScroll"
                           [ngClass]="{'scrollbar': scrollable}">
                <en-whatsapp-preview [isTemplateWizard]="isTemplateWizard"   
                                     [staticMessage]="preview"></en-whatsapp-preview>
            </tui-scrollbar>     
        </ng-template>
    </div>
</div>