import {
	Component,
	Output,
	EventEmitter,
	ChangeDetectionStrategy
} from '@angular/core';

@Component({
	selector: 'en-add-only-uniq-message',
	templateUrl: './add-only-uniq-message.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddOnlyUniqMessageComponent {
	isHover = false;

	@Output() remove = new EventEmitter();
}
