<en-dialog [header]="'message_list.nav_title' | translate"
           [maximizable]="true"
           [style]="{ width: '90%', maxWidth: '108rem', minWidth: '50rem', height: '85vh'}"
           [modal]="true"
           [(visible)]="visible"
           (onHide)="this.hide.emit()">
    <form [formGroup]="searchForm">
        <div class="search">
            <en-search [placeholder]="'message_list.search_placeholder' | translate"
                       styleClass="h32"
                       formControlName="searchControl"
                       [refresher]="false"></en-search>

            <section class="tab">
                <p-tabView [(activeIndex)]="activeIndex">
                    <p-tabPanel [header]="'message_list.tab_header_link_message' | translate">
                    </p-tabPanel>
                    <p-tabPanel [header]="'message_list.tab_header_link_campaing' | translate">
                    </p-tabPanel>

                </p-tabView>
            </section>

        </div>
        <tui-scrollbar class="scroll">
            <div class="table-wrapper">
                <en-message-table [tab]="tabs[activeIndex]"
                                [toolsPanel]="false"
                                [previewLink]="false"
                                class="table__message"
                                [enReorder]="false"
                                [selection]="true"
                                (selected)="select($event);">
                    <ng-template enTemplate="tools">
                    </ng-template>
                </en-message-table>
            </div>
        </tui-scrollbar>
    </form>
</en-dialog>
