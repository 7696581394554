/* eslint-disable import/no-cycle */
// eslint-disable-next-line max-classes-per-file
import { dia, shapes } from '@clientio/rappid';
import { Option, StatusType } from '@enSend/_shared/models';
import { RappidService } from '../../services/rappid.service';
import { scenarioNamespace, chatbotNamespace } from './app.shapes.ts';
import './stencil.shapes';

export * from './app.shapes.ts';
export { initStencilShapes } from './stencil.shapes';

export function initBlocks() {
	Object.assign(shapes, {
		...scenarioNamespace,
		...chatbotNamespace
	});
}

declare module '@clientio/rappid' {
	namespace shapes {
		class Base extends dia.Element {
			static fromStencilShape(element: dia.Element): Base;
			checkValidity(service: RappidService, status?: StatusType): boolean;
		}
		class Block extends Base {
			blockLayout: {
				subheader: string;
				options: string[];
				footer: string;
			};
			addOption(option: Option): void;
			onChangeOptions(): void;
			checkLinksValidity(
				service: RappidService,
				status?: StatusType
			): boolean;
			checkBlockValidity(status?: StatusType): boolean;
			generatePortId(): string;
		}
		namespace scenario {
			class StartBlock extends Block {}
			class MainBlock extends Block {}
			class SeveralPortsBlock extends Block {}
			class DistributionBlock extends Block {}
			class MessageStartBlock extends Block {}
			class ExtraFieldStartBlock extends Block {}
			class EventStartBlock extends Block {}
			class DataChangeMainBlock extends Block {}
			class ApiQueryBlock extends Block {}
			class EndBlock extends Block {}
		}

		namespace chatbot {
			class Start extends Block {}
			class Message extends Block {}
		}
	}
}
