<span class="description en-text-medium">
    {{'scenario_block_mailing_groups.description' | translate}}
</span>


<form [formGroup]="form">
    <div class="en-field">
        <en-dropdown [options]="selectOptions.subscriptionType"
                     styleClass="h36"
                     formControlName="subscriptionType"
                     [disabled]="!isCreationMode"
                     [placeholder]="'scenario_block_mailing_groups.subscription_type_placeholder' | translate">
            <ng-template let-selected
                         pTemplate="selectedItem">
                <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
            </ng-template>
            <ng-template let-type
                         pTemplate="item">
                <div class="duplicate-handling__drop">
                    <span class="drop-label">{{type.label | translate}}</span>
                    <div class="drop-description">{{type.description | translate}}</div>
                </div>
            </ng-template>
        </en-dropdown>
    </div>

    <!-- Группа рассылок -->

    <ng-container *ngIf="showMailingGroupButton">
        <ng-container *tuiLet="selectedMailingGroup$ | async as selectedMailingGroup">
            <div class="select-option">
                <span class="en-text-medium option-header">
                    {{ 'scenario_block_data_change_main.mailing_group_option' | translate }}
                </span>
                <div class="choose-btn"
                     *ngIf="!selectedMailingGroup.length">
                    <ng-container *ngIf="isCreationMode">
                        <button (click)="visibleMailingGroupDialog = true"
                                enButton
                                [label]="'scenario_block_mailing_groups.choose_mail_group' | translate"
                                class="en-button-secondary h36"></button>
                        <en-error formControlName="mailingGroup"></en-error>
                    </ng-container>
                </div>
                <ng-container *ngFor="let info of selectedMailingGroup">
                    <en-chosen-item-info [id]="info.id"
                                         [content]="info.label"
                                         (remove)="removeMailingGroup()"
                                         [isCreationMode]="isCreationMode"></en-chosen-item-info>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

    <!-- Сегмент -->

    <ng-container *ngIf="showSegmentButton">
        <ng-container *tuiLet="selectedSegment$ | async as selectedSegment">
            <div class="select-option">
                <span class="en-text-medium option-header">
                    {{'scenario_block_api.segment_title' | translate}}
                </span>
                <div class="choose-btn"
                     *ngIf="!selectedSegment.length">
                    <ng-container *ngIf="isCreationMode">
                        <button (click)="visibleSegmentDialog = true"
                                enButton
                                [label]="'scenario_block_api.choose_segment' | translate"
                                class="en-button-secondary h36"></button>
                        <en-error [formControl]="segmentControl"></en-error>
                    </ng-container>
                </div>
                <ng-container *ngFor="let info of selectedSegment">
                    <en-chosen-item-info [id]="info.id"
                                         [content]="info.label"
                                         (remove)="removeSegment()"
                                         [isCreationMode]="isCreationMode"></en-chosen-item-info>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</form>

<en-choose-mailing-group-dialog *ngIf="visibleMailingGroupDialog"
                                [dialogVisible]="visibleMailingGroupDialog"
                                (onHide)="visibleMailingGroupDialog=false"
                                (onConfirm)="selectMailingGroup($event)"></en-choose-mailing-group-dialog>


<en-choose-segment-dialog *ngIf="visibleSegmentDialog"
                          [dialogVisible]="visibleSegmentDialog"
                          (onHide)="visibleSegmentDialog=false"
                          (onConfirm)="selectSegment($event)"></en-choose-segment-dialog>

<div class="duplicate-handling">
    <span class="option-header en-text-medium">
        {{'scenario_block_api.duplicate_processing' | translate}}</span>
    <en-dropdown [options]="selectOptions.duplicateHandling"
                 styleClass="h36"
                 class="duplicate-handling__dropdown"
                 [disabled]="!isCreationMode"
                 [formControl]="duplicateHandling"
                 (onChange)="changeDuplicateHandling($event.value)">
        <ng-template let-selected
                     pTemplate="selectedItem">
            <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
        </ng-template>
        <ng-template let-type
                     pTemplate="item">
            <div class="duplicate-handling__drop">
                <span>{{type.label | translate}}</span>
                <div class="drop-description">{{type.description | translate}}</div>
            </div>
        </ng-template>
    </en-dropdown>
</div>

<en-messages [visible]="message.length"
             class="en-message"
             [(value)]="message"></en-messages>
