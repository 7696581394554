import {
	Component,
	ChangeDetectionStrategy,
	Inject,
	Input,
	EventEmitter,
	Output,
	OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaginatorPlugin } from '@datorama/akita';
import { UrlSaverService } from '@enkod-core/services';
import { PAGINATOR_PLUGIN_TOKEN } from '@enkod-core/tokens';
import { MAILING_GROUP_PAGINATION_PROVIDERS } from '@enKod/mailing-groups/mailing-groups-list/pagination-provider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MailingGroup } from '@state-enKod/mailing-groups';
import { Segment } from '@state-enKod/segments';
import { PaginationService, SearchService } from 'ui-lib';

@UntilDestroy()
@Component({
	selector: 'en-choose-mailing-group-dialog',
	templateUrl: './choose-mailing-group-dialog.component.html',
	styleUrls: ['./choose-mailing-group-dialog.component.scss'],
	providers: [MAILING_GROUP_PAGINATION_PROVIDERS],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseMailingGroupDialogComponent implements OnInit {
	searchControl: UntypedFormControl;

	constructor(
		private searchService: SearchService,
		@Inject(PAGINATOR_PLUGIN_TOKEN)
		public readonly paginatorRef: PaginatorPlugin<MailingGroup>,
		public paginationService: PaginationService,
		private urlService: UrlSaverService
	) {}

	@Input() dialogVisible: boolean;

	@Output() onHide = new EventEmitter();
	@Output() onConfirm = new EventEmitter();

	ngOnInit(): void {
		this.searchControl = new UntypedFormControl('');
		this.searchControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => this.searchService.setSearchValue(value));
	}

	onHideDialog() {
		this.onHide.emit();
		this.urlService.setParamsToUrl({});
	}

	onConfirmDialog(item: Segment) {
		this.onConfirm.emit(item);
		this.urlService.setParamsToUrl({});
	}
}
