import { SelectItemDesctiption } from '@enkod-core/interfaces';

export const SUBSCRIPTION_TYPE_OPTIONS: SelectItemDesctiption[] = [
	{
		label: 'scenario_block_mailing_groups.specific_mailing_group',
		value: 'specificGroup',
		description:
			'scenario_block_mailing_groups.specific_mailing_group_description'
	},
	{
		label: 'scenario_block_mailing_groups.specific_mailing_group_with_segment',
		value: 'specificGroupWithSegment',
		description:
			'scenario_block_mailing_groups.specific_mailing_group_with_segment_description'
	},
	{
		label: 'scenario_block_mailing_groups.any_mailing_group_with_segment',
		value: 'anyGroupWithSegment',
		description:
			'scenario_block_mailing_groups.any_mailing_group_with_segment_description'
	},
	{
		label: 'scenario_block_mailing_groups.mobPush',
		value: 'mobPush',
		description: 'scenario_block_mailing_groups.mobPush_description'
	}
];
