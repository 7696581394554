import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EnSelectButtonModule } from 'ui-lib';
import { PreviewButtonsComponent } from './preview-buttons.component';

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, EnSelectButtonModule],
	declarations: [PreviewButtonsComponent],
	exports: [PreviewButtonsComponent]
})
export class PreviewButtonsModule {}
