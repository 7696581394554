import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopupPreviewComponent } from './popup-preview.component';
import { PreviewButtonsModule } from '../preview-buttons/preview-buttons.module';
import { PreviewsContainerModule } from './previews/previews-container/previews-container.module';

@NgModule({
	imports: [CommonModule, PreviewButtonsModule, PreviewsContainerModule],
	declarations: [PopupPreviewComponent],
	exports: [PopupPreviewComponent]
})
export class PopupPreviewModule {}
