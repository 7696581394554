import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PeopleCountItem } from './people-count.model';

export interface PeopleCountState extends EntityState<PeopleCountItem> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'people-count'
})
export class PeopleCountStore extends EntityStore<PeopleCountState> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {
		super();
	}
}
