<ng-conainer *ngIf="isEdit">
    <ng-container [ngSwitch]="typeView">
        <ng-template ngSwitchCase="notNumber">
            <span class="editable-view h28"
                  [class.editor__empty-field]="!values">
                <span class="text-overflow">
                    {{ values || ('client_fields.field_empty' | translate) }}
                </span>
            </span>
        </ng-template>
        <ng-template ngSwitchCase="number">
            <span class="editable-view h28"
                  [class.editor__empty-field]="values === null || values === undefined || values === ''  ">
                <span class="text-overflow">
                    {{ values !== '' && values !== null && values !== undefined ? (values?.toString() | imask:pipe) :
                    'client_fields.field_empty' |
                    translate}}
                </span>
            </span>
        </ng-template>
    </ng-container>
</ng-conainer>

<ng-container [ngSwitch]="type"
              *ngIf="!isEdit">
    <ng-template ngSwitchCase="email">
        <div class="editor__wrapper">
            <span class="en-input-success">
                <input #input
                       enInput
                       type="text"
                       class="h28"
                       [formControl]="inputControl"
                       (blur)="cancelEditMainFIeld()"
                       (keyup.enter)="checkEmail()"
                       (input)="isViewSaveButton = true" />
                <button *ngIf="isViewSaveButton"
                        class="en en-done-circle-o"
                        (mousedown)="checkEmail()"></button>
            </span>
        </div>

        <ng-container *ngIf="inputControl.touched">
            <en-small [visible]="inputControl.hasError('required')"
                      severity="validate"
                      [detail]="'client_fields.field_required' | translate ">
            </en-small>
            <en-small [visible]="inputControl.hasError('invalidEmail')"
                      severity="validate"
                      [detail]="'client_fields.email_invalid' | translate ">
            </en-small>
            <en-small [visible]="inputControl.hasError('isAleradyExist')"
                      severity="validate"
                      [detail]="'client_fields.email_exist' | translate ">
            </en-small>
        </ng-container>

    </ng-template>

    <ng-template ngSwitchCase="phone">
        <span class="en-input-success">
            <input #input
                   enInput
                   class="h28"
                   [imask]="mask"
                   [unmask]="true"
                   [formControl]="inputControl"
                   (blur)="cancelEditMainFIeld()"
                   (keyup.enter)="checkPhone()"
                   (input)="isViewSaveButton = true" />
            <button *ngIf="isViewSaveButton"
                    class="en en-done-circle-o"
                    (mousedown)="checkPhone()"></button>
        </span>

        <ng-container *ngIf="inputControl.touched">
            <en-small [visible]="inputControl.hasError('invalidPhone')"
                      severity="validate"
                      [detail]="'client_fields.phone_invalid' | translate ">
            </en-small>
            <en-small [visible]="inputControl.hasError('isAlreadyExist')"
                      severity="validate"
                      [detail]="'client_fields.phone_exist' | translate ">
            </en-small>
        </ng-container>
    </ng-template>

    <ng-template ngSwitchCase="text">
        <span class="en-input-success">
            <input #input
                   enInput
                   class="h28"
                   [(ngModel)]="newValue"
                   (keyup.enter)="saveTextValue()"
                   (blur)="cancelEditText()" />
            <button class="en en-done-circle-o"
                    (mousedown)="saveTextValue()"></button>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="date">
        <en-dropdown-calendar-new #calendar
                                  class="calendar"
                                  [formControl]="dateControl"
                                  [type]="'date'"
                                  [autofocus]="true"
                                  [focusoutEnable]="false"
                                  (onClose)="closedEditable()"
                                  (keyup.enter)="updateDate()"></en-dropdown-calendar-new>
    </ng-template>

    <ng-template ngSwitchCase="dateTime">
        <en-dropdown-calendar-new #calendar
                                  class="calendar"
                                  [formControl]="dateControl"
                                  [type]="'dateTime'"
                                  [autofocus]="true"
                                  [focusoutEnable]="false"
                                  (onClose)="closedEditable()"
                                  (keyup.enter)="updateDate()"></en-dropdown-calendar-new>
    </ng-template>

    <ng-template ngSwitchCase="bool"
                 class="editor__wrapper">
        <en-dropdown #enDropDown
                     styleClass="en-dropdown-solid h28"
                     appendTo="body"
                     optionLabel="name"
                     [options]="options"
                     [isOpenedOverlay]="true"
                     [(ngModel)]="selectedBoolean"
                     (onChange)="selectBoolean($event)"
                     (onHide)="closedEditable()">
        </en-dropdown>
    </ng-template>

    <ng-template [ngSwitchCase]="type === 'float' ? 'float' : 'number'"
                 class="editor__wrapper">
        <span class="en-input-success">
            <input #input
                   enInput
                   class="h28"
                   [imask]="mask"
                   [unmask]="true"
                   [(ngModel)]="newValue"
                   (blur)="closedEditable()"
                   (keyup.enter)="saveNumberValue()" />
            <button class="en en-done-circle-o"
                    (mousedown)="saveNumberValue()"></button>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="list"
                 class="editor__wrapper">

        <en-dropdown #enDropDown
                     styleClass="en-dropdown-solid h28"
                     appendTo="body"
                     optionLabel="name"
                     [options]="allListOptions"
                     [isOpenedOverlay]="true"
                     [filter]="true"
                     filterBy="value.value"
                     [emptyFilterMessage]="'common.empty_options' | translate"
                     [filterPlaceholder]="'table_detail.search_placeholder' | translate"
                     (onChange)="selectListItem($event)"
                     (onHide)="closedEditable()"
                     [(ngModel)]="selectedListItem">
            <ng-template let-option
                         pTemplate="item">
                <div class="dropdown__item">
                    <div class="dropdown__item-label">{{option.label}}</div>
                </div>
            </ng-template>
        </en-dropdown>
    </ng-template>
</ng-container>