import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	Inject,
	ChangeDetectorRef,
	OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VALIDATION_TOKEN } from '@enkod-core/tokens';

@UntilDestroy()
@Component({
	selector: 'en-link-button',
	templateUrl: './link-button.component.html',
	styleUrls: ['./link-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkButtonComponent implements OnInit {
	@Input() index: number;
	@Input() group: FormGroup;

	@Output() deleteButton = new EventEmitter();

	constructor(
		@Inject(VALIDATION_TOKEN)
		protected readonly checkValidate$: Subject<boolean>,
		private cd: ChangeDetectorRef
	) {}

	get textControl(): FormControl {
		return this.group.controls.label as FormControl;
	}

	get urlControl(): FormControl {
		return this.group.controls.url as FormControl;
	}

	ngOnInit(): void {
		this.checkValidate$
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}
}
