import {
	ChangeDetectionStrategy,
	Component,
	Output,
	EventEmitter,
	Input
} from '@angular/core';

@Component({
	selector: 'en-list-edit-confirm-dialog',
	templateUrl: './list-edit-confirm-dialog.component.html',
	styleUrls: ['./list-edit-confirm-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListEditConfirmDialogComponent {
	@Input() visible: boolean;

	@Output() hide = new EventEmitter<void>();
	@Output() accept = new EventEmitter<void>();
}
