<form [formGroup]="form">
    <span class="en-text-medium option-header">
        {{ 'chatbot_start_block.start_condition' | translate }}
    </span>
    <!-- Раздисеблить когда будут доступны другие опции -->
    <en-dropdown class="en-field-large"
                 [disabled]="true"
                 styleClass="h36 en-dropdown-solid"
                 [options]="triggerCondition"
                 formControlName="triggerCondition">
    </en-dropdown>
</form>