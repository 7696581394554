import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';

export const ENSEND_MENU_OPTIONS: MultiMenuItem[] = [
	{
		label: 'segment_menu_items.conditions_item',
		isTitle: true
	},
	{
		label: 'segment_form.menu_condition_data',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'segment_form.menu_condition_data_field',
				value: 'field'
			},
			{
				label: 'segment_form.menu_condition_data_subscription',
				value: 'subscription'
			},
			{
				label: 'segment_form.options_data_subscription_date',
				value: 'subscriptionDate'
			},
			{
				label: 'segment_form.options_data_birthday_date',
				value: 'birthdayDate'
			},
			{
				label: 'segment_form.options_data_unsubscribe',
				value: 'unsubscription'
			},
			{
				label: 'segment_form.options_data_tag',
				value: 'tag'
			},
			{
				label: 'RFM',
				value: 'currentRFM'
			}
		]
	},
	{
		label: 'segment_form.menu_condition_message',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'segment_form.menu_condition_message_send',
				value: 'message-send'
			},
			{
				label: 'segment_form.menu_condition_message_open',
				value: 'message-open'
			},
			{
				label: 'segment_form.menu_condition_message_click',
				value: 'message-click'
			},
			{
				label: 'segment_form.menu_condition_message_error',
				value: 'message-bounce'
			},
			{
				label: 'segment_message_options.read_option',
				value: 'message-read'
			}
		]
	},
	{
		label: 'segment_form.menu_condition_data_tables',
		value: 'dataTable',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'segment_form.menu_condition_sql_request',
				value: 'sql_template'
			}
		]
	},
	{
		label: 'segment_menu_items.behavior_site_item',
		value: 'tracking',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'segment_options.product_options',
				value: 'product'
			},
			{
				label: 'segment_options.categories_options',
				value: 'category_open'
			},
			{
				label: 'segment_options.cart_options',
				value: 'cart'
			},
			{
				label: 'segment_options.purchase_options',
				value: 'purchase'
			},
			{
				label: 'segment_options.favourite_options',
				value: 'favourite'
			},
			{
				label: 'segment_options.comparison_options',
				value: 'comparison'
			},
			{
				label: 'segment_options.visits_options',
				value: 'visits'
			}
		]
	},
	{
		label: 'segment_menu_items.event_item',
		value: 'event'
	},
	{
		label: 'segment_form.menu_condition_segment',
		value: 'segment'
	}
];
