<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">

    <en-dropdown styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsUserEvents"
                 [formControl]="typeControl"
                 (onChange)="onChangeType($event)"></en-dropdown>

    <en-dropdown class="event-dropdown"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="eventOptions$ | async"
                 [filter]="true"
                 [selectFullOption]="true"
                 filterBy="label,value,id"
                 [autoDisplayFirst]="false"
                 [placeholder]="'segment_common.select_placeholder' | translate"
                 [formControl]="eventControl"
                 (onChange)="onChangeField($event)">
        <ng-template let-selectedOption
                     pTemplate="selectedItem">
            <ng-container *ngIf="selectedOption && selectedOption.id !== null">
                <span>{{ selectedOption.label }}</span>
            </ng-container>
            <div *ngIf="selectedOption.id === null"
                 class="deleted__event">
                <span class="en en-exclamation-triangle-o deleted-icon"
                      [tippy]="'segment_form.deleted_event_tooltip' | translate"></span>
                <span>{{ selectedOption.label }}</span>
            </div>
        </ng-template>

        <ng-template let-field
                     pTemplate="item">
            <div class="dropdown__item">
                <div *ngIf="field.id !== null"
                     class="dropdown__label">
                    {{ field.label }}
                </div>
                <div *ngIf="field.id === null"
                     class="dropdown__label-disabled">
                    {{ field.label }}
                </div>
            </div>
        </ng-template>
    </en-dropdown>

    <!--допусловие по параметру-->
    <div [class.hideMargin]="!paramForm?.value"
         [class.active-hide]="showHide">
        <en-param-condition *ngIf="paramForm?.value"
                            [isHide]="isHideParent || isHide"
                            [form]="paramForm"
                            (remove)="deleteParam()"></en-param-condition>
    </div>

    <!--допусловие по кол-ву-->
    <div [class.hideMargin]="!actionCountForm?.value"
         [class.active-hide]="showHide">
        <en-action-count *ngIf="actionCountForm?.value"
                         [form]="actionCountForm"
                         [isShowRemoveButton]="true"
                         (remove)="deleteCount()"></en-action-count>
    </div>

    <!-- допусловие по времени -->
    <div [class.hideMargin]="!actionTimeForm?.value"
         [class.active-hide]="showHide">
        <en-action-time *ngIf="actionTimeForm?.value"
                        [isHide]="isHideParent || isHide"
                        [timeForm]="actionTimeForm"
                        [isShowRemoveButton]="true"
                        [period]="'past'"
                        (remove)="deleteTime()"></en-action-time>

    </div>

    <en-event-button [class.active-hide]="showHide"
                     [currentConditions]="currentConditions$ | async"
                     (onItemClick)="addSubCodition($event)"></en-event-button>

    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>

</form>
