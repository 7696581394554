/* eslint-disable import/no-cycle */

import { dia, shapes } from '@clientio/rappid';
import { Controller } from '../controller';
import * as actions from '../actions';
import { RappidService } from '../../services/rappid.service';

export class KeyboardController extends Controller<RappidService> {
	constructor(public readonly service: RappidService) {
		super(service);
	}

	startListening() {
		const { keyboard } = this.service;

		this.listenTo(keyboard, {
			'ctrl+z': onCtrlZ,
			'ctrl+y, ctrl+shift+z': onCtrlY,
			v: onV,
			'ctrl+m': onCtrlM,
			'ctrl+o': onCtrlO,
			'keydown:space': onSpacedown,
			'keyup:space': onSpaceup,
			'delete backspace': onDelete
		});
	}
}

function onCtrlZ(service: RappidService): void {
	actions.undoAction(service);
}

function onCtrlY(service: RappidService): void {
	actions.redoAction(service);
}

function onV(service: RappidService): void {
	service.bottomBarService.toggleCursorMod();
}

function onCtrlM(service: RappidService): void {
	service.bottomBarService.toggleNavigator();
}

function onCtrlO(service: RappidService, evt: dia.Event): void {
	evt.preventDefault();
	actions.toggleFullScreen(service);
}

function onSpacedown(service: RappidService, evt: KeyboardEvent) {
	evt.preventDefault();

	const { bottomBarService } = service;

	if (bottomBarService.isSelectionMod) bottomBarService.toggleCursorOnSpace();
}

function onSpaceup(service: RappidService) {
	service.bottomBarService.toggleCursorOnSpace();
}

function onDelete(service: RappidService) {
	if (!service.selection.length) return;

	fixLinkColor(service);

	service.commandManager.initBatchCommand();
	service.selection.forEach(element => element.remove());
	service.inspectorService.toggleInspector(false);
	service.commandManager.storeBatchCommand();
}

function fixLinkColor(service: RappidService) {
	const { selection, paper } = service;
	selection.forEach(cell => {
		if (cell.isLink()) actions.setLinkColor(cell as dia.Link, '#535766');
		if (cell.isElement()) {
			const portsOut = (
				cell as InstanceType<typeof shapes.Block>
			).getGroupPorts('out');
			portsOut.forEach(port => {
				const allLinks = service.graph.getLinks();
				const targetLinks = allLinks.filter(
					link => link.attributes.source.port === port.id
				);
				targetLinks.forEach(link => {
					actions.setLinkColor(link, '#535766');

					const linkView = link.findView(paper);
					linkView.vel.removeClass('selected');
				});
			});
		}
	});
}
