<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsPage"
                 [formControl]="type"
                 (onChange)="onChangeType($event)"></en-dropdown>

    <en-dropdown class="input__medium"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsParameter"
                 formControlName="parameter"></en-dropdown>

    <div *ngIf="parameterValue === 'id'" class="addition">
        <input *ngIf="form.get('value')"
               class="h32 text-overflow"
               [class.active-hide]="showHide"
               type="text"
               enInput
               enDynamicWidth="15rem"
               showClear="true"
               formControlName="value" />
    </div>

    <div>
        <en-action-time [class.active-hide]="showHide"
                        period="past"
                        [isHide]="isHideParent || isHide"
                        [timeForm]="actionTime"
                        [optionsPeriod]="optionsPeriod"></en-action-time>
    </div>

    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>
</form>
