import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SqlTemplate } from './sql-template.model';

export interface SqlTemplatesState extends EntityState<SqlTemplate> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'sql-tempalates'
})
export class SqlTemplatesStore extends EntityStore<SqlTemplatesState> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor() {
		super({ loading: false });
	}
}
