import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ExpandedQuery } from '@enkod-core/abstract';

import { UserEvent } from './event-start.model';
import { EventStartState, EventStartStore } from './event-start.store';

@Injectable({ providedIn: 'root' })
export class EventStartQuery extends ExpandedQuery<EventStartState> {
	constructor(protected store: EventStartStore) {
		super(store);
	}

	readonly events$ = this.selectAll() as Observable<UserEvent[]>;
}
