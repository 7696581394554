import { InjectionToken } from '@angular/core';
import { TableColumn } from '@enkod-core/interfaces';
import { FiltersMessage } from '@enSend/message/_states/_state-message';

export type MessagesTab = {
	location?: 'scenario' | 'message' | 'whatsapp';
	columns?: TableColumn[];
	hideFilters?: Array<keyof Partial<FiltersMessage>>;
	filter?: { [key in keyof Partial<FiltersMessage>]: any };
};

export const MESSAGES_TAB_TOKEN = new InjectionToken<MessagesTab>(
	'Токен для настройки таба в сообщениях'
);
