import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@enkod-core/components';
import { EnButtonModule, EnDialogModule, SearchModule } from 'ui-lib';
import { SegmentsTableModule } from 'app/modules/enKod/segments/components';
import { SharedModule } from '@shared';
import { ChooseSegmentsDialogComponent } from './choose-segments-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnDialogModule,
		EnButtonModule,
		SearchModule,
		SegmentsTableModule,
		TuiScrollbarModule
	],
	declarations: [ChooseSegmentsDialogComponent],
	exports: [ChooseSegmentsDialogComponent]
})
export class ChooseSegmentsDialogModule {}
