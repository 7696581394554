<en-dialog [header]="'segment_message_options.link_select_header' | translate"
           [style]="{ width: '90%', maxWidth: '108rem', minWidth: '50rem', height: '85vh'}"
           [modal]="true"
           [(visible)]="visibleDialog"
           appendTo="body"
           (onHide)="closeDialog.emit()">
    <div class="header">
        <section class="tab">
            <p-tabView [(activeIndex)]="activeIndex"
                       (onChange)="onTabChange()">

                <p-tabPanel [header]="'segment_message_options.links_list_header' | translate">
                    <tui-scrollbar *ngIf="links?.length; else empty">
                        <div class="links__list">
                            <en-search class="search"
                                       [placeholder]="'segment_message_options.search_link_placeholder'| translate"
                                       [refresher]="false"
                                       (onSearch)="onSearch($event)"></en-search>
                            <ng-container *ngIf="filteredLinks?.length; else empty">
                                <ng-container *ngFor="let link of filteredLinks">
                                    <div *ngIf="link.url"
                                         class="link__text"
                                         (click)="applyLink.emit(link)">{{link.url}}</div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </tui-scrollbar>
                </p-tabPanel>

                <p-tabPanel [header]="'segment_message_options.links_map_header' | translate">
                    <ng-container *ngIf="state.visible">
                        <tui-scrollbar *ngIf="html && links?.length; else empty">
                            <div class="iframe-wrapper">
                                <iframe scrolling="no"
                                        enHtmlPreview
                                        enClicksMap
                                        [clicksMapData]="clicksMapData$ | async"
                                        [linksEditorData]="linksEditorData$ | async"
                                        [customScroll]="true"
                                        [html]="html"
                                        (chooseLink)="selectLinkFromMap($event)">
                                </iframe>
                            </div>
                        </tui-scrollbar>
                    </ng-container>
                </p-tabPanel>

            </p-tabView>
        </section>
    </div>
</en-dialog>

<ng-template #empty>
    <div class="empty">
        {{'segment_message_options.empty_links_list_placeholder' | translate}}
    </div>
</ng-template>