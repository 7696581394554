export const MESSAGE_TABLE_COLUMNS = [
	{
		field: 'id',
		header: 'message_list.table_header_id',
		reorderableDisabled: true,
		width: '5%'
	},
	{
		field: 'name',
		header: 'message_list.table_header_name',
		width: '30%',
		'min-width': '250px'
	},
	{
		field: 'type',
		header: 'message_list.table_header_type',
		width: '10%',
		'min-width': '160px'
	},
	{
		field: 'scenarioName',
		header: 'message_list.table_header_scenario_name',
		width: '30%',
		'min-width': '250px'
	},
	{
		field: 'tags',
		header: 'message_list.table_header_tags',
		width: '30%',
		'min-width': '250px',
		sortableDisabled: true
	}
];
