<form [formGroup]="form">
    <div class="addition"
         [class.highlight]="isHover">
        <span class="center-span-text prefix nowrap">
            {{ 'segment_options.event_param_options' | translate }}
        </span>
        <en-dropdown class="event-dropdown"
                     styleClass="en-dropdown-solid"
                     [class.no-right-border]="isDateType"
                     [options]="paramOptions$ | async"
                     [filter]="true"
                     [selectFullOption]="true"
                     filterBy="label,value,id"
                     [autoDisplayFirst]="false"
                     [placeholder]="'segment_common.select_placeholder' |translate"
                     formControlName="id"
                     (onChange)="onChangeParam($event)">
            <ng-template let-selectedOption
                         pTemplate="selectedItem">
                <ng-container *ngIf="selectedOption">
                    <ng-container *ngIf="isDeletedEvent">
                        <span>{{ eventControl.controls.name.value }}</span>
                    </ng-container>
                    <span>{{ selectedOption.label }}</span>
                </ng-container>
            </ng-template>

            <ng-template let-field
                         pTemplate="item">
                <div class="dropdown__item">
                    <div class="dropdown__label">
                        {{ field.label }}
                    </div>
                </div>
            </ng-template>
        </en-dropdown>

        <ng-container *ngIf="paramValue && !isDateType">
            <en-dropdown class="input__small right-border-none"
                         styleClass="en-dropdown-solid"
                         [options]="operatorOptions$ | async"
                         formControlName="operator">
            </en-dropdown>

            <ng-container *ngIf="showValueInput">
                <input class="input__small h32 text-overflow"
                       enInput
                       style="border-left: none"
                       formControlName="value" />
            </ng-container>
        </ng-container>

        <div *ngIf="isDateType">
            <en-field-date-condition [form]="form"
                                     [dateType]="typeControl.value">
            </en-field-date-condition>
        </div>

        <i class="button-remove-condition cancel-icon en en-cancel-fill"
           (mouseenter)="isHover = true"
           (mouseleave)="isHover = false"
           (click)="remove.emit()"></i>
    </div>
</form>