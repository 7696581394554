import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ID } from '@datorama/akita';

import { Observable, of, Subject, throwError, timer } from 'rxjs';
import {
	catchError,
	switchMap,
	tap,
	finalize,
	mergeMap,
	filter,
	takeUntil
} from 'rxjs/operators';

import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { NotificationStatus } from 'ui-lib';
import { NotificationsService, UrlSaverService } from '@enkod-core/services';
import { downloadFromLink } from '@enkod-core/utils/download-from-link';
import { SegmentsStore } from './segments.store';
import { SegmentsDataService } from './segments-data.service';
import { Segment, CountModel, DeleteData, ExportData } from './segment.model';
import { SegmentsQuery } from './segments.query';

@Injectable({ providedIn: 'root' })
export class SegmentsService {
	readonly stopCountStream$ = new Subject();
	readonly stopExportStream$ = new Subject();
	constructor(
		private store: SegmentsStore,
		private query: SegmentsQuery,
		private dataService: SegmentsDataService,
		private notificationsService: NotificationsService,
		private urlService: UrlSaverService,
		private http: HttpClient
	) {}

	getAllList(filters?: any): Observable<Segment[]> {
		if (this.query.hasAllEntities()) return this.query.allSegments$;
		return this.requestAllList(filters);
	}

	requestAllList(filters?: any): Observable<Segment[]> {
		return this.dataService.list(filters).pipe(
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			switchMap(resp => {
				this.store.update(() => ({
					[ALL_LIST_STORE_NAME]: resp.result
				}));
				return this.query.allSegments$;
			})
		);
	}

	getList({ page, sort, perPage, search, filter }: any) {
		const { type, tags } = filter;
		return this.dataService
			.list({
				limit: perPage,
				offset: (page - 1) * perPage,
				sort,
				search,
				type: type?.toString(),
				tags: tags?.toString()
			})
			.pipe(
				tap(() => {
					this.urlService.setParamsToUrl(
						{
							sortKey: sort?.field,
							sortOrder: sort?.order,
							page,
							perPage,
							search
						},
						{
							type: filter?.type || null,
							tags: filter?.tags || null
						}
					);
				}),
				switchMap(resp => {
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: page,
						total: Math.ceil(resp.total / perPage) || 1,
						data: resp.result ? [...resp.result] : []
					});
				})
			);
	}

	async get(id: ID) {
		this.store.setLoading(true);
		const entity = await this.dataService.detail(id).toPromise();
		this.store.upsert(entity.id, entity);
		this.store.setLoading(false);
	}

	async create(entity: Segment) {
		this.store.setLoading(true);
		const resp = await this.dataService.create(entity).toPromise();
		this.store.add(resp);
		this.clearAllListCache();
		this.store.setLoading(false);
		return resp;
	}

	async update(entity: Segment) {
		this.store.setLoading(true);
		await this.dataService.update(entity).toPromise();
		this.clearAllListCache();
		this.store.setLoading(false);
	}

	getRelationsInfo(id: ID) {
		return this.dataService.getRelations(id);
	}

	delete(id: number): Observable<{ error: boolean } | any> {
		this.store.setLoading(true);
		return this.dataService.remove(id).pipe(
			tap(() => {
				this.store.remove(id);
				this.clearAllListCache();
			}),
			catchError(error => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(error);
			}),
			finalize(() => this.store.setLoading(false))
		);
	}

	attachTags(tableName: string = '', tags: string[], id?: number) {
		return this.http.put('enkod/segments/persons/tags/', {
			tableName,
			tags,
			id
		});
	}

	unAttachTags(tableName: string = '', tags: string[], id?: number) {
		const options = {
			tableName,
			tags,
			id
		};
		return this.http.delete('enkod/segments/persons/tags/', {
			body: options
		});
	}

	getAttachedTags(tableName: string = ''): Observable<string[]> {
		return this.http.get('enkod/segments/persons/tags/', {
			params: { tableName }
		}) as Observable<string[]>;
	}

	countSubscribers(segmentSettings: CountModel, id?: number) {
		const POLLING_PERIOD = 5000;
		this.store.update({ countLoading: true });
		return this.dataService.startCount(segmentSettings, id).pipe(
			tap(id => this.store.update({ uuidCount: id })),
			switchMap(id =>
				timer(0, POLLING_PERIOD).pipe(
					mergeMap(() => this.dataService.getCount(id)),
					filter(resp => resp.status === 'ready')
				)
			),
			tap(resp =>
				this.store.update({
					subscribersQuantity: resp
				})
			),
			takeUntil(this.stopCountStream$),
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			}),
			finalize(() =>
				this.store.update({ countLoading: false, uuidCount: null })
			)
		);
	}

	closeCountConnetion() {
		const uuid = this.store.getValue().uuidCount;
		if (uuid) this.dataService.closeCountConnection(uuid).subscribe();
		this.stopCountStream$.next();
	}

	exportSegment(
		entity: ExportData,
		fields: string,
		extraFields: string,
		segmentId?: number
	) {
		const POLLING_PERIOD = 5000;
		return this.dataService
			.startExport(entity, fields, extraFields, segmentId)
			.pipe(
				switchMap(id =>
					timer(0, POLLING_PERIOD).pipe(
						mergeMap(() => {
							return this.dataService.getExportStatus(
								id.toString()
							);
						}),
						filter(resp => resp.status === 'ready'),
						tap(resp => {
							downloadFromLink(resp.link);
							this.stopExportStream$.next();
						})
					)
				),
				takeUntil(this.stopExportStream$),
				catchError(error => {
					this.notificationsService
						.show('toast.detail_request_error', {
							label: 'toast.summary_try_later',
							status: NotificationStatus.ERROR
						})
						.subscribe();
					return throwError(error);
				})
			);
	}

	clearSubscribersQuantityCache() {
		this.store.update({
			subscribersQuantity: undefined
		});
	}

	deleteContacts(tableName: string, id?: number) {
		const params = new HttpParams({
			fromObject: { tableName }
		});
		return this.http.post('enkod/segments/remove/', { id }, { params });
	}

	private clearAllListCache(): void {
		this.store.update(() => ({
			[ALL_LIST_STORE_NAME]: null
		}));
	}

	checkDeleted(id: number): Observable<DeleteData[]> {
		return this.dataService.checkDeletedData(id).pipe(
			catchError(e => {
				this.notificationsService
					.show('toast.detail_request_error', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return throwError(e);
			})
		);
	}

	setLoading(loading: boolean) {
		this.store.setLoading(loading);
	}
}
