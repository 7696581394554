import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { TextMaskModule } from 'angular2-text-mask';

import { KeyFilterModule } from 'primeng/keyfilter';

import { EnInputToFloatModule } from '@enkod-core/directives';
import { SharedModule } from '@shared';
import {
	EnButtonModule,
	EnDropdownCalendarNewModule,
	EnDropdownModule,
	EnInputModule
} from 'ui-lib';
import { DataChangeMainComponent } from './data-change-main.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { DataChangeMainService } from './data-change-main.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnDropdownModule,
		EnButtonModule,
		EnInputModule,
		EnDropdownCalendarNewModule,
		KeyFilterModule,
		IMaskModule,
		EnInputToFloatModule,
		TextMaskModule
	],
	declarations: [DataChangeMainComponent],
	exports: [DataChangeMainComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: DataChangeMainService,
			multi: true
		}
	]
})
export class DataChangeMainModule {}
