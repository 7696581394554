import { BehaviorSubject, Observable } from 'rxjs';

export interface WebsocketConfig {
	url: string;
}

export type CentrifugeConfig = {
	token?: string;
	getToken?(): Promise<string>;
};

export interface IWsMessage<T> {
	event: string;
	data: T;
}

export interface IWebsocketService {
	on?<T>(event: string): Observable<T>;
	send?(event: string, data: any): void;
	createConnection(url: string, channels: string[]): void;
	status: BehaviorSubject<string>;
}

export interface ChannelLinstener {
	channel: string;
	listener: BehaviorSubject<any>;
	sender: BehaviorSubject<any>;
}
