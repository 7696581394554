import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ChannelLinstener, WebsocketService } from '@enkod-core/websocket';
import { TestSendingState, TestSendingStore } from './test-sending.store';
import {
	TestStata,
	SocketTestSendResponse,
	TestSendType,
	TestChannelType
} from '../types';

@Injectable({
	providedIn: 'root'
})
export class TestSendingService {
	private readonly WS_URL = environment.urls.wsUrl;
	statisticsListener$: BehaviorSubject<SocketTestSendResponse>;
	private readonly sendType: TestSendType[] = ['sendTestMobPushResponse'];

	constructor(
		private wsService: WebsocketService,
		private testSendingStore: TestSendingStore
	) {}

	connectToWs(channel: TestChannelType) {
		switch (channel) {
			case 'mobpush':
				this.wsService.createConnection(`${this.WS_URL}mobPush/test/`, [
					'testMobPushChannel'
				]);
				break;

			default:
				break;
		}
	}

	setHandlers(payload: ChannelLinstener[]) {
		this.statisticsListener$ = payload[0].listener;
	}

	updateStata(): Observable<SocketTestSendResponse> {
		return this.statisticsListener$.pipe(
			tap((value: SocketTestSendResponse) => {
				if (this.sendType.includes(value?.type)) {
					this.testSendingStore.update((state: TestSendingState) => ({
						...state,
						statistics: state.statistics.map(item => {
							if (item.testSendId === value.testSendId) {
								return {
									...item,
									errorText:
										value.data === 'error'
											? 'message_wizard_edit_step.error'
											: value.data,
									status: value.data ? 'error' : 'delivered'
								} as TestStata;
							}

							return item;
						})
					}));
				}
			})
		);
	}

	sendTestMessageToWs(payload: { data: any; type: string }) {
		this.wsService.socketSender$.next(payload);
	}

	addStata(payload: TestStata, id: number) {
		this.testSendingStore.update(state => ({
			statistics: [...state.statistics, payload],
			id
		}));
	}

	resetStore() {
		this.testSendingStore.reset();
	}
}
