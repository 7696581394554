import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';
import { TuiDay } from '@taiga-ui/cdk';
import {
	DateRangeName,
	itemsFuture,
	itemsLast
} from '../../calendar-range-new/calendar-range.model';

@Component({
	selector: 'en-primitive-range-picker',
	templateUrl: './primitive-range-picker.component.html',
	styleUrls: ['./primitive-range-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimitiveRangePickerComponent implements OnInit {
	items: Array<DateRangeName>;
	itemsLast = itemsLast;
	itemsFuture = itemsFuture;

	constructor(private calendarService: CalendarService) {}
	get minDate() {
		return this.calendarService.getMinDate();
	}

	get maxDate() {
		return this.calendarService.getMinDate();
	}

	get period() {
		return this.calendarService.getPeriod();
	}

	ngOnInit() {
		if (this.period === 'past') {
			this.items = this.itemsLast;
		} else this.items = this.itemsFuture;
	}

	changeDateRange(value: DateRangeName) {
		const { dateValue, name } = value;
		let newValue;
		switch (name) {
			case 'date-range-calendar.today':
				this.calendarService.changePeriodDateRange({
					day: 0
				});
				break;
			case 'date-range-calendar.future_days':
				this.calendarService.changePeriodDateRange({
					day: dateValue - 1
				});
				break;
			case 'date-range-calendar.future_months':
				this.calendarService.changePeriodDateRange({
					month: dateValue
				});
				break;
			case 'date-range-calendar.future_year':
				this.calendarService.changePeriodDateRange(
					{
						day: 364
					},
					new TuiDay(TuiDay.currentLocal().year + 1, 0, 1)
				);
				break;
			case 'date-range-calendar.last_days':
				this.calendarService.changePeriodDateRange({
					day: -dateValue + 1
				});
				break;
			case 'date-range-calendar.last_months':
				this.calendarService.changePeriodDateRange({
					month: -dateValue
				});
				break;
			case 'date-range-calendar.current_week':
				this.rangeCurrentWeek();
				break;
			case 'date-range-calendar.current_year':
				this.rangeCurrentYear();
				break;
			case 'date-range-calendar.current_month':
				this.rangeCurrentMonth();
				break;
			case 'date-range-calendar.last_week':
				this.calendarService.changePeriodDateRange(
					{ day: -6 },

					TuiDay.currentLocal().append({
						day: -TuiDay.currentLocal().dayOfWeek() - 1
					})
				);
				break;
			case 'date-range-calendar.last_month':
				newValue = new TuiDay(
					TuiDay.currentLocal().year,
					TuiDay.currentLocal().month,
					1
				).append({ day: -1 });
				this.calendarService.changePeriodDateRange(
					{
						day: -TuiDay.lengthBetween(
							new TuiDay(newValue.year, newValue.month, 1),
							newValue
						)
					},
					newValue
				);
				break;
			case 'date-range-calendar.last_year':
				this.calendarService.changePeriodDateRange(
					{
						day: -365
					},
					new TuiDay(TuiDay.currentLocal().year - 1, 11, 31)
				);
				break;
			case 'date-range-calendar.future_week':
				this.calendarService.changePeriodDateRange(
					{
						day: 6
					},
					TuiDay.currentLocal().append({
						day: 6 - TuiDay.currentLocal().dayOfWeek() + 1
					})
				);
				break;
			case 'date-range-calendar.future_month':
				newValue = new TuiDay(
					TuiDay.currentLocal().year,
					TuiDay.currentLocal().month + 1,
					1
				);
				this.calendarService.changePeriodDateRange(
					{
						day:
							TuiDay.lengthBetween(
								newValue,
								new TuiDay(newValue.year, newValue.month + 1, 1)
							) - 1
					},
					newValue
				);
				break;
			default:
				this.calendarService.changePeriodDateRange({ day: dateValue });
		}
	}

	rangeCurrentMonth() {
		if (this.period === 'future')
			this.calendarService.changePeriodDateRange({
				day:
					TuiDay.lengthBetween(
						TuiDay.currentLocal(),
						new TuiDay(
							TuiDay.currentLocal().year,
							TuiDay.currentLocal().month + 1,
							1
						)
					) - 1
			});
		else if (this.period === 'past')
			this.calendarService.changePeriodDateRange({
				day: -TuiDay.lengthBetween(
					new TuiDay(
						TuiDay.currentLocal().year,
						TuiDay.currentLocal().month,
						1
					),
					TuiDay.currentLocal()
				)
			});
		else
			this.calendarService.changePeriodDateRange(
				{
					day: 365
				},
				new TuiDay(
					TuiDay.currentLocal().year,
					TuiDay.currentLocal().month,
					1
				)
			);
	}

	rangeCurrentYear() {
		if (this.period === 'future')
			this.calendarService.changePeriodDateRange({
				day: TuiDay.lengthBetween(
					TuiDay.currentLocal(),
					new TuiDay(TuiDay.currentLocal().year, 11, 31)
				)
			});
		else if (this.period === 'past')
			this.calendarService.changePeriodDateRange({
				day: -TuiDay.lengthBetween(
					new TuiDay(TuiDay.currentLocal().year, 0, 1),
					TuiDay.currentLocal()
				)
			});
		else
			this.calendarService.changePeriodDateRange(
				{
					day: 365
				},
				new TuiDay(TuiDay.currentLocal().year, 0, 1)
			);
	}

	rangeCurrentWeek() {
		if (this.period === 'future')
			this.calendarService.changePeriodDateRange({
				day: 6 - TuiDay.currentLocal().dayOfWeek()
			});
		else if (this.period === 'past')
			this.calendarService.changePeriodDateRange({
				day: -TuiDay.currentLocal().dayOfWeek()
			});
		else
			this.calendarService.changePeriodDateRange(
				{
					day: 6
				},
				TuiDay.currentLocal().append({
					day: -TuiDay.currentLocal().dayOfWeek()
				})
			);
	}
}
