import { ID } from '@datorama/akita';
import { Primitives } from '@enkod-core/types';

export type Channel = 'email' | 'push' | 'phone';

export interface Segment {
	id: ID;
	name: string;
	createDate: string;
	isDeleted: boolean;
	limit?: number;
	module: string;
	conditionJSON: ConditionGroupModel;
	relations?: { popups?: Array<number> };
	hasRelations: boolean;
	tags?: string[];
}
export interface ConditionGroupModel {
	join: 'and' | 'or';
	conditions: ConditionModel[];
	inners: ConditionGroupModel[];
	isHide: boolean;
}
export interface CountModel {
	conditionJSON: ConditionGroupModel;
	limit?: number;
}
export interface SubscribersQuantityModel {
	status: 'progress' | 'ready';
	fullCount?: number;
	people?: number;
	emails?: number;
	pushes?: number;
	phones?: number;
	tableName?: string;
}
export interface SegmentQuantityModel {
	status: 'progress' | 'ready';
	date?: number;
	err?: string;
	fullCount?: number;
	people?: number;
	emails?: number;
	pushes?: number;
	mob_pushes?: number;
	phones?: number;
	link: string;
	tableName?: string;
}
export interface ConditionModel {
	id?: number;
	type?: string;
	name?: string;
	period?: 'session' | 'allTime' | 'time';
	units?: 'sec' | 'min' | 'hour';
	inverse?: boolean;
	operator?: string;
	value?: any;
	message?: MessageModel;
	subscriptionDate?: any;
	unsubscription?: UnsubscriptionModel;
	customEvent?: CustomEvent;
	isHide?: boolean;
}

export interface UnsubscriptionModel {
	channel?: string;
	operator?: string;
	groups?: SelectItemValue[];
	actionTime?: MessageTimeModel | null;
}

export interface MessageModel {
	action: string;
	channel: string;
	property: MessagePropertyModel | null;
	actionCount: MessageCountModel | null;
	actionTime: MessageTimeModel | null;
	actionLink: ActionLink | null;
	bounceType?: BounceType;
	sendingType: SendingTypes;
	tag_items?: Tag[];
	onlyUniqMessage?: boolean;
}
export interface MessagePropertyModel {
	parameter: string;
	value: number | string;
}
export interface MessageCountModel {
	operator: string;
	value: number;
}
export interface MessageTimeModel {
	operator: string;
	start: number;
	end: number;
	value: number;
	units: string;
}

export interface SegmentFilterModel {
	search: string;
}

export type FiltersSegment = {
	type: SegmentType;
	tags: string[];
};

export interface RelationsModel {
	popups?: RelationsPopup[];
	emails?: RelationsEmail[];
	pushes?: RelationsPush[];
}

export interface RelationsPopup {
	id: number;
	name: string;
	content: PopupContent[];
}

export interface PopupContent {
	id: number;
	previewUrl: string;
	splitTestLabel: string;
}

export interface RelationsEmail {
	id: number;
	name: string;
}
export interface RelationsPush {
	id: number;
	name: string;
}

export interface DeleteData {
	id: number;
	name: string;
}

export type SendingTypes =
	| 'sendnow'
	| 'sendlate'
	| 'regular'
	| 'api'
	| 'event'
	| 'doi';

export type BounceType = 'any' | 'hard' | 'block' | 'soft' | 'another';

export type Tag = {
	name: string;
	id: number;
};

export function createSegment() {
	return {} as Segment;
}

export type SetControl = {
	controlName: string;
	controlValue: Primitives | null;
};

export interface SelectItemWithDeleted<T = any> {
	label?: string;
	value: SelectItemValue<T>;
	disabledItem?: boolean;
}

export interface SelectItemValue<T = any> {
	id: T;
	channel?: Channel;
	isDeleted?: boolean;
	name?: string;
}

export type SegmentType = 'enSend' | 'enPop';

export type ExportData = {
	tableName?: string;
};

export interface EventModel {
	action: string;
	channel: string;
	property: MessagePropertyModel | null;
	actionCount: MessageCountModel | null;
	actionTime: MessageTimeModel | null;
	bounceType?: BounceType;
	sendingType: SendingTypes;
	tag_items?: Tag[];
}

export interface CustomEvent {
	customEvent: EventBodyContainer;
	type: string;
}

export interface EventBodyContainer {
	type: string;
	customEvent: EventBody;
	actionCount?: MessageCountModel;
	actionTime?: MessageTimeModel;
	param?: EventParam;
}

export interface EventBody {
	id: number;
	name?: string;
	systemName: string;
}

export interface EventOption {
	id: number | null;
	label: string;
	value: number | null;
}

export interface EventParam {
	id: number;
	name: string;
	systemName: string;
	type: EventParamType;
	operator: string;
	value: string;
}

export interface EventParamOption {
	id: number;
	label: string;
	value: number;
	type: string;
}

export type EventParamType = 'string';

export type ActionLink = {
	url_id: number;
	url: string;
	seekBy: 'fullMatch' | 'urlId';
};
