import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';
import { CANVA_MODE_TOKEN, CanvaModeType } from '@enSend/_shared/tokens';
import { SelectItem } from 'primeng/api';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';

import codemirror, { Position } from 'codemirror';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/display/placeholder';

import { CustomValidators } from 'custom-validators';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { AbstractInspector } from '../../abstract';
import { QUERY_OPTIONS } from './constants';
import { ApiQueryParams, FieldsItem } from './models';

@UntilDestroy()
@Component({
	selector: 'en-api-query',
	templateUrl: './api-query.component.html',
	styleUrls: ['./api-query.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: QUERY_OPTIONS,
			multi: true
		},
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'scenario_block_api_query.invalid_value',
				invalidUrl: 'scenario_block_api_query.invalid_value'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiQueryComponent extends AbstractInspector implements OnInit {
	readonly form = this.fb.group({
		requestType: ['', Validators.required],
		url: ['', [CustomValidators.url, Validators.required]],
		headers: this.fb.array([]),
		params: this.fb.array([]),
		json: ['', CustomValidators.json],
		amoAuthEnabled: false
	});

	options: codemirror.EditorConfiguration = {
		lineNumbers: false,
		mode: 'application/ld+json',
		showHint: true,
		lineWrapping: false,
		readOnly: !this.isCreationMode,
		placeholder: '{“key”: “value”}',
		scrollbarStyle: 'simple'
	};

	openDynamicContent = false;
	jsonDialogVisible = false;
	requestMethod: string;
	symbolIndexUrl: number;
	headersIndex = 0;
	paramsIndex = 0;

	hasUrlPosition = false;
	hasHeaderKeyPosition = false;
	hasHeaderValuePosition = false;
	hasParamKeyPosition = false;
	hasParamValuePosition = false;
	hasBodyPosition = false;

	@ViewChild('urlInput')
	private readonly urlInputElement: ElementRef<HTMLInputElement>;

	@ViewChildren('keyHeaderInput')
	private readonly queryListHeaderKey: QueryList<
		ElementRef<HTMLInputElement>
	>;

	@ViewChildren('valueHeaderInput')
	private readonly queryListHeaderValue: QueryList<
		ElementRef<HTMLInputElement>
	>;

	@ViewChildren('keyParamInput')
	private readonly queryListParamKey: QueryList<ElementRef<HTMLInputElement>>;

	@ViewChildren('valueParamInput')
	private readonly queryListParamValue: QueryList<
		ElementRef<HTMLInputElement>
	>;

	@ViewChild('codemirror')
	private readonly jsonInputElement: CodemirrorComponent;

	constructor(
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: SelectItem[][],
		private fb: UntypedFormBuilder
	) {
		super();
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get requestType() {
		return this.form.get('requestType') as UntypedFormControl;
	}

	get headersArray(): UntypedFormArray {
		return this.form.get('headers') as UntypedFormArray;
	}

	get paramsArray(): UntypedFormArray {
		return this.form.get('params') as UntypedFormArray;
	}

	get headersGroup(): UntypedFormGroup {
		return this.headersArray.controls[
			this.headersIndex || 0
		] as UntypedFormGroup;
	}

	get paramsGroup(): UntypedFormGroup {
		return this.paramsArray.controls[
			this.paramsIndex || 0
		] as UntypedFormGroup;
	}

	get urlControl(): UntypedFormControl {
		return this.form.get('url') as UntypedFormControl;
	}

	get jsonControl(): UntypedFormControl {
		return this.form.get('json') as UntypedFormControl;
	}

	get amoAuthControl(): UntypedFormControl {
		return this.form.get('amoAuthEnabled') as UntypedFormControl;
	}

	get codeMirror() {
		return this.jsonInputElement?.codeMirror;
	}

	get headersArrayControls() {
		return this.headersArray.controls;
	}

	get paramsArrayControls() {
		return this.paramsArray.controls;
	}

	get headersArrayLength() {
		return this.headersArrayControls.length;
	}

	get paramsArrayLength() {
		return this.paramsArrayControls.length;
	}

	get maxHeadersFieldsLength() {
		return this.headersArrayLength >= 20;
	}

	get maxParamsFieldsLength() {
		return this.paramsArrayLength >= 10;
	}

	get mainApiBlocks() {
		return this.cell.collection.filter(
			cell => cell.get('subType') === 'apiQuery'
		);
	}

	get hasPosition() {
		return (
			this.hasUrlPosition ||
			this.hasHeaderKeyPosition ||
			this.hasHeaderValuePosition ||
			this.hasParamKeyPosition ||
			this.hasParamValuePosition ||
			this.hasBodyPosition
		);
	}

	get urlControlValid() {
		return this.form.get('url')?.valid;
	}

	get jsonControlValid() {
		return this.form.get('json')?.valid;
	}

	get headersArrayValid() {
		return this.form.get('headers')?.valid;
	}

	get paramsArrayValid() {
		return this.form.get('params')?.valid;
	}

	ngOnInit() {
		this.initListeners();
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;
						const options = this.cell.get('options');
						const subType = this.cell.get('subType');
						this.patchFormOnInit(options, subType);
					}
				})
			)
			.subscribe();

		if (!this.isCreationMode) this.form.disable({ emitEvent: false });
	}

	addHeaderField() {
		const group = this.fb.group({
			key: ['', Validators.required],
			value: ['', Validators.required]
		});
		this.headersArray.push(group);
	}

	addParameterField(key?: string[], value?: string[]) {
		if (key && value) {
			this.paramsArray.clear();
			for (let i = 0; i < key.length; i++) {
				const group = this.fb.group({
					key: [key[i], Validators.required],
					value: [value[i], Validators.required]
				});
				this.paramsArray.push(group);
			}
		} else {
			const group = this.fb.group({
				key: ['', Validators.required],
				value: ['', Validators.required]
			});
			this.paramsArray.push(group);
		}
	}

	removeHeaderField(idx: number) {
		this.headersArray.removeAt(idx);
	}

	removeParamField(idx: number) {
		this.paramsArray.removeAt(idx);
	}

	openDialog() {
		this.jsonDialogVisible = true;
	}

	urlFocus() {
		this.resetPosition();
		this.hasUrlPosition = true;
	}

	keyHeaderFocus(idx: number) {
		this.resetPosition();
		this.hasHeaderKeyPosition = true;
		this.headersIndex = idx;
	}

	valueHeaderFocus(idx: number) {
		this.resetPosition();
		this.hasHeaderValuePosition = true;
		this.headersIndex = idx;
	}

	keyParamFocus(idx: number) {
		this.resetPosition();
		this.hasParamKeyPosition = true;
		this.paramsIndex = idx;
	}

	valueParamFocus(idx: number) {
		this.resetPosition();
		this.hasParamValuePosition = true;
		this.paramsIndex = idx;
	}

	bodyFocus() {
		this.resetPosition();
		this.hasBodyPosition = true;
	}

	updateControl(value: string) {
		if (this.hasUrlPosition) {
			this.getUrlCursorPosition();
			this.urlControl.patchValue(
				this.updateText(this.urlControl, value, this.symbolIndexUrl)
			);
		}
		if (this.hasHeaderKeyPosition) {
			const elementRefKey = this.queryListHeaderKey?.toArray()[
				this.headersIndex
			] as ElementRef<HTMLInputElement>;

			const symbolIndexKey =
				elementRefKey?.nativeElement.selectionStart || 0;

			this.headersGroup?.controls.key.patchValue(
				this.updateText(
					this.headersGroup?.controls.key as UntypedFormControl,
					value,
					symbolIndexKey
				)
			);
		}
		if (this.hasHeaderValuePosition) {
			const elementRefValue = this.queryListHeaderValue?.toArray()[
				this.headersIndex
			] as ElementRef<HTMLInputElement>;

			const symbolIndexValue =
				elementRefValue?.nativeElement.selectionStart || 0;

			this.headersGroup?.controls.value.patchValue(
				this.updateText(
					this.headersGroup?.controls.value as UntypedFormControl,
					value,
					symbolIndexValue
				)
			);
		}
		if (this.hasParamKeyPosition) {
			const elementRefKey = this.queryListParamKey?.toArray()[
				this.paramsIndex
			] as ElementRef<HTMLInputElement>;
			const symbolIndexKey =
				elementRefKey?.nativeElement.selectionStart || 0;

			this.paramsGroup?.controls.key.patchValue(
				this.updateText(
					this.paramsGroup?.controls.key as UntypedFormControl,
					value,
					symbolIndexKey
				)
			);
		}
		if (this.hasParamValuePosition) {
			const elementRefValue = this.queryListParamValue?.toArray()[
				this.paramsIndex
			] as ElementRef<HTMLInputElement>;

			const symbolIndexValue =
				elementRefValue?.nativeElement.selectionStart || 0;

			this.paramsGroup?.controls.value.patchValue(
				this.updateText(
					this.paramsGroup?.controls.value as UntypedFormControl,
					value,
					symbolIndexValue
				)
			);
		}
		if (this.hasBodyPosition) {
			const positionJson = this.codeMirror?.getCursor() as Position;
			const symbolIndexFromCursor = this.codeMirror?.indexFromPos(
				positionJson
			) as number;

			this.jsonControl.patchValue(
				this.updateText(this.jsonControl, value, symbolIndexFromCursor)
			);
		}
		this.resetPosition();
	}

	private updateText(
		control: UntypedFormControl,
		value: string,
		index: number
	) {
		const leftSideOfText = control.value.slice(0, index);
		const rightSideOfText = control.value.slice(index);
		return `${leftSideOfText}${value}${rightSideOfText}`;
	}

	private resetPosition() {
		this.hasUrlPosition = false;
		this.hasHeaderKeyPosition = false;
		this.hasHeaderValuePosition = false;
		this.hasParamKeyPosition = false;
		this.hasParamValuePosition = false;
		this.hasBodyPosition = false;
	}

	private initListeners() {
		this.requestTypeListener();
		this.urlListener();
		this.headersListener();
		this.paramsListener();
		this.bodyQueryListener();
		this.amoAuthListener();
	}

	private requestTypeListener() {
		this.requestType.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (value === 'GET') {
						this.jsonControl.disable();
						delete this.cell.get('options').apiQuery.params[0].body;
					} else {
						this.jsonControl.enable();
					}
					this.changeCellProp(['options', 'apiQuery'], {
						params: [{ method: value }]
					});
					this.requestMethod = value;
				})
			)
			.subscribe();
	}

	private urlListener() {
		this.urlControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					this.getUrlCursorPosition();

					let label = '';
					if (value.length > 30) {
						label = `${value.slice(0, 30)}...`;
					} else {
						label = value;
					}

					this.changeCellProp(['options', 'apiQuery'], {
						params: [
							{
								id: this.getBlockId(),
								label,
								url: value
							}
						]
					});
					this.changeCellProp(
						['options', 'isValid'],
						this.checkValidity()
					);
				})
			)
			.subscribe(value => this.getAllUrlParams(value));
	}

	private headersListener() {
		this.headersArray.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					this.changeCellProp(['options', 'apiQuery'], {
						params: [{ headers: {} }]
					});
					this.changeCellProp(['options', 'apiQuery'], {
						params: [{ headers: value }]
					});
					this.changeCellProp(
						['options', 'isValid'],
						this.checkValidity()
					);
				})
			)
			.subscribe();
	}

	private paramsListener() {
		this.paramsArray.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.changeCellProp(
						['options', 'isValid'],
						this.checkValidity()
					);
				})
			)
			.subscribe(value => this.setInputParams(value));
	}

	private bodyQueryListener() {
		this.jsonControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					let jsonConvertedToObj;
					if (value && this.jsonControlValid) {
						jsonConvertedToObj = JSON.parse(value);
					} else {
						jsonConvertedToObj = value;
					}
					this.changeCellProp(['options', 'apiQuery'], {
						params: [{ body: null }]
					});
					this.changeCellProp(['options', 'apiQuery'], {
						params: [
							{
								body: jsonConvertedToObj
							}
						]
					});
					this.changeCellProp(
						['options', 'isValid'],
						this.checkValidity()
					);
				})
			)
			.subscribe();
	}

	amoAuthListener() {
		this.amoAuthControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					this.changeCellProp(['options', 'apiQuery'], {
						params: [
							{
								amoAuthEnabled: value
							}
						]
					});
				})
			)
			.subscribe();
	}

	private patchFormOnInit(options: any, subType: string) {
		const { params } = options[subType];
		const param = params[0] || {};

		const DefaultValues = {
			method: 'GET',
			url: '',
			headers: [],
			body: '',
			amoAuthEnabled: false
		};

		this.requestType.patchValue(param.method || DefaultValues.method);

		this.changeCellProp(['options', 'apiQuery'], {
			params: [{ method: param.method || DefaultValues.method }]
		});

		param.method ? this.jsonControl.enable() : this.jsonControl.disable();

		this.urlControl.patchValue(param.url || DefaultValues.url);

		param.headers ? this.otd(param) : this.headersArray.clear();

		const bodyValue =
			typeof param.body === 'object'
				? JSON.stringify(param.body, null, 2)
				: param.body || DefaultValues.body;

		this.jsonControl.patchValue(bodyValue);

		this.amoAuthControl.patchValue(
			param.amoAuthEnabled
				? param.amoAuthEnabled
				: DefaultValues.amoAuthEnabled
		);
	}

	private otd(params: ApiQueryParams) {
		this.headersArray.clear();
		params.headers?.forEach((item: FieldsItem) => {
			const fieldGroup = this.fb.group({
				key: [item.key, Validators.required],
				value: [item.value, Validators.required]
			});

			this.headersArray.push(fieldGroup);
		});
	}

	private getAllUrlParams(url: string) {
		const queryString = url?.split('?')[1];
		const urlParams: any = {};

		if (queryString) {
			const entries = queryString.split('&');

			for (let i = 0; i < entries.length; i++) {
				const keyAndValue = entries[i].split('=');

				const paramKey = keyAndValue[0];
				const paramValue =
					typeof keyAndValue[1] === 'undefined' ? '' : keyAndValue[1];

				urlParams[paramKey] = paramValue;
			}
		}
		const keys = Object.keys(urlParams);

		if (keys.length > 10) keys.length = 10;

		this.addParameterField(keys, Object.values(urlParams));
	}

	private setInputParams(value: FieldsItem[]) {
		const currentUrl = this.urlControl.value;
		let res = `${currentUrl?.split('?')[0]}?`;
		if (currentUrl) {
			for (let i = 0; i < value.length; i++) {
				const parameter = Object.values(value[i]);
				res = `${res}${parameter[0]}=${parameter[1]}&`;
			}
			const result = res.slice(0, -1);

			this.urlControl.patchValue(result, { emitEvent: false });
			this.setUrlCursorPosition();

			let label = '';
			if (result.length > 30) {
				label = `${result.slice(0, 30)}...`;
			} else {
				label = result;
			}

			this.changeCellProp(['options', 'apiQuery'], {
				params: [
					{
						id: this.getBlockId(),
						label,
						url: result
					}
				]
			});
		}
	}

	private getUrlCursorPosition() {
		this.symbolIndexUrl =
			this.urlInputElement?.nativeElement.selectionStart || 0;
	}

	private setUrlCursorPosition() {
		this.urlInputElement?.nativeElement.setSelectionRange(
			this.symbolIndexUrl,
			this.symbolIndexUrl
		);
	}

	private checkValidity() {
		if (this.jsonControl.value && this.jsonControl.enabled) {
			return (
				this.urlControlValid &&
				this.jsonControlValid &&
				this.headersArrayValid &&
				this.paramsArrayValid
			);
		}
		return (
			this.urlControlValid &&
			this.headersArrayValid &&
			this.paramsArrayValid
		);
	}

	private getBlockId(id: number = 0): number {
		let increment = id;
		const blocks = this.mainApiBlocks.filter(
			block => block.get('options').apiQuery?.params[0]?.id === increment
		);

		if (blocks.length) {
			return this.getBlockId(++increment);
		}
		return increment;
	}
}
