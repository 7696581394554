import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@enkod-core/components';
import { SegmentsTableModule } from '@enKod/segments/components';
import { SharedModule } from '@shared';
import { EnDialogModule, SearchModule } from 'ui-lib';
import { ChooseSegmentDialogComponent } from './choose-segment-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnDialogModule,
		SearchModule,
		SegmentsTableModule,
		TuiScrollbarModule
	],
	declarations: [ChooseSegmentDialogComponent],
	exports: [ChooseSegmentDialogComponent]
})
export class ChooseSegmentDialogModule {}
