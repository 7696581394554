import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaginatorPlugin } from '@datorama/akita';
import { UrlSaverService } from '@enkod-core/services';
import { PAGINATOR_PLUGIN_TOKEN } from '@enkod-core/tokens';
import { SEGMENT_PAGINATION_PROVIDERS } from '@enKod/segments/segments-list/pagination-provider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MailingGroup } from '@state-enKod/mailing-groups';
import { PaginationService, SearchService } from 'ui-lib';

@UntilDestroy()
@Component({
	selector: 'en-choose-segment-dialog',
	templateUrl: './choose-segment-dialog.component.html',
	styleUrls: ['./choose-segment-dialog.component.scss'],
	providers: [SEGMENT_PAGINATION_PROVIDERS],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseSegmentDialogComponent implements OnInit {
	searchControl: UntypedFormControl;

	constructor(
		private searchService: SearchService,
		@Inject(PAGINATOR_PLUGIN_TOKEN)
		public readonly paginatorRef: PaginatorPlugin<MailingGroup>,
		public paginationService: PaginationService,
		private urlService: UrlSaverService
	) {}

	@Input() dialogVisible: boolean;

	@Output() onHide = new EventEmitter();
	@Output() onConfirm = new EventEmitter();

	ngOnInit(): void {
		this.searchControl = new UntypedFormControl('');
		this.searchControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => this.searchService.setSearchValue(value));
		this.segmentsWithoutSiteBehavior();
	}

	onHideDialog() {
		this.onHide.emit();
		this.urlService.setParamsToUrl({});
	}

	onConfirmDialog(item: MailingGroup) {
		this.onConfirm.emit(item);
		this.urlService.setParamsToUrl({});
	}

	private segmentsWithoutSiteBehavior() {
		this.paginationService.setFilterValue({ type: 'enSend' });
	}
}
