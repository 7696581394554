import { Injector } from '@angular/core';
import { dia, setTheme, shapes, ui } from '@clientio/rappid';
import { TranslateService } from '@ngx-translate/core';
import _ from 'underscore';
import { CanvaMode } from '@enSend/_shared/models';
import {
	GRID_SIZE,
	PADDING_L,
	PADDING_S
	// SECONDARY_BACKGROUND_COLOR
} from '../theme';
import { enKodLink as Link } from './shapes/app.shapes.ts/link.shape';

export function createPlugins(
	paperElement: Element,
	stencilElement: Element,
	injector: Injector,
	translate: TranslateService,
	canvaMode: CanvaMode
) {
	setTheme('enkod-scenario');
	// Graph
	// https://resources.jointjs.com/docs/jointjs/v3.1/joint.html#dia.Graph
	const graph = new dia.Graph({}, { cellNamespace: shapes[canvaMode] });

	// Paper
	// https://resources.jointjs.com/docs/jointjs/v3.1/joint.html#dia.Paper
	const paper = new dia.Paper({
		model: graph,
		width: 1600,
		height: 800,
		async: true,
		sorting: dia.Paper.sorting.APPROX,
		gridSize: GRID_SIZE,
		linkPinning: false,
		multiLinks: false,
		snapLinks: true,
		moveThreshold: 5,
		magnetThreshold: 'onleave',
		drawGrid: true,
		cellViewNamespace: shapes[canvaMode],
		interactive: {
			labelMove: true,
			linkMove: false
		},
		validateMagnet(cellView, magnet) {
			const port = magnet.getAttribute('port');
			const links = graph.getConnectedLinks(cellView.model, {
				outbound: true
			});
			const portLinks = _.filter(links, link => {
				return link.get('source').port === port;
			});
			if (portLinks.length > 0) {
				return false;
			}
			return magnet.getAttribute('magnet') !== 'passive';
		},
		defaultLink: () => new Link(),
		validateConnection: (
			sourceView: dia.CellView,
			sourceMagnet: SVGElement,
			targetView: dia.CellView,
			targetMagnet: SVGElement
		) => {
			if (sourceView === targetView) return false;
			if (targetView.findAttribute('port-group', targetMagnet) !== 'in')
				return false;
			if (sourceView.findAttribute('port-group', sourceMagnet) !== 'out')
				return false;
			return true;
		},
		defaultRouter: {
			name: 'manhattan',
			args: {
				padding: {
					bottom: PADDING_L,
					vertical: PADDING_S,
					horizontal: PADDING_S
				},
				step: GRID_SIZE
			}
		},
		defaultConnector: {
			name: 'rounded'
		}
	});

	// PaperScroller Plugin (Scroll & Zoom)
	// https://resources.jointjs.com/docs/rappid/v3.1/ui.html#ui.PaperScroller
	const scroller = new ui.PaperScroller({
		paper,
		autoResizePaper: true,
		contentOptions: {
			padding: 100,
			allowNewOrigin: 'any'
		},
		scrollWhileDragging: true,
		cursor: 'grab',
		baseWidth: 1600,
		baseHeight: 800
	});
	paperElement.appendChild(scroller.el);
	scroller.render().center();

	const snaplines = new ui.Snaplines({ paper, distance: 12 });
	// Stencil Plugin (Element Palette)
	// https://resources.jointjs.com/docs/rappid/v3.1/ui.html#ui.Stencil
	const stencil = new ui.Stencil({
		paper: scroller,
		snaplines,
		scaleClones: true,
		dropAnimation: true,
		// height: 300,
		width: 260,
		// groupsToggleButtons: true,
		paperOptions: {
			sorting: dia.Paper.sorting.NONE
		},
		groups: createGroups(translate, canvaMode),
		// @ts-ignore
		dragStartClone: (element: dia.Element) => {
			const name = element.get('appShapeName');
			// @ts-ignore
			const Shape = shapes[canvaMode][name];
			if (!Shape) throw new Error(`Invalid stencil shape name: ${name}`);
			const findedShape = Shape.fromStencilShape(element, injector);
			findedShape.attributes.attrs.name.text = translate.instant(
				findedShape.attributes.attrs.name.text
			);
			return findedShape;
		},
		layout: {
			columns: 1,
			rowGap: 12,
			rowHeight: 'auto',
			marginY: PADDING_S,
			marginX: -PADDING_L,
			// parentRelative: true,
			dx: 0,
			dy: 0,
			resizeToFit: false
		}
	});
	stencilElement.appendChild(stencil.el);
	stencil.render();

	// Command Manager Plugin (Undo / Redo)
	// https://resources.jointjs.com/docs/rappid/v3.1/dia.html#dia.CommandManager
	const commandManager = new dia.CommandManager({
		graph
	});

	const selection = new ui.Selection({
		paper,
		useModelGeometry: true
	});

	const keyboard = new ui.Keyboard();

	return {
		graph,
		paper,
		scroller,
		stencil,
		commandManager,
		selectionUI: selection,
		keyboard,
		snaplines
	};
}

function createGroups(
	translate: TranslateService,
	canvaMode: CanvaMode
): { [key: string]: ui.Stencil.Group } {
	const groups = {
		start: {
			index: 1,
			label: translate.instant('scenario_rappid_config.start_group_title')
		},
		main: {
			index: 2,
			label: translate.instant('scenario_rappid_config.main_group_title'),
			closed: true
		}
	};
	if (canvaMode === 'chatbot') return groups;
	return Object.assign(groups, {
		end: {
			index: 3,
			label: translate.instant('scenario_rappid_config.end_group_title'),
			closed: true
		}
	});
}
