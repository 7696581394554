import {
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	OnInit
} from '@angular/core';
import {
	Validators,
	AbstractControl,
	FormGroup,
	FormControl
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip, startWith, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api/selectitem';
import {
	DataTypeField,
	ExtraField,
	ExtraFieldsService,
	ListOption
} from '@state-enKod/extra-fields';
import { FieldConditionForm } from '@enKod/segments/segments-form.model';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { CustomValidators } from 'custom-validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractCondition } from '../../../abstract-condition.component';
import {
	OptionsBoolean,
	OptionsEmail,
	OptionsList,
	OptionsNumber,
	OptionsPhone,
	OptionsText
} from './field-condition.options';

interface Fields {
	id?: string;
	value: string;
	listOptions?: ListOption[];
	label: string;
}

type FieldParams = {
	isExtraField: boolean;
	extraFieldType: DataTypeField;
};
@UntilDestroy()
@Component({
	selector: 'en-field-condition',
	templateUrl: './field-condition.component.html',
	styleUrls: [
		'../../../abstract-condition.component.scss',
		'./field-condition.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldConditionComponent
	extends AbstractCondition
	implements OnInit
{
	isInit = false;
	markAsInvalid = false;

	fieldsPull: {
		email: FieldParams;
		phone: FieldParams;
		[key: string]: FieldParams;
	} = {
		email: {
			isExtraField: false,
			extraFieldType: 'text'
		} as FieldParams,
		phone: {
			isExtraField: false,
			extraFieldType: 'text'
		} as FieldParams
	};

	optionsField: SelectItem[] = [
		{
			label: 'segment_form.cond_field_email',
			value: 'email'
		},
		{
			label: 'segment_form.cond_field_phone',
			value: 'phone'
		}
	];

	optionsOperatorEmail: SelectItem[] = OptionsEmail;
	optionsOperatorPhone: SelectItem[] = OptionsPhone;
	optionsOperatorTypeText: SelectItem[] = OptionsText;
	optionsOperatorTypeNumber: SelectItem[] = OptionsNumber;
	optionsOperatorTypeBool: SelectItem[] = OptionsBoolean;
	optionsOperatorTypeList: SelectItem[] = OptionsList;
	listOptions: SelectItem[] = [];

	optionsField$: BehaviorSubject<SelectItem[]> = new BehaviorSubject(
		this.optionsField
	);

	previousType: DataTypeField;

	currentType: DataTypeField;

	maskNumber = {
		mask: Number,
		thousandsSeparator: ' ',
		max: '999999999999999',
		min: '-999999999999999'
	};

	maskFloat = {
		mask: Number,
		thousandsSeparator: ' ',
		scale: '9',
		max: '999999999',
		min: '-999999999',
		radix: '.',
		mapToRadix: [',']
	};

	constructor(
		private extraFieldsService: ExtraFieldsService,
		public validateServices: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(validateServices, cd);
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.getExtraFields();
		this.setListeners();
	}

	get typedForm(): FormGroup<FieldConditionForm> {
		return this.form as FormGroup<FieldConditionForm>;
	}

	get operatorControl(): FormControl<string | null> {
		return this.typedForm.controls.operator as FormControl<string>;
	}

	get fieldIdControl(): FormControl<number> {
		return this.typedForm.controls.field_id as FormControl<number>;
	}

	get fieldControl(): FormControl<string> {
		return this.typedForm.controls.field as FormControl<string>;
	}

	get fieldTypeValue(): DataTypeField {
		return this.typedForm?.controls.extraFieldType.value as DataTypeField;
	}

	get operatorValue(): string | null {
		return this.typedForm.controls.operator.value;
	}

	get fieldValue(): string {
		return this.typedForm.controls.field.value;
	}

	get showValueField(): boolean {
		return (
			this.operatorValue !== 'empty' &&
			this.operatorValue !== 'not_empty' &&
			this.operatorValue !== 'false' &&
			this.operatorValue !== 'true'
		);
	}

	get isEmailOrPhone(): boolean {
		return this.operatorValue === 'email' || this.operatorValue === 'phone';
	}

	get showListFieldDropdown() {
		return (
			this.fieldTypeValue === 'list' &&
			(this.operatorValue === 'equal' ||
				this.operatorValue === 'not_equal')
		);
	}

	get showListFieldMultiselect() {
		return (
			this.fieldTypeValue === 'list' &&
			(this.operatorValue === 'one_of' ||
				this.operatorValue === 'not_one_of')
		);
	}

	getValue(): number | string | [] | null | undefined {
		return this.typedForm.value.value;
	}

	getOperator(): string | null {
		return this.typedForm.controls.operator.value;
	}

	getExtraFields() {
		const showListFields = true;

		this.extraFieldsService
			.getAllList(showListFields)
			.pipe(untilDestroyed(this))
			.subscribe((resp: ExtraField[]) => {
				const fieldsPullSecondary: {
					[key: string]: FieldParams;
				} = {};

				const optionsFieldSecondary: any[] = [];

				resp?.forEach((item: ExtraField) => {
					fieldsPullSecondary[item.serviceName] = {
						isExtraField: true,
						extraFieldType: item.dataType
					} as FieldParams;

					optionsFieldSecondary.push({
						label: item.name,
						value: item.serviceName,
						listOptions: item.listOptions ?? [],
						id: item.id
					} as SelectItem);
				});

				this.fieldsPull = {
					...this.fieldsPull,
					...fieldsPullSecondary
				};

				const fields: Fields[] = [
					...this.optionsField,
					...optionsFieldSecondary
				];

				this.optionsField$.next(fields);

				const form = this.form as FormGroup<FieldConditionForm>;
				const type = form?.controls.extraFieldType.value;

				if (type === 'list') {
					this.listOptions =
						fields
							.find(field => {
								return field.value === this.fieldValue;
							})
							?.listOptions?.map(option => {
								return {
									label: option.name,
									value: option
								};
							}) ?? [];
				}

				this.isInit = true;
			});
	}

	setValidation() {
		if (
			this.fieldTypeValue !== 'date' &&
			this.fieldTypeValue !== 'dateTime'
		)
			this.disableDateControls();

		this.previousType = this.fieldTypeValue;
		this.currentType = this.fieldTypeValue;
		this.getSubscriptionNoDebounce('field')
			?.pipe(
				untilDestroyed(this),
				tap(operator => {
					switch (operator) {
						case 'email':
							this.optionsOperatorTypeText =
								this.optionsOperatorEmail;
							this.form.get('isExtraField')?.patchValue(false);
							break;
						case 'phone':
							this.optionsOperatorTypeText =
								this.optionsOperatorPhone;
							this.form.get('isExtraField')?.patchValue(false);
							break;
						default:
							this.optionsOperatorTypeText = OptionsText;
							this.form.get('isExtraField')?.patchValue(true);
							break;
					}
				}),
				skip(1)
			)
			.subscribe(value => {
				if (!this.isInit) return;

				const fieldParams: FieldParams = this.fieldsPull[value];
				this.form
					.get('isExtraField')
					?.setValue(fieldParams.isExtraField);
				this.form
					.get('extraFieldType')
					?.setValue(fieldParams.extraFieldType);

				this.form.patchValue(
					{
						isExtraField: fieldParams.isExtraField,
						extraFieldType: fieldParams.extraFieldType
					},
					{ emitEvent: false }
				);
				this.currentType = fieldParams.extraFieldType;
				this.strategyManager(); // для выбора стратегии для типа доп. полей
				this.setValid();

				this.previousType = fieldParams.extraFieldType;
			});

		this.getSubscriptionNoDebounce('operator')
			?.pipe(untilDestroyed(this))
			.subscribe(() => {
				switch (true) {
					case !this.showValueField:
						this.form.get('value')?.disable();
						break;
					case this.showValueField:
						this.form.get('value')?.enable();
						break;
					default:
						break;
				}
				this.setValid();
			});
	}

	strategyManager() {
		switch (true) {
			// меняем поле с типом text на поле с другим типом
			case this.previousType === 'text' && this.currentType === 'text': {
				this.setValueAndOperator({
					transform: 'textToText'
				});
				break;
			}
			case this.previousType === 'text' &&
				this.currentType === 'number': {
				this.setValueAndOperator({
					transform: 'textToNumber'
				});
				break;
			}
			case this.previousType === 'text' && this.currentType === 'float': {
				this.setValueAndOperator({
					transform: 'textToFloat'
				});
				break;
			}
			case this.previousType === 'text' && this.currentType === 'bool': {
				this.setValueAndOperator({
					transform: 'textToBool'
				});
				break;
			}

			// меняем поле с типом number на поле с другим типом
			case this.previousType === 'number' &&
				this.currentType === 'text': {
				this.setValueAndOperator({
					transform: 'numberToText'
				});
				break;
			}
			case this.previousType === 'number' &&
				this.currentType === 'number':
				break;
			case this.previousType === 'number' && this.currentType === 'float':
				break;
			case this.previousType === 'number' &&
				this.currentType === 'bool': {
				this.setValueAndOperator({
					transform: 'numberToBool'
				});
				break;
			}

			// меняем поле с типом float на поле с другим типом
			case this.previousType === 'float' && this.currentType === 'text': {
				this.setValueAndOperator({
					transform: 'floatToText'
				});
				break;
			}
			case this.previousType === 'float' &&
				this.currentType === 'number': {
				this.setValueAndOperator({
					transform: 'floatToNumber'
				});
				break;
			}
			case this.previousType === 'float' && this.currentType === 'float':
				break;
			case this.previousType === 'float' && this.currentType === 'bool': {
				this.setValueAndOperator({
					transform: 'floatToBool'
				});
				break;
			}

			// меняем поле с типом bool на поле с другим типом
			case this.previousType === 'bool' && this.currentType === 'text': {
				this.setValueAndOperator({
					transform: 'boolToText'
				});
				break;
			}
			case this.previousType === 'bool' &&
				this.currentType === 'number': {
				this.setValueAndOperator({
					transform: 'boolToNumber'
				});
				break;
			}
			case this.previousType === 'bool' && this.currentType === 'float': {
				this.setValueAndOperator({
					transform: 'boolToFloat'
				});
				break;
			}
			case this.previousType === 'bool' && this.currentType === 'bool':
				break;

			// меняем поле с типом date|dateTime на поле с другим типом
			case (this.previousType === 'date' ||
				this.previousType === 'dateTime') &&
				!(
					this.currentType === 'date' ||
					this.currentType === 'dateTime'
				): {
				this.setValueAndOperator({
					transform: 'dateToAny'
				});
				break;
			}
			// меняем поле с любым типом на на поле типа date|dateTime
			case !(
				this.previousType === 'date' || this.previousType === 'dateTime'
			) &&
				(this.currentType === 'date' ||
					this.currentType === 'dateTime'): {
				this.setValueAndOperator({
					transform: 'anyToDate'
				});
				break;
			}

			case !(this.previousType === 'list') &&
				this.currentType === 'list': {
				this.setValueAndOperator({
					transform: 'anyToList'
				});
				break;
			}
			default:
				break;
		}
	}

	setValueAndOperator({ transform }: { transform: string }) {
		let value = this.getValue();
		let operator: string | null = this.getOperator();
		switch (transform) {
			case 'numberToText':
			case 'floatToText': {
				if (this.fieldValue === 'phone') {
					value = null;
					operator = 'empty';
					break;
				}

				value = (value || '').toString();
				operator = operator !== 'empty' ? 'equal' : operator;
				break;
			}
			case 'textToText': {
				operator = 'equal';
				break;
			}

			case 'numberToBool':
			case 'floatToBool':
			case 'textToBool': {
				operator =
					operator !== 'empty' ? (!!value).toString() : operator;
				value = '';
				break;
			}

			case 'floatToNumber': {
				if (typeof value === 'number') value = Math.trunc(value);
				break;
			}

			case 'textToNumber':
			case 'textToFloat': {
				value =
					(typeof value === 'string' ? parseInt(value, 10) : value) ||
					null;
				operator = operator !== 'empty' ? 'equal' : operator;
				break;
			}

			case 'boolToFloat':
			case 'boolToNumber': {
				if (operator !== 'empty') {
					operator = 'equal';
				}
				break;
			}

			case 'boolToText': {
				if (this.fieldValue === 'phone') {
					value = null;
					operator = 'empty';
					break;
				}
				if (operator !== 'empty') {
					operator = 'equal';
				}
				break;
			}

			case 'dateToAny': {
				this.disableDateControls();
				value = null;
				operator = 'empty';
				break;
			}

			case 'anyToDate': {
				this.enableDateControls();
				operator = null;
				value = '';
				break;
			}

			case 'anyToList': {
				operator = 'empty';
				value = null;
				break;
			}
			default:
				break;
		}
		if (operator === 'empty') value = '';
		this.typedForm.patchValue({
			value,
			operator
		});
	}

	setValid(): void {
		const operator: string | null = this.operatorValue;
		const fieldParams: FieldParams = this.fieldsPull[this.fieldValue];
		const re = new RegExp(
			// eslint-disable-next-line no-useless-escape
			/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
		);

		const validateDomain = (control: AbstractControl) => {
			return control.value?.match(re) ? null : { validDomain: true };
		};

		switch (fieldParams?.extraFieldType) {
			case 'text':
				if (this.fieldValue === 'email') {
					if (
						operator?.toString() === 'equal' ||
						operator?.toString() === 'not_equal'
					)
						this.typedForm.controls.value.setValidators([
							Validators.required,
							CustomValidators.email,
							Validators.maxLength(64)
						]);
					else if (
						operator?.toString() === 'contain' ||
						operator?.toString() === 'not_contain'
					)
						this.typedForm.controls.value.setValidators([
							Validators.required,
							CustomValidators.domain
						]);
					else if (operator?.toString() === 'containDomain')
						this.typedForm.controls.value.setValidators([
							Validators.required,
							validateDomain
						]);
					else if (
						operator?.toString() === 'prefix' ||
						operator?.toString() === 'postfix'
					)
						this.typedForm.controls.value.setValidators([
							Validators.required
						]);
					break;
				}
				if (this.fieldValue === 'phone') {
					if (
						operator?.toString() === 'equal' ||
						operator?.toString() === 'not_equal'
					)
						this.typedForm.controls.value.setValidators([
							Validators.required,
							CustomValidators.phone
						]);
					else if (
						operator?.toString() === 'prefix' ||
						operator?.toString() === 'not_prefix'
					)
						this.typedForm.controls.value.setValidators([
							Validators.required
						]);
					break;
				}
				this.typedForm.controls.value.setValidators([
					Validators.required
				]);
				break;

			case 'number':
			case 'float':
				this.form.get('value')?.enable();
				break;
			case 'bool':
				this.form.get('value')?.disable();
				break;
			default:
				break;
		}
		if (operator === 'empty' || operator === 'not_empty')
			this.form.get('value')?.disable();
		this.typedForm.controls.value.updateValueAndValidity();
	}

	private setListeners() {
		this.validateServices.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					if (Array.isArray(this.typedForm.controls.value.value))
						if (this.isHide || this.isHideParent) {
							this.markAsInvalid = false;
						} else {
							this.markAsInvalid =
								!this.typedForm.controls.value.value.length;
						}
					this.cd.markForCheck();
				})
			)
			.subscribe();
		this.typedForm.controls.value?.valueChanges
			.pipe(
				untilDestroyed(this),
				startWith(),
				tap(value => {
					if (Array.isArray(value) && value.length)
						this.markAsInvalid = false;
					this.cd.markForCheck();
				})
			)
			.subscribe();
		this.segmentsValidateService.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.markAsInvalid = false;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.listOperatorListener();
	}

	private listOperatorListener() {
		this.operatorControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(operator => {
					if (
						this.fieldTypeValue === 'list' &&
						(operator === 'one_of' || operator === 'not_one_of')
					) {
						this.typedForm.controls.value.patchValue([]);
					}

					if (operator === 'equal' || operator === 'not_equal') {
						this.typedForm.controls.value.patchValue(null);
					}
				})
			)
			.subscribe();
	}

	onChangeField(event: {
		value: any;
		id: number;
		option: {
			listOptions: ListOption[];
		};
	}) {
		this.listOptions = [];

		if (this.fieldTypeValue === 'list') {
			event.option.listOptions.forEach(list => {
				this.listOptions.push({
					label: list.name,
					value: { ...list }
				});
			});

			this.typedForm.controls.value.patchValue([]);
		}

		if (event.id) {
			this.fieldIdControl.enable();
			this.fieldIdControl.patchValue(event.id);
			return;
		}
		this.fieldIdControl.disable();
	}

	deselectList(index: number): void {
		const valueControl = this.typedForm.controls.value;
		const { value } = valueControl;
		if (Array.isArray(value)) {
			const arrCopy: [] = [...value];
			arrCopy.splice(index, 1);
			valueControl.patchValue(arrCopy);
		}
	}

	private disableDateControls() {
		this.form.get('start')?.disable();
		this.form.get('end')?.disable();
		this.form.get('units')?.disable();
	}

	private enableDateControls() {
		this.form.get('start')?.enable();
		this.form.get('end')?.enable();
		this.form.get('units')?.enable();
		this.operatorControl.patchValue(null);
	}
}
