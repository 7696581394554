import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { FinishComponent } from './finish.component';

@Injectable()
export class FinishService implements InspectorItemPlugin {
	private readonly finishContent = new PolymorpheusComponent(
		FinishComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'finish',
				name: 'finish',
				content: this.finishContent
			}
		]);
	}
}
