import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '@enkod-core/services';
import { NotificationStatus } from 'ui-lib';
import { Cards } from 'ui-lib/cards/cards.model';
import { RfmDataService } from './rfm-data.service';
import {
	BorderCategory,
	BorderField,
	CalculationProgressModel,
	RfSegmentModel,
	SegmentsSettingsFormModel,
	SegmentsSettingsModel
} from './rfm.model';
import { RfmStrore } from './rfm.store';
import { RfmQuery } from './rfm.query';
import { CHART_COLORS } from '../constants';

@Injectable()
export class RfmService {
	readonly isLoading$ = new BehaviorSubject<boolean>(false);
	readonly isCalculated$ = new BehaviorSubject<boolean>(true);
	nextRecount$ = new Subject<number>();
	isError = false;

	constructor(
		private dataService: RfmDataService,
		private store: RfmStrore,
		private query: RfmQuery,
		private translate: TranslateService,
		private notifications: NotificationsService
	) {}

	startCalculation(): Observable<void> {
		return this.dataService.startCalculation().pipe(
			catchError(error => {
				this.showErrorToast();
				return throwError(error);
			})
		);
	}

	stopCalculation(): Observable<void> {
		return this.dataService.stopCalculation().pipe(
			tap(() => {
				this.isCalculated$.next(true);
				this.showStopToast();
			}),
			catchError(error => {
				this.showErrorToast();
				return throwError(error);
			})
		);
	}

	getCalculationStatus(): Observable<CalculationProgressModel> {
		return this.dataService.getCalculationProgress();
	}

	loadSegmentToStore(): Observable<RfSegmentModel[]> {
		this.store.setLoading(true);
		return this.dataService.getSegmentList().pipe(
			tap(data => {
				// Добавляем цвета заднего фона по кол-ву контактов
				const sortedArray = [...data]
					.sort((a, b) => {
						return a.contactsCount - b.contactsCount;
					})
					.map((item, index) => ({
						...item,
						color: CHART_COLORS[index]
					}));
				// Добавляем стили и позиции для графика в style
				const readyData = [...data].map((segment, index) => {
					const finded = sortedArray.find(
						item => item.sysName === segment.sysName
					);
					return {
						...segment,
						id: index + 1,
						style: `word-wrap: break-word; grid-area: segment_${
							index + 1
						}; background-color: #${finded?.color}; ${
							this.isChangeTextColor(finded?.color || '')
								? ' color: #153eb5'
								: ''
						}`
					};
				});
				this.store.set(readyData);
				this.store.setLoading(false);
			}),
			catchError(error => {
				this.showErrorToast();
				return throwError(error);
			})
		);
	}

	getCards(currency: string): Observable<Cards[]> {
		this.isLoading$.next(true);
		return this.dataService.getCardList().pipe(
			map(item => {
				this.nextRecount$.next(item.nextRecount);
				this.isLoading$.next(false);
				return [
					{
						info: {
							label: 'rfm_dashboard.last_calculation',
							value: item.lastRecount,
							isDateValue: true
						},
						footer: [
							{
								label: 'rfm_dashboard.next_calculation',
								value: item.nextRecount,
								isDateValue: true
							}
						]
					},
					{
						info: {
							label: 'rfm_dashboard.one_order',
							value: item.buyersCount,
							tooltip: 'rfm_dashboard.tooltip'
						},
						footer: [
							{
								label: 'rfm_dashboard.contacts_count',
								value: item.contactsCount
							}
						]
					},
					{
						info: {
							label: `${this.translate.instant(
								'rfm_dashboard.sum_order'
							)} ${currency}`,
							value: item.ordersSum,
							tooltip: 'rfm_dashboard.tooltip'
						},
						footer: [
							{
								label: `${this.translate.instant(
									'rfm_dashboard.average_check'
								)} ${currency}:`,
								value: item.averageCheck
							}
						]
					}
				];
			}),
			catchError(error => {
				this.isError = true;
				this.showErrorToast();
				return throwError(error);
			})
		);
	}

	getBorders(): Observable<BorderCategory[]> {
		return this.dataService.getSegmentsSettings().pipe(
			map(data => {
				this.store.update(data);
				const result: BorderCategory[] = [];
				Object.keys(data.bordersSettings).forEach(key => {
					let values = Object.values(
						data.bordersSettings[key as BorderField]
					);

					// Превращаем значения в диапазон значений
					values = values.map((item, index) => {
						if (key !== 'recency') {
							let nextValue: string | number =
								Number(values[index + 1]) - 1;
							nextValue = nextValue ? `-${nextValue}` : '+';
							return `${item}${nextValue}`;
						}
						if (!index) return `${item}+`;
						const prevValue = Number(values[index - 1]) - 1;
						return `${item}-${prevValue}`;
					});
					result.push({
						field: key as BorderField,
						one: values[0],
						two: values[1],
						three: values[2],
						four: values[3],
						five: values[4]
					});
				});
				return result;
			}),
			catchError(error => {
				this.showErrorToast();
				return throwError(error);
			})
		);
	}

	changeSegmentFields(segment: RfSegmentModel): Observable<void> {
		return this.dataService
			.changeSegmentFields({
				systemName: segment.sysName,
				name: segment.name,
				description: segment.description
			})
			.pipe(
				tap(() => {
					let storeData = this.query.getAll();
					storeData = storeData.map(item => {
						if (segment.id === item.id) return segment;
						return item;
					});
					this.store.set(storeData);
				}),
				catchError(error => {
					this.showErrorToast();
					return throwError(error);
				})
			);
	}

	changeSegmentsSettings(
		segmentsSettings: SegmentsSettingsFormModel
	): Observable<SegmentsSettingsModel> {
		const { period, frequency, monetary, recency } = segmentsSettings;
		const settings = {
			bordersSettings: {
				recency: recency.reduce(
					(acc: any, val: number, index: number) => {
						acc[`r${index + 1}`] = val;
						return acc;
					},
					{}
				),
				frequency: frequency.reduce(
					(acc: any, val: number, index: number) => {
						acc[`f${index + 1}`] = val;
						return acc;
					},
					{}
				),
				monetary: monetary.reduce(
					(acc: any, val: number, index: number) => {
						acc[`m${index + 1}`] = val;
						return acc;
					},
					{}
				)
			},
			recountFrequency: period
		};
		this.store.update(settings);
		return this.dataService.changeSegmentsSettings(settings).pipe(
			catchError(error => {
				this.showErrorToast();
				return throwError(error);
			})
		);
	}

	private isChangeTextColor(color: string): boolean {
		return (
			color === 'eef2fc' ||
			color === 'dde5f9' ||
			color === 'cbd9f5' ||
			color === 'baccf2' ||
			color === 'a8c0f0' ||
			color === '97b4ed'
		);
	}

	private showErrorToast() {
		this.notifications
			.show('toast.detail_request_error', {
				label: 'toast.summary_try_later',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}

	private showStopToast() {
		this.notifications
			.show('', {
				label: 'rfm_report.stop_toast',
				status: NotificationStatus.SUCCESS
			})
			.subscribe();
	}
}
