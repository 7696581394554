import {
	Component,
	ChangeDetectionStrategy,
	Inject,
	ChangeDetectorRef,
	OnInit,
	Input
} from '@angular/core';
import {
	ControlValueAccessor,
	FormArray,
	FormBuilder,
	FormGroup,
	NgControl,
	Validators
} from '@angular/forms';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { CommonTypeOptions } from '@enkod-core/types';
import { markAllAsDirty } from '@enkod-core/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';
import { SelectItem } from 'primeng/api';
import { map } from 'rxjs/operators';
import { ParamsMapModel } from '../../sql-request.component';

interface ParamsModel {
	key: string;
	value: any;
	type: CommonTypeOptions;
}

@UntilDestroy()
@Component({
	selector: 'en-sql-params',
	templateUrl: './sql-params.component.html',
	styleUrls: [
		'../../../../../abstract-condition.component.scss',
		'./sql-params.component.scss'
	],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: [
				{
					label: 'boolean.true',
					value: true
				},
				{
					label: 'boolean.false',
					value: false
				}
			]
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SqlParamsComponent implements ControlValueAccessor, OnInit {
	form: FormGroup = this.fb.group({
		params: this.fb.array([])
	});

	get formParams(): FormArray<FormGroup<object | null>> {
		return this.form.controls.params as FormArray<FormGroup<object | null>>;
	}

	@Input() set submitted(value: boolean) {
		if (value) this.checkValidate();
	}

	private onChange: Function = EMPTY_FUNCTION;

	constructor(
		@Inject(NgControl)
		protected ngControl: NgControl,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly booleanOptions: SelectItem<boolean>[],
		private fb: FormBuilder,
		private cd: ChangeDetectorRef
	) {
		this.ngControl.valueAccessor = this;
	}

	ngOnInit(): void {
		this.form.valueChanges
			.pipe(
				map(value => {
					const map: ParamsMapModel = {};
					value.params.forEach((item: ParamsModel) => {
						map[item.key] = {
							value: item.value,
							type: item.type
						};
					});
					return map;
				}),
				untilDestroyed(this)
			)
			.subscribe(value => this.onChange(value));
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	writeValue(value: ParamsMapModel) {
		if (value) this.patchParams(value);
	}

	registerOnTouched() {}

	private patchParams(value: ParamsMapModel): void {
		this.formParams.clear();
		const map = Object.entries(value);
		if (map.length) {
			map.forEach(([key, { type, value }]) => {
				this.formParams.push(
					this.fb.group({
						key,
						value: [value, Validators.required],
						type
					}) as FormGroup<object | null>
				);
			});
		}
		this.cd.markForCheck();
	}

	private checkValidate(): void {
		this.form.markAllAsTouched();
		markAllAsDirty(this.form);
		this.form.updateValueAndValidity();
	}
}
