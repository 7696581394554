import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { TabViewModule } from 'primeng/tabview';

import { TuiScrollbarModule } from '@enkod-core/components';

import { EnDialogModule, SearchModule } from 'ui-lib';

import { SharedModule } from '@shared';

import { MessageTableModule } from 'app/modules/enSend/_shared/components';
import { CopyMessageDialogComponent } from './copy-message-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnDialogModule,
		MessageTableModule,
		TabViewModule,
		SearchModule,
		ReactiveFormsModule,
		TranslateModule,
		TuiScrollbarModule
	],
	declarations: [CopyMessageDialogComponent],
	exports: [CopyMessageDialogComponent]
})
export class CopyMessageDialogModule {}
