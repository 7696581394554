<ng-container *ngIf="snippetsSet?.size; else noSnippets"
              [formGroup]="snippetsFormArray">
    <div class="snippet"
         *ngFor="let snippet of snippetsFormArray.controls; index as i">
        <ng-container [formGroupName]="i">
            <div class="en-field">
                <label>{{snippet.value.key}}</label>
                <input enInput
                       formControlName="value">
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-template #noSnippets>
    <div class="no-snippets">
        {{'message_wizard_edit_step.sendtest_empty_snippets' | translate}}
    </div>
</ng-template>
