import { ID } from '@datorama/akita';
import { MailingGroup } from '@state-enKod/mailing-groups';
import {
	FileAttachment,
	Message,
	SegmentMessage,
	SendingType,
	StatusType
} from '@enSend/message/_states/_state-message';
import { WaTemplateParamsOTD } from '@enSend/message/whatsapp-template-wizard/models';
import {
	MailEntity,
	PushEntity,
	MobPushEntity,
	SmsEntity,
	SplitTestSettings,
	ChoiceWinner,
	UploadFileResponse
} from '@enSend/message/message-wizard/message-wizard-common';
import { Observable } from 'rxjs';

export interface IMessageDetail {
	id: ID;
	filtred?: IMessageDataDetail;
	filteredAmp?: IMessageDataDetail;
	grouped?: IMessageDataDetail[];
	preview?: Message;
	splitStatistics?: IMessageDataDetail[];
	activeSplitVersion: number;
}

export interface IMessageDetailModal extends Message {
	mails?: MailEntity[];
	pushes?: PushEntity[];
	mobPushes?: MobPushEntity[];
	sms?: SmsEntity[];
	splitTestSettings?: SplitTestSettings;
}

export type MessageDetailEntity =
	| MailEntity
	| PushEntity
	| MobPushEntity
	| SmsEntity;

export interface IMessageDataDetail {
	clicks: number;
	complaints: number;
	delivered: number;
	failures: number;
	groupValue: number | string;
	messageId: ID;
	name: string;
	opens: number;
	sent: number;
	uniqClickers: number;
	uniqClicks: number;
	uniqOpens: number;
	unsubscribes: number;
	revenue: number;
	orders: number;
	currency: string;
	desktop: number;
	tablet: number;
	phone: number;
	unknown: number;
}

export interface IFilterModel {
	startDate: number;
	endDate: number;
}

export interface IOverallData {
	id: ID;
	name: string;
	status: StatusType;
	sendingType: string;
}
export interface MessageInfo {
	sent: number;
	uniqueSent?: number;
	notSent: number;
	uniqueNotSent?: number;
	uniqueSubscribers?: number;
	complaints?: number;
	unsubscribes?: number;
	recipients?: number;
	shows?: number;
}
export interface SentCondition {
	dateInfo: Object;
	sendingTypeValue: string;
	regularType: string;
}

export type MessageType =
	| 'mail'
	| 'push'
	| 'mobPush'
	| 'sms'
	| 'whatsapp'
	| 'telegram';

export type ColumnsStatisticHeader = {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	[key in MessageType]: DetailTableColumn[];
};

export type DetailTableColumn = {
	field: string;
	header: string;
	reorderableColumn: boolean;
	width: string;
	['min-width']: string;
};

export type DataOfDetailsMessages = {
	subject: string;
	preheader: string;
	fromEmail: string;
	segments: SegmentMessage[];
	exceptSegments?: SegmentMessage[];
	groups: MailingGroup[];
	tags: string[];
	endTime: number;
	subscribers: number;
	messageType: MessageType;
	sendingType: string;
	scenarioId: number;
	scenarioName: string;
	choiceWinner: ChoiceWinner;
	sendingTime: number;
	importInfo: UploadFileResponse | {};
	durationTest: number;
	durationUnit: Unit;
	testGroupSize: number;
	utm: UtmTags;
	url: [string, string][];
	whatsappTemplateId?: number;
	whatsappName?: string;
	whatsappParams?: WaTemplateParamsOTD;
	withAttachments: boolean;
	isTextVersionExist: boolean;
	isAmpExist: boolean;
	attachments: FileAttachment[];
	fileAttachments: boolean;
};

export type Unit = 'hour' | 'minute';

export type UtmKeys =
	| 'utm_campaign'
	| 'utm_content'
	| 'utm_source'
	| 'utm_medium';

export type UtmTags = [UtmKeys, string | null][];

export type ChooseWinnerRequestBody = {
	messageId: number;
	winnerId: number;
};

export function createMessageDetail() {
	return {} as IMessageDetail;
}

export interface MessageData {
	id: ID;
	status: StatusType;
	sendingType: SendingType;
	type: MessageType;
	name: string;
}

export type ChangeStatusDetails = {
	id: number;
	status: boolean;
};

export type DeleteMessageDetails = {
	id: number;
	name: string;
};

export interface Cards {
	info: CardInfo;
	footer?: CartFooter[];
}

export interface CardInfo {
	label: string;
	tooltip?: string;
	tooltipValue?: number | string;
	value?: number;
	percent?: string;
	color?: string;
	backgroundColor?: string;
	currency?: string;
	type?: string;
}

export interface CartFooter {
	label: string;
	value: number;
	percent?: string;
}

export type LinkData = {
	type?: string;
	url: string;
	isDynamic: boolean;
};

export type LinkUpdate = {
	messageId: ID;
	objectId: ID;
	linkId: number;
	link: {
		url: string;
	};
};

export type LinksQueryParams = {
	messageId: ID;
	objectId: ID;
	getAll?: boolean;
};

export type MessageStatisticsType = 'html' | 'amp' | 'all';
export type StatisticsDataObservable =
	| Observable<IMessageDataDetail[]>
	| undefined;
