import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { Scenario, ScenariosStore } from '@enSend/scenario/_state';
import { isValidScenario } from './utils';

@Injectable()
export class WizardDataService {
	protected readonly endpoint = 'ensend/scenario/';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	constructor(protected http: HttpClient, private store: ScenariosStore) {}

	getEntityById(id: number) {
		this.loading$.next(true);
		return this.getDetail<Scenario>(id).pipe(
			map(value => isValidScenario(value)),
			finalize(() => {
				this.loading$.next(false);
			})
		);
	}

	updateEntity(data: Scenario) {
		this.loading$.next(true);
		return this.update<Scenario, Scenario>(data, data.id).pipe(
			tap(scenario => this.store.update(data.id, scenario)),
			finalize(() => {
				this.loading$.next(false);
			})
		);
	}

	createEntity(data: Scenario) {
		this.loading$.next(true);
		const dataWithoutId = JSON.parse(JSON.stringify(data));
		delete dataWithoutId.id;
		return this.create<Scenario, Scenario>(dataWithoutId).pipe(
			tap(scenario => this.store.update(data.id, scenario)),
			finalize(() => {
				this.loading$.next(false);
			})
		);
	}

	protected create<T, R>(data: T) {
		return this.http.post<R>(this.endpoint, data);
	}

	protected getDetail<T>(id: number) {
		return this.http.get<T>(`${this.endpoint}${id}/`);
	}

	protected update<T, R>(data: T, id: number | string) {
		return this.http.put<R>(`${this.endpoint}${id}/`, data);
	}
}
