import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ID } from '@datorama/akita';
import { SelectItem } from 'primeng/api';
import { TuiMapper } from '@taiga-ui/cdk';

import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { SegmentsValidateService } from 'app/modules/enKod/segments/segments-validate.service';
import { MailingGroupsService } from '@state-enKod/mailing-groups';
import { SelectItemValue, SelectItemWithDeleted } from '@state-enKod/segments';
import {
	UnsubscriptionConditionForm,
	SubscriptionDateForm,
	TimeRangeForm,
	UnsubscriptionReason
} from '@enKod/segments/segments-form.model';

import { CHANNEL_OPTIONS } from '../../../common/constants/channel-options';
import { OPTIONS_UNSUBSCRIPTION_TIME } from '../../../common/constants';
import { AbstractCondition } from '../../../abstract-condition.component';
import { UnitsDeclinationService } from '../../../common/services/units-declination.service';
import { mailingGroupSelectOptionFactory } from '../subscription-date-condition/functions';
import { REASON_OPTIONS } from './reason-options';

type Channel = 'any' | 'mob_push' | 'web_push' | 'email' | 'sms';

type MailingGroup = SelectItemWithDeleted & {
	value: { channel: 'email' | 'sms' };
} & {
	singleItem?: boolean;
};

@UntilDestroy()
@Component({
	selector: 'en-unsubscription-condition',
	templateUrl: './unsubscription-condition.component.html',
	styleUrls: [
		'../../../abstract-condition.component.scss',
		'./unsubscription-condition.component.scss'
	],
	providers: [
		UnitsDeclinationService,
		/** Селект каналов [0] */
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: CHANNEL_OPTIONS,
			multi: true
		},
		/** за все время / между датами [1] */
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: OPTIONS_UNSUBSCRIPTION_TIME,
			multi: true
		},
		/** Список групп рассылок [2] (async) */
		{
			provide: SELECT_OPTIONS_TOKEN,
			useFactory: mailingGroupSelectOptionFactory,
			deps: [MailingGroupsService],
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubscriptionConditionComponent
	extends AbstractCondition
	implements OnInit
{
	private correctMailingGroups$ = new Subject();

	mailingGroups: SelectItem[];

	markAsInvalid = false;

	isFirstLoad = true;

	private readonly unsubscribeReasonOptions: SelectItem<UnsubscriptionReason>[] =
		REASON_OPTIONS;

	private readonly emailReasons: UnsubscriptionReason[] = [
		'mail',
		'soft_bounce_limit',
		'blacklist'
	];

	constructor(
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: Array<SelectItem<string>[]>,
		public segmentsValidateService: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(segmentsValidateService, cd);
	}

	get typedForm(): FormGroup<UnsubscriptionConditionForm> {
		return this.form as FormGroup<UnsubscriptionConditionForm>;
	}

	get unsubscriptionControl(): FormGroup<SubscriptionDateForm> {
		return this.typedForm.controls
			.unsubscription as FormGroup<SubscriptionDateForm>;
	}

	get actionTime(): FormGroup<TimeRangeForm> {
		return this.unsubscriptionControl.controls
			.actionTime as FormGroup<TimeRangeForm>;
	}

	get channelControl(): FormControl<Channel> {
		return this.unsubscriptionControl.controls
			.channel as FormControl<Channel>;
	}

	get operatorControl(): FormControl<string> {
		return this.unsubscriptionControl.controls
			.operator as FormControl<string>;
	}

	get reasonControl(): FormControl<UnsubscriptionReason> {
		return this.unsubscriptionControl.controls
			.reason as FormControl<UnsubscriptionReason>;
	}

	get groupsControl(): FormControl<
		SelectItemWithDeleted[] | SelectItemValue[]
	> {
		return this.unsubscriptionControl.controls.groups as FormControl<
			SelectItemWithDeleted[] | SelectItemValue[]
		>;
	}

	get groupsControlValue() {
		return this.groupsControl.value;
	}

	get mailingGroupVisible(): boolean {
		return (
			this.channelControl.value === 'email' ||
			this.channelControl.value === 'sms'
		);
	}

	get isInvalidSelectedGroup(): boolean {
		return (
			this.operatorControl.value !== 'all' &&
			this.groupsControl.enabled &&
			!this.groupsControl.value?.length
		);
	}

	get showSetReasonButton(): boolean {
		return this.reasonControl.value === '';
	}

	get filteredReasonOptions() {
		const { value } = this.channelControl;

		let options: SelectItem<UnsubscriptionReason>[] = [];

		options =
			value === 'email'
				? this.unsubscribeReasonOptions
				: this.unsubscribeReasonOptions.filter(
						reason => !this.emailReasons.includes(reason.value)
				  );

		if (this.operatorControl.value === 'all') {
			options = options.filter(option => option.value !== 'import');
		}

		return options;
	}

	ngOnInit(): void {
		this.setCategory(this.typedForm.value.type || '');
		this.channelChange();
		this.operatorChange(this.operatorControl.value);
		this.setListeners();
	}

	private setListeners() {
		this.correctMailingGroups$
			.pipe(
				untilDestroyed(this),
				startWith(null),
				switchMap(
					() =>
						this.selectOptions[2] as unknown as Observable<
							MailingGroup[]
						>
				),
				map((list: MailingGroup[]) =>
					list.filter(message => {
						const { value } = this.channelControl;
						const channel = value === 'sms' ? 'phone' : value;
						return message.value.channel === channel;
					})
				),
				map(list => {
					const deletedItems: SelectItemWithDeleted<ID>[] = [];
					this.groupsControlValue?.forEach(item => {
						const typed = item as SelectItemValue;
						if (typed.isDeleted) {
							deletedItems.push({
								label: typed.name,
								value: {
									id: typed.id,
									name: typed.name,
									channel: typed.channel,
									isDeleted: typed.isDeleted
								},
								disabledItem: typed.isDeleted
							});
						}
					});

					return [...deletedItems, ...list];
				})
			)
			.subscribe(list => {
				this.mailingGroups = this.sortedGroups(list);
				this.cd.markForCheck();
			});

		this.segmentsValidateService.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.form.markAsPristine();
						this.markAsInvalid = false;
					} else {
						this.markAsInvalid = this.isInvalidSelectedGroup;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.groupsControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.markAsInvalid = this.isInvalidSelectedGroup;
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.segmentsValidateService.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.markAsInvalid = false;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	channelChange(): void {
		this.unsubscriptionControl.controls.groups.enable({ emitEvent: false });
		this.operatorControl.enable();
		this.correctMailingGroups$.next();
		if (!this.isFirstLoad) {
			this.groupsControl.patchValue([], { emitEvent: false });
		}

		this.isFirstLoad = false;

		if (this.reasonControl.value === '') return;

		this.changeReason();
	}

	operatorChange(operator: string): void {
		if (operator === 'all') {
			this.groupsControl.clearValidators();
			this.groupsControl.patchValue([], { emitEvent: false });

			if (this.reasonControl.value === 'import') {
				this.reasonControl.patchValue(
					this.filteredReasonOptions[0].value
				);
			}

			return;
		}
		this.groupsControl.setValidators([Validators.required]);
	}

	deselectItem(index: number): void {
		const arrCopy = [...this.groupsControl.value];
		arrCopy.splice(index, 1);
		this.groupsControl.patchValue(arrCopy as SelectItemWithDeleted[]);
	}

	initSort(): void {
		this.unsubscriptionControl.controls.groups?.value?.forEach(
			(id: SelectItemWithDeleted) => {
				this.mailingGroups = this.mailingGroups.sort((a, b) => {
					if (a.value.id === id) return -1;
					return b.value.id === id ? 1 : 0;
				});
			}
		);
	}

	mapMultiSelect: TuiMapper<SelectItemValue<number>, any> = (
		item,
		groups: SelectItemWithDeleted<number>[]
	) => groups?.find(group => group.value.id === item.id);

	sortedGroups(group: SelectItem[]) {
		return group.sort((a, b) => {
			if (a.value.id > b.value.id) return -1;
			if (a.value.id < b.value.id) return 1;
			return 0;
		});
	}

	setReason() {
		const { value } = this.channelControl;
		const reason: UnsubscriptionReason =
			value === 'email' ? 'mail' : 'manual';

		this.reasonControl.patchValue(reason);
	}

	private changeReason() {
		if (
			this.channelControl.value === 'sms' &&
			this.emailReasons.includes(this.reasonControl.value)
		) {
			this.reasonControl.patchValue('manual');
		}
	}
}
