<en-dialog [(visible)]="modal"
           [modal]="true"
           headerBackground="warn"
           [headerIcon]="'en en-exclamation-triangle-o'"
           [header]="'segment_list.warn_delete_title'  | translate"
           closeIcon="en en-close"
           [style]="{width: '38.75rem',height: '33.75rem',position:'relative'}"
           [focusOnShow]="false"
           [resizable]="false"
           (onHide)="hide.emit()">
    <div class="modal_body">
        <div class="connections__text en-text-medium"
             [innerHTML]="'segment_list.warn_delete_text' | translate: {segmentName: segmentName}">

        </div>
        <ng-container *ngIf="relations; else templateName">
            <ng-container *ngIf="relations?.popups">
                <span class="type-title">{{'segment_list.warn_type_popup' | translate}}</span>
                <div class="popups">
                    <div class="preview-wrapper"
                         *ngFor="let popup of relations?.popups">
                        <en-popup-card [popup]="popup"
                                       [withTools]="false"
                                       [selectable]="false">
                        </en-popup-card>
                    </div>
                </div>
            </ng-container>

            <div class="message-container"
                 *ngIf="relations?.emails">
                <span class="type-title">{{'segment_list.warn_type_email' | translate}}</span>
                <div class="message"
                     *ngFor="let email of relations?.emails">
                    <span class="id-badge en-mr-2">id {{email.id}}</span>
                    <span class="en-text-large">{{email.name}}</span>
                </div>
            </div>

            <div class="message-container"
                 *ngIf="relations?.pushes">
                <span class="type-title">{{'segment_list.warn_type_push' | translate}}</span>
                <div class="message"
                     *ngFor="let push of relations?.pushes">
                    <span class="id-badge en-mr-2">id {{push.id}}</span>
                    <span class="en-text-large">{{push.name}}</span>
                </div>
            </div>
        </ng-container>
        <ng-template #templateName>
            <en-loader-overlay></en-loader-overlay>
        </ng-template>


    </div>

    <p-footer>
        <en-button styleClass="en-button-secondary h36 margin--mod"
                   [label]="'get-service-modal-component.back-button' | translate"
                   (click)="hide.emit()"></en-button>
    </p-footer>
</en-dialog>
