import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Inject,
	EventEmitter,
	Output,
	ChangeDetectorRef,
	Input,
	TemplateRef,
	ContentChildren,
	QueryList,
	AfterContentInit
} from '@angular/core';

import {
	MailingGroupsState,
	MailingGroup,
	MailingGroupsQuery,
	MailingGroupsService
} from '@state-enKod/mailing-groups';

import { TableColumn } from '@enkod-core/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorPlugin, PaginationResponse, ID } from '@datorama/akita';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { PaginationService, PAGINATION_DATA, RelatedDataItem } from 'ui-lib';
import { TableToolsService } from 'app/ui-lib/table/table-tools.service';
import { ConfirmationService } from 'primeng/api';

import { PAGINATOR_PLUGIN_TOKEN } from '@enkod-core/tokens/paginator-plugin-token';
import { TABLE_COLS_TOKEN } from '@enkod-core/tokens/table-cols-token';
import { UrlParams, UrlSaverService } from '@enkod-core/services';
import { EnTempalateDirective } from '@shared';

@UntilDestroy()
@Component({
	selector: 'en-mailing-groups-table',
	templateUrl: './mailing-groups-table.component.html',
	styleUrls: ['./mailing-groups-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailingGroupsTableComponent implements OnInit, AfterContentInit {
	showRelatedDataDialog = false;
	mailingGroups: MailingGroupsState[] = [];
	total = 0;
	urlParams: UrlParams;
	relatedMessages: RelatedDataItem[];

	constructor(
		public tableToolsService: TableToolsService,
		private confirmationService: ConfirmationService,
		private translate: TranslateService,
		public paginationService: PaginationService,
		private cd: ChangeDetectorRef,
		private mailingGroupsQuery: MailingGroupsQuery,
		private mailingGroupsService: MailingGroupsService,
		@Inject(PAGINATOR_PLUGIN_TOKEN)
		public readonly paginatorRef: PaginatorPlugin<MailingGroup>,
		@Inject(PAGINATION_DATA)
		private readonly data$: Observable<PaginationResponse<MailingGroup>>,
		@Inject(TABLE_COLS_TOKEN)
		public cols: TableColumn[],
		private urlService: UrlSaverService
	) {}

	@Output() editEntity = new EventEmitter<ID>();

	@Output() copyEntity = new EventEmitter<ID>();

	@Output() deleteEntity = new EventEmitter<ID>();

	@Output() showDetail = new EventEmitter<ID>();

	@Input() toolsPanel = true;

	@Input() selection = false;

	@Input() notSaveInUrl = false;

	@Input() selectable = false;

	@Input() maxSelectedItems: number;

	@Input() selectedEntity: MailingGroup[];

	@Input() previewLink = true;

	@Output() selectedEntityChange = new EventEmitter<MailingGroup>();

	@Output() selected = new EventEmitter<MailingGroup>();

	toolsTemplate: TemplateRef<any>;

	@ContentChildren(EnTempalateDirective) templates: QueryList<any>;

	ngOnInit(): void {
		this.urlParams = this.urlService.getQueryParams();
		this.updateTableCols();

		this.data$.pipe(untilDestroyed(this)).subscribe(({ data, total }) => {
			this.mailingGroups = data;
			this.total = total || 0;
			this.cd.markForCheck();
		});
	}

	ngAfterContentInit(): void {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'tools':
					this.toolsTemplate = item.template;
					break;

				default:
					break;
			}
		});
	}

	private updateTableCols(): void {
		this.cols = [
			...(this.selectable
				? [
						{
							field: 'selectCheckbox',
							width: '48px',
							reorderableDisabled: true
						}
				  ]
				: []),
			...this.cols
		];
	}

	onDeleteEntity(id: number): void {
		this.paginatorRef.setLoading(true);

		this.mailingGroupsService
			.checkRelatesMessages(id)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.paginatorRef.setLoading(false);
				})
			)
			.subscribe(resp => {
				if (resp.length) {
					this.relatedMessages = resp;
					this.showRelatedDataDialog = true;
				} else this.confirmDelete(id);
			});
	}

	private confirmDelete(id: number) {
		const entity = this.mailingGroupsQuery.getEntity(id) as MailingGroup;

		this.confirmationService.confirm({
			key: 'confirmDelete',
			message: this.translate.instant(
				'mailing_groups_form.confirm_delete',
				{
					id: entity.id,
					name: entity.name
				}
			),
			accept: () => this.deleteEntity.emit(id)
		});
	}

	refresh(): void {
		this.paginatorRef.refreshCurrentPage();
	}

	checkboxDisabled(data: MailingGroup) {
		if (!this.maxSelectedItems) return false;
		return (
			this.selectedEntity.length >= this.maxSelectedItems &&
			!this.selectedEntity.find(entity => entity?.id === data?.id)
		);
	}
}
