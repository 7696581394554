import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewsContainerComponent } from './previews-container.component';
import { FullscreenPreviewModule } from '../fullscreen-preview/fullscreen-preview.module';
import { MobilePreviewModule } from '../mobile-preview/mobile-preview.module';
import { DesktopPreviewModule } from '../desktop-preview/desktop-preview.module';

@NgModule({
	imports: [
		CommonModule,
		FullscreenPreviewModule,
		MobilePreviewModule,
		DesktopPreviewModule
	],
	declarations: [PreviewsContainerComponent],
	exports: [PreviewsContainerComponent]
})
export class PreviewsContainerModule {}
