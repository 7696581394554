import { SelectItem } from 'primeng/api';

export const TIME_OPTIONS: SelectItem[] = [
	{
		label: 'segment_form.options_period_all',
		value: 'all'
	},
	{
		label: 'segment_options.equal_options',
		value: 'equal'
	},
	{
		label: 'segment_form.options_operator_less',
		value: 'less'
	},
	{
		label: 'segment_form.options_operator_more',
		value: 'more'
	}
];

export const PARAMETER_OPTIONS: SelectItem[] = [
	{
		label: 'segment_form.cond_favourite_total',
		value: 'quantity'
	},
	{
		label: 'segment_options.with_id',
		value: 'id'
	},
	{
		label: 'segment_form.cond_favourite_at_least',
		value: 'atLeastOne'
	}
];
