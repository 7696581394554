import { Component, Input } from '@angular/core';

@Component({
	selector: 'en-ios-mock',
	templateUrl: './ios-mock.component.svg',
	styleUrls: ['./ios-mock.component.scss']
})
export class IosMockComponent {
	colorMain: string;
	colorBg: string;

	@Input('isDetail')
	set isDetailSetter(value: boolean) {
		this.colorMain = value ? '#C5C9D6' : '#FFF';
		this.colorBg = value ? '#B9BFCC' : '#79A4D9';
	}
}
