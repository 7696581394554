import { Pipe, PipeTransform } from '@angular/core';

type UserStatus = 'active' | 'inactive' | 'waiting for confirmation';

@Pipe({
	name: 'userStatusValue'
})
export class UserStatusValuePipe implements PipeTransform {
	transform(status: UserStatus): string {
		switch (status) {
			case 'inactive':
				return 'admin_users_list.table_label_inactive';
			case 'waiting for confirmation':
				return 'admin_users_list.status_wait';
			default:
				return 'admin_accounts_list.active';
		}
	}
}
