import { DATE_FILLER_LENGTH, TuiDay } from '@taiga-ui/cdk';
import IMask from 'imask';

export function createAutoCorrectedDateRangePipe(
	value: string,
	masked: IMask.Masked<string>
) {
	if (value.length < DATE_FILLER_LENGTH) return;

	const date = value.substring(0, 10);
	const dateTo = value.substring(13, 23);

	const dataTui = TuiDay.normalizeParse(date);
	const dataToTui = TuiDay.normalizeParse(dateTo);

	// eslint-disable-next-line no-param-reassign
	if (dataToTui < dataTui) masked.value = `${date} - ${date}`;
}
