import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { ExtraField } from './extra-field.model';

export interface ExtraFieldsState extends EntityState<ExtraField> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'extra-fields' })
export class ExtraFieldsStore extends EntityStore<ExtraFieldsState> {
	constructor() {
		super({
			[ALL_LIST_STORE_NAME]: null
		});
	}
}
