<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown styleClass="en-dropdown-solid margin-bottom"
                 [class.active-hide]="showHide"
                 [options]="actionOptions"
                 formControlName="action">
    </en-dropdown>
    <en-dropdown styleClass="en-dropdown-solid margin-bottom"
                 [class.active-hide]="showHide"
                 [placeholder]="'segment_form.pick' | translate"
                 [options]="sqlTemplatesOptions"
                 [useWidth80]="true"
                 formControlName="id"
                 (onChange)="onTemplateChange($event.value)">
        <ng-template let-condition
                     pTemplate="item">
            <div class="options-groups">
                <span class="text-overflow options-groups__main-content">
                    <span class="id-badge"
                          style="margin-right: 0.5rem;">
                        id {{ condition.value }}
                    </span>
                    <span class="text-overflow">{{ condition.label }}</span>
                </span>
            </div>
        </ng-template>
    </en-dropdown>

    <div>
        <en-sql-params [class.active-hide]="showHide"
                       [submitted]="submitted"
                       formControlName="params"></en-sql-params>
    </div>

    <button *ngIf="hasSqlTemplate"
            enButton
            class="en-button-lowercase h32"
            [class.active-hide]="showHide"
            icon="en en-expand"
            (click)="sqlPreviewVisible = true"></button>

    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>
</form>

<en-sql-preview *ngIf="sqlPreviewVisible"
                [sqlPreviewVisible]="sqlPreviewVisible"
                [allTemplates]="allTemplates"
                [id]="form.value.id"
                (onHide)="sqlPreviewVisible = false"></en-sql-preview>
