import { SelectItem } from 'primeng/api';

export const OptionsParameter: SelectItem[] = [
	{
		label: 'segment_options.with_id',
		value: 'id'
	},
	{
		label: 'segment_form.cond_categories_at_least_one',
		value: 'atLeastOne'
	}
];

export const OptionsPeriod: SelectItem[] = [
	{
		label: 'segment_options.between_date',
		value: 'between'
	},
	{
		label: 'segment_form.options_period_all',
		value: 'all'
	},
	{
		label: 'segment_form.options_count_equal',
		value: 'equal'
	},
	{
		label: 'segment_form.options_operator_less',
		value: 'less'
	},
	{
		label: 'segment_form.options_operator_more',
		value: 'more'
	}
];
