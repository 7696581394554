<div #wrapper class="preview__container" [style.position]="dynamic && 'relative'">
     <div #preview
          class="desktop__preview"
          [style.height]="editorStyle.previewHeight / 16 + 'rem'"
          [style.position]="dynamic && createPopup && 'absolute'"
          [style.min-height]="!dynamic && '32.5rem'">
          <div class="desktop__header">
               <span class="desktop__ellipse"></span>
               <span class="desktop__ellipse"></span>
               <span class="desktop__ellipse"></span>
          </div>
          <div class="iframe"
               [style.height]="editorStyle.height / 16 + 'rem'"
               [style.width]="editorStyle.width / 16 + 'rem'"
               [style.transform]="editorStyle.transform">
               <img #image
                    class="iframe__background"
                    [src]="desktopBackground">
               <en-popup-preview-iframe class="iframe__template"
                                        [style.margin-top]="dynamic && marginTopValue"
                                        [template]="template"></en-popup-preview-iframe>
          </div>
     </div>
</div>