import { FormArray } from '@angular/forms';

/**
 * Проверяет FormArray с группами, содержащими контрол 'name',
 * и устанавливает ошибку 'isTakenValue' только у последнего дублированного контрола.
 * Возвращает true, если дубликатов нет, и false, если найдены дубликаты.
 */
export function validateListDuplicates(formArray: FormArray): boolean {
	const { controls } = formArray;
	const valueMap = new Map<string, number[]>();

	controls.forEach((group, index) => {
		const ctrl = group.get('name');
		if (!ctrl) return;

		if (ctrl.errors?.isTakenValue) {
			const { isTakenValue, ...remainingErrors } = ctrl.errors;
			ctrl.setErrors(
				Object.keys(remainingErrors).length ? remainingErrors : null
			);
		}

		const value = ctrl.value?.trim();
		if (value) {
			valueMap.has(value)
				? valueMap.get(value)!.push(index)
				: valueMap.set(value, [index]);
		}
	});

	let valid = true;
	valueMap.forEach(indices => {
		if (indices.length > 1) {
			const lastIndex = indices[indices.length - 1];
			const ctrl = controls[lastIndex].get('name');
			if (ctrl) {
				ctrl.setErrors({ ...ctrl.errors, isTakenValue: true });
				valid = false;
			}
		}
	});

	return valid;
}
