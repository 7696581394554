<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsCategoryData"
                 formControlName="type"
                 (onChange)="onChangeType($event)"></en-dropdown>

    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsOperatorEquality"
                 [formControl]="form.get('equality')"></en-dropdown>

    <ng-container>
        <en-multiSelect class="condition__subscripion subscripion"
                        styleClass="en-dropdown-solid"
                        [class.active-hide]="showHide"
                        [options]="selectItems"
                        [filter]="true"
                        filterBy="label,value.id"
                        [emptyFilterMessage]="'segment_form.cond_subscription_empty_message' | translate"
                        [showToggleAll]="false"
                        [showCloseButton]="false"
                        [useSelectAllItem]="true"
                        [isInvalid]="markAsInvalid"
                        [useInputWidth85]="true"
                        [placeholder]="'segment_common.group_placeholder' | translate"
                        formControlName="value"
                        (onPanelHide)="initSort()">
            <ng-template pTemplate="selectedItems"
                         let-selectedItems>
                <div *ngIf="selectedItems?.length === 0">{{'segment_common.group_placeholder' |
                    translate}}</div>
                <ng-container *ngIf="selectedItems?.length !== 0 && selectedItems?.length < 3">
                    <ng-container *ngFor="let item of selectedItems; index as idx">
                        <en-mailing-group-info *tuiLet="item | tuiMapper: mapMultiSelect:selectItems as group"
                                               class="en-mailing-group-info"
                                               [icon]="group?.value.channel"
                                               [name]="group?.label"
                                               [id]="group?.value.id"
                                               [wasDeleted]="group?.value.isDeleted"
                                               [tippyText]="'message_detail_info.group_was_removed' | translate"
                                               [isDelete]="true"
                                               [useMaxWidth85]="true"
                                               (deleteItem)="deselectItem(idx)"></en-mailing-group-info>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedItems?.length > 2">
                    <span>{{'segment_form.selected' | translate:{value: selectedItems?.length} }}</span>
                </ng-container>
            </ng-template>
            <ng-template let-condition
                         pTemplate="item">
                <div class="options-groups"
                     [tippy]="condition.value.isDeleted && 'message_detail_info.group_was_removed' | translate"
                     [class.disabled-receiver]="condition.value.isDeleted">
                    <span class="text-overflow options-groups__main-content">
                        <span class="id-badge"
                              style="margin-right: 0.5rem;">
                            id {{ condition.value.id }}
                        </span>

                        <span class="text-overflow">{{ condition.label }}</span>
                    </span>

                    <span>
                        <i [class]="condition.value.channel | typeIcon"></i>
                    </span>
                </div>
            </ng-template>
        </en-multiSelect>
    </ng-container>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>
</form>
