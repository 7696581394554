import {
	Component,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	HostListener,
	AfterViewInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { HtmlConditionForm } from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../abstract-condition.component';

@Component({
	selector: 'en-html-condition',
	templateUrl: './html-condition.component.html',
	styleUrls: [
		'../../abstract-condition.component.scss',
		'./html-condition.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlConditionComponent
	extends AbstractCondition
	implements AfterViewInit
{
	readonly operatorOptions: SelectItem[] = [
		{
			label: 'segment_form.options_operator_contain',
			value: 'contain'
		},
		{
			label: 'segment_options.not_contain_options',
			value: 'not_contain'
		}
	];

	textarea: HTMLElement;
	heightTextarea: string;

	@ViewChild('html') nameField: ElementRef;

	@HostListener('document:click', ['$event'])
	onClick(event: any) {
		if (!this.textarea.contains(event.target)) {
			this.textarea.style.height = this.heightTextarea;
		}
	}

	ngAfterViewInit() {
		const { value } = (this.form as FormGroup<HtmlConditionForm>).value;

		this.textarea = this.nameField.nativeElement;
		this.textarea.style.height = value
			? '30px'
			: `${this.textarea.scrollHeight}px`;

		this.heightTextarea = this.textarea.style.height;
	}
}
