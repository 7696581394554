import { ID } from '@datorama/akita';
import { StatusTypeEnum } from './enums';

export interface Block {
	type: BlockType;
	subType: BlockSubType;
	options: any; // во всех опциях должен быть port: string, остальные свойства уникальны
	ports: BlockPorts;
	position: BlockPosition;
	angle: number;
	id: ID;
	z: number;
}

export interface Link {
	type: BlockType;
	source: LinkSource;
	target: LinkSource;
	id: ID;
	z: number;
}

export interface BlockPorts {
	items: [
		{ group: PortItemsGroup; id: string },
		{ group: PortItemsGroup; id: string }
	];
}

export interface BlockPosition {
	x: number;
	y: number;
}

export interface LinkSource {
	id: ID;
	magnet: LinkSourceMagnet;
	port: string;
}

export type PortItem = {
	group: PortItemsGroup;
	id: ID;
};

export type OnChangeOptions = {
	updatePorts: boolean;
};

export type Option = {
	port: ID;
	[key: string]: any;
};

export interface Subtype {
	text: string;
	iconLinkHref: string;
}

export type StatusType = StatusTypeEnum;

export type BlockType = 'startBlock' | 'mainBlock' | 'endBlock' | 'enKodLink';

export type BlockSubType =
	| 'mailingGroup'
	| 'pause'
	| 'sendMessage'
	| 'finish'
	| 'apiStart'
	| 'messageStart'
	| 'extraFieldStart';

export type PortItemsGroup = 'in' | 'out';

export type LinkSourceMagnet = 'portBody' | 'newBody';

export type CanvaMode = 'scenario' | 'chatbot';
