import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { EventData, Param, ParamData, UserEvent } from './event-start.model';
import { EventStartStore } from './event-start.store';
import { EventStartQuery } from './event-start.query';
import { EventStartParamsStore } from './event-start-params.store';
import { EventStartParamsQuery } from './event-start-params.query';

@Injectable()
export class EventStartDataService {
	constructor(
		private http: HttpClient,
		private eventsStore: EventStartStore,
		private paramsStore: EventStartParamsStore,
		private eventsQuery: EventStartQuery,
		private paramsQuery: EventStartParamsQuery
	) {}

	getEvents() {
		if (this.eventsQuery.hasEntity()) {
			return this.eventsQuery.events$;
		}
		return this.getEventList();
	}

	getParams() {
		if (this.paramsQuery.hasEntity()) {
			return this.paramsQuery.params$;
		}
		return this.getParamList();
	}

	getEventList(): Observable<UserEvent[]> {
		return this.http.get<EventData>('enkod/customEvent').pipe(
			map(r => r.result || []),
			tap(resp => {
				this.eventsStore.add(resp);
			})
		);
	}

	getParamList(): Observable<Param[]> {
		return this.http.get<ParamData>('enkod/param').pipe(
			map(r => r.result || []),
			tap(resp => {
				this.paramsStore.add(resp);
			})
		);
	}
}
