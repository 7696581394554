import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
	Optional,
	Self
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	NgControl,
	NgModel
} from '@angular/forms';
import { UrlSaverService } from '@enkod-core/services';
import { EnsendSharedQuery, EnsendSharedService } from '@enSend/_shared/_state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FiltersSegment } from '@state-enKod/segments';
import { EMPTY_FUNCTION, tuiDefaultProp, tuiPure } from '@taiga-ui/cdk';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { SEGMENT_TYPE_OPTIONS } from './constants';

@UntilDestroy()
@Component({
	selector: 'en-segments-filter',
	templateUrl: './segments-filter.component.html',
	styleUrls: ['./segments-filter.component.scss'],
	providers: [EnsendSharedService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentsFilterComponent<T extends FiltersSegment>
	implements OnInit
{
	private previousInternalValue?: T | null;
	private onTouched = EMPTY_FUNCTION;
	private onChange = EMPTY_FUNCTION;

	form: UntypedFormGroup = this.fb.group({
		type: null,
		tags: null
	});

	items: Array<keyof FiltersSegment>;

	optionsMap: { [key in keyof Partial<FiltersSegment>]: any } = {
		type: SEGMENT_TYPE_OPTIONS,
		tags: this.sharedQuery.tagsWithoutSys$
	};

	@Input('items')
	@tuiDefaultProp()
	set itemsSetter(value: Array<keyof FiltersSegment>) {
		this.items = value;
		this.form.updateValueAndValidity();
	}

	constructor(
		public sharedQuery: EnsendSharedQuery,
		private sharedService: EnsendSharedService,
		private fb: UntypedFormBuilder,
		@Optional()
		@Self()
		@Inject(NgControl)
		private readonly ngControl: NgControl | null,
		@Inject(ChangeDetectorRef)
		private readonly changeDetectorRef: ChangeDetectorRef,
		private urlService: UrlSaverService
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}
	}

	get value(): T {
		return this.previousInternalValue || ({} as T);
	}

	ngOnInit(): void {
		const filters = this.urlService.convertStringParamsToArrays();

		this.sharedService.getTags();
		if (this.ngControl?.valueChanges) {
			this.ngControl.valueChanges
				.pipe(
					untilDestroyed(this),
					take(1),
					tap(value => {
						this.form.reset(value);
						this.form.patchValue({ ...filters });
						this.checkControlUpdate();
					})
				)
				.subscribe();
		}

		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(120),
				tap(value => {
					this.updateValue({ ...this.value, ...value });
				})
			)
			.subscribe();
	}

	writeValue(value: T | null) {
		const controlValue =
			this.ngControl instanceof NgModel &&
			this.previousInternalValue === undefined
				? this.ngControl.model
				: value;

		this.refreshLocalValue(controlValue);
	}

	registerOnChange(onChange: (value: T | unknown) => void) {
		this.onChange = (componentValue: T) => {
			onChange(componentValue);
		};
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	checkControlUpdate() {
		this.changeDetectorRef.markForCheck();
	}

	private refreshLocalValue(value: T | undefined) {
		this.previousInternalValue = value;
		this.checkControlUpdate();
	}

	private updateValue(value: T) {
		if (this.valueIdenticalComparator(this.value, value)) {
			return;
		}

		this.previousInternalValue = value;
		this.controlSetValue(value);
	}

	private valueIdenticalComparator(oldValue: T, newValue: T): boolean {
		return JSON.stringify(oldValue) === JSON.stringify(newValue);
	}

	private controlSetValue(value: T) {
		this.onChange(value);
		this.checkControlUpdate();
	}

	@tuiPure
	isVisible(filterName: keyof FiltersSegment): boolean {
		return !this.items.includes(filterName);
	}

	@tuiPure
	getItemOptions(
		item: keyof FiltersSegment
	): SelectItem[] | Observable<SelectItem[]> {
		return this.optionsMap[item];
	}

	remove(filterName: string) {
		this.form.controls[filterName].patchValue(null);
	}
}
