import { ID } from '@datorama/akita';

export enum DataTypeField {
	text = 'text',
	number = 'number',
	float = 'float',
	bool = 'bool',
	date = 'date',
	dateTime = 'dateTime',
	list = 'list'
}

export interface ExtraField {
	id: number;
	name: string;
	serviceName: string;
	dataType: ExtraFieldDataTypes;
	listOptions: ListOption[];
	description: string;
}

export interface ListOption {
	id: ID;
	name: string;
}

export interface ExtraFieldSelect extends ExtraField {
	value: ID;
	label: string;
}

export interface IDeleteData {
	id: number;
	name: string;
}

export type ActionExtraField = 'edit' | 'create';

export type ExtraFieldDataTypes = keyof typeof DataTypeField;
