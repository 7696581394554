import { Injectable } from '@angular/core';

import { CRUDService } from '@enkod-core/abstract';
import { FieldsModel } from '../model/fields';

@Injectable({
	providedIn: 'root'
})
export class FieldsDataService extends CRUDService<FieldsModel> {
	constructor() {
		super('ensend/scenario/fields/');
	}
}
