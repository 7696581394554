import { inject } from '@angular/core';
import { DateAccountTimezoneService } from '@enkod-core/services';
import { TuiDay, TuiDayLike, TuiMonth, TuiTime } from '@taiga-ui/cdk';
import { DateTimeRange } from 'app/ui-lib/calendar-range-new/calendar-range.model';
import {
	DateTime,
	LENGTH_DATE,
	LENGTH_DATE_TIME,
	LENGTH_TIME,
	nullDate,
	MINIMAL_VALUE_DATE_IN_SECONDS
} from 'app/ui-lib/calendarNew/calendar.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type MonthRange = [TuiMonth, TuiMonth];

export class CalendarService {
	private isSingle = true;
	private _showTime = false;
	private date: DateTime = {
		date: new TuiDay(0, 0, 1),
		time: TuiTime.currentLocal()
	};

	private day = new TuiDay(0, 0, 1);

	private dateRange: DateTimeRange = [
		{ date: new TuiDay(0, 0, 1), time: new TuiTime(0, 0) },
		{ date: new TuiDay(0, 0, 1), time: new TuiTime(23, 59) }
	];

	private min = new TuiDay(0, 0, 1);
	private max = new TuiDay(TuiDay.currentLocal().year + 10, 11, 31);
	private period: string;
	private type = 'date';

	timezoneService = inject(DateAccountTimezoneService);

	currentDateNumber = 0;
	currentDay = new Subject<TuiDay>();
	currentDate = new Subject<DateTime>();
	currentDateRange = new Subject<DateTimeRange>();
	showDate = new BehaviorSubject<TuiMonth>(TuiMonth.currentLocal());

	currentShowDate = this.showDate.asObservable();
	showDateRange: BehaviorSubject<MonthRange>;
	currentShowDateRange: Observable<MonthRange>;

	get currentNumberDate(): number {
		return this.getNumberDate(
			TuiDay.currentLocal(),
			TuiTime.currentLocal()
		);
	}

	get showTime() {
		return this._showTime;
	}

	set showTime(value: boolean) {
		this._showTime = value;
	}

	getIsSingle() {
		return this.isSingle;
	}

	getMinDate(): TuiDay {
		return this.min;
	}

	getPeriod(): string {
		return this.period;
	}

	getMaxDate(): TuiDay {
		return this.max;
	}

	getDate(): TuiDay {
		return this.date?.date;
	}

	getDay(): TuiDay {
		return this.day;
	}

	getDateRange(): DateTimeRange {
		return this.dateRange;
	}

	getMonth(): TuiMonth {
		return new TuiMonth(this.date.date.year, this.date.date.month);
	}

	getShowDate(): TuiMonth {
		return this.showDate.getValue();
	}

	getShowDateRange(): MonthRange {
		if (!this.showDateRange) {
			this.showDateRange = this.getDateRangeByPeriod();
			this.currentShowDateRange = this.showDateRange.asObservable();
		}
		return this.showDateRange.getValue();
	}

	getType() {
		return this.type;
	}

	getDateRangeByPeriod() {
		if (this.period === 'past') {
			const invalidPreviousMonth = TuiMonth.currentLocal().month - 1 < 0;
			return new BehaviorSubject<MonthRange>([
				invalidPreviousMonth
					? new TuiMonth(TuiMonth.currentLocal().year - 1, 11)
					: new TuiMonth(
							TuiMonth.currentLocal().year,
							TuiMonth.currentLocal().month - 1
					  ),
				TuiMonth.currentLocal()
			]);
		}
		const invalidMonth = TuiMonth.currentLocal().month + 1 > 11;

		return new BehaviorSubject<MonthRange>([
			TuiMonth.currentLocal(),
			invalidMonth
				? new TuiMonth(TuiMonth.currentLocal().year + 1, 0)
				: new TuiMonth(
						TuiMonth.currentLocal().year,
						TuiMonth.currentLocal().month + 1
				  )
		]);
	}

	getNumberDate(date: TuiDay, time: TuiTime, fromControl?: boolean): number {
		const toSeconds =
			new Date(
				date.year,
				date.month,
				date.day,
				time.hours,
				time.minutes
			).getTime() / 1000;

		const accountDate = this.timezoneService.toUtcMidnight(toSeconds);
		const returnedDate = fromControl ? toSeconds : accountDate;
		return returnedDate > MINIMAL_VALUE_DATE_IN_SECONDS ? returnedDate : 0;
	}

	getNumberDateForString(): number {
		const numberDate =
			+new Date(
				this.date.date.year,
				this.date.date.month,
				this.date.date.day,
				this.date.time.hours,
				this.date.time.minutes
			) / 1000;
		return numberDate > 0 ? numberDate : 0;
	}

	getStringDateTime(value: number, fromControl?: boolean): string {
		const dateNumber = fromControl
			? new Date(value * 1000)
			: new Date(this.timezoneService.fromUtcWithAccountTz(value));

		const date = `${dateNumber.getDate().toString().padStart(2, '0')}.${(
			dateNumber.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}.${dateNumber.getFullYear()}, ${dateNumber
			.getHours()
			.toString()
			.padStart(2, '0')}:${dateNumber
			.getMinutes()
			.toString()
			.padStart(2, '0')}`;

		this.currentDateNumber = value;

		return date;
	}

	getStringTime(value: number): string {
		const dateNumber = new Date(
			+this.timezoneService.dateWithAccountTimezone(value)
		);

		const time = `${dateNumber
			.getHours()
			.toString()
			.padStart(2, '0')}:${dateNumber
			.getMinutes()
			.toString()
			.padStart(2, '0')}`;

		return time;
	}

	getStringEndTime(value: number): string {
		const dateNumber = new Date(
			+this.timezoneService.dateWithAccountTimezone(value)
		);

		const time = `${dateNumber
			.getHours()
			.toString()
			.padStart(2, '0')}:${dateNumber
			.getMinutes()
			.toString()
			.padStart(2, '0')}`;

		return time;
	}

	getTime(): TuiTime {
		return this.date.time;
	}

	getDateTime(value: number): string {
		if (!value) return ``;

		const dateNumber = new Date(
			+this.timezoneService.dateWithAccountTimezone(value)
		);

		const date = `${dateNumber.getDate().toString().padStart(2, '0')}.${(
			dateNumber.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}.${dateNumber.getFullYear()}, ${dateNumber
			.getHours()
			.toString()
			.padStart(2, '0')}:${dateNumber
			.getMinutes()
			.toString()
			.padStart(2, '0')}`;

		this.currentDateNumber = value;

		return date;
	}

	getDateString(value: number): string {
		if (!value) return ``;

		const dateNumber = new Date(
			+this.timezoneService.dateWithAccountTimezone(value)
		);

		const date = `${dateNumber.getDate().toString().padStart(2, '0')}.${(
			dateNumber.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}.${dateNumber.getFullYear()}`;
		return date;
	}

	getDateRangeString(): string {
		if (this.dateRange[0].date.daySame(nullDate)) return ``;
		if (this.dateRange[1].date.daySame(nullDate))
			return `${this.dateRange[0].date.toString()} - `;
		return `${this.dateRange[0].date.toString()} - ${this.dateRange[1].date.toString()}`;
	}

	getDateTimeRangeString(): string {
		if (this.dateRange[0].date.daySame(nullDate)) return ``;
		if (this.dateRange[1].date.daySame(nullDate))
			return `${this.dateRange[0].date.toString()}, ${this.dateRange[0].time
				.toString()
				.substring(0, 5)} - `;
		return `${this.dateRange[0].date.toString()}, ${this.dateRange[0].time
			.toString()
			.substring(
				0,
				5
			)} - ${this.dateRange[1].date.toString()}, ${this.dateRange[1].time
			.toString()
			.substring(0, 5)}`;
	}

	changeType(type: string) {
		type === 'date' ? (this.type = 'date') : (this.type = 'dateTime');
	}

	changeFormat(format: string) {
		format === 'single' ? (this.isSingle = true) : (this.isSingle = false);
	}

	changePeriod(period: string) {
		this.period = period;

		const date = new Date(this.timezoneService.dateWithAccountTimezone());
		const tuiDayDate = TuiDay.fromLocalNativeDate(date);

		if (period === 'past') this.max = tuiDayDate;
		else if (period === 'future') this.min = tuiDayDate;
	}

	changeMinMaxValue(min: TuiDay, max: TuiDay) {
		this.min = min;
		this.max = max;
	}

	changeTime(time: string) {
		const inputDate = this.getNumberDate(
			this.date.date,
			TuiTime.fromString(time.substring(0, 5))
		);

		if (time.length === LENGTH_TIME) {
			switch (this.period) {
				case 'future':
					this.date = {
						date: this.date.date,
						time:
							inputDate < this.currentNumberDate
								? TuiTime.currentLocal()
								: TuiTime.fromString(time.substring(0, 5))
					};
					break;
				case 'past':
					this.date = {
						date: this.date.date,
						time:
							inputDate > this.currentNumberDate
								? TuiTime.currentLocal()
								: TuiTime.fromString(time.substring(0, 5))
					};
					break;
				default:
					this.date = {
						date: this.date.date,
						time: TuiTime.fromString(time.substring(0, 5))
					};
			}
			this.currentDate.next(this.date);
		}
	}

	changeTimeRange(time: string) {
		if (time.length === LENGTH_TIME) {
			this.date = {
				date: this.date.date,
				time: TuiTime.fromString(time.substring(0, 5))
			};
			this.currentDate.next(this.date);
		}
	}

	changeDate(date: string) {
		if (date.length === LENGTH_DATE) {
			this.date = {
				date: TuiDay.normalizeParse(date.substring(0, 10)),
				time: TuiTime.currentLocal()
			};
			this.currentDate.next(this.date);
		} else {
			this.date = {
				date: TuiDay.currentLocal(),
				time: TuiTime.currentLocal()
			};
			this.currentDate.next(this.date);
		}
	}

	changeCurrentDayFromInput(day: TuiDay) {
		this.day = day;
		this.currentDay.next(day);
	}

	resetCurrentDayFromInput() {
		this.day = new TuiDay(0, 0, 1);
	}

	changeInputFirstTime(time: string) {
		if (time.length === LENGTH_TIME) {
			this.dateRange = [
				{
					date: this.dateRange[0].date,
					time: TuiTime.fromString(time)
				},
				{ ...this.dateRange[1] }
			];
			this.currentDateRange.next(this.dateRange);
		}
	}

	changeInputDoubleTime(time: string) {
		if (time.length === LENGTH_TIME) {
			this.dateRange = [
				{ ...this.dateRange[0] },
				{
					date: this.dateRange[1].date,
					time: TuiTime.fromString(time)
				}
			];
			this.currentDateRange.next(this.dateRange);
		}
	}

	changeInputFirstDate(date: string) {
		const newDate = TuiDay.normalizeParse(date);
		if (
			date.length === LENGTH_DATE &&
			this.dateRange[1].date.daySame(newDate)
		) {
			this.dateRange = [
				{
					date: newDate,
					time: new TuiTime(0, 0)
				},
				{
					date: this.dateRange[1].date,
					time: this.dateRange[1].time
				}
			];
			this.changeShowLeftMonth(this.dateRange[0].date.month);
			this.changeShowFirstYearRange(this.dateRange[0].date.year);
		} else if (
			date.length === LENGTH_DATE &&
			newDate.daySameOrAfter(this.dateRange[1].date) &&
			!this.dateRange[1].date.daySame(nullDate)
		) {
			this.dateRange = [
				{
					date: newDate,
					time: new TuiTime(0, 0)
				},
				{
					date: newDate,
					time: new TuiTime(23, 59)
				}
			];
			this.changeShowLeftMonth(this.dateRange[0].date.month);
			this.changeShowFirstYearRange(this.dateRange[0].date.year);
		} else if (date.length === LENGTH_DATE) {
			this.dateRange = [
				{
					date: newDate,
					time: new TuiTime(0, 0)
				},
				{ ...this.dateRange[1] }
			];
			this.changeShowLeftMonth(this.dateRange[0].date.month);
			this.changeShowFirstYearRange(this.dateRange[0].date.year);
		} else {
			this.dateRange = [
				{
					date: new TuiDay(0, 0, 1),
					time: new TuiTime(0, 0)
				},
				this.dateRange[1]
			];
		}
		this.currentDateRange.next(this.dateRange);
	}

	changeInputDoubleDate(date: string) {
		const newDate = TuiDay.normalizeParse(date);
		if (
			date.length === LENGTH_DATE &&
			(newDate.dayBefore(this.dateRange[0].date) ||
				this.dateRange[0].date.daySame(nullDate))
		) {
			this.dateRange = [
				{
					date: newDate,
					time: new TuiTime(0, 0)
				},
				{
					date: newDate,
					time: new TuiTime(23, 59)
				}
			];
			this.changeShowRightMonth(this.dateRange[1].date.month);
			this.changeShowDoubleYearRange(this.dateRange[1].date.year);
		} else if (
			date.length === LENGTH_DATE &&
			this.dateRange[0].date.daySame(newDate)
		) {
			this.dateRange = [
				{
					date: this.dateRange[0].date,
					time: this.dateRange[0].time
				},
				{
					date: newDate,
					time: new TuiTime(23, 59)
				}
			];
			this.changeShowRightMonth(this.dateRange[1].date.month);
			this.changeShowDoubleYearRange(this.dateRange[1].date.year);
		} else if (date.length === LENGTH_DATE) {
			this.dateRange = [
				{ ...this.dateRange[0] },
				{
					date: newDate,
					time: new TuiTime(23, 59)
				}
			];
			this.changeShowRightMonth(this.dateRange[1].date.month);
			this.changeShowDoubleYearRange(this.dateRange[1].date.year);
		} else {
			this.dateRange = [
				{ ...this.dateRange[0] },
				{
					date: new TuiDay(0, 0, 1),
					time: new TuiTime(23, 59)
				}
			];
		}
		this.currentDateRange.next(this.dateRange);
	}

	patchDate(start: number, end: number) {
		const startDate = TuiDay.normalizeParse(this.getStringDate(start));
		const endDate = TuiDay.normalizeParse(this.getStringEndDate(end));

		const startTime = this.getStringTime(start);
		const endTime = this.getStringEndTime(end);

		this.dateRange = [
			{
				date: startDate,
				time: TuiTime.fromString(startTime)
			},
			{
				date: endDate,
				time: TuiTime.fromString(endTime)
			}
		];

		this.currentDateRange.next(this.dateRange);
	}

	getStringDate(value: number): string {
		if (typeof value !== 'number') return '';
		const dateNumber = new Date(
			+this.timezoneService.dateWithAccountTimezone(value)
		);

		const date = `${dateNumber.getDate().toString().padStart(2, '0')}.${(
			dateNumber.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}.${dateNumber.getFullYear()}`;

		return date;
	}

	getStringEndDate(value: number): string {
		if (!value) return '';

		const dateNumber = new Date(
			+this.timezoneService.dateWithAccountTimezone(value)
		);

		const date = `${dateNumber.getDate().toString().padStart(2, '0')}.${(
			dateNumber.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}.${dateNumber.getFullYear()}`;

		return date;
	}

	changePeriodDateRange(value: TuiDayLike, start?: TuiDay) {
		const startDateAccount = new Date(
			this.timezoneService.dateWithAccountTimezone()
		);

		const startDate = start || TuiDay.fromLocalNativeDate(startDateAccount);

		if (this.period === 'past') {
			this.dateRange = [
				{
					date: startDate.append(value),
					time: new TuiTime(0, 0)
				},
				{
					date: startDate,
					time: new TuiTime(23, 59)
				}
			];

			this.changeShowRightMonth(this.dateRange[0].date.month);
		} else {
			this.dateRange = [
				{
					date: startDate,
					time: new TuiTime(0, 0)
				},
				{
					date: startDate.append(value),
					time: new TuiTime(23, 59)
				}
			];
			this.changeShowLeftMonth(this.dateRange[0].date.month);
		}
		this.currentDateRange.next([...this.dateRange]);
		this.changeShowFirstYearRange(this.dateRange[0].date.year);
		this.changeShowDoubleYearRange(this.dateRange[1].date.year);
	}

	changeRangeDate(date: TuiDay) {
		if (this.dateRange[0].date.daySame(nullDate)) {
			this.dateRange = [
				{
					date,
					time: new TuiTime(0, 0)
				},
				{
					date: new TuiDay(0, 0, 1),
					time: new TuiTime(23, 59)
				}
			];
		} else if (
			this.dateRange[1].date.daySame(nullDate) &&
			date.daySameOrAfter(this.dateRange[0].date)
		) {
			this.dateRange = [
				{ ...this.dateRange[0] },
				{
					date,
					time: new TuiTime(23, 59)
				}
			];
		} else if (
			this.dateRange[1].date.daySame(nullDate) &&
			date.daySameOrBefore(this.dateRange[0].date)
		) {
			this.dateRange = [
				{
					date,
					time: new TuiTime(0, 0)
				},
				{
					date: this.dateRange[0].date,
					time: new TuiTime(23, 59)
				}
			];
		} else {
			this.dateRange = [
				{ date: new TuiDay(0, 0, 1), time: new TuiTime(0, 0) },
				{ date: new TuiDay(0, 0, 1), time: new TuiTime(23, 59) }
			];
			this.dateRange[0].date = date;
		}
		this.currentDateRange.next([...this.dateRange]);
	}

	changeDateTime(value: string): void {
		if (value.length === LENGTH_DATE_TIME) {
			this.date = {
				date: TuiDay.normalizeParse(value.substring(0, 10)),
				time: TuiTime.fromString(value.substring(12, 17))
			};
			this.currentDate.next(this.date);
		}
	}

	changeShowDate(date: TuiMonth) {
		this.showDate.next(date);
	}

	changeShowMonth(month: number) {
		const rangeMonth = month - this.showDate.getValue().month;
		this.showDate.next(
			this.showDate.getValue().append({ month: rangeMonth })
		);
	}

	changeShowLeftMonth(month: number) {
		const rangeMonth = month - this.getShowDateRange()[0].month;
		const newMonth = this.getShowDateRange()[0].append({
			month: rangeMonth
		});
		if (newMonth.monthSameOrAfter(this.getShowDateRange()[1]))
			this.showDateRange?.next([newMonth, newMonth.append({ month: 1 })]);
		else this.showDateRange?.next([newMonth, this.getShowDateRange()[1]]);
	}

	changeShowRightMonth(month: number) {
		const rangeMonth = month - this.getShowDateRange()[1].month;
		const newMonth = this.getShowDateRange()[1].append({
			month: rangeMonth
		});
		if (newMonth.monthSameOrBefore(this.getShowDateRange()[0]))
			this.showDateRange?.next([
				newMonth.append({ month: -1 }),
				newMonth
			]);
		else this.showDateRange?.next([this.getShowDateRange()[0], newMonth]);
	}

	changeShowYear(year: number) {
		this.showDate.next(new TuiMonth(year, this.showDate.getValue().month));
	}

	changeShowFirstYearRange(year: number) {
		const rangeYear = year - this.getShowDateRange()[0].year;
		const newDate = this.getShowDateRange()[0].append({
			year: rangeYear
		});
		if (newDate.monthBefore(this.getShowDateRange()[1]))
			this.showDateRange?.next([newDate, this.getShowDateRange()[1]]);
		else this.showDateRange?.next([newDate, newDate.append({ month: 1 })]);
	}

	changeShowDoubleYearRange(year: number) {
		const rangeYear = year - this.getShowDateRange()[1].year;
		const newDate = this.getShowDateRange()[1].append({
			year: rangeYear
		});
		if (newDate.monthAfter(this.getShowDateRange()[0]))
			this.showDateRange?.next([this.getShowDateRange()[0], newDate]);
		else this.showDateRange?.next([newDate.append({ month: -1 }), newDate]);
	}

	resetDate() {
		this.currentDate.next({
			date: new TuiDay(0, 0, 1),
			time: new TuiTime(0, 0)
		});

		this.currentDateNumber = 0;
		this.changeShowDate(TuiMonth.currentLocal());
	}

	resetDateRange() {
		this.dateRange = [
			{ date: new TuiDay(0, 0, 1), time: new TuiTime(0, 0) },
			{ date: new TuiDay(0, 0, 1), time: new TuiTime(23, 59) }
		];
		this.showDateRange?.next(this.getDateRangeByPeriod().getValue());
		this.currentDateRange.next(this.dateRange);
	}
}
