<div *ngFor="let group of otherButtons.controls; index as idx">
    <ng-container [ngSwitch]="group.controls.type.value">
        <en-text-button *ngSwitchCase="'general'"
                        [index]="idx"
                        [group]="group"
                        (deleteButton)="otherButtons.removeAt(idx)">
        </en-text-button>
        <en-link-button *ngSwitchCase="'link'"
                        [index]="idx"
                        [group]="group"
                        (deleteButton)="otherButtons.removeAt(idx)">></en-link-button>
    </ng-container>
</div>
    <en-back-button *ngIf="backButtonExist"
                    [group]="backButton"
                    (deleteButton)="buttons.removeControl('restart')"></en-back-button>
<ng-container *ngIf="buttons.valueChanges | async"></ng-container>
