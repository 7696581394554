import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ID } from '@datorama/akita';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { ISegments } from '@enSend/message/message-wizard/message-wizard-common/models';
import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';

import { MainFormService } from '@enSend/scenario/scenario-wizard/services';
import { BlockScenarioFieldsQuery } from '@enSend/scenario/scenario-wizard/components/fields/_state/scn-fields-blocks.query';
import { FieldsService } from '@enSend/scenario/scenario-wizard/components/fields/services/fields.service';
import {
	booleanOperatorOptions,
	dateOperatorOptions,
	numberOperatorOptions,
	stringOperatorOptions
} from './constants/distribution-fields.options';

import { AbstractInspector } from '../../abstract';
import { InspectorItemContext } from '../../inspector-item-plugin';
import {
	DistributionParam,
	Field,
	FieldOption,
	FieldValue,
	FormValue,
	Group,
	Options
} from '../../../../../models/distribution.model';

@UntilDestroy()
@Component({
	selector: 'en-distribution',
	templateUrl: './distribution.component.html',
	styleUrls: ['./distribution.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributionComponent extends AbstractInspector implements OnInit {
	readonly operatorsWithoutValue = [
		'true',
		'false',
		'empty',
		'notEmpty',
		'today',
		'thisMonth',
		'thisYear'
	];

	form = this.fb.group({
		distributionType: 'bySegments',
		groupsAmount: 2,
		propGroupsAmount: 2,
		propGroupsValues: this.fb.array([50, 50]),
		fields: this.fb.array([])
	});

	maskNumber = {
		mask: Number,
		thousandsSeparator: '',
		scale: 0,
		max: '999999999999999',
		min: '-999999999999999',
		radix: '.',
		mapToRadix: [',']
	};

	maskFloat = {
		mask: Number,
		scale: 7,
		signed: true,
		thousandsSeparator: '',
		radix: '.',
		mapToRadix: [','],
		max: 999999999,
		normalizeZeros: false
	};

	segmentsDialogVisible = false;
	segmentsTreeVisible = false;
	selectedSegments = new BehaviorSubject<ISegments[]>([]);
	selectedGroups: Group[] = [];
	fieldOptions: FieldOption[] = [];
	previousParams: DistributionParam[] = [];

	disabledSliders: number[] = [];

	step = 0.1;

	private handleSettings: number[] = [];

	props = {
		path: ['options', 'distribution', 'params']
	};

	constructor(
		private fb: UntypedFormBuilder,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		private fieldsService: FieldsService,
		private translate: TranslateService,
		private blockQuery: BlockScenarioFieldsQuery,
		private mainFormService: MainFormService
	) {
		super();
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get disableInput() {
		return this.isCreationMode ? null : true;
	}

	get subTypeOptions() {
		const options = this.cell.get('options');
		const subType = this.cell.get('subType');
		return options[subType];
	}

	get fieldControlArray() {
		return this.form.controls.fields as UntypedFormArray;
	}

	get fieldControls() {
		return this.fieldControlArray.controls;
	}

	get shareGroupsControlArray() {
		return this.form.controls.propGroupsValues as UntypedFormArray;
	}

	get shareGroupsControls() {
		return this.shareGroupsControlArray.controls;
	}

	get scenarioId() {
		return this.mainFormService.form.get('id')?.value ?? 0;
	}

	ngOnInit() {
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;

						this.initShareGroupsListener();

						this.setStep();

						const scenarioId = this.isCreationMode
							? this.scenarioId
							: context.scenarioId;

						this.initScenarioFields(scenarioId);
					}
				}),
				switchMap(() =>
					this.form.valueChanges.pipe(
						untilDestroyed(this),
						// debounceTime(0) нужен для оптимизации
						debounceTime(0),
						tap(() => {
							this.updateGroups(this.form.value.groupsAmount);
							this.updateCell();
						})
					)
				)
			)
			.subscribe();

		this.form.controls.groupsAmount.valueChanges
			.pipe(
				untilDestroyed(this),
				debounceTime(2000),
				tap(value => {
					if (value < 2) {
						this.form.get('groupsAmount')?.patchValue(2);
					}
					if (value > 10) {
						this.form.get('groupsAmount')?.patchValue(10);
					}
				})
			)
			.subscribe();
	}

	private initScenarioFields(scenarioId: number) {
		this.blockQuery.fields$
			.pipe(
				untilDestroyed(this),
				map(fields =>
					fields
						.filter(field => field.scenarioId === scenarioId)
						.map((field: Field) => ({
							id: field.guid,
							label: field.name,
							value: field.name,
							type: field.type
						}))
				),
				tap((fieldOptions: FieldOption[]) => {
					this.fieldOptions = fieldOptions;
				})
			)
			.subscribe();

		this.fieldsService
			.getFields(scenarioId)
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.patchOnInit(this.subTypeOptions);
				})
			)
			.subscribe();
	}

	private patchOnInit(options: Options) {
		const { params } = options;
		this.form.controls.distributionType.patchValue(
			options.distributionType || 'bySegments'
		);
		if (!params.length) return;

		const fieldsForPatch =
			options.fields
				?.filter(
					(param: FieldValue) =>
						param.fieldName &&
						this.fieldOptions
							.map(option => option.value)
							.includes(param.fieldName)
				)
				.map(param => ({
					fieldName: param.fieldName,
					operator: param.operator,
					value:
						param.operator === 'between' &&
						typeof param.value !== 'string' &&
						typeof param.value !== 'number'
							? {
									startDate: param.value?.startDate,
									endDate: param.value?.endDate
							  }
							: param.value
				})) || [];

		this.fieldControls.length = 0;
		fieldsForPatch.forEach(field => {
			this.fieldControlArray.push(this.fb.group(field));
		});

		this.form.patchValue({
			distributionType: options.distributionType || 'bySegments',
			groupsAmount: options.groupsAmount,
			propGroupsAmount: options.propGroupsAmount,
			propGroupsValues: options.propGroupsValues,
			fields: fieldsForPatch
		});

		this.updateParams();

		this.previousParams = this.previousParams.map(param => ({
			...param,
			forUse: false
		}));

		this.setUsedParams();
	}

	updateParams() {
		const type = this.form.value.distributionType;
		const { params } = this.subTypeOptions;

		if (type === 'bySegments') {
			this.selectedSegments.next(
				params.map((option: DistributionParam) => ({
					id: option.id,
					name: option.label
				})) || []
			);
		}
		if (type === 'equally') {
			this.selectedGroups =
				params.map((option: DistributionParam) => ({
					id: option.id,
					name: option.label
				})) || [];
		}
	}

	private setUsedParams() {
		const type = this.form.value.distributionType;
		this.cell.prop(this.props.path).forEach((param: DistributionParam) => {
			if (type !== 'scenarioFields') {
				if (!this.previousParams.find(p => p.id === param.id)) {
					this.previousParams.push({
						...param,
						type
					});
				}
			} else if (!this.previousParams.find(p => p.port === param.port)) {
				this.previousParams.push({
					...param,
					type
				});
			}
		});
	}

	openSegmentsList() {
		this.segmentsDialogVisible = true;
	}

	openSegmentsDetail() {
		this.segmentsTreeVisible = true;
	}

	selectSegments(segments: ISegments[]) {
		this.selectedSegments.next(segments);
		this.segmentsDialogVisible = false;
		this.updateCell();
	}

	updateGroups(value: number) {
		const groups = [];
		for (let i = 0; i < value; i++) {
			const percent = (100 / value).toFixed(1);

			groups.push({
				id: i + 1,
				name: `${this.translate.instant(
					'scenario_block_destribution.group'
				)} ${i + 1} (${parseFloat(percent)}%)`
			});
		}
		this.selectedGroups = groups;
	}

	unselectSegment(id: ID) {
		const newSegments = this.selectedSegments
			.getValue()
			.filter(segment => segment.id !== id);
		this.selectedSegments.next(newSegments);
		this.updateCell();
	}

	onSlide(i: number) {
		const sliders = this.handleSettings;

		/// величина изменения слайдера
		const difference = this.normalizeValue(
			this.form.value.propGroupsValues[i] - sliders[i]
		);

		/// складываем слайдеры в объекты для сохранения индексов после фильтрации
		const slidersAsObj = sliders.map(
			(sliderValue: number, index: number) => ({
				sliderValue,
				index
			})
		);

		/// слайдеры, которые будут зависеть(убираем двигаемый, заблокированные и нулевые, при прибавлении двигаемого)
		const slidersForChange = slidersAsObj.filter(
			(slider: { sliderValue: number; index: number }) => {
				return difference > 0
					? slider.sliderValue > 0 &&
							slider.index !== i &&
							!this.disabledSliders.includes(slider.index)
					: slider.index !== i &&
							!this.disabledSliders.includes(slider.index);
			}
		);

		/// индексы зависимых слайдеров
		const slidersIndexesForChange = slidersForChange.map(
			slider => slider.index
		);

		/// кол-во зависимых слайдеров
		const changingSlidersAmount = slidersIndexesForChange.length;

		/// считаем изменения слайдеров
		const newSlidersValues = sliders.map(
			(sliderValue: number, index: number) => {
				if (!slidersIndexesForChange.includes(index)) {
					return sliderValue;
				}

				const newSliderValue = this.normalizeValue(
					sliderValue - difference / changingSlidersAmount
				);
				if (newSliderValue <= 0) return 0;

				return newSliderValue;
			}
		);

		/// изменяем слайдеры
		this.form.controls.propGroupsValues.patchValue(newSlidersValues);

		/// сумма value зависымых слайдеров
		const othersSum = this.form.value.propGroupsValues
			.filter((_: number, index: number) => index !== i)
			.reduce((acc: number, cur: number) => acc + Math.abs(cur), 0);

		/// не даем уйти от общих 100%
		if (this.form.value.propGroupsValues[i] + Number(othersSum) !== 100) {
			const handleValue = this.normalizeValue(100 - othersSum);
			this.shareGroupsControls[i].patchValue(
				handleValue < 0 ? 0 : handleValue
			);
		}

		/// записываем новые значения
		this.handleSettings = this.form.value.propGroupsValues;
	}

	disableSlider(i: number) {
		if (this.disabledSliders.includes(i)) {
			this.disabledSliders = this.disabledSliders.filter(
				value => value !== i
			);
		} else {
			this.disabledSliders.push(i);
		}

		this.setStep();
	}

	private setStep() {
		this.step =
			this.form.value.propGroupsAmount - this.disabledSliders.length > 1
				? this.normalizeValue(
						(this.form.value.propGroupsAmount -
							this.disabledSliders.length -
							1) *
							0.1
				  )
				: 0.1;
	}

	private normalizeValue(value: number) {
		return Number(value.toFixed(1));
	}

	private initShareGroupsListener() {
		this.form.controls.propGroupsAmount.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (value < this.shareGroupsControls.length) {
						this.shareGroupsControls.length = value;
						this.shareGroupsControlArray.value.length = value;
					}
					if (value > this.shareGroupsControls.length) {
						const d = value - this.shareGroupsControls.length;
						for (let i = 0; i < d; i++) {
							this.shareGroupsControlArray.push(
								this.fb.control(0)
							);
						}
					}
					const arr = [];
					for (let i = 0; i < value; i++) {
						arr.push(this.normalizeValue(100 / value));
					}

					/// приводим к 100%
					const sumExpectFirst = arr
						.slice(1)
						.reduce(
							(acc: number, cur: number) => acc + Math.abs(cur),
							0
						);
					arr[0] = this.normalizeValue(100 - sumExpectFirst);

					this.form.controls.propGroupsValues.patchValue(arr);

					this.handleSettings = this.form.value.propGroupsValues;

					this.disabledSliders = [];

					this.setStep();
				})
			)
			.subscribe();
	}

	private updateCell() {
		this.previousParams = [];
		this.setUsedParams();

		const unsuitableID = this.subTypeOptions.unsuitable;
		const type = this.form.value.distributionType;

		if (type === 'bySegments') {
			const segments = this.selectedSegments.getValue();

			this.changeCellProp('options', {
				isValid: true,
				distribution: {
					...this.defineFieldsTypes(this.form.value),
					unsuitable: unsuitableID,
					params: segments.map(segment => ({
						port: this.previousParams.find(
							param =>
								param.id === segment.id &&
								param.type === 'bySegments'
						)?.port,
						id: segment.id,
						label: segment.name
					}))
				}
			});
		}

		if (type === 'equally') {
			const groups = this.selectedGroups;

			this.changeCellProp('options', {
				isValid: true,
				distribution: {
					...this.defineFieldsTypes(this.form.value),
					unsuitable: unsuitableID,
					params: groups.map((group: Group) => ({
						port: this.previousParams.find(
							param =>
								param.id === group.id &&
								param.type === 'equally'
						)?.port,
						id: group.id,
						label: group.name
					}))
				}
			});
		}

		if (type === 'proportionally') {
			const groups = this.shareGroupsControlArray.value;

			this.changeCellProp('options', {
				isValid: true,
				distribution: {
					...this.defineFieldsTypes(this.form.value),
					unsuitable: unsuitableID,
					params: groups.map((value: number, i: number) => ({
						port: this.previousParams.find(
							(param, index: number) =>
								index === i && param.type === 'proportionally'
						)?.port,
						id: i + 1,
						label: `${this.translate.instant(
							'scenario_block_destribution.group'
						)} ${i + 1} (${value}%)`,
						proportion: Number((value / 100).toFixed(3))
					}))
				}
			});
		}

		if (type === 'scenarioFields') {
			const { fields } = this.form.value;

			this.changeCellProp('options', {
				isValid: this.isFormValid(),
				distribution: {
					...this.defineFieldsTypes(this.form.value),
					unsuitable: unsuitableID,
					params: fields
						.map((field: FieldValue) => {
							const prevParam = this.previousParams.find(
								param =>
									param.label === field.fieldName &&
									param.type === 'scenarioFields' &&
									!param.forUse
							);
							if (prevParam) prevParam.forUse = true;
							return field.operator !== 'between'
								? {
										port: prevParam?.port,
										label: field.fieldName,
										scenarioFieldsCondition: {
											field: field.fieldName,
											operator: field.operator,
											value: field.value
												? field.value.toString()
												: ''
										}
								  }
								: {
										port: prevParam?.port,
										label: field.fieldName,
										scenarioFieldsCondition: {
											field: field.fieldName,
											operator: field.operator,
											value: '',
											startDate:
												field.value &&
												typeof field.value !==
													'string' &&
												typeof field.value !==
													'number' &&
												field.value.startDate
													? field.value.startDate.toString()
													: '',
											endDate:
												field.value &&
												typeof field.value !==
													'string' &&
												typeof field.value !==
													'number' &&
												field.value.endDate
													? field.value.endDate.toString()
													: ''
										}
								  };
						})
						.filter(
							(param: DistributionParam) =>
								param.label && param.label.length > 0
						)
				}
			});

			this.previousParams = this.previousParams.map(param => ({
				...param,
				forUse: false
			}));
		}
	}

	addField() {
		this.fieldControlArray.push(
			this.fb.group({
				fieldName: '',
				operator: '',
				value: ''
			})
		);
		this.updateCell();
	}

	deleteField(i: number) {
		this.fieldControls.splice(i, 1);
		this.fieldControlArray.value.splice(i, 1);

		this.updateCell();
	}

	resetFromParam(i: number) {
		this.fieldControls[i].get('operator')?.reset();
		this.fieldControls[i].get('value')?.reset();
	}

	resetValue(i: number) {
		this.fieldControls[i].get('value')?.reset();
	}

	getOperatorOptions(fieldValue: string) {
		const field = this.fieldOptions.find(
			option => option.value === fieldValue
		);
		switch (field?.type) {
			case 'text':
				return stringOperatorOptions;
			case 'number':
				return numberOperatorOptions;
			case 'float':
				return numberOperatorOptions;
			case 'bool':
				return booleanOperatorOptions;
			case 'date':
				return dateOperatorOptions;
			case 'dateTime':
				return dateOperatorOptions;
			default:
				return [];
		}
	}

	getParamType(fieldValue: string) {
		return this.fieldOptions.find(option => option.value === fieldValue)
			?.type;
	}

	private defineFieldsTypes(formValue: FormValue) {
		const typedFields = formValue.fields.map((field: FieldValue) => ({
			...field,
			type: this.getParamType(field.fieldName)
		}));
		const typedFormValue = {
			...formValue,
			fields: typedFields
		};
		return typedFormValue;
	}

	private isFormValid() {
		const formValue = this.form.value;
		const boolValidControls = formValue.fields.map((field: FieldValue) => {
			if (!field.fieldName) return false;
			if (!field.operator) return false;
			if (
				!this.operatorsWithoutValue.includes(field.operator) &&
				(!field.value || !field.value.toString().length)
			)
				return false;
			if (
				field.operator === 'between' &&
				typeof field.value !== 'string' &&
				typeof field.value !== 'number' &&
				!(field.value.startDate && field.value.endDate)
			)
				return false;
			return true;
		});
		return !boolValidControls.includes(false);
	}
}
