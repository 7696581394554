<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <div class="addition">
        <span class="center-span-text first-block">{{ 'segment_menu_items.device_item' |translate }}</span>
        <en-dropdown class="input__small"
                     styleClass="en-dropdown-solid"
                     [options]="optionsDevice"
                     formControlName="value"></en-dropdown>
    </div>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"></en-condition-tools>
    </div>
</form>