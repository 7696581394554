import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiMonth, TuiTime, TuiDay } from '@taiga-ui/cdk';

import { CalendarService } from 'app/ui-lib/new-calendar/calendar.service';
import {
	LENGTH_DATE,
	LENGTH_TIME,
	nullDate
} from '../../calendarNew/calendar.model';

@UntilDestroy()
@Component({
	selector: 'en-primitive-input-date-time',
	templateUrl: './primitive-input-date-time.component.html',
	styleUrls: ['./primitive-input-date-time.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimitiveInputDateTimeComponent implements OnInit {
	@Input() type = 'date';

	@Input() numberInput: number;

	open = false;
	currentDate: any;

	control = new UntypedFormControl('');

	constructor(
		@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef,
		private calendarService: CalendarService
	) {}

	get date() {
		return this.calendarService.currentDate;
	}

	get focused(): boolean {
		return false;
	}

	get isSingle() {
		return this.calendarService.getIsSingle();
	}

	get min() {
		return this.calendarService.getMinDate();
	}

	get max() {
		return this.calendarService.getMaxDate();
	}

	ngOnInit() {
		if (this.isSingle) this.changeSingleCalendar();
		else this.changeRangeCalendar();
	}

	changeSingleCalendar() {
		if (!this.calendarService.getDate().daySame(nullDate)) {
			if (this.type === 'time') {
				this.currentDate = this.calendarService.getTime();
				this.control.patchValue(
					this.getNormalizedTime(this.currentDate)
				);
			}
			if (this.type === 'date') {
				this.currentDate = this.calendarService.getDate();
				this.control.patchValue(this.calendarService.getDateString(0));
			}
		}
		this.calendarService.currentDate
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				!value.date.daySame(nullDate)
					? (this.currentDate = value?.date)
					: this.control.patchValue('');
				if (this.type === 'time' && !value.date.daySame(nullDate))
					this.currentDate = this.getNormalizedTime(value?.time);
				this.calendarService.changeShowDate(
					new TuiMonth(value.date.year, value.date.month)
				);
				this.cd.markForCheck();
			});
	}

	changeRangeCalendar() {
		if (
			!this.calendarService
				.getDateRange()
				[this.numberInput].date.daySame(nullDate)
		)
			this.type === 'time' ? this.patchTime() : this.patchDate();

		this.calendarService.currentDateRange
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (!value[this.numberInput].date.daySame(nullDate)) {
					this.type === 'time'
						? (this.currentDate = value[this.numberInput].time)
						: (this.currentDate = value[this.numberInput].date);
				} else this.currentDate = '';
				this.cd.markForCheck();
			});
	}

	onInputChange(value: string) {
		if (this.isSingle) {
			if (this.type === 'time' && value.length === LENGTH_TIME) {
				this.calendarService.changeTime(value);
				this.currentDate = this.calendarService
					.getDateTime(0)
					.substring(12, 17);
				this.control.patchValue(this.currentDate);
			}
			if (this.type === 'date' && value.length === LENGTH_DATE) {
				this.calendarService.changeDate(value);
				this.currentDate = value;
			}
			return;
		}
		if (this.type === 'date') {
			this.numberInput
				? this.calendarService.changeInputDoubleDate(value)
				: this.calendarService.changeInputFirstDate(value);
		} else {
			this.numberInput
				? this.calendarService.changeInputDoubleTime(value)
				: this.calendarService.changeInputFirstTime(value);
		}
		this.currentDate = value;
	}

	private patchDate(): void {
		this.currentDate =
			this.calendarService.getDateRange()[this.numberInput].date;
		this.control.patchValue(this.getNormalizedDate(this.currentDate));
	}

	private patchTime(): void {
		this.currentDate =
			this.calendarService.getDateRange()[this.numberInput].time;
		this.control.patchValue(this.getNormalizedTime(this.currentDate));
	}

	private getNormalizedTime(time: TuiTime): string {
		const hours = time.hours < 10 ? `0${time.hours}` : time.hours;
		const minutes = time.minutes < 10 ? `0${time.minutes}` : time.minutes;

		return `${hours}:${minutes}`;
	}

	private getNormalizedDate(date: TuiDay): string {
		const numberDate = this.calendarService.getNumberDate(
			date,
			new TuiTime(0, 0)
		);
		return this.calendarService.getStringDate(numberDate);
	}
}
