<div class="en-small"
     *ngIf="visible"
     role="alert"
     [@messageAnimation]="{
	    value: 'visible',
		params: {
		    showTransitionParams: showTransitionOptions,
			hideTransitionParams: hideTransitionOptions
		}
	 }">
    <span class="en-small-icon en"
          [ngClass]="{
		    'en-info-circle-o en-icon-info': severity === 'info',
            'en-exclamation-circle-o en-icon-validate': severity === 'validate',
            'en-exclamation-triangle-o en-icon-warning': severity === 'warning'
		  }"></span>

    <span *ngIf="detail"
          class="en-small-detail"
          [ngClass]="'en-small-detail--' + severity">
        {{ detail }}
    </span>
</div>
