<en-dialog [header]="'scenario_block_mailing_groups.select_mail_group' | translate"
           styleClass="p-dialog--lg"
           [maximizable]="true"
           [resizable]="false"
           [modal]="true"
           appendTo="body"
           [(visible)]="dialogVisible"
           [draggable]="false"
           (onHide)="onHideDialog()">
    <tui-scrollbar class="scrollbar">
        <div style="padding: 1.5rem">
            <en-mailing-groups-table [toolsPanel]="false"
                                     [selection]="true"
                                     [notSaveInUrl]="true"
                                     (selected)="onConfirmDialog($event)">
                <ng-template enTemplate="tools">
                    <section class="search">
                        <en-search [placeholder]="'scenario_block_mailing_groups.search_placeholder' | translate"
                                   styleClass="h32"
                                   [formControl]="searchControl"
                                   (onSearch)="paginationService.setSearchValue($event)"
                                   [refresher]="false"></en-search>
                    </section>
                </ng-template>
            </en-mailing-groups-table>
        </div>
    </tui-scrollbar>
</en-dialog>
