import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AttachmentLoadingService {
	private _isSplitVersionChanged: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private _isAttachmentsLoading: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	isSplitVersionChanged$: Observable<boolean> =
		this._isSplitVersionChanged.asObservable();

	isAttachmentsLoading$: Observable<boolean> =
		this._isAttachmentsLoading.asObservable();

	setIsVersionChanged(value: boolean) {
		this._isSplitVersionChanged.next(value);
	}

	setIsAttachmentsLoading(value: boolean) {
		this._isAttachmentsLoading.next(value);
	}
}
