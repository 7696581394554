<div class="addition"
     [class.highlight]="isHover"
     [class.error]="bounceType.invalid">
     <en-dropdown styleClass="en-dropdown-solid"
                  [style]="{'borderLeftWidth': '1px'}"
                  [options]="filtredOptions"
                  [formControl]="bounceType">
     </en-dropdown>
     <i class="border-left button-remove-condition cancel-icon en en-cancel-fill"
        (mouseenter)="isHover = true"
        (mouseleave)="isHover = false"
        (click)="remove.emit()"></i>
</div>