/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ID } from '@datorama/akita';
import { CommonWizardFormValue } from '@enSend/message/message-wizard/kit';
import { MailingGroup } from '@state-enKod/mailing-groups';
import { FileAttachment } from '@enSend/message/_states/_state-message';
import { ChamaileonLetter, Params, Utm } from './entity-params.model';
import { SendingType, ISegments } from './message.model';
import { ActionMobPush } from './mob-push-entity.model';
import { UploadFileResponse } from './file-upload.model';

export interface WizardFormValue extends CommonWizardFormValue {
	step_1: Step_1;
	step_2: Step_2;
	step_3: Step_3;
	step_4: Step_4;
}

export interface Step_1 {
	sendingType: SendingType;
	sendnow: boolean;
	sendlate: { startTime: number };
	regular: any;
	api: { transaction: boolean };
	event: any;
	doi?: any;
}

export interface Step_2 {
	mails?: MailFormEntity[];
	status?: string | undefined;
	pushes?: PushFormEntity[];
	mobPushes?: MobPushFormEntity[];
	sms?: SmsFormEntity[];
	whatsapp: WhatsappFormEntity;
	tags: string[];
}

export interface MailFormEntity extends CommonFormEntity {
	status: string;
	sendSettings: SendSettings;
	plainText: string;
	html: string;
	amp: string;
	stripoHtml: string;
	stripoCss: string;
	chamaileonLetter: ChamaileonLetter;
	trackClicks: boolean;
	manually: boolean;
	attachments: FileAttachment[];
	fileAttachments: boolean;
}

export interface PushFormEntity extends CommonFormEntity {
	subject: string;
	body: string;
	icon: string;
	clickAction: string;
	timeToLive: number;
}

export interface MobPushFormEntity extends CommonFormEntity {
	subject: string;
	body: string;

	smallImage: string;
	image: string;
	appName: string;

	linkRef: LinkRefMobPushForm;

	buttons: ButtonsMobPushForm[];
}

export interface SmsFormEntity extends CommonFormEntity {
	body: string;
	toShortLink: boolean;
}

export interface WhatsappFormEntity {
	name?: string;
	titleType?: string;
	whatsappParams?: WaParamsOTD;
	id?: ID;
	text?: string;
	footer?: string;
	type?: string;
	title?: {
		format?: string;
		text?: string;
	};
	buttons?: {
		text?: string;
		type?: string;
	}[];
	whatsappTemplateId?: number;
	whatsappTemplateName?: string;
}

export interface WaParamsOTD {
	footer_text?: string[];
	body_text?: string[];
	header_text?: string[];
	url_button_params?: string[];
	header_handle?: string[];
}

export type LinkRefMobPushForm = { link?: string; action: ActionMobPush };

export type ButtonsMobPushForm = { label: string; linkRef: LinkRefMobPushForm };

interface CommonFormEntity {
	id: ID;
	messageId: ID;
	urlParams: Params;
	utm: Utm;
}

export interface SendSettings {
	fromEmail: string;
	fromName: string;
	subject: string;
	preheader: string;
	withAttachments: boolean;
	replyToName?: string;
	replyToEmail?: string;
}

export interface Step_3 {}

export interface Step_4 {
	segments: ISegments[];
	mailingGroups: MailingGroup[];
	exceptSegments: ISegments[];
	contactsFile: UploadFileResponse | {};
}

/** Типы относящиеся к 1 шагу */

export interface IConditionControls {
	sendlate: UntypedFormGroup;
	regular: UntypedFormGroup;
	api: UntypedFormGroup;
	event: UntypedFormGroup;
	doi: UntypedFormGroup;
}

export enum RegularEveryWeek {
	first = 'first',
	every = 'every',
	second = 'second',
	fourth = 'fourth',
	third = 'third',
	fifth = 'fifth',
	last = 'last'
}

export type RegularFilter = 'day' | 'weekday';

export interface IMonths {
	[key: string]: string;
}

export interface IMonthControls {
	weekdays: UntypedFormControl;
	month: UntypedFormControl;
	filter: UntypedFormControl;
	everyWeek: UntypedFormControl;
	day: UntypedFormControl;
}
