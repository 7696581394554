import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { ExtraFieldStartComponent } from './data-change-start.component';

@Injectable()
export class ExtraFieldStartService implements InspectorItemPlugin {
	private readonly extraFieldStartContent = new PolymorpheusComponent(
		ExtraFieldStartComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'extraFieldStart',
				name: 'extraFieldStart',
				content: this.extraFieldStartContent
			}
		]);
	}
}
