import { UntypedFormBuilder } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';

import { ScenarioShapesEnum } from '../../rappid/config/enum.shapes';
import { AbstractInspector } from './abstract-inspector';

export abstract class AbstractInspectorStart extends AbstractInspector {
	public duplicateHandling = this.fb.control('ignore');
	public message: Message[] = [];

	constructor(
		public fb: UntypedFormBuilder,
		public translate: TranslateService
	) {
		super();
	}

	public get startBlocks() {
		return this.cell.collection.filter(
			cell =>
				cell.get('type') === ScenarioShapesEnum.START_BLOCK ||
				cell.get('type') === ScenarioShapesEnum.MESSAGE_START_BLOCK ||
				cell.get('type') === ScenarioShapesEnum.EXTRA_FIELD_START_BLOCK
		);
	}

	public initDuplicateHandling() {
		const { startBlocks } = this;
		const duplicateHandling =
			startBlocks
				.find(block => block.get('options').duplicateHandling)
				?.get('options').duplicateHandling ||
			this.duplicateHandling.value;
		this.duplicateHandling.setValue(duplicateHandling);

		if (startBlocks.length > 1) {
			if (this.message.length) return;
			this.message.push({
				severity: 'info',
				summary: this.translate.instant(
					'scenario_block_api.message_summary'
				),
				detail: this.translate.instant(
					'scenario_block_api.message_detail'
				)
			});
			return;
		}

		this.message = [];
	}

	public changeDuplicateHandling(value: string) {
		this.startBlocks.forEach(block =>
			block.prop(['options', 'duplicateHandling'], value)
		);
	}
}
