import { Injectable } from '@angular/core';
import { ExpandedQuery } from '@enkod-core/abstract';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';

import { Observable } from 'rxjs';

import { ExtraField } from './extra-field.model';
import { ExtraFieldsStore, ExtraFieldsState } from './extra-fields.store';

@Injectable({ providedIn: 'root' })
export class ExtraFieldsQuery extends ExpandedQuery<ExtraFieldsState> {
	constructor(protected store: ExtraFieldsStore) {
		super(store);
	}

	readonly fields$ = this.selectAll() as Observable<ExtraField[]>;

	readonly allFields$ = this.select(ALL_LIST_STORE_NAME) as Observable<
		ExtraField[]
	>;
}
