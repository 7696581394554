<div class="item">
    <span class="item__id id-badge">id {{id}}</span>
    <i *ngIf="type"
        class="item__type"
       [ngClass]="type | typeIcon"></i>
    <span class="item__name"
          [class.item__name_clickable]="contentClickable"
          (click)="contentAction.emit()">{{content}}</span>
    <ng-container *ngIf="isCreationMode">
        <i class="item__remove-button en en-cancel-fill"
           (click)="remove.emit()"></i>
    </ng-container>
</div>