import { Pipe, PipeTransform } from '@angular/core';
import { UserStatus } from '@state-admin/users';

@Pipe({
	name: 'userStatusValue'
})
export class UserStatusValuePipe implements PipeTransform {
	transform(status: UserStatus | 'temporary'): string {
		switch (status) {
			case 'inactive':
				return 'admin_users_list.table_label_inactive';
			case 'waiting for confirmation':
				return 'admin_users_list.status_wait';
			case 'temporary':
				return 'admin_users_list.status_temporary';
			default:
				return 'admin_accounts_list.active';
		}
	}
}
