import { animate, style, transition, trigger } from '@angular/animations';
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import {
	IChangeStatus,
	IDeleteModel,
	IPopup,
	SplitTestLabelModel
} from '@state-enPop/popups';

@Component({
	selector: 'en-popup-card',
	templateUrl: './popup-card.component.html',
	styleUrls: ['./popup-card.component.scss'],
	animations: [
		trigger('popUp', [
			transition(':enter', [
				style({ height: 0 }),
				animate('80ms', style({ height: '*' }))
			]),
			transition(':leave', [
				style({ height: '*' }),
				animate('80ms', style({ height: 0 }))
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupCardComponent {
	showTools = false;
	previewLabel = 'A';
	private previewIndex = 0;

	@Input()
	readonly popup: IPopup;

	@Input()
	readonly showFullscreenButton = false;

	@Input()
	readonly showVersionsToggler = false;

	@Input()
	readonly showSelectWinner = false;

	@Input()
	readonly withTools = true;

	@Input()
	readonly selectable = true;

	@Output() changeStatus = new EventEmitter<IChangeStatus>();

	@Output() selectPopup = new EventEmitter<number>();

	@Output() handleAction = new EventEmitter<void>();

	@Output() deletePopup = new EventEmitter<IDeleteModel>();

	@Output() fullScreen = new EventEmitter<void>();

	get versions() {
		return this.popup.content;
	}

	get isActive(): boolean {
		return (
			this.popup.status === 'active' ||
			this.popup.status === 'selectWinner'
		);
	}

	get isDraft(): boolean {
		return this.popup.status === 'draft';
	}

	get isSelectWinner(): boolean {
		return this.popup.status === 'selectWinner';
	}

	get previewUrl() {
		if (this.popup.content)
			return this.popup.content[this.previewIndex]?.previewUrl;
		return '';
	}

	changePreview(label: SplitTestLabelModel, idx: number) {
		this.previewLabel = label;
		this.previewIndex = idx;
	}
}
