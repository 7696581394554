import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Inject,
	Output,
	ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

import codemirror, { Position } from 'codemirror';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/display/placeholder';

import { CANVA_MODE_TOKEN, CanvaModeType } from '@enSend/_shared/tokens';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

@Component({
	selector: 'en-json-plugin-dialog',
	templateUrl: './json-plugin-dialog.component.html',
	styleUrls: ['./json-plugin-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonPluginDialogComponent implements OnInit {
	@ViewChild('codemirror')
	private readonly codeMirrorComp?: CodemirrorComponent;

	options: codemirror.EditorConfiguration = {
		lineNumbers: true,
		mode: 'application/ld+json',
		showHint: true,
		scrollbarStyle: 'simple',
		lineWrapping: false,
		placeholder: '{“key”: “value”}',
		viewportMargin: Infinity
	};

	openDynamicContent = false;
	jsonDialogControl = new UntypedFormControl();
	jsonDialogControlValue$: Observable<string>;
	position: Position;
	hasPosition = false;

	@Input() visible: boolean;
	@Input() jsonControl: UntypedFormControl;

	@Output() hide = new EventEmitter<void>();

	constructor(@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType) {}

	get jsonDialogControlValue() {
		return this.jsonDialogControl.value as string;
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get codeMirror() {
		return this.codeMirrorComp?.codeMirror;
	}

	ngOnInit() {
		this.jsonDialogControl.patchValue(this.jsonControl.value);

		this.jsonDialogControlValue$ = this.jsonDialogControl.valueChanges.pipe(
			startWith(this.jsonControl.value),
			debounceTime(500)
		);
	}

	confirm() {
		this.jsonControl.patchValue(this.jsonDialogControl.value);
		this.closeDialog();
	}

	closeDialog() {
		this.hide.emit();
	}

	setPosition() {
		if (this.codeMirror) {
			this.position = this.codeMirror.getCursor();
			this.hasPosition = true;
		}
	}

	updateJson(value: string) {
		const scrollInfo = this.codeMirror?.getScrollInfo();

		if (this.codeMirror) {
			const symbolIndexFromCursor = this.codeMirror.indexFromPos(
				this.position
			);

			const leftSideOfText = this.jsonDialogControlValue.slice(
				0,
				symbolIndexFromCursor
			);
			const rightSideOfText = this.jsonDialogControlValue.slice(
				symbolIndexFromCursor
			);
			const updatedText = `${leftSideOfText} ${value} ${rightSideOfText}`;

			this.jsonDialogControl.patchValue(updatedText);

			this.codeMirror.scrollTo(scrollInfo?.left, scrollInfo?.top);
			this.codeMirror.setCursor(this.position);
			this.codeMirror.focus();
		}
	}
}
