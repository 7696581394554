import { ChangeDetectorRef, Provider } from '@angular/core';
import { asyncValidator, ASYNC_VALIDATE_TOKEN } from '@enkod-core/tokens';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';

export const ASYNC_SCENARIO_NAME: Provider = {
	provide: ASYNC_VALIDATE_TOKEN,
	useFactory: asyncValidator({
		endpoint: 'ensend/scenario/check/',
		requestName: 'name',
		responseName: 'uniqueName'
	}),
	deps: [ChangeDetectorRef]
};

export const ASYNC_SCENARIO_NAME_ERRORS: Provider = {
	provide: TUI_VALIDATION_ERRORS,
	useValue: {
		required: 'validators.required',
		invalidSystemName: 'validators.error_system_name',
		alreadyExist: 'Такое имя уже существует'
	}
};
