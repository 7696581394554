import { SelectItem } from 'primeng/api';

export const POPUP_DEVICE_PREVIEW_OPTIONS: SelectItem[] = [
	{
		icon: 'en en-desktop',
		value: 'desktop'
	},
	{
		icon: 'en en-mobile',
		value: 'mobile'
	},
	{
		icon: 'en en-expand',
		value: 'fullscreen'
	}
];

export const OPTIONS_ENABLE_TNXPAGE: SelectItem[] = [
	{
		label: 'enpop_shared.options_view_popup',
		value: 'popup'
	},
	{
		label: 'enpop_shared.options_view_tnx',
		value: 'thanksPage',
		disabled: true
	}
];

export const OPTIONS_DISABLE_TNXPAGE: SelectItem[] = [
	{
		label: 'enpop_shared.options_view_popup',
		value: 'popup'
	},
	{
		label: 'enpop_shared.options_view_tnx',
		value: 'thanksPage',
		disabled: false
	}
];
