import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
	TuiPrimitiveTextfieldModule,
	TuiTextfieldControllerModule
} from '@taiga-ui/core';
import { EnInputModule } from '../../input/input';
import { PrimitiveInputDateTimeComponent } from './primitive-input-date-time.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnInputModule,
		TuiPrimitiveTextfieldModule,
		TuiTextfieldControllerModule
	],
	declarations: [PrimitiveInputDateTimeComponent],
	exports: [PrimitiveInputDateTimeComponent]
})
export class EnPrimitiveInputDateTimeModule {}
