<div class="en-field">
    <span>{{'message_wizard_edit_step.sendtest_label' | translate}}</span>
    <en-autoComplete [formControl]="emailsInput"
                     [suggestions]="filteredEmailsMultiple"
                     [placeholder]="'message_wizard_edit_step.sendtest_placeholder' | translate"
                     [dropdown]="true"
                     [multiple]="true"
                     appendTo="body"
                     (completeMethod)="filterEmailMultiple($event)"
                     (keyup.enter)="addMailToList($event)"></en-autoComplete>
    <en-error [formControl]="emailsInput"></en-error>
    <en-small [visible]="isVisibleInfo"
              severity="info"
              [detail]="'message_wizard_edit_step.sendtest_tooltip' | translate">
    </en-small>
</div>
