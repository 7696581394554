import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class PreviewControlsService {
	readonly selectedPagePreview: UntypedFormControl = new UntypedFormControl('popup');
	readonly devicePreview: UntypedFormControl = new UntypedFormControl('desktop');

	resetControls() {
		this.selectedPagePreview.patchValue('popup');
		this.devicePreview.patchValue('desktop');
	}
}
