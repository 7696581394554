/* eslint-disable import/no-cycle */
import { Injectable } from '@angular/core';
import { dia, shapes, ui } from '@clientio/rappid';
import { Option } from '@enSend/_shared/models';
import { ChatbotShapesEnum } from '../rappid/config/enum.shapes';

export type ConfigType = 'basic' | 'line';
@Injectable()
export class HaloService {
	halo: ui.Halo;
	cellView: dia.CellView;

	create(cellView: dia.CellView, configType: ConfigType = 'basic') {
		this.cellView = cellView;
		this.halo = new ui.Halo({
			cellView,
			type: 'toolbar',
			handles: this.getHaloConfig(configType),
			boxContent: false,
			useModelGeometry: true
		});
	}

	private getHaloConfig(configType: ConfigType) {
		const config = {
			basic: [
				{
					name: 'customEdit',
					icon: 'assets/scenario/pencil-o.svg'
				},
				{
					name: 'customCopy',
					events: {
						pointerdown: this.startCloning.bind(this),
						pointerup: this.stopCloning.bind(this)
					},
					icon: 'assets/scenario/copy.svg'
				},
				{
					name: 'customRemove',
					events: {
						pointerdown: this.removeElement.bind(this)
					},
					icon: 'assets/scenario/trash.svg'
				}
			],
			line: [
				{
					name: 'customRemove',
					events: { pointerdown: this.removeLink.bind(this) },
					icon: 'assets/scenario/trash.svg'
				}
			]
		};
		return config[configType];
	}

	private removeLink() {
		const { model } = this.cellView;
		if (model.isLink()) {
			model.attr(
				{
					line: {
						stroke: '#535766',
						targetMarker: {
							fill: '#535766'
						}
					}
				},
				{ noHistory: true }
			);
		}
		model.remove();
	}

	private removeElement() {
		this.cellView.model.remove();
	}

	private startCloning() {
		const cell = this.cellView.model as InstanceType<typeof shapes.Block>;
		if (cell.attributes.position) {
			const newElem = cell.clone() as InstanceType<typeof shapes.Block>;
			const subType = newElem.get('subType');
			const options = newElem.get('options');
			newElem.position(
				cell.attributes.position.x,
				cell.attributes.position.y + 100
			);
			if (subType === 'apiStart' || subType === 'sendMessage') {
				const { params } = options[subType];
				newElem.blockLayout.options.length = 0;
				newElem.blockLayout.subheader = '';
				params.length = 0;
				newElem.size(200, 60);
			}
			this.cellView.model.graph.addCell(newElem);
			this.generateNewPorts(newElem);
		}
	}

	private stopCloning(...args: any) {
		// костыль на тип - поправь
		(this.halo as ui.Halo & { stopCloning: Function }).stopCloning(...args);
		const model = this.cellView.model as InstanceType<typeof shapes.Block>;
		const subType = model.get('subType');
		if (subType === 'distribution') {
			const unsuitablePath = ['options', 'distribution', 'unsuitable'];
			// @ts-ignore:next-line
			model.prop(unsuitablePath, model.generatePortId());
		}
	}

	// todo тот же метод в inspector.component вынести куда-то ещё
	private generateNewPorts(model: InstanceType<typeof shapes.Block>) {
		const ports = model.getPorts();
		const newPorts = ports.map(port => ({
			...port,
			id: model.generatePortId()
		}));
		model.prop('ports/items', newPorts, { noHistory: true });

		const newOutPorts = newPorts.filter(port => port.group === 'out');

		const namespace = model.get('namespace') ?? 'scenario';
		const options = model.get('options');
		const subType = model.get('subType');

		if (namespace === 'scenario') {
			const { params } = options[subType];
			const newParams = params.map((option: Option, i: number) => ({
				...option,
				port: newOutPorts[i].id
			}));
			model.prop(`options/${subType}/params`, newParams, {
				noHistory: true
			});
		}

		if (namespace === 'chatbot') {
			if (subType === ChatbotShapesEnum.MESSAGE) {
				const { inlineReplyButtons } = options;
				const newParams = inlineReplyButtons
					.filter((option: Option) => option.type !== 'restart')
					.map((option: Option, i: number) => ({
						...option,
						port: newOutPorts[i].id,
						guid: model.generatePortId()
					}));

				model.prop(`options/inlineReplyButtons`, newParams, {
					noHistory: true
				});
			}
		}
	}
}
