import { ID } from '@datorama/akita';
import { ListOption } from '@state-enKod/extra-fields';

export interface ContactsDetail {
	id: ID;
	firstName: string;
	lastName: string;
	channels: ChannelType[];
	email: string;
	phone: number | string | '';
	countryISOCode?: string;
	extraFields: ContactExtraFields;
	emailInBL: {
		inLocal: boolean;
		inGlobal: boolean;
	};
	tags?: string[];
	customId?: string;
}

export interface MainFields {
	email?: string;
	phone?: number | string;
}

export interface PhoneData {
	phone?: string;
	countryISOCode?: string;
}

export interface PersonData {
	firstName?: string;
	lastName?: string;
	// rating?: number;
	customId?: string;
	tags?: string[];
}

export interface ContactExtraFields {
	[key: string]: any; // для использования строки в качестве ключа прим: object[key]
	id: ID;
	name: string;
	value: string | number | boolean;
	type: string;
	systemName: string;
	serviceName: string;
	description: string;
	dataType?: string;
	listOptions: ListOption[];
}

export interface FieldsViewSettings {
	visible: string[];
	hidden: string[];
}

export interface TransformedViewSettings {
	visible: ContactExtraFields[];
	hidden: ContactExtraFields[];
}

export interface UpdateField {
	systemName: string;
	value: string | number | boolean;
}

export interface RfmTagsModel {
	currentTag: string;
	oldTag: string;
}

export interface ResValidation {
	isValidEmail?: 'valid' | 'notValid' | 'alreadyExist';
	isValidPhone?: 'valid' | 'notValid' | 'alreadyExist';
}

export type ChannelType =
	| 'email'
	| 'mobPush'
	| 'push'
	| 'sms'
	| 'telegram'
	| 'viber'
	| 'whatsapp';

export type EventsType =
	| 'send'
	| 'open'
	| 'click'
	| 'bounce'
	| 'complain'
	| 'windowShow'
	| 'windowClose'
	| 'windowClick'
	| 'dataLeave';
