import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RestService } from 'utils';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';

import {
	Segment,
	CountModel,
	SubscribersQuantityModel,
	RelationsModel,
	DeleteData,
	ExportData,
	SegmentQuantityModel,
	EventParam,
	EventBody
} from './segment.model';

@Injectable({
	providedIn: 'root'
})
export class SegmentsDataService extends RestService<Segment> {
	constructor(private http: HttpClient) {
		super(http, 'enkod/segments');
	}

	// Баг на беке. Id сегмента нужно принимать в url
	update(segment: Segment) {
		return this.http.put<Segment>('enkod/segments/', segment);
	}

	getRelations(id: ID) {
		return this.http.get(
			`enkod/segments/${id}/relations/`
		) as Observable<RelationsModel>;
	}

	startCount(body: CountModel, id?: number) {
		return this.http.post<string>('enkod/segments/count/', { ...body, id });
	}

	getCount(id: string) {
		return this.http.get<SubscribersQuantityModel>(
			`enkod/segments/count/${id}`
		);
	}

	closeCountConnection(id: string) {
		return this.http.delete(`enkod/segments/count/${id}`);
	}

	startExport(
		entity: ExportData,
		fields: string,
		extraFields: string,
		id?: number
	) {
		return this.http.post(
			`enkod/export/segment/?tableName=${entity.tableName}&fields=${fields}&extrafields=${extraFields}`,
			{ ...entity, id }
		);
	}

	getExportStatus(id: string) {
		return this.http.get<SegmentQuantityModel>(
			`enkod/export/segment/${id}`
		);
	}

	checkDeletedData(id: number) {
		return this.http.get(`enkod/segments/deleteCheck/${id}`) as Observable<
			DeleteData[]
		>;
	}

	getUserEventList(): Observable<EventBody[]> {
		return this.http.get<EventBody[]>(
			'enkod/customEvent/all/?sortOrder=desc&sortKey=id'
		);
	}

	getUserParamList(): Observable<EventParam[]> {
		const dataTypes = 'string,float,number,bool,date,dateTime';

		return this.http.get<EventParam[]>(
			`enkod/param/all/?sortOrder=desc&sortKey=id&dataTypes=${dataTypes}`
		);
	}
}
