<en-primitive-range-picker *ngIf="rangePicker"></en-primitive-range-picker>
<div class="wrapper__calendar-range">
    <div>
        <en-primitive-year-month-pagination automation-id="tui-calendar__pagination"
                                            class="pagination"
                                            [position]="isFirst"
                                            (valueChange)="onPaginationValueChange($event)"
                                            (yearClick)="onPickerYearClick($event)"
                                            (monthClick)="onPickerMonthClick($event)">
        </en-primitive-year-month-pagination>
        <en-primitive-calendar automation-id="tui-calendar__calendar"
                               [position]="isFirst"
                               (dayClick)="onDayClick($event)"
                               (closeCalendar)="closeCalendar.emit()"></en-primitive-calendar>

    </div>
    <div>
        <en-primitive-year-month-pagination automation-id="tui-calendar__pagination"
                                            [position]="isDouble"
                                            class="pagination"
                                            (valueChange)="onPaginationValueChange($event)"
                                            (yearClick)="onPickerYearClick($event)"
                                            (monthClick)="onPickerMonthClick($event)">
        </en-primitive-year-month-pagination>
        <en-primitive-calendar automation-id="tui-calendar__calendar"
                               [position]="isDouble"
                               (dayClick)="onDayClick($event)"
                               (closeCalendar)="closeCalendar.emit()">
        </en-primitive-calendar>
    </div>
</div>
<!-- <en-primitive-date-time-picker (changeDate)=onDayClick($event) [type]="type"></en-primitive-date-time-picker> -->