import { ConditionGroupModel } from '@state-enKod/segments';

export function checkGroupEmpty(group: ConditionGroupModel): boolean {
	let isEmpty = true;

	if (group.isHide) return isEmpty;

	if (group.conditions.length) {
		group.conditions.forEach(condition => {
			const isHide = condition.isHide as boolean;
			if (!isHide) isEmpty = false;
		});
	}
	if (group.inners.length) {
		group.inners.forEach(inner => {
			const isInnerEmpty = checkGroupEmpty(inner);
			if (!isInnerEmpty) isEmpty = false;
		});
	}
	return isEmpty;
}
