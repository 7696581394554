import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiScrollbarModule } from '@enkod-core/components';
import {
	EnButtonModule,
	EnDialogModule,
	EnDropdownModule,
	EnInputModule,
	EnMessagesModule,
	SearchModule
} from 'ui-lib';
import { SegmentsTableModule } from '@enKod/segments/components';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SEGMENT_PAGINATION_PROVIDERS } from '@enKod/segments/segments-list/pagination-provider';
import { SharedModule } from '@shared';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { MainFormService } from '@enSend/scenario/scenario-wizard/services';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { ApiStartService } from './api-start.service';
import { ApiStartComponent } from './api-start.component';
import { ChosenItemInfoModule } from '../../components/chosen-item-info/chosen-item-info.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnButtonModule,
		SegmentsTableModule,
		EnDialogModule,
		SearchModule,
		TuiLetModule,
		ChosenItemInfoModule,
		EnDropdownModule,
		EnMessagesModule,
		EnInputModule,
		ClipboardModule,
		TranslateModule,
		TuiScrollbarModule
	],
	declarations: [ApiStartComponent],
	exports: [ApiStartComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: ApiStartService,
			multi: true
		},
		SEGMENT_PAGINATION_PROVIDERS,
		MainFormService
	]
})
export class ApiStartModule {}
