import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectViewService } from '@enSend/message/message-wizard/message-wizard-common/services/select-view.service';
import { EnSelectButtonModule } from 'ui-lib';
import { ViewButtonsComponent } from './view-buttons.component';

@NgModule({
	imports: [CommonModule, FormsModule, EnSelectButtonModule],
	declarations: [ViewButtonsComponent],
	exports: [ViewButtonsComponent],
	providers: [SelectViewService]
})
export class EnViewButtonsModule {}
