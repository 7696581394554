/* eslint-disable no-param-reassign */
import {
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Inject,
	EventEmitter,
	Output,
	OnDestroy,
	OnInit
} from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { finalize, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { SUBJECT_PROVIDER, SUBJECT_TOKEN } from '@enkod-core/tokens';
import {
	FileAttachment,
	MessagesService,
	NewAttachment
} from '@enSend/message/_states/_state-message';
import {
	AccountSubmailersSettingsQuery,
	AccountSubmailersSettingsStore
} from '@state-admin/accounts/account-maker/components/account-settings';

import { EnStepper, StepperSelectionChange } from 'ui-lib';

import { AttachmentLoadingService } from '@enSend/message/message-wizard/kit/components/attachments-control/attachmentLoading.service';
import { WizardDTO } from '../../wizard-dto';
import { MessageWizardService } from '../../message-wizard.service';
import { ENSEND_INVALID_STEP } from '../../constants';
import { StepSplitTestService } from '../../services';

@UntilDestroy()
@Component({
	selector: 'en-wizard-layout',
	templateUrl: './wizard-layout.component.html',
	styleUrls: ['./wizard-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: EnStepper, useExisting: WizardLayoutComponent },
		SUBJECT_PROVIDER
	]
})
export class WizardLayoutComponent
	extends EnStepper
	implements OnInit, OnDestroy
{
	readonly loadingNext$ = combineLatest([
		this.submailerQuery.submailersLoading$,
		this.attachmentLoadingService.isAttachmentsLoading$
	]).pipe(
		map(
			([submailerLoading, attachmentsLoading]) =>
				submailerLoading || attachmentsLoading
		)
	);

	@Output()
	readonly onLeaveWizard = new EventEmitter();

	@Output() readonly saved = new EventEmitter<boolean>();

	constructor(
		private submailerQuery: AccountSubmailersSettingsQuery,
		private accountSubmailersSettingsStore: AccountSubmailersSettingsStore,
		@Inject(SUBJECT_TOKEN)
		protected readonly subject: Subject<void>,
		@Inject(ENSEND_INVALID_STEP)
		protected readonly onInvalid$: Subject<StepperSelectionChange>,
		private messageWizardService: MessageWizardService,
		private messageService: MessagesService,
		private stepSplitTestService: StepSplitTestService,
		private attachmentLoadingService: AttachmentLoadingService,
		private router: Router,
		private cd: ChangeDetectorRef
	) {
		super(subject, cd);
		this.onInvalidStep
			.pipe(untilDestroyed(this))
			.subscribe((next: StepperSelectionChange) => {
				/** Проверяем что переход осуществляется на следущий шаг */
				if (next.index !== next.currentIndex)
					this.onInvalid$.next(next);
			});
	}

	get form() {
		return this.messageWizardService.form;
	}

	get sendingTypeValue(): string {
		return this.form.value.step_1.sendingType;
	}

	get typeMessageValue(): string {
		return this.form.get('type')?.value;
	}

	get fromEmailValue(): string {
		return (this.form.get('step_2.mails') as UntypedFormArray)
			?.at(this.stepSplitTestService.splitIndex)
			.get('sendSettings.fromEmail')?.value;
	}

	get isDraft() {
		return this.form.value.status === 'draft';
	}

	get isActivatedType(): boolean {
		return (
			this.sendingTypeValue === 'regular' ||
			this.sendingTypeValue === 'api' ||
			this.sendingTypeValue === 'event' ||
			this.sendingTypeValue === 'doi'
		);
	}

	ngOnInit() {
		this.accountSubmailersSettingsStore.setLoading(false);
		this.loadingNext$.pipe(untilDestroyed(this)).subscribe(res => {
			this.loading = res;
		});
	}

	/** Метод вызывается в message-wizard.component */
	markAsComplited(maxIndex: number): void {
		this.stepsArray.forEach((step, index) => {
			if (maxIndex > index && step.isStepValid) {
				step.markAsInteracted();
				step.completed = true;
			}
		});
	}

	saveAsDraft(): void {
		this.loading = true;
		const dto: WizardDTO = this.prepareDto();
		dto.isDraft = true;
		this.updateMessage(dto).subscribe();
	}

	leaveWizard() {
		this.onLeaveWizard.emit();
	}

	submit(): void {
		if (this.form.controls.step_2.invalid) {
			return;
		}
		this.loading = true;
		const dto = this.prepareDto();
		const message = {
			...dto,
			...(this.isActivatedType && { isActive: true }),
			isSplitTest: dto.mails && dto.mails.length > 1
		};

		this.updateMessage(message as WizardDTO).subscribe(() => {
			this.router.navigateByUrl('/ensend/messages');
		});
	}

	private updateMessage(message: WizardDTO): Observable<void> {
		return this.messageService.update(message).pipe(
			untilDestroyed(this),
			tap(() => {
				this.markAllAttachmentsAsOld();
				this.saved.emit(true);
				this.messageWizardService.isNew = false;
				this.messageWizardService.form.markAsUntouched();
			}),
			switchMap(() => {
				if (message.id != null) {
					return this.messageService.logImport(message.id);
				}
				return of(null);
			}),
			mapTo(undefined),
			finalize(() => {
				this.loading = false;
			})
		);
	}

	private prepareDto(): WizardDTO {
		const dto = new WizardDTO(this.form.value);
		dto.mails?.forEach(mail => {
			if (Array.isArray(mail.attachments)) {
				mail.attachments = mail.attachments.map(
					(att: FileAttachment & { isNew?: boolean }) => {
						delete att.isNew;
						return att;
					}
				);
			}
		});
		return dto;
	}

	private markAllAttachmentsAsOld(): void {
		const mails = this.form.value.step_2?.mails;
		if (!Array.isArray(mails)) return;

		const updatedMails = mails.map(mail => ({
			...mail,
			attachments: Array.isArray(mail.attachments)
				? mail.attachments.map((att: Partial<NewAttachment>) => ({
						...att,
						isNew: false
				  }))
				: mail.attachments
		}));

		this.form.patchValue(
			{ step_2: { mails: updatedMails } },
			{ emitEvent: false }
		);
	}

	ngOnDestroy(): void {
		this.form.markAsUntouched();
		this.attachmentLoadingService.setIsAttachmentsLoading(false);
		this.accountSubmailersSettingsStore.setLoading(false);
	}
}
