import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Renderer2,
	ViewChild
} from '@angular/core';
import { IframeTemplate } from '../misc';

@Component({
	selector: 'en-popup-preview-iframe[template]',
	templateUrl: './popup-preview-iframe.component.html',
	styleUrls: ['./popup-preview-iframe.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupPreviewIframeComponent {
	@ViewChild('iframe', { static: true })
	private readonly iframe: ElementRef<HTMLIFrameElement>;

	@Input()
	set template(value: IframeTemplate | null) {
		if (value) this.iframeHandler(value);
	}

	constructor(private rd: Renderer2) {}

	get iframedoc() {
		return this.iframe.nativeElement.contentDocument;
	}

	private iframeHandler({ html, css }: IframeTemplate) {
		this.iframedoc?.write(html);
		this.iframedoc?.close();
		const style = this.rd.createElement('style');
		style.innerText = css;
		this.iframedoc?.head.appendChild(style);
	}
}
