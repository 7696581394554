/* eslint-disable import/no-cycle */
import { InjectFlags } from '@angular/core';
import { dia } from '@clientio/rappid';
import { SendMessageStateToken } from '@enSend/_shared/tokens';
import { mainBlock } from './main.shapes';
import { block, blockView } from '../../base.shapes';
import { ScenarioShapesEnum } from '../../../../config/enum.shapes';

export const sendMessageBlock = mainBlock.define(
	ScenarioShapesEnum.SEND_MESSAGE_BLOCK,
	{},
	{
		initialize(...args: any) {
			block.prototype.initialize.apply(this, args);
			this.onChangeOptions();
		},

		onChangeOptions(args: any) {
			block.prototype.onChangeOptions.call(this, {
				updatePorts: true,
				...args
			});
		},
		remove() {
			const state = this.injector.get(
				SendMessageStateToken,
				InjectFlags.Optional
			);
			state?.delete(this.get('id'));
			mainBlock.prototype.remove.call(this);
		}
	}
);

export const sendMessageBlockView: dia.ElementView = Object.assign(blockView);
