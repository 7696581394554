import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { StartComponent } from './start.component';

@Injectable()
export class StartService implements InspectorItemPlugin {
	private readonly startContent = new PolymorpheusComponent(
		StartComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'start',
				name: 'start',
				content: this.startContent
			}
		]);
	}
}
