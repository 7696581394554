<form [formGroup]="form">
    <div class="description">
        <span class="en-text-large">
            {{'scenario_block_api_query.description' | translate }}
        </span>
    </div>

    <div class="en-inputgroup">
        <en-dropdown [options]="selectOptions[0]"
                     appendTo="body"
                     [disabled]="!isCreationMode"
                     formControlName="requestType">
        </en-dropdown>
        <input #urlInput
               class="h32"
               type="text"
               enInput
               [readonly]="!isCreationMode"
               formControlName="url"
               (focus)="urlFocus()" />
    </div>
    <en-error formControlName="url"
              class="error"></en-error>

    <ng-container formGroupName="headers">
        <div *ngFor="let field of headersArrayControls; index as i"
             [formGroupName]="i">

            <div class="field__header">
                <h3>{{ 'scenario_block_api_query.header_header' | translate }}</h3>
                <button *ngIf="headersArrayLength > 0 && isCreationMode"
                        enButton
                        class="en-button-lowercase h32"
                        type="button"
                        icon="en en-minus-circle-o"
                        iconColor="red-button-icon"
                        [disabled]="!isCreationMode"
                        (click)="removeHeaderField(i)"></button>
            </div>

            <div class="field">
                <input #keyHeaderInput
                       enInput
                       type="text"
                       placeholder="key"
                       [readonly]="!isCreationMode"
                       formControlName="key"
                       (focus)="keyHeaderFocus(i)">
                <en-error formControlName="key"></en-error>
            </div>

            <div class="field">
                <input #valueHeaderInput
                       enInput
                       type="text"
                       placeholder="value"
                       [readonly]="!isCreationMode"
                       formControlName="value"
                       (focus)="valueHeaderFocus(i)">
                <en-error formControlName="value"></en-error>
            </div>
        </div>
    </ng-container>

    <span [tippy]="maxHeadersFieldsLength && 'scenario_block_api_query.headers_tooltip'| translate"
          placement="bottom">
        <button *ngIf="isCreationMode"
                enButton
                type="button"
                class="en-button-lowercase"
                icon="en en-plus-circle-o"
                iconColor="blue-button-icon"
                iconPos="left"
                [disabled]="maxHeadersFieldsLength"
                [label]="'scenario_block_api_query.add_header' | translate"
                (click)="addHeaderField()">
        </button>
    </span>

    <ng-container formGroupName="params">
        <div *ngFor="let field of paramsArrayControls; index as i"
             [formGroupName]="i">

            <div class="field__header">
                <h3>{{ 'scenario_block_api_query.field_header' | translate }}</h3>
                <button *ngIf="paramsArrayLength > 0 && isCreationMode"
                        enButton
                        class="en-button-lowercase h32"
                        type="button"
                        icon="en en-minus-circle-o"
                        iconColor="red-button-icon"
                        [disabled]="!isCreationMode"
                        (click)="removeParamField(i)"></button>
            </div>

            <div class="field">
                <input #keyParamInput
                       enInput
                       type="text"
                       placeholder="key"
                       [readonly]="!isCreationMode"
                       formControlName="key"
                       (focus)="keyParamFocus(i)">
                <en-error formControlName="key"></en-error>
            </div>

            <div class="field">
                <input #valueParamInput
                       enInput
                       type="text"
                       placeholder="value"
                       [readonly]="!isCreationMode"
                       formControlName="value"
                       (focus)="valueParamFocus(i)">
                <en-error formControlName="value"></en-error>
            </div>
        </div>
    </ng-container>

    <span [tippy]="maxParamsFieldsLength && 'scenario_block_api_query.parameters_tooltip'| translate"
          class="add-button__wrapper"
          placement="bottom">
        <button *ngIf="isCreationMode"
                enButton
                type="button"
                class="en-button-lowercase"
                icon="en en-plus-circle-o"
                iconColor="blue-button-icon"
                iconPos="left"
                [disabled]="maxParamsFieldsLength"
                [label]="'scenario_block_api_query.add_parameter' | translate"
                (click)="addParameterField()">
        </button>
    </span>

    <div *ngIf="requestMethod === 'POST'"
         class="description">
        <div class="label-wrapper">
            <label for="requestBody"
                   class="en-text-medium">
                {{'scenario_block_api_query.request_body' | translate }}
            </label>

            <button enButton
                    class="h32 expand-button"
                    icon="en en-expand"
                    (click)="openDialog()">
            </button>
        </div>

        <div class="request-body">
            <ngx-codemirror #codemirror
                            pInputTextarea
                            id="requestBody"
                            [options]="options"
                            formControlName="json"
                            (focusChange)="bodyFocus()">
            </ngx-codemirror>
        </div>
    </div>

    <ng-container *ngIf="isCreationMode">
        <button enButton
                [label]="'scenario_block_api_query.dynamic_content' | translate"
                class="en-button-secondary h32"
                (click)="openDynamicContent = true">
        </button>
    </ng-container>

    <en-additional-settings [form]="form"
                            [disabled]="!isCreationMode"></en-additional-settings>
</form>

<en-json-plugin-dialog *ngIf="jsonDialogVisible"
                       [visible]="jsonDialogVisible"
                       [jsonControl]="jsonControl"
                       (hide)="jsonDialogVisible = false"></en-json-plugin-dialog>

<en-dynamic-content *ngIf="openDynamicContent"
                    location="apiQueryBlock"
                    [openDynamicContent]="openDynamicContent"
                    (onHide)="openDynamicContent = false"
                    [hasPosition]="hasPosition"
                    (selectedContent)="updateControl($event)"></en-dynamic-content>