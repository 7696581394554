import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { ID } from '@datorama/akita';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SelectItemDesctiption } from '@enkod-core/interfaces';
import {
	SELECT_OPTIONS_TOKEN,
	TABLE_COLS_TOKEN,
	VALIDATION_TOKEN
} from '@enkod-core/tokens';
import { MailingGroup } from '@state-enKod/mailing-groups';
import { Segment } from '@state-enKod/segments';
import { markAllAsDirty } from '@enkod-core/utils';

import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { DUPLICATE_HANDLING_OPTIONS } from './constants';
import { SCENARIO_MAILING_GROUP_TABLE_COLS } from './constants/table-cols-constant';
import { SUBSCRIPTION_TYPE_OPTIONS } from './constants/subscription-type-options';
import { mailingGroupValidator, segmentValidator } from './validators';
import { AbstractInspectorStart } from '../../abstract/abstract-inspector-start';

export interface SelectedMailingGroup {
	id: ID;
	label: string;
}

export interface BlockSelectOptions {
	duplicateHandling: SelectItemDesctiption[];
	subscriptionType: SelectItemDesctiption[];
}

interface SelectedSegment {
	id: ID;
	label: string;
}

@UntilDestroy()
@Component({
	selector: 'en-mailing-group',
	templateUrl: './mailing-group.component.html',
	styleUrls: ['./mailing-group.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: {
				duplicateHandling: DUPLICATE_HANDLING_OPTIONS,
				subscriptionType: SUBSCRIPTION_TYPE_OPTIONS
			}
		},
		{
			provide: TABLE_COLS_TOKEN,
			useValue: SCENARIO_MAILING_GROUP_TABLE_COLS
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailingGroupComponent
	extends AbstractInspectorStart
	implements OnInit
{
	form: UntypedFormGroup = this.initForm();

	visibleMailingGroupDialog: boolean;
	visibleSegmentDialog: boolean;

	selectedMailingGroup$ = new BehaviorSubject<SelectedMailingGroup[]>([]);
	selectedSegment$ = new BehaviorSubject<SelectedSegment[]>([]);

	constructor(
		public fb: UntypedFormBuilder,
		public translate: TranslateService,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: BlockSelectOptions,
		@Inject(VALIDATION_TOKEN)
		protected readonly checkValidate$: Subject<boolean>,
		private cd: ChangeDetectorRef
	) {
		super(fb, translate);
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get subscriptionTypeControl() {
		return this.form.get('subscriptionType');
	}

	get mailingGroupControl() {
		return this.form.get('mailingGroup');
	}

	get segmentControl() {
		return this.form.get('segment');
	}

	get showSegmentButton() {
		return this.subscriptionTypeControl?.value !== 'specificGroup';
	}

	get showMailingGroupButton() {
		return (
			this.subscriptionTypeControl?.value === 'specificGroup' ||
			this.subscriptionTypeControl?.value === 'specificGroupWithSegment'
		);
	}

	ngOnInit() {
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;

						const options = this.cell.get('options');

						this.patchOptions(options);
						this.initDuplicateHandling();

						const enableMarkAsDirty =
							('isValid' in options && !options.isValid) ||
							this.cell.attributes.invalid;
						if (enableMarkAsDirty) {
							markAllAsDirty(this.form);
						}
					}
				})
			)
			.subscribe();
		this.checkValidate$
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.form.get('mailingGroup')?.markAsDirty();
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private subscriptionTypeListener() {
		this.subscriptionTypeControl?.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(resp => {
					this.pickSubscriptionType(resp);
				})
			)
			.subscribe();
	}

	private initMainFormListener() {
		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(() => {
					const options = this.cell.get('options');
					this.changeCellProp('options', {
						...options,
						duplicateHandling: this.duplicateHandling.value,
						subscriptionType: this.subscriptionTypeControl?.value,
						isValid: this.form.valid
					});
				})
			)
			.subscribe();
	}

	private initForm() {
		return this.fb.group({
			subscriptionType: ['', Validators.required],
			mailingGroup: [
				{ value: [], disabled: true },
				mailingGroupValidator
			],
			segment: [{ value: [], disabled: true }, segmentValidator]
		});
	}

	private pickSubscriptionType(value: string) {
		const options = this.cell.get('options');

		this.form
			.get('mailingGroup')
			?.patchValue(this.selectedMailingGroup$.value);
		this.form.get('segment')?.patchValue(this.selectedSegment$.value);

		switch (value) {
			case 'specificGroup':
				this.form.get('mailingGroup')?.enable();
				this.form.get('segment')?.disable();
				this.changeCellProp('options', {
					...options,
					mailingGroup: {
						params: this.selectedMailingGroup$.value
					},
					segment: [],
					isValid: this.form.valid,
					subscriptionType: 'specificGroup'
				});
				break;
			case 'specificGroupWithSegment':
				this.form.get('mailingGroup')?.enable();
				this.form.get('segment')?.enable();
				this.form.get('segment')?.setValidators(segmentValidator);
				this.form.get('segment')?.updateValueAndValidity();
				this.changeCellProp('options', {
					...options,
					mailingGroup: {
						params: this.selectedMailingGroup$.value
					},
					segment: this.selectedSegment$.value,
					isValid: this.form.valid,
					subscriptionType: 'specificGroupWithSegment'
				});
				break;
			case 'anyGroupWithSegment':
				this.form.get('mailingGroup')?.disable();
				this.form.get('segment')?.enable();
				this.form.get('segment')?.setValidators(segmentValidator);
				this.form.get('segment')?.updateValueAndValidity();
				this.changeCellProp('options', {
					...options,
					mailingGroup: {
						params: this.selectedSegment$.value.length
							? this.selectedSegment$.value
							: []
					},
					segment: this.selectedSegment$.value,
					isValid: this.form.valid,
					subscriptionType: 'anyGroupWithSegment'
				});
				break;
			case 'mobPush':
				this.form.get('mailingGroup')?.disable();
				this.form.get('segment')?.enable();
				this.form.get('segment')?.clearValidators();
				this.form.get('segment')?.updateValueAndValidity();
				this.changeCellProp('options', {
					...options,
					mailingGroup: {
						params: this.selectedSegment$.value.length
							? this.selectedSegment$.value
							: []
					},
					segment: this.selectedSegment$.value,
					isValid: true,
					subscriptionType: 'mobPush'
				});
				break;
			default:
				break;
		}
	}

	patchOptions(options: any) {
		this.resetForm(options);

		const mobPush = this.subscriptionTypeControl?.value === 'mobPush';
		const anyGroup =
			this.subscriptionTypeControl?.value === 'anyGroupWithSegment';

		this.selectedMailingGroup$.next(
			options.mailingGroup.params.length && !mobPush && !anyGroup
				? [options.mailingGroup.params[0]]
				: []
		);

		if (mobPush || anyGroup) {
			this.selectedSegment$.next(
				options.mailingGroup.params.length
					? [options.mailingGroup.params[0]]
					: []
			);
		} else {
			this.selectedSegment$.next(options.segment ? options.segment : []);
		}

		this.pickSubscriptionType(options.subscriptionType ?? 'specificGroup');
	}

	private resetForm(options: any) {
		this.form.reset(
			{
				subscriptionType: '',
				mailingGroup: [],
				segment: []
			},
			{ emitEvent: false }
		);

		this.form = this.initForm();

		const newObj = {
			segment: options.segment ?? [],
			subscriptionType: options.subscriptionType ?? 'specificGroup',
			mailingGroup: options.mailingGroup.params[0]
				? [options.mailingGroup.params[0]]
				: []
		};
		this.form.patchValue(newObj);
		this.initMainFormListener();
		this.subscriptionTypeListener();
	}

	public selectMailingGroup(group: MailingGroup) {
		const nextMailingGroup: SelectedMailingGroup = {
			id: group?.id,
			label: group.name
		};
		this.selectedMailingGroup$.next([nextMailingGroup]);
		this.mailingGroupControl?.patchValue([nextMailingGroup]);
		this.changeCellProp(
			['options', 'mailingGroup', 'params'],
			[nextMailingGroup]
		);
		this.visibleMailingGroupDialog = false;
	}

	public selectSegment(segment: Segment) {
		const nextSegment: SelectedSegment = {
			id: segment?.id,
			label: segment.name
		};
		this.selectedSegment$.next([nextSegment]);
		this.segmentControl?.patchValue([nextSegment]);
		this.changeCellProp(['options', 'segment'], [nextSegment]);
		this.visibleSegmentDialog = false;
		if (
			this.subscriptionTypeControl?.value === 'mobPush' ||
			this.subscriptionTypeControl?.value === 'anyGroupWithSegment'
		)
			this.changeCellProp(
				['options', 'mailingGroup', 'params'],
				[nextSegment]
			);
	}

	public removeMailingGroup() {
		const options = this.cell.get('options');
		this.mailingGroupControl?.patchValue([]);
		this.mailingGroupControl?.markAsDirty();
		this.selectedMailingGroup$.next([]);
		this.changeCellProp(['options'], {
			...options,
			mailingGroup: {
				params: []
			},
			isValid: this.form.valid
		});
	}

	public removeSegment() {
		const options = this.cell.get('options');
		this.segmentControl?.patchValue([]);
		this.segmentControl?.markAsDirty();
		this.selectedSegment$.next([]);
		this.changeCellProp(['options'], {
			...options,
			segment: [],
			isValid: this.form.valid
		});
		if (this.subscriptionTypeControl?.value === 'mobPush')
			this.changeCellProp(['options'], {
				...options,
				mailingGroup: {
					params: []
				},
				segment: [],
				isValid: this.form.valid
			});

		if (this.subscriptionTypeControl?.value === 'anyGroupWithSegment')
			this.changeCellProp(['options'], {
				...options,
				mailingGroup: {
					params: []
				},
				segment: [],
				isValid: this.form.valid
			});
	}
}
