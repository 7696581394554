import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { SelectItem } from 'primeng/api';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Field, FieldOption } from '@enSend/_shared/models';
import { BlockScenarioFieldsQuery } from '@enSend/scenario/scenario-wizard/components/fields/_state/scn-fields-blocks.query';
import { MainFormService } from '@enSend/scenario/scenario-wizard/services';
import { ExtraField, ExtraFieldsService } from '@state-enKod/extra-fields';
import { FieldsService } from '@enSend/scenario/scenario-wizard/components/fields/services/fields.service';
import { declinationWord, UnitsName } from '@enkod-core/utils';
import { DateAccountTimezoneService } from '@enkod-core/services';
import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { AbstractInspector } from '../../abstract';
import { ExtraFieldOption, PauseBlockParam, PauseType } from './pause.model';

@UntilDestroy()
@Component({
	selector: 'en-pause',
	templateUrl: './pause.component.html',
	styleUrls: ['./pause.component.scss'],
	providers: [
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				invalidDate: 'scenario_block_pause.validation_error'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PauseComponent
	extends AbstractInspector
	implements OnInit, OnDestroy
{
	readonly rangeConfig = { minValue: 0, maxValue: Number.MAX_SAFE_INTEGER };

	form: UntypedFormGroup;

	pauseTypeControl = this.fb.control(null);

	pauseTypeOptions: SelectItem[] = [
		{
			label: this.translate.instant(
				'scenario_block_pause.duration_option'
			),
			value: 'duration'
		},
		{
			label: this.translate.instant(
				'scenario_block_pause.specific_option'
			),
			value: 'specificDate'
		},
		{
			label: this.translate.instant(
				'scenario_block_pause.select_data_field'
			),
			value: 'extraFields'
		},
		{
			label: this.translate.instant(
				'scenario_block_pause.scn_fields_option'
			),
			value: 'scenarioFields'
		}
	];

	specificDateControl = this.fb.control([{ value: '', disabled: true }]);

	prevSpecificData: number;

	options: SelectItem[] = [
		{ value: 'minute' },
		{ value: 'hour' },
		{ value: 'day' },
		{ value: 'month' }
	];

	operatorOptions: SelectItem[] = [
		{ value: 'before', label: 'до' },
		{ value: 'after', label: 'после' }
	];

	options$: BehaviorSubject<SelectItem[]> = new BehaviorSubject(this.options);

	scenarioFieldOptions: FieldOption[] = [];
	extraFieldOptions: ExtraFieldOption[] = [];

	public props = {
		path: ['options', 'pause', 'params']
	};

	constructor(
		private fb: UntypedFormBuilder,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		private translate: TranslateService,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		private cd: ChangeDetectorRef,
		private timezoneService: DateAccountTimezoneService,
		private extraFieldsService: ExtraFieldsService,
		private fieldsService: FieldsService,
		private blockQuery: BlockScenarioFieldsQuery,
		private mainFormService: MainFormService
	) {
		super();
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get disableInput() {
		return this.isCreationMode ? null : true;
	}

	get pauseType() {
		return this.pauseTypeControl.value;
	}

	get currentTime() {
		return Math.trunc(Date.now() / 1000);
	}

	get scenarioId() {
		return this.mainFormService.form.get('id')?.value ?? 0;
	}

	ngOnInit() {
		this.form = this.fb.group(this.getFallbackValue());
		this.initListeners();

		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					// костыльное условие
					// событие происходит быстрее чем уничтожается компонент
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;
						const subType = this.cell.get('subType');

						this.props.path = ['options', subType, 'params'];

						const dateValueFromCalendar =
							this.specificDateControl.value;
						if (
							dateValueFromCalendar &&
							dateValueFromCalendar < this.currentTime
						) {
							Promise.resolve().then(() => {
								this.specificDateControl.setErrors({
									invalidDate: true
								});
								this.cd.markForCheck();
							});
						}

						const scenarioId = this.isCreationMode
							? this.scenarioId
							: context.scenarioId;

						this.initExtraFields();
						this.initScenarioFields(scenarioId);
					}
				})
			)
			.subscribe();
	}

	private initExtraFields() {
		this.extraFieldsService
			.getAllList()
			.pipe(
				untilDestroyed(this),
				map(fields =>
					fields
						.filter(
							field =>
								field.dataType === 'date' ||
								field.dataType === 'dateTime'
						)
						.map((field: ExtraField) => ({
							label: field.name,
							value: {
								id: field.id,
								serviceName: field.serviceName,
								type: field.dataType,
								name: field.name
							}
						}))
				),
				tap((extraFieldOptions: ExtraFieldOption[]) => {
					this.extraFieldOptions = extraFieldOptions;
					this.patchOnInit();
				})
			)
			.subscribe();
	}

	private initScenarioFields(scenarioId: number) {
		this.blockQuery.fields$
			.pipe(
				untilDestroyed(this),
				map(fields =>
					fields
						.filter(
							field =>
								field.scenarioId === scenarioId &&
								(field.type === 'date' ||
									field.type === 'dateTime')
						)
						.map((field: Field) => ({
							id: field.guid,
							label: field.name,
							value: field.name,
							type: field.type
						}))
				),
				tap((scenarioFieldOptions: FieldOption[]) => {
					this.scenarioFieldOptions = scenarioFieldOptions;
				})
			)
			.subscribe();

		this.fieldsService
			.getFields(scenarioId)
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.patchOnInit();
				})
			)
			.subscribe();
	}

	private initListeners() {
		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				tap((value: PauseBlockParam) => {
					this.changeCellProp(
						['options', 'isValid'],
						this.isFormValid()
					);
					this.updateDurationDropdownOptions(value.value);

					switch (this.pauseType) {
						case 'extraFields':
							this.changeCellProp(
								this.props.path,
								this.getExtraFieldPauseValue(value)
							);
							break;
						case 'scenarioFields':
							this.changeCellProp(
								this.props.path,
								this.getFieldPauseValue(value)
							);
							break;
						default:
							this.changeCellProp(
								this.props.path,
								this.getDurationPauseValue(value)
							);
					}
				})
			)
			.subscribe();

		// сброс невалидности при переключении типа паузы
		this.pauseTypeControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.changeCellProp(['options', 'isValid'], this.isFormValid());
			});

		this.specificDateControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(resp => {
					if (
						resp &&
						this.pauseTypeControl.value === 'specificDate'
					) {
						this.changeCellProp(
							this.props.path,
							this.getSpecificPauseValue(resp)
						);
						this.prevSpecificData = resp;
					} else {
						this.changeCellProp('options', {
							pause: {
								params: []
							}
						});
					}

					if (resp && resp < this.currentTime) {
						this.specificDateControl.setErrors({
							invalidDate: true
						});
					}
				})
			)
			.subscribe();
	}

	private isFormValid() {
		const { value } = this.form;
		switch (this.pauseType) {
			case 'extraFields':
				return !!value.extraField?.serviceName && value.value !== null;
			case 'scenarioFields':
				return !!value.scenarioField && value.value !== null;
			case 'duration':
				return !!value.value;
			default:
				return true;
		}
	}

	private getFallbackValue() {
		return {
			scenarioField: '',
			value: 0,
			unit: 'day',
			operator: 'before',
			id: 0,
			extraField: {}
		};
	}

	private getDurationPauseValue(pauseParam: PauseBlockParam) {
		const { value, unit } = pauseParam;
		const lowerCaseOption = this.translate.instant(
			declinationWord(unit as keyof UnitsName, value)
		);
		return [
			{
				id: value,
				unit,
				value,
				label: `${value} ${lowerCaseOption}`
			}
		];
	}

	private getSpecificPauseValue(value: number) {
		return [
			{
				id: value,
				unit: 'until',
				value,
				label: this.timezoneService.convertDate(
					value,
					'DD.MM.yyyy HH:mm'
				)
			}
		];
	}

	private getFieldPauseValue(pauseParam: PauseBlockParam) {
		const { scenarioField, value, unit, operator } = pauseParam;
		return [
			{
				id: value,
				scenarioField,
				unit,
				value,
				operator,
				label: scenarioField
			}
		];
	}

	private getExtraFieldPauseValue(pauseParam: PauseBlockParam) {
		const { extraField, value, unit, operator } = pauseParam;
		return [
			{
				id: extraField?.id,
				extraField: extraField?.serviceName,
				unit,
				value,
				operator,
				label: extraField?.name
			}
		];
	}

	private updateDurationDropdownOptions(value: number) {
		const updatedOptions: SelectItem[] = [];
		this.options.forEach(option => {
			const lowerCaseOption = this.translate.instant(
				declinationWord(option.value, value)
			);
			const optionLabel =
				lowerCaseOption.charAt(0).toUpperCase() +
				lowerCaseOption.slice(1);

			updatedOptions.push({
				label:
					this.pauseType === 'duration'
						? optionLabel
						: lowerCaseOption,
				value: option.value
			});
		});
		this.options$.next(updatedOptions);
	}

	private patchOnInit() {
		const { pauseType } = this.cell.attributes.options;

		const params = this.cell.prop(this.props.path) || [];

		const { unit, value, operator, scenarioField, id } =
			params[0] || this.getFallbackValue();

		this.pauseTypeControl.patchValue(pauseType || 'duration');

		switch (pauseType) {
			case 'specificDate':
				this.specificDateControl.patchValue(value);
				break;
			case 'extraFields':
				this.form.patchValue({
					extraField: this.extraFieldOptions.find(
						option => option.value.id === id
					)?.value,
					value,
					unit,
					operator
				});
				break;
			case 'scenarioFields':
				this.form.patchValue({ scenarioField, value, unit, operator });
				break;
			default:
				this.form.patchValue({ unit, value });
				break;
		}
	}

	public onPauseTypeChange(event: PauseType) {
		this.changeCellProp(['options', 'pauseType'], event);
		if (event === 'specificDate') {
			const now = new Date();
			const then = new Date(
				now.getFullYear(),
				now.getMonth(),
				now.getDate() + 1,
				0,
				0,
				0
			);
			const todayMs = now.getTime() - then.getTime();
			const dateValue = Math.trunc((Date.now() - todayMs) / 1000);
			this.specificDateControl.patchValue(dateValue);
			this.changeCellProp(
				this.props.path,
				this.getSpecificPauseValue(dateValue)
			);
		}
		if (event === 'duration') {
			const patchValue: PauseBlockParam = { unit: 'day', value: 0 };
			this.updateDurationDropdownOptions(patchValue.value);

			this.changeCellProp(
				this.props.path,
				this.getDurationPauseValue(patchValue)
			);
			this.form.patchValue(patchValue);
		}
		if (event === 'extraFields') {
			const patchValue: PauseBlockParam = {
				unit: 'day',
				value: 0,
				operator: 'before',
				extraField: {
					id: 0,
					type: '',
					serviceName: '',
					name: ''
				},
				id: ''
			};
			this.updateDurationDropdownOptions(patchValue.value);

			this.changeCellProp(
				this.props.path,
				this.getExtraFieldPauseValue(patchValue)
			);
			this.form.patchValue(patchValue);
		}
		if (event === 'scenarioFields') {
			const patchValue: PauseBlockParam = {
				scenarioField: '',
				unit: 'day',
				value: 0,
				operator: 'before'
			};
			this.updateDurationDropdownOptions(patchValue.value);

			this.changeCellProp(
				this.props.path,
				this.getFieldPauseValue(patchValue)
			);
			this.form.patchValue(patchValue);
		}
	}

	private setPrevOptions(): void {
		this.changeCellProp('options', {
			pause: {
				params: this.getSpecificPauseValue(this.prevSpecificData)
			},
			pauseType: this.pauseType
		});
	}

	ngOnDestroy(): void {
		if (this.specificDateControl.value === null) this.setPrevOptions();
	}
}
