import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IMessageDetail } from './message-detail.model';

export interface MessageDetailState extends EntityState<IMessageDetail> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'message-detail'
})
export class MessageDetailStore extends EntityStore<MessageDetailState> {
	constructor() {
		super({
			filter: {
				startDate: null,
				endDate: null
			},
			activeSplitVersion: 0
		});
	}
}
