import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

import { SearchService } from 'ui-lib';
import { SUBJECT_TOKEN } from '@enkod-core/tokens/subject-token';
import { MessagesService } from '@enSend/message/_states/_state-message';
import { MessagesTab, MESSAGES_TAB_TOKEN } from '@enSend/message/kit';
import {
	MessageModel,
	WizardOTD,
	StepConditionService,
	StepEditorService,
	StepSplitTestService
} from '@enSend/message/message-wizard/message-wizard-common';

@UntilDestroy()
@Component({
	selector: 'en-copy-message-dialog',
	templateUrl: './copy-message-dialog.component.html',
	styleUrls: ['./copy-message-dialog.component.scss'],
	providers: [SearchService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyMessageDialogComponent implements OnInit {
	searchForm: UntypedFormGroup = this.fb.group({
		searchControl: ''
	});

	selectMessage: MessageModel;
	activeIndex = 0;
	@Input() visible: boolean;
	@Input() form: UntypedFormGroup;

	@Output() hide = new EventEmitter<void>();

	constructor(
		@Inject(MESSAGES_TAB_TOKEN)
		public readonly tabs: MessagesTab[],
		private fb: UntypedFormBuilder,
		private searchService: SearchService,
		private messagesService: MessagesService,
		private editorService: StepEditorService,
		private splitTestService: StepSplitTestService,
		private conditionService: StepConditionService,
		@Inject(SUBJECT_TOKEN) private forceRerender: Subject<void>,
		private confirmationService: ConfirmationService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.initSearch();
	}

	select(message: MessageModel) {
		this.selectMessage = message;

		if (
			message.isSplitTest !== undefined &&
			message.isSplitTest &&
			this.isCurrentMessageSplit
		) {
			this.confirmCopySplit();
		} else this.getMessageAndPatchForm();
	}

	private getMessageAndPatchForm() {
		this.messagesService
			.getDetail(this.selectMessage.id)
			.pipe(
				untilDestroyed(this),
				tap(message => {
					this.selectMessage = message;

					const otd = new WizardOTD(message);
					// если выбранное сообщение сплит
					const { sendingType } = this.conditionService;

					if (
						this.isSelectedMessageSplit &&
						(sendingType === 'sendnow' ||
							sendingType === 'sendlate')
					) {
						this.splitTestService.setSplitIndex(0);
						this.editorService.patchMailSplit(otd);
					}

					// если текущее сообщение сплит
					else if (this.isCurrentMessageSplit && otd.step_2.mails) {
						(this.form.get('mails') as UntypedFormArray)
							.at(this.splitTestService.splitIndex)
							.patchValue(otd.step_2.mails[0]);
					} else this.form.patchValue(otd.step_2);

					this.forceRerender.next();
					this.hide.emit();
				})
			)
			.subscribe();
	}

	private initSearch() {
		this.searchForm
			.get('searchControl')
			?.valueChanges.pipe(
				untilDestroyed(this),
				tap(value => this.searchService.setSearchValue(value))
			)
			.subscribe();
	}

	get isSelectedMessageSplit() {
		return (
			this.selectMessage.type === 'mail' &&
			this.selectMessage.mails &&
			this.selectMessage.mails.length > 1
		);
	}

	get isCurrentMessageSplit() {
		return this.splitTestService.splitLength > 1;
	}

	private confirmCopySplit() {
		const message1 = this.translate.instant(
			'split_test.confirm_copy_split_message_1'
		);
		const message2 = this.translate.instant(
			'split_test.confirm_copy_split_message_2'
		);

		this.confirmationService.confirm({
			key: 'confirmCopySplit',
			header: this.translate.instant(
				'split_test.confirm_copy_split_header'
			),
			message: `<div>${message1}</div><br><div class="font-weight-500">${message2}</div>`,
			acceptLabel: this.translate.instant(
				'split_test.confirm_copy_split_accept'
			),
			rejectLabel: this.translate.instant(
				'split_test.confirm_copy_split_reject'
			),
			accept: () => {
				this.getMessageAndPatchForm();
			}
		});
	}
}
