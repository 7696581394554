<div class="wrapper">
    <div class="label">{{ ('chatbot_message_block.button'| translate) + ' ' + (index + 1)}}</div>

    <input enInput
           [placeholder]="'chatbot_message_block.button'| translate"
           [formControl]="textControl"/>
    
    <button enButton
            class="en-button-lowercase delete-button h32"
            icon="en en-trash"
            (click)="deleteButton.emit()"></button>
</div>
<en-error [formControl]="textControl"></en-error>

<div class="wrapper">
    <div class="label">{{ ('chatbot_message_block.link'| translate) + ' ' + (index + 1)}}</div>

    <input enInput 
           [formControl]="urlControl"/>
    
</div>
<en-error [formControl]="urlControl"></en-error>