<form [formGroup]="form">
    <div class="addition"
         [class.highlight]="isHover">
        <en-dropdown class="input__small"
                     styleClass="en-dropdown-solid"
                     [options]="optionsMessageCount"
                     formControlName="operator"></en-dropdown>
        <input class="h32 text-overflow"
               type="number"
               min="1"
               max="1000"
               step="1"
               enInput
               enDynamicWidth
               pKeyFilter="pint"
               showClear="true"
               formControlName="value" />
        <span class="center-span-text">{{ 'time' | declinationWord:countValue | translate }}</span>
        <i *ngIf="isShowRemoveButton"
           class="button-remove-condition cancel-icon en en-cancel-fill"
           (mouseenter)="isHover = true"
           (mouseleave)="isHover = false"
           (click)="remove.emit()"></i>
    </div>
</form>