<form [formGroup]="form"> 
    <span class="en-text-medium option-header">
        {{ 'chatbot_message_block.message_text' | translate }}</span>
    <en-text-area class="text-area"
                  [placeholder]="'chatbot_message_block.message_text'| translate"
                  [manualInvalid$]="manualInvalid$" 
                  formControlName="text"></en-text-area>
    <en-error class='en-error' formControlName="text"></en-error>
    <en-symbol-counter [limit]="messageLimit"
                       formControlName="text"></en-symbol-counter>

    <div class="buttons-container">
        <div class="en-text-medium option-header">
            {{ 'chatbot_message_block.buttons' | translate }}</div>
        <en-buttons-container [buttons]="buttonsGroup"></en-buttons-container>
        <button *ngIf="otherButtons.length < buttonLimit"
                enButton
                type="button"
                class="en-button-lowercase"
                icon="en en-plus-circle-o"
                iconColor="blue-button-icon"
                iconPos="left"
                [matMenuTriggerFor]="menu"
                [label]="'chatbot_message_block.add_button' | translate"></button>
        <span class="buttons-container__footer en-text-secondary"> {{ 'chatbot_message_block.buttons_limit_message' | translate }} </span>
    </div>    
</form>

<mat-menu #menu="matMenu"
          class="en-menu">
    <div mat-menu-item
         (click)="addButton('general')">{{'chatbot_message_block.text_button' | translate}}</div>
    <div mat-menu-item
         (click)="addButton('link')">{{'chatbot_message_block.link_button' | translate}}</div>
    <div mat-menu-item
         [class.disabled]="backButtonExist"
         [disabled]="backButtonExist"
         (click)="addButton('restart')">{{'chatbot_message_block.back_button' | translate}}</div>
</mat-menu>