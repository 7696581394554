import { Subscription } from 'rxjs';
import { ID } from '@datorama/akita';
import { MailEntity } from '@enSend/message/message-wizard/message-wizard-common';
import { WaTemplateParamsOTD } from '@enSend/message/whatsapp-template-wizard/models';
import { MailingGroup } from '@state-enKod/mailing-groups';

export interface MessageResponse {
	total: number;
	result: Message[];
}
export interface Message {
	id: ID;
	name?: string;
	sendingTime: number;
	sendingType: SendingType;
	status: StatusType;
	tags: string[];
	type: MessageType;
	segments?: SegmentMessage[];
	isSplitTest?: boolean;
	whatsappTemplateId?: number;
	whatsappParams?: WaTemplateParamsOTD;
	mails?: MailEntity[];
	sendingSettings?: SendingSettings;
}

export type ScenarioMessage = Message & {
	scenarioId: number;
	schedledTaskId: number;
};

export type FiltersMessage = {
	channel: MessageType;
	sendingTime: [number, number];
	tags: string[];
	sendingType: SendingType;
	status: StatusType;
	noDraft: boolean;
	scenarios: string[];
};

export interface CommonFiltersMessage extends FiltersMessage {
	search: string;
}

export type FiltersModel = [
	number,
	[number, CommonFiltersMessage, SortModel, string]
];

export interface SortModel {
	field: string;
	order: number;
}

export interface SendingTypeOptions {
	label: string;
	value: SendingType;
}

export interface StatusOptions {
	label: string;
	value: StatusType;
}

export type StatusType =
	| 'active'
	| 'inactive'
	| 'planned'
	| 'sent'
	| 'waiting'
	| 'draft'
	| 'finished'
	| 'testing'
	| 'choosingWinner'
	| 'sending';

export type SendingType =
	| 'sendnow'
	| 'sendlate'
	| 'regular'
	| 'api'
	| 'event'
	| 'doi'
	| 'scenario'
	| 'whatsapp';

export type MessageType =
	| 'mail'
	| 'push'
	| 'mobPush'
	| 'sms'
	| 'whatsapp'
	| 'telegram';

export interface ChangeStatus {
	id: ID;
	status: boolean;
}

export interface DeleteData {
	id: number;
	name: string;
}

export interface SegmentMessage {
	id: number;
	name: string;
}

export type ActivatedStatus = 'active' | 'inactive';

export interface RelatedMessages {
	isRelated?: boolean;
	messages?: string[];
}

export interface ScenarioQueryParams {
	limit: number;
	search?: string;
}

export function createMessage() {
	return {} as Message;
}

export type PlainText = {
	plainText: string;
	preheader: string;
};

type Units = 'minute' | 'hour' | 'day' | 'week' | 'month';

type SendingSettings = {
	groups: MailingGroup[];
	units: Units;
	value: number;
};

export interface SplitMail {
	messageId: ID;
	mailId: ID;
	splitTestLabel: string;
	attachments: FileAttachment[];
}

export interface FileAttachment {
	name: string;
	size: number;
	link?: string;
	mimeType: string;
}

export interface NewAttachment extends FileAttachment {
	isLoading: boolean;
	uploadSub?: Subscription;
	isNew?: boolean;
}

export interface UploadAttachmentResponse extends FileAttachment {
	messageId: ID;
	mailId: ID;
}

export interface DeletingAttachment {
	name: string;
	messageId: ID;
	mailId: ID;
}
