/* eslint-disable import/no-cycle */
import { shapes } from '@clientio/rappid';
import {
	PortItem,
	OnChangeOptions,
	Option,
	StatusType,
	StatusTypeEnum
} from '@enSend/_shared/models';
import { base } from './base.shape';
import { RappidService } from '../../../../services/rappid.service';
import { ShapeTypesEnum } from '../../../config/enum.shapes';
import {
	getDistributionSubheader,
	getExtraFieldSubheader,
	getMailingGroupSubheader,
	getMessageStartSubheader,
	getPauseSubheader,
	getChannelSubheader,
	chatbotSubheader
} from './utils/getSubheaderText';

export const block = base.define(
	ShapeTypesEnum.BLOCK,
	{
		options: {},
		blockLayout: {},
		optionsTop: 0,
		subheaderHeight: 24,
		optionHeight: 28,
		questionHeight: 46,
		paddingBottom: 10,
		padding: 4,
		minWidth: 150,
		notForValidation: {
			// finish: 'finish'
			// distribution: 'distribution'
		},
		distribution: {
			unsuitable: ''
		},
		unsuitableOptionHeight: 0,

		size: { width: 200, height: 52 },
		ports: {
			groups: {
				in: {
					position: {
						name: 'left'
					},
					size: {
						width: 16,
						height: 16
					},
					attrs: {
						portBody: {
							magnet: 'passive',
							refWidth: '100%',
							refHeight: '100%',
							refX: '-50%',
							refY: '-50%',
							rx: '50%',
							ry: '50%',
							fill: '#fff',
							stroke: '#8B8F9E',
							strokeWidth: 1
						}
					},
					markup: [
						{
							tagName: 'rect',
							selector: 'portBody'
						}
					]
				},
				out: {
					position: 'right',
					size: {
						width: 16,
						height: 16
					},
					attrs: {
						portBody: {
							magnet: true,
							refWidth: '100%',
							refHeight: '100%',
							refX: '-50%',
							refY: '-50%',
							rx: '50%',
							ry: '50%',
							fill: '#fff',
							stroke: '#8B8F9E',
							strokeWidth: 1
						}
					},
					markup: [
						{
							tagName: 'rect',
							selector: 'portBody'
						}
					]
				}
			}
		},
		attrs: {
			body: {
				refWidth: '100%',
				refHeight: '100%',
				rx: 8,
				fill: 'white'
			},
			header: {},
			headerRect: {
				refX: '50%',
				width: 32,
				height: 32,
				rx: 2,
				x: -32 / 2,
				y: -32 / 2,
				strokeWidth: 1
			},
			headerIcon: {
				ref: 'headerRect',
				refX: '50%',
				refY: '50%',
				x: -20 / 2,
				y: -20 / 2
			},
			subheaderOption: {
				ref: 'body',
				refY: 40
			},
			descContainer: {
				ref: 'subheaderOption',
				refDy: 8
			},
			footerOption: {
				ref: 'descContainer',
				refDy: 0
			},
			'.desc__rect': {
				refX: 8,
				width: 'calc(w - 16)',
				height: 24,
				rx: 4,
				fill: '#F3F3F5'
			},
			'.desc__text': {
				textAnchor: 'middle',
				textVerticalAnchor: 'middle',
				refX: '50%',
				refY: 12,
				fontSize: 11,
				fill: '#434652',
				fontFamily: 'var(--en-font-family)',
				textWrap: {
					width: 186,
					maxLineCount: 1,
					ellipsis: true
				}
			},
			'.desc__value': {
				textAnchor: 'middle',
				textVerticalAnchor: 'middle',
				refX: '50%',
				refY: 12,
				fontSize: 11,
				fill: '#6E7687',
				fontFamily: 'var(--en-font-family)',
				textWrap: {
					width: 186,
					maxLineCount: 1,
					ellipsis: true
				}
			},
			name: {
				ref: 'body',
				refX: 0.5,
				refY: 25,
				textAnchor: 'middle',
				fontSize: 13,
				fill: '#434652',
				fontFamily: 'var(--en-font-family)'
			},
			'.invalid': {
				cursor: 'pointer'
			},

			'.invalid__rect': {
				fill: 'white',
				width: 16,
				height: 16,
				rx: '50%',
				ry: '50%',
				x: -16 / 2,
				y: -16 / 2
			},
			'.invalid__img': {
				x: -16 / 2,
				y: -16 / 2
			},
			'.subheader-option-name__wrapper': {
				refX: 8,
				rx: 4,
				width: 'calc(w - 16)',
				height: 24,
				fill: '#FFFFFF'
			},
			'.subheader-option-name': {
				textAnchor: 'middle',
				textVerticalAnchor: 'middle',
				refX: '50%',
				refY: 16,
				fontSize: 11,
				fill: '#757885',
				fontFamily: 'var(--en-font-family)',
				textWrap: {
					width: 186,
					maxLineCount: 1,
					ellipsis: true
				},
				text: ''
			},
			'footer-option-name__wrapper': {
				refX: 8,
				rx: 4,
				width: 'calc(w - 16)',
				height: 24,
				fill: '#FFFFFF'
			},
			'.footer-option-name': {
				textAnchor: 'middle',
				textVerticalAnchor: 'middle',
				refX: '50%',
				refY: 20,
				fontSize: 11,
				fill: '#434652',
				fontFamily: 'var(--en-font-family)',
				textWrap: {
					width: 186,
					maxLineCount: 1,
					ellipsis: true
				},
				text: ''
			}
		}
	},
	{
		markup: [
			{
				tagName: 'rect',
				className: 'en-body',
				selector: 'body'
			},
			{
				tagName: 'g',
				selector: 'header',
				children: [
					{ tagName: 'rect', selector: 'headerRect' },
					{
						tagName: 'image',
						selector: 'headerIcon'
					}
				]
			},
			{
				tagName: 'g',
				className: 'subheader-option',
				selector: 'subheaderOption'
			},
			{
				tagName: 'g',
				className: 'desc-options',
				selector: 'descContainer'
			},
			{
				tagName: 'g',
				className: 'footer-option',
				selector: 'footerOption'
			},
			{ tagName: 'text', selector: 'name' },
			{
				tagName: 'g',
				className: 'invalid'
			}
		],

		optionMarkup:
			'<g class="desc__block"><rect class="desc__rect"></rect><text class="desc__text"></text><text class="desc__value"></text></g>',

		subheaderMarkup:
			'<g class="subheader-option-block"><rect class="subheader-option-name__wrapper"></rect><text class="subheader-option-name"></text></g>',

		footerMarkup:
			'<g class="footer-option-block"><rect class="footer-option-name__wrapper"></rect><text class="footer-option-name"></text></g>',

		invalidMarkup:
			'<g class="invalid__tippy"><rect class="invalid__rect"></rect><image xlink:href="assets/scenario/exclamation-circle-o.svg#exclamation" class="invalid__img"></image></g>',

		autoresize() {
			const gap = this.get('paddingBottom');
			const questionHeight = this.get('questionHeight');
			const padding = this.get('padding');
			const optionsHeight = this.blockLayout.options[0]?.label
				? this.blockLayout.options.length *
						(this.get('optionHeight') + padding) +
				  this.get('optionsTop')
				: 0;
			const subheaderHeight = this.blockLayout.subheader
				? this.get('subheaderHeight')
				: 0;
			const footerHeight = this.blockLayout.footer ? 24 : 0;
			const height =
				optionsHeight +
				subheaderHeight +
				footerHeight +
				questionHeight +
				gap;

			const namespace = this.get('namespace') ?? 'scenario';
			const width = namespace === 'scenario' ? 200 : 260;

			this.resize(width, height);
		},

		onChangeOptions(changeOptions: OnChangeOptions) {
			const options = this.get('options');
			const subType = this.get('subType');
			const namespace = this.get('namespace') ?? 'scenario';
			const optionHeight = this.get('optionHeight');

			// First clean up the previously set attrs for the old options object.
			// We mark every new attribute object with the `dynamic` flag set to `true`.
			// This is how we recognize previously set attributes.
			const attrs = this.get('attrs');

			Object.keys(attrs).forEach((selector: any) => {
				if (attrs[selector].dynamic) {
					// Remove silently because we're going to update `attrs`
					// later in this method anyway.
					this.removeAttr(selector, { silent: true });
				}
			});

			// Collect new attrs for the new options.
			let offsetY = 0;
			const attrsUpdate = {} as any;
			const padding = this.get('padding');

			this.blockLayout =
				namespace === 'scenario'
					? this.getBlockLayout()
					: this.getChatbotBlockLayout();

			this.blockLayout.options.forEach((option: Option) => {
				if (changeOptions.updatePorts) this.updatePort(option);

				if (!option.label) return;

				const selector = `.desc__block_${option.port}`;
				attrsUpdate[selector] = {
					transform: `translate(0, ${offsetY})`,
					dynamic: true
				};
				attrsUpdate[`${selector} .desc__rect`] = {
					height: optionHeight,
					dynamic: true
				};
				attrsUpdate[`${selector} .desc__text`] = {
					text: option.label || '',
					dynamic: true,
					refY: optionHeight / 2
				};

				if (namespace === 'chatbot') {
					if (subType === 'message') {
						const visibility =
							option.type === 'link' ? 'visible' : 'hidden';
						attrsUpdate[`${selector} .link_icon`] = {
							visibility
						};
					}
				}

				if (namespace === 'scenario') {
					if (
						options[subType].distributionType ===
							'scenarioFields' &&
						(option.scenarioFieldsCondition.operator ||
							option.scenarioFieldsCondition.value)
					) {
						attrsUpdate[`${selector} .desc__text`].y = -8;
						attrsUpdate[`${selector} .desc__value`] = {
							text:
								`${
									option.scenarioFieldsCondition.operator ||
									''
								} ${option.scenarioFieldsCondition.value}` ||
								'',
							dynamic: true,
							y: 8,
							refY: optionHeight / 2
						};
					}
				}

				offsetY += optionHeight + padding;
			});

			if (this.blockLayout.subheader) {
				attrsUpdate['.subheader-option-name'] = {
					text: this.blockLayout.subheader
				};
			}

			if (this.blockLayout.footer) {
				attrsUpdate['.footer-option-name'] = {
					text: this.blockLayout.footer
				};
			}

			this.attr(attrsUpdate);

			this.autoresize();

			if ('isValid' in options && !options.isValid) return;
			if (this.blockLayout.options.length) {
				this.set('invalid', false);
			}
		},

		getBlockLayout() {
			const options = this.get('options');
			const subType = this.get('subType');

			switch (subType) {
				case 'mailingGroup':
					return {
						subheader: getMailingGroupSubheader(
							options.subscriptionType
						),
						options: options.mailingGroup.params
					};
				case 'apiStart':
				case 'scheduleStart':
				case 'customEventStart':
				case 'finish':
					return {
						options: options[subType].params
					};
				case 'messageStart':
					return {
						subheader: getMessageStartSubheader(
							options.messageStartActionType
						),
						options: options.messageStart.params
					};
				case 'extraFieldStart':
					return {
						subheader: getExtraFieldSubheader(
							options.dataChangeType
						),
						options: options.extraFieldStart.params
					};
				case 'pause':
					return {
						subheader: getPauseSubheader(options.pauseType),
						options: options.pause.params
					};
				case 'distribution':
					if (options[subType].distributionType === 'bySegments')
						return {
							subheader: getDistributionSubheader('bySegments'),
							options: options.distribution.params,
							footer: options.distribution.params.length
								? 'scenario_block_destribution.segment_option'
								: ''
						};
					if (options[subType].distributionType === 'scenarioFields')
						return {
							subheader:
								getDistributionSubheader('scenarioFields'),
							options: options.distribution.params,
							footer: options.distribution.params.length
								? 'scenario_block_destribution.fields_footer'
								: ''
						};
					if (options[subType].distributionType === 'equally')
						return {
							subheader: getDistributionSubheader('equally'),
							options: options.distribution.params
						};
					if (options[subType].distributionType === 'proportionally')
						return {
							subheader:
								getDistributionSubheader('proportionally'),
							options: options.distribution.params
						};
					return {
						options: options.distribution.params
					};
				case 'sendMessage':
					return {
						subheader: getChannelSubheader(
							options.sendMessage.params[0]?.type ?? ''
						),
						options: options.sendMessage.params
					};
				case 'dataChangeMain':
					return {
						subheader: getExtraFieldSubheader(options.dataType),
						options: options.dataChangeMain.params
					};
				case 'apiQuery':
					return {
						subheader: options.apiQuery.params[0]?.method,
						options: options.apiQuery.params
					};
				default:
					return {};
			}
		},

		getChatbotBlockLayout() {
			const options = this.get('options');
			const subType = this.get('subType');
			const key = subType as keyof typeof chatbotSubheader;

			switch (subType) {
				case 'start':
					return {
						subheader: chatbotSubheader[key](
							options.triggerCondition
						),
						options: []
					};
				case 'message':
					return {
						subheader: chatbotSubheader[key](options.text),
						options: options.inlineReplyButtons
					};
				default:
					return {};
			}
		},

		updatePort(option: any) {
			if (!option.port) {
				// eslint-disable-next-line no-param-reassign
				option.port =
					this.getPorts()?.find(
						(port: PortItem) => port.group === 'out'
					)?.id || this.generatePortId();
			}
		},

		checkLinksValidity(
			service: RappidService,
			status?: StatusType
		): boolean {
			const { graph } = service;
			const emptyBlock = graph.getConnectedLinks(this).length === 0;
			const inboundLinks = graph.getConnectedLinks(this, {
				inbound: true
			});
			const outboundLinks = graph.getConnectedLinks(this, {
				outbound: true
			});
			const inPorts = (
				this as InstanceType<typeof shapes.Block>
			).getGroupPorts('in');
			const outPorts = (
				this as InstanceType<typeof shapes.Block>
			).getGroupPorts('out');

			if (
				emptyBlock ||
				(inPorts.length !== 0 && inboundLinks.length === 0) ||
				(outPorts.length !== 0 && outboundLinks.length === 0) ||
				outPorts.length !== outboundLinks.length
			) {
				if (status !== StatusTypeEnum.DRAFT) this.set('invalid', true);
				return false;
			}
			if (status !== StatusTypeEnum.DRAFT) this.set('invalid', false);
			return true;
		},

		checkBlockValidity(status?: StatusType) {
			const options = this.get('options');
			const subType = this.get('subType');

			const params = options[subType]?.params ?? [];

			const notForValidation = this.get('notForValidation');

			if (notForValidation[subType]) return true;

			if (options.isValid) return true;

			if (status !== StatusTypeEnum.DRAFT && options.isValid === false) {
				this.set('invalid', true);
				return false;
			}

			if (status !== StatusTypeEnum.DRAFT && !params.length) {
				if (subType === 'finish') return true;
				this.set('invalid', true);
			}
			return params.length && options.isValid !== false;
		}
	}
);
