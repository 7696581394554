import { TuiDay, TuiTime } from '@taiga-ui/cdk';

export type DateTimeRange = [
	{ date: TuiDay; time: TuiTime },
	{ date: TuiDay; time: TuiTime }
];

export type DateRangeName = { dateValue: number; name: string };

export const yearMaxFromCurrent = 8;
export const yearMin = 1970;

export const LENGTH_DATE_RANGE = 23;
export const LENGTH_DATE_TIME_RANGE = 37;
export const LENGTH_DATE_TIME = 17;
export const LENGTH_TIME = 5;

export const itemsLast = [
	{ dateValue: 0, name: 'date-range-calendar.today' },
	{ dateValue: 7, name: 'date-range-calendar.last_days' },
	{ dateValue: 0, name: 'date-range-calendar.last_week' },
	{ dateValue: 30, name: 'date-range-calendar.last_days' },
	{ dateValue: 0, name: 'date-range-calendar.last_month' },
	{ dateValue: 12, name: 'date-range-calendar.last_months' },
	{ dateValue: 0, name: 'date-range-calendar.last_year' }
];

export const itemsFuture = [
	{ dateValue: 0, name: 'date-range-calendar.today' },
	{ dateValue: 7, name: 'date-range-calendar.future_days' },
	{ dateValue: 0, name: 'date-range-calendar.future_week' },
	{ dateValue: 30, name: 'date-range-calendar.future_days' },
	{ dateValue: 0, name: 'date-range-calendar.future_month' },
	{ dateValue: 12, name: 'date-range-calendar.future_months' },
	{ dateValue: 0, name: 'date-range-calendar.future_year' }
];
