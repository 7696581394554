import { ShapeTypesEnum } from '../components/scenario-canva/rappid/config/enum.shapes';
import { Block, Link } from '../models';

export function splitCells(cells: (Link | Block)[]) {
	const splittedCells: { links: Link[]; blocks: Block[] } = {
		links: [],
		blocks: []
	};

	cells.forEach((cell: Link | Block) => {
		if (cell.type === ShapeTypesEnum.LINK)
			splittedCells.links.push(cell as Link);
		else splittedCells.blocks.push(cell as Block);
	});

	return splittedCells;
}
