import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { LocalStorageConditionForm } from '@enKod/segments/segments-form.model';

import { AbstractCondition } from '../../abstract-condition.component';

@Component({
	selector: 'en-local-storage-condition',
	templateUrl: './local-storage-condition.component.html',
	styleUrls: [
		'../../abstract-condition.component.scss',
		'./local-storage-condition.component.scss'
	]
})
export class LocalStorageConditionComponent
	extends AbstractCondition
	implements OnInit
{
	readonly localStorageOptions: SelectItem[] = [
		{
			label: 'segment_form.options_operator_equal',
			value: 'equal'
		},
		{
			label: 'segment_options.not_equal_options',
			value: 'not_equal'
		},
		{
			label: 'segment_form.options_operator_empty',
			value: 'empty'
		},
		{
			label: 'segment_options.not_empty_options',
			value: 'not_empty'
		},
		{
			label: 'segment_form.option_time_more',
			value: 'more'
		},
		{
			label: 'segment_form.options_operator_less',
			value: 'less'
		},
		{
			label: 'segment_form.cond_url_starts',
			value: 'startWith'
		},
		{
			label: 'segment_options.not_prefix_options',
			value: 'not_startWith'
		},
		{
			label: 'segment_options.postfix_options',
			value: 'endWith'
		},
		{
			label: 'segment_form.cond_url_contain',
			value: 'contain'
		},
		{
			label: 'segment_options.not_contain_options',
			value: 'not_contain'
		}
	];

	get typedForm() {
		return this.form as FormGroup<LocalStorageConditionForm>;
	}

	get operatorControl() {
		return this.typedForm.controls.operator;
	}

	get valueControl() {
		return this.typedForm.controls.value;
	}

	get operatorWithValue() {
		return !this.operatorControl.value.includes('empty');
	}

	ngOnInit(): void {
		this.setValueValidation();
	}

	onChangeOperator(value: string) {
		this.setValueValidation(value);

		this.valueControl.reset('');
	}

	private setValueValidation(operator = this.operatorControl.value) {
		operator.includes('empty')
			? this.valueControl.removeValidators(Validators.required)
			: this.valueControl.addValidators(Validators.required);
	}
}
