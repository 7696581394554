export enum ShapeTypesEnum {
	BASE = 'base',
	BLOCK = 'block',
	LINK = 'enKodLink'
}

export enum ScenarioShapesEnum {
	START_BLOCK = 'startBlock',
	MAIN_BLOCK = 'mainBlock',
	SEND_MESSAGE_BLOCK = 'sendMessageBlock',
	API_QUERY_BLOCK = 'apiQueryBlock',
	MESSAGE_START_BLOCK = 'messageStartBlock',
	EXTRA_FIELD_START_BLOCK = 'extraFieldStartBlock',
	DATA_CHANGE_MAIN_BLOCK = 'dataChangeMainBlock',
	SEVERAL_PORTS_BLOCK = 'severalPortsBlock',
	DISTRIBUTION_BLOCK = 'distributionBlock',
	END_BLOCK = 'endBlock'
}

export enum ChatbotShapesEnum {
	START = 'start',
	MESSAGE = 'message'
}
