import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { ExtraFieldsService } from '@state-enKod/extra-fields';
import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';

import { InspectorItemContext } from '../../inspector-item-plugin';
import { AbstractInspectorStart } from '../../abstract/abstract-inspector-start';
import { DataChangeStartOptions } from './data-change-start.model';
import {
	CHANGE_TYPE_OPTIONS,
	DATA_TYPE_OPTIONS,
	EMAIL_EXTRAFIELD_OPTIONS,
	EXTRAFIELD_OPTIONS
} from './misc';

@UntilDestroy()
@Component({
	selector: 'en-extra-field-start',
	templateUrl: './data-change-start.component.html',
	styleUrls: ['./data-change-start.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: {
				dataTypeOptions: DATA_TYPE_OPTIONS,
				extraFieldOptions: EXTRAFIELD_OPTIONS,
				changeTypeOptions: CHANGE_TYPE_OPTIONS,
				emailExtraFieldOptions: EMAIL_EXTRAFIELD_OPTIONS
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraFieldStartComponent
	extends AbstractInspectorStart
	implements OnInit
{
	form = this.fb.group({
		dataType: null,
		extraField: null,
		changeType: null,
		specificValue: null,
		duplicateHandling: 'ignore'
	});

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get dataTypeControl() {
		return this.form.controls.dataType;
	}

	get extraFieldControl() {
		return this.form.controls.extraField;
	}

	get changeTypeControl() {
		return this.form.controls.changeType;
	}

	get specificValueControl() {
		return this.form.controls.specificValue;
	}

	get showExtraFieldSettings() {
		return this.dataTypeControl.value === 'extrafield';
	}

	get showSpecificSettings() {
		return this.changeTypeControl.value === 'specific';
	}

	get extraFieldType() {
		return this.extraFieldControl.value?.dataType;
	}

	get isEmailExtrafield() {
		return (
			this.extraFieldControl.value.label === 'email' &&
			this.extraFieldControl.value.dataType === 'email'
		);
	}

	constructor(
		private cd: ChangeDetectorRef,
		public fb: UntypedFormBuilder,
		public translate: TranslateService,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: DataChangeStartOptions,
		private extrafieldService: ExtraFieldsService,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType
	) {
		super(fb, translate);
	}

	ngOnInit() {
		this.getExtraFieldOptions()
			.pipe(
				untilDestroyed(this),
				switchMap(() => {
					return this.context$.pipe(
						untilDestroyed(this),
						tap(context => {
							if (
								!this.cell ||
								this.cell.get('subType') ===
									context.cell.get('subType')
							) {
								this.cell = context.cell;
								this.patchForm();
								this.formListener();
								this.initDuplicateHandling();
							}
						})
					);
				})
			)
			.subscribe();

		if (!this.isCreationMode) this.form.disable({ emitEvent: false });
	}

	private patchForm() {
		const options = this.cell.get('options');
		const subType = this.cell.get('subType');
		const { params } = options[subType];

		this.form.patchValue({
			dataType: options.dataChangeType,
			extraField: params[0]?.label
				? {
						dataType: params[0].dataType,
						id: params[0].id,
						label: params[0].label
				  }
				: null,
			changeType: options.fieldChangeType,
			specificValue: options.newValue,
			duplicateHandling: options.duplicateHandling ?? 'ignore'
		});

		this.cd.detectChanges();
	}

	private formListener() {
		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				map(value => ({
					duplicateHandling: value.duplicateHandling,
					dataChangeType: value.dataType,
					extraFieldStart: {
						params: value.extraField ? [value.extraField] : []
					},
					fieldChangeType: value.changeType,
					newValue: value.specificValue
						? value.specificValue.toString()
						: null
				}))
			)
			.subscribe(resp => {
				this.changeCellProp('options', resp);
			});
	}

	private getExtraFieldOptions() {
		return this.extrafieldService.getAllList().pipe(
			untilDestroyed(this),
			map(resp => {
				return resp.map(extrafield => {
					const { id, name, dataType } = extrafield;
					return {
						label: extrafield.name,
						value: {
							id,
							label: name,
							dataType
						}
					};
				});
			}),
			tap(resp => {
				this.clearPreviousOptions();
				this.selectOptions.extraFieldOptions = [
					...this.selectOptions.extraFieldOptions,
					...resp
				];
			})
		);
	}

	clearPreviousOptions(): void {
		this.selectOptions.extraFieldOptions = EXTRAFIELD_OPTIONS;
	}

	onExtraFieldChange() {
		this.changeTypeControl.patchValue('any');
		this.specificValueControl.patchValue(null);
	}

	onChangeTypeChange(changeType: string) {
		if (changeType === 'any') {
			this.specificValueControl.patchValue(null);
		}
	}
}
