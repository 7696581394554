import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'extraFieldsIconPipe'
})
export class ExtraFieldsIconPipe implements PipeTransform {
	transform(dataType: string): string {
		switch (dataType) {
			case 'bool':
				return 'en en-boolean-type';
			case 'number':
				return 'en en-number-type';
			case 'text':
			case 'string':
				return 'en en-text-type';
			case 'float':
				return 'en en-float-type';
			case 'date':
				return 'en en-date';
			case 'dateTime':
				return 'en en-datetime-type';
			case 'phone':
				return 'en en-phone-type';
			case 'email':
				return 'en en-email';
			case 'sessionId':
				return 'en en-web';
			case 'list':
				return 'en en-list-type';
			default:
				return 'unknown type';
		}
	}
}
