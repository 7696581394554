import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ContactSendingData } from '../types';

@Injectable({
	providedIn: 'root'
})
export class TestMobpushService {
	tokens$ = new BehaviorSubject<string[]>([]);

	constructor(private http: HttpClient) {}

	getTokens() {
		const params = new HttpParams({
			fromObject: { channel: 'mobpush' }
		});
		return timer(1000).pipe(
			switchMap(() => this.http.get('user/test_contacts/', { params })),
			first()
		);
	}

	postToken(payload: ContactSendingData) {
		return this.http.post('user/test_contacts/', payload);
	}

	updateTokens() {
		this.getTokens().subscribe((value: unknown) => {
			const tokens = Array.isArray(value) ? value : [];
			this.tokens$.next(tokens);
		});
	}
}
