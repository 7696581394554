import { Injectable } from '@angular/core';
import { NotificationsService } from '@enkod-core/services';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationStatus } from 'ui-lib';
import { PaginationResponse } from '@enkod-core/types';
import { ID } from '@datorama/akita';
import { FieldsDataService } from './fields-data.service';
import { FieldsModel } from '../model/fields';
import { BlockScenarioFieldsQuery } from '../_state/scn-fields-blocks.query';
import { BlockScenarioFieldsStore } from '../_state/scn-fields-blocks.store';

@Injectable({
	providedIn: 'root'
})
export class FieldsService {
	incrementId = 1;
	refreshTable$ = new BehaviorSubject(true);

	constructor(
		private notification: NotificationsService,
		private dataService: FieldsDataService,
		private blockQuery: BlockScenarioFieldsQuery,
		private blockStore: BlockScenarioFieldsStore
	) {}

	getList({ perPage, sort, page, search, filter }: any) {
		const { scenarioId } = filter;
		return this.dataService
			.list<PaginationResponse<FieldsModel>>({
				limit: perPage,
				offset: (page - 1) * perPage,
				sort,
				search,
				scenarioId: scenarioId?.toString()
			})
			.pipe(
				map(resp => ({
					perPage: perPage || 10,
					lastPage: 0,
					currentPage: page,
					total: Math.ceil(resp.total / perPage) || 1,
					data: resp.result
						? [...resp.result.map(item => this.generateId(item))]
						: []
				})),
				catchError(() => {
					this.errorNotification();
					return of({
						perPage: perPage || 10,
						lastPage: 0,
						currentPage: 1,
						total: 1,
						data: []
					});
				})
			);
	}

	getFields(scenarioId: number) {
		if (
			this.blockQuery.hasEntity(
				(entity: FieldsModel) => entity.scenarioId
			) &&
			this.blockQuery.getCount(
				(entity: FieldsModel) => entity.scenarioId === scenarioId
			)
		) {
			return this.blockQuery.fields$.pipe(
				map(fields =>
					fields.filter(field => field.scenarioId === scenarioId)
				)
			);
		}
		return this.getFieldList(scenarioId);
	}

	getFieldList(scenarioId: number) {
		return this.dataService
			.list<PaginationResponse<FieldsModel>>({ scenarioId })
			.pipe(
				map(resp => {
					return (
						resp.result?.map(field => ({
							...field,
							scenarioId
						})) || []
					);
				}),
				tap(fields => {
					this.blockStore.add(fields);
				})
			);
	}

	createField(data: FieldsModel) {
		return this.dataService.create<FieldsModel, string>(data).pipe(
			tap(guid => {
				this.blockStore.add({
					guid,
					name: data.name,
					type: data.type,
					scenarioId: data.scenarioId
				});
				this.notification
					.show('', {
						label: 'scenario_fields.create_success_notification',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			})
		);
	}

	deleteField(guid: ID) {
		return this.dataService.delete(guid).pipe(
			tap(() => {
				this.blockStore.remove(guid);
				this.notification
					.show('', {
						label: 'scenario_fields.delete_success_notification',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			}),
			catchError(e => {
				this.errorNotification();
				return throwError(e);
			})
		);
	}

	errorNotification() {
		return this.notification
			.show('toast.detail_request_error', {
				label: 'toast.summary_try_later',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}

	generateId(item: object) {
		return { ...item, id: this.incrementId++ };
	}
}
