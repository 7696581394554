import { ID } from '@datorama/akita';
import { UrlParams } from './entity-params.model';

export interface PushEntity {
	id: ID;
	messageId: ID;

	subject: string;
	body: string;
	icon: string;
	clickAction: string;
	timeToLive: number;

	urlParams: UrlParams;
}
