/* eslint-disable import/no-cycle */
import { base } from '@enSend/_shared/components/scenario-canva/rappid/shapes/app.shapes.ts/base.shapes/base.shape';
import {
	previewBlock,
	startBlock,
	mainBlock,
	sendMessageBlock,
	severalPortsBlock,
	distributionBlock,
	messageStartBlock,
	extraFieldStartBlock,
	enKodLink,
	startBlockView,
	mainBlockView,
	apiQueryBlock,
	endBlockView,
	sendMessageBlockView,
	severalPortsBlockView,
	distributionBlockView,
	messageStartBlockView,
	extraFieldStartBlockView,
	dataChangeMainBlock,
	dataChangeMainBlockView,
	apiQueryBlockView,
	endBlock
} from './app.shapes.ts';

export const shapes = {
	base,
	previewBlock,
	startBlock,
	mainBlock,
	sendMessageBlock,
	severalPortsBlock,
	distributionBlock,
	messageStartBlock,
	extraFieldStartBlock,
	dataChangeMainBlock,
	apiQueryBlock,
	endBlock,
	enKodLink,
	startBlockView,
	mainBlockView,
	sendMessageBlockView,
	severalPortsBlockView,
	distributionBlockView,
	messageStartBlockView,
	extraFieldStartBlockView,
	dataChangeMainBlockView,
	apiQueryBlockView,
	endBlockView
};
