import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { ScheduleStartComponent } from './schedule-start.component';

@Injectable()
export class ScheduleStartService implements InspectorItemPlugin {
	private readonly scheduleStartContent = new PolymorpheusComponent(
		ScheduleStartComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'scheduleStart',
				name: 'scheduleStart',
				content: this.scheduleStartContent
			}
		]);
	}
}
