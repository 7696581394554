<div #container
     [ngClass]="{'p-multiselect p-component':true,'en-multiselect-open':overlayVisible,'en-focus':focus,'p-disabled': disabled}"
     [ngStyle]="style"
     [class]="styleClass"
     [class.input-width-54]="useInputWidth54"
     [class.input-width-80]="useInputWidth80"
     [class.invalid-mark]="isInvalid"
     (click)="onMouseclick($event,in)">
    <div *ngIf="appendTo"
         #appendGrabber
         class="append-overlay-grabber"
         [ngStyle]="{top: appendGrabberOffsetHeight + 1 + 'px'}"></div>
    <div class="p-hidden-accessible">
        <input #in
               type="text"
               readonly="readonly"
               [attr.id]="inputId"
               [attr.name]="name"
               (focus)="onInputFocus($event)"
               (blur)="onInputBlur($event)"
               [disabled]="disabled"
               [attr.tabindex]="tabindex"
               (keydown)="onKeydown($event)"
               aria-haspopup="listbox"
               [attr.aria-expanded]="overlayVisible"
               [attr.aria-labelledby]="ariaLabelledBy"
               role="listbox">
    </div>
    <div #labelContainer
         class="p-multiselect-label-container"
         [pTooltip]="tooltip"
         [tooltipPosition]="tooltipPosition"
         [positionStyle]="tooltipPositionStyle"
         [tooltipStyleClass]="tooltipStyleClass"
         [class.input-width-85]="useInputWidth85"
         [class.input-width-54]="useInputWidth54">
        <div class="p-multiselect-label"
             [ngClass]="{'p-placeholder': valuesAsString === (defaultLabel || placeholder) && (!value || value.length === 0), 'p-multiselect-label-empty': ((valuesAsString == null || valuesAsString.length === 0) && (placeholder == null || placeholder.length === 0))}">
            <ng-container *ngIf="!selectedItemsTemplate">{{valuesAsString || 'empty'}}</ng-container>
            <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: {$implicit: value}"></ng-container>
        </div>
    </div>
    <div *ngIf="showClearButton"
         class="en-multiselect-clear-container">
        <button class="en-multiselect-clear en en-cancel-fill"
                (click)="clearSelected($event)"></button>
    </div>
    <div [ngClass]="{'p-multiselect-trigger':true}">
        <span class="p-multiselect-trigger-icon"
              [ngClass]="dropdownIcon"></span>
    </div>
    <div *ngIf="overlayVisible"
         [ngClass]="['p-multiselect-panel p-component']"
         [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
         (@overlayAnimation.start)="onOverlayAnimationStart($event)"
         [ngStyle]="panelStyle"
         [class]="panelStyleClass"
         (keydown)="onKeydown($event)">
        <div class="p-multiselect-header"
             *ngIf="showHeader">
            <ng-content select="p-header"></ng-content>
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            <div class="p-checkbox p-component"
                 *ngIf="showToggleAll && !selectionLimit">
                <div class="p-hidden-accessible">
                    <input type="checkbox"
                           readonly="readonly"
                           [checked]="isAllChecked()"
                           (focus)="onHeaderCheckboxFocus()"
                           (blur)="onHeaderCheckboxBlur()"
                           (keydown.space)="toggleAll($event)">
                </div>
                <div class="p-checkbox-box"
                     role="checkbox"
                     [attr.aria-checked]="isAllChecked()"
                     [ngClass]="{'p-highlight':isAllChecked(), 'p-focus': headerCheckboxFocus}"
                     (click)="toggleAll($event)">
                    <span class="p-checkbox-icon"
                          [ngClass]="{'en en-done':isAllChecked()}"></span>
                </div>
            </div>
            <div class="p-multiselect-filter-container en-input-search en-input-search-dialog en-input-icon-left h32"
                 *ngIf="filter">
                <i class="en en-search"></i>
                <input #filterInput
                       type="text"
                       role="textbox"
                       [value]="filterValue||''"
                       (input)="onFilter()"
                       class="p-multiselect-filter en-input p-component"
                       [attr.placeholder]="filterPlaceHolder || ('common.search' | translate)"
                       [attr.aria-label]="ariaFilterLabel">
                <!-- <span class="p-multiselect-filter-icon pi pi-search"></span> -->
            </div>
            <button *ngIf="showCloseButton"
                    type="button"
                    enButton
                    class="p-multiselect-close en-button-lowercase h32"
                    icon="en en-cancel"
                    (click)="close($event)"></button>
            <!-- <button class="p-multiselect-close p-link"
                    type="button"
                    (click)="close($event)"
                    pRipple>
                <span class="p-multiselect-close-icon pi pi-times"></span>
            </button> -->
        </div>

        <tui-scrollbar>
            <div class="p-multiselect-items-wrapper"
                 [style.max-height]="virtualScroll ? 'auto' : (scrollHeight||'auto')">
                <ul class="p-multiselect-items p-component"
                    role="listbox"
                    aria-multiselectable="true">
                    <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                        <div *ngIf="useSelectAllItem"
                             class="p-multiselect-item"
                             (click)="toggleAll($event);">
                            <div class="
                             p-checkbox
                             p-component">
                                <div class="p-checkbox-box"
                                     [ngClass]="{ 'p-highlight': isAllChecked() }">
                                    <span class="p-checkbox-icon"
                                          [ngClass]="{ 'en en-done': isAllChecked() }"></span>
                                </div>
                            </div>
                            <span>{{ 'common.select_all' | translate }}</span>
                        </div>
                        <div (click)="setEmptyValue($event)">
                            <ng-container *ngTemplateOutlet="selectedEmptyTemplate"></ng-container>
                        </div>
                        <ng-template ngFor
                                     let-option
                                     let-i="index"
                                     [ngForOf]="visibleOptions">
                            <p-multiSelectItem [option]="option"
                                               [selected]="isSelected(option.value)"
                                               [useSelectAllItem]="useSelectAllItem"
                                               (onClick)="onOptionClick($event)"
                                               (onKeydown)="onOptionKeydown($event)"
                                               [maxSelectionLimitReached]="maxSelectionLimitReached"
                                               [template]="itemTemplate"
                                               [disabled]="option.disabledItem && !isSelected(option.value)"
                                               [selectable]="selectableItems"></p-multiSelectItem>
                        </ng-template>
                    </ng-container>
                    <ng-template #virtualScrollList>
                        <cdk-virtual-scroll-viewport tuiScrollable
                                                     #viewport
                                                     [ngStyle]="{'height': scrollHeight}"
                                                     [itemSize]="itemSize"
                                                     class="en-zero-scrollbar"
                                                     *ngIf="virtualScroll && visibleOptions && visibleOptions.length"
                                                     (scrolledIndexChange)="virtualScrollLoad()">
                            <ng-container
                                          *cdkVirtualFor="let option of visibleOptions; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                                <p-multiSelectItem [option]="option"
                                                   [selected]="isSelected(option.value)"
                                                   (onClick)="onOptionClick($event)"
                                                   (onKeydown)="onOptionKeydown($event)"
                                                   [maxSelectionLimitReached]="maxSelectionLimitReached"
                                                   [template]="itemTemplate"
                                                   [itemSize]="itemSize"
                                                   [selectable]="selectableItems"></p-multiSelectItem>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                    </ng-template>
                    <li *ngIf="filter && isEmptyOptions"
                        class="p-multiselect-empty-message">
                        {{ emptyFilterMessage || ('common.empty_options' |translate) }}
                    </li>
                </ul>
            </div>
        </tui-scrollbar>
        <div class="p-multiselect-footer"
             *ngIf="footerFacet || footerTemplate">
            <ng-content select="p-footer"></ng-content>
            <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
        </div>
    </div>
</div>