import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import { ExtraFieldsService, ExtraField } from '@state-enKod/extra-fields';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import {
	BirthdayDateForm,
	BirthdayOption,
	MonthExtrafield,
	MonthOperator
} from '@enKod/segments/segments-form.model';
import { SelectItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AbstractCondition } from '../../../abstract-condition.component';
import {
	BirthdayDateOptions,
	MonthLabel,
	MonthOperatorOptions,
	MonthOptions,
	UnitOptions
} from './birthday-date.options';

@UntilDestroy()
@Component({
	selector: 'en-birthday-date',
	templateUrl: './birthday-date.component.html',
	styleUrls: [
		'../../../abstract-condition.component.scss',
		'./birthday-date.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
// todo: Это надо рефакторить, код галимый
export class BirthdayDateComponent extends AbstractCondition implements OnInit {
	dateFields$: Observable<SelectItem[]>;
	dateFields: ExtraField[];
	birthdayDateOptions: SelectItem[] = BirthdayDateOptions;
	monthOperatorOptions: SelectItem[] = MonthOperatorOptions;
	unitOptions: SelectItem[] = UnitOptions;
	monthOptions: SelectItem[] = MonthOptions;
	monthLabel = MonthLabel;

	markAsInvalid = false;

	constructor(
		private extraFieldsService: ExtraFieldsService,
		public validateServices: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(validateServices, cd);
	}

	get typedForm(): FormGroup<BirthdayDateForm> {
		return this.form as FormGroup<BirthdayDateForm>;
	}

	get fieldIdControl(): FormControl<number> {
		return this.typedForm.controls.field_id as FormControl<number>;
	}

	get operatorControl(): FormControl<BirthdayOption> {
		return this.typedForm.controls.operator as FormControl<BirthdayOption>;
	}

	get valueControl(): FormControl<number | number[]> {
		return this.typedForm.controls.value as FormControl<number | number[]>;
	}

	get monthOperator(): FormControl<MonthOperator> {
		return this.typedForm.controls
			.month_operator as FormControl<MonthOperator>;
	}

	get unitLabel(): string {
		return this.typedForm.controls.units.value;
	}

	get showMonthOperator(): boolean {
		return this.operatorControl.value === 'in_month';
	}

	get showHappenOperator(): boolean {
		return (
			this.operatorControl.value === 'happen_equal' ||
			this.operatorControl.value === 'happened_equal'
		);
	}

	get showHappenedOperator(): boolean {
		return this.operatorControl.value === 'happened_equal';
	}

	get showMonthsMultiselect(): boolean {
		return (
			this.monthOperator.value === 'in_one_of_month' &&
			this.operatorControl.value === 'in_month'
		);
	}

	get showDaysOperator(): boolean {
		return this.operatorControl.value === 'between_days';
	}

	get void_sex() {
		return this.showDaysOperator || this.showMonthOperator;
	}

	ngOnInit(): void {
		this.getDateFields();
		this.operatorListener();
		this.monthOperatorListener();
		this.setValidationListener();
		this.startDaysListener();
		this.endDaysListener();
	}

	private operatorListener() {
		this.operatorControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(resp => {
				this.typedForm.controls.month_operator.disable();
				this.typedForm.controls.start.disable();
				this.typedForm.controls.end.disable();

				switch (resp) {
					case 'today':
						this.typedForm.controls.units.disable();
						this.typedForm.controls.value.disable();
						break;

					case 'happen_equal':
					case 'happened_equal':
						this.typedForm.controls.units.enable();
						this.typedForm.controls.value.enable();
						this.typedForm.controls.value.patchValue(1);
						break;

					case 'in_month':
						this.typedForm.controls.month_operator.enable();
						break;

					case 'between_days':
						this.typedForm.controls.units.disable();
						this.typedForm.controls.value.disable();

						this.typedForm.controls.start.enable();
						this.typedForm.controls.end.enable();
						break;
					default:
						break;
				}
			});
	}

	private monthOperatorListener(): void {
		this.monthOperator.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(resp => {
				this.typedForm.controls.units.disable();

				if (resp === 'in_one_of_month') {
					this.typedForm.controls.value.enable();
					this.valueControl.patchValue([]);
				}

				if (resp === 'this_month') {
					this.typedForm.controls.value.disable();
					this.valueControl.patchValue(1);
				}
			});
	}

	private setValidationListener() {
		this.validateServices.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.form.markAsPristine();
						this.markAsInvalid = false;
					} else {
						this.markAsInvalid = Array.isArray(
							this.typedForm.controls.value.value
						)
							? !this.typedForm.controls.value.value.length
							: !this.typedForm.controls.value.value;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe(() => this.cd.markForCheck());

		this.validateServices.resetInvalidMark$
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.markAsInvalid = false;
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();

		this.typedForm.controls.value.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (!Array.isArray(value)) return;
					if (value.length) this.markAsInvalid = false;
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private startDaysListener() {
		this.typedForm.controls.start.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(resp => {
				if (!resp) return;

				if (resp > 31) {
					this.typedForm.controls.start.patchValue(31, {
						emitEvent: false
					});
					this.typedForm.controls.end.patchValue(31, {
						emitEvent: false
					});
					return;
				}

				let count = resp;
				count =
					count > this.typedForm.controls.end.value || count === 31
						? count
						: ++count;

				if (count > this.typedForm.controls.end.value) {
					this.typedForm.controls.end.patchValue(count, {
						emitEvent: false
					});
				}
			});
	}

	private endDaysListener() {
		this.typedForm.controls.end.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(resp => {
				if (!resp) return;

				if (resp > 31) {
					this.typedForm.controls.end.patchValue(31, {
						emitEvent: false
					});
					return;
				}

				let count = resp;
				count =
					count < this.typedForm.controls.start.value || count === 1
						? count
						: ++count;

				if (count < this.typedForm.controls.start.value) {
					this.typedForm.controls.start.patchValue(count, {
						emitEvent: false
					});
				}
			});
	}

	onChangeField(option: { value: number; id: number }) {
		const type = this.dateFields.find(item => item.id === option.value)
			?.dataType as MonthExtrafield;
		this.typedForm.controls.extraFieldType.patchValue(type);
	}

	getDateFields() {
		this.dateFields$ = this.extraFieldsService.getAllList().pipe(
			tap(resp => {
				this.dateFields = resp;
			}),
			map(resp => {
				return resp
					.filter(
						field =>
							field.dataType === 'date' ||
							field.dataType === 'dateTime'
					)
					.map(field => ({
						label: field.name,
						value: field.id,
						icon: field.dataType
					}));
			})
		);
	}

	deselectMonth(index: number): void {
		if (Array.isArray(this.valueControl.value)) {
			const arrCopy = [...this.valueControl.value];
			arrCopy.splice(index, 1);
			this.valueControl.patchValue(arrCopy);
		}
	}

	deselectAllMonths(): void {
		this.valueControl.patchValue([]);
	}
}
