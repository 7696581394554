/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { PortItem, Option } from '@enSend/_shared/models';
import { Button } from '@enSend/_shared/components/scenario-canva/inspector/chatbot/message/message.model';
import { RappidService } from '@enSend/_shared/components/scenario-canva/services';
import { block, blockView } from '../../base.shapes';
import { ChatbotShapesEnum } from '../../../../config/enum.shapes';

export const message = block.define(
	ChatbotShapesEnum.MESSAGE,
	{
		questionHeight: 60,
		subheaderHeight: 92,
		optionsTop: 6,
		options: {
			inlineReplyButtons: [],
			isValid: false
		},
		size: {
			width: 260
		},
		ports: {
			items: [
				{
					group: 'in'
				}
			]
		},
		attrs: {
			headerRect: {
				fill: '#234EC4',
				stroke: '#153EB5'
			},
			descContainer: {
				refDy: 12
			},
			subheaderOption: {
				refY: 56
			},
			'.subheader-option-name__wrapper': {
				refX: 12,
				fill: '#F3F4F5',
				height: 92,
				width: 'calc(w - 24)'
			},
			'.subheader-option-name': {
				textAnchor: 'left',
				textVerticalAnchor: 'top',
				refX: 20,
				refY: 8,
				fontSize: 12,
				fill: '#373B43',
				lineHeight: 16,
				textWrap: {
					width: 220,
					maxLineCount: 5
				}
			},
			'.desc__rect': {
				refX: 12,
				width: 'calc(w - 24)',
				fill: '#FFFFFF',
				stroke: '#E4E6EB'
			},
			'.desc__text': {
				textAnchor: 'left',
				refX: 42,
				fontSize: 12
			},
			'.link_icon': {
				visibility: 'hidden',
				x: 20,
				y: 10
			}
		}
	},
	{
		optionMarkup:
			'<g class="desc__block"><rect class="desc__rect"></rect><image xlink:href="assets/scenario/link.svg#exclamation" class="link_icon"></image><text class="desc__text"></text><text class="desc__value"></text></g>',

		initialize(...args: any) {
			block.prototype.initialize.apply(this, args);
			this.onChangeOptions();
		},

		onChangeOptions(args: any) {
			const options = this.get('options');

			let offsetY = 0;
			const optionHeight = this.get('optionHeight');
			const padding = this.get('padding');
			const questionHeight = this.get('questionHeight');
			const subheaderHeight = this.get('subheaderHeight');
			const optionsTop = this.get('optionsTop');

			const { inlineReplyButtons } = options;

			if (inlineReplyButtons.length) {
				inlineReplyButtons.forEach((option: Option) => {
					this.updatePort(option, {
						offsetY,
						optionHeight,
						padding,
						questionHeight,
						subheaderHeight,
						optionsTop
					});

					offsetY += optionHeight + padding;
				});
			}

			this.removeUnusablePorts(inlineReplyButtons);

			if (
				!this.getGroupPorts('out').length &&
				inlineReplyButtons[0]?.type !== 'restart'
			)
				this.createInstantPort();

			block.prototype.onChangeOptions.call(this, {
				updatePorts: true,
				...args
			});
		},

		updatePort(
			option: Button,
			position: {
				offsetY: number;
				optionHeight: number;
				padding: number;
				questionHeight: number;
				subheaderHeight: number;
				optionsTop: number;
			}
		) {
			// eslint-disable-next-line no-param-reassign
			if (!option.guid) option.guid = this.generatePortId();

			if (!position || option.type === 'restart') return;
			// eslint-disable-next-line no-param-reassign
			if (!option.port) option.port = this.generatePortId();

			const portY =
				position.offsetY +
				position.optionHeight / 2 +
				position.questionHeight +
				position.subheaderHeight +
				position.optionsTop +
				1.5;

			if (!this.getPort(`${option.port}`)) {
				this.addPort({
					group: 'out',
					id: `${option.port}`,
					args: {
						y: portY
					}
				});
			} else {
				this.portProp(`${option.port}`, 'args/y', portY);
			}
		},

		removeUnusablePorts(params: any) {
			const portsItems = this.getGroupPorts('out');
			if (!portsItems) return;

			const removedPorts = portsItems.filter(
				(port: PortItem) =>
					!params.find((option: Option) => option.port === port.id)
			);

			if (
				!removedPorts[0]?.args?.y &&
				portsItems?.length === 1 &&
				params[0]?.type !== 'restart'
			)
				return;

			this.removePorts(removedPorts);
		},

		createInstantPort() {
			const portId = this.generatePortId();
			this.addPort({
				group: 'out',
				id: `${portId}`
			});
		},

		checkLinksValidity(service: RappidService) {
			const { graph } = service;
			const inboundLinks = graph.getConnectedLinks(this, {
				inbound: true
			});

			const options = this.get('options');
			const params: Button[] = options.inlineReplyButtons;

			if (!params.length && inboundLinks.length) {
				this.set('invalid', false);
				return true;
			}

			if (!params.length && !inboundLinks.length) {
				this.set('invalid', true);
				return false;
			}

			const commonButtons = params.filter(
				param => param.type === 'general'
			);

			let commonButtonsIsNotConnected = false;

			commonButtons.forEach(button => {
				const outboundLinks = graph.getConnectedLinks(this, {
					outbound: true
				});

				const connectedToButton = outboundLinks.filter(link => {
					return link.source().port === button.port;
				});

				if (!connectedToButton.length) {
					commonButtonsIsNotConnected = true;
				}
			});

			if (commonButtonsIsNotConnected) {
				this.set('invalid', true);
				return false;
			}

			this.set('invalid', false);
			return true;
		},

		checkBlockValidity() {
			const options = this.get('options');

			if (!this.attributes.invalid) this.set('invalid', !options.isValid);

			return options.isValid !== false;
		}
	}
);

export const messageView: dia.ElementView = Object.assign(blockView);
