import { SelectItem } from 'primeng/api';
import { SendingTypes } from 'app/modules/enKod/segments/_state/segment.model';

export const SENDING_TYPE_OPTIONS: SelectItem<SendingTypes>[] = [
	{
		label: 'sending_types.sendnow',
		value: 'sendnow'
	},
	{
		label: 'sending_types.sendlate',
		value: 'sendlate'
	},
	{
		label: 'sending_types.regular',
		value: 'regular'
	},
	{
		label: 'sending_types.api',
		value: 'api'
	},
	{
		label: 'sending_types.event',
		value: 'event'
	},
	{
		label: 'sending_types.doi',
		value: 'doi'
	}
];
