import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SidebarModule } from 'primeng/sidebar';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { TuiDragModule, TuiLetModule } from '@taiga-ui/cdk';

import { SharedModule } from '@shared';
import { TuiScrollbarModule } from '@enkod-core/components';
import { EnButtonModule, HtmlPreviewDirectiveModule } from 'ui-lib';
import { DynamicContentModule } from '@enSend/_shared/components/dynamic-content/dynamic-content.module';
import { JsonPluginDialogComponent } from './json-plugin-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		TuiDragModule,
		TuiScrollbarModule,
		TuiLetModule,
		SidebarModule,
		CodemirrorModule,
		EnButtonModule,
		DynamicContentModule,
		HtmlPreviewDirectiveModule
	],
	declarations: [JsonPluginDialogComponent],
	exports: [JsonPluginDialogComponent]
})
export class JsonPluginDialogModule {}
