<div class="form">
    <div class="form__row">
        <label class="form__label">
            <p class="form__title en-text-medium font-weight-500">
                {{ 'message_wizard_edit_step.recipients' | translate }}
            </p>
            <p class="form__text">
                {{ 'message_wizard_edit_step.enter_mobile_token' | translate }}
            </p>
        </label>
        <en-autoComplete class="form__autocomplete"
                         appendTo="body"
                         [multiple]="false"
                         [dropdown]="true"
                         [formControl]="tokenValue"
                         [suggestions]="options"
                         (completeMethod)="getOptiion($event)"
                         placeholder="dyijA5Sz7iJGPR3YTDGR9n:APA91bE5Jw8jsT07o5d1Xkhq0qes9p..."></en-autoComplete>
    </div>
    <div class="form__row">
        <label class="form__label">
            <p class="form__title en-text-medium font-weight-500">
                {{  'message_wizard_edit_step.os' | translate }}
            </p>
        </label>
        <en-dropdown
                    class="form__select h36"
                    styleClass="en-dropdown-solid"
                    [options]="systems"
                    [formControl]="selectedSystem"></en-dropdown>
    </div>
</div>
