import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ConvertDateModule } from '@enkod-core/pipes';
import { SharedModule } from '@shared';

import {
	EnTableModule,
	PaginatorModule,
	EnButtonModule,
	RelatedDataDialogModule
} from 'ui-lib';

import { MailingGroupsTableComponent } from './mailing-groups-table.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnTableModule,
		PaginatorModule,
		EnButtonModule,
		ConvertDateModule,
		RelatedDataDialogModule
	],
	declarations: [MailingGroupsTableComponent],
	exports: [MailingGroupsTableComponent]
})
export class MailingGroupsTableModule {}
