/* eslint-disable max-classes-per-file */
import { Pipe, PipeTransform, isDevMode, NgModule } from '@angular/core';

import { FULL_URL_REGEX } from 'custom-validators';

@Pipe({
	name: 'shortLink'
})
export class ShortLinkPipe implements PipeTransform {
	transform(value: string, isShort: boolean): string {
		if (isShort) {
			let linkReplace = value;
			const urls: string[] = value.match(FULL_URL_REGEX) || [];
			urls.forEach(url => {
				linkReplace = linkReplace.replace(url, this.generateShortUrl());
			});
			return linkReplace;
		}
		return value;
	}

	private generateShortUrl(): string {
		const random = Math.random().toString(36).substring(2, 9);
		if (isDevMode()) {
			return `https://d.enio.in/${random}`;
		}
		return `https://enio.in/${random}`;
	}
}

@NgModule({
	declarations: [ShortLinkPipe],
	exports: [ShortLinkPipe]
})
export class ShortLinkPipeModule {}
