import { Injectable } from '@angular/core';
import { CRUDService } from '@enkod-core/abstract';
import { NotificationsService } from '@enkod-core/services';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationStatus } from 'ui-lib';

import { PeopleCountItem } from './people-count.model';

@Injectable({
	providedIn: 'root'
})
export class PeopleCountListService extends CRUDService<PeopleCountItem> {
	incrementId = 1;
	constructor(private notificationsService: NotificationsService) {
		super('ensend/scenario/getPersons/');
	}

	private blockGUID: number;
	private scenarioId: number;

	getList({ page, sort, perPage, search, filter }: any) {
		return this.list<{ total: number; persons: PeopleCountItem[] }>({
			limit: perPage,
			offset: (page - 1) * perPage,
			sort,
			search,
			blockGUID: this.blockGUID,
			scenarioId: this.scenarioId,
			...filter
		}).pipe(
			map(resp => ({
				perPage: perPage || 10,
				lastPage: 0,
				currentPage: page,
				total: Math.ceil(resp.total / perPage) || 1,
				data: resp.persons
					? [...resp.persons.map(item => this.generateId(item))]
					: []
			})),
			catchError(() => {
				this.notificationsService
					.show('toast.detail_error_receiving_data', {
						label: 'toast.summary_try_later',
						status: NotificationStatus.ERROR
					})
					.subscribe();
				return of({
					perPage: perPage || 10,
					lastPage: 0,
					currentPage: 1,
					total: 0,
					data: []
				});
			})
		);
	}

	setListProperties(scenarioId: number, blockGUID: number) {
		this.scenarioId = scenarioId;
		this.blockGUID = blockGUID;
	}

	generateId(item: PeopleCountItem) {
		return { ...item, contactId: item.id, id: this.incrementId++ };
	}
}
