import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import IMask from 'imask';

import { LENGTH_DATE_TIME_RANGE } from '../calendar-range.model';
import { tuiCreateAutoCorrectedTimeRangePipe } from './calendar-time.pipe';

export function createAutoCorrectedDateTimeRangePipe(
	value: string,
	masked: IMask.Masked<string>
) {
	const timePipe = tuiCreateAutoCorrectedTimeRangePipe();

	if (value.length < LENGTH_DATE_TIME_RANGE) return;

	const [date, time] = value.substring(0, 17).split(', ');
	const [dateTo, timeTo] = value.substring(20).split(', ');

	const pipedTime = timePipe(time, {} as any);
	if (!pipedTime || typeof pipedTime === 'string') return;
	const pipedTimeTo = timePipe(timeTo, {} as any);
	if (!pipedTimeTo || typeof pipedTimeTo === 'string') return;

	const dataTui = TuiDay.normalizeParse(date);
	const dataToTui = TuiDay.normalizeParse(dateTo);

	if (dataToTui < dataTui) {
		if (pipedTime.value >= pipedTimeTo.value) {
			const newTimeTo = TuiTime.fromString(pipedTime.value)
				.shift({ minutes: 1 })
				.toString();
			pipedTimeTo.value = newTimeTo;
		}
		// eslint-disable-next-line no-param-reassign
		masked.value = `${date}, ${time} - ${date}, ${pipedTimeTo.value}`;
	}

	if (dataToTui.daySame(dataTui) && pipedTime.value >= pipedTimeTo.value) {
		const newTimeTo = TuiTime.fromString(pipedTime.value)
			.shift({ minutes: 1 })
			.toString();
		pipedTimeTo.value = newTimeTo;
		// eslint-disable-next-line no-param-reassign
		masked.value = `${date}, ${time} - ${date}, ${pipedTimeTo.value}`;
	}
}
