import { SelectItem } from 'primeng/api';

export const OPTIONS_PARAMETER: SelectItem[] = [
	{
		label: 'segment_form.cond_purchase_quantity',
		value: 'quantity'
	},
	{
		label: 'segment_form.cond_purchase_sum',
		value: 'sum'
	},
	{
		label: 'segment_options.with_product_id',
		value: 'id'
	},
	{
		label: 'segment_options.with_category_id',
		value: 'categoryId'
	},
	{
		label: 'segment_form.cond_product_stock',
		value: 'atLeastOne'
	}
];
export const OPTIONS_PERIOD: SelectItem[] = [
	{
		label: 'segment_form.cond_purchase_p_all',
		value: 'all'
	},
	{
		label: 'segment_options.between_date',
		value: 'between'
	},
	{
		label: 'segment_options.equal_options',
		value: 'equal'
	},
	{
		label: 'segment_form.options_operator_less',
		value: 'less'
	},
	{
		label: 'segment_form.options_operator_more',
		value: 'more'
	}
];

export const OPTIONS_AGGREGATION: SelectItem[] = [
	{
		label: 'segment_options.average_options',
		value: 'average'
	},
	{
		label: 'segment_form.cond_purchase_total',
		value: 'total'
	},
	{
		label: 'segment_form.cond_purchase_max',
		value: 'max'
	},
	{
		label: 'segment_form.cond_purchase_min',
		value: 'min'
	}
];
