<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsCategoryData"
                 [formControl]="category"
                 (onChange)="onChangeCategory($event)"></en-dropdown>

    <en-dropdown class="condition__field input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsField$ | async"
                 [filter]="true"
                 [selectWithId]="true"
                 filterBy="label,value.name,id"
                 [autoDisplayFirst]="false"
                 formControlName="field"
                 (onChange)="onChangeField($event)">
        <ng-template let-selectedOption
                     pTemplate="selectedItem">
            <ng-container *ngIf="selectedOption">
                <span>{{ selectedOption.id ? selectedOption.label : selectedOption.label | translate }}</span>
            </ng-container>
        </ng-template>

        <ng-template let-field
                     pTemplate="item">
            <div style="display: flex; justify-content: space-between; align-items: center">
                <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                    <span class="id-badge"
                          style="margin-right: 0.5rem;"
                          *ngIf="fieldsPull[field.value].isExtraField && field.id">
                        id {{field.id}}

                    </span>
                    {{fieldsPull[field.value].isExtraField && field.id ? field.label : field.label | translate}}
                </div>
                <span *ngIf="field.value === 'email' && !field.id"
                      class="en en-email"></span>
                <span *ngIf="field.value === 'phone' && !field.id"
                      class="en en-phone-type"></span>
                <span *ngIf="fieldsPull[field.value].isExtraField && field.id"
                      [ngClass]="fieldsPull[field.value]?.extraFieldType | extraFieldsIconPipe"></span>
            </div>
        </ng-template>
    </en-dropdown>
    <ng-container *ngIf="isEmailOrPhone">
        <ng-container *ngIf="fieldControl.value === 'email'">
            <en-dropdown class="condition__operator input__small"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="optionsOperatorEmail"
                         formControlName="operator"></en-dropdown>
            <ng-container *ngIf="showValueField">
                <input class="h32 text-overflow"
                       [class.active-hide]="showHide"
                       type="text"
                       enInput
                       enDynamicWidth="15rem"
                       formControlName="value" />
            </ng-container>
        </ng-container>
        <ng-container *ngIf="fieldControl.value === 'phone'">
            <en-dropdown class="condition__operator input__small"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="optionsOperatorPhone"
                         formControlName="operator"></en-dropdown>
            <ng-container *ngIf="showValueField">
                <input class="h32 text-overflow"
                       [class.active-hide]="showHide"
                       type="text"
                       enInput
                       enDynamicWidth="15rem"
                       formControlName="value" />
            </ng-container>
        </ng-container>

    </ng-container>
    <ng-container>
        <ng-container *ngIf="fieldTypeValue === 'text'">
            <en-dropdown class="condition__operator input__small"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="optionsOperatorTypeText"
                         formControlName="operator"></en-dropdown>
            <ng-container *ngIf="showValueField">
                <input class="h32 text-overflow"
                       [class.active-hide]="showHide"
                       type="text"
                       enInput
                       enDynamicWidth="15rem"
                       [maxlength]="1024"
                       formControlName="value" />
            </ng-container>
        </ng-container>
        <ng-container *ngIf="fieldTypeValue === 'number'">
            <en-dropdown class="condition__operator input__small"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="optionsOperatorTypeNumber"
                         formControlName="operator"></en-dropdown>
            <ng-container *ngIf="showValueField">
                <input [imask]="maskNumber"
                       [unmask]="'typed'"
                       class="h32 text-overflow"
                       [class.active-hide]="showHide"
                       enInput
                       enDynamicWidth
                       formControlName="value">
            </ng-container>
        </ng-container>
        <ng-container *ngIf="fieldTypeValue === 'bool'">
            <en-dropdown class="input__small"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="optionsOperatorTypeBool"
                         dropdownIcon="en en-chevron-down"
                         formControlName="operator"></en-dropdown>
        </ng-container>
        <ng-container *ngIf="fieldTypeValue === 'float'">
            <en-dropdown class="condition__operator input__small"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="optionsOperatorTypeNumber"
                         formControlName="operator"></en-dropdown>
            <ng-container *ngIf="showValueField">
                <input [imask]="maskFloat"
                       [unmask]="'typed'"
                       class="h32 text-overflow"
                       [class.active-hide]="showHide"
                       enInput
                       enDynamicWidth
                       formControlName="value">
            </ng-container>
        </ng-container>
        <ng-container *ngIf="fieldTypeValue === 'list'">
            <en-dropdown class="condition__operator input__small"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="optionsOperatorTypeList"
                         formControlName="operator"></en-dropdown>
            <en-dropdown *ngIf="showListFieldDropdown"
                         class="condition__field input__large"
                         styleClass="en-dropdown-solid"
                         [class.active-hide]="showHide"
                         [options]="listOptions"
                         [filter]="true"
                         filterBy="label"
                         [filterPlaceholder]="'table_detail.search_placeholder' | translate"
                         [autoDisplayFirst]="false"
                         dropdownIcon="en en-chevron-down"
                         [placeholder]="'common.select' | translate"
                         formControlName="value"></en-dropdown>
            <en-multiSelect *ngIf="showListFieldMultiselect"
                            class="condition__field input__large"
                            [class.active-hide]="showHide"
                            [options]="listOptions"
                            [filterPlaceHolder]="'table_detail.search_placeholder' | translate"
                            [showToggleAll]="false"
                            [showCloseButton]="false"
                            [isClearButton]="true"
                            [isInvalid]="markAsInvalid"
                            [placeholder]="'common.select' | translate"
                            formControlName="value">
                <ng-template let-value
                             pTemplate="selectedItems">
                    <div *ngIf="!value?.length">{{'common.select' | translate}}</div>
                    <div *ngIf="value && value.length < 4"
                         class="selected-items__wrapper">
                        <div *ngFor="let option of value; index as idx"
                             class="text-color-badge selected-items">
                            <span class="text-overflow">
                                {{ option.name }}
                            </span>
                            <i [attr.data-clickable]="true"
                               class="en en-cancel-fill button-deleted"
                               (click)="deselectList(idx)"></i>
                        </div>

                    </div>

                    <ng-container *ngIf="value?.length > 3">
                        <span>{{'segment_form.selected' | translate:{value: value?.length} }}</span>
                    </ng-container>
                </ng-template>

                <ng-template let-condition
                             pTemplate="item">
                    <div class="options-groups">
                        <span class="text-overflow">{{ condition.label }}</span>
                    </div>
                </ng-template>
            </en-multiSelect>
        </ng-container>
        <div>
            <en-field-date-condition *ngIf="fieldTypeValue==='date' || fieldTypeValue==='dateTime'"
                                     [form]="form"
                                     [showHide]="showHide"
                                     [dateType]="fieldTypeValue"
                                     [class.active-hide]="showHide">
            </en-field-date-condition>
        </div>
    </ng-container>
    <en-condition-tools *ngIf="isHover"
                        [index]="index"
                        [isHide]="isHide"
                        (removeCondition)="removeCondition.emit($event)"
                        (copyCondition)="copyCondition.emit($event)"
                        (hideCondition)="hideCondition(isHide)"></en-condition-tools>
</form>