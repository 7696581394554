import {
	Component,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

@Component({
	selector: 'en-nav-button',
	templateUrl: './nav-button.component.html',
	styleUrls: ['./nav-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavButtonComponent {
	@Input()
	readonly completed: boolean;

	@Input()
	readonly selected: boolean;

	@Input()
	readonly label: string;

	@Input()
	readonly disableButton: boolean;

	@Output()
	readonly navButtonClick = new EventEmitter<void>();
}
