import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '@enkod-core/utils';
import { SqlTemplate } from './sql-template.model';

@Injectable({
	providedIn: 'root'
})
export class SqlTemplatesDataService extends RestService<SqlTemplate> {
	constructor(private http: HttpClient) {
		super(http, 'enkod/sql/template');
	}
}
