import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnMessagesModule, FileUploadModule } from 'ui-lib';
import { ArchiveUploaderComponent } from './archive-uploader.component';

@NgModule({
	imports: [CommonModule, FileUploadModule, EnMessagesModule],
	declarations: [ArchiveUploaderComponent],
	exports: [ArchiveUploaderComponent]
})
export class ArchiveUploaderModule {}
