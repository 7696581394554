import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MessageFullBodyForm } from '@enKod/segments/segments-form.model';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import { LinkData } from '@enSend/message/message-details/_states/_state-message';
import { ActionLink } from '@state-enKod/segments';

@UntilDestroy()
@Component({
	selector: 'en-message-add-link',
	templateUrl: './message-add-link.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageAddLinkComponent implements OnInit {
	isHover = false;
	visibleDialog = false;
	markAsInvalid = false;

	@Input() form: FormGroup<MessageFullBodyForm>;

	@Input() isShowRemoveButton = false;

	@Input() isHide = false;

	@Output() remove = new EventEmitter();

	constructor(
		private validationService: SegmentsValidateService,
		private cd: ChangeDetectorRef
	) {}

	get typedForm(): FormGroup<MessageFullBodyForm> {
		return this.form as FormGroup<MessageFullBodyForm>;
	}

	get actionLinkForm() {
		return this.typedForm.controls.actionLink;
	}

	ngOnInit(): void {
		this.actionLinkForm.controls.url.valueChanges.subscribe(() => {
			this.actionLinkForm.controls.url_id.disable();
			this.actionLinkForm.controls.seekBy.patchValue('fullMatch');
		});

		this.validationService.checkedValidate
			.pipe(
				untilDestroyed(this),
				tap(() => {
					const isInvalid =
						!this.actionLinkForm.controls.url.value.length;
					this.markAsInvalid = isInvalid && !this.isHide;

					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	openDialog() {
		this.visibleDialog = true;
	}

	applyLink(link: ActionLink & LinkData) {
		const linkData = {
			url_id: link.url_id,
			url: link.url,
			seekBy: link.seekBy
		};

		this.actionLinkForm.controls.url_id.enable();
		this.actionLinkForm.patchValue(linkData, {
			emitEvent: false
		});

		this.visibleDialog = false;
	}
}
