import {
	Component,
	ChangeDetectionStrategy,
	OnInit,
	Inject
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ID } from '@datorama/akita';
import { UrlSaverService } from '@enkod-core/services';
import { SCENARIO_MESSAGES_TAB } from '@enSend/message/message-list/providers';
import { DataWizardService } from '@enSend/message/message-wizard/kit';
import {
	SendMessageStateToken,
	CanvaModeType,
	CANVA_MODE_TOKEN
} from '@enSend/_shared/tokens';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
	MessagesService,
	ScenarioMessage
} from '@enSend/message/_states/_state-message';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SearchService } from 'ui-lib';
import { Router } from '@angular/router';
import { AbstractInspector } from '../../abstract';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { BLOCK_STATUS_OPTIONS, MESSAGE_TABLE_COLUMNS } from './constants';

export interface SelectedMessage {
	id: ID;
	label: string | undefined;
	type: string;
}
@UntilDestroy()
@Component({
	selector: 'en-send-message',
	templateUrl: './send-message.component.html',
	styleUrls: ['./send-message.component.scss'],
	providers: [SearchService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendMessageComponent extends AbstractInspector implements OnInit {
	searchControl: UntypedFormControl;

	messageId: ID;

	visibleDialogMessage: boolean;

	selectedMessage$ = new BehaviorSubject<SelectedMessage[]>([]);

	resetValue$ = new Subject();

	scenarioListTab = {
		...SCENARIO_MESSAGES_TAB,
		filter: {
			// channel: ['mail', 'mobPush'],
			sendingType: ['scenario'],
			noDraft: [true]
		},
		hideFilters: ['status', 'sendingTime'],
		columns: MESSAGE_TABLE_COLUMNS
	};

	private sendMessageState: Map<ID, ID>;

	constructor(
		private searchService: SearchService,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		private confirmService: ConfirmationService,
		private translate: TranslateService,
		private dataWizard: DataWizardService<ScenarioMessage>,
		private messagesService: MessagesService,
		private urlService: UrlSaverService,
		private router: Router
	) {
		super();
	}

	get scenarioId(): number {
		const noQuery = this.router.url.split('?')[0];
		return Number(noQuery.split('/')[4]);
	}

	get isCopyScenario(): boolean {
		const noQuery = this.router.url.split('?')[0];
		return noQuery.split('/')[3] === 'copy';
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get blockId() {
		return this.cell.get('id');
	}

	get statusOptions() {
		return BLOCK_STATUS_OPTIONS;
	}

	ngOnInit() {
		this.searchControl = new UntypedFormControl('');
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;

						const options = this.cell.get('options');
						const subType = this.cell.get('subType');
						const { params } = options[subType];

						if (params.length && params[0].type === 'whatsapp') {
							this.messageId = params[0].id;
						}
						this.selectedMessage$.next(
							params.map((option: SelectedMessage) => ({
								id: option.id,
								label: option.label,
								type: option.type
							})) || []
						);

						this.sendMessageState = context.injector.get(
							SendMessageStateToken
						);
					}
				})
			)
			.subscribe();

		this.searchControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => this.searchService.setSearchValue(value))
			)
			.subscribe();
	}

	checkSelectedMessage(message: ScenarioMessage) {
		// если уже используется, предлагаем скопировать
		if (
			(message.scenarioId !== 0 &&
				message.scenarioId !== this.scenarioId) ||
			[...this.sendMessageState.values()].includes(message.id) ||
			(message.scenarioId === this.scenarioId && this.isCopyScenario)
		) {
			this.confirmService.confirm({
				key: 'info',
				acceptLabel: this.translate.instant(
					'scenario_block_send_message.dialog_accept'
				),
				rejectVisible: true,
				rejectLabel: this.translate.instant('common.cancel'),
				message: this.translate.instant(
					'scenario_block_send_message.dialog_message'
				),
				header: this.translate.instant(
					'scenario_block_send_message.dialog_header'
				),
				accept: () => {
					this.dataWizard
						.getMessage(message.id as number)
						.pipe(
							untilDestroyed(this),
							switchMap(res =>
								this.dataWizard.createMessage(res)
							),
							switchMap(res =>
								this.messagesService.update({
									...res,
									isActive: false
								})
							),
							tap(res => this.select(res))
						)

						.subscribe();
				}
			});
			return;
		}
		this.select(message);
	}

	private select(message: ScenarioMessage): void {
		const selectedMessage: SelectedMessage = {
			id: message.id,
			label: message.name,
			type: message.type
		};
		this.selectedMessage$.next([selectedMessage]);
		this.updateCell();
		this.visibleDialogMessage = false;

		if (message.type === 'whatsapp') this.messageId = message.id;

		this.updateSendMessageState(message.id);
	}

	private updateCell() {
		const message = this.selectedMessage$.getValue();
		this.changeCellProp('options', {
			sendMessage: {
				params: message
			}
		});
	}

	private updateSendMessageState(messageId: ID) {
		this.sendMessageState.delete(this.blockId);
		this.sendMessageState.set(this.blockId, messageId);
	}

	onDialogOpen() {
		this.visibleDialogMessage = true;
	}

	onDialogHide() {
		this.visibleDialogMessage = false;

		this.resetValue$.next();
		this.searchService.setSearchValue('');
		this.urlService.setParamsToUrl({});
	}

	removeMessage() {
		this.selectedMessage$.next([]);
		this.updateCell();
		this.sendMessageState.delete(this.blockId);
	}
}
