/* eslint-disable import/no-cycle */
import { start, startView } from './start.shapes';
import { message, messageView } from './main.shapes';
import { enKodLink } from '../link.shape';

export const chatbotNamespace = {
	chatbot: {
		enKodLink,
		start,
		startView,
		message,
		messageView
	}
};
