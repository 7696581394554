import { Component, ChangeDetectionStrategy } from '@angular/core';
import { PopupPreview } from '../../../misc/abstract-preview';

@Component({
	selector: 'en-mobile-preview[popup]',
	templateUrl: './mobile-preview.component.html',
	styleUrls: ['./mobile-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePreviewComponent extends PopupPreview {}
