import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import {
	ActionTimeForm,
	PurchaseConditionForm
} from '@enKod/segments/segments-form.model';

import { AbstractCondition } from '../../../abstract-condition.component';
import {
	OPTIONS_AGGREGATION,
	OPTIONS_PARAMETER,
	OPTIONS_PERIOD
} from './purchase-options';

@UntilDestroy()
@Component({
	selector: 'en-purchase-condition',
	templateUrl: './purchase-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaseConditionComponent extends AbstractCondition {
	optionsParameter: SelectItem[] = OPTIONS_PARAMETER;
	optionsPeriod: SelectItem[] = OPTIONS_PERIOD;
	optionsAggregation: SelectItem[] = OPTIONS_AGGREGATION;

	get typedForm(): FormGroup<PurchaseConditionForm> {
		return this.form as FormGroup<PurchaseConditionForm>;
	}

	get actionTime(): FormGroup<ActionTimeForm> {
		return this.typedForm.controls.actionTime as FormGroup<ActionTimeForm>;
	}

	get parameterControl(): FormControl<string> {
		return this.typedForm.controls.parameter;
	}

	get parameterValue(): string {
		return this.parameterControl.value;
	}

	get valueControl(): FormControl<number> {
		return this.typedForm.controls.value;
	}

	get operatorControl(): FormControl<string> {
		return this.typedForm.controls.operator;
	}

	private get hasQuantity() {
		return this.operatorControl?.value && this.valueControl?.value;
	}

	setValidation() {
		this.getSubscription('parameter')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				switch (value) {
					case 'quantity':
						this.setQuantity('more', 2);
						this.valueControl?.setValidators([Validators.required]);
						this.operatorControl.enable();
						break;
					case 'sum':
						this.setQuantity('more', 5000);
						this.valueControl?.setValidators([Validators.required]);
						this.operatorControl.enable();
						break;
					case 'atLeastOne':
						this.valueControl?.clearValidators();
						this.setQuantity('moreOrEqual', 1);
						break;
					case 'id':
					case 'categoryId':
						this.valueControl?.setValidators([Validators.required]);
						this.valueControl?.updateValueAndValidity();
						this.operatorControl.disable();
						break;
					default:
						break;
				}
				this.markForCheck();
			});
	}

	isReadonly(): boolean {
		return this.parameterValue === 'categoryId';
	}

	private setQuantity(operator: string, value: number): void {
		if (this.hasQuantity) return;
		this.typedForm.controls.operator.enable();
		this.typedForm.controls.value.enable();
		this.typedForm.patchValue({ operator, value });
	}
}
