<form [formGroup]="form">
    <span class="en-text-large">{{'scenario_block_pause.description' | translate}}</span>
    <en-dropdown class="h32 option-dropdown option-type__dropdown"
                 [options]="pauseTypeOptions"
                 [placeholder]="'scenario_block_pause.type_placeholder' | translate"
                 [formControl]="pauseTypeControl"
                 [disabled]="!isCreationMode"
                 (onChange)="onPauseTypeChange($event.value)"></en-dropdown>
    <ng-container [ngSwitch]="pauseType">
        <div class="inputs-wrapper"
             *ngSwitchCase="'duration'">
            <input *ngIf="isCreationMode; else disabledInput"
                   enInput
                   enNumberRange
                   class="h32 number-input"
                   type="number"
                   [rangeConfig]="rangeConfig"
                   pKeyFilter="pint"
                   formControlName="value">
            <ng-template #disabledInput>
                <input class="h32 number-input"
                       type="number"
                       min="0"
                       enInput
                       disabled
                       formControlName="value">
            </ng-template>
            <en-dropdown class="h32 dropdown-input"
                         styleClass="en-dropdown-solid"
                         [options]="options$ | async"
                         [placeholder]="'scenario_block_pause.time_placeholder' | translate"
                         [disabled]="!isCreationMode"
                         formControlName="unit"></en-dropdown>
        </div>
        <div *ngSwitchCase="'specificDate'">
            <span class="control-header en-text-medium">{{'scenario_block_pause.data_time' | translate}}</span>
            <div class="en-field-medium option-dropdown">
                <div class="en-field">
                    <en-dropdown-calendar-new [type]="'dateTime'"
                                              [disabled]="!isCreationMode"
                                              [forceInvalid]="specificDateControl?.invalid || specificDateControl?.value === null"
                                              period="future"
                                              [formControl]="specificDateControl"></en-dropdown-calendar-new>
                    <en-error mode="advanced"
                              [formControl]="specificDateControl"></en-error>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'extraFields'">
            <div class="time_container">
                <span>{{ 'scenario_block_pause.wait' | translate }}</span>
                <div class="field-inputs_container">
                    <input enInput
                           class="h32"
                           type="number"
                           min="0"
                           pKeyFilter="pint"
                           [attr.disabled]="disableInput"
                           formControlName="value">
                    <en-dropdown class="h32"
                                 styleClass="en-dropdown-solid"
                                 [options]="options$ | async"
                                 [disabled]="!isCreationMode"
                                 formControlName="unit"></en-dropdown>
                    <en-dropdown class="h32"
                                 styleClass="en-dropdown-solid"
                                 [options]="operatorOptions"
                                 [disabled]="!isCreationMode"
                                 formControlName="operator"></en-dropdown>
                </div>
            </div>
            <div class="field_time_text">{{ 'scenario_block_pause.date_in_the_data_field' | translate }}</div>
            <div class="en-field">
                <label>{{ 'scenario_block_pause.data_field_label' | translate }}</label>
                <en-dropdown class="field-dropdown"
                             appendTo="body"
                             [placeholder]="'scenario_block_destribution.select_field' | translate"
                             [options]="extraFieldOptions"
                             [filter]="true"
                             filterBy="label,value.id"
                             [disabled]="!isCreationMode"
                             formControlName="extraField">
                    <ng-template let-field
                                 pTemplate="item">
                        <div class="field-dropdown_item">
                            <div class="field-info">
                                <div class="id-badge">
                                    id {{field.value.id}}
                                </div>
                                <div class="field-dropdown_label">
                                    {{field.label}}
                                </div>
                            </div>
                            <span class="field-dropdown_type"
                                  [ngClass]="field.value.type | extraFieldsIconPipe"></span>
                        </div>
                    </ng-template>
                </en-dropdown>
            </div>
        </div>
        <div *ngSwitchCase="'scenarioFields'">
            <div class="time_container">
                <span>{{ 'scenario_block_pause.wait' | translate }}</span>
                <div class="field-inputs_container">

                    <input enInput
                           class="h32"
                           type="number"
                           min="0"
                           pKeyFilter="pint"
                           [attr.disabled]="disableInput"
                           formControlName="value">
                    <en-dropdown class="h32"
                                 styleClass="en-dropdown-solid"
                                 [options]="options$ | async"
                                 [disabled]="!isCreationMode"
                                 formControlName="unit"></en-dropdown>
                    <en-dropdown class="h32"
                                 styleClass="en-dropdown-solid"
                                 [options]="operatorOptions"
                                 [disabled]="!isCreationMode"
                                 formControlName="operator"></en-dropdown>
                </div>
            </div>
            <div class="field_time_text">{{ 'scenario_block_pause.scn_fields_wait' | translate }}</div>
            <div class="en-field">
                <label>{{ 'scenario_block_destribution.scenario_field' | translate }}</label>
                <en-dropdown class="field-dropdown"
                             appendTo="body"
                             [placeholder]="'scenario_block_destribution.select_field' | translate"
                             [options]="scenarioFieldOptions"
                             [filter]="true"
                             [disabled]="!isCreationMode"
                             formControlName="scenarioField">
                    <ng-template let-field
                                 pTemplate="item">
                        <div class="field-dropdown_item">
                            <div class="field-dropdown_label">
                                {{field.label}}
                            </div>
                            <span class="field-dropdown_type"
                                  [ngClass]="field.type | extraFieldsIconPipe"></span>
                        </div>
                    </ng-template>
                </en-dropdown>
            </div>
        </div>
    </ng-container>
</form>