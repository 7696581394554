import { AbstractControl, Validators } from '@angular/forms';
import { TuiValidationError } from 'app/ui-lib/error/error.component';

export function mailingGroupValidator(
	field: AbstractControl
): Validators | null {
	return field.value.length !== 0
		? null
		: {
				error: new TuiValidationError(
					'scenario_block_mailing_groups.mailing_group_validate_field'
				)
		  };
}
