<span class="en-text-medium description">
    {{'scenario_block_schedule_start.description_start' | translate}}
</span>
<form [formGroup]="form">
    <ng-container *tuiLet="selectedSegment$ | async as selectedSegment">
        <div class="segment">
            <div *ngIf="!selectedSegment.length">
                <ng-container *ngIf="isCreationMode">
                    <button (click)="visibleDialogMessage = true"
                            enButton
                            [label]="'scenario_block_schedule_start.choose_segment' | translate"
                            class="en-button-secondary h36"></button>
                    <en-error formControlName="segment"></en-error>
                </ng-container>
            </div>
            <ng-container *ngFor="let info of selectedSegment">
                <en-chosen-item-info [id]="info.id"
                                     [content]="info.label"
                                     (remove)="removeSegment()"
                                     [isCreationMode]="isCreationMode"></en-chosen-item-info>
            </ng-container>
        </div>
    </ng-container>
    <div>
        <div class="en-field">
            <label>{{'message_wizard.regular_type' | translate}}</label>
            <en-dropdown styleClass="h36"
                         [placeholder]="'message_wizard.regular_type_placeholder' | translate"
                         [options]="regularOptions"
                         formControlName="every">
            </en-dropdown>
            <en-error formControlName="every"></en-error>
        </div>

        <ng-container [ngSwitch]="everyControl?.value">
            <ng-template ngSwitchCase="week">
                <div class="en-field">
                    <label>{{'scenario_block_schedule_start.regular_start_days' | translate}}</label>
                    <en-weekday-selector [formControl]="weekdaysControl"
                                         class="weekdays-selector"
                                         [disabled]="disableWeekdays"></en-weekday-selector>
                    <en-error [formControl]="weekdaysControl"></en-error>
                </div>
            </ng-template>

            <ng-template ngSwitchCase="month">
                <en-month [form]="paramsGroup"
                          class="weekdays-selector"
                          [location]="'scenario'"
                          [disabled]="disableWeekdays"></en-month>
            </ng-template>
        </ng-container>

        <div class="en-field">
            <label>{{'scenario_block_schedule_start.regular_start_time' | translate}}</label>
            <en-calendar [showIcon]="false"
                         [timeOnly]="true"
                         [formControl]="startTimeControl"
                         [disabled]="!isCreationMode"></en-calendar>
            <en-error formControlName="startTime"></en-error>
        </div>
    </div>
</form>

<en-dialog [header]="'scenario_block_api.select_segments' | translate"
           [resizable]="false"
           [modal]="true"
           styleClass="p-dialog--lg"
           appendTo="body"
           [(visible)]="visibleDialogMessage"
           (onHide)="onHideDialog()">
    <tui-scrollbar class="scrollbar">
        <div class="segments-table-content">
            <en-segments-table [toolsPanel]="false"
                               [selection]="true"
                               [notSaveInUrl]="true"
                               [isEnSendSegments]="true"
                               [segmentsInNewWindow]="true"
                               (selected)="selectSegment($event)">
                <ng-template enTemplate="tools">
                    <section class="search">
                        <en-search [placeholder]="'segment_list.search_placeholder' | translate"
                                   styleClass="h32"
                                   (refresh)="paginatorRef.refreshCurrentPage()"
                                   (onSearch)="paginationService.setSearchValue($event)"
                                   [refresher]="false"
                                   [resetValue$]="resetValue$"></en-search>
                    </section>
                </ng-template>
            </en-segments-table>
        </div>
    </tui-scrollbar>
</en-dialog>

<div class="duplicate-handling">
    <span class="option-header en-text-medium">{{'scenario_block_schedule_start.duplicate_processing' |
        translate}}</span>
    <en-dropdown [options]="selectOptions"
                 styleClass="h36"
                 class="duplicate-handling__dropdown"
                 [disabled]="!isCreationMode"
                 [formControl]="duplicateHandling"
                 [disabled]="!isCreationMode"
                 (onChange)="changeDuplicateHandling($event.value)">
        <ng-template let-selected
                     pTemplate="selectedItem">
            <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
        </ng-template>
        <ng-template let-type
                     pTemplate="item">
            <div class="duplicate-handling__drop">
                <span class="drop-label">{{type.label | translate }}</span>
                <div class="drop-description">{{type.description | translate}}</div>
            </div>
        </ng-template>
    </en-dropdown>
</div>

<en-messages [visible]="message.length"
             class="en-message"
             [(value)]="message"></en-messages>