<ng-container [ngSwitch]="previewMode">
    <div *ngSwitchCase="'desktop'" class="preview">
        <en-desktop-preview [template]="template"
                            [desktopBackground]="desktopBackground"
                            [dynamic]="dynamic"
                            popup></en-desktop-preview>
    </div>

    <div *ngSwitchCase="'mobile'">
        <en-mobile-preview [template]="template"
                           [mobileBackground]="mobileBackground"
                           popup></en-mobile-preview>
    </div>

    <div *ngSwitchCase="'fullscreen'">
        <en-fullscreen-preview [template]="template"
                               [desktopBackground]="desktopBackground"
                               (onExit)="devicePreview.patchValue('desktop')"
                               popup></en-fullscreen-preview>
    </div>
</ng-container>

<ng-container *ngIf="devicePreview.valueChanges | async"></ng-container>
