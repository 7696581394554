import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	Inject
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { SelectItem } from 'primeng/api';
import { AbstractInspector } from '../../abstract';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { TriggerCondition } from './start.model';

@UntilDestroy()
@Component({
	selector: 'en-start',
	templateUrl: './start.component.html',
	styleUrls: ['./start.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartComponent extends AbstractInspector implements OnInit {
	form: FormGroup = this.initForm();

	triggerCondition: SelectItem[] = [
		{
			label: 'chatbot_start_block.launch_by_start',
			value: '/start'
		},
		{
			label: 'chatbot_start_block.launch_by_link',
			value: 'link'
		},
		{
			label: 'chatbot_start_block.launch_by_keywords',
			value: 'keywords'
		}
	];

	constructor(
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		private fb: FormBuilder
	) {
		super();
	}

	ngOnInit(): void {
		this.initListeners();
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;
					}
				})
			)
			.subscribe();
	}

	private initForm(): FormGroup {
		return this.fb.group({
			triggerCondition: new FormControl<TriggerCondition>('/start')
		});
	}

	private initListeners() {
		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					this.changeCellProp('options', {
						...value
					});
				})
			)
			.subscribe();
	}
}
