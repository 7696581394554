import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';
import { PeopleCountState, PeopleCountStore } from './people-count.store';

@Injectable({ providedIn: 'root' })
export class PeopleCountQuery extends QueryEntity<PeopleCountState> {
	constructor(protected store: PeopleCountStore) {
		super(store);
	}
}
