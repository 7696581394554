import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { StepSplitTestService } from '../../../services';

@Injectable()
export class TestSnippetsFormService {
	constructor(
		private fb: UntypedFormBuilder,
		private stepSplitTestService: StepSplitTestService
	) {}

	SNIPPET_REGEXP = /\{\{Snippet\(["']\D[a-z]{0,1}[\w_-]{0,}['"]\)\}\}/g;

	SNIPPET_TEST_REGEXP = /^[a-z][a-z_-]{0,1}[\w-]{0,}$/;

	allSnippets: string[] = []; // Все сниппеты с синтаксисом {{Snippet("")}}

	snippets: string[] = []; // Валидные сниппеты

	warnAlert = false;

	snippetForm: UntypedFormGroup = this.fb.group({
		snippets: {}
	});

	get snippetsValue(): { [key: string]: string } {
		return this.snippetForm.value.snippets;
	}

	findSnippets() {
		this.allSnippets = [
			...(this.stepSplitTestService.formMessage
				?.get('html')
				?.value.match(this.SNIPPET_REGEXP) || []),
			...(this.stepSplitTestService.formMessage
				?.get('plainText')
				?.value.match(this.SNIPPET_REGEXP) || [])
		];

		this.fiilValidSnippets();
	}

	fiilValidSnippets() {
		this.snippets = [];
		this.allSnippets.forEach((snippet: string) => {
			const slicedSnippet: string = snippet.slice(11, snippet.length - 4);
			if (this.SNIPPET_TEST_REGEXP.test(slicedSnippet))
				this.snippets.push(slicedSnippet);
		});
	}

	isEmptySnippets(): boolean {
		return (
			!Object.keys(this.snippetForm?.value.snippets).length ||
			Object.values(this.snippetForm?.value.snippets).every(
				value => value === ''
			)
		);
	}
}
