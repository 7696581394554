import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Inject
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ID } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SelectItemDesctiption } from '@enkod-core/interfaces';
import { UrlSaverService } from '@enkod-core/services';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { MessagesTab, MESSAGES_TAB_TOKEN } from '@enSend/message/kit';
import {
	MessagesService,
	MessageType,
	ScenarioMessage
} from '@enSend/message/_states/_state-message';
import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';

import { SearchService } from 'ui-lib';

import { InspectorItemContext } from '../../inspector-item-plugin';
import { DUPLICATE_HANDLING_OPTIONS } from '../mailing-group/constants';
import { MESSAGE_LIST_TABS_PROVIDER_SCENARIO_WIZARD } from './providers/tabs';
import { SelectedMessage } from '../send-message/send-message.component';
import { AbstractInspectorStart } from '../../abstract/abstract-inspector-start';

@UntilDestroy()
@Component({
	selector: 'en-message-start',
	templateUrl: './message-start.component.html',
	styleUrls: ['./message-start.component.scss'],
	providers: [
		MESSAGE_LIST_TABS_PROVIDER_SCENARIO_WIZARD,
		SearchService,
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: DUPLICATE_HANDLING_OPTIONS
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageStartComponent
	extends AbstractInspectorStart
	implements OnInit
{
	actionHandling = this.fb.control(null);
	linksHandling = this.fb.control('');
	activeIndex = 0;
	searchForm: UntypedFormGroup = this.fb.group({
		searchControl: ''
	});

	linksOptions: SelectItem[] = [
		{
			label: this.translate.instant(
				'scenario_block_message_start.any_link'
			),
			value: ''
		}
	];

	actionOptions = [
		{
			label: this.translate.instant(
				'scenario_block_message_start.action_click'
			),
			value: 'click'
		},
		{
			label: this.translate.instant(
				'scenario_block_message_start.action_open'
			),
			value: 'open'
		}
	];

	filters = {};

	visibleDialogMessage: boolean;

	selectedMessage$ = new BehaviorSubject<SelectedMessage[]>([]);

	resetValue$ = new Subject();

	constructor(
		public fb: UntypedFormBuilder,
		public translate: TranslateService,
		private confirmService: ConfirmationService,
		@Inject(MESSAGES_TAB_TOKEN)
		public tabs: MessagesTab[],
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: SelectItemDesctiption[],
		private messagesService: MessagesService,
		private searchService: SearchService,
		private urlService: UrlSaverService
	) {
		super(fb, translate);
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	ngOnInit() {
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;
						const options = this.cell.get('options');
						const subType = this.cell.get('subType');
						const { params } = options[subType];
						this.actionHandling.patchValue(
							options.messageStartActionType
						);

						this.selectedMessage$.next(
							params.map((option: SelectedMessage) => ({
								id: option.id,
								label: option.label,
								type: option.type
							})) || []
						);

						this.initLinksOptionsDropdown(params, options.link);
						this.initDuplicateHandling();
					}
				})
			)
			.subscribe();

		this.initSearch();

		this.linksHandling.valueChanges
			.pipe(
				tap(resp => {
					this.changeCellProp(['options', 'link'], resp);
				})
			)
			.subscribe();
	}

	private initLinksOptionsDropdown(params: any, link: string) {
		this.linksOptions = [
			{
				label: this.translate.instant(
					'scenario_block_message_start.any_link'
				),
				value: ''
			}
		];
		if (this.showLinksDropdown) {
			params.forEach((block: any) => {
				const { id, type } = block;
				this.getMessageLinks(id, type)
					.pipe(untilDestroyed(this))
					.subscribe(resp => {
						resp.forEach((link: any) => {
							this.linksOptions.push({
								label: link,
								value: link
							});
						});
						this.linksHandling.patchValue(link);
					});
			});
		}
	}

	private initSearch() {
		this.searchForm
			.get('searchControl')
			?.valueChanges.pipe(
				untilDestroyed(this),
				tap(value => this.searchService.setSearchValue(value))
			)
			.subscribe();
	}

	get showLinksDropdown() {
		return (
			this.actionHandling.value === 'click' &&
			this.selectedMessage$.value.length !== 0
		);
	}

	select(message: ScenarioMessage): void {
		if (this.actionHandling.value === 'click') {
			const { id, type } = message;
			this.getMessageLinks(id, type)
				.pipe(untilDestroyed(this))
				.subscribe(resp => {
					if (resp === null) this.showEmptyLinksNotification();
					if (resp !== null) {
						this.onMessageSelect(message);
						resp.forEach((link: any) => {
							this.linksOptions.push({
								label: link,
								value: link
							});
						});
					}
				});
		}

		if (this.actionHandling.value === 'open') {
			this.onMessageSelect(message);
		}
	}

	onMessageSelect(message: ScenarioMessage) {
		const selectedMessage: SelectedMessage = {
			id: message.id,
			label: message.name,
			type: message.type
		};
		this.selectedMessage$.next([selectedMessage]);
		this.updateCell();
		this.visibleDialogMessage = false;
	}

	getMessageLinks(id: ID, type: MessageType) {
		return this.messagesService.getMessageUrls(id, type);
	}

	private updateCell() {
		const selectedMessage = this.selectedMessage$.getValue();
		this.changeCellProp('options', {
			messageStart: {
				params: selectedMessage
			},
			duplicateHandling: this.duplicateHandling.value,
			messageStartActionType: this.actionHandling.value,
			link: this.linksHandling.value
		});
	}

	onDialogOpen() {
		this.visibleDialogMessage = true;
		this.urlService.setParamsToUrl({});
	}

	onDialogHide() {
		this.visibleDialogMessage = false;
		this.searchService.setSearchValue('');
		this.resetValue$.next();
	}

	changeActionHandling(value: string) {
		this.removeMessage();
		this.cell.prop(['options', 'messageStartActionType'], value);
	}

	removeMessage() {
		this.selectedMessage$.next([]);
		const selectedMessage = this.selectedMessage$.getValue();
		this.changeCellProp('options', {
			messageStart: {
				params: selectedMessage
			},
			duplicateHandling: this.duplicateHandling.value,
			messageStartActionType: this.actionHandling.value
		});
		this.linksOptions = [
			{
				label: this.translate.instant(
					'scenario_block_message_start.any_link'
				),
				value: ''
			}
		];
	}

	showEmptyLinksNotification() {
		this.confirmService.confirm({
			key: 'info',
			acceptLabel: this.translate.instant(
				'scenario_block_message_start.notify_accept'
			),
			message: this.translate.instant(
				'scenario_block_message_start.notify_text'
			),
			header: this.translate.instant(
				'scenario_block_message_start.notify_header'
			)
		});
	}
}
