import { SelectItemDesctiption } from '@enkod-core/interfaces';

export const DUPLICATE_HANDLING_OPTIONS: SelectItemDesctiption[] = [
	{
		label: 'scenario_block_duplicate_handling.duplicate_ignore_title',
		value: 'ignore',
		description:
			'scenario_block_duplicate_handling.duplicate_ignore_description'
	},
	{
		label: 'scenario_block_duplicate_handling.duplicate_parallel_title',
		value: 'parallel',
		description:
			'scenario_block_duplicate_handling.duplicate_parallel_description'
	},
	{
		label: 'scenario_block_duplicate_handling.duplicate_beginning_title',
		value: 'moveToBeginning',
		description:
			'scenario_block_duplicate_handling.duplicate_beginning_description'
	}
];
