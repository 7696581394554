/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { PortItem, Option } from '@enSend/_shared/models';
import { ScenarioShapesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';
import { blockView } from '@enSend/_shared/components/scenario-canva/rappid/shapes';
import { previewBlock } from '../base.shapes';

export const severalPortsBlock = previewBlock.define(
	ScenarioShapesEnum.SEVERAL_PORTS_BLOCK,
	{
		ports: {
			items: [
				{
					group: 'in'
				}
			]
		},
		attrs: {
			headerRect: {
				fill: '#1B77E0',
				stroke: '#1061C3'
			}
		}
	},
	{
		initialize(...args: any) {
			previewBlock.prototype.initialize.apply(this, args);
			this.onChangeOptions();
		},

		onChangeOptions(args: any) {
			const options = this.get('options');
			const subType = this.get('subType');

			let offsetY = 0;
			const optionHeight = this.get('optionHeight');
			const padding = this.get('padding');
			const questionHeight = this.get('questionHeight');

			const blockOptions = options[subType];
			const { params } = blockOptions;

			params.forEach((option: Option) => {
				this.updatePort(option, {
					offsetY,
					optionHeight,
					padding,
					questionHeight
				});

				offsetY += optionHeight + padding;
			});

			this.removeUnusablePorts(blockOptions);

			previewBlock.prototype.onChangeOptions.call(this, {
				updatePorts: true,
				...args
			});
		},

		updatePort(
			option: any,
			position: {
				offsetY: number;
				optionHeight: number;
				padding: number;
				questionHeight: number;
			}
		) {
			if (!position) return;
			// eslint-disable-next-line no-param-reassign
			if (!option.port) option.port = this.generatePortId();

			const portY =
				position.offsetY +
				position.optionHeight / 2 +
				position.questionHeight;

			if (!this.getPort(`${option.port}`)) {
				this.addPort({
					group: 'out',
					id: `${option.port}`,
					args: { y: portY }
				});
			} else {
				this.portProp(`${option.port}`, 'args/y', portY);
			}
		},

		removeUnusablePorts(blockOptions: any) {
			const { params } = blockOptions;
			const portsItems = this.getPorts();
			if (!portsItems) return;

			let removedPorts = portsItems.filter(
				(port: PortItem) =>
					port.group === 'out' &&
					!params.find((option: Option) => option.port === port.id)
			);

			Object.keys(blockOptions).forEach(key => {
				removedPorts = removedPorts.filter(
					(item: Option) => item.id !== blockOptions[key]
				);
			});

			this.removePorts(removedPorts);
		}
	}
);
export const severalPortsBlockView: dia.ElementView = Object.assign(blockView);
