import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit,
	ChangeDetectorRef
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
	FormArray
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';
import { VALIDATION_TOKEN } from '@enkod-core/tokens';
import { CustomValidators } from 'custom-validators';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { AbstractInspector } from '../../abstract';
import {
	MESSAGE_LIMIT,
	BUTTONS_LIMIT,
	BUTTONS_LABEL_LIMIT
} from './message.constants';
import { ButtonType, Options, Button } from './message.model';

@UntilDestroy()
@Component({
	selector: 'en-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss'],
	providers: [
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'chatbot_message_block.message_text_required',
				maxlength: 'chatbot_message_block.message_max_lenght'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent extends AbstractInspector implements OnInit {
	readonly form: FormGroup = this.initForm();
	messageLimit: number = MESSAGE_LIMIT;
	manualInvalid$ = new BehaviorSubject(false);

	constructor(
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(VALIDATION_TOKEN)
		protected readonly checkValidate$: Subject<boolean>,
		private fb: FormBuilder,
		private cd: ChangeDetectorRef,
		private translate: TranslateService
	) {
		super();
	}

	get buttonsGroup(): FormGroup {
		return this.form.controls.buttons as FormGroup;
	}

	get otherButtons(): FormArray {
		return this.buttonsGroup.controls.other as FormArray;
	}

	get textControl(): FormControl {
		return this.form.controls.text as FormControl;
	}

	get backButtonExist(): boolean {
		return this.buttonsGroup.contains('restart');
	}

	get buttonLimit(): number {
		return this.backButtonExist ? BUTTONS_LIMIT - 1 : BUTTONS_LIMIT;
	}

	get emptyLabel(): string {
		return this.translate.instant('chatbot_message_block.button');
	}

	ngOnInit(): void {
		this.initListeners();
		this.context$
			.pipe(
				untilDestroyed(this),
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;

						const options = this.cell.get('options');
						this.patchOnInit(options);

						this.changeCellProp('options', {
							...options,
							isValid: this.form.valid
						});
					}
				}),
				switchMap(() =>
					this.form.valueChanges.pipe(
						untilDestroyed(this),
						// debounceTime(0) нужен для оптимизации
						debounceTime(0),
						tap(value => {
							const buttons = [
								...value.buttons.other,
								value.buttons.restart
							]
								.filter(button => button)
								.map(button => this.dto(button));

							this.cell.set('invalid', !this.form.valid);

							this.changeCellProp('options', {
								isValid: this.form.valid,
								text: value.text,
								inlineReplyButtons: buttons
							});

							if (this.textControl.dirty) {
								this.manualInvalid$.next(
									!this.textControl.valid
								);
							}
						})
					)
				),
				finalize(() => {
					const options = this.cell.get('options');
					this.patchOnInit(options);
					this.changeCellProp('options', {
						...options,
						isValid: this.form.valid
					});
				})
			)
			.subscribe();
	}

	private initForm(): FormGroup {
		return this.fb.group({
			text: new FormControl<string>('', [
				Validators.maxLength(MESSAGE_LIMIT),
				Validators.required
			]),
			buttons: new FormGroup({
				other: new FormArray([])
			})
		});
	}

	private initListeners() {
		this.checkValidate$
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.manualInvalid$.next(this.textControl.invalid);
					this.textControl.markAsDirty();
					this.otherButtons.controls.forEach(button => {
						(button as FormGroup).controls.label.markAsDirty();
						(button as FormGroup).controls.url?.markAsDirty();
					});
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private patchOnInit(options: Options) {
		this.otherButtons.clear();
		if (this.backButtonExist) this.buttonsGroup.removeControl('restart');

		this.textControl.patchValue(options.text ?? '');

		if (this.cell.attributes.invalid) {
			this.manualInvalid$.next(!this.textControl.valid);
			this.textControl.markAsDirty();
		}

		const buttons = options.inlineReplyButtons;
		const last = buttons.length - 1;

		if (buttons[last]?.type === 'restart') {
			this.addButton('restart');
			this.buttonsGroup.controls.restart.patchValue(buttons[last]);

			for (let i = 0; i < buttons.slice(0, -1).length; i++) {
				this.addButton(buttons[i].type);
				this.patchButton(i, buttons[i]);
			}
			return;
		}

		for (let i = 0; i < buttons.length; i++) {
			this.addButton(buttons[i].type);
			this.patchButton(i, buttons[i]);
		}
	}

	private dto(button: Button): Button {
		return {
			...button,
			...(button.label === '' ? { label: this.emptyLabel } : {}),
			...(button.port ? { port: button.port } : {})
		};
	}

	private patchButton(i: number, button: Button) {
		const { type, label, url, port, guid } = button;
		const group = this.otherButtons.controls[i] as FormGroup;

		group.controls.guid.patchValue(guid);
		group.controls.type.patchValue(type);
		group.controls.label.patchValue(label);
		group.controls.port.patchValue(port);
		group.controls.label.markAsDirty();

		if (button.type === 'link') {
			group.controls.url.patchValue(url);
			group.controls.url.markAsDirty();
		}
	}

	addButton(value: ButtonType) {
		const group: FormGroup = this.fb.group({
			guid: new FormControl<string>(''),
			type: new FormControl<ButtonType>(value),
			label: new FormControl<string>('', [
				Validators.required,
				Validators.maxLength(BUTTONS_LABEL_LIMIT)
			])
		});

		if (value === 'link') {
			group.addControl(
				'url',
				new FormControl('', [Validators.required, CustomValidators.url])
			);
		}

		if (value === 'restart') {
			group.controls.label.patchValue(
				this.translate.instant('chatbot_message_block.back')
			);
			this.buttonsGroup.addControl('restart', group);
			return;
		}

		group.addControl('port', new FormControl<string>(''));
		this.otherButtons.push(group);
	}
}
