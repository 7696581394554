<div class="preview"
     [ngClass]="{   'preview_medium': countButtons === 2 || isAndroid,
                    'preview_large': countButtons === 3 && !isAndroid }">

    <div class="push-wrapper"
         [hidden]="view !== 'ios'">

        <div class="push push_ios"
             (click)="togglePreview()">

            <div class="push__head">
                <div class="push__image"></div>
                <span class="push__sender">{{ preview.appName || 'APP NAME' }}</span>
                <span class="push__time">now</span>
            </div>

            <div class="push__content content">
                <div class="content__big-image big-image big-image_ios"
                     [class.big-image_expanded]="iosImage && expanded"
                     [ngStyle]="{ height: iosHeightImage + 'px' }">
                    <img #imageIos
                         [hidden]="!iosImage || !expanded"
                         alt=""
                         [src]="getValidImgUrl(iosImage)"
                         (load)="changeIosImageHight()"
                         (error)="onErrorLoadIosImage()">
                </div>

                <div class="content__text-container text-container text-container_ios">
                    <div class="text-container__wrapper">
                        <div class="text-container__title text-container__title_ios">
                            {{
                            preview.subject ||
                            (placeholder ? ('message_wizard_edit_step.settings_theme'| translate) : '')
                            }}
                        </div>
                        <div class="text-container__text text-container__text_ios en-text-medium">
                            {{
                            preview.body ||
                            (placeholder ? ('message_wizard_edit_step.settings_push_message_text'| translate) : '')
                            }}
                        </div>
                    </div>

                    <div *ngIf="iosSmallImage"
                         [hidden]="expanded"
                         class="content__small-image small-image small-image_ios"
                         [@fadeIn]
                         [@heightCollapse]>
                        <img alt=""
                             [src]="getValidImgUrl(iosSmallImage)">
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="expanded && countButtons"
             class="buttons"
             [ngClass]="{   'buttons_two': countButtons === 2,
                            'buttons_three': countButtons === 3 }"
             [@fadeIn]
             [@heightCollapse]>
            <div class="buttons__container"
                 *ngFor="let button of preview.buttons; index as i">
                <h2>
                    {{ button ||
                    ('message_wizard_edit_step.additional_settings_button' | translate) + ' ' + (i + 1) }}
                </h2>
            </div>
        </div>
    </div>

    <div class="push push_android"
         [hidden]="view !== 'android'"
         (click)="togglePreview()">

        <div class="push__head">
            <div class="push__image"></div>
            <span class="push__sender">{{ preview.appName || 'App Name' }}</span>
            <span style="padding: 0 0.25rem">&bull;</span>
            <span class="push__time">now</span>
            <div class="push__arrow"
                 [class.icon_rotated]="expanded">
                <span class="en en-arrow-down"></span>
            </div>
        </div>

        <div class="push__content content">
            <div class="content__text-container text-container text-container_android">
                <div class="text-container__wrapper">
                    <div class="text-container__title text-container__title_android">
                        {{
                        preview.subject ||
                        (placeholder ? ('message_wizard_edit_step.settings_theme'| translate) : '' )
                        }}
                    </div>
                    <div class="text-container__text text-container__text_android en-text-medium">
                        {{
                        preview.body ||
                        (placeholder ? ('message_wizard_edit_step.settings_push_message_text'| translate) : '')
                        }}
                    </div>
                </div>

                <div *ngIf="androidSmallImage"
                     class="content__small-image small-image small-image_android"
                     [@fadeIn]
                     [@heightCollapse]>
                    <img alt=""
                         [src]="getValidImgUrl(androidSmallImage)">
                </div>
            </div>
        </div>

        <div class="push__expand"
             [class.push__expand_expanded]="expanded">
            <div class="push__content content">
                <div class="content__big-image big-image big-image_android"
                     [class.big-image_expanded]="androidSmallImage && expanded">
                    <img [hidden]="!androidSmallImage"
                         alt=""
                         [src]="getValidImgUrl(androidSmallImage)">
                </div>
            </div>

            <div class="buttons"
                 *ngIf="expanded && countButtons"
                 [@fadeIn]
                 [@heightCollapse]>
                <div class="buttons__container"
                     *ngFor="let button of preview.buttons; index as i">
                    <div class="button text-overflow">
                        {{
                        button ||
                        ('message_wizard_edit_step.additional_settings_button' | translate) + ' ' + (i + 1)
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
