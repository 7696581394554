import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';

import { BehaviorSubject } from 'rxjs';

import { PhoneModel, SelectedView } from '../models/view-mail.model';

@Injectable()
export class SelectViewService {
	readonly selectedViewMail$ = new BehaviorSubject<SelectedView>('desktop');

	readonly selectedViewMobile$ = new BehaviorSubject<PhoneModel>('android');

	readonly optionsViewMail: Readonly<SelectItem[]> = [
		{ icon: 'en en-desktop', value: 'desktop' },
		{ icon: 'en en-mobile', value: 'mobile' },
		{ icon: 'en en-editor-html', value: 'codeHtml' }
	];

	readonly optionsViewMobile: Readonly<SelectItem[]> = [
		{
			icon: 'en en-android',
			value: 'android'
		},
		{
			icon: 'en en-apple',
			value: 'ios'
		}
	];
}
