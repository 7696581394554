<div class="destribution"
     [formGroup]="form">
    <en-radiobutton [class.radio-button-disabled]="!isCreationMode"
                    [disabled]="!isCreationMode"
                    value="bySegments"
                    [label]="'scenario_block_destribution.segment_type' | translate"
                    formControlName="distributionType"></en-radiobutton>
    <en-radiobutton [class.radio-button-disabled]="!isCreationMode"
                    [disabled]="!isCreationMode"
                    value="scenarioFields"
                    [label]="'scenario_block_destribution.scenario_fields' | translate"
                    formControlName="distributionType"></en-radiobutton>
    <en-radiobutton [class.radio-button-disabled]="!isCreationMode"
                    [disabled]="!isCreationMode"
                    value="equally"
                    [label]="'scenario_block_destribution.equally_type' | translate"
                    formControlName="distributionType"></en-radiobutton>
    <en-radiobutton [class.radio-button-disabled]="!isCreationMode"
                    [disabled]="!isCreationMode"
                    value="proportionally"
                    [label]="'scenario_block_destribution.proportionally_type' | translate"
                    formControlName="distributionType"></en-radiobutton>

    <ng-container [ngSwitch]="form.value.distributionType">
        <div *ngSwitchCase="'bySegments'">
            <div class="description">
                <span class="en-text-large">
                    {{'scenario_block_destribution.description' | translate}}
                </span>
            </div>

            <ng-container *tuiLet="selectedSegments | async as segments">
                <div class="segments">
                    <ng-container *ngFor="let segment of segments">
                        <en-chosen-item-info [id]="segment.id"
                                             [content]="segment.name"
                                             (remove)="unselectSegment(segment.id)"
                                             [contentClickable]="false"
                                             (contentAction)="openSegmentsDetail()"
                                             [isCreationMode]="isCreationMode"></en-chosen-item-info>
                    </ng-container>
                </div>

                <ng-container *ngIf="isCreationMode">
                    <div class="chooseSegment">
                        <button enButton
                                [label]="'scenario_block_api.choose_segment' | translate"
                                class="h36 en-button-secondary"
                                (click)="openSegmentsList()"></button>
                    </div>
                </ng-container>


                <en-choose-segments-dialog *ngIf="segmentsDialogVisible"
                                           [dialogVisible]="segmentsDialogVisible"
                                           [selectedSegments]="segments"
                                           [isHeaderCheckboxDisabled]="true"
                                           (onHide)="segmentsDialogVisible=false"
                                           (onConfirm)="selectSegments($event)"></en-choose-segments-dialog>
                <!-- <en-segments-tree-dialog *ngIf="segmentsTreeVisible"
                                                         [dialogVisible]="segmentsTreeVisible"
                                                         (onHide)="segmentsTreeVisible=false"></en-segments-tree-dialog> -->
            </ng-container>
        </div>

        <div *ngSwitchCase="'equally'">
            <span class="en-text-large">
                {{'scenario_block_destribution.equally_description' | translate}}
            </span>

            <div class="en-field">
                <label class="en-text-medium">{{'scenario_block_destribution.groups_amount' | translate}}</label>
                <input class="h36"
                       type="number"
                       enInput
                       enNumberRange
                       pKeyFilter="pint"
                       [attr.disabled]="disableInput"
                       [rangeConfig]="{ minValue: 2, maxValue: 10 }"
                       formControlName="groupsAmount">
            </div>
        </div>

        <div *ngSwitchCase="'proportionally'">
            <span class="en-text-large">
                {{'scenario_block_destribution.proportionally_description' | translate}}
            </span>

            <div class="en-field">
                <label class="en-text-medium">{{'scenario_block_destribution.groups_amount' | translate}}</label>
                <input class="h36"
                       type="number"
                       enInput
                       enNumberRange
                       pKeyFilter="pint"
                       [attr.disabled]="disableInput"
                       [rangeConfig]="{ minValue: 2, maxValue: 10 }"
                       formControlName="propGroupsAmount">
            </div>

            <div formArrayName="propGroupsValues">
                <div *ngFor="let item of shareGroupsControls; index as i"
                     class="en-field">
                    <div *ngIf="i !== 0"
                         class="slider__hr"></div>
                    <label>{{'scenario_block_destribution.group' | translate}} {{ i + 1 }}</label>
                    <div class="slider__container">
                        <en-slider [step]="step"
                                   [sliderWidth]="17.5"
                                   [disabled]="disabledSliders.includes(i) || !isCreationMode"
                                   [formControlName]="i"
                                   (onChange)="onSlide(i)"></en-slider>

                        <en-button class="lock__button"
                                   styleClass="en-button-lowercase h36"
                                   [icon]="disabledSliders.includes(i) || !isCreationMode ? 'en en-lock' : 'en en-not-lock'"
                                   [title]="(disabledSliders.includes(i) || !isCreationMode ?
                                                 'table_tools.tools_button_unblock' :
                                                 'table_tools.tools_button_block') | translate"
                                   (click)="disableSlider(i)"></en-button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngSwitchCase="'scenarioFields'">
            <span class="en-text-large">
                {{'scenario_block_destribution.fields_description' | translate}}
            </span>


            <div formArrayName="fields">
                <div *ngFor="let field of fieldControls; index as i"
                     class="en-field"
                     [formGroup]="field">
                    <div class="field__label">
                        <label>{{ 'scenario_block_destribution.scenario_field' | translate }}</label>
                        <button *ngIf="isCreationMode && fieldControls.length > 1"
                                enButton
                                class="en-button-lowercase h28"
                                icon="en en-minus-circle-o"
                                iconColor="red-button-icon"
                                [disabled]="!isCreationMode"
                                (click)="deleteField(i)"></button>
                    </div>
                    <div class="field-controls">
                        <en-dropdown class="field-dropdown"
                                     appendTo="body"
                                     [placeholder]="'scenario_block_destribution.select_field' | translate"
                                     [options]="fieldOptions"
                                     [filter]="true"
                                     [disabled]="!isCreationMode"
                                     formControlName="fieldName"
                                     (onChange)="resetFromParam(i)">
                            <ng-template let-selectedOption
                                         pTemplate="selectedItem">
                                <ng-container *ngIf="selectedOption">
                                    <div class="selected-field">
                                        <span class="en-mr-2"
                                              [ngClass]="selectedOption.type | extraFieldsIconPipe"></span>
                                        <span class="selected-field__name">{{ selectedOption.label }}</span>
                                    </div>
                                </ng-container>
                            </ng-template>
                            <ng-template let-field
                                         pTemplate="item">
                                <div class="field-dropdown_item">
                                    <div class="field-dropdown_label">
                                        {{field.label}}
                                    </div>
                                    <span class="field-dropdown_type"
                                          [ngClass]="field.type | extraFieldsIconPipe"></span>
                                </div>
                            </ng-template>
                        </en-dropdown>

                        <ng-container *ngIf="field.value.fieldName">
                            <ng-container [ngSwitch]="getParamType(field.value.fieldName)">
                                <en-multilevel-dropdown *ngSwitchCase="'date'"
                                                        class="multilevel-dropdown"
                                                        menuWidth="19.85rem"
                                                        [placeholder]="'scenario_block_destribution.select' | translate"
                                                        [options]="getOperatorOptions(field.value.fieldName)"
                                                        [disabled]="!isCreationMode"
                                                        [valueControl]="field.controls.operator"
                                                        (onChange)="resetValue(i)"></en-multilevel-dropdown>
                                <en-multilevel-dropdown *ngSwitchCase="'dateTime'"
                                                        class="multilevel-dropdown"
                                                        menuWidth="19.85rem"
                                                        [placeholder]="'scenario_block_destribution.select' | translate"
                                                        [options]="getOperatorOptions(field.value.fieldName)"
                                                        [disabled]="!isCreationMode"
                                                        [valueControl]="field.controls.operator"
                                                        (onChange)="resetValue(i)"></en-multilevel-dropdown>
                                <en-dropdown *ngSwitchCase="'bool'"
                                             styleClass="en-dropdown-solid"
                                             [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                             [options]="getOperatorOptions(field.value.fieldName)"
                                             [disabled]="!isCreationMode"
                                             formControlName="operator"
                                             (onChange)="resetValue(i)"></en-dropdown>
                                <en-dropdown *ngSwitchDefault
                                             styleClass="en-dropdown-solid"
                                             [placeholder]="'scenario_block_destribution.select' | translate"
                                             [options]="getOperatorOptions(field.value.fieldName)"
                                             [disabled]="!isCreationMode"
                                             formControlName="operator"
                                             (onChange)="resetValue(i)"></en-dropdown>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="field.value.fieldName &&
                                             field.value.operator &&
                                             !operatorsWithoutValue.includes(field.value.operator) &&
                                             field.value.operator !== 'between'">
                            <ng-container [ngSwitch]="getParamType(field.value.fieldName)">
                                <input *ngSwitchCase="'text'"
                                       enInput
                                       type="text"
                                       class="h32"
                                       [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                       [attr.disabled]="disableInput"
                                       formControlName="value">
                                <input *ngSwitchCase="'number'"
                                       enInput
                                       class="h32"
                                       [imask]="maskNumber"
                                       [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                       [attr.disabled]="disableInput"
                                       formControlName="value">
                                <input *ngSwitchCase="'float'"
                                       enInput
                                       class="h32"
                                       [imask]="maskFloat"
                                       [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                       [attr.disabled]="disableInput"
                                       formControlName="value">
                                <en-dropdown-calendar-new *ngSwitchDefault
                                                          [type]="getParamType(field.value.fieldName)"
                                                          [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                                          [disabled]="!isCreationMode"
                                                          formControlName="value"></en-dropdown-calendar-new>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="field.value.operator && field.value.operator === 'between'">
                            <en-dropdown-calendar-range class="range-dropdown"
                                                        [type]="getParamType(field.value.fieldName)"
                                                        appendTo="body"
                                                        [placeholder]="'scenario_block_data_change.field_placeholder' | translate"
                                                        [disabled]="!isCreationMode"
                                                        formControlName="value"></en-dropdown-calendar-range>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div *ngIf="isCreationMode">
                <button *ngIf="fieldControls.length < 10"
                        enButton
                        type="button"
                        class="add-field en-button-lowercase"
                        icon="en en-plus-circle-o"
                        iconColor="blue-button-icon"
                        iconPos="left"
                        [label]="'scenario_block_destribution.add_field' | translate"
                        (click)="addField()"></button>
                <span class="max-fields-desc">{{ 'scenario_block_destribution.max_fields' | translate }}</span>
            </div>
        </div>
    </ng-container>
</div>