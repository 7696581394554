import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnButtonModule } from 'ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarHotkeysComponent } from './sidebar-hotkeys.component';

@NgModule({
	imports: [CommonModule, EnButtonModule, TranslateModule],
	exports: [SidebarHotkeysComponent],
	declarations: [SidebarHotkeysComponent]
})
export class SidebarHotkeysModule {}
