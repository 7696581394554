import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ScenariosStore, ScenariosState } from './scenarios.store';

@Injectable({ providedIn: 'root' })
export class ScenariosQuery extends QueryEntity<ScenariosState> {
	constructor(protected store: ScenariosStore) {
		super(store);
	}
}
