import { ID } from '@datorama/akita';
import { FileAttachment } from '@enSend/message/_states/_state-message';
import { UrlParams } from './entity-params.model';

export type SplitTestLabel = 'A' | 'B' | 'C' | 'D' | 'E' | 'winner';
export interface MailEntity {
	id: ID;
	messageId: ID;
	status: string | undefined;

	fromEmail: string;
	fromName: string;
	subject: string;
	preheader: string;
	withAttachments: boolean;
	replyToName?: string;
	replyToEmail?: string;
	splitTestLabel?: SplitTestLabel;
	plainText: string;
	html: string;
	amp: string;
	stripoHtml: string;
	stripoCss: string;
	chamaileonLetter: any;
	trackClicks: boolean;
	manually: boolean;

	urlParams: UrlParams;
	attachments: FileAttachment[];
	fileAttachments: boolean;
}
