<span class="en-text-large">
    {{'scenario_block_finish.description' | translate}}
</span>
<div [formGroup]="form">
    <div class="select-action">
        <span class="en-text-medium option-header">
            {{ 'scenario_block_finish.action_label' | translate }}
        </span>
        <en-dropdown [options]="actionOptions"
                     styleClass="h36"
                     class="duplicate-handling__dropdown"
                     [disabled]="!isCreationMode"
                     formControlName="finishType"
                     (onChange)="changeDropdown()">
            <ng-template let-selected
                         pTemplate="selectedItem">
                <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
            </ng-template>
            <ng-template let-type
                         pTemplate="item">
                <div class="duplicate-handling__drop">
                    <span class="drop-label">{{type.label | translate}}</span>
                    <div class="drop-description">{{type.description | translate}}</div>
                </div>
            </ng-template>
        </en-dropdown>
        <span *ngIf="isDeleteMode"
              class="delete-description">
            {{'scenario_block_finish.delete_description' | translate}}
        </span>
    </div>
    <!-- Переподписка на рассылки -->
    <div class="select-block"
         *ngIf="isResubscribeMode">
        <span class="en-text-medium option-header">
            {{ 'scenario_block_finish.unsub_label' | translate }}
        </span>
        <en-multiSelect class="mailing-selector"
                        styleClass="en-dropdown-solid h36"
                        [options]="unsubscribeGroups"
                        [filter]="isCreationMode"
                        filterBy="label,value"
                        [emptyFilterMessage]="textPlaceholder | translate"
                        [placeholder]="textPlaceholder | translate"
                        [showToggleAll]="false"
                        [showCloseButton]="false"
                        [useSelectAllItem]="isAvailableUnsubscribeGroups"
                        [isInvalid]="markAsInvalid"
                        [useInputWidth80]="true"
                        [disabled]="!isCreationMode && unsubscribeGroups?.length === 0"
                        formControlName="toUnsub"
                        (onChange)="unsubscribeChange($event)">
            <ng-template pTemplate="selectedItems"
                         let-selectedItems>
                <div *ngIf="selectedItems?.length === 0 || !selectedItems">{{textPlaceholder | translate}}</div>
                <ng-container *ngIf="selectedItems?.length > 0">
                    <span>{{'scenario_block_finish.selected_placeholder' | translate:{value: selectedItems.length}
                        }}</span>
                </ng-container>
            </ng-template>
            <ng-template let-group
                         pTemplate="item">
                <div class="options-groups"
                     [tippy]="isCreationMode && group.disabled && ('scenario_block_finish.multiselect_tooltip' | translate)">
                    <span class="text-overflow">
                        <span class="id-badge"
                              style="margin-right: 0.5rem;">
                            id {{ group.value }}
                        </span>
                        <span class="text-overflow">{{ group.label }}</span>
                    </span>
                    <span>
                        <i [class]="group.channel | typeIcon"></i>
                    </span>
                </div>
            </ng-template>
        </en-multiSelect>
        <en-error formControlName="toUnsub"></en-error>
    </div>
    <div class="select-block"
         *ngIf="isResubscribeMode">
        <span class="en-text-medium option-header">
            {{ 'scenario_block_finish.sub_label' | translate }}
        </span>
        <en-multiSelect class="mailing-selector"
                        styleClass="en-dropdown-solid h36"
                        [options]="subscribeGroups"
                        [filter]="isCreationMode"
                        filterBy="label,value"
                        [emptyFilterMessage]="textPlaceholder | translate"
                        [placeholder]="textPlaceholder | translate"
                        [showToggleAll]="false"
                        [showCloseButton]="false"
                        [useSelectAllItem]="isAvailableSubscribeGroups"
                        [isInvalid]="markAsInvalid"
                        [useInputWidth80]="true"
                        [disabled]="!isCreationMode && subscribeGroups?.length === 0"
                        formControlName="toSub"
                        (onChange)="subscribeChange($event)">
            <ng-template pTemplate="selectedItems"
                         let-selectedItems>
                <div *ngIf="selectedItems?.length === 0 || !selectedItems">{{textPlaceholder | translate}}</div>
                <ng-container *ngIf="selectedItems?.length > 0">
                    <span>{{'scenario_block_finish.selected_placeholder' | translate:{value: selectedItems.length}
                        }}</span>
                </ng-container>
            </ng-template>
            <ng-template let-group
                         pTemplate="item">
                <div class="options-groups"
                     [tippy]="isCreationMode && group.disabled && ('scenario_block_finish.multiselect_tooltip' | translate)">
                    <span class="text-overflow">
                        <span class="id-badge"
                              style="margin-right: 0.5rem;">
                            id {{ group.value }}
                        </span>
                        <span class="text-overflow">{{ group.label }}</span>
                    </span>
                    <span>
                        <i [class]="group.channel | typeIcon"></i>
                    </span>
                </div>
            </ng-template>
        </en-multiSelect>
        <en-error formControlName="toSub"></en-error>

        <div class="select-block">
            <span class="en-text-medium option-header">
                {{ 'scenario_block_finish.settings_label' | translate }}
            </span>
            <en-dropdown [options]="subscribeOptions"
                         styleClass="h36"
                         [disabled]="!isCreationMode"
                         formControlName="subscriptionType"
                         (onChange)="changeDropdown()">
                <ng-template let-selected
                             pTemplate="selectedItem">
                    <div class="select-block__selection-text text-overflow">{{selected.label | translate}}</div>
                </ng-template>
                <ng-template let-type
                             pTemplate="item">
                    <span class="select-block__item select-block__item_long">{{type.label | translate}}</span>
                </ng-template>
            </en-dropdown>
        </div>
    </div>
</div>