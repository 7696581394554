import { MessageTypeChoose } from '../../kit';

export const TYPE_CHOOSE_CONFIG: MessageTypeChoose[] = [
	{
		value: 'mail',
		name: 'channels_name.email',
		icon: 'en-email',
		isDisabled: false
	},

	{
		value: 'push',
		name: 'channels_name.push',
		icon: 'en-push',
		isDisabled: false
	},
	{
		value: 'sms',
		name: 'channels_name.sms',
		icon: 'en-sms',
		isDisabled: false
	},
	{
		value: 'mobPush',
		name: 'channels_name.mobPush',
		icon: 'en-mobpush',
		isDisabled: false
	},
	{
		value: 'telegram',
		name: 'channels_name.telegram',
		icon: 'en-telegram',
		isDisabled: true
	},
	{
		value: 'whatsapp',
		name: 'channels_name.whatsapp',
		icon: 'en-whatsapp',
		isDisabled: false
	}
];
