import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	CalculationProgressModel,
	ChangeSegmentInfoModel,
	DashboardModel,
	RfSegmentModel,
	SegmentsSettingsModel
} from './rfm.model';

@Injectable({ providedIn: 'root' })
export class RfmDataService {
	constructor(private http: HttpClient) {}

	getCalculationProgress(): Observable<CalculationProgressModel> {
		return this.http.get<CalculationProgressModel>('enkod/rfm/progress/');
	}

	getSegmentList(): Observable<RfSegmentModel[]> {
		return this.http.get<RfSegmentModel[]>('enkod/rfm/');
	}

	getSegmentNames(): Observable<Record<string, string>> {
		return this.http.get<Record<string, string>>(
			'settings/rfm/segments/names/'
		);
	}

	getSegmentsSettings(): Observable<SegmentsSettingsModel> {
		return this.http.get<SegmentsSettingsModel>('settings/rfm/segments/');
	}

	getCardList(): Observable<DashboardModel> {
		return this.http.get<DashboardModel>('settings/rfm/info/');
	}

	startCalculation(): Observable<void> {
		return this.http.get<void>('enkod/rfm/count/');
	}

	stopCalculation(): Observable<void> {
		return this.http.get<void>('enkod/rfm/stop/');
	}

	changeSegmentFields(data: ChangeSegmentInfoModel): Observable<void> {
		return this.http.put<void>('settings/rfm/segment/', data);
	}

	changeSegmentsSettings(
		data: SegmentsSettingsModel
	): Observable<SegmentsSettingsModel> {
		return this.http.put<SegmentsSettingsModel>(
			'settings/rfm/segments/',
			data
		);
	}
}
