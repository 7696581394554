import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
	TimeRangeForm,
	VisitsConditionForm
} from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';

@Component({
	selector: 'en-visits-count',
	templateUrl: './visits-count.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitsCountComponent extends AbstractCondition {
	get actionTime(): FormGroup<TimeRangeForm> {
		return (this.form as FormGroup<VisitsConditionForm>).controls
			.actionTime as FormGroup<TimeRangeForm>;
	}
}
