import {
	Component,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'en-condition-tools',
	template: `
		<div class="options__remove">
			<button
				*ngIf="isEnSendSegment"
				enButton
				class="en-button-lowercase h32 options__icon icon"
				type="button"
				[icon]="'en ' + (isHide ? 'en-eye-close' : 'en-eye')"
				(click)="hideCondition.emit(index)"
			></button>
			<button
				class="en-button-lowercase h32 options__icon icon"
				type="button"
				enButton
				icon="en en-copy"
				(click)="copyCondition.emit(index)"
			></button>
			<button
				class="en-button-lowercase h32 options__icon icon"
				type="button"
				enButton
				icon="en en-trash"
				(click)="removeCondition.emit(index)"
			></button>
		</div>
	`,
	styleUrls: ['./condition-tools.component.scss'],
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionToolsComponent {
	constructor(private route: ActivatedRoute) {}

	@Input() index: number;
	@Input() isHide: boolean;

	@Output() removeCondition = new EventEmitter<number>();
	@Output() copyCondition = new EventEmitter<number>();
	@Output() hideCondition = new EventEmitter<number>();

	get isEnSendSegment() {
		return this.route.snapshot.queryParams.location === 'enSend';
	}
}
