import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RfSegmentModel, SegmentsSettingsModel } from './rfm.model';

export interface RfmState extends EntityState<RfSegmentModel> {
	segmentsSettings: SegmentsSettingsModel;
}

const initialState = {
	bordersSettings: {
		recency: { r1: '0', r2: '0', r3: '0', r4: '0', r5: '0' },
		frequency: { f1: '0', f2: '0', f3: '0', f4: '0', f5: '0' },
		monetary: { m1: '0', m2: '0', m3: '0', m4: '0', m5: '0' }
	},
	recountFrequency: 14
};

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'rfm-segments'
})
export class RfmStrore extends EntityStore<RfmState> {
	constructor() {
		super(initialState);
	}
}
