import { Injectable } from '@angular/core';
import { ExpandedQuery } from '@enkod-core/abstract';
import { ALL_LIST_STORE_NAME } from '@enkod-core/constants';
import { SegmentsStore, SegmentsState } from './segments.store';

@Injectable({ providedIn: 'root' })
export class SegmentsQuery extends ExpandedQuery<SegmentsState> {
	constructor(protected store: SegmentsStore) {
		super(store);
	}

	readonly subscribersQuantity$ = this.select(
		store => store.subscribersQuantity
	);

	readonly countLoading$ = this.select(store => store.countLoading);

	readonly allSegments$ = this.select(ALL_LIST_STORE_NAME);
}
