import { Scenario } from '@enSend/scenario/_state';
import { splitCells } from '@enSend/_shared/utilits';
import { FormModel } from '../models';

export class ScenarioFormDTO {
	formModel: FormModel;

	constructor(value: any) {
		Object.assign(this, { formModel: value });
	}

	getValue(): Scenario {
		const { id, name, status, creationDate, isValid, cells } =
			this.formModel;

		return {
			id,
			name,
			status,
			creationDate,
			isValid,
			...splitCells(cells.cells)
		};
	}
}
