import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@enkod-core/components';
import { EnButtonModule, EnInputModule, ErrorModule } from 'ui-lib';
import { EditableModule, EDITABLE_CONFIG } from '@ngneat/edit-in-place';
import { ReactiveFormsModule } from '@angular/forms';
import {
	VALIDATION_PROVIDER,
	FORCE_RERENDER_PROVIDER
} from '@enkod-core/tokens';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'primeng/sidebar';
import { CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';
import { InspectorModule } from './inspector/inspector.module';

import {
	EventBusService,
	InspectorService,
	TopBarService,
	RappidService
} from './services';

import { CanvaComponent } from './canva/canva.component';
import { ScenarioTopBarComponent } from './canva/scenario-top-bar/scenario-top-bar.component';
import { SidebarHotkeysModule } from './canva/sidebar-hotkeys/sidebar-hotkeys.module';
import { ChatbotTopBarComponent } from './canva/chatbot-top-bar/chatbot-top-bar.component';
import { BottomBarModule } from '../bottom-bar/bottom-bar.module';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		InspectorModule,
		SidebarHotkeysModule,
		EnInputModule,
		TuiScrollbarModule,
		EditableModule,
		ErrorModule,
		TippyModule,
		TranslateModule,
		EnButtonModule,
		SidebarModule,
		BottomBarModule
	],
	declarations: [
		CanvaComponent,
		ScenarioTopBarComponent,
		ChatbotTopBarComponent
	],
	exports: [CanvaComponent],
	providers: [
		EventBusService,
		TopBarService,
		InspectorService,
		RappidService,
		BottomBarModule,
		{
			provide: EDITABLE_CONFIG,
			useValue: {
				openBindingEvent: 'click',
				closeBindingEvent: 'click'
			}
		},
		VALIDATION_PROVIDER,
		FORCE_RERENDER_PROVIDER,
		{
			provide: CANVA_MODE_TOKEN,
			useValue: 'create'
		}
	]
})
export class ScenarioCanvaModule {}
