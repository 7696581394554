import {
	Component,
	ChangeDetectionStrategy,
	Input,
	EventEmitter,
	Output
} from '@angular/core';

@Component({
	selector: 'en-contacts-limit-dialog',
	templateUrl: './contacts-limit-dialog.component.html',
	styleUrls: ['./contacts-limit-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsLimitDialogComponent {
	@Input() limitVisible: boolean;

	@Input() set limitValue(value: number) {
		this._limitValue = value;
	}

	@Output() closeLimit = new EventEmitter<void>();

	@Output() onSubmit = new EventEmitter<number>();

	private _limitValue: number;

	get limitValue(): number {
		return this._limitValue;
	}

	clearLimit(): void {
		this._limitValue = 0;
	}
}
