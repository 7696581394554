<p-sidebar [(visible)]="visible"
           [fullScreen]="true"
           [showCloseIcon]="false"
           [closeOnEscape]="false"
           appendTo="body"
           (onHide)="closeDialog()">
    <div class="header">
        <div class="header__title">{{'scenario_block_api_query.request_body'| translate}}</div>

        <div>
            <button class="h32 en-button-secondary en-mr-4"
                    type="button"
                    enButton
                    [label]="'scenario_block_api_query.cancel'| translate"
                    (click)="closeDialog()">
            </button>
            <button type="button"
                    class="h32"
                    enButton
                    [label]="'scenario_block_api_query.save'| translate"
                    [disabled]="!isCreationMode"
                    (click)="confirm()">
            </button>
        </div>
    </div>

    <div class="content">
        <div class="content__editor editor">
            <div class="editor__canva">
                <ngx-codemirror #codemirror
                                [formControl]="jsonDialogControl"
                                [options]="options"
                                (focusChange)="setPosition()">
                </ngx-codemirror>
            </div>

            <div class="editor__footer">
                <button enButton
                        type="button"
                        class="en-button-secondary h24 en-mr-3"
                        [label]="'dc_manager.button_open' | translate"
                        [disabled]="!isCreationMode"
                        (click)="openDynamicContent = true">
                </button>
            </div>
        </div>
    </div>
</p-sidebar>

<en-dynamic-content *ngIf="openDynamicContent"
                    location="apiQueryBlock"
                    [openDynamicContent]="openDynamicContent"
                    (onHide)="openDynamicContent = false"
                    [hasPosition]="hasPosition"
                    (selectedContent)="updateJson($event)"></en-dynamic-content>