<en-dialog [modal]="true"
           [(visible)]="isVisible"
           [draggable]="false"
           [style]="{ maxWidth: '37.5rem', width: '100%', maxHeight: '38.75rem', height: '100%' }"
           [header]="'message_wizard_edit_step.test_sending' | translate"
           (onHide)="handleClose($event)">


    <div class="test-sending">
        <tui-scrollbar>
            <div class="test-sending__body">
                <div class="test-sending__main">
                    <ng-container [ngSwitch]="testChannelType">
                        <ng-template ngSwitchCase="mobpush">
                            <en-test-mobpush class="test-sending__control"
                                             [selectedSystem]="selectedSystem"
                                             [tokenValue]="tokenValue"
                                             (onChange)="handleChange($event)"></en-test-mobpush>
                        </ng-template>
                    </ng-container>

                    <div>
                        <p class="test-sending-title en-text-medium font-weight-500">
                           {{ 'message_wizard_edit_step.history_of_test_submissions' | translate }}
                        </p>
                        <p class="test-sending-text">
                           {{ 'message_wizard_edit_step.test_sending_statistics' | translate }}
                        </p>
                    </div>
                </div>

                <ng-container *ngIf="isEmpty; else list">
                    <p class="test-sending__empty test-sending-text">
                       {{ 'message_wizard_edit_step.empty_list' | translate }}
                    </p>
                </ng-container>

                <ng-template #list>
                    <ul class="test-sending__list">
                        <li
                            *ngFor="let item of statistics"
                            class="test-sending__item item">
                            <div class="item__col">
                                <p *ngIf="item?.system"
                                   class="font-weight-500 en-text-small">
                                   {{ item.system }}
                                </p>
                                <p class="item__contact">
                                   {{ item.contact }}
                                </p>
                                <p class="item__error en-text-small">
                                   {{ item.errorText | translate }}
                                </p>
                            </div>
                            <en-status-tag [status]="item.status | testSendingStatusVariant"
                                           [showIcon]="false"
                                           [showLoader]="true"
                                           [label]="item.status | testSendingStatusText | translate"></en-status-tag>
                        </li>
                    </ul>
                </ng-template>
            </div>
        </tui-scrollbar>
    </div>

    <p-footer>
        <button enButton
                class="h36 en-mr-3 en-button-secondary"
                [label]="'common.cancel' | translate"
                (click)="cancel()"></button>
        <button enButton
                class="h36 en-button-secondary"
                [disabled]="isDisabled"
                [label]="'common.send' | translate"
                (click)="testSending()"></button>
    </p-footer>

</en-dialog>
