import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable()
export class UnitsDeclinationService {
	getCaseUnits(
		control: FormControl,
		isPastTime: boolean,
		useShortTime?: boolean,
		useLongTime?: boolean
	) {
		const { value } = control;
		const allUnits = [
			{
				label: this.getCaseLabel(value, isPastTime, [
					'common.second',
					'pluralization.seconds',
					'pluralization.seconds_third'
				]),
				value: 'second'
			},
			{
				label: this.getCaseLabel(value, isPastTime, [
					'common.minute',
					'common.minutes',
					'common.minutes_genitive'
				]),
				value: 'minute'
			},
			{
				label: this.getCaseLabel(value, isPastTime, [
					'common.hour',
					'common.hour_genitive',
					'common.hours_genitive'
				]),
				value: 'hour'
			},
			{
				label: this.getCaseLabel(value, isPastTime, [
					'common.day',
					'common.day_genitive',
					'common.days_genitive'
				]),
				value: 'day'
			},
			{
				label: this.getCaseLabel(value, isPastTime, [
					'common.month',
					'common.month_genitive',
					'common.months_genitive'
				]),
				value: 'month'
			},
			{
				label: this.getCaseLabel(value, isPastTime, [
					'common.year',
					'common.year_genitive',
					'common.years_genitive'
				]),
				value: 'year'
			}
		];

		if (useShortTime) {
			return allUnits.filter(
				unit =>
					unit.value === 'minute' ||
					unit.value === 'second' ||
					unit.value === 'hour'
			);
		}

		if (useLongTime) {
			return allUnits.filter(
				unit =>
					unit.value === 'day' ||
					unit.value === 'month' ||
					unit.value === 'year'
			);
		}

		return allUnits;
	}

	private getCaseLabel(n: number, isPastTime: boolean, unitsPull: string[]) {
		const num = Math.abs(n) % 100;
		const n1 = n % 10;
		if (num > 10 && n < 20) {
			return unitsPull[2];
		}
		if (n1 > 1 && n1 < 5) {
			return isPastTime ? unitsPull[2] : unitsPull[1];
		}
		if (n1 === 1) {
			return isPastTime ? unitsPull[1] : unitsPull[0];
		}
		return unitsPull[2];
	}
}
