import {
	Component,
	ChangeDetectionStrategy,
	Inject,
	Output,
	EventEmitter,
	Input
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BounceType } from '@state-enKod/segments';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';
import { SelectItem } from 'primeng/api';
import { BounceTypes } from '../../../common/models';
import { BOUNCE_TYPE_OPTIONS } from './bounce-type-options';

@UntilDestroy()
@Component({
	selector: 'en-message-add-bounce',
	templateUrl: './message-add-bounce.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: BOUNCE_TYPE_OPTIONS
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageAddBounceComponent {
	bounceType = new FormControl('any');

	isHover = false;

	filtredOptions: SelectItem[] = this.bounceTypeOptions;

	@Input()
	set channelType(value: string) {
		switch (value) {
			case 'email':
				this.filtredOptions = this.bounceTypeOptions.filter(
					option => option.value !== 'any'
				);
				break;
			default:
				this.filtredOptions = this.bounceTypeOptions;
				break;
		}
	}

	constructor(
		@Inject(NgControl)
		private ngControl: NgControl,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly bounceTypeOptions: SelectItem<BounceTypes>[]
	) {
		this.ngControl.valueAccessor = this;
		this.setupControlListener();
	}

	@Output() remove = new EventEmitter<void>();

	private onChange: Function = EMPTY_FUNCTION;

	writeValue(value: BounceType) {
		this.bounceType.patchValue(value);
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched() {}

	private setupControlListener(): void {
		this.bounceType.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => this.onChange(value));
	}
}
