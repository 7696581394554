/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable @typescript-eslint/naming-convention */

import { ID } from '@datorama/akita';
import { MessageType } from '@enSend/message/kit';
import { CommonWizardDTO } from '@enSend/message/message-wizard/kit';
import { MailingGroup } from '@state-enKod/mailing-groups';
import { WhatsappParamsDTO } from '@enSend/_shared/classes';
import {
	WaTemplateParamsDTO,
	WaTemplateParamsOTD
} from '@enSend/message/whatsapp-template-wizard/models';
import { SPLIT_TEST_LABEL } from './constants';
import {
	WizardFormValue,
	MailFormEntity,
	MailEntity,
	PushEntity,
	PushFormEntity,
	SendingType,
	SendSettings,
	ISegments,
	MobPushEntity,
	MobPushFormEntity,
	SmsFormEntity,
	Utm
} from './models';
import { SmsEntity } from './models/sms-entity.model';

// из формы в объект
export class WizardDTO implements CommonWizardDTO {
	id: ID;
	type: MessageType;
	sendingType: SendingType;
	sendingSettings: {};
	splitTestSettings?: {};
	importInfo: { id?: number };
	mails?: MailEntity[];
	pushes?: PushEntity[];
	mobPushes?: MobPushEntity[];
	sms?: SmsEntity[];
	whatsappTemplateId?: number;
	whatsappParams?: WaTemplateParamsDTO;
	segments: ISegments[];
	groups: MailingGroup[];
	exceptSegments: ISegments[];
	name: string;
	tags: string[];
	isSplitTest: boolean;
	isDraft?: boolean;

	constructor(formValue: WizardFormValue) {
		const { id, title, type, step_1, step_2, step_3, step_4 } = formValue;

		this.id = id;

		this.type = type;

		this.sendingType = step_1.sendingType;

		'id' in step_4.contactsFile
			? (this.importInfo = { id: step_4.contactsFile?.id })
			: (this.importInfo = {});

		switch (this.sendingType) {
			case 'sendnow':
				break;

			case 'sendlate':
				this.sendingSettings = { ...step_1.sendlate };
				break;

			case 'regular':
				this.sendingSettings = { ...step_1.regular };
				break;

			case 'event':
				this.sendingSettings = { ...step_1.event };
				break;

			case 'api':
				this.sendingSettings = { ...step_1.api };
				break;

			case 'doi':
				this.sendingSettings = { ...step_1.doi };
				break;

			default:
				break;
		}

		switch (this.type) {
			case 'mail':
				if (step_2.mails) {
					this.mails = [
						...this.getMails(step_2.mails, step_2.status)
					];
					this.name = title.length ? title : this.mails[0]?.subject;
				}
				break;
			case 'push':
				if (step_2.pushes) {
					this.pushes = [...this.getPush(step_2.pushes)];
					this.name = title.length ? title : this.pushes[0].subject;
				}
				break;

			case 'mobPush':
				if (step_2.mobPushes) {
					this.mobPushes = [...this.getMobPush(step_2.mobPushes)];
					this.name = title.length
						? title
						: this.mobPushes[0].subject;
				}
				break;

			case 'sms':
				if (step_2.sms) {
					this.sms = [...this.getSms(step_2.sms)] as any;
					this.name = title.length ? title : step_2.sms[0].body;
				}
				break;

			case 'whatsapp':
				if (step_2.whatsapp.whatsappTemplateId)
					this.whatsappTemplateId =
						step_2.whatsapp.whatsappTemplateId;
				if (step_2.whatsapp.whatsappParams)
					this.whatsappParams = this.getWAParams(
						step_2.whatsapp.whatsappParams,
						step_2.whatsapp.titleType
					);
				this.name = step_2.whatsapp.name || '';
				break;
			default:
				break;
		}

		if (this.mails && this.mails.length > 1) {
			this.isSplitTest = true;
			this.splitTestSettings = { ...step_3 };
			const splitLabel = SPLIT_TEST_LABEL;
			this.mails.forEach((mail, i) => {
				// eslint-disable-next-line no-param-reassign
				mail.splitTestLabel = splitLabel[i];
			});
		} else {
			this.isSplitTest = false;
			delete this.splitTestSettings;
		}
		this.tags = [...step_2.tags];
		this.segments = [...step_4.segments];
		this.exceptSegments = [...step_4.exceptSegments];
		switch (this.sendingType) {
			case 'regular':
			case 'sendlate':
			case 'sendnow':
				this.groups = [...step_4.mailingGroups];
				break;
			default:
				break;
		}
	}

	private getWAParams(
		templateParams: WaTemplateParamsOTD,
		titleType?: string
	) {
		return new WhatsappParamsDTO(templateParams, titleType).getValue();
	}

	private getSms(messages: SmsFormEntity[]) {
		return messages.map(sms => ({
			...this.deleteUtm<SmsFormEntity>(sms),
			urlParams: {
				params: sms.urlParams,
				utm: sms.utm
			}
		}));
	}

	private deleteUtm<T extends { utm?: Utm }>(entity: T): T {
		const copyEntity = { ...entity };
		delete copyEntity.utm;
		return copyEntity;
	}

	private getMobPush(messages: MobPushFormEntity[]) {
		return messages.map(mobPush => {
			const mobPushEntity: MobPushEntity = {
				id: 0,
				messageId: 0,

				subject: '',
				body: '',
				action: 'url',
				smallImage: '',
				image: '',
				appName: '',
				buttons: [],

				urlParams: {
					params: {},
					utm: {}
				}
			};

			(Object.keys(mobPush) as (keyof MobPushFormEntity)[]).forEach(
				key => {
					switch (key) {
						case 'urlParams':
							mobPushEntity.urlParams.params = mobPush.urlParams;
							break;

						case 'utm':
							mobPushEntity.urlParams.utm = mobPush.utm;
							break;

						case 'id':
						case 'messageId':
							mobPushEntity[key] = mobPush[key];
							break;

						case 'linkRef':
							if (mobPush.linkRef.link !== undefined)
								mobPushEntity.link = mobPush.linkRef.link;
							mobPushEntity.action = mobPush.linkRef.action;
							break;

						case 'buttons':
							mobPush.buttons.forEach(button => {
								mobPushEntity.buttons.push({
									label: button.label,
									...button.linkRef
								});
							});
							break;

						default:
							mobPushEntity[key] = mobPush[key];
							break;
					}
				}
			);
			return mobPushEntity;
		});
	}

	private getPush(messages: PushFormEntity[]) {
		return messages.map(push => {
			const pushEntity: PushEntity = {
				id: 0,
				messageId: 0,

				subject: '',
				body: '',
				icon: '',
				clickAction: '',
				timeToLive: 259200,

				urlParams: {
					params: {},
					utm: {}
				}
			};

			(Object.keys(push) as (keyof PushFormEntity)[]).forEach(key => {
				switch (key) {
					case 'urlParams':
						pushEntity.urlParams.params = push.urlParams;
						break;

					case 'utm':
						pushEntity.urlParams.utm = push.utm;
						break;

					case 'id':
					case 'messageId':
						pushEntity[key] = push[key];
						break;

					case 'timeToLive':
						pushEntity[key] = Number(push[key]);
						break;

					default:
						pushEntity[key] = push[key];
						break;
				}
			});
			return pushEntity;
		});
	}
	private getMails(messages: MailFormEntity[], status: string | undefined) {
		return messages.map(mail => {
			const mailWithStatus = { ...mail };
			mailWithStatus.status = '';
			const mailEntity: MailEntity = {
				id: 0,
				messageId: 0,
				status: '',

				fromEmail: '',
				fromName: '',
				subject: '',
				preheader: '',
				withAttachments: false,

				plainText: '',
				html: '',
				amp: '',
				stripoHtml: '',
				stripoCss: '',
				trackClicks: false,
				manually: false,

				chamaileonLetter: {
					jsonBody: {}
				},

				urlParams: {
					params: {},
					utm: {}
				},

				attachments: [],
				fileAttachments: false
			};

			(Object.keys(mail) as (keyof MailFormEntity)[]).forEach(key => {
				switch (key) {
					case 'urlParams':
						mailEntity.urlParams.params = mail.urlParams;
						break;

					case 'utm':
						mailEntity.urlParams.utm = mail.utm;
						break;

					case 'attachments':
						mailEntity.attachments = mail.attachments;
						break;

					case 'fileAttachments':
						mailEntity.fileAttachments =
							mail.attachments?.length > 0;
						break;

					case 'status':
						mailEntity.status = status;
						break;

					case 'id':
					case 'messageId':
						mailEntity[key] = mail[key];
						break;

					case 'trackClicks':
						mailEntity.trackClicks = mail.trackClicks;
						break;

					case 'manually':
						mailEntity.manually = mail.manually;
						break;

					case 'sendSettings':
						(
							Object.keys(
								mail.sendSettings
							) as (keyof SendSettings)[]
						).forEach(key => {
							switch (key) {
								case 'replyToEmail':
									mailEntity.replyToEmail =
										mail.sendSettings.replyToEmail;
									break;
								case 'replyToName':
									mailEntity.replyToName =
										mail.sendSettings.replyToName;
									break;

								case 'withAttachments':
									mailEntity.withAttachments =
										mail.sendSettings.withAttachments;
									break;

								default:
									mailEntity[key] = mail.sendSettings[key];
									break;
							}
						});
						break;

					default:
						mailEntity[key] = mail[key] as string;
						break;
				}
			});
			return mailEntity;
		}) as MailEntity[];
	}
}
