import {
	Component,
	ChangeDetectionStrategy,
	OnInit,
	ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs/operators';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import {
	ActionTimeForm,
	ComparisonConditionForm
} from '@enKod/segments/segments-form.model';

import { AbstractCondition } from '../../../abstract-condition.component';
import { PARAMETER_OPTIONS, TIME_OPTIONS } from './constants';

@UntilDestroy()
@Component({
	selector: 'en-comparison-condition',
	templateUrl: './comparison-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonConditionComponent
	extends AbstractCondition
	implements OnInit
{
	parameterOptions = PARAMETER_OPTIONS;
	timeOptions = TIME_OPTIONS;
	isFirstLoad = true;

	constructor(
		public validateServices: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(validateServices, cd);
	}

	get typedForm(): FormGroup<ComparisonConditionForm> {
		return this.form as FormGroup<ComparisonConditionForm>;
	}

	get parameterValue(): string {
		return this.typedForm.controls.parameter.value;
	}

	get actionTimeForm(): FormGroup<ActionTimeForm> {
		return this.typedForm.controls.actionTime as FormGroup<ActionTimeForm>;
	}

	get parameterControl(): FormControl<string> {
		return this.typedForm.controls.parameter as FormControl<string>;
	}

	get operatorControl(): FormControl<string> {
		return this.typedForm.controls.operator as FormControl<string>;
	}

	get valueControl(): FormControl<string | number> {
		return this.typedForm.controls.value as FormControl<string | number>;
	}

	ngOnInit(): void {
		this.checkControls(this.parameterControl.value);
		this.setListeners();
	}

	private setListeners(): void {
		this.parameterControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => this.checkControls(value))
			)
			.subscribe();

		this.validateServices.checkedValidate
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent)
						this.form.markAsPristine();
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private checkControls(parameter: string) {
		switch (parameter) {
			case 'id':
				this.valueControl.enable();
				this.operatorControl.disable();
				if (!this.isFirstLoad) this.valueControl.patchValue('');
				break;
			case 'quantity':
				this.valueControl.enable();
				this.operatorControl.enable();
				if (!this.isFirstLoad) this.valueControl.patchValue(1);
				break;
			default:
				this.valueControl.disable();
				this.operatorControl.disable();
				break;
		}
		this.isFirstLoad = false;
	}
}
