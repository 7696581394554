import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Inject,
	ChangeDetectorRef
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { MailingGroupsService } from '@state-enKod/mailing-groups';
import { TranslateService } from '@ngx-translate/core';
import { markAllAsDirty } from '@enkod-core/utils';
import { AbstractInspector } from '../../abstract';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { ACTION_OPTIONS, SUBSCRIBE_OPTIONS } from './finish-options';
import { mailingGroupValidator } from '../mailing-group/validators';

interface SelectGroupsItem extends SelectItem {
	channel: string;
	disabled?: boolean;
}
@UntilDestroy()
@Component({
	selector: 'en-finish',
	templateUrl: './finish.component.html',
	styleUrls: ['./finish.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinishComponent extends AbstractInspector implements OnInit {
	readonly actionOptions: SelectItem[] = ACTION_OPTIONS;
	readonly subscribeOptions: SelectItem[] = SUBSCRIBE_OPTIONS;

	form: UntypedFormGroup = this.fb.group({
		finishType: 'nothing',
		toUnsub: [[], mailingGroupValidator],
		toSub: [[], mailingGroupValidator],
		subscriptionType: 'all'
	});

	unsubscribeGroups: SelectGroupsItem[];
	subscribeGroups: SelectGroupsItem[];
	allGroups: SelectGroupsItem[];

	constructor(
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		@Inject(CANVA_MODE_TOKEN)
		private canvaMode: CanvaModeType,
		private fb: UntypedFormBuilder,
		private mailingGroupsService: MailingGroupsService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService
	) {
		super();
	}

	get finishTypeControl(): UntypedFormControl {
		return this.form.get('finishType') as UntypedFormControl;
	}

	get isCreationMode(): boolean {
		return this.canvaMode === 'create';
	}

	get isResubscribeMode(): boolean {
		return this.form.get('finishType')?.value === 'resub';
	}

	get isDeleteMode(): boolean {
		return this.form.get('finishType')?.value === 'remove';
	}

	get isNothingMode(): boolean {
		return this.form.get('finishType')?.value === 'nothing';
	}

	get isNotValid(): boolean {
		return (
			this.isResubscribeMode &&
			!this.form.get('toUnsub')?.value.length &&
			!this.form.get('toSub')?.value.length
		);
	}

	get isAvailableUnsubscribeGroups(): boolean {
		const noDisabled = this.unsubscribeGroups?.filter(
			group => group.disabled !== true
		);
		return noDisabled?.length > 0;
	}

	get isAvailableSubscribeGroups(): boolean {
		const noDisabled = this.subscribeGroups?.filter(
			group => group.disabled !== true
		);
		return noDisabled?.length > 0;
	}

	get textPlaceholder() {
		if (this.isCreationMode) {
			return 'scenario_block_finish.select_placeholder';
		}
		return 'scenario_block_finish.select_placeholder_preview';
	}

	ngOnInit() {
		this.context$
			.pipe(
				untilDestroyed(this),
				// eslint-disable-next-line no-undef
				tap(context => {
					// костыльное условие
					// событие происходит быстрее чем уничтожается компонент
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;

						const options = this.cell.get('options');
						const subType = this.cell.get('subType');
						const { params } = options[subType];

						const paramsForPatch = params.length
							? params[0]
							: {
									finishType: 'nothing',
									toUnsub: [],
									toSub: [],
									subscriptionType: 'all'
							  };

						this.form.patchValue(paramsForPatch);

						if (params.length) {
							this.changeForm();
							this.updateCell();
						}

						const enableMarkAsDirty =
							('isValid' in options && !options.isValid) ||
							this.cell.attributes.invalid;
						if (enableMarkAsDirty) {
							markAllAsDirty(this.form);
						}
					}
				})
			)
			.subscribe();

		this.mailingGroupsService
			.getAllList()
			.pipe(
				map(list =>
					list.map(group => ({
						label: group.name,
						value: group.id,
						channel: group.channel
					}))
				)
			)
			.subscribe(list => {
				this.allGroups = list;
				this.subscribeGroups = this.getItemsWithDisabled(
					this.form.get('toUnsub')?.value
				);
				this.unsubscribeGroups = this.getItemsWithDisabled(
					this.form.get('toSub')?.value
				);
				if (!this.isCreationMode) {
					this.subscribeGroups = this.getItemsSelected(
						this.form.get('toSub')?.value
					);
					this.unsubscribeGroups = this.getItemsSelected(
						this.form.get('toUnsub')?.value
					);
				}
				this.cd.markForCheck();
			});

		this.finishTypeControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(() => {
					this.changeForm();
				})
			)
			.subscribe();
	}

	changeDropdown() {
		this.updateCell();
	}

	unsubscribeChange(event: { value: number[] }) {
		this.subscribeGroups = this.getItemsWithDisabled(event.value);
		this.updateCell();
		if (!this.isNotValid) {
			this.form.markAsPristine();
		}
	}

	subscribeChange(event: { value: number[] }) {
		this.unsubscribeGroups = this.getItemsWithDisabled(event.value);
		this.updateCell();
		if (!this.isNotValid) {
			this.form.markAsPristine();
		}
	}

	private updateCell() {
		const label = this.getLabel();

		this.changeCellProp('options', {
			isValid: !this.isNotValid,
			finish: {
				params: this.isNothingMode
					? []
					: [{ ...this.form.value, label }]
			}
		});
	}

	private getItemsWithDisabled(selected: number[]): SelectGroupsItem[] {
		let result = this.allGroups;
		selected.forEach(value => {
			result = result.map(group => {
				if (value === group.value)
					return {
						value: group.value,
						label: group.label,
						channel: group.channel,
						disabled: true
					};
				return group;
			});
		});
		return result;
	}

	private getItemsSelected(selected: number[]): SelectGroupsItem[] {
		const result = this.allGroups;
		const newResult: SelectGroupsItem[] = [];
		selected.forEach(value => {
			result.forEach(group => {
				if (value === group.value) {
					newResult.push({
						value: group.value,
						label: group.label,
						channel: group.channel,
						disabled: true
					});
				}
			});
		});
		return newResult;
	}

	private getLabel(): string | null {
		switch (true) {
			case this.isResubscribeMode:
				return this.translate.instant(
					'scenario_block_finish.resub_action'
				);
			case this.isDeleteMode:
				return this.translate.instant(
					'scenario_block_finish.remove_action'
				);
			default:
				return null;
		}
	}

	private changeForm(): void {
		if (!this.isCreationMode) return;
		switch (this.finishTypeControl.value) {
			case 'resub':
				this.form.get('toUnsub')?.enable();
				this.form.get('toSub')?.enable();
				this.form.get('subscriptionType')?.enable();
				break;
			case 'remove':
			case 'nothing':
				this.form.get('toUnsub')?.disable();
				this.form.get('toSub')?.disable();
				this.form.get('subscriptionType')?.disable();
				break;
			default:
				break;
		}
	}
}
