import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { SelectedViewMobileSms } from '../message-preview.component';

type ViewSettings = Record<SelectedViewMobileSms, any>;

const SMS_ANDROID_PREVIEW_SETTINGS = {
	topIframe: 50,
	heightPreview: 460,
	maxHeightIframe: 658
};

const SMS_IOS_PREVIEW_SETTINGS = {
	topIframe: 18,
	heightPreview: 460,
	maxHeightIframe: 698
};

@Injectable()
export class SmsPreviewService {
	isDetail = false;
	fullScreenPhone = false;
	transformSlide = 'translate3d(0%, 0px, 0px)';

	viewSettings: ViewSettings = {
		android: { ...SMS_ANDROID_PREVIEW_SETTINGS },
		ios: { ...SMS_IOS_PREVIEW_SETTINGS }
	};

	private view: SelectedViewMobileSms;
	private iframeElementRef: ElementRef;
	private renderer: Renderer2;

	get activeViewsSettings() {
		return this.viewSettings[this.view];
	}

	setView(element: ElementRef, renderer: Renderer2) {
		this.iframeElementRef = element;
		this.renderer = renderer;
	}

	setActiveView(view: SelectedViewMobileSms) {
		this.view = view;
	}

	toogleDetail() {
		this.isDetail = !this.isDetail;
		this.transformSlide = `translate3d(${this.isDetail ? -100 : 0}%, 0, 0)`;

		this.iframeElementRef.nativeElement.style.transition =
			'transform 500ms ease 0s';
	}

	changeHeightIframe() {
		if (!this.iframeElementRef) return;

		const { offsetHeight } = this.iframeElementRef.nativeElement;

		const { maxHeightIframe, topIframe, heightPreview } =
			this.activeViewsSettings;

		if (this.isDetail && offsetHeight >= maxHeightIframe) {
			this.viewSettings.android.heightPreview = null;
			this.viewSettings.ios.heightPreview = null;

			this.fullScreenPhone = true;
		}

		if (!this.fullScreenPhone) {
			if (this.isDetail && heightPreview < offsetHeight + topIframe) {
				this.activeViewsSettings.heightPreview =
					offsetHeight + topIframe;
			}

			if (heightPreview && heightPreview > offsetHeight + topIframe) {
				this.renderer.setStyle(
					this.iframeElementRef.nativeElement.parentElement,
					'min-height',
					`${heightPreview - topIframe}px`
				);
			}
		}
	}

	resetSettings() {
		if (this.isDetail) this.toogleDetail();

		this.fullScreenPhone = false;
		this.viewSettings = {
			android: { ...SMS_ANDROID_PREVIEW_SETTINGS },
			ios: { ...SMS_IOS_PREVIEW_SETTINGS }
		};
	}
}
