/* eslint-disable import/no-cycle */
import { Injectable, Injector } from '@angular/core';
import { dia, ui } from '@clientio/rappid';
import { Events } from 'backbone';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CanvaMode, StatusType } from '@enSend/_shared/models';
import { Controller } from '../rappid/controller';
import { KeyboardController, RappidController } from '../rappid/controllers';
import { createPlugins } from '../rappid/plugins';
import { EventBusService } from './event-bus.service';
import { InspectorService } from './inspector.service';

import * as actions from '../rappid/actions';
import { NavigatorElementView } from '../rappid/shapes/app.shapes.ts';
import {
	ScenarioShapesEnum,
	ChatbotShapesEnum
} from '../rappid/config/enum.shapes';
import { initBlocks, initStencilShapes } from '../rappid/shapes';
import { BottomBarService } from '../../bottom-bar/services';

import { TopBarService } from './top-bar.service';

@Injectable()
export class RappidService {
	public controllers: { rappid: Controller; keyboard: Controller };
	public graph: dia.Graph;
	public canva: Element;
	public keyboard: ui.Keyboard;
	public commandManager: dia.CommandManager;
	public paper: dia.Paper;
	public scroller: ui.PaperScroller;
	public stencil: ui.Stencil;
	public navigator: ui.Navigator;
	public selectionUI: ui.Selection;
	public snaplines: ui.Snaplines;
	public canvaMode: CanvaMode;

	public selection: dia.Cell[] = [];

	private subscriptions = new Subscription();

	constructor(
		public readonly eventBusService: EventBusService,
		public readonly bottomBarService: BottomBarService,
		public readonly inspectorService: InspectorService,
		public readonly topBarService: TopBarService,
		public readonly injector: Injector,
		private translate: TranslateService
	) {
		initBlocks();
		this.subscriptions.add(
			// Translate RxJx notifications to Backbone Events
			this.eventBusService
				.events()
				.subscribe(({ name, value }) =>
					Events.trigger.call(this.eventBusService, name, value)
				)
		);
	}

	get isSelectionMod() {
		return this.bottomBarService.isSelectionMod;
	}

	get isHotkeysOpened() {
		return this.topBarService.hotkeysOpened$.getValue();
	}

	init(paperElement: Element, stencilElement: Element, canvaMode: CanvaMode) {
		this.canvaMode = canvaMode;
		initStencilShapes(canvaMode);
		Object.assign(
			this,
			createPlugins(
				paperElement,
				stencilElement,
				this.injector,
				this.translate,
				canvaMode
			)
		);

		this.controllers = {
			rappid: new RappidController(this),
			keyboard: new KeyboardController(this)
		};

		this.subscriptions.add(
			this.bottomBarService.cursorChanges.subscribe(cursor => {
				this.setCursor(cursor);
			})
		);

		this.commandManager = new dia.CommandManager({
			graph: this.graph,
			cmdBeforeAdd(_cmdName, _cell, _graph, options) {
				if (options.noHistory) return false;
				return true;
			}
		});
	}

	setCursor(cursor: string) {
		this.scroller.setCursor(cursor);
	}

	initNavigator(el: Element) {
		this.navigator = new ui.Navigator({
			paperScroller: this.scroller,
			width: 260,
			height: 140,
			padding: 10,
			zoomOptions: { max: 2, min: 0.4 },
			paperOptions: {
				async: true,
				sorting: dia.Paper.sorting.NONE,
				elementView: NavigatorElementView
			}
		});
		el.appendChild(this.navigator.el);
		this.navigator.render();
	}

	checkValidity(status?: StatusType): boolean {
		return actions.checkValidity(this, this.translate, status);
	}

	public destroy(): void {
		const { paper, scroller, stencil, controllers, subscriptions } = this;
		paper.remove();
		scroller.remove();
		stencil.remove();
		(Object.keys(controllers) as Array<keyof typeof controllers>).forEach(
			name => controllers[name]?.stopListening()
		);
		subscriptions.unsubscribe();
		this.bottomBarService.navigatorInited$.next(false);
	}

	public setSelection(selection: dia.Cell[]) {
		this.selection = selection;
	}

	public startBlockExist(): boolean {
		const startBlock = this.graph
			.getElements()
			.find(
				element =>
					element.attributes.type ===
						ScenarioShapesEnum.START_BLOCK ||
					element.attributes.type ===
						ScenarioShapesEnum.MESSAGE_START_BLOCK ||
					element.attributes.type ===
						ScenarioShapesEnum.EXTRA_FIELD_START_BLOCK ||
					element.attributes.type === ChatbotShapesEnum.START
			);
		return !!startBlock;
	}

	public endBlockExist(): boolean {
		const endBlock = this.graph
			.getElements()
			.find(
				element =>
					element.attributes.type === ScenarioShapesEnum.END_BLOCK
			);
		return !!endBlock;
	}
}
