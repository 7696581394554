import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';

export const menuOptions: MultiMenuItem[] = [
	{
		label: 'segment_operator_menu.absolute_title',
		isTitle: true
	},
	{
		label: 'segment_operator_menu.after_item',
		value: 'after'
	},
	{
		label: 'segment_operator_menu.before_item',
		value: 'before'
	},
	{
		label: 'segment_options.between_date',
		value: 'between'
	},
	{
		label: 'segment_form.cond_field_date_direction_today',
		value: 'today'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_operator_menu.relatively_title',
		isTitle: true
	},
	{
		label: 'segment_operator_menu.past_item',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'segment_operator_menu.past_less_item',
				value: 'happened_less'
			},
			{
				label: 'segment_operator_menu.past_equal_item',
				value: 'happened_equal'
			},
			{
				label: 'segment_operator_menu.past_more_item',
				value: 'happened_more'
			}
		]
	},
	{
		label: 'segment_operator_menu.future_item',
		isSubmenu: true,
		submenuItems: [
			{
				label: 'segment_operator_menu.future_less_item',
				value: 'happen_less'
			},
			{
				label: 'segment_operator_menu.future_equal_item',
				value: 'happen_equal'
			},
			{
				label: 'segment_operator_menu.future_more_item',
				value: 'happen_more'
			}
		]
	},
	{
		label: 'segment_operator_menu.dynamic_title',
		isTitle: true
	},
	{
		label: 'segment_operator_menu.today_item',
		value: 'matches_today'
	},
	{
		label: 'segment_operator_menu.mounth_item',
		value: 'this_month'
	},
	{
		label: 'segment_operator_menu.year_item',
		value: 'this_year'
	}
];
