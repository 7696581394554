import { SelectItem } from 'primeng/api';

export const BOUNCE_TYPE_OPTIONS: SelectItem[] = [
	{
		label: 'segment_form.cond_message_bounce_type_option_block',
		value: 'block'
	},
	{
		label: 'segment_form.cond_message_bounce_type_option_soft',
		value: 'soft'
	},
	{
		label: 'segment_form.cond_message_bounce_type_option_hard',
		value: 'hard'
	},
	{
		label: 'segment_form.cond_message_bounce_type_option_another',
		value: 'another'
	}
];
