import { TableColumn } from '@enkod-core/interfaces';

export const SCENARIO_MAILING_GROUP_TABLE_COLS: TableColumn[] = [
	{
		field: 'id',
		header: 'mailing_groups_list.table_header_id',
		width: '5%',
		reorderableDisabled: true
	},
	{
		field: 'name',
		header: 'mailing_groups_list.table_header_name',
		width: '45%',
		'min-width': '12.5rem'
	},
	{
		field: 'totalSubscribers',
		header: 'mailing_groups_list.table_header_total_count',
		width: '20%',
		'min-width': '12.5rem'
	},
	{
		field: 'createDate',
		header: 'mailing_groups_list.table_header_date',
		width: '20%',
		'min-width': '12.5rem'
	},
	{
		field: 'channel',
		header: 'mailing_groups_list.table_header_channels',
		width: '20%',
		sortableDisabled: true
	}
];
