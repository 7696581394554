<div class="send-message">
    <span class="en-text-large description">
        {{'scenario_block_send_message.description' | translate}}
    </span>

    <ng-container *tuiLet="selectedMessage$ | async as selectedMessage">
        <div class="choose-btn"
             *ngIf="!selectedMessage.length">
            <ng-container *ngIf="isCreationMode">
                <button (click)="onDialogOpen()"
                        enButton
                        [label]="'scenario_block_send_message.choose_message' | translate"
                        class="en-button-secondary h36"></button>
            </ng-container>

        </div>
        <ng-container *ngFor="let info of selectedMessage">
            <en-chosen-item-info [id]="info.id"
                                 [content]="info.label"
                                 [type]="info.type"
                                 (remove)="removeMessage()"
                                 [isCreationMode]="isCreationMode"></en-chosen-item-info>
            <en-whatsapp-info *ngIf="info.type === 'whatsapp'"
                              [messageId]="messageId"
                              (onDeletedTemplate)="removeMessage()"></en-whatsapp-info>
        </ng-container>
    </ng-container>
</div>

<en-dialog [header]="'mailing_groups_form.dialog_header' | translate"
           styleClass="p-dialog--lg"
           [maximizable]="true"
           [resizable]="false"
           [modal]="true"
           appendTo="body"
           [(visible)]="visibleDialogMessage"
           [draggable]="false"
           (onHide)="onDialogHide()">
    <div>
        <div class="search">
            <en-search [placeholder]="'message_list.search_placeholder' | translate"
                       styleClass="h32"
                       [formControl]="searchControl"
                       [refresher]="false"
                       [resetValue$]="resetValue$"></en-search>

        </div>
        <tui-scrollbar class="scrollbar">
            <div class="table-wrapper">
                <en-message-table *ngIf="visibleDialogMessage"
                                  [toolsPanel]="false"
                                  [previewLink]="false"
                                  [enReorder]="false"
                                  [selection]="true"
                                  [tab]="scenarioListTab"
                                  [statusOptions]="statusOptions"
                                  [notSaveInUrl]="true"
                                  [hideDeleted]="true"
                                  (selected)="checkSelectedMessage($event)">
                    <ng-template enTemplate="tools">
                    </ng-template>
                </en-message-table>
            </div>
        </tui-scrollbar>
    </div>
</en-dialog>