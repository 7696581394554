/* eslint-disable */
import { Directive, Input } from '@angular/core';
import { IframeTemplate } from './types';

@Directive({
	selector: '[abstractPreviewClass]'
})
export abstract class PopupPreview {
	@Input()
	readonly template: IframeTemplate;

	@Input()
	readonly loading: boolean;

	@Input()
	readonly showPageToggler: boolean = true;

	@Input()
	readonly desktopBackground: string;

	@Input()
	readonly mobileBackground: string;

	@Input()
	readonly disableThanksButton: boolean;

	@Input()
	readonly dynamic: boolean = true;
}
