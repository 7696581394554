import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ConvertDateModule } from '@enkod-core/pipes';

import { StatusComponent } from './status.component';
import { UserStatusValuePipe } from './pipes/user-status-value.pipe';

@NgModule({
	imports: [CommonModule, TranslateModule, ConvertDateModule],
	declarations: [StatusComponent, UserStatusValuePipe],
	exports: [StatusComponent]
})
export class EnStatusModule {}
