import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewChild,
	ElementRef,
	Output,
	EventEmitter,
	TemplateRef,
	OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import codemirror from 'codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/jump-to-line';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import {
	MessageDetailQuery,
	MessageDetailService
} from '@enSend/message/message-details/_states/_state-message';

import { SelectedView } from '../../../../message-wizard/message-wizard-common/models/view-mail.model';
import {
	IEmailPreview,
	PreviewType
} from '../../../../message-wizard/message-wizard-common/step-content/2-step-editor/email-editor/email-editor.component';

@UntilDestroy()
@Component({
	selector: 'en-mail-preview',
	templateUrl: './mail-preview.component.html',
	styleUrls: ['./mail-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailPreviewComponent implements OnInit {
	_html: string;
	_plainText: string;

	options: codemirror.EditorConfiguration = {
		lineNumbers: true,
		mode: 'htmlmixed',
		readOnly: true,
		showHint: true,
		scrollbarStyle: 'simple',
		viewportMargin: Infinity,
		lineWrapping: true
	};

	/** Функционал для превью тулзов */
	isOnHover = false;
	status: string;
	id: number;
	mouseEnter$: Observable<any>;
	mouseLeave$: Observable<any>;

	clicksMapData$ = this.messageQuery.clicksMapData$;
	linksEditorData$ = this.messageQuery.linksEditorData$;

	tempHtml = new UntypedFormControl();
	private changeSplit$ = this.messageQuery
		.preview$ as Observable<IEmailPreview>;

	@ViewChild('preview') preview: ElementRef;
	@ViewChild('descktopIframe') descktopIframe: ElementRef;

	@Input() set dataGroup(value: IEmailPreview) {
		if (value) {
			this._html = value.html;
			this._plainText = value.plainText;
		}
	}

	@Input() chooseEditorButton: TemplateRef<any>;
	@Input() createButtonTemplate: TemplateRef<any>;
	@Input() view: SelectedView;
	@Input() optionsView: SelectItem[];
	@Input() bordersNone: boolean;
	@Input() previewType: PreviewType;
	@Input() clicksMapAvailable: boolean;
	@Input() fullscreenVisible: boolean;

	@Output() openEditor = new EventEmitter();
	@Output() fullscreenVisibleChange = new EventEmitter();
	@Output() resetCurrentHtml = new EventEmitter();
	@Output() changeView = new EventEmitter();

	constructor(
		private messageQuery: MessageDetailQuery,
		private router: Router,
		private detailService: MessageDetailService
	) {
		this.id = this.detailService.activeId;
		if (this.id) this.getDetail();
	}

	get html() {
		return this._html;
	}

	get plainText() {
		return this._plainText;
	}

	get typePreview() {
		return this.previewType === 'preview';
	}

	get editPreview() {
		return this.previewType === 'edit';
	}

	get createPreview() {
		return this.previewType === 'create';
	}

	get visibleTools() {
		return this.isOnHover && this.previewType !== 'create';
	}

	private getDetail() {
		this.detailService
			.getDetailById(this.id)
			.pipe(
				untilDestroyed(this),
				tap(data => {
					this.status = data.status;
				})
			)
			.subscribe();
	}

	editMessage() {
		this.router.navigateByUrl(`/ensend/messages/edit/mail/${this.id}`);
	}

	closeFullscreen() {
		this.fullscreenVisibleChange.emit(false);
	}

	ngOnInit() {
		this.changeSplit$
			.pipe(
				untilDestroyed(this),
				tap(value => this.tempHtml.setValue(value.html))
			)
			.subscribe();
	}
}
