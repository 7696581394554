import { Injectable } from '@angular/core';
import { NotificationStatus } from 'ui-lib';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CRUDService } from '@enkod-core/abstract';
import { NotificationsService, UrlSaverService } from '@enkod-core/services';
import { PaginationResponse } from '@enkod-core/types';
import { IEnkodTags, ListParams } from '../models/tags-models';

@Injectable()
export class EnkodTagsDataService extends CRUDService {
	constructor(
		private notification: NotificationsService,
		private urlService: UrlSaverService,
		private notificationsService: NotificationsService
	) {
		super('enkod/tag/all/');
	}

	getAll(): Observable<IEnkodTags[]> {
		return this.http
			.get<PaginationResponse<IEnkodTags>>('enkod/tag/all/')
			.pipe(map(data => data.result));
	}

	getList({ page, sort, perPage, search }: ListParams) {
		return this.list<PaginationResponse<IEnkodTags>>({
			limit: perPage,
			offset: (page - 1) * perPage,
			sort,
			search
		}).pipe(
			tap(() => {
				this.urlService.setParamsToUrl(
					{
						sortOrder: sort?.order,
						perPage,
						search
					},
					{ page }
				);
			}),
			map(resp => ({
				perPage: perPage || 10,
				lastPage: 0,
				currentPage: page,
				total: Math.ceil(resp?.total / perPage) || 1,
				data: resp?.result || []
			})),
			catchError(() => {
				this.showErrorToast();
				return of({
					perPage: 10,
					lastPage: 0,
					currentPage: 1,
					total: 0,
					data: []
				});
			})
		);
	}

	createTag(data: IEnkodTags) {
		return this.create(data).pipe(
			tap(() => {
				this.notificationsService
					.show('', {
						label: 'toast.tag_save',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			}),
			catchError(() => {
				this.showErrorToast();
				return of(null);
			})
		);
	}

	updateTag(data: IEnkodTags, id: number) {
		return this.update(data, id).pipe(
			tap(() => {
				this.notificationsService
					.show('', {
						label: 'toast.tag_save',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			}),
			catchError(() => {
				this.showErrorToast();
				return of(null);
			})
		);
	}

	deleteTag(id: number) {
		return this.delete(id).pipe(
			tap(() => {
				this.notificationsService
					.show('', {
						label: 'toast.tag_delete',
						status: NotificationStatus.SUCCESS
					})
					.subscribe();
			}),
			catchError(() => {
				this.showErrorToast();
				return of(null);
			})
		);
	}

	private showErrorToast() {
		this.notification
			.show('toast.summary_try_later', {
				label: 'toast.error',
				status: NotificationStatus.ERROR
			})
			.subscribe();
	}
}
