import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IMaskModule } from 'angular-imask';

import { SharedModule } from '@shared';
import {
	EnButtonModule,
	EnDropdownCalendarNewModule,
	EnDropdownCalendarRangeModule,
	EnDropdownModule,
	EnInputModule,
	EnMessagesModule,
	EnMultilevelDropdownModule
} from 'ui-lib';

import { EventStartComponent } from './event-start.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { EventStartService } from './event-start.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		EnDropdownModule,
		EnMultilevelDropdownModule,
		EnButtonModule,
		EnInputModule,
		EnDropdownCalendarNewModule,
		EnDropdownCalendarRangeModule,
		EnMessagesModule,
		IMaskModule
	],
	declarations: [EventStartComponent],
	exports: [EventStartComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: EventStartService,
			multi: true
		}
	]
})
export class EventStartModule {}
