import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TUI_VALIDATION_ERRORS } from 'ui-lib';

@Component({
	selector: 'en-buttons-container',
	templateUrl: './buttons-container.component.html',
	styleUrls: ['./buttons-container.component.scss'],
	providers: [
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				required: 'chatbot_message_block.button_text_required',
				invalidUrl: 'chatbot_message_block.button_text_required',
				maxlength: 'chatbot_message_block.button_max_lenght'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonsContainerComponent {
	@Input() buttons: FormGroup;

	get otherButtons(): FormArray {
		return this.buttons.controls.other as FormArray;
	}

	get backButton(): FormGroup {
		return this.buttons.controls.restart as FormGroup;
	}

	get backButtonExist(): boolean {
		return this.buttons.contains('restart');
	}
}
