/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { ScenarioShapesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';
import { blockView } from '@enSend/_shared/components/scenario-canva/rappid/shapes';
import { startBlock } from './start.shapes';

export const extraFieldStartBlock = startBlock.define(
	ScenarioShapesEnum.EXTRA_FIELD_START_BLOCK,
	{},
	{
		initialize(...args: any) {
			startBlock.prototype.initialize.apply(this, args);
		},

		onChangeOptions(args: any) {
			startBlock.prototype.onChangeOptions.call(this, {
				updatePorts: true,
				...args
			});
		}
	}
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const extraFieldStartBlockView: dia.ElementView =
	Object.assign(blockView);
