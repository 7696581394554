<section class="tools">
    <div>
        <ng-container *ngTemplateOutlet="toolsTemplate"></ng-container>
    </div>

    <en-paginator [total]="total"
                  [paginatorRef]="paginatorRef"
                  (onChangePerPage)="paginationService.setPerPageValue($event)"
                  [onLoadFromUrl]="!notSaveInUrl"></en-paginator>
</section>
<p-table *ngIf="true"
         [columns]="cols"
         [value]="mailingGroups"
         [resizableColumns]="true"
         [customSort]="true"
         [lazy]="true"
         [rowHover]="true"
         dataKey="id"
         [loading]="paginatorRef.isLoading$ | async"
         [(selection)]="selectedEntity"
         [selectionPageOnly]="true"
         [autoLayout]="true"
         [sortField]="urlParams.sortKey"
         [sortOrder]="urlParams.sortOrder"
         (onSort)="paginationService.setSortValue($event)"
         (onHeaderCheckboxToggle)="selectedEntityChange.emit(selectedEntity)"
         (onRowSelect)="selectedEntityChange.emit(selectedEntity)"
         (onRowUnselect)="selectedEntityChange.emit(selectedEntity)">
    <ng-template pTemplate="header"
                 let-columns>
        <tr>
            <th *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="col.field==='selectCheckbox' || col.sortableDisabled"
                pResizableColumn
                [pResizableColumnDisabled]="col.field==='selectCheckbox'"
                [style.width]="col.width"
                [style.min-width]="col['min-width']"
                valign="middle">
                <ng-container [ngSwitch]="col.field">
                    <ng-template ngSwitchCase="selectCheckbox">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </ng-template>

                    <ng-template ngSwitchDefault>
                        <div class="en-header-column-wrapper">
                            <span class="text-overflow">{{col.header | translate}}</span>
                            <p-sortIcon *ngIf="!col.sortableDisabled"
                                        [field]="col.field"></p-sortIcon>
                        </div>
                    </ng-template>
                </ng-container>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-rowData
                 let-columns="columns"
                 let-index="rowIndex">
        <tr (mouseenter)="tableToolsService.showTools(index, $event, rowData )"
            (mouseleave)="tableToolsService.hideTools()"
            class="visible-selection-button">
            <td *ngFor="let col of columns;"
                [class.en-cell-no-padding]="col.field === 'name' || col.field === 'channels'"
                [class.line-height-100]="selection"
                style="max-width: 0px">
                <ng-container [ngSwitch]="col.field">
                    <ng-template ngSwitchCase="selectCheckbox">
                        <p-tableCheckbox [value]="rowData"
                                         [disabled]="checkboxDisabled(rowData)"></p-tableCheckbox>
                    </ng-template>
                    <ng-template ngSwitchCase="name">
                        <div class="en-table-name">
                            <div class="en-table-name__name-block"
                                 (click)="showDetail.emit(rowData.id)">
                                <span class="text-overflow"
                                      [class.en-table-name__preview-link]="previewLink">
                                    {{ rowData[col.field] || ('common.no_name' | translate) }}
                                </span>
                            </div>
                            <div class="en-table-name__selection"
                                 *ngIf="selection">
                                <button type="button"
                                        enButton
                                        class="h24 en-button-secondary"
                                        [label]="'common.to_select'| translate"
                                        (click)="selected.emit(rowData)"></button>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template ngSwitchCase="createDate">
                        <ng-template [ngIf]="rowData[col.field] > 0">
                            {{ rowData[col.field] | convertDate : 'DD.MM.yyyy' }}
                        </ng-template>
                    </ng-template>

                    <ng-template ngSwitchCase="totalSubscribers">
                        {{ rowData[col.field] }}
                    </ng-template>

                    <ng-template ngSwitchCase="channel">
                        <div *ngIf="rowData[col.field]"
                             class="channel">
                            <span class="channel__badge">
                                <span [ngClass]="rowData[col.field] | typeIcon"
                                      class="en channel__icon"></span>
                                {{rowData[col.field] | channelName }}
                            </span>
                        </div>
                    </ng-template>


                    <ng-template ngSwitchDefault>{{ rowData[col.field] }}</ng-template>
                </ng-container>
            </td>
        </tr>
        <div *ngIf="toolsPanel"
             class="tools-box"
             [ngStyle]="{ top: tableToolsService.offsetTop, height: tableToolsService.trHeight }"
             [ngClass]="{ 'show': tableToolsService.tool && index === tableToolsService.chosenTr, 'inactive':
                index !== tableToolsService.chosenTr }"
             (mouseenter)="tableToolsService.showTools(index)"
             (mouseleave)="tableToolsService.hideTools()">
            <en-button [title]="'table_tools.tools_button_edit' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-pencil-o"
                       (onClick)="editEntity.emit(rowData.id)"></en-button>
            <en-button [title]="'table_tools.tools_button_copy' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-copy"
                       (onClick)="copyEntity.emit(rowData.id)"></en-button>
            <en-button [title]="'table_tools.tools_button_delete' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-trash"
                       (onClick)="onDeleteEntity(rowData.id)"></en-button>
        </div>
    </ng-template>
    <ng-template pTemplate="emptymessage"
                 let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                <div class="empty-table">
                    <h3>{{'common.empty_table' | translate}}</h3>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<en-related-data-dialog *ngIf="showRelatedDataDialog"
                        [dialogVisible]="showRelatedDataDialog"
                        [relatedData]="relatedMessages"
                        navigateUrl="ensend/scenario/detail/"
                        infoText="mailing_groups_list.related_messages_text"
                        (closeDialog)="showRelatedDataDialog = false"></en-related-data-dialog>