import { SelectItem } from 'primeng/api';
import { UrlCondition } from '../models/types';

export const URL_SEGMENTS_OPERATORS: SelectItem<UrlCondition>[] = [
	{
		label: 'segment_form.cond_url_starts',
		value: 'startWith'
	},
	{
		label: 'segment_form.cond_url_contain',
		value: 'contain'
	},
	{
		label: 'segment_form.cond_url_equal',
		value: 'equal'
	},
	{
		label: 'segment_form.cond_url_wild',
		value: 'wildCard'
	}
];
