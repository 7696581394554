/* eslint-disable import/no-cycle */
import {
	startBlock,
	messageStartBlock,
	messageStartBlockView,
	extraFieldStartBlock,
	extraFieldStartBlockView,
	startBlockView,
	customEventStartBlock,
	customEventStartBlockView
} from './start.shapes';
import {
	mainBlock,
	mainBlockView,
	sendMessageBlock,
	sendMessageBlockView,
	severalPortsBlock,
	severalPortsBlockView,
	distributionBlock,
	distributionBlockView,
	dataChangeMainBlock,
	dataChangeMainBlockView,
	apiQueryBlock,
	apiQueryBlockView
} from './main.shapes';
import { endBlock, endBlockView } from './end.shapes';
import { enKodLink } from '../link.shape';

export const scenarioNamespace = {
	scenario: {
		enKodLink,
		startBlock,
		mainBlock,
		sendMessageBlock,
		messageStartBlock,
		extraFieldStartBlock,
		customEventStartBlock,
		severalPortsBlock,
		distributionBlock,
		dataChangeMainBlock,
		apiQueryBlock,
		endBlock,
		startBlockView,
		mainBlockView,
		sendMessageBlockView,
		severalPortsBlockView,
		distributionBlockView,
		messageStartBlockView,
		extraFieldStartBlockView,
		customEventStartBlockView,
		dataChangeMainBlockView,
		apiQueryBlockView,
		endBlockView
	}
};
