import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { TableColumn } from '@enkod-core/interfaces';
import { Block, Link, StatusType } from '@enSend/_shared/models';

export interface Scenario {
	name: string;
	id: ID;
	status: StatusType;
	creationDate: number;
	blocks: Block[];
	links: Link[];
	isValid: boolean;
	openTemplatesModal?: boolean;
}

export type CreateScenario = { name: string; openTemplatesModal: boolean };
export type ChangeStatusType = 'start' | 'stop';

export interface DeleteData {
	id: ID;
	name: string;
}

export type OnChangeStatus = {
	id: ID;
	status: ChangeStatusType;
};

export type ToolsClickAction = {
	action: ToolsActions;
	id: ID;
	isValid?: boolean;
	status: StatusType;
};

export type ToolsActions =
	| 'activate'
	| 'deactivate'
	| 'copy'
	| 'edit'
	| 'delete';

export interface ContactsStatisticsServicePlugin {
	count: Observable<number>;
	description: string;
	getEndpoint(): string;
	getColumns(): TableColumn[];
}

export type ScenarioFilters = {
	status: StatusTypeFilter;
	dateRange: [number, number];
};

export type StatusTypeFilter = 'active' | 'inactive' | 'draft';
