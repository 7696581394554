import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RfmState, RfmStrore } from './rfm.store';

@Injectable({ providedIn: 'root' })
export class RfmQuery extends QueryEntity<RfmState> {
	constructor(protected store: RfmStrore) {
		super(store);
	}
}
