<div [formGroup]="form"
     class="filters">

    <span>{{ 'segment_filters.show_text' | translate }}</span>

    <!-- Тип сегмента -->
    <div [hidden]="isVisible('type')">
        <en-multiSelect [disableControl]="isVisible('type')"
                        styleClass="en-multiselect-filter en-width"
                        [options]="getItemOptions('type')"
                        formControlName="type"
                        [isClearButton]="true"
                        [showHeader]="false"
                        (onClear)="remove('type')">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div style="display: flex;">
                    <span class="text-overflow"
                          *ngIf="value?.length > 0">
                        ({{ value.length }}) {{ 'type' | declinationWord:value.length | translate }}

                    </span>
                </div>
                <div *ngIf="!value || value.length === 0"
                     class="en-multiselected-empty-token en-placeholder">
                    {{ 'segment_filters.filter_type' | translate }}
                </div>
            </ng-template>
        </en-multiSelect>
    </div>

    <!-- Тег -->
    <div [hidden]="isVisible('tags')">
        <en-multiSelect [disableControl]="isVisible('tags')"
                        class="w320"
                        styleClass="en-multiselect-filter en-width"
                        [options]="getItemOptions('tags') | async"
                        [isClearButton]="true"
                        [showToggleAll]="false"
                        [showCloseButton]="false"
                        formControlName="tags"
                        (onClear)="remove('tags')">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div style="display: flex;">
                    <span class="text-overflow"
                          *ngIf="value?.length > 0">
                        ({{ value.length }}) {{ 'tag' | declinationWord:value.length | translate }}

                    </span>
                </div>
                <div *ngIf="!value || value.length === 0"
                     class="en-multiselected-empty-token en-placeholder">
                    {{ 'message_list.filter_tag' | translate }}
                </div>
            </ng-template>
            <ng-template let-tag
                         pTemplate="item">
                <div class="tag__item_wrapper">
                    <div class="tag__item">{{ tag.value }}</div>
                </div>
            </ng-template>
        </en-multiSelect>
    </div>
</div>