import { SelectItem } from 'primeng/api';

export const OPTIONS_TIME_ABSTRACT: SelectItem<string>[] = [
	{
		label: 'segment_options.between_date',
		value: 'between'
	},
	{
		label: 'segment_options.equal_options',
		value: 'equal'
	},
	{
		label: 'segment_form.options_operator_less',
		value: 'less'
	},
	{
		label: 'segment_form.options_operator_more',
		value: 'more'
	}
];
