import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiScrollbarModule } from '@enkod-core/components';
import { MailingGroupsTableModule } from '@enKod/mailing-groups/components';
import { SharedModule } from '@shared';
import { EnDialogModule, SearchModule } from 'ui-lib';

import { ChooseMailingGroupDialogComponent } from './choose-mailing-group-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnDialogModule,
		SearchModule,
		MailingGroupsTableModule,
		TuiScrollbarModule
	],
	declarations: [ChooseMailingGroupDialogComponent],
	exports: [ChooseMailingGroupDialogComponent]
})
export class ChooseMailingGroupDialogModule {}
