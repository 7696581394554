import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { ChosenItemInfoComponent } from './chosen-item-info.component';

@NgModule({
	imports: [CommonModule, SharedModule],
	exports: [ChosenItemInfoComponent],
	declarations: [ChosenItemInfoComponent]
})
export class ChosenItemInfoModule {}
