<form [formGroup]="form"
      class="condition condition_flex-start"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <div class="addition">
        <span class="center-span-text first-block">{{ 'segment_menu_items.html_item' | translate }}</span>
        <en-dropdown class="input__small"
                     styleClass="en-dropdown-solid"
                     [options]="operatorOptions"
                     formControlName="operator"></en-dropdown>
    </div>
    <textarea #html
              class="textarea"
              [autoResize]="true"
              rows="1"
              pInputTextarea
              formControlName="value"></textarea>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"></en-condition-tools>
    </div>
</form>