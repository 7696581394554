/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import {
	Controller,
	SharedEvents
} from '@enSend/_shared/components/scenario-canva/rappid/controller';
import { RappidInterface } from '@enSend/_shared/models';
import * as actions from '@enSend/_shared/components/scenario-canva/rappid/actions';
import { PreviewRappidService } from '../../services/preview-rappid.service';

const MOUSEWHEEL_DELTA_THROTTLE = 0.2;

export class RappidController extends Controller<PreviewRappidService> {
	constructor(public readonly service: PreviewRappidService) {
		super(service);
	}

	startListening() {
		const { paper } = this.service;

		this.listenTo(paper, {
			scale: onPaperScale,
			'blank:pointerdown': onPaperBlankPointerdown,

			'blank:mousewheel': onPaperBlankMousewheel,
			'cell:mousewheel': onCellMousewheel,
			'cell:pointerdown': onCellPointerDown,
			'cell:pointerdblclick': onPaperCellDBclick
		});

		// snaplines.startListening();
	}
}

function onPaperScale(service: RappidInterface, value: number) {
	actions.setScale(service, Math.round(value * 100));
}

// Graph

function onPaperBlankMousewheel(
	service: RappidInterface,
	evt: dia.Event,
	x: number,
	y: number,
	delta: number
): void {
	evt.preventDefault();
	zoomAtPoint(service, delta * MOUSEWHEEL_DELTA_THROTTLE, x, y);
}

function onCellMousewheel(
	service: RappidInterface,
	_cellView: dia.CellView,
	evt: dia.Event,
	x: number,
	y: number,
	delta: number
) {
	evt.preventDefault();
	zoomAtPoint(service, delta * MOUSEWHEEL_DELTA_THROTTLE, x, y);
}

function zoomAtPoint(
	service: RappidInterface,
	deltaZoom: number,
	x: number,
	y: number
): void {
	const { scroller } = service;
	scroller.zoom(deltaZoom, {
		min: 0.4,
		max: 3,
		grid: 0.2,
		ox: x,
		oy: y
	});
}

function onPaperBlankPointerdown(
	service: PreviewRappidService,
	evt: dia.Event
): void {
	service.scroller.startPanning(evt);
	service.inspectorService.toggleInspector(false);
	service.dimService.resetDim(service);
}

function onPaperCellDBclick(
	service: PreviewRappidService,
	elementView: dia.CellView
) {
	if (elementView.model.isLink()) return;

	service.eventBusService.emit(SharedEvents.SELECTION_CHANGED, [
		elementView.model
	]);
	service.dimService.dim(service, elementView);
}

function onCellPointerDown(
	service: PreviewRappidService,
	cellView: dia.CellView,
	evt: dia.Event
) {
	// если был клик на счетчик людей внутри блока
	if (
		evt.target.closest('g').className.baseVal === 'people-counter' ||
		evt.target.closest('g').className.baseVal === 'contact-in-block'
	) {
		service.dialogService.openPeopleCountDialog({
			subtype: cellView.model.get('subType'),
			blockGUID: cellView.model.get('id'),
			scenarioId: cellView.model.attributes.scenarioId
		});
	}
}
