import { Inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { WINDOW } from '@enkod-core/utils';

@Injectable({ providedIn: 'root' })
export class UpdateService {
	/** Если после запуска приложения прошло 3 и более секунд,
	 *  но обновления не применились - выводим алерт */
	private readonly SECONDS_TO_SHOW_ALERT = 3;

	constructor(
		@Inject(WINDOW) private readonly window: Window,
		private updates: SwUpdate,
		private translate: TranslateService
	) {}

	checkUpdates() {
		if (!this.updates.isEnabled) {
			return;
		}

		this.updates.checkForUpdate();

		this.updates.versionUpdates
			.pipe(
				filter(
					(evt): evt is VersionReadyEvent =>
						evt.type === 'VERSION_READY'
				)
			)
			.subscribe(evt => {
				console.log(`Current app version: ${evt.currentVersion.hash}`);
				console.log(
					`New app version ready for use: ${evt.latestVersion.hash}`
				);

				setTimeout(() => {
					const translate = this.translate.instant(
						'common.update_alert'
					);

					const confirmApprove = this.window.confirm(translate);

					if (confirmApprove) {
						this.window.location.reload();
					}
				}, this.SECONDS_TO_SHOW_ALERT * 1000);
			});
	}
}
