<ng-container *ngIf="splitIndexChanges$ | async"></ng-container>
<ng-container *ngIf="forceRerender && forceRerender | async"></ng-container>

<button *ngIf="splitLength < 2 && isDraft"
        enButton
        type="button"
        class="en-button-lowercase h32"
        icon="en en-plus-circle-o"
        iconColor="blue-button-icon"
        iconPos="left"
        [disabled]="loading"
        [label]="'split_test.button_create' | translate"
        (click)="createSplit()"></button>


<ng-container *ngIf="splitLength >= 2">
    <div #menuTrigger="matMenuTrigger"
         class="split-menu"
         [class._opened]="openMenu"
         [matMenuTriggerFor]="splitMenu"
         [class._disabled]="loading"
         (menuOpened)="openMenu=menuTrigger.menuOpen "
         (menuClosed)="openMenu=menuTrigger.menuOpen ">
        <span>
            {{ 'split_test.version_item' | translate: { version: versions[selectedSplitIndex] } }}
        </span>
        <i class="en en-sort-down"></i>
    </div>
</ng-container>


<mat-menu #splitMenu="matMenu"
          class="split-handler en-menu en-menu_mt-1">
    <div *ngIf="changeMode && splitLength < 5 && isDraft"
         mat-menu-item
         class="menu-item menu-create-split"
         (click)="createSplit()">
        {{ 'split_test.create_item' | translate }}
    </div>
    <ng-container *ngFor="let split of splits.controls; index as i">
        <div mat-menu-item
             class="menu-item"
             [class.highlight]="i === selectedSplitIndex"
             (click)="selectSplit(i)">
            {{ 'split_test.version_item' | translate: { version: versions[i] } }}
            <i *ngIf="changeMode"
               class="en en-cancel-fill"
               (click)="removeSplit($event, i)"></i>
        </div>
    </ng-container>
</mat-menu>