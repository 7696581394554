import { Inject, Injectable } from '@angular/core';
import { util } from '@clientio/rappid';
import { BehaviorSubject, Subject } from 'rxjs';
import { FORCE_RERENDER_TOKEN } from '@enkod-core/tokens';
import { WINDOW } from '@enkod-core/utils';
import { RappidInterface } from '@enSend/_shared/models';

type CursorMod = 'select' | 'panning';

@Injectable()
export class BottomBarService {
	private _scale$ = new BehaviorSubject<number>(100);
	private _cursor$ = new BehaviorSubject('default');

	private cursorMod: CursorMod = 'select';
	private toggleOnSpace = false;

	navigatorInited$ = new BehaviorSubject<boolean>(false);

	constructor(
		@Inject(FORCE_RERENDER_TOKEN) readonly forceRerender$: Subject<void>,
		@Inject(WINDOW) readonly window: Window
	) {}

	get scaleChanges() {
		return this._scale$.asObservable();
	}

	get cursorChanges() {
		return this._cursor$.asObservable();
	}

	get isSelectionMod() {
		return this.cursorMod === 'select';
	}

	get isPanningMod() {
		return this.cursorMod === 'panning';
	}

	setScaleValue(value: number) {
		this._scale$.next(value);
	}

	toggleCursorMod() {
		if (this.isPanningMod) {
			this.setDefaultCursor();
		} else {
			this.cursorMod = 'panning';
			this._cursor$.next('grab');
		}

		this.forceRerender$.next();
	}

	setDefaultCursor() {
		this.cursorMod = 'select';
		this._cursor$.next('default');
	}

	toggleFullScreen(service: RappidInterface) {
		util.toggleFullScreen(service.canva);
		// fullscreenchange евент не работает при переключении на F11, todo: найти нормальный способ ререндера без сет таймаута 50
		// на escape тоже нужен отдельный ререндер, но евент не срабатывает, когда выходишь из фулскрина!
		// на F11 не пробовал обрабатывать евент
		// setTimeout(() => this.forceRerender$.next(), 50);
	}

	toggleCursorOnSpace() {
		if (this.isSelectionMod) {
			this.cursorMod = 'panning';
			this._cursor$.next('grab');
			this.toggleOnSpace = true;

			return;
		}
		if (this.toggleOnSpace) {
			this.cursorMod = 'select';
			this._cursor$.next('default');
			this.toggleOnSpace = false;
		}
	}

	toggleNavigator() {
		this.navigatorInited$.next(!this.navigatorInited$.value);
	}
}
