/* eslint-disable import/no-cycle */
import { Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { dia, shapes } from '@clientio/rappid';
import { DelegateInstance, Props, delegate } from 'tippy.js';
import { TranslateService } from '@ngx-translate/core';
import { CanvaMode, RappidInterface, StatusType } from '@enSend/_shared/models';
import { ConfigType, HaloService } from '../services/halo.service';
import { RappidService } from '../services/rappid.service';
import { PADDING_L, ZOOM_MAX, ZOOM_MIN, ZOOM_STEP } from '../theme';
import { SharedEvents } from './controller';
import {
	ChatbotShapesEnum,
	ScenarioShapesEnum,
	ShapeTypesEnum
} from './config/enum.shapes';
import { StencilConfig } from './config/stencil.config';
import './shapes/index';

const haloServiceInjector = Injector.create({
	providers: [
		{
			provide: HaloService,
			useClass: HaloService
		}
	]
});

let invalidLinksTI: DelegateInstance<Props>[] = [];
let emptyBlockTI: DelegateInstance<Props>[] = [];

export function checkValidity(
	service: RappidService,
	translate: TranslateService,
	status?: StatusType
) {
	const { graph, paper } = service;
	const elements = graph.getElements() as InstanceType<typeof shapes.Block>[];
	let valid = true;

	elements.forEach(block => {
		if (!block.checkLinksValidity(service, status)) {
			const blockView = block.findView(paper);

			if (block.attributes.subType === 'message') {
				blockView.vel.addClass('invalid-links__message');
			} else {
				blockView.vel.addClass('invalid-links');
			}

			valid = false;
		}

		if (!block.checkBlockValidity(status)) {
			const blockView = block.findView(paper);

			if (block.attributes.subType === 'message') {
				blockView.vel.removeClass('invalid-links__message');
			} else {
				blockView.vel.removeClass('invalid-links');
			}

			blockView.vel.addClass('empty-block');
			valid = false;
		}
	});
	renderInvalidTooltips(translate);
	return valid;
}

function renderInvalidTooltips(translate: TranslateService) {
	invalidLinksTI.forEach(instance => instance.destroy());
	emptyBlockTI.forEach(instance => instance.destroy());

	invalidLinksTI = delegate('.empty-block', {
		target: '.invalid__tippy',
		content: translate.instant('scenario_wizard.empty_block')
	});

	emptyBlockTI = delegate('.invalid-links', {
		target: '.invalid__tippy',
		content: translate.instant('scenario_wizard.invalid_links')
	});

	emptyBlockTI = delegate('.invalid-links__message', {
		target: '.invalid__tippy',
		content: translate.instant('chatbot_message_block.invalid_links')
	});
}

// Selection

export function showHalo(service: RappidService, selection: dia.Cell[]) {
	const { paper, inspectorService: inspector } = service;
	const [cell] = selection;
	const haloService = haloServiceInjector.get(HaloService);

	if (cell.isElement()) {
		createTools();
	}

	if (cell.isLink()) {
		if (!(cell as dia.Link).getTargetCell()) return;
		createTools('line');
	}

	function createTools(type: ConfigType = 'basic') {
		const cellView = cell.findView(paper);
		haloService.create(cellView, type);

		const { halo } = haloService;
		halo.on('action:customEdit:pointerdown', () => {
			const destroy$ = new Subject<void>();

			inspector.toggleInspectorChanges
				.pipe(takeUntil(destroy$), first())
				.subscribe(value => {
					if (value) inspector.toggleInspector(false);
					setTimeout(() => {
						setActiveSelection(service);
						destroy$.next();
						destroy$.complete();
					}, 500);
				});
		});
		halo.render();
	}
}

export function setSelection(
	service: RappidService,
	selection: dia.Cell[]
): void {
	const { paper, selection: previousSelection } = service;

	previousSelection.forEach(cell => {
		resetLinks(cell);
		const cellView = cell.findView(paper);
		if (cellView) {
			cellView.vel.removeClass('selected');
		}
	});

	service.setSelection(selection);

	selection.forEach(cell => {
		const cellView = cell.findView(paper);
		highlightLinks(cell);
		if (cellView) {
			cellView.vel.addClass('selected');
		}
	});

	function resetLinks(cell: dia.Cell) {
		if (cell.isLink() && !selection.find(elem => elem === cell)) {
			setLinkColor(cell as dia.Link, '#535766');
		}
		if (cell.isElement()) {
			const portsOut = (
				cell as InstanceType<typeof shapes.Block>
			).getGroupPorts('out');
			portsOut.forEach(port => {
				const allLinks = service.graph.getLinks();
				const targetLinks = allLinks.filter(
					link => link.attributes.source.port === port.id
				);
				targetLinks.forEach(link => {
					setLinkColor(link, '#535766');

					const linkView = link.findView(paper);
					linkView.vel.removeClass('selected');
				});
			});
		}
	}

	function highlightLinks(cell: dia.Cell) {
		if (cell.isElement()) {
			const portsOut = (
				cell as InstanceType<typeof shapes.Block>
			).getGroupPorts('out');
			portsOut.forEach(port => {
				const allLinks = service.graph.getLinks();
				const targetLinks = allLinks.filter(
					link => link.attributes.source.port === port.id
				);
				targetLinks.forEach(link => {
					setLinkColor(link, '#234ec4');

					const linkView = link.findView(paper);
					linkView.vel.addClass('selected');
				});
			});
		}
	}
}

export function setActiveSelection(service: RappidService) {
	const { selection, eventBusService } = service;

	if (selection.length === 1)
		eventBusService.emit(SharedEvents.SELECTION_CHANGED, service.selection);
}

// Stencil

export function loadStencilShapes(
	service: RappidService,
	canvaMode: CanvaMode,
	translate?: TranslateService
): void {
	const { stencil } = service;
	const key: keyof typeof StencilConfig = canvaMode;
	stencil.load({
		start: stencilFactory(StencilConfig[key].start, translate),
		main: stencilFactory(StencilConfig[key].main, translate),
		end: stencilFactory(StencilConfig[key].end, translate)
	});
}

const stencilFactory = (configShapes: any[], translate?: TranslateService) => {
	return configShapes
		.map(shape => {
			const translatedShape = shape;
			translatedShape.attrs.label.text = translate?.instant(
				shape.attrs.label.text
			);
			return translatedShape;
		})
		.map(
			// @ts-ignore
			shape => new shapes.stencil[shape.name](shape)
		);
};

// Zooming

export function setScale(service: RappidInterface, value: number) {
	const { bottomBarService } = service;
	bottomBarService.setScaleValue(value);
}

export function zoomToFit(service: RappidService) {
	if (!service.graph.getCells().length) return;
	const { scroller } = service;
	scroller.zoomToFit({
		minScale: ZOOM_MIN,
		maxScale: ZOOM_MAX,
		scaleGrid: ZOOM_STEP,
		useModelGeometry: true,
		padding: PADDING_L * 5
	});
}

// Import / Export

export function importGraphFromJSON(
	service: RappidService,
	json: any,
	translate?: TranslateService
): void {
	const { graph, commandManager } = service;

	const shapeTypes = [
		...Object.values(ScenarioShapesEnum),
		...Object.values(ChatbotShapesEnum),
		ShapeTypesEnum.LINK
	];
	commandManager?.reset();
	try {
		if (json.cells.some((cell: any) => !shapeTypes.includes(cell.type))) {
			throw new Error('Invalid JSON: Unknown Cell Type');
		}
		graph.fromJSON(json, { injector: service.injector });
		// Переводим названия блоков
		graph.attributes.cells?.models.map(model => {
			if (model.attributes.type !== 'enKodLink') {
				const { attrs } = model.attributes;
				const options = model.get('options');
				const subType = model.get('subType');
				const { params } = options[subType];
				if (attrs && translate) {
					if (attrs.name && attrs.name.text) {
						attrs.name.text = translate.instant(attrs.name.text);
					}
					// Переводим опции блоков
					if (attrs['.subheader-option-name']?.text)
						attrs['.subheader-option-name'].text =
							translate.instant(
								attrs['.subheader-option-name'].text
							);
					if (attrs['.footer-option-name']?.text)
						attrs['.footer-option-name'].text = translate.instant(
							attrs['.footer-option-name'].text
						);

					if (
						subType === 'scheduleStart' &&
						attrs[`.desc__block_${params[0]?.port} .desc__text`]
							?.text
					) {
						const string = attrs[
							`.desc__block_${params[0].port} .desc__text`
						]?.text as string;
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						attrs[
							`.desc__block_${params[0].port} .desc__text`
						]!.text = translate.instant(string);
					}
				}
			}
			return model;
		});
	} catch (e) {
		// Invalid JSON format
	}
}

export function undoAction(service: RappidInterface) {
	const { commandManager } = service;
	commandManager!.undo({ injector: service.injector });
}

export function redoAction(service: RappidInterface) {
	const { commandManager } = service;
	commandManager!.redo({ injector: service.injector });
}

export function toggleFullScreen(service: RappidInterface) {
	// service.bottomBarService.isFullScreenMod =
	// 	!service.bottomBarService.isFullScreenMod;
	service.bottomBarService.toggleFullScreen(service);
	// util.toggleFullScreen(service.canva);
}
export function setLinkColor(link: dia.Link, color: string) {
	link.attr(
		{
			line: {
				stroke: color,
				targetMarker: {
					fill: color
				}
			}
		},
		{ noHistory: true }
	);
}
export function removeCell(cell: dia.Cell) {
	cell.remove();
}
