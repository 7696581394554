import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared';
import { EnButtonModule, EnDialogModule, EnInputModule } from 'ui-lib';
import { KeyFilterModule } from 'primeng/keyfilter';

import { ContactsLimitDialogComponent } from './contacts-limit-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		EnDialogModule,
		EnButtonModule,
		EnInputModule,
		KeyFilterModule
	],
	declarations: [ContactsLimitDialogComponent],
	exports: [ContactsLimitDialogComponent]
})
export class ContactsLimitDialogModule {}
