import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EnDialogModule } from 'ui-lib/dialog/dialog';
import { SharedModule } from '@shared';
import { MatMenuModule } from '@angular/material/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SidebarModule } from 'primeng/sidebar';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

import { EnMultiplierPipeModule, SystemTagModule } from '@enkod-core/pipes';
import {
	EnButtonModule,
	EnCalendarModule,
	EnDropdownCalendarNewModule,
	EnDropdownCalendarRangeModule,
	EnDropdownModule,
	EnInputModule,
	EnMultilevelDropdownModule,
	EnMultiSelectModule,
	EnSmallModule,
	EnTagModule,
	EnToggleButtonModule,
	HtmlPreviewDirectiveModule,
	EnClicksMapModule,
	MailingGroupInfoModule,
	SearchModule
} from 'ui-lib';
import { TuiLetModule, TuiMapperPipeModule } from '@taiga-ui/cdk';

import { IMaskModule } from 'angular-imask';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TippyModule } from '@ngneat/helipopper';
import { MessageTableModule } from '@enSend/_shared/components';
import { TabViewModule } from 'primeng/tabview';
import { TuiScrollbarModule } from '@enkod-core/components';
import { SegmentsTreeComponent } from './segments-tree.component';

import {
	ActionCountComponent,
	ActionTimeComponent,
	ConditionToolsComponent
} from './conditions/common/components';

import {
	FieldConditionComponent,
	FieldDateConditionComponent,
	SubscriptionConditionComponent,
	SubscriptionDateConditionComponent,
	UnsubscriptionConditionComponent,
	TagConditionComponent,
	VisitsConditionComponent,
	CartConditionComponent,
	ProductConditionComponent,
	PurchaseConditionComponent,
	FavouriteConditionComponent,
	MessageComponent,
	SqlRequestComponent,
	SqlParamsComponent,
	SqlPreviewComponent,
	BirthdayDateComponent,
	CategoriesConditionComponent,
	RfmConditionComponent,
	UnsubscribeReasonComponent,
	SegmentSelectionModalComponent,
	ComparisonConditionComponent
} from './conditions/message-conditions';
import {
	MessageAddBounceComponent,
	MessageAddCountComponent,
	MessageAddTagComponent,
	MessageAddTypeComponent,
	MessageAddLinkComponent,
	LinkPickerDialogComponent,
	AddOnlyUniqMessageComponent,
	AdditionHidePipe,
	AddConditionButtonComponent,
	SelectedMessageItemComponent
} from './conditions/message-conditions/2-message';
import {
	EventParamСonditionComponent,
	UserEventsСonditionComponent,
	EventButtonComponent
} from './conditions/message-conditions/5-user-events';
import {
	CloseConditionComponent,
	DeviceConditionComponent,
	ReferrersConditionComponent,
	ScrollConditionComponent,
	TimeConditionComponent,
	UtmConditionComponent,
	UrlConditionComponent,
	HtmlConditionComponent,
	VisitsCountComponent,
	CartChangeConditionComponent,
	LocalStorageConditionComponent
} from './conditions/popup-conditions';

const DATA_CONDITIONS = [
	FieldConditionComponent,
	FieldDateConditionComponent,
	SubscriptionConditionComponent,
	SubscriptionDateConditionComponent,
	UnsubscriptionConditionComponent,
	TagConditionComponent,
	RfmConditionComponent,
	BirthdayDateComponent
];

const BEHAVIOR_CONDITIONS = [
	CloseConditionComponent,
	DeviceConditionComponent,
	ReferrersConditionComponent,
	ScrollConditionComponent,
	CartChangeConditionComponent,
	TimeConditionComponent,
	UtmConditionComponent,
	UrlConditionComponent,
	VisitsConditionComponent,
	HtmlConditionComponent,
	VisitsCountComponent,
	ComparisonConditionComponent,
	LocalStorageConditionComponent,
	CategoriesConditionComponent
];

const BUYING_CONDITIONS = [
	CartConditionComponent,
	ProductConditionComponent,
	PurchaseConditionComponent,
	FavouriteConditionComponent
];

const MESSAGE_CONDITIONS = [
	MessageAddBounceComponent,
	MessageAddCountComponent,
	MessageAddTagComponent,
	MessageAddTypeComponent,
	MessageAddLinkComponent,
	LinkPickerDialogComponent,
	MessageComponent,
	AdditionHidePipe,
	SelectedMessageItemComponent,
	AddConditionButtonComponent,
	AddOnlyUniqMessageComponent
];

const DATA_TABLES_CONDITIONS = [
	SqlParamsComponent,
	SqlPreviewComponent,
	SqlRequestComponent
];

const USER_EVENTS_CONDITIONS = [
	UserEventsСonditionComponent,
	EventButtonComponent,
	EventParamСonditionComponent
];

const USING_SEGMENTS_CONDITIONS = [SegmentSelectionModalComponent];

const CONDITIONS = [
	...DATA_CONDITIONS,
	...BEHAVIOR_CONDITIONS,
	...BUYING_CONDITIONS,
	...MESSAGE_CONDITIONS,
	...DATA_TABLES_CONDITIONS,
	...USER_EVENTS_CONDITIONS,
	...USING_SEGMENTS_CONDITIONS
];

const COMMON_COMPONENTS = [
	ActionTimeComponent,
	ActionCountComponent,
	ConditionToolsComponent,
	UnsubscribeReasonComponent
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		MatMenuModule,
		EnInputModule,
		EnButtonModule,
		EnDropdownModule,
		EnToggleButtonModule,
		EnCalendarModule,
		IMaskModule,
		EnMultiSelectModule,
		KeyFilterModule,
		TippyModule,
		EnMultiplierPipeModule,
		InputTextareaModule,
		MailingGroupInfoModule,
		TuiMapperPipeModule,
		TuiLetModule,
		SidebarModule,
		CodemirrorModule,
		EnDropdownCalendarNewModule,
		EnDropdownCalendarRangeModule,
		EnMultilevelDropdownModule,
		TranslateModule,
		EnDialogModule,
		SearchModule,
		MessageTableModule,
		TabViewModule,
		EnTagModule,
		SystemTagModule,
		TuiScrollbarModule,
		EnSmallModule,
		HtmlPreviewDirectiveModule,
		EnClicksMapModule
	],
	declarations: [SegmentsTreeComponent, ...CONDITIONS, ...COMMON_COMPONENTS],
	exports: [SegmentsTreeComponent]
})
export class SegmentsTreeModule {}
