<div class="enpop-card"
     [ngClass]="{'selectable': selectable}"
     (mouseenter)="showTools = true"
     (mouseleave)="showTools = false">

    <ng-container *ngIf="showSelectWinner">
        <div class="select-winner">{{ 'popup_list.select_winner_block' | translate }}</div>
    </ng-container>

    <ng-container *ngIf="showFullscreenButton">
        <div *ngIf="showTools"
             class="card__full">
            <button enButton
                    class="en-button-solid h32 full"
                    icon="en en-expand"
                    (click)="fullScreen.emit()"></button>
        </div>
    </ng-container>

    <ng-container *ngIf="showVersionsToggler">
        <div class="versions">
            <div class="version"
                 *ngFor="let version of versions; index as idx"
                 [ngClass]="{'selected': previewLabel === version.splitTestLabel}"
                 (click)="changePreview(version.splitTestLabel, idx)">{{ version.splitTestLabel }}</div>
        </div>
    </ng-container>

    <img enImgDef
         class="enpop-card__img"
         [src]="previewUrl"
         alt="no preview"
         loading="lazy"
         (click)="selectPopup.emit(popup.id)">
    <div class="enpop-card__name">
        <span class="id-badge">id {{popup.id}}</span>
        <span class="text-overflow">{{popup.name}}</span>
    </div>

    <ng-container *ngIf="withTools">
        <div *ngIf="showTools"
             [@popUp]
             class="enpop-card__tools">
            <en-button *ngIf="!isDraft && !isSelectWinner"
                       [title]="isActive
                           ? ('popup_list.menu_deactive' | translate)
                           : ('popup_list.menu_active' | translate)"
                       styleClass="en-button-lowercase"
                       [icon]="isActive ? 'en en-pause-circle-o' : 'en en-play-circle-o'"
                       (onClick)="changeStatus.emit({
                               id: popup.id,
                               name: popup.name,
                               status: popup.status === 'active'
                                       ? 'inactive' : 'active'
                           })"></en-button>
            <en-button [title]="'common.edit' | translate"
                       styleClass="en-button-lowercase"
                       icon="en en-pencil-o"
                       (onClick)="handleAction.emit({ mode: 'edit', id: popup.id })"></en-button>
            <en-button [title]="'common.create_copy' | translate"
                       styleClass="en-button-lowercase"
                       icon="en en-copy"
                       (onClick)="handleAction.emit({ mode: 'copy', id: popup.id })"></en-button>
            <en-button [title]="'common.delete' | translate"
                       styleClass="en-button-lowercase"
                       icon="en en-trash"
                       (onClick)="deletePopup.emit({
                      id: popup.id,
                      name: popup.name
                   })"></en-button>
        </div>
    </ng-container>

</div>
