import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChannelLinstener, WebsocketService } from '@enkod-core/websocket';
import { TestSendingService } from './_state/test-sending.service';
import { TestChannelType, TestStata } from './types';
import { TestSendingQuery } from './_state/test-sending.query';

@UntilDestroy()
@Component({
	selector: 'en-dialog-test-sending',
	templateUrl: './dialog-test-sending.component.html',
	styleUrls: ['./dialog-test-sending.component.scss'],
	providers: [TestSendingService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogTestSendingComponent implements OnInit {
	selected: string | string[] = '';

	statistics: TestStata[] = [];
	id = 0;

	readonly tokenValue = new FormControl('');

	@Input() selectedSystem: FormControl;
	@Input() isVisible: boolean;
	@Input() testChannelType: TestChannelType;

	@Output() onSubmitTest = new EventEmitter();
	@Output() onClose = new EventEmitter();

	constructor(
		private wsService: WebsocketService,
		private testSendingService: TestSendingService,
		private testSendingQuery: TestSendingQuery,
		private cd: ChangeDetectorRef
	) {}

	get isDisabled(): boolean {
		return !this.selected || !this.selected.length;
	}

	get isEmpty(): boolean {
		return !this.statistics.length;
	}

	ngOnInit(): void {
		this.testSendingService.connectToWs(this.testChannelType);

		this.wsService.channelEventArray$
			.pipe(
				tap((value: ChannelLinstener[]) => {
					this.testSendingService.setHandlers(value);
				}),
				untilDestroyed(this)
			)
			.subscribe();

		this.testSendingQuery.selectAll$
			.pipe(
				tap(value => {
					this.statistics = value.statistics;
					this.id = value.id;
				}),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this.cd.detectChanges();
			});

		this.testSendingService
			.updateStata()
			.pipe(untilDestroyed(this))
			.subscribe();
	}

	handleChange(value: string | string[]) {
		this.selected = value;
	}

	add(id: number) {
		if (typeof this.selected === 'string') {
			this.testSendingService.addStata(
				{
					system:
						this.selectedSystem.value === 'android'
							? 'Android'
							: 'IOS',
					contact: this.selected,
					errorText: '',
					status: 'sending',
					testSendId: id
				},
				id
			);
		}
	}

	testSending() {
		const lastId = this.id + 1;
		this.add(lastId);

		this.onSubmitTest.emit({
			system: this.selectedSystem.value,
			contact: this.selected,
			testSendId: lastId
		});

		this.resetToken();
	}

	handleClose(event: any) {
		this.onClose.emit(event);
		this.resetToken();
	}

	cancel() {
		this.isVisible = false;
		this.resetToken();
	}

	resetToken() {
		if (this.tokenValue.value !== '') this.tokenValue.reset();
	}
}
