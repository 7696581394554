import { Injectable, Injector } from '@angular/core';

import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

import { Observable, of } from 'rxjs';

import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { EventStartComponent } from './event-start.component';

@Injectable()
export class EventStartService implements InspectorItemPlugin {
	private readonly customEventStartContent = new PolymorpheusComponent(
		EventStartComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'customEventStart',
				name: 'customEventStart',
				content: this.customEventStartContent
			}
		]);
	}
}
