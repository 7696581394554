import { dia } from '@clientio/rappid';

export type Properties = {
	[property: string]: dia.Path;
};

export abstract class AbstractInspector {
	public props: Properties;

	public cell: dia.Cell;

	public changeCellProp(path: dia.Path, value: any): void {
		this.cell.prop(path, value);
	}
}
