import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TuiScrollbarModule } from '@enkod-core/components';
import { SharedModule } from '@shared';
import { WrapLinkModule } from '@enSend/message/message-wizard/kit/directives';
import { EnButtonModule } from 'ui-lib';
import { SmsPreviewComponent } from './sms-preview.component';
import { PeviewIosComponent } from './peview-ios/peview-ios.component';
import { PreviewAndroidComponent } from './preview-android/preview-android.component';
import { EnBubbleMessageModule } from './bubble-message/bubble-message.module';
import { AndroidMockComponent } from './preview-android/android-mock/android-mock.component';
import { IosMockComponent } from './peview-ios/ios-mock/ios-mock.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		EnButtonModule,
		EnBubbleMessageModule,
		TuiScrollbarModule,
		WrapLinkModule
	],
	declarations: [
		SmsPreviewComponent,
		PeviewIosComponent,
		PreviewAndroidComponent,
		AndroidMockComponent,
		IosMockComponent
	],
	exports: [SmsPreviewComponent]
})
export class SmsPreviewModule {}
