import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared';
import { EnDropdownModule } from 'ui-lib';
import { StartComponent } from './start.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { StartService } from './start.service';

@NgModule({
	declarations: [StartComponent],
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnDropdownModule
	],
	exports: [StartComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: StartService,
			multi: true
		}
	]
})
export class StartModule {}
