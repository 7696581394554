<div class="addition"
     [class.highlight]="isHover">
    <span class="center-span-text prefix">{{ 'segment_form.cond_message_prop_tag' | translate }}</span>
    <en-multiSelect [options]="tags$ | async"
                    [filter]="true"
                    filterBy="label,value"
                    [ngStyle]="{display: 'flex'}"
                    [emptyFilterMessage]="'segment_form.cond_message_prop_tag_empty' | translate"
                    [useInputWidth54]="true"
                    [showToggleAll]="false"
                    [showCloseButton]="false"
                    [useSelectAllItem]="true"
                    [placeholder]="'segment_common.select_placeholder' | translate"
                    [formControl]="control"
                    [isInvalid]="markAsInvalid || isHover"
                    #tags>
        <ng-template let-value
                     pTemplate="selectedItems">
            <div style="display: flex;"
                 *ngIf="value && value.length < 3">
                <en-tag *ngFor="let val of value"
                        class="condition__message-tag"
                        status="message"
                        [value]="val | systemTag"
                        [defaultValue]="val"></en-tag>
            </div>
            <div *ngIf="!value || value.length === 0">{{'segment_common.select_placeholder' | translate}}</div>
            <span *ngIf="value && value.length >= 3"
                  class="ui-multiselected-empty-token">
                {{ 'segment_form.cond_message_prop_tag_selected' | translate: { amount: value.length } }}
            </span>
        </ng-template>
        <ng-template let-entity
                     pTemplate="item">
            <en-tag status="message"
                    [value]="entity.value | systemTag"
                    [defaultValue]="entity.value"></en-tag>
        </ng-template>
    </en-multiSelect>
    <i class="button-remove-condition cancel-icon en en-cancel-fill"
       (mouseenter)="isHover = true"
       (mouseleave)="isHover = false"
       (click)="remove.emit()"></i>
</div>