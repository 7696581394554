import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit
} from '@angular/core';
import { IPushPreview } from '../../../../message-wizard/message-wizard-common/step-content/2-step-editor/push-editor/push-editor.model';
import { SelectedViewPush } from '../message-preview.component';

@Component({
	selector: 'en-push-preview',
	templateUrl: './push-preview.component.html',
	styleUrls: ['./push-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PushPreviewComponent implements OnInit {
	@Input() view: SelectedViewPush;

	@Input() preview: IPushPreview;

	@Input() bordersNone: boolean;

	ngOnInit() {}
}
