import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({
	name: 'additionHide',
	pure: false
})
export class AdditionHidePipe implements PipeTransform {
	transform(value: SelectItem[]): boolean {
		const conditions = [...value];
		conditions.shift();
		return conditions.some(item => !item.disabled);
	}
}
