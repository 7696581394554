import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { TestStata } from '../types';

export interface TestSendingState {
	statistics: TestStata[];
	id: number;
}

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'testSending'
})
export class TestSendingStore extends EntityStore<TestSendingState> {
	constructor() {
		super({
			statistics: [],
			id: 0
		});
	}
}
