import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TabViewModule } from 'primeng/tabview';
import { TippyModule } from '@ngneat/helipopper';
import {
	AutoCompleteModule,
	EnButtonModule,
	EnDialogModule,
	EnInputModule,
	EnSmallModule,
	ErrorModule
} from 'ui-lib';
import { SharedModule } from '@shared';

import { SendTestService } from './send-test.service';
import { TestSnippetsFormService } from './test-snippets/test-snippets-form.service';

import { SendTestComponent } from './send-test.component';
import { TestMailsComponent } from './test-mails/test-mails.component';
import { TestSnippetsComponent } from './test-snippets/test-snippets.component';
import { SnippetInputsComponent } from './test-snippets/snippet-inputs/snippet-inputs.component';
import { TestMailsService } from './test-mails/test-mails.service';

/**
 * @deprecated
 * Новый модуль лежит в папке dialogs */
@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		EnDialogModule,
		EnButtonModule,
		TabViewModule,
		EnInputModule,
		SharedModule,
		AutoCompleteModule,
		ErrorModule,
		EnSmallModule,
		TippyModule
	],
	declarations: [
		SendTestComponent,
		TestMailsComponent,
		TestSnippetsComponent,
		TestSnippetsComponent,
		SnippetInputsComponent
	],
	exports: [SendTestComponent],
	providers: [SendTestService, TestSnippetsFormService, TestMailsService]
})
export class SendTestModule {}
