import { ID } from '@datorama/akita';
import { UrlParams } from './entity-params.model';

export type ActionMobPush = 'url' | 'deeplink' | 'openapp';

export interface ButtonsMobPush {
	action: ActionMobPush;
	label: string;
	link?: string;
}

export interface MobPushEntity {
	id: ID;
	messageId: ID;

	subject: string;
	body: string;
	link?: string;
	action: ActionMobPush;
	smallImage: string;
	image: string;
	appName: string;

	buttons: ButtonsMobPush[];

	urlParams: UrlParams;
}
