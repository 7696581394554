<div class="attachments__description"
     [innerHtml]="attachmentDescription | translate"></div>

<div class="attachments__list">
    <ng-container *ngFor="let attachment of attachments; let i = index">
        <div class="attachments__item">
            <div class="attachments__name-wrapper text-overflow">
                <i class="en" [ngClass]="attachment.isLoading ? 'en-spiner' : 'en-list-o'"></i>
                <div class="attachments__name text-overflow">{{ attachment.name }}</div>
            </div>

            <div class="attachments__size-wrapper">
                <div *ngIf="!attachment.isLoading"
                     class="attachments__size en-text-secondary">
                    {{ attachment.size | convertSize:2 }}
                </div>
                <i class="en cancel-icon en-cancel-fill"
                   (click)="deleteAttachment(i)"></i>
            </div>
        </div>
    </ng-container>
</div>

<div class="file-upload custom-file-upload">
    <label  for="customFileUpload"
            class="en-button en-button-secondary">
            {{ 'message_wizard_edit_step.email_attachment_download' | translate }}
    </label>
    <input  id="customFileUpload"
            type="file"
            (change)="onFileSelected($event)"/>
</div>

<div *ngIf="message[0]?.detail"
     class="attachments__error-message">
    <en-messages class="en-message"
                 [(value)]="message"></en-messages>
</div>
