import { SelectItem } from 'primeng/api';

export const CHART_COLORS: string[] = [
	'eef2fc',
	'dde5f9',
	'cbd9f5',
	'baccf2',
	'a8c0f0',
	'97b4ed',
	'86a7e9',
	'759ae6',
	'638ee3',
	'5281e0'
];

export const CHART_TYPE_OPTIONS: SelectItem[] = [
	{ label: 'rf_chart.rf_option', value: 'rf' }
];
