import { SelectItem } from 'primeng/api';

export const OPTIONS_CATEGORY: SelectItem[] = [
	{
		label: 'segment_form.options_message_send',
		value: 'send'
	},
	{
		label: 'segment_message_options.not_send_option',
		value: 'not_send'
	},
	{
		label: 'segment_form.options_message_open',
		value: 'open'
	},
	{
		label: 'segment_message_options.not_open_option',
		value: 'not_open'
	},
	{
		label: 'segment_form.menu_condition_message_click',
		value: 'click'
	},
	{
		label: 'segment_message_options.not_click_option',
		value: 'not_click'
	},
	{
		label: 'segment_message_options.return_bounce_option',
		value: 'bounce'
	},
	{
		label: 'segment_message_options.not_return_bounce_option',
		value: 'not_bounce'
	},
	{
		label: 'segment_message_options.read_option',
		value: 'read'
	},
	{
		label: 'segment_message_options.not_read_option',
		value: 'not_read'
	}
];
export const OPTIONS_PARAMETER: SelectItem[] = [
	{
		label: 'segment_message_options.any_option',
		value: 'any'
	},
	{
		label: 'segment_message_options.specific_option',
		value: 'specific'
	}
];
