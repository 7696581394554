<section class="tools">
    <div class="tools__wrapper">
        <en-segments-filter [items]="filterItems"
                            [ngModel]="filterModel$ | async"
                            (ngModelChange)="onModelChange($event)"></en-segments-filter>
        <ng-container *ngTemplateOutlet="toolsTemplate"></ng-container>
    </div>

    <en-paginator [total]="total"
                  [paginatorRef]="paginatorRef"
                  [onLoadFromUrl]="!notSaveInUrl"
                  (onChangePerPage)="paginationService.setPerPageValue($event)"></en-paginator>
</section>

<p-table *ngIf="true"
         [columns]="cols"
         [value]="segments"
         [resizableColumns]="true"
         [reorderableColumns]="true"
         [customSort]="true"
         [lazy]="true"
         [rowHover]="true"
         dataKey="id"
         [loading]="paginatorRef.isLoading$ | async"
         [(selection)]="selectedEntity"
         [selectionPageOnly]="true"
         [sortField]="urlParams.sortKey"
         [sortOrder]="urlParams.sortOrder"
         (onSort)="paginationService.setSortValue($event)"
         (onHeaderCheckboxToggle)="selectedEntityChange.emit(selectedEntity)"
         (onRowSelect)="selectedEntityChange.emit(selectedEntity)"
         (onRowUnselect)="selectedEntityChange.emit(selectedEntity)">
    <ng-template pTemplate="header"
                 let-columns>
        <tr>
            <th *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="col.field==='tools' || col.field==='selectCheckbox' || col.sortableDisabled"
                pResizableColumn
                [pResizableColumnDisabled]="col.field==='selectCheckbox'"
                pReorderableColumn
                [pReorderableColumnDisabled]="!col.reorderableColumn"
                [style.width]="col.width"
                [style.min-width]="col['min-width']"
                valign="middle">
                <ng-container [ngSwitch]="col.field">
                    <ng-template ngSwitchCase="selectCheckbox">
                        <p-tableHeaderCheckbox [disabled]="isHeaderCheckboxDisabled"></p-tableHeaderCheckbox>
                    </ng-template>

                    <ng-template ngSwitchDefault>
                        <div class="en-header-column-wrapper">
                            <span class="text-overflow">{{col.header | translate}}</span>
                            <p-sortIcon *ngIf="!col.sortableDisabled"
                                        [field]="col.field"></p-sortIcon>
                        </div>
                    </ng-template>
                </ng-container>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-rowData
                 let-columns="columns"
                 let-index="rowIndex">
        <tr (mouseenter)="toolsPanel && tableToolsService.showTools(index, $event, rowData )"
            (mouseleave)="toolsPanel && tableToolsService.hideTools()"
            class="visible-selection-button">
            <td *ngFor="let col of columns;"
                [class.p-reorderable-column]="col.reorderableColumn">
                <ng-container [ngSwitch]="col.field">
                    <ng-template ngSwitchCase="selectCheckbox">
                        <p-tableCheckbox [value]="rowData"
                                         [disabled]="checkboxDisabled(rowData)"></p-tableCheckbox>
                    </ng-template>
                    <ng-template ngSwitchCase="name">
                        <div class="en-table-name">
                            <div class="en-table-name__name-block">
                                <a class="text-overflow en-table-name__preview-link" 
                                   [target]="segmentsInNewWindow ? '_blank' : '_self'"
                                   [routerLink]="['/segments/edit', rowData.id]"
                                   (click)="urlService.saveCurrentUrl()">
                                    {{ rowData[col.field] }}
                                </a>
                            </div>
                            <div *ngIf="selection" 
                                 class="en-table-name__selection">
                                <button enButton
                                        class="h24 en-button-secondary"
                                        [label]="'common.to_select'| translate"
                                        (click)="selected.emit(rowData)"></button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngSwitchCase="module">
                        <en-module-badge [value]="rowData[col.field]"></en-module-badge>
                    </ng-template>

                    <ng-template ngSwitchCase="tags">
                        <horizontal-scroll-wrapper>
                            <en-tag *ngFor="let tag of rowData[col.field]"
                                    [value]="tag"
                                    [defaultValue]="tag"
                                    showTooltip="true"
                                    status="message">
                            </en-tag>
                        </horizontal-scroll-wrapper>
                    </ng-template>

                    <ng-template ngSwitchCase="createDate">
                        <div class="en-table-name">
                            <div class="en-table-name__date-block">
                                {{ rowData[col.field] | convertDate : 'DD.MM.yyyy' }},
                            </div>
                            <div class="en-table-name__date-block-secondary">
                                {{ rowData[col.field] | convertDate : 'HH:mm' }}
                            </div>
                        </div>
                    </ng-template>

                    <ng-template ngSwitchDefault>{{ rowData[col.field ]}}</ng-template>
                </ng-container>
            </td>
        </tr>
        <div *ngIf="toolsPanel"
             class="tools-box"
             [ngStyle]="{ top: tableToolsService.offsetTop, height: tableToolsService.trHeight }"
             [class.show]="tableToolsService.tool && index === tableToolsService.chosenTr"
             [class.inactive]="index !== tableToolsService.chosenTr"
             (mouseenter)="tableToolsService.showTools(index)"
             (mouseleave)="tableToolsService.hideTools()">
            <en-button (click)="editEntity.emit(rowData)"
                       [title]="'table_tools.tools_button_edit' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-pencil-o"></en-button>
            <en-button (click)="copyEntity.emit(rowData)"
                       [title]="'table_tools.tools_button_copy' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-copy"></en-button>
            <en-button (click)="checkDeleted(rowData)"
                       [title]="'table_tools.tools_button_delete' | translate"
                       styleClass="en-button-lowercase h32"
                       icon="en en-trash"></en-button>
        </div>
    </ng-template>
    <ng-template pTemplate="emptymessage"
                 let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                <div class="empty-table">
                    <h3>{{'common.empty_table' | translate}}</h3>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<en-delete-segment-with-relations *ngIf="modal"
                                  [modal]="modal"
                                  [relations]="relations$ | async"
                                  [segmentName]="segmentName"
                                  (hide)="modal=false"></en-delete-segment-with-relations>

<en-related-data-dialog *ngIf="isModalShowSegment"
                        [dialogVisible]="isModalShowSegment"
                        [navigateUrl]="'/ensend/scenario/detail/'"
                        [infoText]="'segment_list.related_segments_text'"
                        [relatedData]="relatedSegments"
                        (closeDialog)="isModalShowSegment = false"></en-related-data-dialog>