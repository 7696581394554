import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TuiScrollbarModule } from '@enkod-core/components';

import {
	EnDialogModule,
	EnButtonModule,
	ErrorModule,
	AutoCompleteModule,
	EnSmallModule,
	EnInputModule,
	EnStatusTagModule,
	EnDropdownModule
} from 'ui-lib';
import { DialogTestSendingComponent } from './dialog-test-sending.component';
import { TestMobpushComponent } from './test-mobpush/test-mobpush.component';
import { TestSendingStatusTextPipe, TestSendingStatusVariant } from './pipes';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		EnDialogModule,
		EnButtonModule,
		ErrorModule,
		AutoCompleteModule,
		EnSmallModule,
		EnInputModule,
		EnStatusTagModule,
		ReactiveFormsModule,
		EnDropdownModule,
		TuiScrollbarModule
	],
	declarations: [
		DialogTestSendingComponent,
		TestMobpushComponent,
		TestSendingStatusTextPipe,
		TestSendingStatusVariant
	],
	exports: [DialogTestSendingComponent]
})
export class DialogTestSendingModule {}
