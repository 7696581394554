<div class="header">
    <span>{{'scenario_block_send_message.whatsapp_header' | translate}}</span>
    <button enButton
            class="en-button-lowercase h32"
            icon="en en-expand"
            (click)="isPreviewVisible = true"></button>
</div>
<div class="content">
    <p class="content__text"
       [innerHtml]="textContent$ | async"></p>
</div>

<en-whatsapp-dc-preview *ngIf="isPreviewVisible"
                        [template]="previewTemplate"
                        [messageId]="messageId"
                        [visible]="isPreviewVisible"
                        (hide)="isPreviewVisible = false"></en-whatsapp-dc-preview>