/* eslint-disable import/no-cycle */
import { Events } from 'backbone';
import { PreviewRappidService } from '@enSend/scenario/scenario-details/scenario-details-preview/preview-canva/services/preview-rappid.service';
import { RappidService } from '../services/rappid.service';

export enum SharedEvents {
	SELECTION_CHANGED = 'selection-changed',
	GRAPH_CHANGED = 'graph-changed'
}

type ControllerCallback<T> = (service: T, ...args: any[]) => void;

interface ControllerEventMap<T> {
	[eventName: string]: ControllerCallback<T>;
}

export abstract class Controller<T = RappidService | PreviewRappidService> {
	constructor(public readonly service: T) {
		this.startListening();
	}

	abstract startListening(): void;

	stopListening(): void {
		Events.stopListening.call(this);
	}

	protected listenTo(object: any, events: ControllerEventMap<T>): void {
		Object.keys(events).forEach(event => {
			const callback = events[event];
			if (typeof callback !== 'function') return;
			// Invoke the callback with the service argument passed first
			Events.listenTo.call(
				this,
				object,
				event,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore: Unreachable code error
				callback.bind(null, this.service)
			);
		});
	}
}
