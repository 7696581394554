<p-sidebar [(visible)]="sqlPreviewVisible"
           [showCloseIcon]="false"
           [position]="'right'">
    <div class="sql">
        <div class="sql__header">
            <h4 class="header__name">{{ selectedTemplate.name }}</h4>
            <button enButton
                    class="en-button-lowercase h32"
                    icon="en en-close"
                    (click)="onHide.emit()"></button>
        </div>
        <div class="sql__preview">
            <ngx-codemirror [(ngModel)]="selectedTemplate.sql"
                            [options]="options"></ngx-codemirror>
        </div>
    </div>
</p-sidebar>
