import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-cart-change-condition',
	templateUrl: './cart-change-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartChangeConditionComponent extends AbstractCondition {
	setValidation() {
		this.getSubscription('value')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (value < 1) {
					this.isInit = false;
					this.setValue('value', 1);
				}
				if (value > 9999) {
					this.isInit = false;
					this.setValue('value', 9999);
				}
			});
	}
}
