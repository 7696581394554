<div>
    <tui-hosted-dropdown tuiDropdownAlign="left"
                         [content]="dropdown"
                         [(open)]="isOpenMenu">
        <button *ngIf="isButtonMode && !label"
                type="button"
                enButton
                class="h32"
                [class.en-button-secondary]="useSecondaryButton"
                [class.en-segments-button-icon]="useSegmentsButton"
                [icon]="'en ' + icon"
                [attr.disabled]="disableInput"
                (click)="onButtonClick.emit()"></button>
        <button *ngIf="isButtonMode && label"
                type="button"
                enButton
                class="h32"
                [label]="label | translate"
                iconPos="left"
                [class.en-button-secondary]="useSecondaryButton"
                [class.en-segments-button-icon]="useSegmentsButton"
                [icon]="'en ' + icon"
                [attr.disabled]="disableInput"
                (click)="onButtonClick.emit()"></button>
        <span *ngIf="!isButtonMode"
              class="dropdown"
              [class.hideRightBorder]="hideRightBorder"
              [class.disabled-input]="disabled">
            <input enInput
                   #input
                   class="dropdown__input h32"
                   readonly
                   [class.disabled-input]="disabled"
                   [placeholder]="placeholder | translate"
                   [attr.disabled]="disableInput"
                   [formControl]="labelControl" />
            <div class="dropdown__icon"
                 (click)="isOpenMenu = !isOpenMenu">
                <i class="en en-sort-down"></i>
            </div>
        </span>
    </tui-hosted-dropdown>
</div>
<ng-template #dropdown
             let-activeZone>
    <div class="menu"
         [style.min-width]="menuWidth"
         [tuiActiveZoneParent]="activeZone">
        <div *ngFor="let option of _options"
             [class.menu__title]="option.isTitle"
             [class.menu__item]="option.isSubmenu"
             [class.menu__string]="!option.isTitle && !option.isSubmenu"
             [class.menu-main]="useMainMenuStyle"
             [class.menu-main-item]="useMainMenuStyle"
             [class.menu-first-item]="useMainMenuStyle && !option.isTitle"
             (mouseenter)="openSecondMenu(option)"
             (click)="selectOption(option)">
            <div *ngIf="option.isTitle"
                 class="menu__title-group">
                <span>{{option.label | translate}}</span>
                <span *ngIf="useExtraButtonText"
                      class="menu__extra-button"
                      (click)="onExtraButtonClick()">{{useExtraButtonText | translate}}</span>
            </div>
            <span *ngIf="!option.isTitle && !option.isSubmenu">{{option.label | translate}}</span>
            <tui-hosted-dropdown *ngIf="option.isSubmenu"
                                 class="menu__dropdown"
                                 tuiDropdownAlign="right"
                                 [tuiDropdownSided]="true"
                                 [content]="secondDrop"
                                 [(open)]="option.isOpen">
                <div class="menu__child"
                     [class.menu-main-item]="useMainMenuStyle">
                    <span>{{option.label | translate}}</span>
                    <i class="en en-chevron-right margin-right"></i>
                </div>
            </tui-hosted-dropdown>
            <!-- Второй уровень вложенности -->
            <ng-template #secondDrop
                         let-activeZone>
                <div class="menu expanded"
                     [tuiActiveZoneParent]="activeZone">
                    <div *ngFor="let secondItem of option.submenuItems"
                         [class.menu__item]="secondItem.isSubmenu"
                         [class.menu__string]="!secondItem.isSubmenu"
                         [class.menu-main-item]="useMainMenuStyle"
                         (click)="selectOption(secondItem)">
                        <span *ngIf="!secondItem.isSubmenu"
                              (mouseenter)="openSecondMenu(option); openTrirdMenu(secondItem)">
                            {{secondItem.label | translate}}</span>
                        <tui-hosted-dropdown *ngIf="secondItem.isSubmenu"
                                             class="menu__dropdown"
                                             tuiDropdownAlign="right"
                                             [tuiDropdownSided]="true"
                                             [content]="thirdDrop"
                                             [(open)]="secondItem.isOpen"
                                             (mouseenter)="openSecondMenu(option); openTrirdMenu(secondItem)">
                            <div class="menu__child">
                                <span>{{secondItem.label | translate}}</span>
                                <i class="en en-chevron-right"></i>
                            </div>
                        </tui-hosted-dropdown>
                        <!-- Третий уровень вложенности -->
                        <ng-template #thirdDrop>
                            <div class="menu">
                                <div *ngFor="let thirdItem of secondItem.submenuItems"
                                     class="menu__string"
                                     (click)="selectOption(thirdItem)">
                                    <span>{{thirdItem.label | translate}}</span>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>