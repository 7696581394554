import { TranslateService } from '@ngx-translate/core';
import { ShapeTypesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';
import { DateAccountTimezoneService } from '@enkod-core/services';
import { declinationWord } from '@enkod-core/utils';
import {
	getDistributionSubheader,
	getExtraFieldSubheader,
	getMailingGroupSubheader,
	getMessageStartSubheader,
	getPauseSubheader,
	getChannelSubheader
} from '@enSend/_shared/components/scenario-canva/rappid/shapes/app.shapes.ts/base.shapes/utils/getSubheaderText';
import { block } from '@enSend/_shared/components/scenario-canva/rappid/shapes/app.shapes.ts/base.shapes/block.shape';
import { FieldValue, OnChangeOptions, Option } from '@enSend/_shared/models';
import { operatorTranslates } from '@enSend/_shared/constants';
import { separateThousands } from '../../../misc/separate-thousands';

export const previewBlock = block.define(
	ShapeTypesEnum.BASE,
	{
		size: { width: 200, height: 112 },
		ports: {
			groups: {
				in: {
					size: {
						width: 0,
						height: 0
					},
					attrs: {
						portBody: {
							fill: 'transparent',
							stroke: 'transparent',
							strokeWidth: 0
						}
					}
				},
				out: {
					size: {
						width: 0,
						height: 0
					},
					attrs: {
						portBody: {
							fill: 'transparent',
							stroke: 'transparent',
							strokeWidth: 0
						}
					}
				}
			}
		},
		attrs: {
			peopleCounter: {
				refY: '100%'
			},

			peopleCounterIcon: {
				refY: -22,
				refX: 12,
				d: 'M8.00001 7.99977C7.30612 8.0077 6.62554 7.80919 6.04473 7.42946C5.46392 7.04973 5.0091 6.50592 4.73805 5.86711C4.467 5.22831 4.39196 4.52335 4.52245 3.84181C4.65295 3.16026 4.9831 2.53289 5.47095 2.03939C5.9588 1.5459 6.58234 1.20856 7.26235 1.07024C7.94235 0.931917 8.64812 0.998854 9.29 1.26254C9.93187 1.52623 10.4809 1.97478 10.8673 2.55118C11.2536 3.12759 11.46 3.80584 11.46 4.49977C11.4626 4.95678 11.3752 5.40982 11.2028 5.83304C11.0303 6.25626 10.7762 6.64137 10.4549 6.96638C10.1336 7.29139 9.75144 7.54992 9.33023 7.72724C8.90902 7.90455 8.45701 7.99715 8.00001 7.99977ZM8.00001 2.51977C7.60444 2.51977 7.21777 2.63707 6.88887 2.85683C6.55997 3.0766 6.30362 3.38895 6.15225 3.75441C6.00087 4.11986 5.96127 4.52199 6.03844 4.90995C6.11561 5.29792 6.30609 5.65428 6.58579 5.93399C6.8655 6.21369 7.22187 6.40417 7.60983 6.48134C7.99779 6.55851 8.39992 6.51891 8.76537 6.36753C9.13083 6.21616 9.44318 5.95981 9.66295 5.63091C9.88271 5.30201 10 4.91534 10 4.51977C10 3.98934 9.78929 3.48063 9.41422 3.10556C9.03915 2.73049 8.53044 2.51977 8.00001 2.51977ZM10.25 14.9998H5.75001C4.73306 14.9361 3.77875 14.4867 3.08185 13.7434C2.38496 13 1.99802 12.0187 2.00001 10.9998C2.00263 10.5365 2.18785 10.0929 2.51547 9.76524C2.84309 9.43762 3.28669 9.2524 3.75001 9.24977H12.25C12.7133 9.2524 13.1569 9.43762 13.4845 9.76524C13.8122 10.0929 13.9974 10.5365 14 10.9998C14.002 12.0187 13.6151 13 12.9182 13.7434C12.2213 14.4867 11.267 14.9361 10.25 14.9998ZM3.75001 10.7498C3.6837 10.7498 3.62012 10.7761 3.57323 10.823C3.52635 10.8699 3.50001 10.9335 3.50001 10.9998C3.4923 11.623 3.71952 12.2262 4.13645 12.6895C4.55338 13.1527 5.12944 13.442 5.75001 13.4998H10.25C10.8706 13.442 11.4466 13.1527 11.8636 12.6895C12.2805 12.2262 12.5077 11.623 12.5 10.9998C12.5 10.9335 12.4737 10.8699 12.4268 10.823C12.3799 10.7761 12.3163 10.7498 12.25 10.7498H3.75001Z',
				fill: '#535766'
			},
			peopleCounterText: {
				refY: -19,
				refX: 38
			},
			'.people-counter__text': {
				fontSize: 12,
				fill: '#434652',
				fontFamily: 'var(--en-font-family)'
			}
		}
	},
	{
		markup: [
			{
				tagName: 'path',
				className: 'en-body',
				selector: 'body'
			},
			{
				tagName: 'g',
				selector: 'header',
				children: [
					{ tagName: 'rect', selector: 'headerRect' },
					{
						tagName: 'image',
						selector: 'headerIcon'
					}
				]
			},
			{
				tagName: 'g',
				className: 'subheader-option',
				selector: 'subheaderOption'
			},
			{
				tagName: 'g',
				className: 'desc-options',
				selector: 'descContainer'
			},
			{
				tagName: 'g',
				className: 'footer-option',
				selector: 'footerOption'
			},
			{ tagName: 'text', selector: 'name' },
			{
				tagName: 'g',
				selector: 'portAddButton',
				children: [
					{
						tagName: 'rect',
						selector: 'portAddButtonBody'
					},
					{
						tagName: 'path',
						selector: 'portAddButtonIcon'
					}
				]
			},
			{
				tagName: 'g',
				selector: 'peopleCounter',
				className: 'people-counter',
				children: [
					{
						tagName: 'path',
						selector: 'peopleCounterBackground'
					},
					{
						tagName: 'path',
						selector: 'peopleCounterIcon'
					},
					{
						tagName: 'text',
						selector: 'peopleCounterText',
						className: 'people-counter__text'
					}
				]
			}
		],

		autoresize() {
			const gap = this.get('paddingBottom');
			const questionHeight = this.get('questionHeight');
			const padding = this.get('padding');
			const peopleCounter = 28;
			const optionsHeight = this.blockLayout.options[0]?.label
				? this.blockLayout.options.length *
				  (this.get('optionHeight') + padding)
				: 0;
			const subheaderHeight = this.blockLayout.subheader ? 24 : 0;
			const footerHeight = this.blockLayout.footer ? 24 : 0;
			const height =
				optionsHeight +
				subheaderHeight +
				footerHeight +
				questionHeight +
				gap +
				peopleCounter;
			this.resize(200, height);
		},

		initialize(...args: any) {
			block.prototype.initialize.apply(this, args);
			this.pauseLabelFix();
		},

		onChangeOptions(changeOptions: OnChangeOptions) {
			const type = this.get('type');
			const options = this.get('options');
			const subType = this.get('subType');
			const optionHeight = this.get('optionHeight');

			const translate = this.injector?.get(TranslateService);
			const timezoneService = this.injector?.get(
				DateAccountTimezoneService
			);

			// First clean up the previously set attrs for the old options object.
			// We mark every new attribute object with the `dynamic` flag set to `true`.
			// This is how we recognize previously set attributes.
			const attrs = this.get('attrs');

			Object.keys(attrs).forEach((selector: any) => {
				if (attrs[selector].dynamic) {
					// Remove silently because we're going to update `attrs`
					// later in this method anyway.
					this.removeAttr(selector, { silent: true });
				}
			});

			// Collect new attrs for the new options.
			let offsetY = 0;
			const attrsUpdate = {} as any;
			const padding = this.get('padding');

			this.blockLayout = this.getBlockLayout();

			this.blockLayout.options.forEach((option: Option, i: number) => {
				if (changeOptions.updatePorts) this.updatePort(option);

				const selector = `.desc__block_${option.port}`;

				attrsUpdate[selector] = {
					transform: `translate(0, ${offsetY})`,
					dynamic: true
				};
				attrsUpdate[`${selector} .desc__rect`] = {
					height: optionHeight,
					dynamic: true
				};
				attrsUpdate[`${selector} .desc__text`] = {
					text: option.label || '',
					dynamic: true,
					refY: optionHeight / 2
				};
				if (options[subType].distributionType === 'scenarioFields') {
					attrsUpdate[`${selector} .desc__text`].y = -8;
					attrsUpdate[`${selector} .desc__value`] = {
						text: this.getDistrBlockValue(
							option,
							i,
							translate,
							timezoneService
						),
						dynamic: true,
						y: 8,
						refY: optionHeight / 2
					};
				}

				offsetY += optionHeight + padding;
			});

			if (this.blockLayout.subheader) {
				attrsUpdate['.subheader-option-name'] = {
					text: this.blockLayout.subheader
				};
			}

			if (this.blockLayout.footer) {
				attrsUpdate['.footer-option-name'] = {
					text: this.blockLayout.footer
				};
			}

			// счетчик контаков в блоке, динамичный по размеру
			const count = this.get('count');

			const countPassed = this.get('countPassed');

			const countText = separateThousands(count);

			const countPassedText = separateThousands(countPassed);

			const counterText =
				type === 'endBlock'
					? countPassedText
					: `${countText} / ${countPassedText}`;

			const spacesAmount = counterText
				.split('')
				.filter(s => s === ' ').length;

			// 3.5 - ширина пробела
			const spacesWidth = spacesAmount * 3.5;

			// ширина цифры
			const digitSize = 8;

			const width =
				type === 'endBlock'
					? digitSize * countPassed.toString().length + spacesWidth
					: digitSize *
							(count.toString().length +
								countPassed.toString().length) +
					  spacesWidth +
					  // ширина слеша
					  5;

			attrsUpdate[`.people-counter__text`] = {
				text: counterText,
				dynamic: true,
				textWrap: {
					width,
					maxLineCount: 1,
					ellipsis: true
				}
			};

			attrsUpdate.body = {
				d:
					'M12,0 h180 a8,8 0 0 1 8,8 v' +
					'calc(h - 44)' +
					` a8,8 0 0 1 -8,8 h${
						-132 + width
					} q-8 0 -8 8 v12 a8,8 0 0 1 -8,8 h${
						-36 - width
					} a8,8 0 0 1 -8,-8  v-` +
					'calc(h - 16)' +
					' a8,8 0 0 1 8,-8 z',
				fill: 'white'
			};

			attrsUpdate.peopleCounterBackground = {
				refY: '-28',
				d: `M20 0 h${41 + width} q${-8 - digitSize / width} 0.3 ${
					-9.2 + (width - digitSize) / 100
				} 8 v12.5 q0 5.6 -6 7.7 h${
					-39.8 - width - (width - digitSize) / 100
				} q-4.5 -1 -6.2 -6 v-22 Z`,
				fill: '#EAF3FB'
			};

			attrsUpdate['.people-counter'] = {
				'data-tippy-content':
					type === 'endBlock'
						? translate.instant(
								'scenario_details.end_block_tooltip',
								{
									countPassed: countPassedText
								}
						  )
						: translate.instant(
								'scenario_details.contacts_tooltip',
								{
									count: countText,
									countPassed: countPassedText
								}
						  )
			};

			this.attr(attrsUpdate);

			this.autoresize();
		},

		getBlockLayout() {
			const options = this.get('options');
			const subType = this.get('subType');

			switch (subType) {
				case 'mailingGroup':
					return {
						subheader: getMailingGroupSubheader(
							options.subscriptionType
						),
						options: options.mailingGroup.params
					};
				case 'apiStart':
					return {
						options: options.apiStart.params
					};
				case 'messageStart':
					return {
						subheader: getMessageStartSubheader(
							options.messageStartActionType
						),
						options: options.messageStart.params
					};
				case 'extraFieldStart':
					return {
						subheader: getExtraFieldSubheader(
							options.dataChangeType
						),
						options: options.extraFieldStart.params
					};
				case 'scheduleStart':
					return {
						options: options.scheduleStart.params
					};
				case 'customEventStart':
					return {
						options: options[subType].params
					};
				case 'pause':
					return {
						subheader: getPauseSubheader(options.pauseType),
						options: options.pause.params
					};

				case 'distribution':
					if (options[subType].distributionType === 'bySegments')
						return {
							subheader: getDistributionSubheader('bySegments'),
							options: options.distribution.params,
							footer: 'scenario_block_destribution.segment_option'
						};
					if (options[subType].distributionType === 'scenarioFields')
						return {
							subheader:
								getDistributionSubheader('scenarioFields'),
							options: options.distribution.params,
							footer: 'scenario_block_destribution.fields_footer'
						};
					if (options[subType].distributionType === 'equally')
						return {
							subheader: getDistributionSubheader('equally'),
							options: options.distribution.params
						};
					if (options[subType].distributionType === 'proportionally')
						return {
							subheader:
								getDistributionSubheader('proportionally'),
							options: options.distribution.params
						};
					return {
						options: options.distribution.params
					};
				case 'sendMessage':
					return {
						subheader: getChannelSubheader(
							options.sendMessage.params[0]?.type ?? ''
						),
						options: options.sendMessage.params
					};
				case 'dataChangeMain':
					return {
						subheader: getExtraFieldSubheader(options.dataType),
						options: options.dataChangeMain.params
					};
				case 'apiQuery':
					return {
						subheader: options.apiQuery.params[0]?.method,
						options: options.apiQuery.params
					};
				case 'finish':
					return {
						options: options.finish.params
					};
				default:
					return {
						options: []
					};
			}
		},

		pauseLabelFix() {
			const translate = this.injector?.get(TranslateService);
			const timezoneService = this.injector?.get(
				DateAccountTimezoneService
			);

			const options = this.get('options');
			const subType = this.get('subType');
			const { params } = options[subType];

			if (
				options.pauseType === 'scenarioFields' ||
				options.pauseType === 'extraFields'
			)
				return;

			if (
				this.get('subType') === 'pause' &&
				params.length &&
				params[0].unit === 'until'
			) {
				const dateLabelAccountTz = timezoneService.convertDate(
					params[0].value,
					'DD.MM.yyyy HH:mm'
				);

				this.prop(
					['options', 'pause', 'params'],
					[
						{
							label: `${dateLabelAccountTz}`
						}
					]
				);

				return;
			}

			if (
				this.get('subType') === 'pause' &&
				params.length &&
				params[0].unit !== 'until'
			) {
				const lowerCaseOption = translate.instant(
					declinationWord(params[0].unit, params[0].value)
				);

				this.prop(
					['options', 'pause', 'params'],
					[
						{
							label: `${params[0].value} ${lowerCaseOption}`
						}
					]
				);
			}
		},

		getDistrBlockValue(
			option: Option,
			i: number,
			translate: any,
			timezoneService: any
		) {
			const options = this.get('options');
			const subType = this.get('subType');

			const fieldType = options[subType].fields.find(
				(field: FieldValue) =>
					field.fieldName === option.scenarioFieldsCondition?.field
			).type;

			const operator = options[subType].fields[i].operator as string;

			let { value } = options[subType].fields[i];

			if (fieldType === 'date' && value)
				value =
					operator === 'between'
						? `${timezoneService.convertDate(
								Number(value.startDate),
								'DD.MM.yyyy'
						  )} - ${timezoneService.convertDate(
								Number(value.endDate),
								'DD.MM.yyyy'
						  )}`
						: timezoneService.convertDate(
								Number(value),
								'DD.MM.yyyy'
						  );

			if (fieldType === 'dateTime' && value)
				value =
					operator === 'between'
						? `${timezoneService.convertDate(
								Number(value.startDate),
								'DD.MM.yyyy HH:mm'
						  )} - ${timezoneService.convertDate(
								Number(value.endDate),
								'DD.MM.yyyy HH:mm'
						  )}`
						: timezoneService.convertDate(
								Number(value),
								'DD.MM.yyyy HH:mm'
						  );

			return `${translate.instant(
				operatorTranslates[operator] || operator
			)} ${value || ''}`;
		}
	}
);
