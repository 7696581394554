/* eslint-disable max-classes-per-file */
import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { FULL_URL_REGEX } from 'custom-validators';

import { UrlParams } from '../../../../message-wizard/message-wizard-common/models';

@Pipe({
	name: 'previewUrlParams'
})
export class PreviewUrlParamsPipe implements PipeTransform {
	transform(value: string, urlParams?: UrlParams): string {
		const urls: string[] | null = value.match(FULL_URL_REGEX);

		if (urls && urlParams && this.hasAnyParams(urlParams)) {
			let valueWithUtm = value;
			urls.forEach(url => {
				valueWithUtm = valueWithUtm.replace(
					url,
					this.utmLinkGenerator(urlParams, url)
				);
			});
			return valueWithUtm;
		}
		return value;
	}

	private hasAnyParams({ utm, params }: UrlParams): boolean {
		if (utm && Object.keys(utm).length) return true;
		if (params && Object.keys(params).length) return true;
		return false;
	}

	private utmLinkGenerator({ utm, params }: UrlParams, url: string): string {
		const paramsUrl = new URLSearchParams();

		if (utm) {
			Object.entries(utm).forEach(([key, value]) => {
				paramsUrl.set(key, value);
			});
		}

		if (params) {
			Object.entries(params).forEach(([key, value]) => {
				paramsUrl.set(key, value);
			});
		}

		return `${url}?${paramsUrl.toString()}`;
	}
}

@NgModule({
	declarations: [PreviewUrlParamsPipe],
	exports: [PreviewUrlParamsPipe]
})
export class PreviewUrlParamsPipeModule {}
