import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'en-module-badge',
	template: `
		<span class="badge">
			{{ text | translate }}
		</span>
	`,
	styleUrls: ['./module-badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleBadgeComponent implements OnInit {
	text: string;

	@Input() value: 'enSend' | 'enPop';

	constructor(private cd: ChangeDetectorRef) {}

	get isEnSend(): boolean {
		return this.value === 'enSend';
	}

	ngOnInit(): void {
		this.text = this.isEnSend
			? 'segment_common.for_message'
			: 'segment_common.for_popup';
		this.cd.markForCheck();
	}
}
