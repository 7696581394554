import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImgDefDirectiveModule } from '@enkod-core/directives';
import { SharedModule } from '@shared';
import { EnButtonModule } from 'ui-lib';
import { PopupCardComponent } from './popup-card.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		EnButtonModule,
		ImgDefDirectiveModule
	],
	declarations: [PopupCardComponent],
	exports: [PopupCardComponent]
})
export class PopupCardModule {}
