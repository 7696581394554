<div class="preview"
     [class.preview_full-screen]="fullScreenPhone"
     [ngStyle]="{ height: viewSettings.heightPreview ? viewSettings.heightPreview + 'px' : null }">

    <en-android-mock class="phone-mock"
                     [isDetail]="isDetail"></en-android-mock>

    <div class="iframe"
         [ngStyle]="{ top: viewSettings.topIframe + 'px', 'max-height': viewSettings.maxHeightIframe + 'px' }"
         [class.iframe_full-screen]="fullScreenPhone">
        <div #iframe
             class="iframe__wrapper"
             [ngStyle]="{ transform: transformSlide }">
            <div class="iframe__push">
                <div class="iframe__time-bg"></div>

                <div class="push"
                     (click)="toogleDetail()">
                    <div class="push__head">
                        <div class="push__image"></div>
                        <span class="push__sender">{{ 'message_wizard_edit_step.sms_preview_header' | translate }}</span>
                        <span style="padding: 0 0.25rem">&bull;</span>
                        <span class="push__time">{{ 'message_wizard_edit_step.sms_preview_timepointer' | translate }}</span>
                        <div class="push__arrow">
                            <span class="en en-arrow-down"></span>
                        </div>
                    </div>

                    <div class="push__content push-content">
                        <div class="push-content__text-container text-container text-container_android">
                            <div class="text-container__wrapper">
                                <div class="text-container__title text-container__title_android">
                                    {{ 'message_wizard_edit_step.sms_preview_sender_name'| translate }}
                                </div>
                                <div [enWrapLink]="preview || 'message_wizard_edit_step.settings_push_message_text'| translate"
                                     styleClass="sms-link"
                                     class="text-container__text en-text-medium">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="iframe__detail">
                <div class="detail__header detail-header">
                    <button type="button"
                            enButton
                            class="detail-header__button en-button-lowercase h32 en-mr-2"
                            icon="en en-arrow-left"
                            (click)="toogleDetail()"></button>
                    <div class="detail-header__name">
                        {{ 'message_wizard_edit_step.sms_preview_sender_name' | translate }}
                    </div>
                    <div style="flex: 1"></div>
                    <div class="detail-header__menu"><span class="en en-ellipsis-v"></span></div>
                </div>

                <tui-scrollbar>
                    <div class="detail__content">
                        <div class="detail__time">{{ 'message_wizard_edit_step.sms_preview_time' | translate }}</div>
                        <en-bubble-message [message]="preview"></en-bubble-message>
                    </div>
                </tui-scrollbar>
            </div>
        </div>
    </div>
</div>
