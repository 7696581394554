import { dia, util } from '@clientio/rappid';

export const NavigatorElementView = dia.ElementView.extend({
	body: null,

	markup: [
		{
			tagName: 'rect',
			selector: 'body',
			attributes: {
				fill: '#1EBD53'
			}
		}
	],

	initFlag: ['RENDER', 'UPDATE', 'TRANSFORM'],

	presentationAttributes: {
		size: ['UPDATE'],
		position: ['TRANSFORM'],
		angle: ['TRANSFORM']
	},

	confirmUpdate(flags: number) {
		if (this.hasFlag(flags, 'RENDER')) {
			this.render();
		}
		if (this.hasFlag(flags, 'UPDATE')) {
			this.update();
		}
		if (this.hasFlag(flags, 'TRANSFORM')) {
			this.updateTransformation();
		}
	},

	render() {
		const {
			fragment,
			selectors: { body }
		} = util.parseDOMJSON(this.markup);
		this.body = body;
		this.el.appendChild(fragment);
	},

	update() {
		const { model, body } = this;
		const { width, height } = model.size();
		const fill = model.attr('headerRect/fill');
		body.setAttribute('width', width);
		body.setAttribute('height', height);
		body.setAttribute('fill', fill);
	}
});
