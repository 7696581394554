import { Provider } from '@angular/core';
import {
	paginatorPluginFactory,
	PAGINATOR_PLUGIN_TOKEN
} from '@enkod-core/tokens/paginator-plugin-token';
import {
	paginationDataFactory,
	PaginationService,
	PAGINATION_DATA
} from 'ui-lib';
import { SegmentsQuery, SegmentsService } from '../_state';

export const SEGMENT_PAGINATION_PROVIDERS: Provider[] = [
	PaginationService,
	{
		provide: PAGINATOR_PLUGIN_TOKEN,
		useFactory: paginatorPluginFactory,
		deps: [SegmentsQuery]
	},
	{
		provide: PAGINATION_DATA,
		useFactory: paginationDataFactory,
		deps: [PAGINATOR_PLUGIN_TOKEN, PaginationService, SegmentsService]
	}
];
