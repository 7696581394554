import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Message } from 'primeng/api';

import { SelectItemDesctiption } from '@enkod-core/interfaces';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { CANVA_MODE_TOKEN, CanvaModeType } from '@enSend/_shared/tokens';

import { DUPLICATE_HANDLING_OPTIONS } from './duplicate-handling-options';

@Component({
	selector: 'en-duplicate-handling',
	templateUrl: './duplicate-handling.component.html',
	styleUrls: ['./duplicate-handling.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: DUPLICATE_HANDLING_OPTIONS
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DuplicateHandlingComponent implements OnInit {
	@Input() control: UntypedFormControl;
	@Input() message: Message[];

	@Output() changeDuplicateHandling = new EventEmitter();

	constructor(
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: SelectItemDesctiption[],
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType
	) {}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	ngOnInit(): void {
		if (!this.isCreationMode) this.control.disable({ emitEvent: false });
	}
}
