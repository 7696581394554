import {
	Component,
	ChangeDetectionStrategy,
	Output,
	EventEmitter
} from '@angular/core';
import { PopupPreview } from '../../../misc/abstract-preview';

@Component({
	selector: 'en-fullscreen-preview[popup]',
	templateUrl: './fullscreen-preview.component.html',
	styleUrls: ['./fullscreen-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenPreviewComponent extends PopupPreview {
	@Output()
	readonly onExit = new EventEmitter();
}
