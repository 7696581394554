import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SymbolCounterComponent } from './symbol-counter.component';

@NgModule({
	declarations: [SymbolCounterComponent],
	imports: [CommonModule],
	exports: [SymbolCounterComponent]
})
export class EnSymbolCounterModule {}
