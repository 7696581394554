import { ChatbotShapesEnum } from './enum.shapes';

export enum ScenarioSubtype {
	MAILING_GROUP = 'mailingGroup',
	MESSAGE_START = 'messageStart',
	API_START = 'apiStart',
	EXTRA_FIELD_START = 'extraFieldStart',
	DATA_CHANGE_MAIN = 'dataChangeMain',
	SCHEDULE_START = 'scheduleStart',
	EVENT_START = 'customEventStart',
	PAUSE = 'pause',
	DISTRIBUTION = 'distribution',
	SEND_MESSAGE = 'sendMessage',
	FINISH = 'finish',
	API_QUERY = 'apiQuery'
}

export const shapeConfig = {
	scenario: {
		[ScenarioSubtype.MAILING_GROUP]: {
			text: 'scenario_rappid_config.mailing_group',
			iconLinkHref: 'assets/scenario/subscribed.svg#subscribed'
		},
		[ScenarioSubtype.MESSAGE_START]: {
			text: 'scenario_rappid_config.message',
			iconLinkHref: 'assets/scenario/start-message.svg#start-message'
		},
		[ScenarioSubtype.API_START]: {
			text: 'API',
			iconLinkHref: 'assets/scenario/api-start.svg#api'
		},
		[ScenarioSubtype.EXTRA_FIELD_START]: {
			text: 'scenario_rappid_config.data_change',
			iconLinkHref:
				'assets/scenario/data-change-start.svg#data-change-start'
		},
		[ScenarioSubtype.DATA_CHANGE_MAIN]: {
			text: 'scenario_rappid_config.data_change_main',
			iconLinkHref:
				'assets/scenario/data-change-main.svg#data-change-main'
		},
		[ScenarioSubtype.SCHEDULE_START]: {
			text: 'scenario_rappid_config.schedule_start',
			iconLinkHref: 'assets/scenario/schedule-start.svg#schedule-start'
		},
		[ScenarioSubtype.EVENT_START]: {
			text: 'scenario_rappid_config.event_start',
			iconLinkHref: 'assets/scenario/event-start.svg#event-start'
		},
		[ScenarioSubtype.PAUSE]: {
			text: 'scenario_rappid_config.pause',
			iconLinkHref: 'assets/scenario/pause.svg#pause'
		},
		[ScenarioSubtype.DISTRIBUTION]: {
			text: 'scenario_rappid_config.distribution',
			iconLinkHref: 'assets/scenario/distribution.svg#distribution'
		},
		[ScenarioSubtype.SEND_MESSAGE]: {
			text: 'scenario_rappid_config.send_message',
			iconLinkHref: 'assets/scenario/send-message.svg#send-message'
		},
		[ScenarioSubtype.API_QUERY]: {
			text: 'scenario_rappid_config.api_query',
			iconLinkHref: 'assets/scenario/api-query.svg#api-query'
		},
		[ScenarioSubtype.FINISH]: {
			text: 'scenario_rappid_config.finish',
			iconLinkHref: 'assets/scenario/finish.svg#finish'
		}
	},
	chatbot: {
		[ChatbotShapesEnum.START]: {
			text: 'chatbot_rappid_config.start',
			iconLinkHref: 'assets/scenario/subscribed.svg#subscribed'
		},
		[ChatbotShapesEnum.MESSAGE]: {
			text: 'chatbot_rappid_config.message',
			iconLinkHref: 'assets/scenario/start-message.svg#start-message'
		}
	}
};
