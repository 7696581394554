import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Inject,
	ChangeDetectorRef
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import { Router } from '@angular/router';

import { PaginatorPlugin } from '@datorama/akita';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { SelectItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PaginationService } from 'ui-lib';
import { CalendarService } from 'ui-lib/new-calendar/calendar.service';

import { SelectItemDesctiption } from '@enkod-core/interfaces';
import { DateAccountTimezoneService } from '@enkod-core/services';
import {
	PAGINATOR_PLUGIN_TOKEN,
	SELECT_OPTIONS_TOKEN
} from '@enkod-core/tokens';
import { markAllAsDirty } from '@enkod-core/utils';
import { SEGMENT_PAGINATION_PROVIDERS } from '@enKod/segments/segments-list/pagination-provider';
import {
	regularDayValidator,
	regularEveryValidator,
	regularEveryWeekValidator,
	regularFilterValidator,
	regularMonthValidator,
	regularStartTimeValidator,
	regularWeekdaysValidator
} from '@enSend/message/message-wizard/message-wizard-common/services/message-wizard-validators';
import { MonthService } from '@enSend/_shared/components/month/month.service';

import { CanvaModeType, CANVA_MODE_TOKEN } from '@enSend/_shared/tokens';
import { Segment, SegmentsQuery } from '@state-enKod/segments';

import { TranslateService } from '@ngx-translate/core';
import { SelectedSegment } from '../api-start/api-start.component';
import { InspectorItemContext } from '../../inspector-item-plugin';
import { DUPLICATE_HANDLING_OPTIONS } from '../mailing-group/constants';
import { segmentValidator } from './segment-control-validator';
import { AbstractInspectorStart } from '../../abstract/abstract-inspector-start';

interface IRegularControls {
	weekdays: UntypedFormControl;
	month: UntypedFormControl;
	filter: UntypedFormControl;
	everyWeek: UntypedFormControl;
	day: UntypedFormControl;
}
type RegularTypes = 'weekdays' | 'month' | 'filter' | 'everyWeek' | 'day';

@UntilDestroy()
@Component({
	selector: 'en-schedule-start',
	templateUrl: './schedule-start.component.html',
	styleUrls: ['./schedule-start.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: DUPLICATE_HANDLING_OPTIONS
		},
		SEGMENT_PAGINATION_PROVIDERS,
		CalendarService,
		MonthService
	]
})
export class ScheduleStartComponent
	extends AbstractInspectorStart
	implements OnInit
{
	form: UntypedFormGroup = this.initRegular();

	selectedSegment$ = new BehaviorSubject<SelectedSegment[]>([]);

	resetValue$ = new Subject();

	visibleDialogMessage: boolean;

	startTimeControl = this.fb.control('');

	regularOptions: SelectItem[] = [
		{
			label: 'scenario_block_schedule_start.regular_type_halfhour',
			value: 'halfhour'
		},
		{
			label: 'scenario_block_schedule_start.regular_type_hour',
			value: 'hour'
		},
		{
			label: 'scenario_block_schedule_start.regular_type_day',
			value: 'day'
		},
		{
			label: 'scenario_block_schedule_start.regular_type_week',
			value: 'week'
		},
		{
			label: 'scenario_block_schedule_start.regular_type_month',
			value: 'month'
		}
	];

	blockParamLabel: any = {
		halfhour: 'scenario_block_schedule_start.regular_type_halfhour',
		hour: 'scenario_block_schedule_start.regular_type_hour',
		day: 'scenario_block_schedule_start.regular_type_day',
		week: 'scenario_block_schedule_start.regular_type_week',
		month: 'scenario_block_schedule_start.regular_type_month'
	};

	startLabel = 'message_wizard.sendlate_calendar_label';

	constructor(
		public fb: UntypedFormBuilder,
		public translate: TranslateService,
		@Inject(CANVA_MODE_TOKEN) private canvaMode: CanvaModeType,
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context$: Observable<InspectorItemContext>,
		private segmentsQuery: SegmentsQuery,
		public paginationService: PaginationService,
		@Inject(PAGINATOR_PLUGIN_TOKEN)
		public readonly paginatorRef: PaginatorPlugin<Segment>,
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: SelectItemDesctiption[],
		private monthService: MonthService,
		private cd: ChangeDetectorRef,
		private timezoneService: DateAccountTimezoneService,
		private router: Router
	) {
		super(fb, translate);
		this.monthService.paramsControl = this.paramsGroup;
	}

	get action() {
		const noQuery = this.router.url.split('?')[0];
		return noQuery.split('/')[3];
	}

	get isCreationMode() {
		return this.canvaMode === 'create';
	}

	get disableWeekdays() {
		return this.canvaMode === 'create' ? null : true;
	}

	get startTime(): UntypedFormControl {
		return this.form.get('startTime') as UntypedFormControl;
	}

	get everyControl(): UntypedFormControl {
		return this.form.get('every') as UntypedFormControl;
	}

	get paramsGroup(): UntypedFormGroup {
		return this.form.get('params') as UntypedFormGroup;
	}

	get allParamsControls(): IRegularControls {
		return {
			weekdays: this.paramsGroup?.get('weekdays') as UntypedFormControl,
			month: this.paramsGroup?.get('month') as UntypedFormControl,
			filter: this.paramsGroup?.get('filter') as UntypedFormControl,
			everyWeek: this.paramsGroup?.get('everyWeek') as UntypedFormControl,
			day: this.paramsGroup?.get('day') as UntypedFormControl
		};
	}

	get weekdaysControl(): UntypedFormControl {
		return this.paramsGroup?.get('weekdays') as UntypedFormControl;
	}

	get segmentControl(): UntypedFormControl {
		return this.form.get('segment') as UntypedFormControl;
	}

	ngOnInit() {
		this.context$
			.pipe(
				untilDestroyed(this),
				// eslint-disable-next-line no-undef
				tap(context => {
					if (
						!this.cell ||
						this.cell.get('subType') === context.cell.get('subType')
					) {
						this.cell = context.cell;
						const options = this.cell.get('options');
						this.patchOptions(options);
						this.changeTime();
						this.initDuplicateHandling();

						const enableMarkAsDirty =
							('isValid' in options && !options.isValid) ||
							this.cell.attributes.invalid;
						if (enableMarkAsDirty) {
							markAllAsDirty(this.form);
						}
					}
				})
			)
			.subscribe();
		this.initListeners();
		this.segmentsWithoutSiteBehavior();
		if (!this.isCreationMode) this.form.disable({ emitEvent: false });
	}

	private initListeners() {
		this.initMainFormListener();
		this.initEveryControlListener();
	}

	private initMainFormListener() {
		this.form.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(resp => {
					const formClone = JSON.parse(JSON.stringify(resp));
					delete formClone.segment;
					const options = this.cell.get('options');
					this.changeCellProp('options', {
						...options,
						regularity: formClone,
						duplicateHandling: this.duplicateHandling.value,
						isValid: this.form.valid
					});
				})
			)
			.subscribe();
	}

	private initEveryControlListener() {
		this.everyControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(resp => {
					const options = this.cell.get('options');
					this.pickRegularControls(resp);
					if (resp === 'month')
						this.monthService.pickFilterControl(
							this.monthService.filterControl.value
						);
					if (resp === 'week')
						this.monthService.weekdaysControl?.enable();
					this.changeCellProp('options', {
						...options,
						scheduleStart: {
							params: resp
								? [
										{
											label: this.blockParamLabel[resp]
										}
								  ]
								: []
						},
						regularity: this.form.value
					});
				})
			)
			.subscribe();
	}

	private patchOptions(options: any) {
		this.form.reset(
			{
				every: '',
				startTime: '',
				segment: [],
				params: {
					weekdays: [],
					month: [],
					filter: '',
					everyWeek: [],
					day: []
				}
			},
			{ emitEvent: false }
		);
		this.selectedSegment$.next([]);
		this.cd.detectChanges();

		this.startTimeControl.patchValue(options.regularity?.startTime || '');

		if (options.segment) {
			this.selectedSegment$.next(options.segment);
		}

		if (!options.regularity) {
			this.form = this.initRegular();
			this.initListeners();
			this.pickRegularControls('');
			this.monthService.paramsControl = this.paramsGroup;
		}

		if (options.regularity) {
			this.form.patchValue({
				...options.regularity,
				segment: options.segment || []
			});
			this.pickRegularControls(options.regularity.every);
			if (options.regularity.every === 'month') {
				this.monthService.paramsControl = this.paramsGroup;
				this.monthService.pickFilterControl(
					this.monthService.filterControl.value
				);
			}
			return;
		}

		this.monthService.pickFilterControl(
			this.monthService.filterControl.value
		);
	}

	private initRegular() {
		return this.fb.group({
			every: ['', regularEveryValidator],
			startTime: [0, [regularStartTimeValidator]],
			params: this.initRegularParams(),
			segment: [[], segmentValidator]
		});
	}

	private initRegularParams() {
		return this.fb.group({
			weekdays: [{ value: [], disabled: true }, regularWeekdaysValidator],
			month: [{ value: [], disabled: true }, regularMonthValidator],
			filter: [{ value: '', disabled: true }, regularFilterValidator],
			everyWeek: [
				{ value: [], disabled: true },
				regularEveryWeekValidator
			],
			day: [{ value: [], disabled: true }, regularDayValidator]
		});
	}

	private segmentsWithoutSiteBehavior() {
		this.paginationService.setFilterValue({ type: 'enSend' });
	}

	public selectSegment(segment: Segment) {
		const selectedSegment = this.segmentsQuery.getEntity(
			segment.id
		) as Segment;
		const nextSegment: SelectedSegment = {
			id: selectedSegment?.id,
			label: selectedSegment.name
		};
		this.selectedSegment$.next([nextSegment]);
		this.segmentControl.patchValue([nextSegment]);
		const options = this.cell.get('options');
		this.changeCellProp('options', {
			...options,
			segment: this.selectedSegment$.getValue()
		});
		this.visibleDialogMessage = false;
	}

	public removeSegment() {
		this.selectedSegment$.next([]);
		this.segmentControl.patchValue([]);
		const options = this.cell.get('options');
		this.changeCellProp('options', {
			...options,
			segment: []
		});
	}

	private pickRegularControls(value: string) {
		switch (value) {
			case 'week':
				this.enableWeek();
				break;

			case 'month':
				this.enableMonth();
				break;

			default:
				this.disableParamsGroup();
				break;
		}
	}

	private enableWeek(): void {
		this.enableControls(['weekdays']);
		this.disableMonthControls();
	}

	private enableMonth(): void {
		this.enableMonthControls();
	}

	private enableMonthControls(): void {
		this.enableControls(['month', 'filter', 'everyWeek', 'day']);
	}

	private disableMonthControls(): void {
		this.disableControls(['month', 'filter', 'everyWeek', 'day']);
	}

	private disableParamsGroup(): void {
		Object.keys(this.paramsGroup.controls).forEach(
			(controlName: string) => {
				this.paramsGroup?.controls[controlName]?.disable();
			}
		);
	}

	private enableControls(controls: RegularTypes[]) {
		controls.forEach(controlName => {
			this.allParamsControls[controlName].enable();
		});
	}

	private disableControls(controls: RegularTypes[]) {
		controls.forEach(controlName => {
			this.allParamsControls[controlName].disable();
		});
	}

	private changeTime() {
		this.startTimeControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					const UTCTime = this.timezoneService.toUTCTime(value);
					this.startTime.patchValue(UTCTime);
				})
			)
			.subscribe();
	}

	onHideDialog() {
		this.visibleDialogMessage = false;
		this.resetValue$.next();
	}
}
