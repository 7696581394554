<div class="description">
    <span class="en-text-large">
        {{'scenario_block_api.description_link_start' | translate}}
        <a class="en-message__link"
           target="_blank"
           href="https://openapi.enkod.io/#tag/Scenario/paths/~1event~1start~1%7BscenarioId%7D~1%7BblockId%7D~1/post">
            {{'scenario_block_api.description_link_end' | translate}}</a>.
    </span>
    <span class="en-text-large">
        {{'scenario_block_api.description_text' | translate}}
    </span>
</div>

<ng-container *tuiLet="generatedUrl$ | async as url">
    <div class="choose-btn"
         *ngIf="!url.length">
        <ng-container *ngIf="isCreationMode">
            <button (click)="generateUrl()"
                    enButton
                    [label]="'scenario_block_api.generate_url' | translate"
                    class="en-button-secondary h36 url-button"></button>
        </ng-container>
    </div>
    <ng-container *ngFor="let info of url">
        <span class="en-text-medium option-header">URL</span>
        <div class="selected-url">
            <input disabled
                   enInput
                   [placeholder]="info.url">
            <button enButton
                    icon="en en-copy"
                    class="en-button-lowercase h32 icon-color-secondary"
                    [cdkCopyToClipboard]="info.url"
                    iconColor="gray-button-icon"></button>
        </div>
    </ng-container>
</ng-container>


<ng-container *tuiLet="selectedSegment$ | async as selectedSegment">
    <span class="en-text-medium option-header">
        {{'scenario_block_api.segment_title' | translate}}
    </span>
    <div class="choose-btn"
         *ngIf="!selectedSegment.length">
        <ng-container *ngIf="isCreationMode">
            <button (click)="visibleDialogMessage = true"
                    enButton
                    [label]="'scenario_block_api.choose_segment' | translate"
                    class="en-button-secondary h36"></button>
        </ng-container>
        <ng-container *ngIf="!isCreationMode">
            <div class="not-chosen-field">
                <span>{{'common.not_chosen' | translate}}</span>
            </div>
        </ng-container>
    </div>
    <ng-container *ngFor="let info of selectedSegment">
        <en-chosen-item-info [id]="info.id"
                             [content]="info.label"
                             (remove)="removeSegment()"
                             [isCreationMode]="isCreationMode"></en-chosen-item-info>
    </ng-container>
</ng-container>


<div class="duplicate-handling">
    <span class="option-header en-text-medium">
        {{'scenario_block_api.duplicate_processing' | translate}}
    </span>
    <en-dropdown [options]="selectOptions"
                 styleClass="h36"
                 class="duplicate-handling__dropdown"
                 [disabled]="!isCreationMode"
                 [formControl]="duplicateHandling"
                 (onChange)="changeDuplicateHandling($event.value)">
        <ng-template let-selected
                     pTemplate="selectedItem">
            <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
        </ng-template>
        <ng-template let-type
                     pTemplate="item">
            <div class="duplicate-handling__drop">
                <span class="drop-label">{{type.label | translate}}</span>
                <div class="drop-description">{{type.description | translate}}</div>
            </div>
        </ng-template>
    </en-dropdown>
</div>

<en-messages [visible]="message.length"
             class="en-message"
             [(value)]="message"></en-messages>

<en-dialog [header]="'scenario_block_api.select_segments' | translate"
           [resizable]="false"
           [modal]="true"
           styleClass="p-dialog--lg"
           appendTo="body"
           [(visible)]="visibleDialogMessage"
           (onHide)="onDialogHide()">
    <tui-scrollbar class="scrollbar">
        <div class="segments-table-content">
            <en-segments-table [toolsPanel]="false"
                               [selection]="true"
                               [isEnSendSegments]="true"
                               [segmentsInNewWindow]="true"
                               (selected)="selectSegment($event)">
                <ng-template enTemplate="tools">
                    <section class="search">
                        <en-search [placeholder]="'segment_list.search_placeholder' | translate"
                                   styleClass="h32"
                                   (refresh)="paginatorRef.refreshCurrentPage()"
                                   (onSearch)="paginationService.setSearchValue($event)"
                                   [refresher]="false"
                                   [resetValue$]="resetValue$"></en-search>
                    </section>
                </ng-template>
            </en-segments-table>
        </div>
    </tui-scrollbar>  
</en-dialog>