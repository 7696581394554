import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-scroll-condition',
	templateUrl: './scroll-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollConditionComponent extends AbstractCondition {
	setValidation() {
		this.getSubscription('value')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (value < 1) this.setValue('value', 1);
				if (value > 100) this.setValue('value', 100);
			});
	}
}
