import { SelectItem } from 'primeng/api';
import { CountOptions } from '../models/types';

export const COUNT_OPTIONS: SelectItem<CountOptions>[] = [
	{
		label: 'segment_form.options_count_min',
		value: 'min'
	},
	{
		label: 'segment_form.options_count_equal',
		value: 'equal'
	},
	{
		label: 'segment_form.options_count_max',
		value: 'max'
	}
];
