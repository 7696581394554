import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'en-bubble-message',
	template: `
		<div
			[enWrapLink]="
				message || 'message_wizard_edit_step.settings_push_message_text'
					| translate
			"
			styleClass="sms-link"
			class="bubble"
		></div>
	`,
	styleUrls: ['./bubble-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbleMessageComponent {
	@Input() message: string;
}
