import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api/selectitem';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { MultiMenuItem } from 'ui-lib/dropdown-multilevel/dropdown-multilevel.model';
import { FieldConditionForm } from '@enKod/segments/segments-form.model';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import { Subject } from 'rxjs';
import { menuOptions } from './operator-menu-options';
import { UnitsDeclinationService } from '../../../common/services/units-declination.service';

@UntilDestroy()
@Component({
	selector: 'en-field-date-condition',
	templateUrl: './field-date-condition.component.html',
	styleUrls: [
		'../../../abstract-condition.component.scss',
		'./field-date-condition.component.scss'
	],
	providers: [UnitsDeclinationService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldDateConditionComponent implements OnInit {
	private _dateType: 'date' | 'dateTime';
	markValueAsInvalid = false;
	markRangeAsInvalid = false;

	reset$ = new Subject();

	isFirstLoad = true;

	@Input() form: FormGroup<FieldConditionForm>;
	@Input() showHide = false;

	@Input()
	set dateType(name: 'date' | 'dateTime') {
		this._dateType = name;
		this.init();
	}

	constructor(
		private declinationService: UnitsDeclinationService,
		private validationService: SegmentsValidateService,
		private cd: ChangeDetectorRef
	) {}

	get valueControl(): FormControl<string | number | null> {
		return this.form.controls.value as FormControl<string | number | null>;
	}

	get startControl(): FormControl<number | null> {
		return this.form.controls.start as FormControl<number | null>;
	}

	get endControl(): FormControl<number | null> {
		return this.form.controls.end as FormControl<number | null>;
	}

	get unitsControl(): FormControl<string> {
		return this.form.controls.units as FormControl<string>;
	}

	get dateType(): 'date' | 'dateTime' {
		return this._dateType;
	}

	get operatorValue() {
		return this.form.get('operator')?.value;
	}

	get showRangeCalendar(): boolean {
		return this.operatorValue === 'between';
	}

	get showSingleCalendar(): boolean {
		return (
			this.operatorValue === 'after' ||
			this.operatorValue === 'before' ||
			this.operatorValue === 'equal' ||
			this.operatorValue === 'not_equal'
		);
	}

	get showUnitSelector(): boolean {
		return (
			this.operatorValue === 'happened_less' ||
			this.operatorValue === 'happened_equal' ||
			this.operatorValue === 'happened_more' ||
			this.operatorValue === 'happen_less' ||
			this.operatorValue === 'happen_equal' ||
			this.operatorValue === 'happen_more'
		);
	}

	get isHappenedLessOrMore(): boolean {
		return (
			this.operatorValue === 'happened_less' ||
			this.operatorValue === 'happened_more'
		);
	}

	get isPastTimeOperator(): boolean {
		return (
			this.operatorValue === 'happened_less' ||
			this.operatorValue === 'happened_equal' ||
			this.operatorValue === 'happened_more'
		);
	}

	get hideRightBorder(): boolean {
		return (
			this.showUnitSelector ||
			this.showRangeCalendar ||
			this.showSingleCalendar
		);
	}

	get isInvalidValue(): boolean {
		return !this.valueControl.value || this.valueControl.value === 0;
	}

	get isInvalidRange(): boolean {
		return !this.startControl.value || !this.endControl.value;
	}

	calendarLocale = {};

	optionsMenu: MultiMenuItem[] = menuOptions;
	optionsUnits: SelectItem[] = [];

	dateRangeControl = new FormControl<{
		startDate: number;
		endDate: number;
	} | null>(null);

	dateContol = new FormControl(null);
	datesGroupBetween = new FormGroup({
		start: new FormControl(null),
		end: new FormControl(null)
	});

	minDate: Date | null;
	maxDate: Date | null;

	ngOnInit() {
		this.patchFormValue();
		this.subscribe();
	}

	init() {
		this.setCaseUnits();
	}

	patchFormValue() {
		if (this.startControl.value && this.endControl.value)
			this.dateRangeControl.patchValue({
				startDate: this.startControl.value,
				endDate: this.endControl.value
			});
		this.minDate =
			this.form.controls.start?.value !== null
				? new Date(this.form.controls.start.value * 1000)
				: null;

		this.maxDate =
			this.form.controls.end?.value !== null
				? new Date(this.form.controls.end.value * 1000)
				: null;
	}

	subscribe() {
		this.valueControl?.valueChanges
			.pipe(untilDestroyed(this), distinctUntilChanged())
			.subscribe(value => {
				if (value) this.markValueAsInvalid = false;
				this.setCaseUnits();
			});

		this.dateRangeControl.valueChanges
			.pipe(
				untilDestroyed(this),
				tap(value => {
					if (value) {
						this.form.get('start')?.patchValue(value.startDate);
						this.form.get('end')?.patchValue(value.endDate);

						this.markRangeAsInvalid = this.isInvalidRange;

						if (this.isInvalidRange) {
							this.form
								.get('start')
								?.setErrors({ invalidRange: true });
							this.form
								.get('end')
								?.setErrors({ invalidRange: true });
						}
					}
				})
			)
			.subscribe();

		this.validationService.checkedValidate
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.markValueAsInvalid =
					this.valueControl.enabled && !this.showHide
						? this.isInvalidValue
						: false;
				this.markRangeAsInvalid =
					this.startControl.enabled && !this.showHide
						? this.isInvalidRange
						: false;
				this.cd.markForCheck();
			});

		this.form
			.get('operator')
			?.valueChanges.pipe(
				untilDestroyed(this),
				tap(operator => {
					switch (true) {
						// Операторы с одиночным календарем
						case this.showSingleCalendar:
							this.valueControl.enable();
							this.startControl.disable();
							this.endControl.disable();
							this.unitsControl.disable();
							if (!this.isFirstLoad) {
								this.reset$.next();
								this.valueControl.patchValue(null);
							}
							break;
						// Операторы с календарем периода
						case operator === 'between':
							this.startControl.enable();
							this.endControl.enable();
							this.unitsControl.disable();
							this.valueControl.disable();
							break;
						// Операторы с units
						case this.showUnitSelector:
							this.valueControl.enable();
							this.unitsControl.enable();
							this.startControl.disable();
							this.endControl.disable();
							break;
						default:
							this.startControl.disable();
							this.endControl.disable();
							this.unitsControl.disable();
							this.valueControl.disable();
							break;
					}
				})
			)
			.subscribe(() => {
				if (
					this.showUnitSelector &&
					this.isFirstLoad &&
					this.form.controls.extraFieldType?.value === 'date' &&
					this.unitsControl.value === 'hour'
				) {
					this.unitsControl.patchValue('day');
					this.valueControl.patchValue(1);
				}

				if (this.showUnitSelector && !this.isFirstLoad) {
					this.form.controls.extraFieldType?.value === 'date'
						? this.unitsControl.patchValue('day')
						: this.unitsControl.patchValue('hour');
					this.valueControl.patchValue(1);
				}

				this.setCaseUnits();
				this.isFirstLoad = false;
			});
	}

	// склонение units
	setCaseUnits() {
		if (this.dateType === 'date') {
			this.optionsUnits = this.declinationService.getCaseUnits(
				this.valueControl,
				this.isHappenedLessOrMore,
				false,
				this.showUnitSelector
			);
		} else {
			this.optionsUnits = this.declinationService.getCaseUnits(
				this.valueControl,
				this.isHappenedLessOrMore
			);
		}
	}
}
