import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
	EnButtonModule,
	EnDialogModule,
	EnDropdownModule,
	EnMessagesModule,
	SearchModule
} from 'ui-lib';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SharedModule } from '@shared';
import { TuiScrollbarModule } from '@enkod-core/components';
import { CopyMessageDialogModule } from '@enSend/message/message-wizard/message-wizard-common/step-content/2-step-editor/email-editor/copy-message-dialog/copy-message-dialog.module';
import { MessageTableModule } from '@enSend/_shared/components';
import { TabViewModule } from 'primeng/tabview';
import { TranslateModule } from '@ngx-translate/core';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { MessageStartService } from './message-start.service';
import { MessageStartComponent } from './message-start.component';
import { ChosenItemInfoModule } from '../../components/chosen-item-info/chosen-item-info.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnDropdownModule,
		EnMessagesModule,
		CopyMessageDialogModule,
		EnDialogModule,
		EnButtonModule,
		TuiLetModule,
		SearchModule,
		ChosenItemInfoModule,
		MessageTableModule,
		TabViewModule,
		TranslateModule,
		TuiScrollbarModule
	],
	declarations: [MessageStartComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: MessageStartService,
			multi: true
		}
	]
})
export class MessageStartModule {}
