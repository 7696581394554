import {
	Component,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	OnInit,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';

import { UntilDestroy } from '@ngneat/until-destroy';
import { tuiPure } from '@taiga-ui/cdk';

import { findPosition, IframeTemplate } from '../../../misc';
import { PopupPreview } from '../../../misc/abstract-preview';

@UntilDestroy()
@Component({
	selector: 'en-desktop-preview[popup]',
	templateUrl: './desktop-preview.component.html',
	styleUrls: ['./desktop-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopPreviewComponent
	extends PopupPreview
	implements OnInit, OnDestroy
{
	desktopWidth = 1280;
	desktopHeight = 854;
	previewHeightOffset = 36;
	editorStyle = {
		height: 0,
		width: 0,
		scale: 0,
		transform: '',
		previewHeight: 0
	};

	containerObserver = new ResizeObserver(() => {
		this.onResize();
		this.cd.detectChanges();
	});

	createPopup: boolean = true;

	@ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLDivElement>;
	@ViewChild('preview', { static: true }) preview: ElementRef<HTMLDivElement>;
	@ViewChild('image', { static: true }) image: ElementRef<HTMLImageElement>;

	onResize() {
		if (this.preview) {
			const maxHeight = this.dynamic
				? this.wrapper.nativeElement.offsetHeight
				: this.desktopHeight;
			const newWidth = this.preview.nativeElement.offsetWidth;

			const imageHeight = this.image.nativeElement.offsetHeight;

			this.editorStyle.scale = +(
				newWidth / this.editorStyle.width
			).toFixed(3);
			this.editorStyle.transform = `scale(${this.editorStyle.scale})`;

			const previewHeight =
				imageHeight * this.editorStyle.scale + this.previewHeightOffset;

			imageHeight
				? (this.editorStyle.previewHeight = Math.min(
						previewHeight,
						maxHeight
				  ))
				: (this.editorStyle.previewHeight =
						this.desktopHeight * this.editorStyle.scale +
						this.previewHeightOffset);
		}
	}

	constructor(private cd: ChangeDetectorRef, private router: Router) {
		super();
	}

	get difference() {
		const previewHeight =
			this.image.nativeElement.offsetHeight * this.editorStyle.scale +
			this.previewHeightOffset;
		const maxHeight = this.wrapper.nativeElement.offsetHeight;
		return (previewHeight - maxHeight) * (2 - this.editorStyle.scale);
	}

	get marginTopValue() {
		if (this.difference < 0) return '';

		const position = this.getPosition(this.template);

		switch (this.difference > 0) {
			case position.includes('top'):
				return '';
			case position.includes('bottom'):
				return `${-this.difference / 16}rem`;
			default:
				return `${-this.difference / 32}rem`;
		}
	}

	ngOnInit(): void {
		this.containerObserver.observe(this.wrapper.nativeElement);

		this.createPopup = !this.router.url.includes('popups/detail');

		this.editorStyle.width = this.desktopWidth;
		this.editorStyle.height = this.desktopHeight;
		this.onResize();

		this.image.nativeElement.onload = () => {
			this.onResize();
			this.cd.markForCheck();
		};
	}

	@tuiPure
	getPosition(template: IframeTemplate): string {
		return findPosition(template);
	}

	ngOnDestroy(): void {
		this.containerObserver.unobserve(this.wrapper.nativeElement);
	}
}
