import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

@Component({
	selector: 'en-selected-message-item',
	templateUrl: './selected-message-item.component.html',
	styleUrls: ['./selected-message-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedMessageItemComponent {
	@Input() id = '';
	@Input() name = '';
	@Input() isCreationMode = true;
	@Output() remove = new EventEmitter();
}
