<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsCategoryData"
                 formControlName="type"
                 (onChange)="onChangeType($event)">
    </en-dropdown>

    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsOperatorRfmEquality"
                 [formControl]="form.get('equality')">
    </en-dropdown>

    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="selectItems"
                 [placeholder]="'segment_common.select_placeholder' | translate"
                 [formControl]="form.get('value')">
    </en-dropdown>

    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)">
        </en-condition-tools>
    </div>
</form>