import { StatusType } from '@enSend/message/_states/_state-message';
import { SelectItem } from 'primeng/api';

export const BLOCK_STATUS_OPTIONS: SelectItem<StatusType>[] = [
	{
		label: 'message_list.status_active',
		value: 'active'
	},
	{
		label: 'message_list.status_inactive',
		value: 'inactive'
	}
];
