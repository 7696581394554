import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InspectorService {
	private toggleInspector$ = new BehaviorSubject<boolean>(false);
	viewInspector = true;

	get toggleInspectorChanges() {
		return this.toggleInspector$.asObservable();
	}

	toggleViewInspector(value: boolean) {
		this.viewInspector = value;
	}

	toggleInspector(value: boolean) {
		if (this.viewInspector) {
			this.toggleInspector$.next(value);
		}
	}
}
