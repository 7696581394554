import { Injectable } from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	UntypedFormArray,
	Validators
} from '@angular/forms';

import { MessageModel } from '../models';
import {
	editorMailFromEmailValidator,
	editorMailFromNameValidator,
	editorMessageSubjectValidator,
	editorMobPushButtonLabelValidator,
	editorPushClickActionValidator,
	editorPushIconValidator,
	editorPushTimeToLiveValidator,
	validationSmsLength
} from './message-wizard-validators';

import {
	DEFAULT_HTML,
	DEFAULT_STRIPO_HTML,
	STRIPO_CSS
} from '../step-content/2-step-editor/email-editor/constants';
import { WizardOTD } from '../wizard-otd';

@Injectable()
export class StepEditorService {
	constructor(private fb: UntypedFormBuilder) {}

	private form: UntypedFormGroup;

	get stepEditor(): UntypedFormGroup {
		return this.form as UntypedFormGroup;
	}

	get mails(): UntypedFormArray {
		return this.stepEditor?.get('mails') as UntypedFormArray;
	}

	get pushes(): UntypedFormArray {
		return this.stepEditor.get('pushes') as UntypedFormArray;
	}

	init() {
		this.form = this.initEditorForm();
	}

	initEditorForm(): UntypedFormGroup {
		return this.fb.group({
			tags: this.fb.control([])
		});
	}

	initMessage(message: Partial<MessageModel>) {
		const { type } = message;

		switch (type) {
			case 'mail':
				this.form.setControl('mails', this.fb.array([this.initMail()]));
				break;

			case 'push':
				this.form.setControl(
					'pushes',
					this.fb.array([this.initPush()])
				);
				break;

			case 'mobPush':
				this.form.setControl(
					'mobPushes',
					this.fb.array([this.initMobPush(message)])
				);
				break;

			case 'sms':
				this.form.setControl('sms', this.fb.array([this.initSms()]));
				break;

			case 'whatsapp':
				this.form.setControl('whatsapp', this.initWhatsapp());
				break;
			default:
				break;
		}
	}

	initMail() {
		return this.fb.group({
			id: 0,
			messageId: 0,
			status: '',

			sendSettings: this.fb.group({
				fromEmail: ['', editorMailFromEmailValidator],
				fromName: ['', editorMailFromNameValidator],
				subject: ['', editorMessageSubjectValidator],
				preheader: [''],
				withAttachments: false,
				replyToName: [{ value: '', disabled: true }],
				replyToEmail: [
					{ value: '', disabled: true },
					{ validators: editorMailFromEmailValidator }
				]
			}),
			showReplyTo: false,

			stripoHtml: DEFAULT_STRIPO_HTML,
			stripoCss: STRIPO_CSS,
			plainText: '',
			html: DEFAULT_HTML,
			amp: '',

			trackClicks: true,

			chamaileonLetter: this.fb.group({
				jsonBody: [null],
				htmlBlocks: [null],
				images: [null]
			}),

			editorType: this.fb.control(''),

			urlParams: this.fb.control({}),
			utm: this.fb.control({}),
			attachments: this.fb.control([]),
			fileAttachments: false
		});
	}

	private initPush() {
		return this.fb.group({
			id: 0,
			messageId: 0,

			subject: ['', editorMessageSubjectValidator],
			body: '',
			icon: ['', editorPushIconValidator],
			clickAction: ['', editorPushClickActionValidator],
			timeToLive: [259200, editorPushTimeToLiveValidator],

			urlParams: this.fb.control({}),
			utm: this.fb.control({})
		});
	}

	private initMobPush(message: Partial<MessageModel>) {
		const buttons = this.fb.array([]);

		if (message?.mobPushes && message.mobPushes[0]?.buttons) {
			const buttonsData = message.mobPushes[0]?.buttons;
			const addButtonControl = () =>
				this.fb.group({
					label: [
						'',
						{ validators: editorMobPushButtonLabelValidator }
					],
					linkRef: [{ link: '', action: 'url' }]
				});

			buttonsData.forEach(() => {
				const buttonsControl = addButtonControl();
				buttons.push(buttonsControl);
			});
		}

		return this.fb.group({
			id: 0,
			messageId: 0,

			subject: ['', editorMessageSubjectValidator],
			body: '',
			linkRef: [{ link: '', action: 'url' }],

			smallImage: [''],
			image: [''],
			appName: [''],

			buttons,

			urlParams: this.fb.control({}),
			utm: this.fb.control({})
		});
	}

	private initSms(): UntypedFormGroup {
		return this.fb.group({
			id: 0,
			messageId: 0,
			body: ['', [Validators.required, validationSmsLength]],
			toShortLink: false,
			urlParams: this.fb.control({}),
			utm: this.fb.control({})
		});
	}

	private initWhatsapp(): UntypedFormGroup {
		return this.fb.group({
			id: 0,
			text: ['', Validators.required],
			footer: '',
			type: 'text',
			title: this.fb.group({
				format: '',
				text: ''
			}),

			titleType: 'none',
			buttons: this.fb.array([]),
			whatsappTemplateId: [null, Validators.required],
			whatsappTemplateName: '',
			name: ['', Validators.required],
			whatsappParams: this.fb.group({
				body_text: this.fb.array([]),
				footer_text: this.fb.array([]),
				header_text: this.fb.array([]),
				url_button_params: this.fb.array([]),
				header_handle: this.fb.array([])
			})
		});
	}

	patchMailSplit(message: WizardOTD): void {
		const mailsFormArray = <UntypedFormArray>this.mails;

		/** Чищу, так как патч оставляет один объект в formArray */
		mailsFormArray.clear();

		message.step_2.mails?.forEach(mail => {
			const mailForm = this.initMail();
			mailForm.patchValue(mail, { emitEvent: false });
			mailsFormArray.push(mailForm);
		});
	}
}
