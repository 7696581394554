import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AbstractCondition } from '../../abstract-condition.component';

@Component({
	selector: 'en-device-condition',
	templateUrl: './device-condition.component.html',
	styleUrls: ['../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConditionComponent extends AbstractCondition {
	readonly optionsDevice: SelectItem[] = [
		{
			label: 'segment_form.cond_device_desktop',
			value: 'desktop'
		},
		{
			label: 'segment_form.cond_device_mobile',
			value: 'mobile'
		}
	];
}
