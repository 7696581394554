/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { ScenarioShapesEnum } from '../../../../config/enum.shapes';
import {
	severalPortsBlock,
	severalPortsBlockView
} from './several-ports.shape';

export const distributionBlock = severalPortsBlock.define(
	ScenarioShapesEnum.DISTRIBUTION_BLOCK,
	{
		ports: {
			groups: {
				out: {
					attrs: {
						portBody: {
							refY: '110%'
						}
					}
				}
			}
		}
	},
	{
		initialize(...args: any) {
			const options = this.get('options');
			const subType = this.get('subType');

			const unsuitableID =
				options[subType].unsuitable || this.generatePortId();
			const unsuitablePath = ['options', 'distribution', 'unsuitable'];

			this.prop(unsuitablePath, unsuitableID);

			this.prop(['ports', 'items'], this.getPorts());

			severalPortsBlock.prototype.initialize.apply(this, args);
		},

		onChangeOptions(args: any) {
			const options = this.get('options');
			const subType = this.get('subType');
			const { params } = options[subType];

			const unsuitableID =
				options[subType].unsuitable || this.generatePortId();

			this.attributes.optionHeight =
				options[subType].distributionType === 'scenarioFields'
					? 42
					: 28;

			if (params.length && !this.getPort(unsuitableID)) {
				this.addPort({
					group: 'out',
					id: unsuitableID
				});
			}

			if (
				!params.length ||
				options[subType].distributionType === 'equally' ||
				options[subType].distributionType === 'proportionally'
			) {
				this.removePort(unsuitableID);
			}

			if (this.getPort(unsuitableID)) {
				this.updateUnsuitablePort();
			}

			severalPortsBlock.prototype.onChangeOptions.call(this, {
				...args
			});
		},

		updateUnsuitablePort() {
			const options = this.get('options');
			const subType = this.get('subType');
			const { params } = options[subType];
			const optionHeight = this.get('optionHeight');
			const padding = this.get('padding');
			let offsetY = 62;

			const unsuitablePortID = options[subType].unsuitable;

			params.forEach(() => {
				offsetY += optionHeight + padding;
			});

			this.portProp(unsuitablePortID, 'args/y', offsetY);
		}
	}
);

export const distributionBlockView: dia.ElementView = Object.assign(
	severalPortsBlockView
);
