import { SelectItem } from 'primeng/api';

export const OPERATOR_STRING_OPTIONS: SelectItem[] = [
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_form.options_operator_contain',
		value: 'contain'
	},
	{
		label: 'segment_options.not_contain_options',
		value: 'not_contain'
	}
];

export const OPERATOR_NUMBER_OPTIONS: SelectItem[] = [
	{
		label: 'segment_form.options_operator_equal',
		value: 'equal'
	},
	{
		label: 'segment_options.not_equal_options',
		value: 'not_equal'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	},
	{
		label: 'segment_form.options_operator_less',
		value: 'less'
	},
	{
		label: 'segment_form.options_operator_lessOrEqual',
		value: 'lessOrEqual'
	},
	{
		label: 'segment_form.options_operator_more',
		value: 'more'
	},
	{
		label: 'segment_form.options_operator_moreOrEqual',
		value: 'moreOrEqual'
	}
];

export const OPERATOR_BOOLEAN_OPTIONS: SelectItem[] = [
	{
		label: 'segment_form.options_field_boolean_true',
		value: 'true'
	},
	{
		label: 'segment_form.options_field_boolean_false',
		value: 'false'
	},
	{
		label: 'segment_form.options_operator_empty',
		value: 'empty'
	},
	{
		label: 'segment_options.not_empty_options',
		value: 'not_empty'
	}
];
