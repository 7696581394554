<form *ngIf="formParams.controls.length"
      [formGroup]="form">
    <div class="condition"
         formArrayName="params">
        <ng-container *ngFor="let param of formParams.controls; index as i">
            <div class="inputgroup-wrapper"
                 [formGroupName]="i">
                <ng-container [ngSwitch]="param.value.type">
                    <ng-template [ngSwitchCase]="'date'">
                        <span class="text-overflow en-input-addon">@{{param.value.key}}</span>
                        <en-dropdown-calendar-new [showReset]="false"
                                                  [type]="'date'"
                                                  [size]="'s'"
                                                  formControlName="value"></en-dropdown-calendar-new>
                    </ng-template>
                    <ng-container *ngSwitchCase="'dateTime'">
                        <div class="en-input-addon">
                            <span class="text-overflow">@{{param.value.key}}</span>
                        </div>
                        <en-dropdown-calendar-new [showReset]="false"
                                                  [type]="'dateTime'"
                                                  [size]="'s'"
                                                  formControlName="value"></en-dropdown-calendar-new>
                    </ng-container>
                    <ng-template [ngSwitchCase]="'bool'">
                        <div class="en-input-addon">
                            <span class="text-overflow">@{{param.value.key}}</span>
                        </div>
                        <en-dropdown styleClass="en-dropdown-solid h32"
                                     [options]="booleanOptions"
                                     formControlName="value"></en-dropdown>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'text'">
                        <div class="en-input-addon h32">
                            <span class="text-overflow">@{{param.value.key}}</span>
                        </div>
                        <input class="param-input text-overflow"
                               enInput
                               enDynamicWidth="15rem"
                               formControlName="value">
                    </ng-template>
                    <ng-container *ngSwitchCase="'number'">
                        <div class="en-input-addon">
                            <span class="text-overflow">@{{param.value.key}}</span>
                        </div>
                        <input class="param-input text-overflow"
                               enInput
                               enDynamicWidth="15rem"
                               pKeyFilter="int"
                               formControlName="value">
                    </ng-container>
                    <ng-container *ngSwitchCase="'float'">
                        <div class="en-input-addon">
                            <span class="text-overflow">@{{param.value.key}}</span>
                        </div>
                        <input class="param-input text-overflow"
                               enInput
                               enDynamicWidth="15rem"
                               pKeyFilter="num"
                               formControlName="value">
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</form>