<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown styleClass="en-dropdown-solid"
                 [options]="optionsBehavior"
                 [formControl]="type"
                 (onChange)="onChangeType($event)"></en-dropdown>
    <div class="addition">
        <en-dropdown class="input__small"
                     styleClass="en-dropdown-solid"
                     [options]="optionsOperatorShort"
                     formControlName="operator"></en-dropdown>
        <input class="h32 text-overflow"
               type="number"
               min="1"
               max="100"
               step="1"
               enInput
               enDynamicWidth
               pKeyFilter="pint"
               showClear="true"
               formControlName="value" />
        <span class="center-span-text">{{ 'segment_common.count_description' | translate }}</span>
    </div>
    <div>
        <en-action-time [timeForm]="actionTime"
                        [optionsPeriod]="optionsOperatorPeriod"></en-action-time>
    </div>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"></en-condition-tools>
    </div>
</form>