import {
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Input,
	Inject,
	Optional
} from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { SUBJECT_TOKEN } from '@enkod-core/tokens/subject-token';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { AttachmentLoadingService } from '@enSend/message/message-wizard/kit/components/attachments-control/attachmentLoading.service';
import { StepSplitTestService } from '../../services';

@Component({
	selector: 'en-split-handler',
	templateUrl: './split-handler.component.html',
	styleUrls: ['./split-handler.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitHandlerComponent {
	private _selectedSplitIndex = 0;

	splitIndexChanges$ = this.splitService.splitIndexChanges;
	openMenu = false;

	@Input() changeMode = false;

	@Input() isDraft: boolean;

	@Input() loading: boolean;

	constructor(
		private splitService: StepSplitTestService,
		private confirmationService: ConfirmationService,
		private cd: ChangeDetectorRef,
		private traslateService: TranslateService,
		@Optional()
		@Inject(SUBJECT_TOKEN)
		public forceRerender: Subject<void> | null,
		private attachmentLoadingService: AttachmentLoadingService
	) {}

	get splits(): UntypedFormArray {
		return this.splitService.splits;
	}

	get splitLength(): number {
		return this.splitService.splitLength;
	}

	get versions(): ReadonlyArray<string> {
		return this.splitService.versions;
	}

	get selectedSplitIndex(): number {
		return this.splitService.splitIndex;
	}

	createSplit(): void {
		this.splitService.createSplit();
	}

	selectSplit(index: number): void {
		this.splitService.setSplitIndex(index);
		if (index !== this._selectedSplitIndex) {
			this.attachmentLoadingService.setIsVersionChanged(true);
			this._selectedSplitIndex = index;
		}
	}

	removeSplit(event: MouseEvent, index: number): void {
		event.stopPropagation();

		this.confirmationService.confirm({
			key: 'confirmSplitDelete',
			header: this.traslateService.instant(
				'split_test.confirm_delete_header'
			),
			message: this.traslateService.instant(
				'split_test.confirm_delete_message',
				{ version: this.versions[index] }
			),
			acceptLabel: this.traslateService.instant(
				'split_test.confirm_delete_accept'
			),
			rejectLabel: this.traslateService.instant(
				'split_test.confirm_delete_reject'
			),
			accept: () => {
				if (this.selectedSplitIndex !== 0)
					this.splitService.decreseSplitIndex();

				this.splitService.removeSplit(index);
				this.cd.markForCheck();
			}
		});
	}
}
