import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Button } from 'primeng/button';
import { Dialog } from 'primeng/dialog';
import { SendTestService } from '../send-test.service';
import { TestSnippetsFormService } from './test-snippets-form.service';

@UntilDestroy()
@Component({
	selector: 'en-test-snippets',
	templateUrl: './test-snippets.component.html',
	styleUrls: ['./test-snippets.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestSnippetsComponent implements OnInit {
	constructor(
		private testSnippetsForm: TestSnippetsFormService,
		private sendTestService: SendTestService
	) {}

	@Input() sendTestButton: Button;

	@Input() warnAlert: boolean;

	@Input() dialog: Dialog;

	@Output() showMails = new EventEmitter();

	get snippetForm(): UntypedFormGroup {
		return this.testSnippetsForm.snippetForm as UntypedFormGroup;
	}

	ngOnInit(): void {
		this.setupListeners();
	}

	private setupListeners() {
		this.sendTestButton.onClick.pipe(untilDestroyed(this)).subscribe(() => {
			if (this.sendTestService.minLength()) {
				this.showMails.emit();
			}
		});
	}
}
