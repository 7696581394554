import {
	AbstractControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn
} from '@angular/forms';

/** Регулярка емейла состоит из регулярок EMAIL_WITHOUT_MAIL_DOMAIN_REGEXP и DOMAIN_REGEXP,
 * если будешь вносить изменения в одну из частей, то и в емейловской в эту часть также нужно внести изменения, и наоборот,
 * чтобы везде емейловская и доменная валидация была одинаковая
 * ↓↓↓
 */
// полный емейл с частью до собаки и после
// если будешь менять EMAIL_REGEXP, то и в скрипте ее также нужно поменять
export const EMAIL_REGEXP =
	/^([a-zA-Z0-9_+=\\-][.]?)+@([\w\\-]+\.)+[\w\\-]{2,}$/;
// часть до собаки (без @mail.ru)
export const EMAIL_WITHOUT_MAIL_DOMAIN_REGEXP = /^([a-zA-Z0-9_+=\\-][.]?)+$/i;
// часть после собаки
export const DOMAIN_REGEXP = /^([\w\\-]+\.)+[\w\\-]{2,}$/;
/** ↑↑↑ */

// если будешь менять PHONE_REGEXP, то и в скрипте ее также нужно поменять
export const PHONE_REGEXP = /^\+?[1-9]\d{10,14}$/;
export const FULL_URL_REGEX =
	/^(?!.*\.$)(?!.*\.\.)(?!.*\/\/\/)(?!.*::)(http[s]?|ftp[s]?):+\/\/+[a-zA-Z0-9-]+\.+[a-zA-Z]+\S*$/i;
export const CYRILLIC_REGEXP = /[а-яА-ЯёЁ№]/gm;
export const UUID_REGEXP =
	/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const LINK_START_REGEXP = /<a\b[^>]*>/gi;
const LINK_END_REGEXP = /<\/a>/gi;

export function removeLink(text: string): string {
	const newText = text
		.replace(LINK_START_REGEXP, '')
		.replace(LINK_END_REGEXP, '');
	return newText;
}

export class CustomValidators {
	static required(control: AbstractControl) {
		if (
			!control.value ||
			(control.value.trim && control.value.trim() === '')
		) {
			return { required: true };
		}
		return null;
	}

	static email(control: AbstractControl) {
		if (!control || !control.value) return null;

		if (EMAIL_REGEXP.test(control.value)) {
			return null;
		}

		return {
			invalidEmail: true
		};
	}

	static emailWithoutMailDomain(control: AbstractControl) {
		if (!control || !control.value) return null;

		if (EMAIL_WITHOUT_MAIL_DOMAIN_REGEXP.test(control.value)) {
			return null;
		}

		return {
			invalidEmail: true
		};
	}

	static password(control: AbstractControl) {
		if (!control || !control.value) return null;

		const PASSWORD_REGEXP =
			/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W?)(?!.* ).{8,}$/;

		if (PASSWORD_REGEXP.test(control.value)) {
			return null;
		}

		return { invalidPassword: true };
	}

	static contact(control: AbstractControl) {
		if (!control || !control.value) return null;

		if (EMAIL_REGEXP.test(control.value)) {
			return null;
		}

		if (PHONE_REGEXP.test(control.value)) {
			return null;
		}

		return {
			invalidEmailOrPhone: true
		};
	}

	static domain(control: AbstractControl) {
		if (!control || !control.value) return null;

		if (DOMAIN_REGEXP.test(control.value)) {
			return null;
		}

		return {
			invalidDomain: true
		};
	}

	static domainName(control: AbstractControl) {
		if (!control || !control.value) return null;

		const DOMEN_REGEXP = /^[a-z0-9-]{2,}$/g;

		if (DOMEN_REGEXP.test(control.value)) {
			return null;
		}

		return {
			invalidDomain: true
		};
	}

	static phone(control: AbstractControl) {
		if (!control || !control.value) return null;

		if (PHONE_REGEXP.test(control.value)) {
			return null;
		}

		return {
			invalidPhone: true
		};
	}

	static url(control: AbstractControl) {
		if (!control || !control.value) return null;
		if (!control.value.match(FULL_URL_REGEX)) {
			return { invalidUrl: true };
		}
		return null;
	}

	static json(control: AbstractControl) {
		try {
			JSON.parse(control.value.replace(/\s\s+/g, ' '));
			return null;
		} catch {
			return { jsonInvalid: true };
		}
	}

	static checkFirstSymbolSpace(control: AbstractControl) {
		if (control.value?.match(new RegExp(/^\s/))) {
			return { invalidFirstSymbol: true };
		}
		return null;
	}

	static channel(control: AbstractControl) {
		if (
			JSON.stringify(control.value) ===
			JSON.stringify({
				email: false
				// push: false,
				// sms: false
				// telegram: false,
				// viber: false,
				// whatsapp: false
			})
		)
			return { required: true };

		return null;
	}

	static contactsImport(control: AbstractControl) {
		const columnValid = [];

		const columns = control.value;
		Object.keys(columns).forEach(key => {
			switch (columns[key]) {
				case 'email':
					columnValid.push(columns[key]);
					break;
				case 'phone':
					columnValid.push(columns[key]);
					break;
				default:
					break;
			}
		});

		if (columnValid.length === 0) return { required: true };

		return null;
	}

	static accountName(control: AbstractControl) {
		const REGEXP = /^[A-Z]{1}[^а-яА-Я]{0,}$/;
		if (REGEXP.test(control.value)) {
			return null;
		}
		return {
			invalidAccountName: true
		};
	}

	static systemName(control: AbstractControl) {
		const REGEXP = /^[a-z][a-z_-]{0,1}[\da-z_-]{0,}$/;
		if (REGEXP.test(control.value)) {
			return null;
		}
		return {
			invalidSystemName: true
		};
	}

	static bannedSysNamesDeny(control: AbstractControl) {
		const bannedSysNames = ['phone', 'email', 'id'];

		return bannedSysNames.includes(control.value)
			? {
					bannedSysName: true
			  }
			: null;
	}

	static transactionValidator(form: UntypedFormGroup) {
		if (
			form.controls.transaction.value &&
			!form.controls.userAgreement.value
		) {
			return {
				agreement: false
			};
		}
		return null;
	}

	static servicesValidation(
		form: UntypedFormGroup
	): { requireService: boolean } | null {
		const { enPop, enSend } = form.value;
		return !enPop && !enSend ? { requireService: true } : null;
	}

	static hex(control: AbstractControl) {
		const REGEXP = /^#[0-9a-fA-F]{6}$/;
		if (REGEXP.test(control.value)) {
			return null;
		}
		return {
			invalidHex: true
		};
	}

	static UUID(control: AbstractControl) {
		const REGEXP = UUID_REGEXP;
		if (REGEXP.test(control.value) || !control.value) {
			return null;
		}
		return {
			invalidUUID: true
		};
	}

	static maxLengthWithoutLink(maxLenght: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const text = removeLink(control.value);
			if (text.length <= maxLenght) {
				return null;
			}
			return {
				maxlength: true
			};
		};
	}

	static multipleLineBreakValidation(control: AbstractControl) {
		const REGEXP = /\n{3}/g;
		if (REGEXP.test(control.value)) {
			return {
				multipleLineBreak: true
			};
		}
		return null;
	}
}
