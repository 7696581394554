<en-autoComplete [formControl]="formTags"
                 [suggestions]="filteredTagsMultiple"
                 [dropdown]="true"
                 [multiple]="true"
                 [readonly]="readonly"
                 [showClear]="showClear"
                 [placeholder]="(placeholder ?? 'autocomplete_component.tags_placeholder') | translate"
                 appendTo="body"
                 (completeMethod)="filterTagMultiple($event)"
                 (onKeyUp)="addTag($event)"
                 (clickOnClear)="clearTags()"></en-autoComplete>
