/* eslint-disable */
import { dia } from '@clientio/rappid';
import {
	ScenarioShapesEnum
} from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';

import { severalPortsBlock, severalPortsBlockView } from './main.shapes';

export const distributionBlock = severalPortsBlock.define(
	ScenarioShapesEnum.DISTRIBUTION_BLOCK,
	{
		ports: {
			groups: {
				out: {
					attrs: {
						portBody: {
							refY: '26'
						}
					}
				}
			}
		}
	},
	{
		initialize(args: any, opts?: { [key: string]: any }) {
			const injector = opts?.injector;
			if (injector) this.injector = injector;
			const options = this.get('options');
			const subType = this.get('subType');
			const { params } = options[subType];

			const unsuitableID =
				options[subType].unsuitable || this.generatePortId();
			const unsuitablePath = ['options', 'distribution', 'unsuitable'];

			this.attributes.optionHeight =
				options[subType].distributionType === 'scenarioFields'
					? 42
					: 28;

			if (params.length) {
				this.unsuitableOptionHeight = 24;
			}

			this.prop(unsuitablePath, unsuitableID);

			this.prop(['ports', 'items'], this.getPorts());

			severalPortsBlock.prototype.initialize.call(this, args);
		},

		onChangeOptions() {
			const options = this.get('options');
			const subType = this.get('subType');
			const { params } = options[subType];

			const unsuitableID =
				options[subType].unsuitable || this.generatePortId();

			if (params.length && !this.getPort(unsuitableID)) {
				this.addPort({
					group: 'out',
					id: unsuitableID
				});
				this.unsuitableOptionHeight = 24;
			}

			if (!params.length) {
				this.unsuitableOptionHeight = 0;
				this.removePort(unsuitableID);
			}

			if (this.getPort(unsuitableID)) {
				this.updateUnsuitablePort();
			}

			severalPortsBlock.prototype.onChangeOptions.call(this);
		},

		updateUnsuitablePort() {
			const options = this.get('options');
			const subType = this.get('subType');
			const { params } = options[subType];
			const optionHeight = this.get('optionHeight');
			const padding = this.get('padding');
			let offsetY = 62;

			const unsuitablePortID = options[subType].unsuitable;

			params.forEach(() => {
				offsetY += optionHeight + padding;
			});

			this.portProp(unsuitablePortID, 'args/y', offsetY);
		}
	}
);

export const distributionBlockView: dia.ElementView = Object.assign(
	severalPortsBlockView
);
