import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Scenario } from '@enSend/scenario/_state';
import { Observable, Subject } from 'rxjs';
import { ScenarioFormOTD } from '../classes';

@Injectable()
export class MainFormService {
	private firstBoot$ = new Subject<void>();

	updateFromPolling = false;

	private readonly _form: UntypedFormGroup = this.fb.group({
		id: 0,
		name: '',
		isValid: false,
		cells: {
			cells: []
		},
		status: ''
	});

	constructor(private fb: UntypedFormBuilder) {}

	get firstBootChanges(): Observable<void> {
		return this.firstBoot$.asObservable();
	}

	get form(): UntypedFormGroup {
		return this._form;
	}

	patchForm(value: Scenario): void {
		const formOTD = new ScenarioFormOTD(value);
		const valueOTD = formOTD.getValue();
		this.form.patchValue(valueOTD);
	}

	setEntityLoaded() {
		this.firstBoot$.next();
	}

	hasFormError(): boolean {
		if (!this.form.get('name')?.value.trim()) {
			this.form.get('name')?.setErrors({
				required: true
			});
		}
		return this.form.invalid;
	}
}
