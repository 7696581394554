import { SelectItem } from 'primeng/api';
import { Units } from '../models/types';

export const UNIT_OPTIONS: SelectItem<Units>[] = [
	{
		label: 'segment_form.options_units_min',
		value: 'min'
	},
	{
		label: 'segment_form.options_units_hour',
		value: 'hour'
	},
	{
		label: 'segment_form.options_units_day',
		value: 'day'
	},
	{
		label: 'segment_form.options_units_month',
		value: 'month'
	}
];
