import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { EMAIL_REGEXP } from 'custom-validators';

@Injectable()
export class SendTestService {
	emailsInput: UntypedFormControl = new UntypedFormControl([]);

	mailsList = new Set([]);

	errorMessage$ = new BehaviorSubject<string>('');

	minLength(): boolean {
		return !this.emailsInput.value || this.emailsInput.value.length === 0;
	}

	validateEmail(email: string): boolean {
		switch (true) {
			case email === '':
				return true;
			case email.includes(',') || email.includes(' '):
				return true;
			case !EMAIL_REGEXP.test(email):
				return true;
			default:
				return false;
		}
	}
}
