import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { RestService } from 'utils';
import { Scenario, OnChangeStatus } from './scenario.model';

@Injectable({
	providedIn: 'root'
})
export class ScenariosDataService extends RestService<Scenario> {
	constructor(protected http: HttpClient) {
		super(http, 'ensend/scenario');
	}

	changeStatus({ id, status }: OnChangeStatus) {
		return this.http.put<void>(`ensend/scenario/status/`, {
			id,
			status
		});
	}

	delete(id: ID) {
		return this.http.delete<void>(`ensend/scenario/${id}/`);
	}
}
