<div *ngIf="isUseSegmentModal && !isInner" class="overlay overlay__non-interactive"></div>
<div *ngIf="isUseSegmentModal && !isInner" class="overlay overlay__cursor"></div>

<form [formGroup]="form"
      [class.force-validate]="forceValidate"
      [class.preview]="isPreview"
      [class.overlay__disabled]="isUseSegmentModal">
    <div class="group"
         (mouseenter)="isHover = true"
         (mouseleave)="isHover = false">
        <div class="header"
             [class.hide-condition]="isHide">
            {{ (isRoot ? 'segment_form.group_title_root_1':'segment_form.group_title_inner_1') | translate }}
            <en-dropdown class="group__join"
                         [options]="optionsJoin"
                         formControlName="join"></en-dropdown>
            {{ (isRoot ? 'segment_form.group_title_root_2':'segment_form.group_title_inner_2') | translate }}
        </div>
        <div class="button">
            <button *ngIf="isRoot && (inners.length || conditions?.length)"
                    enButton
                    class="en-button-lowercase h32 icon-button"
                    type="button"
                    icon="en en-trash"
                    [class.show-button]="isHover"
                    (click)="removeAll()"></button>

            <ng-container *ngIf="!isRoot && !isParentHide">
                <button *ngIf="isEnSendSegment && isHide"
                        enButton
                        class="en-button-lowercase h32 icon-button"
                        type="button"
                        icon="en en-eye-close"
                        [class.show-button]="isHover"
                        (click)="emitHideGroup.emit(innerIndex)"></button>
                <button *ngIf="isEnSendSegment && !isHide"
                        enButton
                        class="en-button-lowercase h32 icon-button"
                        type="button"
                        icon="en en-eye"
                        [class.show-button]="isHover"
                        (click)="emitHideGroup.emit(innerIndex)"></button>
                <button enButton
                        class="en-button-lowercase h32 icon-button"
                        type="button"
                        icon="en en-copy"
                        [class.show-button]="isHover"
                        (click)="emitCopyGroup.emit(innerIndex)"></button>
                <button enButton
                        class="en-button-lowercase h32 icon-button"
                        type="button"
                        icon="en en-trash"
                        [class.show-button]="isHover"
                        (click)="emitRemoveGroup.emit(innerIndex)"></button>
            </ng-container>
        </div>
    </div>
    <div class="options"
         [class.hide-condition]="isHide">
        <ng-template ngFor
                     let-option
                     [ngForOf]="conditionArray.controls"
                     let-i="index"
                     let-lastOption="last"
                     let-firstOption="first">
            <div class="options__option"
                 [ngSwitch]="true">
                <div class="arrow"
                     [class.first-option]="firstOption"></div>
                <!-- Условия для сообщений -->
                <!-- 1 - Поле / Группа рассылок / Дата подписки  -->
                <en-field-condition [form]="option"
                                    [index]="i"
                                    [conditionsArray]="conditionArray"
                                    [isHideParent]="isHide"
                                    (changeType)="changeCondition($event)"
                                    (removeCondition)="removeCondition($event)"
                                    (copyCondition)="copyCondition($event)"
                                    *ngSwitchCase="option.get('type').value === 'field'"></en-field-condition>
                <en-subscription-condition [form]="option"
                                           [index]="i"
                                           [conditionsArray]="conditionArray"
                                           [isHideParent]="isHide"
                                           (changeType)="changeCondition($event)"
                                           (removeCondition)="removeCondition($event)"
                                           (copyCondition)="copyCondition($event)"
                                           *ngSwitchCase="option.get('type').value === 'subscription'">
                </en-subscription-condition>
                <en-subscription-date-condition [form]="option"
                                                [index]="i"
                                                [conditionsArray]="conditionArray"
                                                [isHideParent]="isHide"
                                                (changeType)="changeCondition($event)"
                                                (removeCondition)="removeCondition($event)"
                                                (copyCondition)="copyCondition($event)"
                                                *ngSwitchCase="option.get('type').value === 'subscriptionDate'">
                </en-subscription-date-condition>
                <en-unsubscription-condition [form]="option"
                                             [index]="i"
                                             [conditionsArray]="conditionArray"
                                             [isHideParent]="isHide"
                                             (changeType)="changeCondition($event)"
                                             (removeCondition)="removeCondition($event)"
                                             (copyCondition)="copyCondition($event)"
                                             *ngSwitchCase="option.value.type === 'unsubscription'">
                </en-unsubscription-condition>
                <en-tag-condition [form]="option"
                                  [index]="i"
                                  [conditionsArray]="conditionArray"
                                  [isHideParent]="isHide"
                                  (changeType)="changeCondition($event)"
                                  (removeCondition)="removeCondition($event)"
                                  (copyCondition)="copyCondition($event)"
                                  *ngSwitchCase="option.get('type').value === 'tag'">
                </en-tag-condition>
                <en-rfm-condition [form]="option"
                                  [index]="i"
                                  [conditionsArray]="conditionArray"
                                  [isHideParent]="isHide"
                                  (changeType)="changeCondition($event)"
                                  (removeCondition)="removeCondition($event)"
                                  (copyCondition)="copyCondition($event)"
                                  *ngSwitchCase="option.get('type').value === 'currentRFM'">
                </en-rfm-condition>
                <en-rfm-condition [form]="option"
                                  [index]="i"
                                  [conditionsArray]="conditionArray"
                                  [isHideParent]="isHide"
                                  (changeType)="changeCondition($event)"
                                  (removeCondition)="removeCondition($event)"
                                  (copyCondition)="copyCondition($event)"
                                  *ngSwitchCase="option.get('type').value === 'previousRFM'">
                </en-rfm-condition>
                <en-birthday-date [form]="option"
                                  [index]="i"
                                  [conditionsArray]="conditionArray"
                                  [isHideParent]="isHide"
                                  (changeType)="changeCondition($event)"
                                  (removeCondition)="removeCondition($event)"
                                  (copyCondition)="copyCondition($event)"
                                  *ngSwitchCase="option.get('type').value === 'birthdayDate'"></en-birthday-date>

                <!-- 2 - По сообщениям -->
                <en-message [form]="option"
                            [index]="i"
                            [conditionsArray]="conditionArray"
                            [submitted]="submitted"
                            [isPreview]="isPreview"
                            [isHideParent]="isHide"
                            (changeType)="changeCondition($event)"
                            (removeCondition)="removeCondition($event)"
                            (copyCondition)="copyCondition($event)"
                            *ngSwitchCase="option.get('type').value === 'message'"></en-message>

                <!-- 3 - SQL-шаблоны -->
                <en-sql-request [form]="option"
                                [index]="i"
                                [conditionsArray]="conditionArray"
                                [submitted]="submitted"
                                [isHideParent]="isHide"
                                (changeType)="changeCondition($event)"
                                (removeCondition)="removeCondition($event)"
                                (copyCondition)="copyCondition($event)"
                                *ngSwitchCase="option.get('type').value === 'sql_template'"></en-sql-request>

                <!-- 4 - Поведение на сайте -->
                <en-product-condition [form]="option"
                                      [index]="i"
                                      [conditionsArray]="conditionArray"
                                      [isHideParent]="isHide"
                                      (changeType)="changeCondition($event)"
                                      (removeCondition)="removeCondition($event)"
                                      (copyCondition)="copyCondition($event)"
                                      *ngSwitchCase="option.get('type').value === 'product' || option.get('type').value === 'not_product'">
                </en-product-condition>
                <en-cart-condition [form]="option"
                                   [index]="i"
                                   [conditionsArray]="conditionArray"
                                   [isHideParent]="isHide"
                                   (changeType)="changeCondition($event)"
                                   (removeCondition)="removeCondition($event)"
                                   (copyCondition)="copyCondition($event)"
                                   *ngSwitchCase="option.get('type').value === 'cart' || option.get('type').value === 'not_cart'">
                </en-cart-condition>
                <en-purchase-condition [form]="option"
                                       [index]="i"
                                       [conditionsArray]="conditionArray"
                                       [isHideParent]="isHide"
                                       (changeType)="changeCondition($event)"
                                       (removeCondition)="removeCondition($event)"
                                       (copyCondition)="copyCondition($event)"
                                       *ngSwitchCase="option.get('type').value === 'purchase' ||
                                        option.get('type').value === 'not_purchase'">
                </en-purchase-condition>
                <en-favourite-condition [form]="option"
                                        [index]="i"
                                        [conditionsArray]="conditionArray"
                                        [isHideParent]="isHide"
                                        (changeType)="changeCondition($event)"
                                        (removeCondition)="removeCondition($event)"
                                        (copyCondition)="copyCondition($event)"
                                        *ngSwitchCase="option.get('type').value === 'favourite' ||
                                         option.get('type').value === 'not_favourite'">
                </en-favourite-condition>
                <en-categories-condition [form]="option"
                                         [index]="i"
                                         [conditionsArray]="conditionArray"
                                         [isHideParent]="isHide"
                                         (changeType)="changeCondition($event)"
                                         (removeCondition)="removeCondition($event)"
                                         (copyCondition)="copyCondition($event)"
                                         *ngSwitchCase="option.get('type').value === 'category_open'||
                                         option.get('type').value === 'not_category_open'"></en-categories-condition>
                <en-visits-condition [form]="option"
                                     [index]="i"
                                     [conditionsArray]="conditionArray"
                                     [isHideParent]="isHide"
                                     (changeType)="changeCondition($event)"
                                     (removeCondition)="removeCondition($event)"
                                     (copyCondition)="copyCondition($event)"
                                     *ngSwitchCase="option.get('type').value === 'visits' || option.get('type').value === 'not_visits'">
                </en-visits-condition>
                <en-comparison-condition [form]="option"
                                         [index]="i"
                                         [conditionsArray]="conditionArray"
                                         [isHideParent]="isHide"
                                         (changeType)="changeCondition($event)"
                                         (removeCondition)="removeCondition($event)"
                                         (copyCondition)="copyCondition($event)"
                                         *ngSwitchCase="option.get('type').value === 'comparison'
                                         || option.get('type').value === 'not_comparison'">
                </en-comparison-condition>
                <!-- 5 - Пользовательские события -->
                <en-user-events [form]="option"
                                [index]="i"
                                [conditionsArray]="conditionArray"
                                [isHideParent]="isHide"
                                [isUseSegmentModal]="isUseSegmentModal"
                                (changeType)="changeCondition($event)"
                                (removeCondition)="removeCondition($event)"
                                (copyCondition)="copyCondition($event)"
                                *ngSwitchCase="checkIsCustomEvent(option)">
                </en-user-events>

                <!-- Условия для всплывающих окон -->
                <!-- Поведение на сайте -->
                <en-time-condition [form]="option"
                                   [index]="i"
                                   [conditionsArray]="conditionArray"
                                   (changeType)="changeCondition($event)"
                                   (removeCondition)="removeCondition($event)"
                                   (copyCondition)="copyCondition($event)"
                                   *ngSwitchCase="option.get('type').value === 'time'"></en-time-condition>
                <en-scroll-condition [form]="option"
                                     [index]="i"
                                     [conditionsArray]="conditionArray"
                                     (changeType)="changeCondition($event)"
                                     (removeCondition)="removeCondition($event)"
                                     (copyCondition)="copyCondition($event)"
                                     *ngSwitchCase="option.get('type').value === 'scroll'"></en-scroll-condition>
                <en-referrers-condition [form]="option"
                                        [index]="i"
                                        [conditionsArray]="conditionArray"
                                        (changeType)="changeCondition($event)"
                                        (removeCondition)="removeCondition($event)"
                                        (copyCondition)="copyCondition($event)"
                                        *ngSwitchCase="option.get('type').value === 'referrers'">
                </en-referrers-condition>
                <en-visits-count [form]="option"
                                 [submitted]="submitted"
                                 [index]="i"
                                 [conditionsArray]="conditionArray"
                                 (changeType)="changeCondition($event)"
                                 (removeCondition)="removeCondition($event)"
                                 (copyCondition)="copyCondition($event)"
                                 *ngSwitchCase="option.get('type').value === 'visitsCount'"></en-visits-count>
                <en-close-condition [form]="option"
                                    [index]="i"
                                    [conditionsArray]="conditionArray"
                                    (changeType)="changeCondition($event)"
                                    (removeCondition)="removeCondition($event)"
                                    (copyCondition)="copyCondition($event)"
                                    *ngSwitchCase="option.get('type').value === 'close'"></en-close-condition>
                <en-cart-change-condition [form]="option"
                                          [index]="i"
                                          [conditionsArray]="conditionArray"
                                          (changeType)="changeCondition($event)"
                                          (removeCondition)="removeCondition($event)"
                                          (copyCondition)="copyCondition($event)"
                                          *ngSwitchCase="option.get('type').value === 'cartChange'"></en-cart-change-condition>

                <!-- Адрес страницы -->
                <en-url-condition [form]="option"
                                  [index]="i"
                                  [conditionsArray]="conditionArray"
                                  (changeType)="changeCondition($event)"
                                  (removeCondition)="removeCondition($event)"
                                  (copyCondition)="copyCondition($event)"
                                  *ngSwitchCase="option.get('type').value === 'url'"></en-url-condition>
                <en-utm-condition [form]="option"
                                  [index]="i"
                                  [conditionsArray]="conditionArray"
                                  (changeType)="changeCondition($event)"
                                  (removeCondition)="removeCondition($event)"
                                  (copyCondition)="copyCondition($event)"
                                  *ngSwitchCase="option.get('type').value === 'utm'"></en-utm-condition>

                <!-- Тип устройства -->
                <en-device-condition [form]="option"
                                     [index]="i"
                                     [conditionsArray]="conditionArray"
                                     (changeType)="changeCondition($event)"
                                     (removeCondition)="removeCondition($event)"
                                     (copyCondition)="copyCondition($event)"
                                     *ngSwitchCase="option.get('type').value === 'device'"></en-device-condition>

                <!-- Верстка -->
                <en-html-condition [form]="option"
                                   [index]="i"
                                   [conditionsArray]="conditionArray"
                                   (changeType)="changeCondition($event)"
                                   (removeCondition)="removeCondition($event)"
                                   (copyCondition)="copyCondition($event)"
                                   *ngSwitchCase="option.get('type').value === 'html'"></en-html-condition>

                <!-- Local storage -->
                <en-local-storage-condition [form]="option"
                                            [index]="i"
                                            [conditionsArray]="conditionArray"
                                            (changeType)="changeCondition($event)"
                                            (removeCondition)="removeCondition($event)"
                                            (copyCondition)="copyCondition($event)"
                                            *ngSwitchCase="option.value.type === 'localStorage'"></en-local-storage-condition>

            </div>
        </ng-template>
        <div *ngIf="!isPreview"
             class="options__option option-add">
            <div class="arrow"
                 [class.last-option]="!inners.controls.length"
                 [class.first-option]="!conditionArray.controls.length"></div>

            <en-multilevel-dropdown [options]="mainMenuOptions"
                                    [isButtonMode]="true"
                                    [useMainMenuStyle]="true"
                                    [useExtraButtonText]="'segment_common.add_group_button' | translate"
                                    [useSecondaryButton]="true"
                                    (itemClick)="addCondition($event)"
                                    (extraButtonClick)="addGroup()"></en-multilevel-dropdown>
        </div>
    </div>
    <div class="inners"
         *ngFor="let inner of inners.controls;last as lastInners;let j = index; trackBy: trackByIndex">
        <div class="inners__inner">
            <div class="arrow inner"
                 [class.last-inner]="lastInners"></div>
            <en-segments-tree [form]="inner"
                              [innerIndex]="j"
                              [isAccessEnpop]="isAccessEnpop"
                              [isAccessEnsend]="isAccessEnsend"
                              [isAccessTables]="isAccessTables"
                              [isUseSegmentModal]="isUseSegmentModal"
                              [mainMenuOptions]="mainMenuOptions"
                              [forceUpdate$]="forceUpdate$"
                              [isParentHide]="isHide"
                              [isInner]="true"
                              [isPreview]="isPreview"
                              (emitRemoveGroup)="removeGroup($event)"
                              (emitCopyGroup)="copyGroup($event)"
                              (emitHideGroup)="hideGroup($event)"></en-segments-tree>
        </div>
    </div>
</form>
<!-- 6 - Использование сегментов в сегментах -->
<en-segment-selection-modal *ngIf="isModalVisible"
                            [visible]="isModalVisible"
                            (close)="closeSelectionModal()"
                            (select)="confirmSelectionModal($event)"></en-segment-selection-modal>

<pre *ngIf="showJson && !isInner">{{ form.value | json }}</pre>
