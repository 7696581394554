import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input
} from '@angular/core';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { SelectItem } from 'primeng/api';

import { PreviewControlsService } from '../../services';
import {
	OPTIONS_DISABLE_TNXPAGE,
	OPTIONS_ENABLE_TNXPAGE,
	POPUP_DEVICE_PREVIEW_OPTIONS
} from '../misc';
import { PopupPreview } from '../misc/abstract-preview';

@Component({
	selector: 'en-preview-buttons',
	templateUrl: './preview-buttons.component.html',
	styleUrls: ['./preview-buttons.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: POPUP_DEVICE_PREVIEW_OPTIONS,
			multi: true
		},
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: OPTIONS_ENABLE_TNXPAGE,
			multi: true
		},
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: OPTIONS_DISABLE_TNXPAGE,
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewButtonsComponent extends PopupPreview {
	previewOptions: SelectItem[] = [];

	@Input() set disableThanksPage(value: boolean) {
		if (value) this.previewOptions = this.previewWithTnxPage;
		else this.previewOptions = this.previewWithoutTnxPage;
	}

	constructor(
		@Inject(SELECT_OPTIONS_TOKEN)
		public readonly selectOptions: Array<
			SelectItem<'popup' | 'thanksPage'>[]
		>,
		private controlsService: PreviewControlsService
	) {
		super();
	}

	get selectedPagePreview() {
		return this.controlsService.selectedPagePreview;
	}

	get devicePreview() {
		return this.controlsService.devicePreview;
	}

	get previewWithTnxPage() {
		return this.selectOptions[1];
	}

	get previewWithoutTnxPage() {
		return this.selectOptions[2];
	}

	handleViewMode(event: string) {
		this.devicePreview.patchValue(event);
	}
}
