import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	Renderer2,
	ViewChild
} from '@angular/core';
import { SmsPreviewService } from '../sms-preview.service';

@Component({
	selector: 'en-preview-android',
	templateUrl: './preview-android.component.html',
	styleUrls: ['./preview-android.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewAndroidComponent
	implements AfterViewInit, AfterViewChecked
{
	@Input() preview: string;
	@Input() placeholder = false;

	@ViewChild('iframe') iframeRef: ElementRef;

	constructor(
		private smsPreviewService: SmsPreviewService,
		private renderer: Renderer2,
		private cd: ChangeDetectorRef
	) {}

	get isDetail(): boolean {
		return this.smsPreviewService.isDetail;
	}

	get fullScreenPhone(): boolean {
		return this.smsPreviewService.fullScreenPhone;
	}

	get transformSlide() {
		return this.smsPreviewService.transformSlide;
	}

	get viewSettings() {
		return this.smsPreviewService.activeViewsSettings;
	}

	ngAfterViewInit() {
		this.smsPreviewService.setView(this.iframeRef, this.renderer);
		this.smsPreviewService.changeHeightIframe();

		// костыль. При переходе с шага offsetHeight равен 0. При перезагрузке все ок.
		if (this.iframeRef.nativeElement.offsetHeight === 0) {
			setTimeout(() => {
				this.smsPreviewService.changeHeightIframe();
				this.cd.detectChanges();
			}, 0);
		}
	}

	ngAfterViewChecked() {
		this.smsPreviewService.changeHeightIframe();
		this.cd.detectChanges();
	}

	toogleDetail() {
		this.smsPreviewService.toogleDetail();
		this.smsPreviewService.changeHeightIframe();
	}
}
