import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TestSendingState, TestSendingStore } from './test-sending.store';

@Injectable({
	providedIn: 'root'
})
export class TestSendingQuery extends QueryEntity<TestSendingState> {
	constructor(protected store: TestSendingStore) {
		super(store);
	}

	selectAll$ = this.select(state => state);
}
