/* eslint-disable @typescript-eslint/no-use-before-define */
import { ID } from '@datorama/akita';
import { CommonMessageModel } from '@enSend/message/message-wizard/kit';
import { MailingGroup } from '@state-enKod/mailing-groups';
import {
	WaTemplateParamsDTO,
	WaTemplateParamsOTD
} from '@enSend/message/whatsapp-template-wizard/models';
import { UrlParams } from './entity-params.model';
import { MailEntity } from './mail-entity.model';
import { MobPushEntity } from './mob-push-entity.model';
import { PushEntity } from './push-entity.model';
import { SmsEntity } from './sms-entity.model';
import { UploadFileResponse } from './file-upload.model';

export interface MessageModel extends CommonMessageModel {
	status: WizardStatus;
	sendingType: SendingType | '';
	sendingSettings?: any;
	splitTestSettings?: SplitTestSettings;
	importInfo: UploadFileResponse | {};
	mails?: MailEntity[];
	pushes?: PushEntity[];
	mobPushes?: MobPushEntity[];
	sms?: SmsEntity[];
	segments?: ISegments[];
	groups: MailingGroup[];
	exceptSegments: ISegments[];
	isSplitTest?: boolean;
	name: string;
	whatsappTemplateId?: number;
	whatsappParams?: WaTemplateParamsOTD;
	isDeleted: boolean;
}
export interface ISegments {
	id: ID;
	name: string;
	isDeleted?: boolean;
}

export interface SplitTestSettings {
	choiceWinner: ChoiceWinner;
	duration: number;
	testGroupSize: number;
}

export type ChoiceWinner = 'clicks' | 'opens' | 'manually' | 'withoutWinner';

export interface TestMail {
	messageId: ID;
	name: string;
	fromName: string;
	fromEmail: string;
	toEmails: string[];
	subject: string;
	preheader: string;
	withAttachments: boolean;
	plainText: string;
	html: string;
	amp: string;
	urlParams: UrlParams;
	replyToName: string;
	replyToEmail: string;
	trackClicks: boolean;
	testingMailId?: ID;
	fileAttachments?: boolean;
}

export type TestSms = {
	messageId: number;
	body: string;
	toShortLink: boolean;
	urlParams: UrlParams;
	toPhones: string[];
};

export type TestWhatsapp = {
	messageId: number;
	templateId: number;
	phones: string[];
	params: WaTemplateParamsDTO;
	sendingType: SendingType;
};

export type SendingType =
	| 'sendnow'
	| 'sendlate'
	| 'regular'
	| 'api'
	| 'event'
	| 'doi'
	| 'scenario'
	| '';

export type WizardStatus = 'draft' | 'inactive';
