<div *ngIf="toggleSideBar$ | async"
     class="wrapper"
     [class.widht-400]="namespace === 'chatbot'"
     [@tuiSlideInRight]>
    <ng-container *ngIf="inspector$ | async as inspector">
        <tui-scrollbar>
            <div class="bar">
                <div class="bar__header">
                    <div class="bar__title">
                        {{ inspector.name | tuiMapper : getInspectorText : namespace | translate}}
                    </div>
                    <ng-container *ngIf="isCreationMode">
                        <div class="bar__toolbar">
                            <button type="button"
                                    enButton
                                    class="en-button-lowercase h32"
                                    icon="en en-copy"
                                    (click)="copyElement()"></button>
                            <button type="button"
                                    enButton
                                    class="en-button-lowercase h32"
                                    icon="en en-trash"
                                    (click)="removeElement()"></button>
                        </div>
                    </ng-container>
                </div>
                <div class="bar__content">
                    <div polymorpheus-outlet
                         [content]="inspector.content"
                         [context]="context$">
                    </div>
                </div>
            </div>
        </tui-scrollbar>
    </ng-container>
</div>
