export interface UploadEvent {
	files: File[];
}

export interface UploadFileResponse {
	id: number;
	fileName: string;
	countContacts: number;
	status?: string;
}

export interface ResponseStatus {
	status: string;
}
