import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as joint from 'jointjs';
import { FORM_TOKEN, VALIDATION_TOKEN } from '@enkod-core/tokens';
import { FormService } from '@enSend/_shared/models';
import { Subject } from 'rxjs';
import { RappidService } from '../../services/rappid.service';
import { TopBarService } from '../../services/top-bar.service';

@Component({
	selector: 'en-chatbot-top-bar',
	templateUrl: './chatbot-top-bar.component.html',
	styleUrls: [
		'../scenario-top-bar/scenario-top-bar.component.scss',
		'./chatbot-top-bar.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotTopBarComponent {
	constructor(
		private rappid: RappidService,
		@Inject(FORM_TOKEN)
		private formService: FormService,
		@Inject(VALIDATION_TOKEN)
		readonly checkValidate$: Subject<void>,
		private topbarService: TopBarService
	) {}

	isTopBarOpened = true;

	collapseToggleBar() {
		this.isTopBarOpened = !this.isTopBarOpened;
	}

	get canva() {
		return this.rappid.canva;
	}

	get nameControl() {
		return this.formService.form.get('name');
	}

	get form() {
		return this.formService.form;
	}

	download() {
		const imageTitle = this.nameControl?.value;
		this.rappid.paper.toPNG(
			png => {
				// eslint-disable-next-line prefer-template
				joint.util.downloadDataUri(png, imageTitle + '.png');
			},
			{
				padding: 15
			}
		);
	}

	toggleHotkeys() {
		const value = this.topbarService.hotkeysOpened$.getValue();
		this.topbarService.toggleHotkeys(!value);
	}
}
