import {
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import { TimeConditionForm } from '@enKod/segments/segments-form.model';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import { AbstractCondition } from '../../../abstract-condition.component';
import { UnitsDeclinationService } from '../../../common/services/units-declination.service';

@UntilDestroy()
@Component({
	selector: 'en-time-condition',
	templateUrl: './time-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeConditionComponent extends AbstractCondition {
	optionsUnits: SelectItem[];

	constructor(
		public segmentsValidateService: SegmentsValidateService,
		public cd: ChangeDetectorRef,
		private declinationService: UnitsDeclinationService
	) {
		super(segmentsValidateService, cd);
	}

	get valueControl(): FormControl<number> {
		return (this.form as FormGroup<TimeConditionForm>).controls
			.value as FormControl<number>;
	}

	setValidation() {
		this.setCaseUnits();
		this.getSubscription('value')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (value < 1) this.setValue('value', 1);
				if (value > 1000) this.setValue('value', 1000);
			});
	}

	updateValue() {
		this.setCaseUnits();
	}

	private setCaseUnits() {
		this.optionsUnits = this.declinationService.getCaseUnits(
			this.valueControl,
			true,
			true
		);
	}
}
