/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { block, blockView } from '../../base.shapes';
import { ScenarioShapesEnum } from '../../../../config/enum.shapes';

export const mainBlock = block.define(
	ScenarioShapesEnum.MAIN_BLOCK,
	{
		ports: {
			items: [{ group: 'in' }, { group: 'out' }]
		},
		attrs: {
			headerRect: {
				fill: '#1B77E0',
				stroke: '#1061C3'
			}
		}
	},
	{
		initialize(...args: any) {
			block.prototype.initialize.apply(this, args);
			this.onChangeOptions();
		},

		onChangeOptions(args: any) {
			block.prototype.onChangeOptions.call(this, {
				updatePorts: true,
				...args
			});
		}
	}
);

export const mainBlockView: dia.ElementView = Object.assign(blockView);
