import { TableColumn } from '@enkod-core/interfaces';

export const TABLE_COLUMNS_MESSAGE: TableColumn[] = [
	{
		field: 'id',
		header: 'message_list.table_header_id',
		reorderableDisabled: true,
		width: '5%'
	},
	{
		field: 'name',
		header: 'message_list.table_header_name',
		width: '30%',
		'min-width': '15.5rem'
	},
	{
		field: 'type',
		header: 'message_list.table_header_type',
		width: '10%',
		'min-width': '10rem'
	},
	{
		field: 'sendingType',
		header: 'message_list.table_header_sending_type',
		width: '15%',
		'min-width': '11.25rem'
	},
	{
		field: 'status',
		header: 'message_list.table_header_sending_status',
		width: '13%',
		'min-width': '11.75rem'
	},
	{
		field: 'tags',
		header: 'message_list.table_header_tags',
		width: '25%',
		'min-width': '15.5rem',
		sortableDisabled: true
	}
];

export const TABLE_COLUMNS_SCENARIO: TableColumn[] = [
	{
		field: 'id',
		header: 'message_list.table_header_id',
		reorderableDisabled: true,
		width: '5%'
	},
	{
		field: 'name',
		header: 'message_list.table_header_name',
		width: '30%',
		'min-width': '15.5rem'
	},
	{
		field: 'type',
		header: 'message_list.table_header_type',
		width: '15%',
		'min-width': '3rem'
	},
	{
		field: 'scenarioName',
		header: 'message_list.table_header_scenario_name',
		width: '15%',
		'min-width': '3rem'
	},
	{
		field: 'status',
		header: 'message_list.table_header_sending_status',
		width: '13%',
		'min-width': '12rem'
	},
	{
		field: 'tags',
		header: 'message_list.table_header_tags',
		width: '25%',
		'min-width': '15.5rem',
		sortableDisabled: true
	}
];
