import { shapes } from '@clientio/rappid';
import { ShapeTypesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';
import { separateThousands } from '../../../misc/separate-thousands';

// @ts-ignore
export const enKodLink = shapes.standard.Link.define(
	ShapeTypesEnum.LINK,
	{
		connector: {
			name: 'rounded',
			args: {
				raw: true,
				radius: 5
			}
		},
		attrs: {
			line: {
				stroke: '#A5A9B8',
				strokeWidth: 2,
				strokeDasharray: '4 4',
				targetMarker: null,
				wrapper: {
					connection: true,
					strokeWidth: 10,
					strokeLinejoin: 'round'
				}
			}
		}
	},
	{
		initialize() {
			this.initLabels();
		},
		initLabels() {
			const count = this.get('count');

			this.appendLabel({
				markup: [
					{
						tagName: 'rect',
						selector: 'body'
					},
					{
						tagName: 'text',
						selector: 'label'
					}
				],
				attrs: {
					label: {
						text: separateThousands(count),
						fill: '#fff',
						fontFamily: 'var(--en-font-family)',
						fontSize: 12,
						textAnchor: 'middle',
						yAlignment: 'middle'
						// pointerEvents: 'none'
					},
					body: {
						ref: 'label',
						refX: -8,
						refY: -5,
						width: 'calc(w + 16)',
						height: 24,
						rx: 12,
						fill: '#8B8F9E'
					}
				}
			});
		}
	}
);
