<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsPage"
                 [formControl]="type"
                 (onChange)="onChangeType($event)"></en-dropdown>
    <en-dropdown class="input__small"
                 [class.active-hide]="showHide"
                 styleClass="en-dropdown-solid"
                 [options]="optionsParameter"
                 formControlName="parameter"></en-dropdown>

    <ng-container [ngSwitch]="parameterValue">
        <!-- По ID -->
        <ng-template [ngSwitchCase]="'id'">
            <input *ngIf="form.get('value')"
                   class="h32 text-overflow"
                   [class.active-hide]="showHide"
                   type="text"
                   enInput
                   enDynamicWidth="15rem"
                   showClear="true"
                   formControlName="value" />
        </ng-template>
        <!-- Суммарно -->
        <ng-template [ngSwitchCase]="'quantity'">
            <div class="addition"
                 [class.active-hide]="showHide">
                <en-dropdown *ngIf="form.get('operator')"
                             class="input__small"
                             styleClass="en-dropdown-solid"
                             [options]="optionsOperatorFull"
                             formControlName="operator"></en-dropdown>
                <input *ngIf="form.get('value')"
                       class="h32 text-overflow"
                       type="number"
                       min="1"
                       max="1000"
                       step="1"
                       enInput
                       enDynamicWidth
                       pKeyFilter="pint"
                       showClear="true"
                       formControlName="value" />
                <span translate
                      class="center-span-text">segment_form.cond_product_count</span>
            </div>
        </ng-template>
    </ng-container>
    <div>
        <en-action-time [class.active-hide]="showHide"
                        [timeForm]="actionTime"
                        [isHide]="isHideParent || isHide"
                        [optionsPeriod]="optionsPeriod"></en-action-time>
    </div>
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>
</form>
