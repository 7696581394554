/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { toUnix } from '@enkod-core/utils/time';
import { AuthQuery, AuthService } from '@state-auth';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private authQuery: AuthQuery
	) {}

	canActivate(): Observable<boolean> | boolean {
		if (!this.authQuery.isLoggedIn) {
			this.authService.logout();
			return false;
		}

		const expireDate = this.authQuery.getValue()?.expire || 0;
		const now = toUnix(new Date());

		if (expireDate < now) {
			console.log('Guard: token expired');
			return this.updateToken();
		}
		return true;
	}

	private updateToken(): Observable<boolean> {
		return new Observable(observer => {
			this.authService.refreshToken().subscribe({
				complete: () => {
					console.log('Guard: token updated');
					observer.next(true);
				},
				error: () => {
					console.log('Guard: error updating token');
					this.authService.logout();
					observer.next(false);
				}
			});
		});
	}
}
