import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

import { SelectItem } from 'primeng/api';

@Component({
	selector: 'en-view-buttons',
	template: `
		<en-selectbutton
			styleClass="h32"
			[ngModel]="currentValue"
			(ngModelChange)="onChange.emit($event)"
			[options]="options"
		></en-selectbutton>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewButtonsComponent {
	@Input() options: SelectItem[];
	@Input() currentValue: string;

	@Output() onChange = new EventEmitter();
}
