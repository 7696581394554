import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { EnDropdownModule, EnMessagesModule } from 'ui-lib';

import { DuplicateHandlingComponent } from './duplicate-handling.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		EnDropdownModule,
		EnMessagesModule
	],
	exports: [DuplicateHandlingComponent],
	declarations: [DuplicateHandlingComponent]
})
export class DuplicateHandlingModule {}
