import {
	Component,
	Input,
	Output,
	EventEmitter,
	Inject,
	ChangeDetectionStrategy
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SendingTypes } from 'app/modules/enKod/segments/_state/segment.model';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';
import { SELECT_OPTIONS_TOKEN } from '@enkod-core/tokens';
import { SENDING_TYPE_OPTIONS } from './sending-type-options';

@UntilDestroy()
@Component({
	selector: 'en-message-add-type',
	templateUrl: './message-add-type.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	providers: [
		{
			provide: SELECT_OPTIONS_TOKEN,
			useValue: SENDING_TYPE_OPTIONS
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageAddTypeComponent {
	filtredOptions: SelectItem<SendingTypes>[] = this.optionsSendingType;
	sendingType = new FormControl('sendnow');
	isHover = false;

	@Input()
	set channelType(value: string) {
		switch (value) {
			case 'sms':
				this.filtredOptions = this.optionsSendingType.filter(
					option => option.value !== 'doi' && option.value !== 'event'
				);
				break;
			case 'push':
				this.filtredOptions = this.optionsSendingType.filter(
					option =>
						option.value !== 'doi' &&
						option.value !== 'event' &&
						option.value !== 'api'
				);
				break;
			case 'mobPush':
				this.filtredOptions = this.optionsSendingType.filter(
					({ value }) => value === 'sendnow'
				);
				break;
			default:
				this.filtredOptions = this.optionsSendingType;
				break;
		}
	}

	@Output() remove = new EventEmitter<void>();

	constructor(
		@Inject(NgControl)
		private ngControl: NgControl,
		public translate: TranslateService,
		@Inject(SELECT_OPTIONS_TOKEN)
		private optionsSendingType: SelectItem<SendingTypes>[]
	) {
		this.ngControl.valueAccessor = this;
		this.setupControlListener();
	}

	private onChange: Function = EMPTY_FUNCTION;

	writeValue(value: SendingTypes) {
		this.sendingType.patchValue(value);
	}

	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	registerOnTouched() {}

	private setupControlListener(): void {
		this.sendingType.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => this.onChange(value));
	}
}
