import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnIphoneViewModule } from 'app/ui-lib/mobile-preview-mock/iphone-view/iphone-view.module';
import { MobilePreviewComponent } from './mobile-preview.component';
import { PopupPreviewIframeModule } from '../../../popup-preview-iframe/popup-preview-iframe.module';

@NgModule({
	imports: [CommonModule, PopupPreviewIframeModule, EnIphoneViewModule],
	declarations: [MobilePreviewComponent],
	exports: [MobilePreviewComponent]
})
export class MobilePreviewModule {}
