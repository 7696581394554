import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapLinkModule } from '@enSend/message/message-wizard/kit/directives';
import { TranslateModule } from '@ngx-translate/core';
import { BubbleMessageComponent } from './bubble-message.component';

@NgModule({
	imports: [CommonModule, TranslateModule, WrapLinkModule],
	declarations: [BubbleMessageComponent],
	exports: [BubbleMessageComponent]
})
export class EnBubbleMessageModule {}
