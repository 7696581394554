import { TuiSvgModule } from '@taiga-ui/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SidebarModule } from 'primeng/sidebar';
import { TuiLetModule } from '@taiga-ui/cdk';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

import { TuiScrollbarModule } from '@enkod-core/components';

import {
	EnButtonModule,
	EnClicksMapModule,
	EnMobileMockModule,
	EnSelectButtonModule,
	EnTooltipModule,
	HtmlPreviewDirectiveModule
} from 'ui-lib';

import { SharedModule } from 'app/shared/shared.module';
import { EnViewButtonsModule } from 'app/ui-lib/view-buttons/view-buttons.module';
import { WhatsappPreviewModule } from '@enSend/message/message-wizard/kit';
import { SmsPreviewService } from './sms-preview/sms-preview.service';
import { MobilePushPreviewComponent } from './mobile-push-preview/mobile-push-preview.component';
import { MessagePreviewComponent } from './message-preview.component';
import { MailPreviewComponent } from './mail-preview/mail-preview.component';
import { PushPreviewComponent } from './push-preview/push-preview.component';
import { PreviewUrlParamsPipeModule, ShortLinkPipeModule } from './pipes';
import { SelectViewService } from '../../../message-wizard/message-wizard-common/services/select-view.service';
import { SmsPreviewModule } from './sms-preview/sms-preview.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		HtmlPreviewDirectiveModule,
		EnButtonModule,
		EnSelectButtonModule,
		SidebarModule,
		EnMobileMockModule,
		CodemirrorModule,
		EnViewButtonsModule,
		EnClicksMapModule,
		EnTooltipModule,
		TuiSvgModule,
		SmsPreviewModule,
		ShortLinkPipeModule,
		PreviewUrlParamsPipeModule,
		TuiLetModule,
		WhatsappPreviewModule,
		TuiScrollbarModule
	],
	declarations: [
		MessagePreviewComponent,
		MailPreviewComponent,
		PushPreviewComponent,
		MobilePushPreviewComponent
	],
	exports: [MessagePreviewComponent],
	providers: [SmsPreviewService, SelectViewService]
})
export class MessagePreviewModule {}
