import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { RelationsModel } from '@state-enKod/segments';

@Component({
	selector: 'en-delete-segment-with-relations',
	templateUrl: './delete-segment-with-relations.component.html',
	styleUrls: ['./delete-segment-with-relations.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteSegmentWithRelationsComponent implements OnInit {
	@Input() modal: boolean;

	@Input() segmentName: string;

	@Input() relations: RelationsModel;

	@Output() hide = new EventEmitter<boolean>();

	ngOnInit() {}
}
