<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsPage"
                 (onChange)="onChangeType($event)"
                 formControlName="type"></en-dropdown>

    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="parameterOptions"
                 formControlName="parameter"></en-dropdown>

    <ng-container [ngSwitch]="parameterValue">
        <ng-template ngSwitchCase="id">
            <div class="addition">
                <input enInput
                       enDynamicWidth="15rem"
                       type="text"
                       class="h32 text-overflow"
                       [class.active-hide]="showHide"
                       formControlName="value" />
            </div>
        </ng-template>
        <ng-template ngSwitchCase="quantity">
            <div>
                <en-action-count [form]="form"
                                 [class.active-hide]="showHide"></en-action-count>
            </div>
        </ng-template>
    </ng-container>

    <div>
        <en-action-time period="past"
                        [isHide]="isHideParent || isHide"
                        [timeForm]="actionTimeForm"
                        [optionsPeriod]="timeOptions"
                        [class.active-hide]="showHide"></en-action-time>
    </div>

    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)"></en-condition-tools>
    </div>
</form>
