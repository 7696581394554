import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';

import { TuiMapperPipeModule } from '@taiga-ui/cdk';
import { EnButtonModule } from 'ui-lib';

import { TuiScrollbarModule } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared';
import { InspectorComponent } from './inspector.component';
import {
	DistributionModule,
	FinishModule,
	MailingGroupModule,
	PauseModule,
	SendMessageModule,
	MessageStartModule,
	ApiStartModule,
	ExtraFieldStartModule,
	DataChangeMainModule,
	ScheduleStartModule,
	ApiQueryModule,
	EventStartModule,
	StartModule,
	MessageModule
} from '.';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		PolymorpheusModule,
		TuiMapperPipeModule,
		EnButtonModule,
		MailingGroupModule,
		PauseModule,
		SendMessageModule,
		FinishModule,
		DistributionModule,
		MessageStartModule,
		ApiStartModule,
		ExtraFieldStartModule,
		DataChangeMainModule,
		ScheduleStartModule,
		TuiScrollbarModule,
		TranslateModule,
		ApiQueryModule,
		EventStartModule,
		StartModule,
		MessageModule
	],
	declarations: [InspectorComponent],
	exports: [InspectorComponent]
})
export class InspectorModule {}
