import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	OnDestroy
} from '@angular/core';
import { SelectedViewMobileSms } from '../message-preview.component';
import { SmsPreviewService } from './sms-preview.service';

@Component({
	selector: 'en-sms-preview',
	template: `
		<en-peview-ios
			*ngIf="isIos"
			[placeholder]="placeholder"
			[preview]="preview"
		></en-peview-ios>
		<en-preview-android
			*ngIf="isAndorid"
			[placeholder]="placeholder"
			[preview]="preview"
		></en-preview-android>
	`,
	styleUrls: ['./sms-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmsPreviewComponent implements OnInit, OnDestroy {
	view: SelectedViewMobileSms;
	@Input() placeholder: boolean;
	@Input() preview: string;
	@Input('view') set viewSetter(value: SelectedViewMobileSms) {
		this.view = value;
		this.smsPreviewService.setActiveView(this.view);
	}

	constructor(private smsPreviewService: SmsPreviewService) {}

	get isAndorid() {
		return this.view === 'android';
	}

	get isIos() {
		return this.view === 'ios';
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.smsPreviewService.resetSettings();
	}
}
