export const stringOperatorOptions = [
	{ label: 'segment_form.options_operator_equal', value: 'equal' },
	{ label: 'segment_options.not_equal_options', value: 'notEqual' },
	{ label: 'segment_form.options_operator_contain', value: 'contains' },
	{ label: 'segment_options.not_contain_options', value: 'notContains' },
	{ label: 'segment_form.options_operator_empty', value: 'empty' },
	{ label: 'segment_options.not_empty_options', value: 'notEmpty' }
];

export const numberOperatorOptions = [
	{ label: 'segment_form.options_operator_equal', value: 'equal' },
	{ label: 'segment_options.not_equal_options', value: 'notEqual' },
	{ label: 'segment_form.options_operator_less', value: 'less' },
	{
		label: 'segment_form.options_operator_lessOrEqual',
		value: 'lessOrEqual'
	},
	{ label: 'segment_form.options_operator_more', value: 'more' },
	{
		label: 'segment_form.options_operator_moreOrEqual',
		value: 'moreOrEqual'
	},
	{ label: 'segment_form.options_operator_empty', value: 'empty' },
	{ label: 'segment_options.not_empty_options', value: 'notEmpty' }
];

export const booleanOperatorOptions = [
	{ label: 'client_fields.value_true', value: 'true' },
	{ label: 'client_fields.value_false', value: 'false' },
	{ label: 'segment_form.options_operator_empty', value: 'empty' },
	{ label: 'segment_options.not_empty_options', value: 'notEmpty' }
];

export const dateOperatorOptions = [
	{
		label: 'segment_operator_menu.absolute_title',
		isTitle: true
	},
	{ label: 'segment_operator_menu.after_item', value: 'after' },
	{ label: 'segment_operator_menu.before_item', value: 'before' },
	{ label: 'segment_options.between_date', value: 'between' },
	{ label: 'segment_form.cond_field_date_direction_today', value: 'today' },
	{ label: 'segment_form.options_operator_empty', value: 'empty' },
	{ label: 'segment_options.not_empty_options', value: 'notEmpty' },
	{ label: 'segment_form.options_operator_equal', value: 'equal' },
	{ label: 'segment_options.not_equal_options', value: 'notEqual' },
	{
		label: 'segment_operator_menu.dynamic_title',
		isTitle: true
	},
	{ label: 'segment_operator_menu.today_item', value: 'thisDay' },
	{ label: 'segment_operator_menu.mounth_item', value: 'thisMonth' },
	{ label: 'segment_operator_menu.year_item', value: 'thisYear' }
];
