import { Injectable } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { DateAccountTimezoneService } from '@enkod-core/services';
import { WizardStatus } from '../models';
import {
	sendlateValidator,
	regularRangeValidator,
	regularEveryValidator,
	regularStartTimeValidator,
	regularWeekdaysValidator,
	regularMonthValidator,
	regularFilterValidator,
	regularEveryWeekValidator,
	regularDayValidator,
	apiAgreementValidator,
	eventTypeValidator,
	eventExtraFieldValidator,
	eventExtraFieldValueValidator,
	eventMessageValidator,
	eventMailingGroupsValidator,
	eventMailingGroupsArrayValidator,
	sendlateTimeValidator,
	regularTimeSendValidator
} from './message-wizard-validators';

@Injectable()
export class StepConditionService {
	private form: UntypedFormGroup;

	loading$: BehaviorSubject<boolean>;
	firstStepChecked = false;
	status: WizardStatus = 'draft';

	constructor(
		private fb: UntypedFormBuilder,
		private timezoneService: DateAccountTimezoneService
	) {}

	get stepCondition(): UntypedFormGroup {
		return this.form as UntypedFormGroup;
	}

	get sendingTypeControl(): UntypedFormControl {
		return this.form.get('sendingType') as UntypedFormControl;
	}

	get sendingType(): string {
		return this.form?.get('sendingType')?.value;
	}

	get hiddenReceivers(): boolean {
		return this.sendingType === 'doi' || this.sendingType === 'api';
	}

	init() {
		this.form = this.initCondtionForm();
	}

	private initCondtionForm(): UntypedFormGroup {
		/** Для дизейбла активного контрола при выходе из мастера */
		if (this.form) this.disableEnabled();
		return this.fb.group({
			sendingType: ['', Validators.required],
			sendnow: [{ value: false, disabled: true }],
			sendlate: this.initSendlate(),
			regular: this.initRegular(),
			api: this.initAPI(),
			event: this.initEvent(),
			doi: this.initDOI()
		});
	}

	disableControl(controlName: string): void {
		if (controlName) this.form.controls[controlName].disable();
	}

	enableControl(controlName: string): void {
		if (controlName) this.form.controls[controlName].enable();
	}

	private disableEnabled() {
		Object.keys(this.form.controls).forEach(item => {
			if (this.form.controls[item].enabled && item !== 'sendingType')
				this.form.controls[item].disable();
		});
	}

	/** Запланированное */
	private initSendlate() {
		return this.fb.group({
			startTime: [
				{ value: '', disabled: true },
				[sendlateValidator, sendlateTimeValidator]
			]
		});
	}

	/** Регулярные */
	private initRegular() {
		return this.fb.group({
			every: [{ value: '', disabled: true }, regularEveryValidator],
			startDate: [
				{ value: '', disabled: true },
				[
					regularRangeValidator,
					regularTimeSendValidator(this.timezoneService)
				]
			],
			endDate: [
				{ value: '', disabled: true },
				[
					regularRangeValidator,
					regularTimeSendValidator(this.timezoneService)
				]
			],
			dateRange: [{ value: '', disabled: true }, regularRangeValidator],
			startTime: [
				{ value: '', disabled: true },
				regularStartTimeValidator
			],
			params: this.initRegularParams()
		});
	}

	private initRegularParams() {
		return this.fb.group({
			weekdays: [{ value: [], disabled: true }, regularWeekdaysValidator],
			month: [{ value: [], disabled: true }, regularMonthValidator],
			filter: [{ value: '', disabled: true }, regularFilterValidator],
			everyWeek: [
				{ value: [], disabled: true },
				regularEveryWeekValidator
			],
			day: [{ value: [], disabled: true }, regularDayValidator]
		});
	}

	/** АПИ */
	private initAPI() {
		return this.fb.group({
			transaction: [{ value: false, disabled: true }],
			agreement: [{ value: false, disabled: true }, apiAgreementValidator]
		});
	}

	/** По событию */
	private initEvent() {
		return this.fb.group({
			type: [{ value: '', disabled: true }, eventTypeValidator],
			params: this.initEventParams()
		});
	}

	private initDOI() {
		return this.fb.group({
			value: [{ value: 0, disabled: true }, Validators.required],
			units: [{ value: 'minute', disabled: true }, Validators.required],
			groups: [
				{ value: [], disabled: true },
				eventMailingGroupsArrayValidator
			]
		});
	}

	private initEventParams() {
		return this.fb.group({
			units: [{ value: 'minute', disabled: true }, Validators.required],
			value: [{ value: 0, disabled: true }, Validators.required],
			group: [
				{ value: null, disabled: true },
				eventMailingGroupsValidator
			],
			linkSearchType: [
				{ value: 'any', disabled: true },
				Validators.required
			],
			link: [{ value: '', disabled: true }, Validators.required],
			isOnce: [{ value: false, disabled: true }, Validators.required],
			messageId: [{ value: null, disabled: true }, eventMessageValidator],
			extraFieldId: [
				{ value: null, disabled: true },
				eventExtraFieldValidator
			],
			extraFieldValue: [
				{ value: null, disabled: true },
				eventExtraFieldValueValidator
			],
			condition: [{ value: 'after', disabled: true }, Validators.required]
		});
	}
}
