<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <en-dropdown class="input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="optionsCategoryData"
                 formControlName="type"
                 (onChange)="onChangeType($event)">
    </en-dropdown>

    <en-dropdown class="condition__field input__small"
                 styleClass="en-dropdown-solid"
                 [class.active-hide]="showHide"
                 [options]="dateFields$ | async"
                 [filter]="true"
                 [autofocusFilter]="false"
                 filterBy="label,value"
                 [placeholder]="'common.select'"
                 formControlName="field_id"
                 (onChange)="onChangeField($event)">
        <ng-template let-field
                     pTemplate="item">
            <div style="display: flex; justify-content: space-between; align-items: center">
                <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                    <span class="id-badge"
                          style="margin-right: 0.5rem;">
                        id {{field.value}}
                    </span>
                    {{field.label}}
                </div>
                <span [ngClass]="field.icon | extraFieldsIconPipe"></span>
            </div>
        </ng-template>
    </en-dropdown>

    <div class="addition">
        <en-dropdown class="input__small"
                     styleClass="en-dropdown-solid"
                     [class.active-hide]="showHide"
                     [class.show-right-border]="void_sex"
                     [options]="birthdayDateOptions"
                     formControlName="operator">
        </en-dropdown>
        <en-dropdown *ngIf="showMonthOperator"
                     class="input__small"
                     styleClass="en-dropdown-solid"
                     [class.active-hide]="showHide"
                     [options]="monthOperatorOptions"
                     formControlName="month_operator">
        </en-dropdown>

        <input *ngIf="showHappenOperator"
               class="h32 text-overflow"
               [class.active-hide]="showHide"
               type="number"
               min="1"
               max="1000"
               step="1"
               enInput
               enDynamicWidth
               pKeyFilter="pint"
               formControlName="value">

        <en-dropdown *ngIf="showHappenOperator"
                     [class.active-hide]="showHide"
                     [options]="unitOptions"
                     styleClass="en-dropdown-solid"
                     formControlName="units">
            <ng-template let-value
                         pTemplate="selectedItem">
                <div style="display: flex">
                    <span class="text-overflow"
                          [class.active-hide]="showHide">
                        {{ unitLabel | declinationWord:valueControl.value | translate }}
                    </span>
                </div>
            </ng-template>
        </en-dropdown>

        <span *ngIf="showHappenedOperator"
              class="center-span-text"
              [class.active-hide]="showHide">{{'segment_form.cond_field_date_ago' | translate}}</span>

        <en-multiSelect *ngIf="showMonthsMultiselect"
                        class="no-left-border"
                        [class.active-hide]="showHide"
                        [options]="monthOptions"
                        [isInvalid]="markAsInvalid"
                        [showHeader]="false"
                        [placeholder]="'common.select' | translate"
                        formControlName="value">
            <ng-template let-value
                         pTemplate="selectedItems">
                <div *ngIf="!value?.length">{{'common.select' | translate}}</div>
                <div class="wrapper__selected-items">
                    <div class="selected-items">
                        <div *ngIf="value && value.length < 4"
                             style="display: flex;">
                            <span class="text-color-badge text-overflow selected-month"
                                  *ngFor="let option of value; index as idx">
                                {{ monthLabel[option] | translate }}
                                <span [attr.data-clickable]="true"
                                      class="en en-cancel-fill button-deleted"
                                      (click)="deselectMonth(idx)"></span>
                            </span>
                        </div>
                        <span *ngIf="value && value.length >= 4">
                            {{ value.length}} {{'message_wizard.month_count' | translate }}
                        </span>
                    </div>
                    <span *ngIf="value?.length"
                          class="en en-cancel-fill"
                          [attr.data-clickable]="true"
                          (click)="deselectAllMonths()"></span>
                </div>
            </ng-template>
        </en-multiSelect>

        <ng-containter *ngIf="showDaysOperator">
            <div class="addition">
                <span class="center-span-text no-borders"
                      [class.active-hide]="showHide">{{'segment_form.options_operator_from' | translate}}</span>
                <input class="h32 add-time"
                       [class.active-hide]="showHide"
                       type="number"
                       min="1"
                       max="31"
                       step="1"
                       enInput
                       enDynamicWidth
                       pKeyFilter="pint"
                       formControlName="start">
                <span class="center-span-text no-borders"
                      [class.active-hide]="showHide">{{'segment_form.options_operator_to' | translate}}</span>
                <input class="h32 add-time no-left-radius"
                       [class.active-hide]="showHide"
                       type="number"
                       min="1"
                       max="31"
                       step="1"
                       enInput
                       enDynamicWidth
                       pKeyFilter="pint"
                       formControlName="end">
            </div>
        </ng-containter>
    </div>

    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            [isHide]="isHide"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"
                            (hideCondition)="hideCondition(isHide)">
        </en-condition-tools>
    </div>
</form>