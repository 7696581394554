import { shapes } from '@clientio/rappid';
import { ShapeTypesEnum } from '../../config/enum.shapes';

export const enKodLink = shapes.standard.Link.define(ShapeTypesEnum.LINK, {
	connector: {
		name: 'rounded',
		args: {
			raw: true,
			radius: 5
		}
	},
	attrs: {
		line: {
			stroke: '#535766',
			strokeWidth: 1,
			targetMarker: {
				type: 'path',
				d: 'M7.1 3.8.6.3a.2.2 0 0 0-.4.3L1.5 4 .2 7.4c0 .2.2.4.4.3l6.5-3c.4-.2.4-.7 0-.9Z',
				fill: '#535766',
				stroke: 'none',
				transform: 'matrix(1.3333 0 0 1.3333 -10 -5.5)',
				class: 'target-marker'
			},
			wrapper: {
				connection: true,
				strokeWidth: 10,
				strokeLinejoin: 'round'
			}
		}
	}
});
