import {
	Component,
	ChangeDetectionStrategy,
	Input,
	QueryList,
	ContentChildren,
	AfterContentInit,
	TemplateRef,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';

import { SelectItem } from 'primeng/api';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { EnTempalateDirective } from '@shared';
import { commonAnimation } from '@enkod-core/animations';
import { AuthQuery } from '@enkod-core/authentication/_state';
import { Access } from '@enkod-core/authentication/models';
import { MessageDetailService } from '@enSend/message/message-details/_states/_state-message';
import { MessageType } from '@enSend/message/kit';
import { SendingType } from '@enSend/message/_states/_state-message';

import { StepConditionService } from '@enSend/message/message-wizard/message-wizard-common';
import { EditorType } from '@enSend/message/message-wizard/message-wizard-common/step-content/2-step-editor/email-editor/chamaileon-plugin-dialog/core/chamaileon-plugin.model';
import { IPushPreview } from '../../../message-wizard/message-wizard-common/step-content/2-step-editor/push-editor/push-editor.model';
import {
	IEmailPreview,
	PreviewType
} from '../../../message-wizard/message-wizard-common/step-content/2-step-editor/email-editor/email-editor.component';
import { MobPushPreview } from '../../../message-wizard/message-wizard-common/step-content/2-step-editor/mobile-push-editor/mobile-push-editor.model';
import { SmsPreview } from '../../../message-wizard/message-wizard-common/step-content/2-step-editor/sms-editor/sms-editor.model';
import { SelectViewService } from '../../../message-wizard/message-wizard-common/services/select-view.service';
import { MailPreviewComponent } from './mail-preview/mail-preview.component';

export type SelectedViewMail = 'html' | 'push' | 'amp';
export type SelectedViewPush = 'desktop' | 'browser' | 'android' | 'ios';
export type SelectedViewMobilePush = 'android' | 'ios';
export type SelectedViewMobileSms = 'android' | 'ios';

type PreviewChannelType =
	| IPushPreview
	| IEmailPreview
	| MobPushPreview
	| SmsPreview;

@Component({
	selector: 'en-message-preview',
	templateUrl: './message-preview.component.html',
	styleUrls: ['./message-preview.component.scss'],
	providers: [Window],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [commonAnimation.opacity]
})
export class MessagePreviewComponent
	implements AfterContentInit, AfterViewInit
{
	isOnHover = false;
	id: number;
	selectedView: string;
	status: string;
	fullscreenVisible = false;
	mouseEnter$: Observable<any>;
	mouseLeave$: Observable<any>;
	@ViewChild('content') content: ElementRef;
	constructor(
		private selectViewService: SelectViewService,
		private detailService: MessageDetailService,
		private stepConditionService: StepConditionService,
		private cd: ChangeDetectorRef,
		private router: Router,
		private authQuery: AuthQuery
	) {
		this.id = this.detailService.activeId;
		if (this.id) {
			this.previewType = 'edit';
			this.getDetail();
		}
	}

	private _clicksMapAvailable = false;
	private readonly editingAllowedStatuses: string[] = [
		'inactive',
		'draft',
		'waiting',
		'active'
	];

	src = '';

	@ViewChild(MailPreviewComponent) private mailPreview: MailPreviewComponent;

	@Input() preview: PreviewChannelType;
	@Input() messageType: MessageType;
	@Input() sendingType: SendingType;
	@Input() bordersNone = false;
	@Input() placeholder?: boolean;
	@Input() previewType: PreviewType;
	@Input() isMaker = false;
	@Input() editorType: EditorType;
	@Input() set clicksMapAvailable(clicksMapAvailable: boolean) {
		this._clicksMapAvailable = clicksMapAvailable;
	}

	@Input() scrollable: boolean = true;
	@Input() invisibleScroll: boolean = true;

	// only for whatsapp
	@Input() isTemplateWizard = true;

	@Output() openEditor = new EventEmitter();
	@Output() resetCurrentHtml = new EventEmitter();
	@Output() openTemplateDialog = new EventEmitter();
	@Output() openTextVersionDialog = new EventEmitter();
	@Output() openAmpDialog = new EventEmitter();

	@ContentChildren(EnTempalateDirective)
	templates: QueryList<EnTempalateDirective>;

	createButtonTemplate: TemplateRef<any>;

	selectedViewPush: SelectedViewPush = 'desktop';
	selectedViewMobilePush: SelectedViewMobilePush = 'android';
	selectedViewMobileSms: SelectedViewMobileSms = 'android';
	selectedViewMail$ = this.selectViewService.selectedViewMail$.pipe(
		tap(value => {
			this.selectedView = value;
		})
	);

	optionsViewMobile: Readonly<SelectItem[]> = [
		{
			icon: 'en en-android',
			value: 'android'
		},
		{
			icon: 'en en-apple',
			value: 'ios'
		}
	];

	optionsViewPush: Readonly<SelectItem[]> = [
		{
			icon: 'en en-desktop',
			value: 'desktop'
		},
		// {
		// 	icon: 'en en-hashtag',
		// 	value: 'browser'
		// },
		// {
		// 	icon: 'en en-hashtag',
		// 	value: 'android'
		// },
		{
			icon: 'en en-mobile',
			value: 'ios'
		}
	];

	optionsViewMobilePush: Readonly<SelectItem[]> = [...this.optionsViewMobile];
	optionsViewMobileSms: Readonly<SelectItem[]> = [...this.optionsViewMobile];

	@Input() viewTemplate: TemplateRef<any>;
	@Input() toolsTemplate: TemplateRef<any>;

	get messageTypeMail() {
		return this.messageType === 'mail';
	}

	get messageTypePush() {
		return this.messageType === 'push';
	}

	get messageTypeMobPush() {
		return this.messageType === 'mobPush';
	}

	get isEditPreviewType() {
		return this.previewType === 'edit';
	}

	get isCreatePreviewType() {
		return this.previewType === 'create';
	}

	get isPreviewType() {
		return this.previewType === 'preview';
	}

	// Выбор редактора mail
	get htmlEditorAvailable() {
		return this.isEditPreviewType && this.editorType === 'HTML';
	}

	get chamaileonEditorAvailable() {
		return this.isEditPreviewType && this.editorType === 'chamaileon';
	}

	get stripoEditorAvailable() {
		return this.isEditPreviewType && this.editorType === 'Stripo';
	}

	get textVersionAvailable() {
		return this.htmlEditorAvailable || this.stripoEditorAvailable;
	}

	get sendingTypeValue() {
		return this.stepConditionService.sendingType;
	}

	get ampVersionAvailable() {
		return (
			(this.htmlEditorAvailable || this.stripoEditorAvailable) &&
			(this.sendingTypeValue === 'sendnow' ||
				this.sendingTypeValue === 'sendlate') &&
			this.sendingType !== 'scenario'
		);
	}

	get editButtonAvailable() {
		const canEditByStatus =
			this.editingAllowedStatuses.includes(this.status) &&
			!this.isEditPreviewType &&
			!this.isCreatePreviewType;

		switch (this.messageType) {
			case Access.SMS:
				return this.authQuery.isAccessSms && canEditByStatus;
			case Access.MAIL:
				return this.authQuery.isAccessMail && canEditByStatus;
			case 'push':
				return this.authQuery.isAccessWebPush && canEditByStatus;
			case 'whatsapp':
				return false;
			default:
				return canEditByStatus;
		}
	}

	get messageTypeSms() {
		return this.messageType === 'sms';
	}

	get clicksMapAvailable() {
		return (
			this._clicksMapAvailable &&
			this.messageType === 'mail' &&
			this.previewType === 'preview'
		);
	}

	get trashButtonAvailable() {
		return this.isEditPreviewType && this.messageTypeMail;
	}

	get fullScreenButtonAvailable() {
		return (
			!this.isCreatePreviewType &&
			this.messageTypeMail &&
			this.selectedView !== 'codeHtml'
		);
	}

	get saveAsTemplateButtonAvailable() {
		return this.isEditPreviewType && this.messageTypeMail;
	}

	get visibleHoverButtons() {
		return this.previewType && !this.isMaker;
	}

	get scenarioOrMessage() {
		return this.sendingType === 'scenario' ? this.sendingType : 'message';
	}

	get optionsViewMail() {
		if (this.previewType === 'preview' && !this.isMaker)
			return this.selectViewService.optionsViewMail;
		return this.selectViewService.optionsViewMail.filter(
			item => item.value !== 'codeHtml'
		);
	}

	getDetail() {
		this.detailService
			.getDetailById(this.id)
			.pipe(
				tap(data => {
					this.status = data.status;
				})
			)
			.subscribe();
	}

	redirectEditMessage(type: string) {
		if (this.isPreviewType) {
			this.router.navigateByUrl(
				`/ensend/messages/edit/${this.scenarioOrMessage}/${this.messageType}/${this.id}`
			);
		} else {
			this.openEditor.emit(type);
		}
	}

	ngAfterViewInit() {
		this.mouseEnter$ = fromEvent(
			this.content.nativeElement,
			'mouseenter'
		).pipe(debounceTime(100));
		this.mouseLeave$ = fromEvent(
			this.content.nativeElement,
			'mouseleave'
		).pipe(debounceTime(100));

		this.mouseEnter$.subscribe(() => {
			this.isOnHover = true;
			this.cd.markForCheck();
		});

		this.mouseLeave$.subscribe(() => {
			this.isOnHover = false;
			this.cd.markForCheck();
		});
	}

	ngAfterContentInit() {
		this.templates.forEach(item => {
			switch (item.getType()) {
				case 'view':
					this.viewTemplate = item.template;
					break;

				case 'tools':
					this.toolsTemplate = item.template;
					break;

				case 'createButton':
					this.createButtonTemplate = item.template;
					break;

				default:
					break;
			}
		});
	}
}
