import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PreviewControlsService } from 'app/modules/enPop/_shared/services';
import { PopupPreview } from '../../../misc/abstract-preview';

@Component({
	selector: 'en-previews-container',
	templateUrl: './previews-container.component.html',
	styleUrls: ['./previews-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewsContainerComponent extends PopupPreview {
	constructor(private controlsService: PreviewControlsService) {
		super();
	}

	get devicePreview() {
		return this.controlsService.devicePreview;
	}

	get previewMode() {
		return this.devicePreview.value;
	}
}
