<section class="navigator-section">
    <div class="left-buttons">
        <en-button class="en-mr-2"
                   styleClass="en-button-secondary h36"
                   icon="en en-back"
                   (onClick)="onExit.emit()"></en-button>
        <button *ngIf="!customPrevButton; else customPrev"
                enButton
                previousStep
                [disabled]="disablePrev"
                [label]="prevButtonLabel | translate"
                class="en-button-secondary h36"
                icon="en en-arrow-left"
                iconPos="left"></button>
        <ng-template #customPrev>
            <ng-container *ngTemplateOutlet="customPrevButton"></ng-container>
        </ng-template>
    </div>
    <div class="center-buttons"
         [ngStyle]="{'grid-template-columns': 'repeat('+ steps.length * 2 + ', 1fr)'}">
        <div class="center-buttons-wrapper">
            <en-nav-button *ngFor="let step of steps; index as i"
                           class="center-button"
                           [ngStyle]="{'width': 100 / steps.length + '%'}"
                           [completed]="step.completed"
                           [selected]="selectedIndex === i"
                           [disableButton]="step.disabled"
                           [label]="step.stepLabel"
                           (navButtonClick)="!step.disabled && selectedIndex = i"></en-nav-button>
        </div>
        <div [ngStyle]="{'grid-column': 2 +  '/' + steps.length * 2}"
             class="progress-bar">
            <div [ngStyle]="{'width': calculateWidth + '%'}"
                 class="progress-bar-fill"></div>
        </div>
    </div>
    <div class="right-buttons">
        <en-button *ngIf="saveAsDraft"
                   [disabled]="loading"
                   [tippy]="'stepper_navigator.save_as_draft_tooltip' | translate"
                   placement="bottom"
                   class="en-mr-2"
                   styleClass="en-button-secondary h36"
                   icon="en en-save-o"
                   (onClick)="onSaveAsDraft.emit()"></en-button>

        <ng-container *ngIf="!customNextButtons; else customNext">
            <button *ngIf="!lastStep; else submitButton"
                    [ngClass]="{'en-loading-button': loading }"
                    nextStep
                    enButton
                    [enLoading]="loadingNext"
                    [label]="(nextButtonLabel | translate) || ' '"
                    class="h36 next-step"
                    icon="en en-arrow-right"
                    iconPos="right"></button>
        </ng-container>

        <ng-template #submitButton>
            <button enButton
                    class="h36 submit-button"
                    [ngClass]="{'en-loading-button': loading }"
                    [label]="submitButtonLabel | translate"
                    [disabled]="loading"
                    (click)="onSubmit.emit()"></button>
        </ng-template>

        <ng-template #customNext>
            <ng-container *ngTemplateOutlet="customNextButtons"></ng-container>
        </ng-template>
    </div>
</section>

<ng-container *ngIf="stateChange$ && stateChange$ | async"></ng-container>