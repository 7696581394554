import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'testSendingStatusVariant'
})
export class TestSendingStatusVariant implements PipeTransform {
	transform(value: string) {
		switch (value) {
			case 'sending':
				return 'loading';
			case 'delivered':
				return 'success';
			case 'error':
				return 'error';
			default:
				return '';
		}
	}
}
