import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { ReactiveFormsModule } from '@angular/forms';

import {
	EnDialogModule,
	SearchModule,
	EnButtonModule,
	SearchService
} from 'ui-lib';

import { TuiLetModule } from '@taiga-ui/cdk';

import { TuiScrollbarModule } from '@enkod-core/components';

import {
	MessageTableModule,
	WhatsappDcPreviewModule
} from '@enSend/_shared/components';

import { SendMessageComponent } from './send-message.component';
import { SendMessageService } from './send-message.service';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { ChosenItemInfoModule } from '../../components/chosen-item-info/chosen-item-info.module';
import { WhatsappInfoComponent } from './whatsapp-info/whatsapp-info.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnDialogModule,
		MessageTableModule,
		EnButtonModule,
		SearchModule,
		TuiLetModule,
		ChosenItemInfoModule,
		WhatsappDcPreviewModule,
		TuiScrollbarModule
	],
	declarations: [SendMessageComponent, WhatsappInfoComponent],
	providers: [
		SendMessageService,
		SearchService,
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: SendMessageService,
			multi: true
		}
	]
})
export class SendMessageModule {}
