import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { SendMessageComponent } from './send-message.component';

@Injectable()
export class SendMessageService implements InspectorItemPlugin {
	private readonly sendMessageContent = new PolymorpheusComponent(
		SendMessageComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'sendMessage',
				name: 'sendMessage',
				content: this.sendMessageContent
			}
		]);
	}
}
