export interface UrlParams {
	utm: Utm;
	params: Params;
}

export interface Utm {
	utm_campaign?: string;
	utm_content?: string;
	utm_source?: string;
	utm_medium?: string;
}

export interface Params {
	[key: string]: string;
}

export interface ChamaileonLetter {
	jsonBody: {};
}
