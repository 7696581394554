import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { tap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SendTestData } from '@enSend/message/message-wizard/kit';

import { TUI_VALIDATION_ERRORS } from 'ui-lib';

import { SendTestService } from '../send-test.service';
import { TestMailsService } from './test-mails.service';

@UntilDestroy()
@Component({
	selector: 'en-test-mails',
	templateUrl: './test-mails.component.html',
	styleUrls: ['./test-mails.component.scss'],
	providers: [
		{
			provide: TUI_VALIDATION_ERRORS,
			useValue: {
				notValid: 'message_wizard_edit_step.test_email_not_valid',
				maxLength: 'message_wizard_edit_step.test_email_max_length',
				exist: 'message_wizard_edit_step.test_email_exist'
			}
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestMailsComponent implements OnInit {
	filteredEmailsMultiple: string[];
	onInitEmails = [];

	constructor(
		private sendTestService: SendTestService,
		private testMailsService: TestMailsService
	) {}

	get emailsInput(): UntypedFormControl {
		return this.sendTestService.emailsInput as UntypedFormControl;
	}

	get mailsList() {
		return this.sendTestService.mailsList;
	}

	get isVisibleInfo() {
		return !this.emailsInput.errors;
	}

	ngOnInit() {
		this.testMailsService
			.getEmails()
			.pipe(
				untilDestroyed(this),
				tap((emails: any) => {
					if (emails) this.onInitEmails = emails;
				})
			)
			.subscribe();
	}

	addMailToList(event: { key: string; target: { value: string } }): void {
		const inputValue = event.target.value.trim().toLowerCase();
		const data: SendTestData = {
			channel: 'email',
			value: inputValue
		};
		this.emailsInput.markAsDirty();
		if (this.fullValidationPassed(inputValue)) {
			if (this.emailsInput.value.indexOf(inputValue) !== -1) {
				this.emailsInput.setErrors({ exist: true });
				return;
			}
			const selectedMails = [...this.emailsInput.value] || [];
			selectedMails.push(inputValue);

			this.sendTestService.mailsList.add(inputValue as never);
			this.emailsInput.patchValue(selectedMails);

			this.testMailsService
				.postMails(data)
				.pipe(untilDestroyed(this))
				.subscribe();

			// eslint-disable-next-line no-param-reassign
			event.target.value = '';
		}
	}

	fullValidationPassed(inputValue: string): boolean {
		switch (true) {
			case this.sendTestService.validateEmail(inputValue):
				this.emailsInput.setErrors({ notValid: true });
				return false;
			case this.sendTestService.mailsList.size >= 20:
				this.emailsInput.setErrors({ maxLength: true });
				return false;
			default:
				return true;
		}
	}

	filterEmailMultiple(event: any) {
		const { query } = event;
		this.filteredEmailsMultiple = this.filterEmail(
			query,
			this.onInitEmails
		);
	}

	private filterEmail(query: any, emails: string[] | null): string[] {
		const filtered: string[] = [];
		if (emails) {
			for (let i = 0; i < emails.length; i++) {
				const country = emails[i];
				if (country.toLowerCase().indexOf(query.toLowerCase()) === 0) {
					filtered.unshift(country);
				}
			}
		}
		return filtered;
	}
}
