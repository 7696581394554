import { Injector } from '@angular/core';
import { dia } from '@clientio/rappid';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';

export type InspectorItemLike = string;

export type InspectorItem = Readonly<{
	id: string;
	name: string;
	content: PolymorpheusContent;
	context?: InspectorItemContext;
}>;

export type InspectorItemContext = {
	scenarioId: number;
	injector: Injector;
	cell: dia.Cell;
};

export interface InspectorItemPlugin {
	getItems(): Observable<InspectorItem[]>;
}
