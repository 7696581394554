<form [formGroup]="form"
      class="condition"
      (mouseenter)="isHover = true"
      (mouseleave)="isHover = false">
    <span class="local-storage-label center-span-text first-block">Local storage</span>
    <div class="addition">
        <span class="field-label center-span-text first-block">{{ 'segment_form.field' | translate }}</span>
        <input class="h32 text-overflow"
               type="text"
               enInput
               enDynamicWidth="15rem"
               formControlName="field" />
    </div>
    <en-dropdown class="input__medium"
                 styleClass="en-dropdown-solid"
                 [options]="localStorageOptions"
                 formControlName="operator"
                 (onChange)="onChangeOperator($event.value)"></en-dropdown>
    <input *ngIf="operatorWithValue"
           class="h32 text-overflow"
           type="text"
           enInput
           enDynamicWidth="15rem"
           formControlName="value" />
    <div>
        <en-condition-tools *ngIf="isHover"
                            [index]="index"
                            (removeCondition)="removeCondition.emit($event)"
                            (copyCondition)="copyCondition.emit($event)"></en-condition-tools>
    </div>
</form>