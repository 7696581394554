import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	EventEmitter,
	Inject,
	Input,
	Output
} from '@angular/core';
import { Segment } from '@state-enKod/segments';
import { SEGMENT_PAGINATION_PROVIDERS } from 'app/modules/enKod/segments/segments-list/pagination-provider';
import { ISegments } from '@enSend/message/message-wizard/message-wizard-common/models';
import { PaginationService } from 'ui-lib';
import { PaginatorPlugin } from '@datorama/akita';
import { PAGINATOR_PLUGIN_TOKEN } from '@enkod-core/tokens';
import { Subject } from 'rxjs';
import { UrlSaverService } from '@enkod-core/services';

@Component({
	selector: 'en-choose-segments-dialog',
	templateUrl: './choose-segments-dialog.component.html',
	styleUrls: ['./choose-segments-dialog.component.scss'],
	providers: [SEGMENT_PAGINATION_PROVIDERS],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseSegmentsDialogComponent implements OnInit {
	resetValue$ = new Subject();

	constructor(
		@Inject(PAGINATOR_PLUGIN_TOKEN)
		public readonly paginatorRef: PaginatorPlugin<Segment>,
		public paginationService: PaginationService,
		private urlService: UrlSaverService
	) {}

	@Input() dialogVisible: boolean;
	@Input() isHeaderCheckboxDisabled = false;
	@Input() selectedSegments: ISegments[];

	@Output() onHide = new EventEmitter();
	@Output() onConfirm = new EventEmitter();

	ngOnInit(): void {
		this.segmentsWithoutSiteBehavior();
	}

	/** Передаем параметр withoutSiteBehavior в запрос,
	 *  чтобы бэк отфильтровал и убрал сегменты по поведению пользователя */
	private segmentsWithoutSiteBehavior() {
		this.paginationService.setFilterValue({ type: 'enSend' });
	}

	selectedSegmentChange(segments: Segment[]) {
		this.selectedSegments = segments.map(segment => ({
			id: segment.id,
			name: segment.name
		}));
	}

	onHideDialog() {
		this.onHide.emit();
		this.urlService.setParamsToUrl({});
	}

	onConfirmDialog(items: ISegments[]) {
		this.onConfirm.emit(items);
		this.urlService.setParamsToUrl({});
	}
}
