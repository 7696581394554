import { AbstractControl, Validators } from '@angular/forms';
import { TuiValidationError } from 'app/ui-lib/error/error.component';

export function segmentValidator(field: AbstractControl): Validators | null {
	return field.value.length !== 0
		? null
		: {
				error: new TuiValidationError(
					'scenario_block_schedule_start.segment_validate_field'
				)
		  };
}
