import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';

import { DisableControlDirectiveModule } from '@enkod-core/directives';
import { SharedModule } from '@shared';

import {
	EnCalendarModule,
	EnDropdownCalendarNewModule,
	EnDropdownModule,
	EnInputModule,
	EnMessagesModule
} from 'ui-lib';

import { ExtraFieldStartComponent } from './data-change-start.component';
import { INSPECTOR_ITEM_PLUGIN } from '../../inspector-item-plugin.token';
import { ExtraFieldStartService } from './data-change-start.service';
import { DuplicateHandlingModule } from '../../shared/components/duplicate-handling/duplicate-handling.module';
import { ExtrafieldSettingsComponent } from './extrafield-settings/extrafield-settings/extrafield-settings.component';
import { EmailExtrafieldComponent } from './email-extrafield/email-extrafield.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		EnDropdownModule,
		EnInputModule,
		IMaskModule,
		EnMessagesModule,
		EnCalendarModule,
		EnDropdownCalendarNewModule,
		DisableControlDirectiveModule,
		DuplicateHandlingModule
	],
	declarations: [
		ExtraFieldStartComponent,
		ExtrafieldSettingsComponent,
		EmailExtrafieldComponent
	],
	exports: [ExtraFieldStartComponent],
	providers: [
		{
			provide: INSPECTOR_ITEM_PLUGIN,
			useClass: ExtraFieldStartService,
			multi: true
		}
	]
})
export class ExtraFieldStartModule {}
