<button *ngIf="splitsLabels.length < 2; else switcher"
        enButton
        type="button"
        class="en-button-lowercase h32"
        icon="en en-plus-circle-o"
        iconColor="blue-button-icon"
        iconPos="left"
        [label]="'enpop_shared.split_handler_label' | translate"
        (click)="createSplit.emit()"></button>

<ng-template #switcher>
    <div class="split-menu"
         [matMenuTriggerFor]="menu">
        {{'enpop_shared.version_label' | translate: { label: splitsLabels[index] } }}
        <span *ngIf="winnerId && content[index].id === winnerId"
              class="split-menu-winner">({{ 'enpop_shared.version_winner' | translate }})</span>
        <i class="en en-sort-down"></i>
    </div>
</ng-template>

<mat-menu #menu="matMenu"
          class="en-menu split-handler">
    <div *ngIf="content.length < 3 && !readonly"
         mat-menu-item
         class="menu-item menu-create-split"
         (click)="createSplit.emit()">
        {{ 'enpop_shared.create_version' | translate }}
    </div>
    <ng-container *ngFor="let item of content; index as i; last as isLast">
        <button class="menu-item"
                [class.highlight]="i === index"
                mat-menu-item
                (click)="switchTo.emit(i)">
            {{'enpop_shared.version_label' | translate: { label: item.splitTestLabel } }}
            <i *ngIf="deletable && !readonly"
               class="en en-cancel-fill"
               (click)="remove($event, i)"></i>
            <span *ngIf="winnerId && item.id === winnerId"
                  class="item-winner">{{ 'enpop_shared.version_winner' | translate }}</span>
        </button>
    </ng-container>
</mat-menu>