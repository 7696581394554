<div class="side-bar">
    <tui-scrollbar>
        <div #stencil
             class="stencil-container"></div>
    </tui-scrollbar>
</div>

<div class="main-container">
    <div class="paper-container">
        <div #paper
             class="paper">
        </div>
        <en-scenario-top-bar *ngIf="canvaMode === 'scenario';else chatbotTopBar"
                             class="top-bar"></en-scenario-top-bar>
        <ng-template #chatbotTopBar>
            <en-chatbot-top-bar class="top-bar"></en-chatbot-top-bar>
        </ng-template>
        <en-bottom-bar class="bottom-bar"></en-bottom-bar>
    </div>
</div>

<en-canva-inspector class="inspector-container"></en-canva-inspector>
<en-sidebar-hotkeys class="inspector-container"></en-sidebar-hotkeys>