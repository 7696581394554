import { TuiDay } from '@taiga-ui/cdk';
import IMask from 'imask';

import { createAutoCorrectedDateRangePipe } from '../pipes/calendar-date.pipe';
import { createAutoCorrectedDateTimeRangePipe } from '../pipes/calendar-date-time.pipe';

const MM = {
	mask: IMask.MaskedRange,
	from: 1,
	to: 12,
	autofix: true
};

const DD = {
	mask: IMask.MaskedRange,
	from: 1,
	to: 31,
	autofix: true
};

const HH = {
	mask: IMask.MaskedRange,
	from: 0,
	to: 23,
	autofix: true
};

const mm = {
	mask: IMask.MaskedRange,
	from: 0,
	to: 59,
	autofix: true
};

function createYearRange(min: number, max: number): IMask.AnyMaskedOptions {
	return {
		mask: IMask.MaskedRange,
		from: min,
		to: max,
		autofix: true
	};
}

export function createImaskDate(
	min: TuiDay,
	max: TuiDay
): IMask.MaskedPattern<string> {
	return IMask.createMask({
		mask: 'DD.`MM.`YYYY - `DD.`MM.`YYYY',

		blocks: {
			YYYY: createYearRange(min.year, max.year),
			DD,
			MM
		},

		overwrite: true,
		placeholderChar: '0',

		commit: (value: string, masked: IMask.Masked<string>) => {
			createAutoCorrectedDateRangePipe(value, masked);
		}
	});
}

export function createImaskDateTime(
	min: TuiDay,
	max: TuiDay
): IMask.MaskedPattern<string> {
	return IMask.createMask({
		mask: 'DD.`MM.`YYYY, `HH:`mm - `DD.`MM.`YYYY, `HH:`mm',

		blocks: {
			YYYY: createYearRange(min.year, max.year),
			DD,
			MM,
			HH,
			mm
		},

		overwrite: true,
		placeholderChar: '0',

		commit: (value: string, masked: IMask.Masked<string>) => {
			createAutoCorrectedDateTimeRangePipe(value, masked);
		}
	});
}
