/* eslint-disable import/no-cycle */
import { dia } from '@clientio/rappid';
import { ScenarioShapesEnum } from '@enSend/_shared/components/scenario-canva/rappid/config/enum.shapes';
import { startBlock, startBlockView } from './start.shapes';

export const messageStartBlock = startBlock.define(
	ScenarioShapesEnum.MESSAGE_START_BLOCK,
	{},
	{
		initialize(...args: any) {
			startBlock.prototype.initialize.apply(this, args);
		},

		onChangeOptions() {
			startBlock.prototype.onChangeOptions.call(this);
		}
	}
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const messageStartBlockView: dia.ElementView =
	Object.assign(startBlockView);
