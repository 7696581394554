import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthQuery, AuthService } from '@enkod-core/authentication/_state';
import { Centrifuge, Subscription } from 'centrifuge';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from '@enkod-core/utils';
import {
	CentrifugeConfig,
	ChannelLinstener,
	IWebsocketService
} from './websocket.model';

// Для работы с сервисом достаточно вызвать метод createConnection()
// После чего управление будет доступно через массив channelEventArray
@UntilDestroy()
@Injectable()
export class WebsocketService implements IWebsocketService, OnDestroy {
	private config: CentrifugeConfig;
	private currentSubscriptions: Subscription[];
	private centrifuge: Centrifuge;

	private _connected$ = new Subject();
	private _connecting$ = new Subject();
	private _disconnected$ = new Subject<{ code: number }>();

	private _socketSender$ = new BehaviorSubject<any>(null);
	private _socketListener$ = new BehaviorSubject<any>(null);

	private _channelEventArray$ = new BehaviorSubject<ChannelLinstener[]>([]);
	private channelEventArray: ChannelLinstener[] = [];
	private _status = new BehaviorSubject<string>('disconnected');

	constructor(
		private authService: AuthService,
		private authQuery: AuthQuery,
		@Inject(WINDOW) readonly window: Window
	) {}

	get connected$(): Subject<unknown> {
		return this._connected$;
	}

	get connecting$(): Subject<unknown> {
		return this._connecting$;
	}

	get disconnected$(): Subject<{ code: number }> {
		return this._disconnected$;
	}

	get socketSender$(): BehaviorSubject<any> {
		return this._socketSender$;
	}

	get socketListener$(): BehaviorSubject<any> {
		return this._socketListener$;
	}

	get channelEventArray$(): BehaviorSubject<ChannelLinstener[]> {
		return this._channelEventArray$;
	}

	get status(): BehaviorSubject<string> {
		return this._status;
	}

	createConnection(url: string, channels: string[]) {
		// Сбрасываем сторадж
		// this.resetLocalStorage();

		this.config = {
			token: this.authQuery.jwtToken,
			getToken: () => this.getToken()
		};
		// Создаем объект центрифуги с конфигом
		this.centrifuge = new Centrifuge(url, this.config);
		// Создаем подписки для каждого канала
		this.currentSubscriptions = channels.map(channel => {
			const sub = this.centrifuge.newSubscription(channel);
			sub.subscribe();
			return sub;
		});
		this.generateListeners();
		this.generateSenders();

		this.centrifuge.connect();
	}

	getToken(): Promise<string> {
		return this.authService
			.refreshToken()
			.pipe(
				untilDestroyed(this),
				switchMap(auth => {
					return of(auth.accessToken);
				})
			)
			.toPromise();
	}

	private generateListeners(): void {
		// Стандартные слушатели
		this.centrifuge.on('connected', ctx => {
			this._status.next('connected');
			this._connected$.next(ctx);
		});
		this.centrifuge.on('connecting', ctx => {
			this._status.next('connecting');
			this._connecting$.next(ctx);
		});
		this.centrifuge.on('disconnected', ctx => {
			this._status.next('disconnected');
			this._disconnected$.next(ctx);
		});
		this.centrifuge.on('message', ctx => {
			this._socketListener$.next(ctx);
		});
		// Слушатели канала
		this.currentSubscriptions.forEach((sub, index) => {
			this.channelEventArray.push({
				channel: sub.channel,
				listener: new BehaviorSubject(null),
				sender: new BehaviorSubject(null)
			});

			sub.on('publication', ctx => {
				this.channelEventArray[index].listener.next(ctx.data);
			});

			this.centrifuge.on('message', ctx =>
				this.channelEventArray[index].listener.next(ctx.data)
			);
		});
		this._channelEventArray$.next(this.channelEventArray);
	}

	private generateSenders(): void {
		this.socketSender$.pipe(untilDestroyed(this)).subscribe(data => {
			if (data) this.centrifuge.send(data);
		});
		this.channelEventArray.forEach((sub, index) => {
			sub.sender.pipe(untilDestroyed(this)).subscribe(data => {
				if (data) this.currentSubscriptions[index].publish(data);
			});
		});
	}

	private resetLocalStorage(): void {
		const TOKEN_KEY = 'enkod-token';
		const STATUS_KEY = 'enkod-loading';
		const storageRef = this.window.localStorage;

		storageRef.removeItem(TOKEN_KEY);
		storageRef.removeItem(STATUS_KEY);
	}

	ngOnDestroy(): void {
		// проверка чейном на случай если сервис запровайдили, а коннект не открыли
		this.centrifuge?.disconnect();
	}
}
