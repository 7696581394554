import {
	Component,
	ChangeDetectionStrategy,
	OnInit,
	ChangeDetectorRef,
	Input
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { SegmentsDataService } from '@enKod/segments/_state/segments-data.service';
import { EventOption } from '@state-enKod/segments';
import { debounceTime, tap } from 'rxjs/operators';
import { SegmentsValidateService } from '@enKod/segments/segments-validate.service';
import { SegmentsFormService } from '@enKod/segments/segments-form.service';
import {
	ActionCountForm,
	ConditionFormTypes,
	CustomEvent,
	EventParameterForm,
	TimeRangeForm,
	UserEventConditionForm
} from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-user-events',
	templateUrl: './user-event.component.html',
	styleUrls: [
		'./user-event.component.scss',
		'../../abstract-condition.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserEventsСonditionComponent
	extends AbstractCondition
	implements OnInit
{
	eventOptions$ = new BehaviorSubject<EventOption[]>([]);
	currentConditions$ = new BehaviorSubject<string[]>([]);

	@Input() form: FormGroup<ConditionFormTypes>;
	@Input() isUseSegmentModal: boolean = false;

	constructor(
		private formService: SegmentsFormService,
		private dataService: SegmentsDataService,
		private route: ActivatedRoute,
		public validateServices: SegmentsValidateService,
		public cd: ChangeDetectorRef
	) {
		super(validateServices, cd);
	}

	get typedForm(): FormGroup<UserEventConditionForm> {
		return (this.form as FormGroup).controls
			.customEvent as FormGroup<UserEventConditionForm>;
	}

	get typeControl(): FormControl<string> {
		return this.typedForm.controls.type as FormControl<string>;
	}

	get customEventForm(): FormGroup<CustomEvent> {
		return this.typedForm.controls.customEvent as FormGroup<CustomEvent>;
	}

	get eventControl(): FormControl<number | null> {
		return this.customEventForm.controls.id as FormControl<number | null>;
	}

	get eventNameControl(): FormControl<string> {
		return this.customEventForm.controls.name as FormControl<string>;
	}

	get actionTimeForm(): FormGroup<TimeRangeForm> {
		return this.typedForm.controls.actionTime as FormGroup<TimeRangeForm>;
	}

	get actionCountForm(): FormGroup<ActionCountForm> {
		return this.typedForm.controls
			.actionCount as FormGroup<ActionCountForm>;
	}

	get paramForm(): FormGroup<EventParameterForm> {
		return this.typedForm.controls.param as FormGroup<EventParameterForm>;
	}

	get isCreateMode(): boolean {
		return this.route.snapshot.data.action === 'create';
	}

	ngOnInit(): void {
		this.setListeners();
		this.loadCurrentConditions();
	}

	onChangeField(event: { option: EventOption }) {
		this.customEventForm.patchValue(
			{ ...event.option, name: event.option.label },
			{ emitEvent: false }
		);
		this.eventOptions$.next(
			this.eventOptions$.getValue().filter(event => event.id !== null)
		);
	}

	addSubCodition(type: string) {
		const currentConditions = this.currentConditions$.getValue();
		currentConditions.push(type);
		this.currentConditions$.next(currentConditions);
		switch (type) {
			case 'actionTime':
				this.typedForm.addControl(
					type,
					this.formService.createEventActionTime()
				);
				break;
			case 'actionCount':
				this.typedForm.addControl(
					type,
					this.formService.createEventActionCount()
				);
				break;
			case 'param':
				this.typedForm.addControl(
					type,
					this.formService.createEventParam()
				);
				break;
			default:
				break;
		}
	}

	deleteTime() {
		(this.typedForm as FormGroup).removeControl('actionTime');
		this.removeSubCondition('actionTime');
		this.form.markAsTouched();
	}

	deleteCount() {
		(this.typedForm as FormGroup).removeControl('actionCount');
		this.removeSubCondition('actionCount');
		this.form.markAsTouched();
	}

	deleteParam() {
		(this.typedForm as FormGroup).removeControl('param');
		this.removeSubCondition('param');
		this.form.markAsTouched();
	}

	private loadCurrentConditions(): void {
		const result = [];
		const { actionTime, actionCount, param } = this.typedForm.controls;
		if (actionTime) result.push('actionTime');
		if (actionCount) result.push('actionCount');
		if (param) result.push('param');
		this.form.markAsUntouched();

		this.currentConditions$.next(result);
	}

	private setListeners() {
		this.dataService
			.getUserEventList()
			.pipe(
				untilDestroyed(this),
				tap(events => {
					const savedEvent = events.find(
						event => event.id === this.eventControl.value
					);

					const eventOptions: EventOption[] = events.map(event => ({
						id: event.id,
						value: event.id,
						label: event.systemName
					}));
					if (
						!savedEvent &&
						(!this.isCreateMode || this.isUseSegmentModal)
					) {
						this.eventControl.patchValue(null);
						eventOptions.unshift({
							id: null,
							label: this.eventNameControl.value || '',
							value: null
						});
					}

					this.eventOptions$.next(eventOptions);
				})
			)
			.subscribe();

		this.validateServices.checkedValidate
			.pipe(
				untilDestroyed(this),
				debounceTime(100),
				tap(() => {
					if (this.isHide || this.isHideParent) {
						this.form.markAsPristine();
					}
					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	private removeSubCondition(type: string) {
		const currentConditions = this.currentConditions$
			.getValue()
			.filter(currentCondition => currentCondition !== type);
		this.currentConditions$.next(currentConditions);
	}
}
