<form [formGroup]="form">
    <span class="en-text-large">{{ 'scenario_block_event_start.description' | translate }}</span>

    <en-dropdown class="en-field event-dropdown"
                 styleClass="h36"
                 filterBy="label,value.sysName"
                 appendTo="body"
                 [placeholder]="'scenario_block_event_start.event_label' | translate"
                 [options]="eventOptions"
                 [filter]="true"
                 [disabled]="!isCreationMode"
                 formControlName="systemName"
                 (onChange)="resetParams()">
        <ng-template let-event
                     pTemplate="item">
            <div class="event-dropdown_item">
                <div class="event-dropdown_label">
                    {{event.label}}
                </div>
                <div class="event-dropdown_value">
                    {{event.value.sysName}}
                </div>
            </div>
        </ng-template>
    </en-dropdown>

    <div formArrayName="conditions">
        <div *ngFor="let condition of conditionControls; index as i"
             class="en-field"
             [formGroup]="condition">
            <div class="param__label">
                <label>{{ 'scenario_block_event_start.param' | translate }} {{ i + 1 }}</label>
                <button *ngIf="isCreationMode"
                        enButton
                        class="en-button-lowercase h28"
                        icon="en en-minus-circle-o"
                        iconColor="red-button-icon"
                        [disabled]="!isCreationMode"
                        (click)="deleteParam(i)"></button>
            </div>
            <div class="param-controls">
                <en-dropdown class="param-dropdown"
                             appendTo="body"
                             filterBy="label,value.sysName"
                             [placeholder]="'scenario_block_event_start.param_label' | translate"
                             [options]="paramOptions"
                             [filter]="true"
                             [disabled]="!isCreationMode"
                             formControlName="paramName"
                             (onChange)="resetFromParam(i)">
                    <ng-template let-selectedOption
                                 pTemplate="selectedItem">
                        <ng-container *ngIf="selectedOption">
                            <div class="selected-param">
                                <span class="en-mr-2"
                                      [ngClass]="selectedOption.value.type | extraFieldsIconPipe"></span>
                                <span class="selected-param__name">{{ selectedOption.label }}</span>
                            </div>
                        </ng-container>
                    </ng-template>
                    <ng-template let-param
                                 pTemplate="item">
                        <div class="param-dropdown_item">
                            <div class="param-dropdown_label">
                                {{param.label}}
                            </div>
                            <div class="param-dropdown_value">
                                <span style="color: var(--color-text-secondary)"
                                      [ngClass]="param.value.type | extraFieldsIconPipe"></span>
                                <div class="param-dropdown_sys-name">
                                    {{param.value.sysName}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </en-dropdown>

                <ng-container *ngIf="condition.value.paramName">
                    <ng-container [ngSwitch]="getParamType(condition.value.paramName.sysName)">
                        <en-multilevel-dropdown *ngSwitchCase="'date'"
                                                class="multilevel-dropdown"
                                                menuWidth="19.85rem"
                                                [placeholder]="'scenario_block_event_start.param_label' | translate"
                                                [options]="getOperatorOptions(condition.value.paramName.sysName)"
                                                [disabled]="!isCreationMode"
                                                [valueControl]="condition.controls.operator"
                                                (onChange)="resetValue(i)"></en-multilevel-dropdown>
                        <en-multilevel-dropdown *ngSwitchCase="'dateTime'"
                                                class="multilevel-dropdown"
                                                menuWidth="19.85rem"
                                                [placeholder]="'scenario_block_event_start.param_label' | translate"
                                                [options]="getOperatorOptions(condition.value.paramName.sysName)"
                                                [disabled]="!isCreationMode"
                                                [valueControl]="condition.controls.operator"
                                                (onChange)="resetValue(i)"></en-multilevel-dropdown>
                        <en-dropdown *ngSwitchDefault
                                     styleClass="en-dropdown-solid"
                                     [placeholder]="'scenario_block_event_start.param_label' | translate"
                                     [options]="getOperatorOptions(condition.value.paramName.sysName)"
                                     [disabled]="!isCreationMode"
                                     formControlName="operator"
                                     (onChange)="resetValue(i)"></en-dropdown>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="condition.value.paramName &&
                                     condition.value.operator &&
                                     !operatorsWithoutValue.includes(condition.value.operator) &&
                                     condition.value.operator !== 'between'">
                    <ng-container [ngSwitch]="getParamType(condition.value.paramName.sysName)">
                        <input *ngSwitchCase="'string'"
                               enInput
                               type="text"
                               [attr.disabled]="disableInput"
                               formControlName="value">
                        <input *ngSwitchCase="'number'"
                               enInput
                               [imask]="maskNumber"
                               [unmask]="true"
                               [attr.disabled]="disableInput"
                               formControlName="value">
                        <input *ngSwitchCase="'float'"
                               enInput
                               [imask]="maskFloat"
                               [unmask]="true"
                               [attr.disabled]="disableInput"
                               formControlName="value">
                        <en-dropdown-calendar-new *ngSwitchCase="'date'"
                                                  type="date"
                                                  [disabled]="!isCreationMode"
                                                  formControlName="value"></en-dropdown-calendar-new>
                        <en-dropdown-calendar-new *ngSwitchCase="'dateTime'"
                                                  [disabled]="!isCreationMode"
                                                  formControlName="value"></en-dropdown-calendar-new>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="condition.value.operator &&
                                     condition.value.operator === 'between' &&
                                     condition.value.operator !== 'thisDay'">
                    <ng-container [ngSwitch]="getParamType(condition.value.paramName.sysName)">
                        <en-dropdown-calendar-range *ngSwitchCase="'date'"
                                                    class="range-dropdown"
                                                    type="date"
                                                    appendTo="body"
                                                    [disabled]="!isCreationMode"
                                                    formControlName="value"></en-dropdown-calendar-range>
                        <en-dropdown-calendar-range *ngSwitchCase="'dateTime'"
                                                    class="range-dropdown"
                                                    appendTo="body"
                                                    [disabled]="!isCreationMode"
                                                    formControlName="value"></en-dropdown-calendar-range>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="isCreationMode && eventControl.value">
        <button *ngIf="conditionControls.length < 5"
                enButton
                type="button"
                class="en-button-lowercase"
                icon="en en-plus-circle-o"
                iconColor="blue-button-icon"
                iconPos="left"
                [label]="'scenario_block_event_start.add_param' | translate"
                (click)="addParam()"></button>
        <span class="max-params-desc">{{ 'scenario_block_event_start.max_params' | translate }}</span>
    </div>

    <div class="duplicate-handling">
        <span class="sub-title en-text-medium">{{'scenario_block_api.message_summary' | translate}}</span>
        <en-dropdown styleClass="h36"
                     [options]="selectOptions"
                     [disabled]="!isCreationMode"
                     [formControl]="duplicateHandling"
                     (onChange)="changeDuplicateHandling($event.value)">
            <ng-template let-selected
                         pTemplate="selectedItem">
                <div class="duplicate-handling__selected">{{selected.label | translate}}</div>
            </ng-template>
            <ng-template let-type
                         pTemplate="item">
                <div class="duplicate-handling__drop">
                    <span class="drop-label">{{type.label | translate}}</span>
                    <div class="drop-description">{{type.description | translate}}</div>
                </div>
            </ng-template>
        </en-dropdown>
    </div>

    <en-messages class="en-message"
                 [visible]="message.length"
                 [(value)]="message"></en-messages>
</form>