<en-dialog [header]="'message_wizard_edit_step.action_dropdown_button_test' | translate"
           [visible]="showTest"
           [modal]="true"
           [resizable]="false"
           closeIcon="en en-close"
           (visibleChange)="testClose()"
           #dialog>

    <p-tabView *ngIf="tabsShowCondition"
               [activeIndex]="index"
               (onChange)="tabChange($event)">
        <p-tabPanel [header]="'message_wizard_edit_step.sendtest_tab1' | translate">
            <en-test-mails></en-test-mails>
        </p-tabPanel>
        <p-tabPanel [header]="'message_wizard_edit_step.sendtest_tab2' | translate">
            <en-test-snippets [sendTestButton]="sendTestButton"
                              [dialog]="dialog"
                              [warnAlert]="warnAlert"
                              (showMails)="this.index = 0;"></en-test-snippets>
        </p-tabPanel>
    </p-tabView>

    <div class="dialog__no-snippets">
        <en-test-mails *ngIf="!tabsShowCondition"></en-test-mails>
    </div>

    <p-footer>
        <button enButton
                class="h36 en-button-secondary en-mr-3"
                [label]="'message_wizard_edit_step.sendtest_button_back' | translate"
                (click)="testClose()">
        </button>
        <button #sendTestButton
                enButton
                class="h36"
                [disabled]="emptyEmailsInput"
                [label]="'message_wizard_edit_step.sendtest_button_send' | translate"
                [enLoading]="isLoading$ | async"
                (click)="sendTest()">
        </button>
    </p-footer>
</en-dialog>