import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import {
	ActionTimeForm,
	CategoriesConditionForm
} from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';
import {
	OptionsParameter,
	OptionsPeriod
} from './categories-condition.options';

@UntilDestroy()
@Component({
	selector: 'en-categories-condition',
	templateUrl: './categories-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesConditionComponent extends AbstractCondition {
	optionsParameter: SelectItem[] = OptionsParameter;
	optionsPeriod: SelectItem[] = OptionsPeriod;

	get typedForm(): FormGroup<CategoriesConditionForm> {
		return this.form as FormGroup<CategoriesConditionForm>;
	}

	get actionTime(): FormGroup<ActionTimeForm> {
		return this.typedForm.controls.actionTime as FormGroup<ActionTimeForm>;
	}

	get parameterControl(): FormControl<string> {
		return this.typedForm.controls.parameter as FormControl<string>;
	}

	get parameterValue(): string {
		return this.parameterControl.value;
	}

	get controlValue(): FormControl<number> {
		return this.typedForm.controls.value as FormControl<number>;
	}

	setValidation(): void {
		this.parameterControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (value === 'atLeastOne') {
					this.controlValue?.setValue(1);
				}
			});
	}
}
