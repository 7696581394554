export const operatorTranslates: { [key: string]: string } = {
	equal: 'segment_form.options_operator_equal',
	notEqual: 'segment_options.not_equal_options',
	contains: 'segment_form.options_operator_contain',
	notContains: 'segment_options.not_contain_options',
	empty: 'segment_form.options_operator_empty',
	notEmpty: 'segment_options.not_empty_options',
	less: 'segment_form.options_operator_less',
	lessOrEqual: 'segment_form.options_operator_lessOrEqual',
	more: 'segment_form.options_operator_more',
	moreOrEqual: 'segment_form.options_operator_moreOrEqual',
	true: 'client_fields.value_true',
	false: 'client_fields.value_false',
	after: 'segment_operator_menu.after_item',
	before: 'segment_operator_menu.before_item',
	between: 'segment_options.between_date',
	today: 'segment_form.cond_field_date_direction_today',
	thisMonth: 'segment_operator_menu.mounth_item',
	thisYear: 'segment_operator_menu.year_item'
};
