export const DEFAULT_RF_TITLES: string[] = [
	'rf_chart.no_lose_option',
	'rf_chart.loyal_option',
	'rf_chart.champions_option',
	'rf_chart.at_risk_option',
	'rf_chart.attention_option',
	'rf_chart.maybe_loyal_option',
	'rf_chart.sleeping_option',
	'rf_chart.asleep_soon_option',
	'rf_chart.promising_option',
	'rf_chart.beginners_option'
];

export const DEFAULT_RF_DESCRIPTIONS: string[] = [
	'rf_chart.no_lose_description',
	'rf_chart.loyal_description',
	'rf_chart.champions_description',
	'rf_chart.at_risk_description',
	'rf_chart.attention_description',
	'rf_chart.maybe_loyal_description',
	'rf_chart.sleeping_description',
	'rf_chart.asleep_soon_description',
	'rf_chart.promising_description',
	'rf_chart.beginners_description'
];
