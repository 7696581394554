import { Injectable, Injector } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import {
	InspectorItem,
	InspectorItemPlugin
} from '../../inspector-item-plugin';
import { ApiStartComponent } from './api-start.component';

@Injectable()
export class ApiStartService implements InspectorItemPlugin {
	private readonly apiStartContent = new PolymorpheusComponent(
		ApiStartComponent,
		this.injector
	);

	constructor(private readonly injector: Injector) {}

	getItems(): Observable<InspectorItem[]> {
		return of([
			{
				id: 'apiStart',
				name: 'apiStart',
				content: this.apiStartContent
			}
		]);
	}
}
