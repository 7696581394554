import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'en-android-mock',
	templateUrl: './android-mock.component.svg',
	styleUrls: ['./android-mock.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AndroidMockComponent {
	colorBar: string;
	colorMain: string;
	colorBg: string;

	@Input('isDetail')
	set isDetailSetter(value: boolean) {
		this.colorBar = value ? '#F7F8FA' : 'rgba(247,248,250, 0.1)';
		this.colorMain = value ? '#C5C9D6' : '#FFF';
		this.colorBg = value ? '#E8EAEF' : '#73A3DC';
	}
}
