import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from 'primeng/api';
import {
	TimeRangeForm,
	VisitsConditionForm
} from '@enKod/segments/segments-form.model';
import { AbstractCondition } from '../../../abstract-condition.component';

@UntilDestroy()
@Component({
	selector: 'en-visits-condition',
	templateUrl: './visits-condition.component.html',
	styleUrls: ['../../../abstract-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitsConditionComponent extends AbstractCondition {
	readonly optionsParameter: SelectItem[] = [
		{
			label: 'segment_options.with_any_url',
			value: 'any'
		},
		{
			label: 'segment_options.with_specific_url',
			value: 'specific'
		}
	];

	readonly optionsSpecific: SelectItem[] = [
		{
			label: 'segment_form.options_operator_equal',
			value: 'equal'
		},
		{
			label: 'segment_form.options_operator_start_with',
			value: 'prefix'
		},
		{
			label: 'segment_form.options_operator_contain',
			value: 'contain'
		},
		{
			label: 'segment_form.cond_url_wild',
			value: 'mask'
		}
	];

	readonly optionsCount: SelectItem[] = [
		{
			label: 'segment_options.equal_options',
			value: 'equal'
		},
		{
			label: 'segment_form.cond_message_count_min',
			value: 'min'
		},
		{
			label: 'segment_form.cond_message_count_max',
			value: 'max'
		}
	];

	get typedForm(): FormGroup<VisitsConditionForm> {
		return this.form as FormGroup<VisitsConditionForm>;
	}

	get actionTime(): FormGroup<TimeRangeForm> {
		return this.typedForm.controls.actionTime as FormGroup<TimeRangeForm>;
	}

	get parameterValue(): string {
		return this.typedForm.controls.parameter.value;
	}

	get isShowSpecificParam(): boolean {
		return this.parameterValue === 'specific';
	}

	get isSpecificLoaded(): boolean {
		return Boolean(this.typedForm.controls.specificOperator.value);
	}

	setValidation() {
		this.getSubscription('parameter')
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				switch (value) {
					case 'any':
						this.form.get('specificOperator')?.disable();
						this.form.get('specificValue')?.disable();
						break;
					case 'specific':
						this.form.get('specificOperator')?.enable();
						this.form.get('specificValue')?.enable();
						this.form
							.get('specificValue')
							?.setValidators([Validators.required]);
						break;
					default:
						break;
				}
				this.markForCheck();
			});

		this.getSubscription('value')
			?.pipe(untilDestroyed(this))
			.subscribe(value => {
				if (value < 1) this.setValue('value', 1);
				if (value > 100) this.setValue('value', 100);
			});
	}
}
