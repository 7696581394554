import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';

import { Observable } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import {
	ContactsDetail,
	FieldsViewSettings,
	MainFields,
	PersonData,
	PhoneData,
	ResValidation,
	UpdateField,
	ContactExtraFields,
	RfmTagsModel
} from './contacts-detail.model';
import { ContactsDetailDataService } from './contacts-detail-data.service';
import { ContactsDetailQuery } from './contacts-detail.query';
import { ContactsDetailStore } from './contacts-detail.store';

@Injectable({
	providedIn: 'root'
})
export class ContactsDetailService {
	constructor(
		private dataService: ContactsDetailDataService,
		private query: ContactsDetailQuery,
		public store: ContactsDetailStore
	) {}

	get id() {
		return this.query.getActiveId();
	}

	getDetail(id: ID) {
		this.store.setLoading(true);
		let extraField = {} as ContactExtraFields;

		return this.dataService.getDetail<ContactsDetail>(id).pipe(
			tap(resp => {
				extraField = resp.extraFields;
				this.store.upsert(id, {
					...resp
				});
				this.store.setActive(id);
			}),
			switchMap(() => {
				return this.getExtraFieldViewSettings(extraField);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	async updatePersonData(data: PersonData) {
		await this.dataService.updateContactField(data, this.id).toPromise();
		this.store.update(this.id, data);
	}

	updateMainField(mainField: MainFields) {
		return this.dataService.updateContactField(mainField, this.id).pipe(
			tap(() => {
				this.store.update(this.id, mainField);
			})
		);
	}

	updatePhoneField(phoneField: PhoneData) {
		return this.dataService.updateContactField(phoneField, this.id).pipe(
			tap(() => {
				this.store.update(this.id, phoneField);
			})
		);
	}

	async updateExtraField(extraField: UpdateField) {
		const transformedObject = this.toKeyValue(extraField);
		await this.dataService
			.updateContactField(transformedObject, this.id)
			.toPromise();
		this.store.update(this.id, state => {
			return {
				...state,
				extraFields: {
					...state.extraFields,
					[extraField.systemName]: {
						...state?.extraFields[extraField?.systemName],
						value: extraField.value
					}
				} as ContactExtraFields
			};
		});
	}

	async setExtraFieldViewSettings(settings: FieldsViewSettings) {
		await this.dataService.setFieldsViewSettings(settings).toPromise();
		// Добавить тост ?
		this.store.update({ viewSettings: settings });
	}

	private toKeyValue(object: UpdateField): { [key: string]: string } {
		let keyValueObject = {};
		const map = Object.entries(object);
		const key = map[0][1];
		const value = map[1][1];

		keyValueObject = {
			[key]: value
		};

		return keyValueObject;
	}

	checkMxRecordEmail(mail: string): Observable<ResValidation> {
		return this.dataService.checkMxRecordEmail({ email: mail });
	}

	checkPhone(phone: PhoneData): Observable<ResValidation> {
		return this.dataService.checkPhone(phone);
	}

	async getExtraFieldViewSettings(extraFields: ContactExtraFields) {
		const respSettings = (await this.dataService
			.getFieldsViewSettings()
			.toPromise()) as FieldsViewSettings;

		const syncedSettings = this.syncSettings(respSettings, extraFields);

		if (JSON.stringify(syncedSettings) !== JSON.stringify(respSettings))
			await this.setExtraFieldViewSettings(syncedSettings);

		this.store.update({ viewSettings: syncedSettings });
	}

	removeContact(id: string) {
		return this.dataService.removeContact(id);
	}

	updateTags(id: ID, data: object = {}) {
		return this.dataService.updateTags(id, data);
	}

	getRfmTags(id: ID): Observable<RfmTagsModel> {
		return this.dataService.getRfmTags(id);
	}

	private syncSettings(
		settings: FieldsViewSettings,
		extraFields: ContactExtraFields
	): FieldsViewSettings {
		if (settings === null) return settings;

		const syncedSettings: FieldsViewSettings = {
			hidden: [],
			visible: []
		};

		const fieldsNames: string[] = Object.keys(extraFields);

		const allSettings: string[] = [...settings.hidden, ...settings.visible];

		syncedSettings.hidden = settings.hidden.filter((name: string) => {
			return fieldsNames.includes(name);
		});

		syncedSettings.visible = settings.visible.filter((name: string) => {
			return fieldsNames.includes(name);
		});

		fieldsNames.forEach(name => {
			if (!allSettings.includes(name)) syncedSettings.hidden.push(name);
		});

		return syncedSettings;
	}
}
